import React from 'react';
import {Button, Col, Row, Form, Dropdown, ProgressBar} from 'react-bootstrap';
import {useState, useRef} from 'react';
import {useEffect} from 'react';
import commonApi from '../../Services/CommonService';
import {DEFAULT_IMAGE, selected_lang} from '../../Utils/env';
// import {labels} from '../../Constants/Translations';
// import {selected_lang} from '../../Utils/env';
import ImageLightbox from '../ImageLightbox';
// import {RenderHTMLBlock} from '../../Utils/Helpers';
import OpportunityList from '../OpportunitiesList/OpportunityList';
import AddNewSnapShot from '../Messages/AddNewSnapShot';
import Scrollbars from 'react-custom-scrollbars/lib/Scrollbars';
import {useSelector, useDispatch} from 'react-redux';
import {
  arrayColumn,
  calculateMilestoneProgress,
  capitalizeFirst,
  capitalizeFirstLetter,
  checkPermissionsOfMenus,
  checkPermissionsOfMenusProfile,
  contactTab,
  customertoemployee,
  detailButtons,
  Employmenttabs,
  filteringUsers,
  fixName,
  formatAmount,
  formatString,
  getVisibleTabs,
  goToZoom,
  ownertocustomer,
  ownertoemploye,
  truncateTitle,
  // formatAmount,
} from '../../Utils/commonUtils';
import {useHistory} from 'react-router-dom';
import CustomModal from '../CustomModal';
import OpportunityScreen from '../FrontScreens/OpportunityScreen';

import {getOpportunityListingProfile} from '../../Store/Actions/OpportunityAction';
import {
  employeeMenu,
  employeeToEmployeeMenu,
  customerMenu,
  employeeMenuP,
  employeeToEmployeeMenuP,
  customerMenuP,
} from '../../Constants';
import {
  ArrowIcon,
  FireIcon,
  MachineIcon,
  WhitePenIcon,
  timeIcon,
  bagIcon,
  heartIcon,
  Bandage,
} from '../../Constants/Images';
import moment from 'moment';
import ProfileProjects from './ProfileProjects';
import CheckoutForm from '../SessionDetails/CheckoutForm';
import {loadStripe} from '@stripe/stripe-js';

import {
  PaymentElement,
  Elements,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';
import CustomSlidingButtons from '../CustomSlidingButtons';
import {useImperativeHandle} from 'react';
import ProfileOverview from './ProfileOverview';
import Review from '../SessionDetails/Review';
import ReviewListing from '../SessionDetails/ReviewListing';
import ProfileAppointmentList from '../AppointmentPage/ProfileAppointmentList';
import ProfileFollowUp from './ProfileFollowUp';
import ProfileTask from './ProfileTask';
import ProfileDeals from './ProfileDeals';
import ProfilePipeline from '../PipeLine/ProfilePipeline';
import LoaderSpinner from '../Modals/LoaderSpinner';
import GalleryIcons from './GalleryIcons';
import ProfileMeetup from '../SessionDetails/ProfileMeetup';
import ProfileRecruitment from '../OpportunitiesList/ProfileRecruitment';
import NorecordFound from '../NoRecord/NorecordFound';
import {propertyNoRecordFound} from '../../Utils/NoRecordConstant';
import {POCNoRecordFound} from '../../Utils/NoRecordConstant';
import FloatingMenu from './FloatingMenu';
import {FormattedText, RenderHTMLBlock} from '../../Utils/Helpers';
import {labels} from '../../Constants/Translations';
import ProfileNotes from './ProfileNotes';
import {useLocation} from 'react-router-dom/cjs/react-router-dom';
import ProfileNotesListing from './ProfileNotesListing';
import ContactDetail from '../ContactDetail';
import {getReferralListing} from '../../Store/Actions/ReferralAction';
import Moment from 'react-moment';
import AppointmentItem from '../AppointmentPage/AppointmentItem';
import API from '../../Services/SessionServices';
import {errorMessage} from '../../Utils/ToastMessages';
import {amplitudeEvent} from '../../Services/AmplitudeServices';
import OtherUserImages from '../Common/OtherUserImages';
import ShowMore from '../Common/ShowMore';
import {getItem} from '../../Utils/LocalStorage';
import InvoiceItem from '../InvoiceEstimate/InvoiceItem';
import SesssionItem from '../SesssionItem';
import EventsItem from '../EventsItem';
import CallsInformation from '../../Pages/Front/StaticPages/CallsInformation';
import SvgIconComponent from '../../Constants/SvgIconComponent';
import {Project_Listing} from '../../Store/Actions/ProjectAction';
import ListingOfProjects from '../../Pages/Front/ListingOfProjects';
import ProfileTransformation from './ProfileTransformation';
import CreatePortfolio from '../Portfolio/CreatePortfolio';
// import OpportunityItem from "../OpportunitiesList/OppotunityItem";
import CompensationPay from '../Compensation/CompensationPay';
import CompensationIncentive from '../Compensation/CompensationIncentive';
import Availability from '../Availability/Availability';
import PortfolioListing from '../Portfolio/PortfolioListing';
import {emptySearch} from '../../Store/Actions/emptySearchAction';
import EmergencyConatct from './EmergencyConatct';

const pathSegments = window.location.pathname.split('/');
const lastSegment = pathSegments[pathSegments.length - 1];

const ProfileGallery = props => {
  // const [gallery, setGallery] = useState();
  // const [oppos, setOppos] = useState();
  // const [sesList, setSesList] = useState(props.sesList);

  const {subTabType} = props;

  const [showMore, setShowMore] = useState(false);

  const [galleryType, setGalleryType] = useState('general');
  const [popupImages, setPopupImages] = useState([]);
  const [threeDots, setThreeDots] = useState(false);
  const [show, setShow] = useState(false);
  const [current, setCurrent] = useState(0);
  const [pocData, setPocData] = useState([]);
  // const [showMore, setShowMore] = useState(false);
  // const dispatch = useDispatch();
  const menuPermesions = useSelector(state => state.menuPermesionList);
  // const [isNewSession, setIsNewSession] = useState(props.isNewSession);
  const getEmptySearch = useSelector(state => state.emptySearchReducer);

  const [userSnapshots, setSnapshotData] = useState([]);
  // const [showSnapshots, setShowSnapshot] = useState(false);

  const [userUpcomming, setUpcommingData] = useState([]);
  // const [showUpcomming, setShowUpcomming] = useState(false);

  // const [opportunityData, setOpportunityData] = useState([]);
  // const [showOpportunity, setShowOpportunity] = useState(false);

  const [addNewSnapshot, setAddNewSnapshot] = useState(false);
  const [showSnapshotModal, setShowSnapshotModal] = useState(false);
  const [categoriesArr, setCategoriesArr] = useState([]);
  // const [categoriesName, setCategoriesName] = useState(['All']);
  const [upcomingFilter, setUpcomingFilter] = useState('all');
  const [opportunityFilter, setOpportunityFilter] = useState('all');
  const [showSpinner, setShowSpinner] = useState(true);
  const [showComponent, setShowComponent] = useState('');
  const [transformationList, setTransformationList] = useState([]);
  const [type, setType] = useState(
    props.fromPage || props.snapShotRefer === true ? 'credentials' : 'overview',
  );
  const allMine = props.userId === localStorage.getItem('id') ? 'all' : 'mine';
  const [invoices, setInvoices] = useState([]);
  const [invoiceItem, setInvoiceItem] = useState({});
  const [showStripeModal, setShowStripeModal] = useState(false);
  const [selectedPrice, setSelectedPrice] = useState('');
  const [paymentId, setPaymentId] = useState('');
  const [currentComponent, setCurrentComponent] = useState('');
  const [paymentType, setPaymentType] = useState('unpaid');
  const [categoryTypes, setCategoryTypes] = useState([
    {title: 'Pending', id: 1},
    {title: 'Paid', id: 2},
  ]);
  const [dropdownType, setDropdownType] = useState([1]);
  const [dropdownTypeValue, setDropdownTypeValue] = useState(['']);
  const [subDropdownValue, setSubDropdownValue] = useState(['']);
  const [detailButtonsId, setDetailButtonsId] = useState(['1']);
  const [detailButtonsValue, setDetailButtonsValue] = useState(['attachments']);
  const [showContactDetails, setShowContactDetails] = useState(true);
  const [uploadFilesListing, setUploadFilesListing] = useState([]);
  const [showFileComponent, setShowFileComponent] = useState(false);
  const [showFirstValue, setShowFirstValue] = useState(['']);
  const [contactData, setContactData] = useState({
    name: '',
    email: '',
    phoneNo: '',
    address: '',
    type: '',
    socialListing: [],
    pocListing: [],
  });
  const [memberData, setMemberData] = useState({
    name: '',
    email: '',
    phoneNo: '',
    address: '',
    type: '',
  });
  const [additionalDetail, setAdditionalDetail] = useState({
    website: '',
    contactSource: '',
    birthDate: '',
    timeZone: '',
  });

  const [showProfileListing, setShowProfileListing] = useState({
    notes: true,
  });
  // const [subTabType, setSubTabsType] = useState('all');
  const [referTypeIds, setReferTypeIds] = useState('');
  const [userData, setUserData] = useState([]);
  const [accomplishmentId, setAccomplishmentId] = useState('');
  const [serverTime, setServerTime] = useState('');
  const [arrowButton, setArrowButton] = useState(false);
  const [dataa, setData] = useState([]);
  const [referSession, setReferSession] = useState([]);
  const [notes, setNotes] = useState('');
  const [appointmentData, setAppointmentData] = useState({});
  const [opportunityData, setOpportunityData] = useState([]);
  const [toggleShowMore, setToggleShowMore] = useState(false);
  const showMoreClass = toggleShowMore ? 'show-all' : '';

  const [eventItem, setEventItem] = useState([]);
  const [searchQry, setSearchQry] = useState('');
  const [projectListing, setProjectListing] = useState([]);
  const [messagesListing, setMessagesListing] = useState([]);
  const [referrelListing, setReferrelListing] = useState([]);
  const [projectFilter, setProjectFilters] = useState([]);

  const [showLoader, setshowLoader] = useState(false);
  const [transformation_Filter, setTransformation_Filter] = useState([1]);
  const [dropdownMenu, setDropdownMenu] = useState([]);
  const [subDropdownMenu, setSubDropdownMenu] = useState([]);
  const [subDropdownId, setSubDropdownId] = useState('');
  const [open, setOpen] = useState(false);
  const stripePromise = loadStripe(
    'pk_test_51PEB5a2K8W7Cs25henyw4Ahf3eGMQgtDgRztlGV4POXFghfMEH5DDg5PtNpT916JChWA9ELeby2Znh5mu2IjXyuY00TWUVZN1V',
  );
  // const opportunityData = useSelector(
  //   state => state.opportunityListingProfile?.listing,
  // );

  const activeCompany = localStorage.getItem('activeWorkspaceItem')
    ? JSON.parse(localStorage.getItem('activeWorkspaceItem'))
    : [];

  const hitApiRef = useRef();
  const history = useHistory();
  const dispatch = useDispatch();
  const [tabs, setTabs] = useState([]);
  const location = useLocation();

  const [showAll, setShowAll] = useState(false);

  const componentDecesion = (item, type) => {
    // setUserData(item);
    handleMenu(type, item);
  };

  const serverTimeCheck = () => {
    // var payLoad = {
    //   search_query: qry,
    //   user_id: localStorage.getItem('user'),
    // };
    commonApi
      .check_server_time()
      .then(res => {
        setServerTime(res.server_time);
      })
      .catch(err => {
        return {type: 'error', message: err.message};
      });
  };

  useEffect(() => {
    serverTimeCheck();
  }, []);

  useEffect(() => {
    if (dropdownTypeValue.includes('recent')) {
      setReferrelListing(
        props.userData?.referrals ? props.userData?.referrals : [],
      );
    }
  }, [dropdownTypeValue]);

  useEffect(() => {
    if (getEmptySearch) {
      setSearchQry('');
      dispatch(emptySearch(false));
    }
  }, [getEmptySearch]);

  useEffect(() => {
    if (dropdownTypeValue.includes('referalls')) {
      setReferrelListing(props.referListing ? props.referListing : []);
    }
  }, [dropdownTypeValue, props.referListing, subTabType]);

  const callToPerson = item => {
    if (localStorage.getItem('purchased_number_status') === 'active') {
      // setCallModalRecent(true);
    } else {
      errorMessage("You didn't purchase any number");
    }

    // alert(item.phoneNumber,'1')
    // alert(phone,'2')
  };

  const overViewDropdown =
    localStorage.getItem('userRole_in_company') !== 'Customer' &&
    props.userData?.user_role === 'Customer'
      ? checkPermissionsOfMenusProfile(
          ownertocustomer,
          menuPermesions,
          props?.userData?.pipeline_permission,
          props.userData?.user_role,
        )
      : localStorage.getItem('userRole_in_company') === 'Customer' &&
        props.userData?.user_role !== 'Customer'
      ? checkPermissionsOfMenusProfile(
          customertoemployee,
          menuPermesions,
          props?.userData?.pipeline_permission,
          props.userData?.user_role,
        )
      : checkPermissionsOfMenusProfile(
          ownertoemploye,
          menuPermesions,
          props?.userData?.pipeline_permission,
          props.userData?.user_role,
        ) || [];

  const decideDropdownValues =
    type === 'overview'
      ? overViewDropdown
      : type === 'employment' ? Employmenttabs
      : type === 'contacts' ? contactTab
      : type === 'Detail' && detailButtons;

  // decideDropdownValues.unshift({ id: '1', name: 'Recent', tab: 'recent' });

  const getListing = {
    projects: [], // Empty array means it won’t be shown
    payment: [1],
    appointment: [],
    meetup: [1, 2],
    tasks: props.userData?.tasks ? props.userData?.tasks : [],
    Notes: props.userData?.notes_detail ? props.userData?.notes_detail : [],
    Messages: props.userData?.chat_details ? props.userData?.chat_details : [],
  };

  // const showOnlyListingTab = getVisibleTabs(decideDropdownValues, getListing);

  // const filteredItems =
  //   decideDropdownValues?.filter(item => item.id !== '1') || [];

  // const visibleItems = showAll ? filteredItems : filteredItems.slice(0, 4);

  const snapshotCategories = useSelector(
    state => state.accomplishmentCategories,
  );

  const handleChange = (type, e, otherType) => {
    if (type === 'open_closed') {
      setShowContactDetails(!showContactDetails);
    } else if (type === 'change_value') {
      if (otherType === 'additionalDetail') {
        setAdditionalDetail({
          ...additionalDetail,
          [e.target.name]: e.target.value,
        });
      } else if (otherType === 'member') {
        setMemberData({
          ...memberData,
          [e.target.name]: e.target.value,
        });
      } else {
        setContactData({
          ...contactData,
          [e.target.name]: e.target.value,
        });
      }
    }
  };

  useEffect(() => {
    setContactData({});
    setMemberData({});
    setAdditionalDetail({});
  }, [type, dropdownTypeValue]);

  //

  // state = {
  //     gallery:{} ,
  //     oppos: {},
  //     sesList: {},
  //     galleryType: "Snapshot",
  //     popupImages: [],
  //     show: false,
  //     current: 0,
  //     isNewSession: false,
  // };

  const get_user_linked_projects_of_active_company = page => {
    // setShowSpinner(true)
    const options = projectFilter?.map(element => {
      return element.toLowerCase();
    });

    let payload = {
      user_id: props.userData?.data?.id ? props.userData?.data?.id : '',
      status: options,
    };

    commonApi
      .get_user_linked_projects_of_active_company(payload)
      .then(res => {
        if (res.status === 200) {
          setProjectListing(res?.projects);
        }
        setShowSpinner(false);
      })
      .catch(err => {
        setShowSpinner(false);
        return {type: 'error', message: err.message};
      });
  };

  const get_users_chats_new = () => {
    let payload = {
      user_id: props.userData?.data?.id ? props.userData?.data?.id : '',
      filter: props.filter,
      search_query: searchQry,
    };

    commonApi
      .get_users_chats_new(payload)
      .then(res => {
        if (res.status === 200) {
          setMessagesListing(res?.chats);

          const getLatestMessages = res?.chats?.slice(0, 2) || [];

          props.setUserData(prev => ({
            ...prev,
            chat_details: getLatestMessages, // Keeping the first existing item and the latest item
          }));
        }
      })
      .catch(err => {
        return {type: 'error', message: err.message};
      });
  };

  // const get_referrals = (type, qry, arrow, pageN) => {
  //   console.log(arrow, 'arrowarrow')

  //   const data = {
  //     builder_filter_data: {
  //       allies: 'no',
  //       appointmentTypes: [],
  //       gender: '',
  //       homeTown: '',
  //       cityStates: [],
  //       ethnicities: '',
  //       homeLatitude: '',
  //       homeLongitude: '',
  //       travelLatitude: '',
  //       travelLongitude: '',
  //       travelLocation: '',
  //       otherIdentifiers: [],
  //       onBoardingAreas: [],
  //       onBoardingCategories: [],
  //       // focusData: focusValues
  //     },
  //     opportunity_filter_data: {
  //       address: '',
  //       allies: '',
  //       amount: '',
  //       frequencyIds: [],
  //       latitude: '',
  //       longitude: '',
  //       opportunityIds: [],
  //       vaccinated: false,
  //       virtual: false,
  //     },
  //     type: ['builders'],
  //     category_ids: '',
  //     search_query: searchQry,
  //     referred_by_me: arrow,
  //     page: 1,
  //   };

  //   commonApi
  //     .get_referrals(data)
  //     .then(res => {
  //       if (res.status === 200) {
  //         // setReferrelListing(res?.data);
  //       }
  //     })
  //     .catch(err => {
  //       console.log('Err', err);
  //       return {type: 'error', message: err.message};
  //     });
  // };

  useEffect(() => {
    if (
      (dropdownType === 'project management' || props.hitApi === 'project') &&
      dropdownType !== 'recent'
    ) {
      get_user_linked_projects_of_active_company();
      props.setHitApi('');
    }
  }, [dropdownType, props.hitApi]);

  useEffect(() => {
    if (dropdownType === 'Messages') {
      get_users_chats_new();
    }
  }, [dropdownType, props.hitApi, searchQry, props.filter]);

  useEffect(() => {
    if (dropdownType === 'Referrals') {
      props.get_referrals(subTabType, '', arrowButton, 1);
    }
  }, [dropdownType, arrowButton, subTabType]);

  const handleClickButtons = e => {
    const {id, value} = e.target;
    setDropdownType(id);
    setDropdownTypeValue([value]);
  };
  const handleDetailDropdownButtons = e => {
    const {id, value} = e.target;
    setDetailButtonsId(id);
    setDetailButtonsValue([value]);
  };

  useEffect(() => {
    if (decideDropdownValues?.length > 0) {
      setDropdownType(decideDropdownValues[0].id);
      setDropdownTypeValue([decideDropdownValues[0].tab]);
      setSubDropdownValue([decideDropdownValues[0].name]);
    }
  }, [type]);

  const handleSnapshotSelection = event => {
    const {value, checked} = event.target;
    const itemObj = JSON.parse(value);

    if (itemObj.id === 'All') {
      //   setCategoriesName(['All']);
      if (!checked) {
        setCategoriesArr([]);
      } else {
        let categories = arrayColumn(snapshotCategories, 'id');
        setCategoriesArr(categories);
      }
    } else {
      if (checked) {
        // setCategoriesName(prev => [...prev, itemObj.title]);
        setCategoriesArr(prev => [...prev, itemObj.id]);
      } else {
        // setCategoriesName(prev =>
        //   prev.filter(title => title !== itemObj.title),
        // );
        setCategoriesArr(prev => prev.filter(id => id !== itemObj.id));
      }
    }
  };

  useEffect(() => {
    if (props.fromPage) {
      console.log( props.userId,' props.userId  props.userId  props.userId ')
      const dropdownMenu =customerMenu 
        // props.userId === localStorage.getItem('id')
        //   ? customerMenu
          // : props.userData?.user_role === 'Customer'
          // ? customerMenu
          // : props.userData?.user_role !== 'Customer' &&
          //   localStorage.getItem('userRole_in_company') !== 'Customer'
          // ? employeeToEmployeeMenu
          // : employeeMenu;

      if (props.type === 'market') {
        setDropdownMenu(dropdownMenu.slice(0, -1));
      } else {
        setDropdownMenu(dropdownMenu);
      }
    }
  }, []);

  useEffect(() => {
    // const decideSubDropdown =
  }, []);

  // useEffect(() => {
  //   if (
  //     categoriesArr.length === 0 ||
  //     categoriesArr.length === snapshotCategories.length
  //   ) {
  //     setCategoriesName(['All']);
  //   } else {
  //     const filterData = snapshotCategories.filter(item =>
  //       categoriesArr.includes(item.id),
  //     );
  //     setCategoriesName(arrayColumn(filterData, 'category_name'));
  //   }
  // }, [categoriesArr]);
  const getUserInvoices = type => {
    //props.fromPage
    var payload = {};
    if (props.fromPage == 'business') {
      payload = {
        search_query: '',
        status: paymentType,
      };
      commonApi
        .get_company_invoices(payload)
        .then(res => {
          if (res.status === 200) {
            if (res.data) {
              setInvoices(res.data);
            }
          }
        })
        .catch(err => {
          return {type: 'error', message: err.message};
        });
    } else {
      if (props.userId === localStorage.getItem('id')) {
        payload = {
          invoice_type: 'invoice',
          sub_type: '',
          search_query: '',
          list_type: props.selectedPaymentFilter,
        };
      } else {
        payload = {
          invoice_type: 'invoice',
          sub_type: '',
          search_query: '',
          list_type: props.selectedPaymentFilter,
          sender_id: props.userId,
        };
      }

      commonApi
        .get_user_invoices(payload)
        .then(res => {
          if (res.status === 200) {
            if (res.data) {
              setInvoices(res.data);
            }
          }
        })
        .catch(err => {
          return {type: 'error', message: err.message};
        });
    }
  };
  const getUserInvoicesFilter = type => {
    //props.fromPage
    var payload = {};
    if (props.fromPage == 'business') {
      payload = {
        search_query: '',
        status: type,
      };
      commonApi
        .get_company_invoices(payload)
        .then(res => {
          if (res.status === 200) {
            if (res.data) {
              setInvoices(res.data);
            }
          }
        })
        .catch(err => {
          return {type: 'error', message: err.message};
        });
    } else {
      if (props.userId === localStorage.getItem('id')) {
        payload = {
          invoice_type: 'invoice',
          sub_type: '',
          search_query: '',
          list_type: 'to_me',
        };
      } else {
        payload = {
          invoice_type: 'invoice',
          sub_type: '',
          search_query: '',
          list_type: 'to_me',
          sender_id: props.userId,
          status: type,
        };
      }

      commonApi
        .get_user_invoices(payload)
        .then(res => {
          if (res.status === 200) {
            if (res.data) {
              setInvoices(res.data);
            }
          }
        })
        .catch(err => {
          return {type: 'error', message: err.message};
        });
    }
  };
  const getSnapshotData = (cat = '') => {
    var data = {
      user_id: props.companyData ? '' : props.userId,
      categories: cat,
      company_id: props.companyData
        ? props.companyData.id
        : activeCompany[0]?.id
        ? activeCompany[0].id
        : '',
    };
    commonApi
      .get_accomplishment(data)
      .then(res => {
        if (res.status === 200) {
          setSnapshotData(res.accomplishment);
          // setShowSnapshot(true);
          setshowLoader(false);
        }
        // setShowSpinner(false);
      })
      .catch(err => {
        return {type: 'error', message: err.message};
      });
  };
  // const getReferrals = async (type, qry, arrow, pageN) => {
  //   // setShowSpinner(true);
  //   var data = {
  //     builder_filter_data: {
  //       allies: 'no',
  //       appointmentTypes: '',
  //       backgrounds: '',
  //       characteristics: [],
  //       homeLatitude: '',
  //       homeLongitude: '',
  //       homeTown: '',
  //       travelLatitude: '',
  //       travelLocation: '',
  //       travelLongitude: '',
  //     },
  //     category_ids: '',
  //     opportunity_filter_data: {
  //       address: '',
  //       allies: '',
  //       amount: '',
  //       frequencyIds: '',
  //       latitude: '',
  //       longitude: '',
  //       opportunityIds: '',
  //       vaccinated: '',
  //       virtual: 'virtual',
  //       // "search_query": ""
  //     },

  //     page: '',
  //     referred_by_me: '',
  //     search_query: '',
  //     //  type: 'all',
  //     type: '',
  //   };

  //   const response = await dispatch(getReferralListing(data));

  //   if (response) {
  //     // if (pageN > 1) {
  //     //   if (response.data.length > 0) {
  //     //     setPage(pageN);
  //     //   }
  //     // } else {
  //     //   setPage(1);
  //     // }

  //     // // setShowSpinner(false);
  //     // setTimeout(() => {
  //     //   setShowSpinner(false);
  //     // }, 1000);
  //   }
  // };

  // useEffect(()=>{
  //   getReferrals()
  // },[])

  // let tabs = [];

  const getUserData = () => {
    const data = {user_id: props.userId};
    commonApi
      .get_user_sess_opp_accom(data)
      .then(response => {
        const updatedTabs = [...tabs]; // Create a new array from the existing tabs
        if (response.exist_accomplishment) {
          // alert('ll');
          // setTabs(['Snapshots','Upcoming','Opportunities']);
          updatedTabs.push('Snapshots');
        }
        if (response.exist_session) {
          // alert('ll1');
          // updatedTabs.push('Upcoming');
        }
        if (response.exist_opportunity) {
          // alert('ll2');
          // updatedTabs.push('Opportunities');
        }

        // Set the tabs array to the updatedTabs
        setTabs(updatedTabs);

        // if (updatedTabs.includes('Snapshots')) {
        //   setGalleryType('Snapshots');
        // } else {
        //   if (updatedTabs.includes('Upcoming')) {
        //     setGalleryType('Upcoming');
        //   } else {
        //     if (updatedTabs.includes('Opportunities')) {
        //       setGalleryType('Opportunities');
        //     }
        //   }
        // }

        // Now you can use the updatedTabs array for your logic

        // Continue with your logic here
      })
      .catch(err => {
        return {type: 'error', message: err.message};
      });
  };

  const assigneDetail = item => {
    // const userId = localStorage.getItem('id');
    // const check = item.watchers_detail.some(obj => obj.id === userId);
    // return item.created_by === userId && item.assigne !== '0' ? (
    //   <p className="p-reg m-0 actionsCategories single-line-text">
    //     From you to{' '}
    //     {fixName(item.assigne_detail.first_name, item.assigne_detail.last_name)}
    //   </p>
    // ) : item.assigne === userId || check ? (
    //   <p className="p-reg m-0 actionsCategories single-line-text">
    //     For you from{' '}
    //     {fixName(item.creator_detail.first_name, item.creator_detail.last_name)}
    //   </p>
    // ) : (
    //   <></>
    // );
  };

  const getUpcommingData = (type = 'mine') => {
    // return false;
    var data = {
      user_id: props.userId,
      type: type,
    };
    commonApi
      .get_user_sessions(data)
      .then(res => {
        if (res.status === 200) {
          setUpcommingData(res.user_session);
          // setShowUpcomming(true);
          setTimeout(() => {
            setshowLoader(false);
          }, 500);
        }
      })
      .catch(err => {
        return {type: 'error', message: err.message};
      });
  };
  const getOpportunityData = async (type = 'mine') => {
    // return false;
    // setshowLoader(true);
    var data = {
      user_id: props.userId,
      type: type,
    };
    dispatch(getOpportunityListingProfile(data));

    setTimeout(() => {
      setshowLoader(false);
    }, 500);

    // setOpportunityData()
  };

  // useEffect(() => {
  //   setGallery(userSnapshots);
  //   setOppos(opportunityData);
  //   setSesList(userUpcomming);
  //   setIsNewSession(props.isNewSession);

  // }, [props.userId]);

  useEffect(() => {
    // getSnapshotData();
    if (localStorage.getItem('id') === props.userId) {
      setGalleryType('general');
    } else {
      // getUserData();
    }

    // getOpportunityData();
    // getUpcommingData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.userId, addNewSnapshot, props.companyData]);

  useEffect(() => {
    if (!props.fromPage) {
      getUserDataExist();
    }
  }, [props.userData]);

  const handle = item => {
    setReferTypeIds(item.refer_type_id);
    // setShowComponent('sendRefer');
    handleMenu('sendRefer', item, arrowButton);
    setUserData(item);
    setAccomplishmentId(item.accomplishment_category_id);
    if (item.accomplishment_category_id !== undefined) {
      detailAccomplishment(item);
    }
  };

  const eventHandle = item => {
    setEventItem(item);
    // setShowComponent('event');
    handleMenu('referEvent', item);
  };

  const detailAccomplishment = item => {
    var payLoad = {
      accomplishment_id: item.refer_type_id,
    };
    commonApi
      .detail_accomplishment(payLoad)
      .then(res => {
        if (res.status === 200) {
          // setImages(res.accomplishment_detail.images);
          setData(res.accomplishment_detail);

          setShowComponent('snapshot');
        }
        // setShowSpinner(false);
      })
      .catch(err => {
        return {type: 'error', message: err.message};
      });
  };

  const breakDown = item => {
    setReferSession(item);
    // setShowComponent('session');
    handleMenu('referSession', item);
  };

  const joinTheSession = async (item, type) => {
    var CheckLastSession = '';
    const getId = type === 'meetups' ? item.id : item.refer_type_id;

    if (CheckLastSession && CheckLastSession === getId) {
      let payload = {conference_id: getId};
      const response = await API.is_session_started(payload);

      if (response.started) {
        if (response.session_id) {
          // localStorage.setItem('conferenceAlias', response.conferenceAlias);
          // localStorage.setItem('conferenceId', response.session_id);
          // localStorage.setItem('conf_id', getId);
          // history.push('/session-details');
          goToZoom(response.session_id);
        } else {
        }
        if (item.creator_id === getItem('id')) {
          amplitudeEvent('START_SESSION');
        } else {
          amplitudeEvent('JOIN_SESSION');
        }
      } else {
        errorMessage(response.message);
      }
    }
    if (!CheckLastSession) {
      let payload = {conference_id: getId};
      const response = await API.is_session_started(payload);

      if (response.started) {
        if (response.session_id) {
          // localStorage.setItem('conferenceAlias', response.conferenceAlias);
          // localStorage.setItem('conferenceId', response.session_id);
          // localStorage.setItem('conf_id', getId);
          // history.push('/session-details');
          goToZoom(response.session_id);
        } else {
        }
        if (item.creator_id === getItem('id')) {
          amplitudeEvent('START_SESSION');
        } else {
          amplitudeEvent('JOIN_SESSION');
        }
      } else {
        errorMessage(response.message);
      }
    }
    if (CheckLastSession && CheckLastSession !== getId) {
      errorMessage(
        'Your are already in a session ' +
          localStorage.getItem('session_started_name') +
          '.',
      );
    }
  };

  const getUserDataExist = () => {
    const data = {user_id: props.userId};
    commonApi
      .get_user_sess_opp_accom(data)
      .then(response => {
        let updatedMenu = [];

        const dropdownMenu = employeeMenuP;

        updatedMenu = [...dropdownMenu];
        props.userData?.schedules &&
          updatedMenu
            .push
            // {id: '6', name: 'Appointments', tab: 'appointment'}
            ();

        if (response.exist_session) {
          // updatedMenu.push({
          //   id: '7',
          //   name: 'Meetups',
          //   tab: 'meetup',
          // });
        }
        if (response.exist_opportunity) {
          // updatedMenu.push({id: '8', name: 'Recruitment', tab: 'recruitment'});
        }

        updatedMenu
          .push
          // {id: '9', name: 'Follow ups', tab: 'followUp'},
          // {id: '10', name: 'Task', tab: 'tasks'},
          // {id: '10', name: 'Review', tab: 'review'},
          ();

        if (
          localStorage.getItem('id') === props.userId &&
          (props.userData.pipeline_permission.create_pipeline ||
            props.userData.pipeline_permission.associate_pipeline ||
            props.userData.pipeline_permission.show_pipeline)
        ) {
          // updatedMenu.push({id: '11', name: 'Pipeline', tab: 'pipeline'});
        }
        if (
          localStorage.getItem('id') !== props.userId &&
          (props.userData.deals_permission.create ||
            props.userData.deals_permission.read)
        ) {
          // updatedMenu.push({id: '12', name: 'Deals', tab: 'deals'});
        }

        if (
          localStorage.getItem('id') !== props.userId &&
          props.userData.data.linked_user_detail &&
          props.userData.data.linked_user_detail.user_type === 'business' &&
          props.userData.data.is_invited_by_me &&
          props.userData.data.is_invited_by_me === true
          // &&
          // props.userData.data.linked_user_detail.linked_users_poc &&
          // Object.entries(
          //   props.userData.data.linked_user_detail.linked_users_poc,
          // ).length > 0
        ) {
          updatedMenu.push({id: '13', name: 'POCs', tab: 'poc'});
          setPocData(props.userData.data.linked_user_detail.linked_users_poc);
        }
        if (dropdownMenu.length > 1) {
          if (type !== 'deals' && type !== 'poc') {
            setType(props.snapShotRefer === true ? 'credentials' : 'overview');
          }
          if (
            props.userData.data.linked_user_detail.user_type !== 'business' &&
            type === 'poc'
          ) {
            setType(props.snapShotRefer === true ? 'credentials' : 'overview');
          }
        }
        setDropdownMenu(updatedMenu);
        props.setshowLoader && props.setshowLoader(false);
      })
      .catch(err => {
        return {type: 'error', message: err.message};
      });
  };

  useEffect(() => {
    if (type === 'credentials') {
      getSnapshotData(props.filterCat);
    } else if (galleryType === 'Upcoming') {
      if (allMine === 'all') {
        // getUpcommingData(upcomingFilter);
      } else {
        // getUpcommingData(allMine);
      }
    } else if (galleryType === 'Opportunities') {
      if (allMine === 'all') {
        // getOpportunityData(opportunityFilter);
      } else {
        // getOpportunityData(allMine);
      }
    }

    // getOpportunityData();
    // getUpcommingData();
    // active={galleryType}
    // tabs={['Snapshots', 'Upcoming', 'Opportunities']}
    //

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    galleryType,
    props.showComponentOpp,
    props.showComponent,
    tabs,
    props.filterCat,
    props.companyData,
  ]);

  useEffect(() => {
    // getOpportunityData(allMine);
    // getUpcommingData(allMine);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.userId]);

  // UNSAFE_componentWillMount = () => {
  //     this.setState({
  //         gallery: this.props.gallery,
  //         oppos: this.props.oppos,
  //         sesList: this.props.sesList,
  //         isNewSession: this.props.isNewSession,
  //     });
  // };

  //    const  componentDidUpdate=(prevProps)=>{
  //         if (this.props.userId !== prevProps.userId) {
  //             this.setState({
  //                 gallery: this.props.gallery,
  //                 oppos: this.props.oppos,
  //                 sesList: this.props.sesList,
  //                 isNewSession: this.props.isNewSession,
  //             });
  //         }
  //     }

  const setGalleryTypeee = type => {
    setGalleryType(type);
  };

  // const showLightbox = (imagez, current) => {
  //

  //     const tempImgs = [];

  //     for (let i = 0; i < imagez.length; i++) {
  //         tempImgs.push({
  //             id: i,
  //             image: imagez[i].images[0],
  //         });
  //     }

  //     this.setState({
  //         show: true,
  //         popupImages: tempImgs,
  //         current,
  //     });
  // };

  const handleClickLink = link => {
    // perform some action

    // navigate to a specific page using history object
    history.push(link);
  };

  const _renderCreatorImg = person => {
    return (
      <div className="img-item">
        {
          <span className="owner-icon">
            <i className="fas fa-crown" />
          </span>
        }
        <img
          src={person.creator_img === '' ? DEFAULT_IMAGE : person.creator_img}
          alt=""
        />
      </div>
    );
  };
  const _renderAudiImg = person => {
    return (
      <div key={`person-id-${person.id}`} className="img-item">
        <img
          src={person.profile_img === '' ? DEFAULT_IMAGE : person.profile_img}
          alt=""
        />
      </div>
    );
  };

  useEffect(() => {
    if (dropdownTypeValue.includes('payment')) {
      getUserInvoices();
    }
    // getTabstatus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.selectedPaymentFilter, dropdownTypeValue]);

  useEffect(() => {
    if (type === 'recruitment') {
      getOpportunityData(allMine);
    } else if (type === 'meetup') {
      // getUpcommingData(allMine);
      // setTimeout(() => {
      //   setshowLoader(false);
      // }, 1500);
    } else if (type === 'credentials') {
      getSnapshotData();
    }
  }, [type]);

  // useEffect(() => {
  //  if(type ==='deals'){
  //   setType(type)
  //  }
  // }, [])

  const reanderImages = images => {
    return (
      <div className="gallery-imgs my-snapshots pb-5">
        <Row className="gallery-snapshot-row-holder profile-gallery-block">
          {Object.entries(images).length > 0 ? (
            Object.entries(images).length > 0 &&
            images.map((item, index) => {
              return (
                <Col md={4} sm={4} key={`gallery-img-${index}`}>
                  <div
                    className="gallery-item"
                    onClick={() => {
                      props.getUserData && props.getUserData(item.created_by);
                      props.handleCommand(item.id, 'snapshot');
                      // this.showLightbox(images, index);
                    }}>
                    {/* <span className="zoom-icon">
                                            <i className="fas fa-magnifying-glass" />
                                        </span> */}
                    <img src={item.images[0]} alt="" />
                  </div>
                </Col>
              );
            })
          ) : props.userId === localStorage.getItem('user') ? (
            <div className="no-results-wrapper">
              <div className="img-bg-wrapper">
                <img
                  src={require('../../Assets/images/norecord/no-snapshot.png')}
                  alt=""
                />
              </div>

              <div className="no-results-content">
                {props.permission &&
                (props.permission === 'allow' ||
                  props.permission?.edit_company === true) ? (
                  <>
                    <h2>Manage Credentials</h2>
                    <p>
                      Create shareable one-pagers for your business(es),
                      affiliations and more
                    </p>
                  </>
                ) : (
                  <h2>No Credentials Found</h2>
                )}

                {props.permission &&
                  (props.permission === 'allow' ||
                    props.permission?.edit_company === true) && (
                    <div className="btn-wrapper mt-3">
                      <Button
                        className="btn btn-dark"
                        onClick={() => {
                          props.handleCommand('', 'addNewSnapshot');
                        }}>
                        Add Credentials
                      </Button>
                    </div>
                  )}
              </div>
            </div>
          ) : (
            <p className="text-center">No record found</p>
          )}
        </Row>
      </div>
    );
  };

  // const OpportunityItem = item => {
  //   const heartClass = item.like ? 'fas' : 'far';
  //   return (
  //     <div
  //       // onClick={() => {
  //       //     props.handleCommand(item, "opportunity");
  //       // }}
  //       className="opportunity-item card">
  //       <h2>{item.title}</h2>
  //       <div className="oppo-share-wrapper">
  //         <span className="category">{item.opportunity_type_name}</span>
  //         <span className="oppo-share-icon">
  //           <i className="fas fa-share" />
  //         </span>
  //       </div>

  //       <RenderHTMLBlock className="oppo-desc" content={item.description} />

  //       <div className="oppo-address">
  //         <span className="address">
  //           <i className="icon-map-pin" /> {item.address}
  //         </span>
  //         <span className="injeaction-text">
  //           <i className="icon-injection" />{' '}
  //           {labels.partnership_text[selected_lang]}
  //           <b>
  //             {item.amount &&
  //               formatAmount(item.amount ? item.amount : '') + '/hr'}
  //           </b>
  //         </span>
  //         <span className="hearticon">
  //           <i className={`${heartClass} fa-heart`} />
  //         </span>
  //       </div>
  //     </div>
  //   );
  // };

  const SessionItem = item => {
    var totalMem = item.total_members + 1;
    var totalMode = item.total_moderators + 1;
    return (
      <div
        onClick={() => {
          props.handleCommand(item, 'session');
        }}
        className="session-item card as-link">
        <div className="session-heading">
          <h2>{item.session_title}</h2>
          <span className="users-text">
            <i className="fas fa-circle-user" /> {`${totalMem}/${totalMode}`}
          </span>
          <span className="category-text">{item.category_name}</span>
        </div>

        <div className="persons-img-list">
          {item && _renderCreatorImg(item)}
          {item.audience_ids &&
            item.audience_list &&
            Object.entries(item.audience_list).length > 0 &&
            item.audience_list.map((person, index) => _renderAudiImg(person))}
        </div>
        <div className="persons-name-list">
          <ul>
            {
              <li key={`person-name-creator`}>
                {
                  <span className="owner-icon">
                    <i className="fas fa-crown" />
                  </span>
                }
                {fixName(item.creator_first_name, item.creator_last_name)}
              </li>
            }
            {item.audience_ids &&
              item.audience_list &&
              Object.entries(item.audience_list).length > 0 &&
              item.audience_list.map((person, index) => (
                <li key={`person-name-${index}`}>
                  {/* {person.owner && (
                                        <span className="owner-icon">
                                            <i className="fas fa-crown" />
                                        </span>
                                    )}
                                    {!person.owner && person.speaker && (
                                        <span className="speaker-icon">
                                            <i className="fas fa-microphone" />
                                        </span>
                                    )} */}
                  {fixName(person.first_name, person.last_name)}
                </li>
              ))}
          </ul>
          {/* <ul>
                        {Object.entries(item.persons).length > 0 &&
                            item.persons.map((person, index) => (
                                <li key={`person-name-${index}`}>
                                    {person.owner && (
                                        <span className="owner-icon">
                                            <i className="fas fa-crown" />
                                        </span>
                                    )}
                                    {!person.owner && person.speaker && (
                                        <span className="speaker-icon">
                                            <i className="fas fa-microphone" />
                                        </span>
                                    )}
                                    {person.name}
                                </li>
                            ))}
                    </ul> */}
        </div>
        {item.address && (
          <div className="person-address-place" style={{fontSize: 15}}>
            <i className="fas fa-location-dot" /> {item.address}
          </div>
        )}
      </div>
    );
  };
  const OnselectValue = eventKey => {
    setType(eventKey);
    if (props.setSelectDropdown) {
      props.setSelectDropdown(eventKey);
    }
    if (eventKey == 'payment') {
      getUserInvoices();
    }
  };
  const getTabstatus = () => {
    commonApi
      .get_tab_status()
      .then(res => {
        // setshowLoader(false);
        // setShowSpinner(false);
        //
        if (res.status === 200) {
          // setShowComponent('firstVist');
          const item = res.tab_status;
          if (item.length === 0) {
            setShowComponent('firstVist');
          } else {
            const newArray = [];

            if (Object.entries(item).length > 0) {
              item.forEach((item, index) => {
                newArray.push(item.tab);
              });
            }
            const found = newArray.find(element => element === 'snapshot');
            if (found === 'snapshot') {
              // setShowComponent('');
            } else {
              if (props.userId === localStorage.getItem('id')) {
                setShowComponent('firstVist');
              }
            }
          }
        }
      })
      .catch(err => {
        return {type: 'error', message: err.message};
      });
  };

  // const generateTabs = () => {
  //   if (
  //     userSnapshots.length > 0 &&
  //     userUpcomming.length > 0 &&
  //     opportunityData.length > 0
  //   ) {
  //     return ['Snapshots', 'Upcoming', 'Opportunities'];
  //   } else if (userSnapshots.length > 0 && userUpcomming.length > 0) {
  //     return ['Snapshots', 'Upcoming'];
  //   } else if (userSnapshots.length > 0 && opportunityData.length > 0) {
  //     return ['Snapshots', 'Opportunities'];
  //   } else if (userUpcomming.length > 0 && opportunityData.length > 0) {
  //     return ['Upcoming', 'Opportunities'];
  //   } else if (userSnapshots.length > 0) {
  //     return ['Snapshots'];
  //   } else if (userUpcomming.length > 0) {
  //     return ['Upcoming'];
  //   } else if (opportunityData.length > 0) {
  //     return ['Opportunities'];
  //   } else {
  //     return []; // No tabs when none of the arrays have length
  //   }
  // };

  useImperativeHandle(props.hitApiRef, () => ({
    getInvoice() {
      if (localStorage.getItem('id') === props.userId) {
        return getUserInvoices();
      }
      if (type === 'overview') {
        return props.getUserData && props.getUserData();
      }
      if (dropdownTypeValue.includes('payment')) {
        getUserInvoicesFilter('unpaid');
      }
    },

    getFollowUps() {
      hitApiRef.current.getFollowUps();
    },
    getMessages() {
      return get_users_chats_new();
    },
    getTasks() {
      hitApiRef.current.getTasks();
    },

    getCompanyInvoice() {
      getUserInvoices();
    },
  }));

  const handleToggle = isOpen => {
    setOpen(isOpen);
  };

  const lines =
    props.parentCompoent === 'widgets'
      ? props.userData.data.bio
      : props.userData?.data?.bio?.split('\n');

  const handleMenu = (type, data, item) => {
    const hasDeals = decideDropdownValues.some(item => item.id === 'Deals');

    if (type === 'calendar') {
      props.handleBlock(props.userData?.data, 'appointment');
    } else if (type === 'check') {
      props.handleBlock('', 'tasks');
    } else if (type === 'dollar') {
      props.handleBlock('', 'invoice');
    } else if (type === 'fields') {
      props.handleBlock(props.userData?.data, 'profile-notes');
    } else if (type === 'messages') {
      props.handleBlock('', 'messages', data);
    } else if (type === 'create_group') {
      props.handleBlock('', 'create_group');
    } else if (type === 'project_listing') {
      props.handleBlock('', 'project_listing', data);
    } else if (type === 'create_Project' || type === 'createProject') {
      props.handleBlock('', 'create_Project');
    } else if (type === 'share') {
      props.handleBlock('', 'share');
    } else if (type === 'create_profile_session') {
      props.handleBlock('', 'create_profile_session');
    } else if (type === 'createTransform') {
      props.handleBlock('', 'createTransform');
    } else if (type === 'pipeline' && hasDeals) {
      props.handleCommand('', 'createDeal');
    } else if (type === 'pipeline' && !hasDeals) {
      props.handleBlock('', 'pipeline');
    } else if (type === 'call_center') {
      props.handleBlock('', 'call_center');
    } else if (type === 'detail_Project') {
      props.handleBlock('', 'detail_Project', data, item);
    } else if (type === 'sendRefer') {
      props.handleBlock('', 'sendRefer', data, item);
    } else if (type === 'create_appointment') {
      props.handleBlock('', 'create_appointment', data);
    } else if (type === 'referSession') {
      props.handleBlock('', 'referSession', data);
    } else if (type === 'referEvent') {
      props.handleBlock('', 'referEvent', data, arrowButton);
    } else if (type === 'Notes') {
      props.handleBlock('', 'Notes', data);
    } else if (type === 'referFilter') {
      props.handleBlock('', 'referFilter', arrowButton);
    } else if (type === 'session' || type === 'event') {
      props.handleCommand(data, 'session');
    } else if (type === 'filterMessages') {
      props.handleBlock('', 'filterMessages');
    } else if (type === 'create_recruitment') {
      props.handleCommand('', 'create_recruitment');
    } else if (type === 'opportunity') {
      props.handleBlock('', 'refer_opportunity', data, arrowButton);
    } else if (type === 'create_Portfolio') {
      // props.handleCommand('', 'create_Portfolio');
      props.handleBlock('', 'create_Portfolio');
    } else if (type === 'edit_portfolio') {
      props.handleBlock('', 'edit_portfolio', data);
    }
  };

  const handleOpenChatRoom = item => {
    handleMenu('messages', item);
  };

  const [groupedTasks, setGroupedTasks] = useState({});

  // Group tasks by phase_title when component mounts or data changes
  useEffect(() => {
    const groupByPhase = props?.userData?.tasks.reduce((acc, task) => {
      // Check if phase already exists in groupedTasks
      if (!acc[task.phase_title]) {
        acc[task.phase_title] = [];
      }
      acc[task.phase_title].push(task);
      return acc;
    }, {});
    setGroupedTasks(groupByPhase);
  }, [props?.userData?.tasks]);

  return (
    <>
      {showComponent === 'firstVist' &&
        props.userId === localStorage.getItem('id') && (
          <OpportunityScreen
            onClose={() => {
              setShowComponent('');
            }}
            type={'snapshot'}
            setAddNewSnapshot={setAddNewSnapshot}
            handleSnapshot={() => {
              props.handleCommand('', 'addNewSnapshot');
            }}
          />
        )}

      <div
        className={`profile-gallery-wrapper menus_gallery my-menus-gallery ${
          props.userData ? 'profile-gallery' : ''
        }`}>
        {showComponent !== 'firstVist' && (
          <>
            {/* {showSpinner && <LoaderSpinner />} */}

            <div className="">
              <>
                <div
                  className="dropdown-search-icon d-flex mb-2 gallery_dropdown"
                  style={{marginLeft: '0.15rem'}}>
                  <div
                    className="select-wrapper"
                    onClick={() => {
                      handleToggle(!open);
                    }}>
                    <Dropdown
                      show={open}
                      onToggle={handleToggle}
                      onSelect={(eventKey, event) => {
                        props.setSessionFilterValue &&
                          props.setSessionFilterValue('all');
                        OnselectValue(eventKey);
                        if (
                          eventKey === 'credentials' ||
                          eventKey === 'recruitment' ||
                          eventKey === 'meetup'
                        ) {
                          setshowLoader(true);
                        }
                      }}
                      drop="down">
                      <Dropdown.Toggle variant="primary">
                        {type
                          ? dropdownMenu.find(item => item.tab === type)?.name
                          : ' '}
                        <i />
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        {dropdownMenu.map((item, index) => (
                          <Dropdown.Item
                            key={`st-${index}`}
                            eventKey={item.tab}
                            active={item.tab === type}>
                            {item.name}
                          </Dropdown.Item>
                        ))}
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                  {dropdownTypeValue.includes('project management') &&
                    type === 'overview' &&
                    localStorage.getItem('id') !== props.userId && (
                      <div className="search-tabs-icons">
                        <span
                          className={
                            props.secondaryComponent === 'createProject'
                              ? 'icon active'
                              : 'icon'
                          }>
                          <i
                            className="icon-plus"
                            onClick={() => props.handleProject('createProject')}
                          />
                        </span>
                      </div>
                    )}

                  <GalleryIcons
                    userId={props.userId}
                    galleryType={galleryType}
                    type={type}
                    profileGalleryType={props.type ? props.type : null}
                    handleCommand={props.handleCommand}
                    handleBlock={props.handleBlock}
                    userData={props.userData}
                    setThreeDots={setThreeDots}
                    threeDots={threeDots}
                    permission={props.permission ? props.permission : null}
                    fromPage={props.fromPage}
                  />

                  {(type === 'credentials' ||
                    (dropdownTypeValue.includes('payment') &&
                      props.fromPage !== 'business')) && (
                    <div className="filter-icon filter-profile-gallery-icon gallery-custom-filter-new-icon">
                      <Button className="gal-custom-filter-btn">
                        <i
                          className="icon-filter-icon"
                          onClick={() => {
                            // setShowSnapshotModal(true);
                            if (type === 'credentials') {
                              props.handleCommand('', 'snapshot_filter');
                            } else if (dropdownTypeValue.includes('payment')) {
                              props.handleCommand('', 'payment_filter');
                            }
                          }}
                        />
                      </Button>
                    </div>
                  )}
                </div>
                {(type === 'overview' || type === 'employment' || type === 'contacts' || type === 'Detail') && threeDots && 
                <div
                  className="dropdown-search-icon d-flex mb-2 gallery_dropdown"
                  style={{marginLeft: '0.15rem'}}>
                  <div
                    className="select-wrapper">
                    <Dropdown
                      onSelect={(eventKey, event) => {
                        const selectedItem = decideDropdownValues.find(
                          item => item.tab === eventKey,
                        );
                        if (selectedItem) {
                          setDropdownType(selectedItem.id); // Ensure dropdownType is an array
                          setDropdownTypeValue([selectedItem.tab]); // Store name as an array
                          setSubDropdownValue([selectedItem.name]);
                        }
                      }}
                      drop="down">
                      <Dropdown.Toggle variant="primary">
                        {dropdownType.length > 0
                          ? capitalizeFirst(subDropdownValue[0])
                          : 'Select an option'}
                        <i />
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        {decideDropdownValues?.map((item, index) => (
                          <Dropdown.Item
                            key={`st-${index}`}
                            eventKey={item.tab}
                            active={dropdownTypeValue.includes(item.tab)}>
                            {item.name}
                          </Dropdown.Item>
                        ))}
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </div>
}
                {/* {type === 'overview' && threeDots && (
                  <div
                    className="category-btns-wrapper category-btns-wrapper-slider"
                    style={{marginTop: '8px'}}>
                    <CustomSlidingButtons
                      items={decideDropdownValues}
                      activeButtons={dropdownType}
                      onItemClick={e => {
                        handleClickButtons(e);
                      }}
                      showItems={2}
                      label={'name'}
                      value={dropdownTypeValue}
                    />
                  </div>
                )} */}
                {/* {type === 'Detail' && threeDots && (
                  <div
                    className="category-btns-wrapper category-btns-wrapper-slider"
                    style={{marginTop: '8px'}}>
                    <CustomSlidingButtons
                      items={detailButtons}
                      activeButtons={detailButtonsId}
                      onItemClick={e => {
                        handleDetailDropdownButtons(e);
                      }}
                      showItems={2}
                      label={'name'}
                      value={detailButtonsValue}
                    />
                  </div>
                )} */}

                <div className={`refferal-list-wrapper refferal-business-list-wrapper ${dropdownTypeValue.includes('availability')  && 'bg-white'}`}>
                  {showLoader && (
                    <div className="loader_hieght">
                      {showLoader && <LoaderSpinner />}
                    </div>
                  )}

                  {!showLoader && (
                    <>
                      {galleryType === 'Upcoming' &&
                        props.userId === localStorage.getItem('id') && (
                          <div className="fieldset mt-3">
                            <div className="select-wrapper ">
                              <select
                                className="form-control"
                                value={upcomingFilter}
                                onChange={e => {
                                  setUpcomingFilter(e.target.value);
                                  getUpcommingData(e.target.value);
                                }}>
                                {['all', 'mine', 'others'].map(
                                  (item, index) => (
                                    <option
                                      value={item}
                                      key={`sesCate-${index}`}>
                                      {capitalizeFirst(item)}
                                    </option>
                                  ),
                                )}
                              </select>
                            </div>
                          </div>
                        )}

                      {galleryType === 'Opportunities' &&
                        props.userId === localStorage.getItem('id') && (
                          <div className="fieldset mt-3">
                            <div className="select-wrapper ">
                              <select
                                className="form-control"
                                value={opportunityFilter}
                                onChange={e => {
                                  setOpportunityFilter(e.target.value);
                                  getOpportunityData(e.target.value);
                                }}>
                                {['all', 'mine', 'others'].map(
                                  (item, index) => (
                                    <option
                                      value={item}
                                      key={`oppoCate-${index}`}>
                                      {capitalizeFirst(item)}
                                    </option>
                                  ),
                                )}
                              </select>
                            </div>
                          </div>
                        )}

                      <div className="img-gallery-wrapper px-0">
                        {dropdownTypeValue.includes('recent') &&
                          type === 'overview' && (
                            <>
                              {props.userData?.tasks.length > 0 && (
                                <>
                                  <div className="d-flex align-items-center phases_item mt-2 icon-alias-holder">
                                    <div
                                      style={{background: 'transparent'}}
                                      className="customer_name flex-1">
                                      <div className="alias_name">
                                        <h5>Tasks</h5>
                                      </div>
                                    </div>
                                  </div>
                                  <div style={{margin: '8px 12px'}}>
                                    {groupedTasks &&
                                      Object.keys(groupedTasks)?.length > 0 &&
                                      Object.keys(groupedTasks).map(
                                        (phase, index) => (
                                          <div key={index}>
                                            <h2 style={{marginBottom: '6px'}}>
                                              {phase}
                                            </h2>
                                            {groupedTasks[phase]?.length > 0 &&
                                              groupedTasks[phase].map(
                                                (item, index) => (
                                                  <div
                                                    key={index}
                                                    className="refferals-list action-listing as-link"
                                                    style={{
                                                      border:
                                                        '1px solid #f1eded',
                                                    }}
                                                    onClick={() => {
                                                      props.handleCommand(
                                                        item,
                                                        'followUp',
                                                      );
                                                    }}>
                                                    <div className="task-item">
                                                      <h2 className="truncate_2_lines actions-title mb-2">
                                                        {item.title}
                                                      </h2>
                                                      <div className="d-flex align-items-center session-cam mb-2">
                                                        <p className="p-reg m-0 single-line-text p-txt-area">
                                                          {capitalizeFirst(
                                                            item.priority,
                                                          )}
                                                        </p>
                                                      </div>
                                                      <p className="p-reg m-0 mb-2">
                                                        {capitalizeFirst(
                                                          item.status,
                                                        )}
                                                      </p>
                                                      <div
                                                        className="d-flex align-items-center justify-content-between"
                                                        style={{
                                                          alignItems: 'center',
                                                        }}>
                                                        <div className="d-flex align-items-center">
                                                          <p className="p-reg m-0 actionsCategories single-line-text">
                                                            {assigneDetail(
                                                              item,
                                                            )}
                                                          </p>
                                                        </div>

                                                        <div className="arrow-up"></div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                ),
                                              )}
                                          </div>
                                        ),
                                      )}
                                  </div>
                                </>
                              )}

                              {/* {props.userData?.notes_detail.length > 0 && (
                                <>
                                  <div className="d-flex align-items-center phases_item mt-2 icon-alias-holder">
                                    <div
                                      style={{background: 'transparent'}}
                                      className="customer_name flex-1">
                                      <div className="alias_name as-link">
                                        <h5>Notes</h5>
                                      </div>
                                    </div>
                                  </div>

                                  <ProfileNotesListing
                                    userData={props.userData?.notes_detail}
                                    type="otherProfile"
                                  />
                                </>
                              )} */}

                              {props.userData?.chat_details.length > 0 && (
                                <>
                                  <div className="d-flex align-items-center phases_item mt-2 icon-alias-holder">
                                    <div
                                      style={{background: 'transparent'}}
                                      className="customer_name flex-1">
                                      <div className="alias_name">
                                        <h5>Messages</h5>
                                      </div>
                                    </div>
                                  </div>
                                  {props.userData?.chat_details.map(
                                    (item, index) => {
                                      const newMessage = item.read
                                        ? ''
                                        : 'new-message';
                                      const userIs =
                                        parseInt(item.creator_id) ===
                                        parseInt(localStorage.getItem('user'))
                                          ? 'user_detail'
                                          : item.hasOwnProperty(
                                              'creator_details',
                                            )
                                          ? 'creator_details'
                                          : 'creator_detail';
                                      return (
                                        <div
                                          style={{
                                            margin: '8px 12px',
                                            backgroundColor: '#fff',
                                            border:
                                              '1px solid rgb(241, 237, 237)',
                                            borderRadius: '8px',
                                          }}
                                          className="messages-users-list h-auto custom-messages-users-list-holder"
                                          key={index}>
                                          <div
                                            onClick={() =>
                                              handleMenu('messages', item)
                                            }
                                            className={`message-user-item no-link likes-dislikes-msgs ${newMessage}`}>
                                            <div className="user-img as-link">
                                              <img
                                                src={
                                                  item.group_image !== ''
                                                    ? item.group_image
                                                    : item[userIs][
                                                        'profile_img'
                                                      ]
                                                    ? item[userIs][
                                                        'profile_img'
                                                      ]
                                                    : DEFAULT_IMAGE
                                                }
                                                alt=""
                                              />
                                            </div>
                                            <div className="user-content as-link">
                                              <h2 className="single-line-text">
                                                {item.group_name !== ''
                                                  ? item.group_name
                                                  : fixName(
                                                      item.first_name,
                                                      item.last_name,
                                                    )}
                                              </h2>
                                              <p className="single-line-text">
                                                {item.last_message &&
                                                  formatString(
                                                    item.last_message,
                                                  )}
                                              </p>
                                              <p className="single-line-text"></p>
                                              <div className="message-date-info">
                                                <span className="message-date">
                                                  <Moment format="MM/DD/YY HH:mm A">
                                                    {item.last_message_time
                                                      ? item.last_message_time
                                                      : item.created_at}
                                                  </Moment>
                                                </span>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      );
                                    },
                                  )}
                                </>
                              )}
                              {props.userData.appointment_detail && (
                                <div className="mb-4">
                                  <span className="fw-bold">Scheduler</span>
                                  <div>
                                    <AppointmentItem
                                      item={props.userData.appointment_detail}
                                      userData={props.userData}
                                      handleBlock={props.handleBlock}
                                      handleCommand={props.handleCommand}
                                    />
                                  </div>
                                </div>
                              )}

                              {props.userData?.projects.length > 0 && (
                                <>
                                  <div className="d-flex align-items-center phases_item mt-2 icon-alias-holder">
                                    <div
                                      style={{background: 'transparent'}}
                                      className="customer_name flex-1">
                                      <div className="alias_name">
                                        <h5>Projects</h5>
                                      </div>
                                    </div>
                                  </div>
                                  <ListingOfProjects
                                    projectListing={props.userData?.projects}
                                    handleProject={handleMenu}
                                  />
                                </>
                              )}
                              {props.userData.pending_pay && (
                                <div className="mb-4 mt-4">
                                  <span className="fw-bold">Payments</span>

                                  <div>
                                    <InvoiceItem
                                      item={props.userData.pending_pay}
                                      handleCommand={props.handleCommand}
                                    />
                                  </div>
                                </div>
                              )}

                              {props.userData?.sessions.length > 0 && (
                                <div className="mb-4">
                                  <div className="d-flex align-items-center phases_item mt-2 icon-alias-holder">
                                    <div
                                      style={{background: 'transparent'}}
                                      className="customer_name flex-1">
                                      <div className="alias_name">
                                        <h5>Meetups</h5>
                                      </div>
                                    </div>
                                  </div>
                                  {props?.userData?.sessions.length > 0 &&
                                    props?.userData?.sessions.map(
                                      (item, index) =>
                                        !item.image ? (
                                          <SesssionItem
                                            key={`session-item-${index}`}
                                            item={item}
                                            // heart={sessionType}
                                            componentDecesion={
                                              componentDecesion
                                            }
                                            serverTime={serverTime}
                                          />
                                        ) : (
                                          <EventsItem
                                            key={`event-item-${index}`}
                                            item={item}
                                            index={index}
                                            //  eventDetails={eventDetails}
                                            componentDecesion={
                                              componentDecesion
                                            }
                                          />
                                        ),
                                    )}
                                </div>
                              )}

                              {props?.userData?.call_details.length > 0 && (
                                <div className="mb-4">
                                  <span className="fw-bold">Calls</span>
                                  {props?.userData?.call_details.map(
                                    (item, index) => (
                                      <div className="events-item p-3 mb-2 rounded-md as-link">
                                        <div className="session-heading flex items-start">
                                          <div className="persons-img-list mb-0 flex items-center flex-1">
                                            <div className="img-item mb-0">
                                              <img
                                                src={
                                                  item.receiver_detail
                                                    ?.profile_img
                                                    ? item.receiver_detail
                                                        ?.profile_img
                                                    : DEFAULT_IMAGE
                                                }
                                                alt=""
                                                className="w-15 h-15 as-link rounded-full "
                                              />
                                            </div>
                                            <div className="flex-1">
                                              <div className="flex justify-between gap-0">
                                                <div className="m-1">
                                                  {' '}
                                                  {fixName(
                                                    item?.receiver_detail
                                                      ?.first_name,
                                                    item?.receiver_detail
                                                      ?.last_name,
                                                  )}
                                                </div>
                                                <div>
                                                  <SvgIconComponent
                                                    icon={'info'}
                                                  />
                                                </div>
                                              </div>
                                              <div className="flex justify-between items-center mt-1">
                                                <div>
                                                  {item?.receiver_detail
                                                    ?.user_role && (
                                                    <div>
                                                      {
                                                        item?.receiver_detail
                                                          ?.user_role
                                                      }
                                                    </div>
                                                  )}
                                                </div>
                                                <div className="flex justify-between gap-3">
                                                  <div>
                                                    <Moment format="H:mmA">
                                                      {item?.created_at}
                                                    </Moment>
                                                  </div>
                                                  <div>
                                                    <SvgIconComponent
                                                      icon={
                                                        item?.receiver_detail
                                                          ?.id ===
                                                        localStorage.getItem(
                                                          'id',
                                                        )
                                                          ? 'arrowDown'
                                                          : 'arrow'
                                                      }
                                                    />
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    ),
                                  )}
                                </div>
                              )}
                            </>
                          )}
                        {(dropdownTypeValue.includes('referalls') ||
                          dropdownTypeValue.includes('recent')) &&
                          type === 'overview' && (
                            <>
                              {referrelListing.length > 0 &&
                              dropdownTypeValue.includes('recent') ? (
                                <div className="d-flex align-items-center phases_item mt-2 icon-alias-holder">
                                  <div
                                    style={{background: 'transparent'}}
                                    className="customer_name flex-1">
                                    <div className="alias_name">
                                      <h5>Referrals</h5>
                                    </div>
                                  </div>
                                </div>
                              ) : (
                                dropdownTypeValue.includes('referalls') &&
                                threeDots && (
                                  <>
                                    <div className="d-flex justify-content-between align-items-center mb-3">
                                      <div className="event-tabs tabs-style-2 refferal-tabs-custom-style">
                                        <ul>
                                          <li>
                                            <Button
                                              className={
                                                arrowButton === false
                                                  ? 'active'
                                                  : ''
                                              }
                                              onClick={() => {
                                                setArrowButton(!arrowButton);
                                                // setShowSpinner(true);
                                                amplitudeEvent(
                                                  'VISIT_REFERRALS_RECIEVED',
                                                );
                                                // setShowComponent('');
                                              }}>
                                              Received
                                            </Button>
                                          </li>
                                          <li>
                                            <Button
                                              className={
                                                arrowButton === true
                                                  ? 'active'
                                                  : ''
                                              }
                                              onClick={() => {
                                                setArrowButton(!arrowButton);
                                                // setShowSpinner(true);
                                                amplitudeEvent(
                                                  'VISIT_REFERRALS_GIVEN',
                                                );
                                                // setShowComponent('');
                                              }}>
                                              Given
                                            </Button>
                                          </li>
                                        </ul>
                                      </div>
                                    </div>

                                    <div className="search-tabs-wrapper mt-1">
                                      <div className="search-form d-flex mt-0">
                                        <Form.Group className="search-input">
                                          <Form.Control
                                            onChange={e => {
                                              setSearchQry(e.target.value);
                                              // props.get_referrals(
                                              //   '',
                                              //   e.target.value,
                                              //   arrowButton,
                                              // );
                                            }}
                                            name="allUsers"
                                            type="text"
                                            //placeholder={'Search for a ' + subTabType}
                                            placeholder={'Search'}
                                            value={searchQry}
                                          />
                                        </Form.Group>

                                        <Button
                                          onClick={() => {
                                            // setshowFilter(true)
                                            handleMenu('referFilter');
                                          }}>
                                          <i className="icon-filter-icon" />
                                        </Button>
                                      </div>
                                    </div>
                                  </>
                                )
                              )}

                              {referrelListing && referrelListing?.length > 0
                                ? referrelListing?.map((item, index) => (
                                    <div className="" key={`temp_${index}`}>
                                      {subTabType === 'builders' &&
                                        item.referred_detail && (
                                          <div
                                            key={`ee-www-${item.id}`}
                                            className="refferals-list-item card qa-list-item">
                                            <div
                                              className="refferal-img as-link"
                                              onClick={() => {
                                                handle(item);
                                              }}>
                                              <img
                                                src={
                                                  item.referred_detail
                                                    .profile_img === ''
                                                    ? DEFAULT_IMAGE
                                                    : item.referred_detail
                                                        .profile_img
                                                }
                                                alt=""
                                              />
                                            </div>
                                            <div className="d-flex align-items-center referrals-dataa">
                                              <div className="refferal-content as-link w-100">
                                                {item.referred_detail && (
                                                  <h2
                                                    onClick={() => {
                                                      handle(item);
                                                    }}>
                                                    {fixName(
                                                      item.referred_detail
                                                        .first_name,
                                                      item.referred_detail
                                                        .last_name,
                                                    )}
                                                  </h2>
                                                )}
                                                <span className="refferal-auther as-link w-100">
                                                  {item.user_first_name
                                                    ? fixName(
                                                        item.user_first_name,
                                                        item.users_last_name,
                                                      )
                                                    : item.first_name &&
                                                      item.last_name
                                                    ? fixName(
                                                        item.first_name,
                                                        item.last_name,
                                                      )
                                                    : item.first_name &&
                                                      !item.last_name &&
                                                      capitalizeFirst(
                                                        item.first_name,
                                                      )}
                                                </span>

                                                {item.note && (
                                                  <span className="refferal-auther as-link w-100">
                                                    {item.note}
                                                  </span>
                                                )}
                                                {/* <p>Think there may be some synergy</p> */}
                                                <span className="refferal-date as-link">
                                                  <Moment format="MM/DD/YY H:mmA">
                                                    {item.updated_at}
                                                  </Moment>
                                                </span>

                                                {item.images && (
                                                  <div className="invited-by-wrapper as-link">
                                                    <div className="user-img">
                                                      <img
                                                        src={
                                                          !item.images
                                                            ? DEFAULT_IMAGE
                                                            : item.images[0]
                                                        }
                                                        alt=""
                                                      />
                                                      {/* <span>{smartItem.name}</span> */}
                                                    </div>
                                                  </div>
                                                )}
                                              </div>
                                              <div
                                                className="refferal-like-unlike as-link"
                                                onClick={() => {
                                                  handle(item);
                                                }}>
                                                {item.feedback === 'like' && (
                                                  <span className="refferal-icons">
                                                    <i
                                                      className="icon-like-icon"
                                                      style={{
                                                        color: '#00bf4d',
                                                      }}
                                                    />
                                                  </span>
                                                )}
                                                {item.feedback ===
                                                  'dislike' && (
                                                  <span className="refferal-icons">
                                                    <i className="icon-unlike" />
                                                  </span>
                                                )}
                                              </div>
                                            </div>
                                            <div className="bottom-icons">
                                              {item.refer_notes && (
                                                <span
                                                  onClick={() => {
                                                    // setNotes(
                                                    //   item.refer_notes,
                                                    // );
                                                    handleMenu(
                                                      'Notes',
                                                      item.refer_notes,
                                                    );
                                                    // setShowComponent('Notes');
                                                  }}>
                                                  <i className="fa-sharp fa-solid fa-book" />
                                                </span>
                                              )}
                                            </div>
                                          </div>
                                        )}

                                      {subTabType === 'appointment' &&
                                        item.referred_detail && (
                                          <div
                                            className="refferals-list as-link"
                                            key={'www' + index}>
                                            <div
                                              className="refferals-list-item card new-refferal-list"
                                              onClick={() => {
                                                // setShowComponent(
                                                //   'create-appointment',
                                                // );
                                                // setAppointmentData(item);
                                                handleMenu(
                                                  'create_appointment',
                                                  item,
                                                );
                                              }}>
                                              <div className="refferal-content">
                                                <h2>
                                                  {item.creator_details &&
                                                    fixName(
                                                      item.creator_details
                                                        .first_name,
                                                      item.creator_details
                                                        .last_name,
                                                    )}
                                                </h2>
                                                <span className="refferal-auther">
                                                  {item.appointment_type_title}
                                                </span>
                                                {/* <p>Think there may be some synergy</p> */}
                                                <span className="refferal-date">
                                                  {moment(
                                                    item.start_date,
                                                  ).format('MM/DD/YYYY')}{' '}
                                                  {item.duration + 'min'}
                                                </span>
                                              </div>
                                              <div className="refferal-img">
                                                <img
                                                  src={
                                                    item.creator_details &&
                                                    item.creator_details
                                                      .profile_img
                                                      ? item.creator_details
                                                          .profile_img
                                                      : DEFAULT_IMAGE
                                                  }
                                                  alt=""
                                                />
                                                <p className="refferal-info-que text-end">
                                                  {item.cost
                                                    ? '$' + item.cost
                                                    : 'Free'}
                                                </p>
                                              </div>
                                            </div>
                                          </div>
                                        )}

                                      {item.creator_detail &&
                                        subTabType === 'sessions' && (
                                          <div className="session-item card">
                                            <div
                                              className="session-heading as-link"
                                              onClick={() => {
                                                if (
                                                  new Date(serverTime) <
                                                  new Date(item.start_time)
                                                ) {
                                                  breakDown(item);
                                                } else {
                                                  joinTheSession(item);
                                                }
                                              }}>
                                              <h2>{item.session_title} </h2>
                                              <span className="users-text as-link">
                                                <i className="fas fa-circle-user" />{' '}
                                                {item.total_members + 1}/
                                                {item.total_moderators + 1}
                                              </span>
                                              <span className="category-text as-link">
                                                {item.category_name}
                                              </span>
                                              <span className="category-text mb-2 d-block as-link">
                                                <Moment format="MM/DD/YY HH:mm A">
                                                  {item.session_start_time}
                                                </Moment>
                                              </span>
                                              <h2 className="as-link">
                                                Referred{' '}
                                                {arrowButton ? 'to' : 'by'}{' '}
                                                {item.referred_detail &&
                                                  fixName(
                                                    item.referred_detail
                                                      .first_name,
                                                    item.referred_detail
                                                      .last_name,
                                                  )}
                                              </h2>
                                            </div>
                                            <div
                                              className="persons-img-list as-link"
                                              onClick={() => {
                                                if (
                                                  new Date(serverTime) <
                                                  new Date(item.start_time)
                                                ) {
                                                  breakDown(item);
                                                } else {
                                                  joinTheSession(item);
                                                }
                                              }}>
                                              <div className="img-item as-link">
                                                <span className="owner-icon as-link">
                                                  <i className="fas fa-crown" />
                                                </span>
                                                <img
                                                  src={
                                                    item.creator_detail
                                                      .profile_img === ''
                                                      ? DEFAULT_IMAGE
                                                      : item.creator_detail
                                                          .profile_img
                                                  }
                                                  alt=""
                                                />
                                              </div>

                                              {!(item.moderator_ids === '') &&
                                                item.moderators_list &&
                                                item.moderators_list.length >
                                                  0 &&
                                                Object.entries(
                                                  item.moderators_list,
                                                ).length > 0 &&
                                                item.moderators_list
                                                  .slice(0, 4)
                                                  .map(person => {
                                                    // if (index < 7) {
                                                    return _renderAudiImg(
                                                      person,
                                                      true,
                                                    );
                                                    // }
                                                  })}
                                              {!(item.audience_ids === '') &&
                                                item.audience_list &&
                                                item.audience_list.length > 0 &&
                                                Object.entries(
                                                  item.audience_list,
                                                ).length > 0 &&
                                                item.audience_list
                                                  .slice(0, 3)
                                                  .map(person => {
                                                    // if (index < 7) {
                                                    return _renderAudiImg(
                                                      person,
                                                    );
                                                    // }
                                                  })}
                                            </div>

                                            <div className="persons-name-list">
                                              <ul>
                                                <li>
                                                  <span className="owner-icon">
                                                    <i className="fas fa-crown" />
                                                  </span>
                                                  {fixName(
                                                    item.creator_detail
                                                      .creator_first_name,
                                                    item.creator_detail
                                                      .creator_last_name,
                                                  )}
                                                </li>
                                                {!(item.moderator_ids === '') &&
                                                  item.moderators_list &&
                                                  item.moderators_list.length >
                                                    0 &&
                                                  Object.entries(
                                                    item.moderators_list,
                                                  ).length > 0 &&
                                                  item.moderators_list
                                                    .slice(0, 4)
                                                    .map(person => (
                                                      <li
                                                        key={`person-mod-${person.id}`}>
                                                        {fixName(
                                                          person.first_name,
                                                          person.last_name,
                                                        )}
                                                        <span className="speaker-icon">
                                                          <i className="fas fa-microphone" />
                                                        </span>
                                                      </li>
                                                    ))}
                                                {!(item.audience_ids === '') &&
                                                  item.audience_list &&
                                                  item.audience_list.length >
                                                    0 &&
                                                  Object.entries(
                                                    item.audience_list,
                                                  ).length > 0 &&
                                                  item.audience_list
                                                    .slice(0, 3)
                                                    .map(person => (
                                                      // index < 5 &&
                                                      <li
                                                        key={`person-aud-${person.id}`}>
                                                        {fixName(
                                                          person.first_name,
                                                          person.last_name,
                                                        )}
                                                      </li>
                                                    ))}
                                              </ul>
                                            </div>
                                            <div className="bottom-icons">
                                              <span>
                                                <i className="fas fa-microphone" />
                                              </span>
                                              {item.refer_notes && (
                                                <span
                                                  onClick={() => {
                                                    // setNotes(
                                                    //   item.refer_notes,
                                                    // );
                                                    // setShowComponent('Notes');
                                                    handleMenu(
                                                      'Notes',
                                                      item.refer_notes,
                                                    );
                                                  }}>
                                                  <i className="fa-sharp fa-solid fa-book" />
                                                </span>
                                              )}
                                            </div>
                                          </div>
                                        )}
                                      {item.creator_detail &&
                                        subTabType === 'events' && (
                                          <>
                                            <div className="session-item card">
                                              <div
                                                className="session-heading"
                                                onClick={() => {
                                                  eventHandle(item);
                                                }}>
                                                <h2 className="as-link">
                                                  {item.session_title}
                                                </h2>
                                                <span className="category-text as-link d-block mb-2">
                                                  <Moment format="MM/DD/YY HH:mm A">
                                                    {item.start_time}
                                                  </Moment>
                                                </span>
                                                <span className="users-text as-link ">
                                                  <i className="fas fa-circle-user" />{' '}
                                                  {item.total_members + 1}/
                                                  {item.total_moderators + 1}
                                                </span>
                                                <span className="category-text as-link">
                                                  {item.category_name}
                                                </span>
                                              </div>

                                              {item.referred_detail && (
                                                <div
                                                  className="mb-3"
                                                  onClick={() => {
                                                    eventHandle(item);
                                                  }}>
                                                  <span className="reffered-text as-link">
                                                    Referred{' '}
                                                    {arrowButton ? 'to' : 'by'}{' '}
                                                    {fixName(
                                                      item.referred_detail
                                                        .first_name,
                                                      item.referred_detail
                                                        .last_name,
                                                    )}{' '}
                                                  </span>
                                                </div>
                                              )}

                                              <div
                                                className="persons-img-list"
                                                onClick={() => {
                                                  eventHandle(item);
                                                }}>
                                                <div className="img-item as-link">
                                                  <img
                                                    src={
                                                      item.creator_detail
                                                        .profile_img === ''
                                                        ? DEFAULT_IMAGE
                                                        : item.creator_detail
                                                            .profile_img
                                                    }
                                                    alt=""
                                                  />
                                                </div>

                                                {!(item.moderator_ids === '') &&
                                                  item.moderators_list &&
                                                  item.moderators_list.length >
                                                    0 &&
                                                  Object.entries(
                                                    item.moderators_list,
                                                  ).length > 0 &&
                                                  item.moderators_list
                                                    .slice(0, 4)
                                                    .map(person => {
                                                      // if (index < 7) {
                                                      return _renderAudiImg(
                                                        person,
                                                        true,
                                                      );
                                                      // }
                                                    })}
                                                {!(item.audience_ids === '') &&
                                                  item.audience_list &&
                                                  item.audience_list.length >
                                                    0 &&
                                                  Object.entries(
                                                    item.audience_list,
                                                  ).length > 0 &&
                                                  item.audience_list
                                                    .slice(0, 3)
                                                    .map(person => {
                                                      // if (index < 7) {
                                                      return _renderAudiImg(
                                                        person,
                                                      );
                                                      // }
                                                    })}
                                              </div>

                                              <div className="persons-name-list">
                                                <ul>
                                                  <li>
                                                    <span className="owner-icon">
                                                      <i className="fas fa-crown" />
                                                    </span>
                                                    {fixName(
                                                      item.creator_detail
                                                        .creator_first_name,
                                                      item.creator_detail
                                                        .creator_last_name,
                                                    )}
                                                  </li>
                                                  {!(
                                                    item.moderator_ids === ''
                                                  ) &&
                                                    item.moderators_list &&
                                                    item.moderators_list
                                                      .length > 0 &&
                                                    Object.entries(
                                                      item.moderators_list,
                                                    ).length > 0 &&
                                                    item.moderators_list
                                                      .slice(0, 4)
                                                      .map(person => (
                                                        <li
                                                          key={`person-mod-${person.id}`}>
                                                          {fixName(
                                                            person.first_name,
                                                            person.last_name,
                                                          )}
                                                          <span className="speaker-icon">
                                                            <i className="fas fa-microphone" />
                                                          </span>
                                                        </li>
                                                      ))}
                                                  {!(
                                                    item.audience_ids === ''
                                                  ) &&
                                                    item.audience_list &&
                                                    item.audience_list.length >
                                                      0 &&
                                                    Object.entries(
                                                      item.audience_list,
                                                    ).length > 0 &&
                                                    item.audience_list
                                                      .slice(0, 3)
                                                      .map(person => (
                                                        // index < 5 &&
                                                        <li
                                                          key={`person-aud-${person.id}`}>
                                                          {fixName(
                                                            person.first_name,
                                                            person.last_name,
                                                          )}
                                                        </li>
                                                      ))}
                                                </ul>
                                              </div>
                                              <div className="events-place-like session-address-wrapper mt-2">
                                                {item.address && (
                                                  <span
                                                    className="address as-link"
                                                    onClick={() => {}}>
                                                    <i className="fas fa-location-dot"></i>{' '}
                                                    {item.address}
                                                  </span>
                                                )}

                                                {item.price && (
                                                  <span>
                                                    {formatAmount(
                                                      item.price
                                                        ? item.price
                                                        : '',
                                                    )}
                                                  </span>
                                                )}
                                              </div>
                                              <div className="bottom-icons">
                                                {item.refer_notes && (
                                                  <span
                                                    onClick={() => {
                                                      // setNotes(
                                                      //   item.refer_notes,
                                                      // );
                                                      // setShowComponent(
                                                      //   'Notes',
                                                      // );
                                                      handleMenu(
                                                        'Notes',
                                                        item.refer_notes,
                                                      );
                                                    }}>
                                                    <i className="fa-sharp fa-solid fa-book" />
                                                  </span>
                                                )}
                                                <span>
                                                  <i className="icon-tickets" />
                                                </span>
                                              </div>
                                            </div>
                                          </>
                                        )}
                                      {subTabType === 'opportunities' &&
                                        item.opportunity_type_name && (
                                          <>
                                            <div className="opportunity-item card">
                                              <h2 className="as-link">
                                                {item.title}
                                              </h2>
                                              <div
                                                className="oppo-share-wrapper as-link"
                                                onClick={() => {
                                                  // setOpportunityData(item);
                                                  // setShowComponent(
                                                  //   'opportunity',
                                                  // );
                                                  handleMenu(
                                                    'opportunity',
                                                    item,
                                                  );
                                                }}>
                                                <span className="category as-link">
                                                  {item.business_name}
                                                </span>
                                                {/* <span className="oppo-share-icon">
                                            <i className="fas fa-share" />
                                          </span> */}
                                              </div>
                                              {item.referred_detail && (
                                                <span
                                                  className="reffered-text as-link"
                                                  onClick={() => {
                                                    // setOpportunityData(item);
                                                    // setShowComponent(
                                                    //   'opportunity',
                                                    // );
                                                    handleMenu(
                                                      'opportunity',
                                                      item,
                                                    );
                                                  }}>
                                                  Referred{' '}
                                                  {arrowButton ? 'to' : 'by'}{' '}
                                                  {fixName(
                                                    item.referred_detail
                                                      .first_name,
                                                    item.referred_detail
                                                      .last_name,
                                                  )}
                                                </span>
                                              )}
                                              <br />
                                              <div
                                                className="as-link"
                                                onClick={() => {
                                                  // setOpportunityData(item);
                                                  // setShowComponent(
                                                  //   'opportunity',
                                                  // );
                                                  handleMenu(
                                                    'opportunity',
                                                    item,
                                                  );
                                                }}>
                                                <RenderHTMLBlock
                                                  className={`oppo-desc-content ${showMoreClass}`}
                                                  content={item.description}
                                                />
                                                {/* {item.description && (
                                                <ShowMore limit={150}>
                                                  {item.description}
                                                </ShowMore>
                                              )} */}
                                              </div>
                                              {item.description.length >
                                                110 && (
                                                <div className="oppo-desc as-link ">
                                                  <span
                                                    className="read-more"
                                                    onClick={() =>
                                                      setToggleShowMore(
                                                        !toggleShowMore,
                                                      )
                                                    }>
                                                    {!toggleShowMore
                                                      ? labels.read_more[
                                                          selected_lang
                                                        ]
                                                      : labels.read_less[
                                                          selected_lang
                                                        ]}
                                                  </span>
                                                </div>
                                              )}
                                              <div
                                                className="oppo-address as-link"
                                                onClick={() => {
                                                  // setOpportunityData(item);
                                                  // setShowComponent(
                                                  //   'opportunity',
                                                  // );
                                                  handleMenu(
                                                    'opportunity',
                                                    item,
                                                  );
                                                }}>
                                                {item.address && (
                                                  <span className="address">
                                                    <i className="icon-map-pin" />{' '}
                                                    {item.address}
                                                  </span>
                                                )}
                                                <span
                                                  className="injeaction-text as-link"
                                                  onClick={() => {
                                                    // setOpportunityData(item);
                                                    // setShowComponent(
                                                    //   'opportunity',
                                                    // );
                                                    handleMenu(
                                                      'opportunity',
                                                      item,
                                                    );
                                                  }}>
                                                  {item.vaccinate === 'yes' && (
                                                    <i className="icon-injection" />
                                                  )}{' '}
                                                  {item.opportunity_type_name}
                                                  {/* <b>
                                                {formatAmount(
                                                  item.amount
                                                    ? item.amount
                                                    : '',
                                                )}
                                              </b> */}
                                                  <b>
                                                    {item.amount &&
                                                      formatAmount(
                                                        item.amount
                                                          ? item.amount
                                                          : '',
                                                      ) + '/hr'}
                                                  </b>
                                                </span>
                                                {/* <span className="hearticon">
                                                               <i className={`${heartClass} fa-heart`} />
                                                           </span> */}
                                              </div>
                                              <div className="bottom-icons">
                                                {/* <span>
                                              <i className="fas fa-microphone" />
                                            </span> */}
                                                {item.refer_notes && (
                                                  <span
                                                    onClick={() => {
                                                      // setNotes(
                                                      //   item.refer_notes,
                                                      // );
                                                      // setShowComponent(
                                                      //   'Notes',
                                                      // );
                                                      handleMenu(
                                                        'Notes',
                                                        item.refer_notes,
                                                      );
                                                    }}>
                                                    <i className="fa-sharp fa-solid fa-book" />
                                                  </span>
                                                )}
                                              </div>
                                            </div>
                                          </>
                                        )}

                                      {subTabType === 'group_chat' &&
                                        item.refer_type === 'group_chat' && (
                                          <>
                                            <div
                                              className="messages-users-list card-container"
                                              onClick={() => {
                                                // setShowComponent(
                                                //   'create-appointment',
                                                // );
                                                // setAppointmentData(item);
                                                handleMenu(
                                                  'create_appointment',
                                                  item,
                                                );
                                              }}>
                                              <div
                                                key={`msg-${index}`}
                                                className={
                                                  'message-user-item no-link padd-with-borderContaier m-0'
                                                }>
                                                <div
                                                  className="user-img as-link"
                                                  onClick={() => {
                                                    // amplitudeEvent('OPEN_CHAT_ROOM');
                                                    handleOpenChatRoom(item);
                                                  }}>
                                                  <img
                                                    src={
                                                      item.group_image !== ''
                                                        ? item.group_image
                                                        : item[
                                                            parseInt(
                                                              item.creator_id,
                                                            ) ===
                                                            parseInt(
                                                              localStorage.getItem(
                                                                'user',
                                                              ),
                                                            )
                                                              ? 'user_detail'
                                                              : item.hasOwnProperty(
                                                                  'creator_details',
                                                                )
                                                              ? 'creator_details'
                                                              : 'creator_detail'
                                                          ]['profile_img']
                                                        ? item[
                                                            parseInt(
                                                              item.creator_id,
                                                            ) ===
                                                            parseInt(
                                                              localStorage.getItem(
                                                                'user',
                                                              ),
                                                            )
                                                              ? 'user_detail'
                                                              : item.hasOwnProperty(
                                                                  'creator_details',
                                                                )
                                                              ? 'creator_details'
                                                              : 'creator_detail'
                                                          ]['profile_img']
                                                        : DEFAULT_IMAGE
                                                    }
                                                    alt=""
                                                  />
                                                  {item.chat_type ===
                                                    'question' && (
                                                    <span>
                                                      <i className="fa-solid fa-q"></i>
                                                    </span>
                                                  )}
                                                  {item.chat_type ===
                                                    'opportunity' && (
                                                    <span className="icon icon-posts"></span>
                                                  )}
                                                  {item.chat_type ===
                                                    'event' && (
                                                    <span className="icon-tickets"></span>
                                                  )}
                                                  {item.chat_type ===
                                                    'session' && (
                                                    <span className="icon-video-camera-2"></span>
                                                  )}
                                                  {/* <span className="fa-solid fa-shop"></span> */}
                                                </div>
                                                <div
                                                  className="user-content as-link"
                                                  onClick={() => {
                                                    handleOpenChatRoom(item);
                                                  }}>
                                                  <h2 className="single-line-text">
                                                    {/* {tabType === 'subInvites' &&
                                      !item.hasOwnProperty('read') */}
                                                    {item.group_name !== ''
                                                      ? item.group_name
                                                      : fixName(
                                                          item[
                                                            parseInt(
                                                              item.creator_id,
                                                            ) ===
                                                            parseInt(
                                                              localStorage.getItem(
                                                                'user',
                                                              ),
                                                            )
                                                              ? 'user_detail'
                                                              : item.hasOwnProperty(
                                                                  'creator_details',
                                                                )
                                                              ? 'creator_details'
                                                              : 'creator_detail'
                                                          ]['first_name'],
                                                          item[
                                                            parseInt(
                                                              item.creator_id,
                                                            ) ===
                                                            parseInt(
                                                              localStorage.getItem(
                                                                'user',
                                                              ),
                                                            )
                                                              ? 'user_detail'
                                                              : item.hasOwnProperty(
                                                                  'creator_details',
                                                                )
                                                              ? 'creator_details'
                                                              : 'creator_detail'
                                                          ]['last_name'],
                                                        )}
                                                  </h2>

                                                  {formatString(
                                                    item.last_message,
                                                  )}

                                                  <div className="message-date-info">
                                                    <span className="message-date">
                                                      <Moment format="MM/DD/YY HH:mm A">
                                                        {item.last_message_time
                                                          ? item.last_message_time
                                                          : item.created_at}
                                                      </Moment>
                                                    </span>
                                                    {/* {renderMessageUsers(item.moreUsers)} */}
                                                  </div>
                                                </div>

                                                {!item.hasOwnProperty(
                                                  'invitation_type',
                                                ) &&
                                                  item.hasOwnProperty(
                                                    'group_detail',
                                                  ) && (
                                                    <OtherUserImages
                                                      otherUsers={filteringUsers(
                                                        item.audience_detail.concat(
                                                          item.moderator_detail,
                                                          item.creator_detail
                                                            ? [
                                                                item.creator_detail,
                                                              ]
                                                            : [
                                                                item.creator_details,
                                                              ],
                                                        ),
                                                        arrayColumn(
                                                          item.group_detail,
                                                          'id',
                                                        ),
                                                      )}
                                                    />
                                                  )}
                                                {!item.hasOwnProperty(
                                                  'invitation_type',
                                                ) &&
                                                  !item.hasOwnProperty(
                                                    'opportunity_invitaion_status',
                                                  ) &&
                                                  item.hasOwnProperty(
                                                    'opportunity_id',
                                                  ) &&
                                                  item.chat_type ===
                                                    'opportunity' && (
                                                    <OtherUserImages
                                                      otherUsers={filteringUsers(
                                                        item.other_persons_opportunity_chat_room.concat(
                                                          item.team_member_details,
                                                          item.creator_detail
                                                            ? [
                                                                item.creator_detail,
                                                              ]
                                                            : [
                                                                item.creator_details,
                                                              ],
                                                        ),
                                                        arrayColumn(
                                                          [
                                                            ...item.other_persons_opportunity_chat_room,
                                                            ...item.team_member_details,
                                                            item.creator_details,
                                                          ],
                                                          'id',
                                                        ),
                                                      )}
                                                    />
                                                  )}
                                              </div>
                                            </div>
                                          </>
                                        )}

                                      {subTabType === 'all' &&
                                      item.refer_type === 'opportunity' ? (
                                        <div className="opportunity-item card">
                                          <h2
                                            className="as-link"
                                            onClick={() => {
                                              // setOpportunityData(item);
                                              // setShowComponent('opportunity');
                                              handleMenu('opportunity', item);
                                            }}>
                                            {item.title}
                                          </h2>
                                          <div
                                            className="oppo-share-wrapper as-link"
                                            onClick={() => {
                                              // setOpportunityData(item);
                                              // setShowComponent('opportunity');
                                              handleMenu('opportunity', item);
                                            }}>
                                            <span
                                              className="category as-link"
                                              onClick={() => {
                                                // setOpportunityData(item);
                                                // setShowComponent(
                                                //   'opportunity',
                                                // );
                                                handleMenu('opportunity', item);
                                              }}>
                                              {item.business_name}
                                            </span>
                                          </div>
                                          {item.referred_detail && (
                                            <span
                                              className="as-link reffered-text"
                                              onClick={() => {
                                                // setOpportunityData(item);
                                                // setShowComponent(
                                                //   'opportunity',
                                                // );
                                                handleMenu('opportunity', item);
                                              }}>
                                              Referred{' '}
                                              {arrowButton ? 'to' : 'by'}{' '}
                                              {fixName(
                                                item.referred_detail.first_name,
                                                item.referred_detail.last_name,
                                              )}
                                            </span>
                                          )}

                                          <div
                                            className="oppo-desc as-link"
                                            onClick={() => {
                                              // setOpportunityData(item);
                                              // setShowComponent('opportunity');
                                              handleMenu('opportunity', item);
                                            }}>
                                            <RenderHTMLBlock
                                              className={`oppo-desc-content ${showMoreClass}`}
                                              content={item.description}
                                            />

                                            {/* {item.description && (
                                            <span className="refferal-auther as-link w-100">
                                              <ShowMore limit={150}>
                                                {item.description}
                                              </ShowMore>
                                            </span>
                                          )} */}
                                          </div>
                                          {item.description.length > 110 && (
                                            <div className="oppo-desc as-link ">
                                              <span
                                                className="read-more"
                                                onClick={() =>
                                                  setToggleShowMore(
                                                    !toggleShowMore,
                                                  )
                                                }>
                                                {!toggleShowMore
                                                  ? labels.read_more[
                                                      selected_lang
                                                    ]
                                                  : labels.read_less[
                                                      selected_lang
                                                    ]}
                                              </span>
                                            </div>
                                          )}
                                          <div
                                            className="oppo-address as-link"
                                            onClick={() => {
                                              // setOpportunityData(item);
                                              // setShowComponent('opportunity');
                                              handleMenu('opportunity', item);
                                            }}>
                                            {item.address && (
                                              <span className="address">
                                                <i className="icon-map-pin" />{' '}
                                                {item.address}
                                              </span>
                                            )}
                                            <span className="injeaction-text as-link">
                                              {item.vaccinate === 'yes' && (
                                                <i className="icon-injection" />
                                              )}{' '}
                                              {item.opportunity_type_name}
                                              {/* <b>
                                            {formatAmount(
                                              item.amount ? item.amount : '',
                                            )}
                                          </b> */}
                                              <b>
                                                {item.amount &&
                                                  formatAmount(
                                                    item.amount
                                                      ? item.amount
                                                      : '',
                                                  ) + '/hr'}
                                              </b>
                                            </span>
                                            {/* <span className="hearticon">
                                                               <i className={`${heartClass} fa-heart`} />
                                                           </span> */}
                                          </div>
                                          <div className="bottom-icons">
                                            {/* <span>
                                            <i className="fas fa-microphone" />
                                          </span> */}
                                            {item.refer_notes && (
                                              <span
                                                onClick={() => {
                                                  // setNotes(item.refer_notes);
                                                  // setShowComponent('Notes');
                                                  handleMenu(
                                                    'Notes',
                                                    item?.refer_notes,
                                                  );
                                                }}>
                                                <i className="fa-sharp fa-solid fa-book" />
                                              </span>
                                            )}
                                          </div>
                                        </div>
                                      ) : null}

                                      {subTabType === 'all' &&
                                      item.refer_type === 'session' ? (
                                        <div className="session-item card">
                                          <div
                                            className="session-heading as-link"
                                            onClick={() => {
                                              if (
                                                new Date(serverTime) <
                                                new Date(item.start_time)
                                              ) {
                                                breakDown(item);
                                              } else {
                                                joinTheSession(item);
                                              }
                                            }}>
                                            <h2>{item.session_title}</h2>
                                            <span className="users-text as-link">
                                              <i className="fas fa-circle-user" />{' '}
                                              {item.total_members + 1}/
                                              {item.total_moderators + 1}
                                            </span>
                                            <span className="category-text as-link">
                                              {item.category_name}
                                            </span>
                                            <span className="category-text mb-2 d-block as-link">
                                              <Moment format="MM/DD/YY HH:mm A">
                                                {item.session_start_time}
                                              </Moment>
                                            </span>
                                            {item.referred_detail && (
                                              <h2 className="as-link">
                                                Referred{' '}
                                                {arrowButton ? 'to' : 'by'}{' '}
                                                {fixName(
                                                  item.referred_detail
                                                    .first_name,
                                                  item.referred_detail
                                                    .last_name,
                                                )}{' '}
                                                {/* {item.referred_detail.last_name} */}
                                              </h2>
                                            )}
                                          </div>
                                          <div
                                            className="persons-img-list as-link"
                                            onClick={() => {
                                              if (
                                                new Date(serverTime) <
                                                new Date(item.start_time)
                                              ) {
                                                breakDown(item);
                                              } else {
                                                joinTheSession(item);
                                              }
                                            }}>
                                            <div className="img-item as-link">
                                              <span className="owner-icon">
                                                <i className="fas fa-crown" />
                                              </span>
                                              <img
                                                src={
                                                  item.creator_detail
                                                    .profile_img === ''
                                                    ? DEFAULT_IMAGE
                                                    : item.creator_detail
                                                        .profile_img
                                                }
                                                alt=""
                                              />
                                            </div>
                                            {!(item.moderator_ids === '') &&
                                              item.moderators_list &&
                                              item.moderators_list.length > 0 &&
                                              Object.entries(
                                                item.moderators_list,
                                              ).length > 0 &&
                                              item.moderators_list
                                                .slice(0, 4)
                                                .map(person => {
                                                  return _renderAudiImg(
                                                    person,
                                                    true,
                                                  );
                                                })}
                                            {!(item.audience_ids === '') &&
                                              item.audience_list &&
                                              item.audience_list.length > 0 &&
                                              Object.entries(item.audience_list)
                                                .length > 0 &&
                                              item.audience_list
                                                .slice(0, 3)
                                                .map(person => {
                                                  return _renderAudiImg(person);
                                                })}
                                          </div>
                                          <div className="persons-name-list ">
                                            <ul>
                                              <li>
                                                <span className="owner-icon">
                                                  <i className="fas fa-crown" />
                                                </span>
                                                {fixName(
                                                  item.creator_detail
                                                    .creator_first_name,
                                                  item.creator_detail
                                                    .creator_last_name,
                                                )}
                                              </li>
                                              {!(item.moderator_ids === '') &&
                                                item.moderators_list &&
                                                item.moderators_list.length >
                                                  0 &&
                                                Object.entries(
                                                  item.moderators_list,
                                                ).length > 0 &&
                                                item.moderators_list
                                                  .slice(0, 4)
                                                  .map(person => (
                                                    <li
                                                      key={`person-mod-${person.id}`}>
                                                      {fixName(
                                                        person.first_name,
                                                        person.last_name,
                                                      )}
                                                      <span className="speaker-icon">
                                                        <i className="fas fa-microphone" />
                                                      </span>
                                                    </li>
                                                  ))}
                                              {!(item.audience_ids === '') &&
                                                item.audience_list &&
                                                item.audience_list.length > 0 &&
                                                Object.entries(
                                                  item.audience_list,
                                                ).length > 0 &&
                                                item.audience_list
                                                  .slice(0, 3)
                                                  .map(person => (
                                                    // index < 5 &&
                                                    <li
                                                      key={`person-aud-${person.id}`}>
                                                      {fixName(
                                                        person.first_name,
                                                        person.last_name,
                                                      )}
                                                    </li>
                                                  ))}
                                            </ul>
                                          </div>
                                          <div className="bottom-icons">
                                            <span>
                                              <i className="fas fa-microphone" />
                                            </span>
                                            {item.refer_notes && (
                                              <span
                                                onClick={() => {
                                                  // setNotes(item.refer_notes);
                                                  // setShowComponent('Notes');
                                                  handleMenu(
                                                    'Notes',
                                                    item.refer_notes,
                                                  );
                                                }}>
                                                <i className="fa-sharp fa-solid fa-book" />
                                              </span>
                                            )}
                                          </div>
                                        </div>
                                      ) : null}

                                      {(subTabType === 'all' &&
                                        item.refer_type === 'profile') ||
                                      item.refer_type === 'accomplishment' ? (
                                        <div
                                          key={`ee-www-${item.id}`}
                                          className="refferals-list-item card qa-list-item">
                                          <div
                                            className="refferal-img as-link"
                                            onClick={() => {
                                              handle(item);
                                            }}>
                                            <img
                                              src={
                                                item.referred_detail
                                                  .profile_img === ''
                                                  ? DEFAULT_IMAGE
                                                  : item.referred_detail
                                                      .profile_img
                                              }
                                              alt=""
                                            />
                                          </div>
                                          <div className="d-flex align-items-center referrals-dataa referals-dataa-holder">
                                            <div className="refferal-content as-link w-100">
                                              {item.referred_detail && (
                                                <h2
                                                  onClick={() => {
                                                    handle(item);
                                                  }}>
                                                  {fixName(
                                                    item.referred_detail
                                                      .first_name,
                                                    item.referred_detail
                                                      .last_name,
                                                  )}
                                                </h2>
                                              )}
                                              <span
                                                className="refferal-auther as-link w-100"
                                                onClick={() => {
                                                  handle(item);
                                                }}>
                                                {item.user_first_name
                                                  ? fixName(
                                                      item.user_first_name,
                                                      item.users_last_name,
                                                    )
                                                  : item.first_name &&
                                                    item.last_name
                                                  ? fixName(
                                                      item.first_name,
                                                      item.last_name,
                                                    )
                                                  : item.first_name &&
                                                    !item.last_name &&
                                                    capitalizeFirst(
                                                      item.first_name,
                                                    )}
                                              </span>

                                              {item.note && (
                                                <span className="refferal-auther as-link w-100">
                                                  <ShowMore limit={85}>
                                                    {item.note}
                                                  </ShowMore>
                                                </span>
                                              )}

                                              <span
                                                className="refferal-date as-link"
                                                onClick={() => {
                                                  handle(item);
                                                }}>
                                                <Moment format="MM/DD/YY H:mmA">
                                                  {item.updated_at}
                                                </Moment>
                                              </span>
                                            </div>
                                            <div className="refferal-right-side-links">
                                              {item.images && (
                                                <div
                                                  className="invited-by-wrapper as-link"
                                                  onClick={() => {
                                                    handle(item);
                                                  }}>
                                                  <div className="user-img">
                                                    <img
                                                      src={
                                                        !item.images
                                                          ? DEFAULT_IMAGE
                                                          : item.images[0]
                                                      }
                                                      alt=""
                                                    />
                                                  </div>
                                                </div>
                                              )}

                                              <div className="refferal-bottom-right-links d-flex align-items-end justify-content-center">
                                                <div
                                                  className="refferal-like-unlike as-link"
                                                  onClick={() => {
                                                    handle(item);
                                                  }}>
                                                  {item.feedback === 'like' && (
                                                    <span className="refferal-icons">
                                                      <i
                                                        className="icon-like-icon"
                                                        style={{
                                                          color: '#00bf4d',
                                                        }}
                                                      />
                                                    </span>
                                                  )}
                                                  {item.feedback ===
                                                    'dislike' && (
                                                    <span className="refferal-icons">
                                                      <i className="icon-unlike" />
                                                    </span>
                                                  )}
                                                </div>
                                                <div className="bottom-icons">
                                                  {item.refer_notes && (
                                                    <span
                                                      onClick={() => {
                                                        // setNotes(
                                                        //   item.refer_notes,
                                                        // );
                                                        // setShowComponent(
                                                        //   'Notes',
                                                        // );
                                                        handleMenu(
                                                          'Notes',
                                                          item.refer_notes,
                                                        );
                                                      }}>
                                                      <i className="fa-sharp fa-solid fa-book" />
                                                    </span>
                                                  )}
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      ) : null}

                                      {subTabType === 'all' &&
                                      item.refer_type === 'group_chat' ? (
                                        <div className="messages-users-list card-container">
                                          <div
                                            key={`msg-${index}`}
                                            className={
                                              'message-user-item no-link padd-with-borderContaier m-0'
                                            }>
                                            <div
                                              className="user-img as-link"
                                              // onClick={() => {
                                              //   amplitudeEvent('OPEN_CHAT_ROOM');
                                              //   handleOpenChatRoom(item);
                                              // }}
                                            >
                                              <img
                                                src={
                                                  item.group_image !== ''
                                                    ? item.group_image
                                                    : item[
                                                        parseInt(
                                                          item.creator_id,
                                                        ) ===
                                                        parseInt(
                                                          localStorage.getItem(
                                                            'user',
                                                          ),
                                                        )
                                                          ? 'user_detail'
                                                          : item.hasOwnProperty(
                                                              'creator_details',
                                                            )
                                                          ? 'creator_details'
                                                          : 'creator_detail'
                                                      ]['profile_img']
                                                    ? item[
                                                        parseInt(
                                                          item.creator_id,
                                                        ) ===
                                                        parseInt(
                                                          localStorage.getItem(
                                                            'user',
                                                          ),
                                                        )
                                                          ? 'user_detail'
                                                          : item.hasOwnProperty(
                                                              'creator_details',
                                                            )
                                                          ? 'creator_details'
                                                          : 'creator_detail'
                                                      ]['profile_img']
                                                    : DEFAULT_IMAGE
                                                }
                                                alt=""
                                              />
                                              {item.chat_type ===
                                                'question' && (
                                                <span>
                                                  <i className="fa-solid fa-q"></i>
                                                </span>
                                              )}
                                              {item.chat_type ===
                                                'opportunity' && (
                                                <span className="icon icon-posts"></span>
                                              )}
                                              {item.chat_type === 'event' && (
                                                <span className="icon-tickets"></span>
                                              )}
                                              {item.chat_type === 'session' && (
                                                <span className="icon-video-camera-2"></span>
                                              )}
                                              {/* <span className="fa-solid fa-shop"></span> */}
                                            </div>
                                            <div
                                              className="user-content as-link"
                                              onClick={() => {
                                                // handleOpenChatRoom(item);
                                              }}>
                                              <h2 className="single-line-text">
                                                {/* {tabType === 'subInvites' &&
                                      !item.hasOwnProperty('read') */}
                                                {item.group_name !== ''
                                                  ? item.group_name
                                                  : fixName(
                                                      item[
                                                        parseInt(
                                                          item.creator_id,
                                                        ) ===
                                                        parseInt(
                                                          localStorage.getItem(
                                                            'user',
                                                          ),
                                                        )
                                                          ? 'user_detail'
                                                          : item.hasOwnProperty(
                                                              'creator_details',
                                                            )
                                                          ? 'creator_details'
                                                          : 'creator_detail'
                                                      ]['first_name'],
                                                      item[
                                                        parseInt(
                                                          item.creator_id,
                                                        ) ===
                                                        parseInt(
                                                          localStorage.getItem(
                                                            'user',
                                                          ),
                                                        )
                                                          ? 'user_detail'
                                                          : item.hasOwnProperty(
                                                              'creator_details',
                                                            )
                                                          ? 'creator_details'
                                                          : 'creator_detail'
                                                      ]['last_name'],
                                                    )}
                                              </h2>

                                              {formatString(item.last_message)}

                                              <div className="message-date-info">
                                                <span className="message-date">
                                                  <Moment format="MM/DD/YY HH:mm A">
                                                    {item.last_message_time
                                                      ? item.last_message_time
                                                      : item.created_at}
                                                  </Moment>
                                                </span>
                                                {/* {renderMessageUsers(item.moreUsers)} */}
                                              </div>
                                            </div>

                                            {!item.hasOwnProperty(
                                              'invitation_type',
                                            ) &&
                                              item.hasOwnProperty(
                                                'group_detail',
                                              ) && (
                                                <OtherUserImages
                                                  otherUsers={filteringUsers(
                                                    item.audience_detail.concat(
                                                      item.moderator_detail,
                                                      item.creator_detail
                                                        ? [item.creator_detail]
                                                        : [
                                                            item.creator_details,
                                                          ],
                                                    ),
                                                    arrayColumn(
                                                      item.group_detail,
                                                      'id',
                                                    ),
                                                  )}
                                                />
                                              )}
                                            {!item.hasOwnProperty(
                                              'invitation_type',
                                            ) &&
                                              !item.hasOwnProperty(
                                                'opportunity_invitaion_status',
                                              ) &&
                                              item.hasOwnProperty(
                                                'opportunity_id',
                                              ) &&
                                              item.chat_type ===
                                                'opportunity' && (
                                                <OtherUserImages
                                                  otherUsers={filteringUsers(
                                                    item.other_persons_opportunity_chat_room.concat(
                                                      item.team_member_details,
                                                      item.creator_detail
                                                        ? [item.creator_detail]
                                                        : [
                                                            item.creator_details,
                                                          ],
                                                    ),
                                                    arrayColumn(
                                                      [
                                                        ...item.other_persons_opportunity_chat_room,
                                                        ...item.team_member_details,
                                                        item.creator_details,
                                                      ],
                                                      'id',
                                                    ),
                                                  )}
                                                />
                                              )}
                                          </div>
                                        </div>
                                      ) : null}

                                      {subTabType === 'all' &&
                                      item.refer_type === 'appointment' ? (
                                        <div
                                          className="refferals-list as-link"
                                          key={'www' + index}
                                          onClick={() => {
                                            // setShowComponent(
                                            //   'create-appointment',
                                            // );
                                            // setAppointmentData(item);
                                            handleMenu(
                                              'create_appointment',
                                              item,
                                            );
                                          }}>
                                          <div className="refferals-list-item card new-refferal-list">
                                            <div className="refferal-content">
                                              <h2>
                                                {item.creator_details &&
                                                  fixName(
                                                    item.creator_details
                                                      .first_name,
                                                    item.creator_details
                                                      .last_name,
                                                  )}
                                              </h2>
                                              <span className="refferal-auther">
                                                {item.appointment_type_title}
                                              </span>
                                              {/* <p>Think there may be some synergy</p> */}
                                              <span className="refferal-date">
                                                {moment(item.start_date).format(
                                                  'MM/DD/YYYY',
                                                )}{' '}
                                                {item.duration + 'min'}
                                              </span>
                                            </div>
                                            <div className="refferal-img">
                                              <img
                                                src={
                                                  item.creator_details &&
                                                  item.creator_details
                                                    .profile_img
                                                    ? item.creator_details
                                                        .profile_img
                                                    : DEFAULT_IMAGE
                                                }
                                                alt=""
                                              />
                                              <p className="refferal-info-que text-end">
                                                {item.cost
                                                  ? formatAmount(item.cost)
                                                  : 'Free'}
                                              </p>
                                            </div>
                                          </div>
                                        </div>
                                      ) : null}
                                      {subTabType === 'all' &&
                                      item.refer_type === 'event' ? (
                                        <div className="session-item card">
                                          <div
                                            className="session-heading"
                                            onClick={() => {
                                              eventHandle(item);
                                            }}>
                                            <h2 className="as-link">
                                              {item.session_title}
                                            </h2>
                                            <span className="category-text as-link d-block mb-2">
                                              <Moment format="MM/DD/YY HH:mm A">
                                                {item.start_time}
                                              </Moment>
                                            </span>
                                            <span className="users-text as-link ">
                                              <i className="fas fa-circle-user" />{' '}
                                              {item.total_members + 1}/
                                              {item.total_moderators + 1}
                                            </span>
                                            <span className="category-text as-link">
                                              {item.category_name}
                                            </span>
                                          </div>

                                          {item.referred_detail && (
                                            <div
                                              className="mb-3"
                                              onClick={() => {
                                                eventHandle(item);
                                              }}>
                                              <span className="reffered-text as-link">
                                                Referred{' '}
                                                {arrowButton ? 'to' : 'by'}{' '}
                                                {fixName(
                                                  item.referred_detail
                                                    .first_name,
                                                  item.referred_detail
                                                    .last_name,
                                                )}{' '}
                                              </span>
                                            </div>
                                          )}

                                          <div
                                            className="persons-img-list"
                                            onClick={() => {
                                              eventHandle(item);
                                            }}>
                                            <div className="img-item as-link">
                                              <img
                                                src={
                                                  item.creator_detail
                                                    .profile_img === ''
                                                    ? DEFAULT_IMAGE
                                                    : item.creator_detail
                                                        .profile_img
                                                }
                                                alt=""
                                              />
                                            </div>

                                            {!(item.moderator_ids === '') &&
                                              item.moderators_list &&
                                              item.moderators_list.length > 0 &&
                                              Object.entries(
                                                item.moderators_list,
                                              ).length > 0 &&
                                              item.moderators_list
                                                .slice(0, 4)
                                                .map(person => {
                                                  // if (index < 7) {
                                                  return _renderAudiImg(
                                                    person,
                                                    true,
                                                  );
                                                  // }
                                                })}
                                            {!(item.audience_ids === '') &&
                                              item.audience_list &&
                                              item.audience_list.length > 0 &&
                                              Object.entries(item.audience_list)
                                                .length > 0 &&
                                              item.audience_list
                                                .slice(0, 3)
                                                .map(person => {
                                                  // if (index < 7) {
                                                  return _renderAudiImg(person);
                                                  // }
                                                })}
                                          </div>

                                          <div className="persons-name-list">
                                            <ul>
                                              <li>
                                                <span className="owner-icon">
                                                  <i className="fas fa-crown" />
                                                </span>
                                                {fixName(
                                                  item.creator_detail
                                                    .creator_first_name,
                                                  item.creator_detail
                                                    .creator_last_name,
                                                )}
                                              </li>
                                              {!(item.moderator_ids === '') &&
                                                item.moderators_list &&
                                                item.moderators_list.length >
                                                  0 &&
                                                Object.entries(
                                                  item.moderators_list,
                                                ).length > 0 &&
                                                item.moderators_list
                                                  .slice(0, 4)
                                                  .map(person => (
                                                    <li
                                                      key={`person-mod-${person.id}`}>
                                                      {fixName(
                                                        person.first_name,
                                                        person.last_name,
                                                      )}
                                                      <span className="speaker-icon">
                                                        <i className="fas fa-microphone" />
                                                      </span>
                                                    </li>
                                                  ))}
                                              {!(item.audience_ids === '') &&
                                                item.audience_list &&
                                                item.audience_list.length > 0 &&
                                                Object.entries(
                                                  item.audience_list,
                                                ).length > 0 &&
                                                item.audience_list
                                                  .slice(0, 3)
                                                  .map(person => (
                                                    // index < 5 &&
                                                    <li
                                                      key={`person-aud-${person.id}`}>
                                                      {fixName(
                                                        person.first_name,
                                                        person.last_name,
                                                      )}
                                                    </li>
                                                  ))}
                                            </ul>
                                          </div>
                                          <div className="events-place-like session-address-wrapper mt-2">
                                            {item.address && (
                                              <span
                                                className="address as-link"
                                                onClick={() => {}}>
                                                <i className="fas fa-location-dot"></i>{' '}
                                                {item.address}
                                              </span>
                                            )}

                                            {item.price && (
                                              <span>
                                                {formatAmount(
                                                  item.price ? item.price : '',
                                                )}
                                              </span>
                                            )}
                                          </div>
                                          <div className="bottom-icons">
                                            {item.refer_notes && (
                                              <span
                                                onClick={() => {
                                                  // setNotes(item.refer_notes);
                                                  // setShowComponent('Notes');
                                                  handleMenu(
                                                    'Notes',
                                                    item.refer_notes,
                                                  );
                                                }}>
                                                <i className="fa-sharp fa-solid fa-book" />
                                              </span>
                                            )}
                                            <span>
                                              <i className="icon-tickets" />
                                            </span>
                                          </div>
                                        </div>
                                      ) : null}
                                      {subTabType === 'all' &&
                                      item.refer_type === 'refer_type' ? (
                                        <div
                                          className="refferals-list as-link"
                                          key={'www' + index}>
                                          <div className="refferals-list-item card new-refferal-list">
                                            <div className="refferal-content">
                                              <h2>
                                                {item.creator_details &&
                                                  fixName(
                                                    item.creator_details
                                                      .first_name,
                                                    item.creator_details
                                                      .last_name,
                                                  )}
                                              </h2>
                                              <span className="refferal-auther">
                                                {item.appointment_type_title}
                                              </span>
                                              {/* <p>Think there may be some synergy</p> */}
                                              <span className="refferal-date">
                                                {moment(item.start_date).format(
                                                  'MM/DD/YYYY',
                                                )}{' '}
                                                {item.duration + 'min'}
                                              </span>
                                            </div>
                                            <div className="refferal-img">
                                              <img
                                                src={
                                                  item.creator_details &&
                                                  item.creator_details
                                                    .profile_img
                                                    ? item.creator_details
                                                        .profile_img
                                                    : DEFAULT_IMAGE
                                                }
                                                alt=""
                                              />
                                              <p className="refferal-info-que text-end">
                                                {item.cost
                                                  ? '$' + item.cost
                                                  : 'Free'}
                                              </p>
                                            </div>
                                          </div>
                                        </div>
                                      ) : null}
                                    </div>
                                  ))
                                : dropdownTypeValue.includes('referalls') && (
                                    <p className="text-center">
                                      No record found
                                    </p>
                                  )}

                              {/* {!arrowButton && subTabType === 'all' && (
                                <div className="no-results-wrapper Builders-NoRecord">
                                  <div className="img-bg-wrapper">
                                    <img
                                      src={''}
                                      alt=""
                                    />
                                  </div>

                                  <div className="no-results-content">
                                    <h2>Manage Referrals</h2>
                                    <p>
                                      Increase the number of referrals people
                                      send to you by referring more to others
                                    </p>

                                    <div className="btn-wrapper mt-3">
                                      <Button
                                        className="btn btn-dark"
                                        onClick={() => {
                                          // setWork(true);
                                        }}>
                                        Refer Colleague
                                      </Button>
                                    </div>
                                  </div>
                                </div>
                              )}
                              {arrowButton && subTabType === 'all' && (
                                <div className="no-results-wrapper Builders-NoRecord">
                                  <div className="img-bg-wrapper">
                                    <img
                                      src={''}
                                      alt=""
                                    />
                                  </div>

                                  <div className="no-results-content">
                                    <h2>Manage Referrals</h2>
                                    <p>
                                      Increase the number of referrals people
                                      send to you by referring more to others
                                    </p>

                                    <div className="btn-wrapper mt-3">
                                      <Button
                                        className="btn btn-dark"
                                        onClick={() => {
                                          // setWork(true);
                                        }}>
                                        Refer Colleague
                                      </Button>
                                    </div>
                                  </div>
                                </div>
                              )} */}
                            </>
                          )}
                        {type === 'overview' &&
                          dropdownTypeValue.includes('recent') &&
                          props.userData?.pipelines.length > 0 && (
                            <>
                              <div className="d-flex align-items-center phases_item mt-2 icon-alias-holder">
                                <div
                                  style={{background: 'transparent'}}
                                  className="customer_name flex-1">
                                  <div className="alias_name">
                                    <h5>Pipeline</h5>
                                  </div>
                                </div>
                              </div>
                              {props.userData?.pipelines?.map((item, index) => (
                                <div
                                  key={`pipeline_${index}`}
                                  className="refferals-list action-listing as-link"
                                  style={{border: '1px solid #f1eded'}}
                                  onClick={() => {
                                    props.handleCommand(item, 'pipeLineDetail');
                                    // setPipelineItem(item);
                                  }}>
                                  <div className="task-item">
                                    <div className="d-flex justify-content-between">
                                      <h2 className="truncate_2_lines actions-title mb-2">
                                        {item.title}
                                      </h2>
                                    </div>

                                    <p className="p-reg p-reg-truncate m-0 mb-2">
                                      {item.description}
                                    </p>
                                    <div
                                      className="d-flex align-items-center justify-content-between"
                                      style={{alignItems: 'center'}}>
                                      <div className="d-flex align-items-center">
                                        <p className="p-reg m-0 actionsCategories single-line-text">
                                          Phases:{' '}
                                          {item.phases
                                            .map(phase => phase.phase)
                                            .join(', ')}
                                        </p>
                                      </div>

                                      <div className="arrow-up"></div>
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </>
                          )}

                        {dropdownTypeValue.includes('payment') &&
                          type === 'overview' && (
                            <>
                              {/* {props && props?.fromPage == 'business' && */}
                              {props &&
                                (props.userId != localStorage.getItem('id') ||
                                  props?.fromPage == 'business') && (
                                  <div className="tranformation_filter category-btns-wrapper category-btns-wrapper-slider mt-3 mb-3">
                                    <CustomSlidingButtons
                                      items={categoryTypes}
                                      activeButtons={transformation_Filter}
                                      onItemClick={e => {
                                        if (e.target.value == 'Pending') {
                                          setPaymentType('unpaid');
                                          setTransformation_Filter([1]);
                                          getUserInvoicesFilter('unpaid');
                                        } else {
                                          setTransformation_Filter([2]);
                                          setPaymentType('paid');
                                          getUserInvoicesFilter('paid');
                                        }
                                        // alert(e.target.value)
                                        // handleClick(e);
                                      }}
                                      showItems={2}
                                    />
                                  </div>
                                )}

                              {/* <div className="search-form d-flex">
                                <Form.Group className="search-input">
                                  <Form.Control
                                    onChange={e => {
                                      setSearchQry(e.target.value);
                                    }}
                                    value={searchQry}
                                    type="text"
                                    placeholder={'Search for a session'}
                                  />
                                </Form.Group>

                                <Button>
                                  <i
                                    className="icon-filter-icon"
                                    onClick={
                                      () => {}
                                      // setSearchFilterModal(true)
                                    }
                                  />
                                </Button>
                              </div> */}

                              <div className="mt-2">
                                {Object.entries(invoices).length > 0 ? (
                                  invoices.map((item, index) => (
                                    <div className="payment-card-main">
                                      <div
                                        className="as-link"
                                        onClick={() => {
                                          props.handleCommand(
                                            item,
                                            'invoiceDetail',
                                          );
                                        }}>
                                        <div className="payment-content-card">
                                          <div className="task-profile-img">
                                            {/* <img
                                        src="https://s3.amazonaws.com/build-dev/event/slider/1692340229593.jpg"
                                        alt=""
                                      /> */}
                                            <img
                                              src={
                                                item.profile_img
                                                  ? item.profile_img
                                                  : DEFAULT_IMAGE
                                              }
                                              alt=""
                                            />
                                          </div>

                                          <div className="task-content">
                                            <h1
                                              style={{
                                                color: '#000',
                                                fontFamily: 'Inter',
                                                fontSize: '15px',
                                                fontStyle: 'normal',
                                                fontWeight: '700',
                                                lineHeight: 'normal',
                                              }}>
                                              {item.first_name +
                                                ' ' +
                                                item.last_name}
                                              {/* {fixName(item.first_name, item.last_name)} */}
                                              {/* Samantha Ellington */}
                                            </h1>

                                            <span
                                              style={{
                                                color: '#000',
                                                fontFamily: 'Inter',
                                                fontSize: '15px',
                                                fontStyle: 'normal',
                                                fontWeight: '500',
                                                lineHeight: 'normal',
                                              }}>
                                              {item.invoice_title
                                                ? item.invoice_title
                                                : ''}
                                            </span>
                                            <span
                                              className="project-title"
                                              style={{
                                                color: '#707070',
                                                fontFamily: 'Inter',
                                                fontSize: '14px',
                                                fontStyle: 'normal',
                                                fontWeight: '400',
                                                lineHeight: 'normal',
                                              }}>
                                              Invoice
                                            </span>
                                          </div>
                                        </div>

                                        <div className="payment-service-icons">
                                          <div className="roi-service-icons">
                                            <div className="service-icon">
                                              <img src={WhitePenIcon} alt="" />
                                            </div>

                                            <div className="service-icon-time">
                                              <img
                                                className=""
                                                src={timeIcon}
                                                alt=""
                                              />
                                              <span>5D</span>
                                            </div>

                                            <div className="service-icon w-25">
                                              <img src={FireIcon} alt="" />
                                            </div>

                                            <div className="service-icon w-29">
                                              <img src={MachineIcon} alt="" />
                                            </div>

                                            <div className="service-icon w-29">
                                              <img src={ArrowIcon} alt="" />
                                            </div>

                                            <div className="service-icon w-29">
                                              <img src={Bandage} alt="" />
                                            </div>

                                            <div className="service-icon w-28">
                                              <img src={heartIcon} alt="" />
                                            </div>

                                            <div className="service-icon w-29">
                                              <img src={bagIcon} alt="" />
                                            </div>
                                            <div className="w-29">
                                              <i
                                                className="fa-solid fa-ellipsis"
                                                style={{
                                                  color: '#0a0a0a',
                                                  paddingTop: '15px',
                                                }}></i>
                                            </div>
                                          </div>
                                        </div>
                                      </div>

                                      <div className="payment-card-bottom">
                                        <div
                                          className="payment-card-icons"
                                          style={{
                                            color: '#262626',

                                            fontFamily: 'Inter',
                                            fontSize: '15px',
                                            fontStyle: 'normal',
                                            fontWeight: '600',
                                            lineHeight: 'normal',
                                            paddingTop: '11px',
                                          }}>
                                          {moment(item?.due_date)
                                            .format('MM/DD/YY')
                                            .toString()}
                                        </div>
                                        <div className="pay-btn">
                                          <span
                                            style={{
                                              color: '#262626',
                                              fontFamily: 'Inter',
                                              fontSize: '15px',
                                              fontStyle: 'normal',
                                              fontWeight: '600',
                                              lineHeight: 'normal',
                                            }}>
                                            {' '}
                                            ${item.total}
                                          </span>

                                          {props &&
                                            props?.fromPage != 'business' &&
                                            item.action_status !== 'paid' &&
                                            props.selectedPaymentFilter !==
                                              'from_me' && (
                                              <Button
                                                className="new-btn btn-green ml-3 "
                                                onClick={() => {
                                                  props.handleCommand(
                                                    {
                                                      price: item.total,
                                                      paymentId:
                                                        item.payment_id,
                                                    },
                                                    'stripeModal',
                                                  );
                                                  // setSelectedPrice(item.total);
                                                  // setPaymentId(item.payment_id);
                                                  // setShowStripeModal(true);
                                                }}>
                                                Pay
                                              </Button>
                                            )}
                                        </div>
                                      </div>
                                    </div>
                                  ))
                                ) : (
                                  <div className="no-results-wrapper mt-5">
                                    <div className="no-results-content mt-5">
                                      <span>No Payments found</span>
                                      {props.fromPage !== 'business' && (
                                        <div className="btn-wrapper mt-3">
                                          <Button
                                            className="btn btn-dark"
                                            onClick={() => {
                                              props.handleCommand(
                                                '',
                                                'create_invoice',
                                              );
                                            }}>
                                            Create Invoice
                                          </Button>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                )}
                              </div>
                            </>
                          )}
                        {type === 'credentials' &&
                          !addNewSnapshot &&
                          reanderImages(userSnapshots)}

                        {dropdownTypeValue.includes('Messages') &&
                          type === 'overview' && (
                            <>
                              {threeDots && (
                                <div className="search-tabs-wrapper mt-1">
                                  <div className="search-form d-flex mt-0">
                                    <Form.Group className="search-input">
                                      <Form.Control
                                        onChange={e => {
                                          setSearchQry(e.target.value);
                                        }}
                                        type="text"
                                        placeholder={'Search'}
                                        value={searchQry}
                                      />
                                    </Form.Group>

                                    <Button
                                      onClick={() => {
                                        handleMenu('filterMessages');
                                      }}>
                                      <i className="icon-filter-icon" />
                                    </Button>
                                  </div>
                                </div>
                              )}
                              {messagesListing.length > 0 ? (
                                messagesListing.map((item, index) => {
                                  const newMessage = item.read
                                    ? ''
                                    : 'new-message';
                                  return (
                                    <div
                                      style={{
                                        margin: '8px 12px',
                                        backgroundColor: '#fff',
                                        border: '1px solid rgb(241, 237, 237)',
                                        borderRadius: '8px',
                                      }}
                                      className="messages-users-list h-auto custom-messages-users-list-holder"
                                      key={index}>
                                      <div
                                        onClick={() =>
                                          handleMenu('messages', item)
                                        }
                                        className={`message-user-item no-link likes-dislikes-msgs ${newMessage}`}>
                                        <div className="user-img as-link">
                                          <img
                                            src={
                                              item.group_image !== ''
                                                ? item.group_image
                                                : item.profile_img
                                                ? item.profile_img
                                                : DEFAULT_IMAGE
                                            }
                                            alt=""
                                          />
                                        </div>
                                        <div className="user-content as-link">
                                          <h2 className="single-line-text">
                                            {item.group_name !== ''
                                              ? item.group_name
                                              : fixName(
                                                  item.first_name,
                                                  item.last_name,
                                                )}
                                          </h2>
                                          <p className="single-line-text">
                                            {item.last_message &&
                                              formatString(item.last_message)}
                                          </p>
                                          <p className="single-line-text"> </p>
                                          <div className="message-date-info">
                                            <span className="message-date">
                                              <Moment format="MM/DD/YY HH:mm A">
                                                {item.last_message_time
                                                  ? item.last_message_time
                                                  : item.created_at}
                                              </Moment>
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  );
                                })
                              ) : (
                                <div className="no-results-wrapper mt-5">
                                  <div className="img-bg-wrapper">
                                    <img
                                      src={require('../../Assets/images/norecord/no-convos.webp')}
                                      alt=""
                                    />
                                  </div>
                                  <div className="no-results-content">
                                    <h2>
                                      {labels.MANAGE_GROUP_CHAT[selected_lang]}
                                    </h2>
                                    <p>
                                      {labels.Create_to_Invite[selected_lang]}
                                    </p>

                                    <div className="btn-wrapper mt-3">
                                      <Button
                                        className="btn btn-dark"
                                        onClick={() => {
                                          handleMenu('create_group');
                                        }}>
                                        {labels.Create_Table[selected_lang]}
                                      </Button>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </>
                          )}

                        {dropdownTypeValue.includes('appointment') &&
                          type === 'overview' && (
                            <ProfileAppointmentList
                              userData={props.userData.data}
                              handleBlock={props.handleBlock}
                              userInfo={props.userData}
                              handleCommand={props.handleCommand}
                              hitApiRef={hitApiRef}
                              threeDots={threeDots}
                              selectedFilterValue={props.selectedFilterValue}
                            />
                          )}

                        {dropdownTypeValue.includes('recruitment') &&
                        type === 'overview' ? (
                          <>
                            <ProfileRecruitment
                              userId={props.userId}
                              setshowLoader={setshowLoader}
                              showLoader={showLoader}
                              handleBlock={props.handleBlock}
                              userInfo={props.userData}
                              handleCommand={props.handleCommand}
                              hitApiRef={hitApiRef}
                              threeDots={threeDots}
                              sessionFilterValue={props.sessionFilterValue}
                            />
                            {/* <div className="opportunities-list-wrapper">
                              {Object.entries(opportunityData).length > 0 ? (
                                Object.entries(opportunityData).length > 0 &&
                                opportunityData.map((item, index) => (
                                  <OpportunityList
                                    key={`opportunity-item-${index}`}
                                    item={item}
                                    handleCommand={props.handleCommand}
                                  />
                                ))
                              ) : props.userId ===
                                localStorage.getItem('user') ? (
                                <div className="no-results-wrapper">
                                 

                                  <div className="no-results-content">
                                    <h2>
                                      You are not participating in any
                                      opportunities.
                                    </h2>
                                    <p>
                                      Create or explore opportunities to
                                      accelerate your growth. Find partners,
                                      seek funding, invest and so much more.
                                    </p>

                                    <div className="btn-wrapper mt-3">
                                      <Button
                                        className="btn btn-dark"
                                        onClick={() => {
                                          handleClickLink('/recruitment');
                                        }}>
                                        Explore Opportunities
                                      </Button>
                                    </div>
                                  </div>
                                </div>
                              ) : (
                                <></>
                              )}
                            </div> */}
                          </>
                        ) : (
                          <></>
                        )}
                        {/* {galleryType && galleryType === "snapshot" && this.reanderImages(gallery.snapshots)}
                        {galleryType && galleryType === "upcoming" && this.reanderImages(gallery.upcoming)}
                        {galleryType && galleryType === "opportunity" && this.reanderImages(gallery.opportunities)} */}
                      </div>

                      {dropdownTypeValue.includes('project management') &&
                        type === 'overview' && (
                          <>
                            {/* <ProfileProjects
                              handleTransform={props.handleCommand}
                              userData={projectListing}
                              userId={props.userId}
                              companyData={props.companyData}
                              setTransformationList={setTransformationList}
                              transformationList={transformationList}
                              threeDots={threeDots}
                              showComponent={props.showComponent}
                              Profile={props.fromPage}
                              handleProject={props.handleProject}
                              setProjectFilters={
                                props.type !== 'own_profile' &&
                                props.setProjectFilters
                              }
                              projectFilter={
                                props.type !== 'own_profile' &&
                                props.projectFilter
                              }
                            /> */}
                            {showSpinner && <LoaderSpinner />}
                            <ListingOfProjects
                              projectListing={projectListing}
                              handleProject={handleMenu}
                            />
                          </>
                        )}
                      {dropdownTypeValue.includes('member') &&
                        type === 'overview' && (
                          <>
                            <ContactDetail
                              type="company"
                              otherType="member"
                              showContactDetails={showContactDetails}
                              contactData={memberData}
                              handleChange={handleChange}
                              userData={props.userData}
                              setContactData={setMemberData}
                              setAdditionalDetail={setAdditionalDetail}
                              additionalDetail={additionalDetail}
                            />
                          </>
                        )}
                      {
                      // dropdownTypeValue.includes('portfolio') &&
                        type === 'portfolio' && (
                          <>
                            <CreatePortfolio
                              handleBlock={props.handleBlock}
                              passComponent2={props.passComponent}
                              onClose={props.onClose}
                              userId={props?.userData?.data?.id}
                            />
                            {/* <PortfolioListing handleProject={handleMenu} />
                             */}
                          </>
                        )}
                      {dropdownTypeValue.includes('availability') &&
                        type === 'employment' && (
                          <>
                            <Availability 
                            handleBlock={props.handleBlock}
                            passComponent2={props.passComponent}
                            onClose={props.onClose}
                            userId={props?.userData?.data?.id}
                            setAvailabilityData={props.setObjData}
                            availabilityData={props.objData}
                            />
                          </>
                        )}
                      {dropdownTypeValue.includes('compensation') &&
                        type === 'employment' && (
                          <>
                            <CompensationPay 
                            handleBlock={props.handleBlock}
                            />
                            <div className="mt-3">
                              <CompensationIncentive 
                              handleBlock={props.handleBlock}
                              />
                            </div>
                          </>
                        )}

                      {type === 'projects' && (
                        <>
                          <div className="Business_profile_page">
                            <ProfileTransformation
                              setTransformationList={setTransformationList}
                              transformationList={transformationList}
                              userId={props.userId}
                              companyData={props.companyData}
                              handleTransFormItem={props.handleCommand}
                              threeDots={threeDots}
                              showComponent={props.showComponent}
                            />
                          </div>
                        </>
                      )}

                      {type === 'Detail' && (
                        <div className="profile-content-infos profile-content-social-infos">
                          {/* <div className="skills">{CategoryOfUser.join(', ')}</div> */}

                          <div className="other-infos">
                            {(props.userData.data.id ===
                              localStorage.getItem('id') ||
                              (props.userData.data.id !==
                                localStorage.getItem('id') &&
                                props.userData.data.home_location)) && (
                              <div
                                className={
                                  props.userData.data.id ===
                                  localStorage.getItem('id')
                                    ? 'profile-address as-link'
                                    : 'profile-address'
                                }
                                onClick={() => {
                                  props.userData.data.id ===
                                    localStorage.getItem('id') &&
                                    // setShowComponent('homeMap');
                                    props.handleBlock(
                                      props.userData.data,
                                      'home-map',
                                    );
                                  // setEditProfile('homeMap');
                                }}>
                                <i className="fas fa-home" />{' '}
                                {props.userData.data.home_location
                                  ? props.userData.data.home_location
                                  : 'Add Location'}
                              </div>
                            )}
                            {(props.userData.data.id ===
                              localStorage.getItem('id') ||
                              (props.userData.data.id !==
                                localStorage.getItem('id') &&
                                props.userData.data.current_location)) && (
                              <div
                                className={
                                  props.userData.data.id ===
                                  localStorage.getItem('id')
                                    ? 'profile-address as-link'
                                    : 'profile-address'
                                }
                                onClick={() => {
                                  if (
                                    props.userData.data.id ===
                                    localStorage.getItem('id')
                                  ) {
                                    // setShowComponent('currentMap');
                                    // setEditProfile('currentMap');
                                    props.handleBlock(
                                      props.userData.data,
                                      'current-map',
                                    );
                                  }
                                }}>
                                <i className="icon-aroplane" />{' '}
                                {props.userData.data.current_location
                                  ? props.userData.data.current_location
                                  : 'Add Location'}
                              </div>
                            )}
                          </div>
                          {props.userData.data.bio ? (
                            // <RenderHTMLBlock
                            //   className={`profile-description ${showMoreClass}`}
                            //   container={'div'}
                            //   content={profile.data.bio}
                            // />
                            <FormattedText
                              className={`profile-description ${showMoreClass}`}
                              content={props.userData.data.bio}
                            />
                          ) : (
                            props.userData.data.id ===
                              localStorage.getItem('id') &&
                            lastSegment === 'profile' && (
                              <div className="no-results-wrapper mt-3">
                                {/* <div className="img-bg-wrapper">
                                          <img src={QA} alt="" />
                                        </div> */}

                                <div className="no-results-content">
                                  {/* <h2>What represents your life in a snapshot?</h2> */}
                                  <p>No bio is added, tap to add bio</p>

                                  <div className="btn-wrapper">
                                    <Button
                                      className="btn btn-dark"
                                      onClick={() => {
                                        // setEditProfile('editProf');
                                      }}>
                                      Add Bio
                                    </Button>
                                  </div>
                                </div>
                              </div>
                            )
                          )}

                          {(props.userData?.data?.bio?.length > 250 ||
                            lines?.length > 3) && (
                            <>
                              {' '}
                              <span
                                className="show-more-link"
                                onClick={() => setShowMore(!showMore)}>
                                {!showMore
                                  ? labels.read_more[selected_lang]
                                  : labels.read_less[selected_lang]}
                                {/* {profile.data.bio.length} */}
                              </span>
                            </>
                          )}

                          <div className="social-icons">
                            <ul style={{marginLeft: '1px'}}>
                              {props.userData.data.instagram && (
                                <li>
                                  <a
                                    // href={profile.data.instagram}
                                    href={`https://www.instagram.com/${props.userData.data.instagram.replace(
                                      /(http(s?):\/\/)?(www\.)?instagram\.com\//,
                                      '',
                                    )}`}
                                    target="_blank"
                                    rel="noopener noreferrer">
                                    <i className="fab fa-instagram" />
                                  </a>
                                </li>
                              )}
                              {props.userData.data.linkedin && (
                                <li>
                                  <a
                                    // href={profile.data.linkedin}
                                    href={`https://www.linkedin.com/in/${props.userData.data.linkedin.replace(
                                      /(http(s)?:\/\/)?(www\.)?linkedin\.com\/(in\/)?/,
                                      '',
                                    )}`}
                                    target="_blank"
                                    rel="noopener noreferrer">
                                    <i className="fa-brands fa-linkedin" />
                                  </a>
                                </li>
                              )}
                              {props.userData.data.twitter && (
                                <li>
                                  <a
                                    // href={profile.data.twitter}
                                    href={`https://twitter.com/${props.userData.data.twitter.replace(
                                      /(http(s?):\/\/)?(www\.)?twitter\.com\//,
                                      '',
                                    )}`}
                                    target="_blank"
                                    rel="noopener noreferrer">
                                    <i className="fab fa-twitter" />
                                  </a>
                                </li>
                              )}
                              {props.userData.data.youtube && (
                                <li>
                                  <a
                                    // href={profile.data.youtube}
                                    href={`https://www.youtube.com/${props.userData.data.youtube.replace(
                                      /(http(s?):\/\/)?(www\.)?(m\.)?youtube\.com\/(channel\/)?/,
                                      '',
                                    )}`}
                                    target="_blank"
                                    rel="noopener noreferrer">
                                    <i className="fab fa-youtube" />
                                  </a>
                                </li>
                              )}

                              {props.userData.data.web && (
                                <li>
                                  <a
                                    // href={profile.data.web}
                                    href={`https://${props.userData.data.web.replace(
                                      /(http(s)?:\/\/|http(s)?:\/)?/,
                                      '',
                                    )}`}
                                    target="_blank"
                                    rel="noopener noreferrer">
                                    <i className="icon-link" />
                                  </a>
                                </li>
                              )}
                              {/* <span
                                          onClick={() => {
                                              // saveMessage();
                                          }}
                                          className="form-icon send-btn"
                                      > */}

                              {/* </span> */}
                            </ul>
                          </div>
                        </div>
                      )}

                      {
                      // detailButtonsValue.includes('company') &&
                        type === 'companies' && (
                          <ContactDetail
                            type="company"
                            showContactDetails={showContactDetails}
                            contactData={contactData}
                            handleChange={handleChange}
                            userData={props.userData}
                            setContactData={setContactData}
                          />
                        )}
                      {
                      // detailButtonsValue.includes('pocs') &&
                        type === 'contacts' && dropdownTypeValue.includes('pocs') && (
                          <ContactDetail
                            type="pocs"
                            showContactDetails={showContactDetails}
                            contactData={contactData}
                            handleChange={handleChange}
                            setContactData={setContactData}
                            userData={props.userData}
                            handleBlock={props.handleBlock}
                            image={props.image}
                            setImage={props.setImage}
                          />
                        )}
                      {type === 'contacts' && dropdownTypeValue.includes('emergency_contact') && (
                          <EmergencyConatct
                            userData={props.userData}
                          />
                        )}
                      {dropdownTypeValue.includes('attachments') &&
                        type === 'Detail' && (
                          <ContactDetail
                            type="attachment"
                            showContactDetails={showContactDetails}
                            handleChange={handleChange}
                            uploadFilesListing={uploadFilesListing}
                            setUploadFilesListing={setUploadFilesListing}
                            setShowFileComponent={setShowFileComponent}
                          />
                        )}
                      {
                      // detailButtonsValue.includes('social') &&
                      type === 'social' && (
                          <ContactDetail
                            type="social"
                            showContactDetails={showContactDetails}
                            contactData={contactData}
                            handleChange={handleChange}
                            setContactData={setContactData}
                          />
                        )}

                      {type === 'overview' &&
                        dropdownTypeValue.includes('recent') && (
                          <ProfileOverview
                            userInfo={props.userData}
                            handleCommand={props.handleCommand}
                            handleBlock={props.handleBlock}
                          />
                        )}

                      {type === 'review' &&
                        (props.userId !== localStorage.getItem('id') ? (
                          <Review
                            //  setShowComponent={setShowComponent}
                            userData={props.userData?.data}
                            userInfo={props.userData}
                          />
                        ) : (
                          props.userId === localStorage.getItem('id') && (
                            <ReviewListing userData={props.userData?.data} />
                          )
                        ))}

                      {type === 'followUp' && (
                        <ProfileFollowUp
                          handleCommand={props.handleCommand}
                          userData={props.userData}
                          hitApiRef={hitApiRef}
                        />
                      )}

                      {dropdownTypeValue.includes('tasks') &&
                        type === 'overview' && (
                          <ProfileTask
                            handleCommand={props.handleCommand}
                            userData={props.userData}
                            hitApiRef={hitApiRef}
                            threeDots={threeDots}
                            filterOptionValue={props.filterOptionValue}
                            // type="profile"
                          />
                        )}
                      {dropdownTypeValue.includes('Notes') &&
                        type === 'overview' && (
                          <ProfileNotesListing
                            userData={props.userData?.notes_detail}
                            type="otherProfile"
                            setShowProfileListing={setShowProfileListing}
                          />
                        )}

                      {type === 'property' && (
                        <NorecordFound noRecordData={propertyNoRecordFound} />
                      )}

                      {dropdownTypeValue.includes('deals') && type === 'overview' && (
                        <ProfileDeals
                          handleCommand={props.handleCommand}
                          userData={props.userData}
                        />
                      )}

                      {type === 'poc' &&
                        (Object.entries(pocData).length > 0 ? (
                          pocData.map((item, index) => (
                            <div
                              className="poc_sent_card mb-2"
                              key={item.id + index}>
                              <div className="poc_card_img">
                                <img
                                  src={
                                    item.profile_img
                                      ? item.profile_img
                                      : DEFAULT_IMAGE
                                  }
                                  className="as-link"
                                  onClick={() => {
                                    props.handleBlock(item.id, 'Profile_view');
                                  }}
                                  alt=""
                                />
                              </div>

                              <div className="poc_card_content">
                                <h1>
                                  {' '}
                                  {fixName(item.first_name, item.last_name)}
                                </h1>
                                <span>Head Technician </span>
                              </div>

                              {localStorage.getItem('id') !== item.id && (
                                <div
                                  className="poc_send_icon as-link d-flex align-items-center "
                                  onClick={() => {
                                    props.handleBlock(item, 'poc_chat');
                                  }}>
                                  <i className="fa-solid icon-plane"></i>
                                </div>
                              )}
                            </div>
                          ))
                        ) : (
                          <NorecordFound
                            className={'mt-5'}
                            noRecordData={POCNoRecordFound}
                            // buttonPermesion={props.userData.data.invited_by.id === localStorage.getItem('id') ? true :false}
                            buttonPermesion={true}
                            ButtonAction={() => {
                              props.handleCommand('', 'addpoc_in_business');
                            }}
                          />
                        ))}
                      {dropdownTypeValue.includes('pipeline') &&
                        type === 'overview' && (
                          <ProfilePipeline
                            handleCommand={props.handleCommand}
                            userData={props.userData}
                          />
                        )}
                    </>
                  )}
                  {dropdownTypeValue.includes('meetup') &&
                  type === 'overview' ? (
                    <>
                      <ProfileMeetup
                        userId={props.userId}
                        showLoader={showLoader}
                        setshowLoader={setshowLoader}
                        handleBlock={props.handleBlock}
                        userInfo={props.userData}
                        handleCommand={props.handleCommand}
                        hitApi={props.hitApi}
                        threeDots={threeDots}
                        sessionFilterValue={props.sessionFilterValue}
                        search={searchQry}
                        setSearch={setSearchQry}
                        joinTheSession={joinTheSession}
                        setServerTime={setServerTime}
                        serverTime={serverTime}
                        // type="profile"
                      />

                      {/* {Object.entries(userUpcomming).length > 0 ? (
                              Object.entries(userUpcomming).length > 0 &&
                              userUpcomming.map((item, index) => (
                                <div key={`ses-item-${index}`}>
                                  {item && SessionItem(item)}
                                </div>
                              ))
                            ) : props.userId ===
                              localStorage.getItem('user') ? (
                              <div className="no-results-wrapper">
                               

                                <div className="no-results-content">
                                  <h2>
                                    You don't have any upcoming activities.
                                  </h2>
                                  <p>
                                    Grow your personal and professional circles
                                    by creating and attending group sessions and
                                    events.
                                  </p>

                                  <div className="btn-wrapper mt-3">
                                    <Button
                                      className="btn btn-dark"
                                      onClick={() => {
                                        handleClickLink('/');
                                      }}>
                                      Explore Sessions and Events
                                    </Button>
                                  </div>
                                </div>
                              </div>
                            ) : (
                              <></>
                            )} */}
                    </>
                  ) : (
                    <></>
                  )}
                </div>

                {props.fromPage !== 'business' && (
                  <FloatingMenu
                    handle={handleMenu}
                    type={props.type ? props.type : ''}
                    profile={props.userData}
                  />
                )}
              </>
            </div>
            {show && (
              <ImageLightbox
                show={show}
                current={current}
                images={popupImages}
                setPopupImages={setPopupImages}
                onClose={() => {
                  // this.setState({ show: false, current: 0 });
                  setShow(false);
                  setCurrent(0);
                }}
              />
            )}

            {showSnapshotModal && (
              <CustomModal
                size="medium"
                className={'filter-modal from-otherProfile-Snapshot'}
                style={{height: '85%'}}
                onClose={() => setShowSnapshotModal(false)}>
                <div className="filter-wrapper custom-modal-filter mb-0">
                  <div className="filter-headings text-center">
                    <h2>Filter By Category</h2>
                  </div>
                  <Scrollbars
                    autoHide
                    renderTrackHorizontal={props => (
                      <div {...props} style={{display: 'none'}} />
                    )}
                    // Hide the vertical scrollbar
                    renderTrackVertical={props => (
                      <div {...props} style={{display: 'none'}} />
                    )}>
                    <div className="filter-category-list">
                      <ul>
                        <li
                          key={`sessionC-100`}
                          className={
                            categoriesArr.length === 8 ? 'active' : ''
                          }>
                          <Form.Label>
                            {'All'}
                            <input
                              type="checkbox"
                              value={'{"id":"All", "title":"All"}'}
                              onChange={handleSnapshotSelection}
                              checked={categoriesArr.length === 8}
                            />
                            <span />
                          </Form.Label>
                        </li>
                        {Object.entries(snapshotCategories).length > 0 &&
                          snapshotCategories.map((item, index) => (
                            <li
                              key={`sessionC-${index}`}
                              className={
                                categoriesArr.includes(item.id) ? 'active' : ''
                              }>
                              <Form.Label>
                                {item.category_name}
                                <input
                                  type="checkbox"
                                  value={
                                    '{"id":"' +
                                    item.id +
                                    '", "title":"' +
                                    item.category_name +
                                    '"}'
                                  }
                                  onChange={handleSnapshotSelection}
                                  checked={categoriesArr.includes(item.id)}
                                />
                                <span />
                              </Form.Label>
                            </li>
                          ))}
                      </ul>
                    </div>
                  </Scrollbars>
                  <div className="filter-category-list text-center">
                    <Button
                      className="btn-dark"
                      onClick={() => {
                        getSnapshotData(categoriesArr);
                        setShowSnapshotModal(false);
                      }}>
                      Filter
                    </Button>
                  </div>
                </div>
              </CustomModal>
            )}
          </>
        )}
      </div>

      {addNewSnapshot === true && (
        <AddNewSnapShot
          onClose={() => {
            setAddNewSnapshot(false);
          }}
        />
      )}

      {showStripeModal && (
        <CustomModal
          size="small"
          onClose={() => {
            setShowStripeModal(false);
            setSelectedPrice('');
            setPaymentId('');
          }}
          className="availability-modal">
          <div
            className="new-session-modal-wrapper text-center  "
            style={{width: '-webkit-fill-available'}}>
            <div className="new-session-modal estimate_name_modal">
              <Elements
                stripe={stripePromise}
                options={{
                  mode: 'payment',
                  amount: 10 * 100,
                  currency: 'usd',
                  paymentMethodTypes: ['card'],
                  payment_method_types: ['card'],
                }}>
                <CheckoutForm
                  price={selectedPrice}
                  paymentId={paymentId}
                  transactionType={'invoice'}
                  onClose={() => {
                    setShowStripeModal(false);
                    setSelectedPrice('');
                    setPaymentId('');
                  }}
                />
              </Elements>
            </div>
          </div>
        </CustomModal>
      )}

      {}
    </>
  );
};

export default ProfileGallery;
