import React from 'react';
import {
  ArrowIcon,
  FireIcon,
  MachineIcon,
  WhitePenIcon,
  timeIcon,
  bagIcon,
  heartIcon,
  Bandage,
} from '../../Constants/Images';
import moment from 'moment';
import {DEFAULT_IMAGE} from '../../Utils/env';

import {Button} from 'react-bootstrap';
import { changeStatus } from '../../Utils/commonUtils';

const AppointmentItem = ({
  item,
  index,
  handleBlock,
  userData,
  handleCommand,
}) => {
 
  return (
    <div className="refferals-list as-link" key={'www' + index}>
      <div className="payment-card-main">
        <div
          className="payment-content-card"
          style={{height: '86px'}}
          onClick={() => {
            handleCommand(item, 'appointmentDetail');
          }}>
          <div
            className="task-profile-img"
            onClick={() => {
              // handleShowDtail(item);
            }}>
            <img
              src={
                item.user_details &&
                localStorage.getItem('id') !== item.user_details.id
                  ? item.user_details.profile_img
                  : item.creator_details && item.creator_details.profile_img
                  ? item.creator_details.profile_img
                  : DEFAULT_IMAGE
              }
              alt=""
            />
          </div>

          <div
            className="task-content"
            onClick={() => {
              // handleShowDtail(item);
            }}>
            <h1
              style={{
                color: '#000',
                fontFamily: 'Inter',
                fontSize: '15px',
                fontStyle: 'normal',
                fontWeight: '700',
                lineHeight: 'normal',
                marginBottom: '4px',
              }}>
              {item.user_details &&
              localStorage.getItem('id') !== item.user_details.id
                ? item.user_details.first_name +
                  ' ' +
                  item.user_details.last_name
                : item.creator_details
                ? item.creator_details.first_name +
                  ' ' +
                  item.creator_details.last_name
                : ''}
            </h1>

            <span
              style={{
                color: '#000',
                fontFamily: 'Inter',
                fontSize: '15px',
                fontStyle: 'normal',
                fontWeight: '500',
                lineHeight: 'normal',
                marginBottom: '4px',
              }}>
              {item.user_details.current_location
                ? item.user_details.current_location
                : ''}
            </span>
            <div className="payment-card-3rd-text">
              <span
                className="project-title"
                style={{
                  color: '#707070',
                  fontFamily: 'Inter',
                  fontSize: '13px',
                  fontStyle: 'normal',
                  fontWeight: '400',
                  lineHeight: 'normal',
                  marginBottom: '4px',
                }}>
                {/* ETA 45 Min */}

                {item.creator_details && item.creator_details.id === localStorage.getItem('id')  ? item.user_status : item.creator_status }
              </span>

              {/* <div>5:00 PM</div> */}
              <div>{moment(item.start_date).format('h:mm A')} </div>
            </div>
          </div>
        </div>

        <div
          className="payment-service-icons"
          onClick={() => {
            // handleShowDtail(item);
          }}>
          <div className="roi-service-icons">
            <div className="service-icon">
              <img src={WhitePenIcon} alt="" />
            </div>

            <div className="service-icon-time">
              <img className="" src={timeIcon} alt="" />
              <span>5D</span>
            </div>

            <div className="service-icon w-25">
              <img src={FireIcon} alt="" />
            </div>

            <div className="service-icon w-29">
              <img src={MachineIcon} alt="" />
            </div>

            <div className="service-icon w-29">
              <img src={ArrowIcon} alt="" />
            </div>

            <div className="service-icon w-29">
              <img src={Bandage} alt="" />
            </div>

            <div className="service-icon w-28">
              <img src={heartIcon} alt="" />
            </div>

            <div className="service-icon w-29">
              <img src={bagIcon} alt="" />
            </div>
            {/* <div className="w-29">
              <i
                className="fa-solid fa-ellipsis"
                style={{
                  color: '#0a0a0a',
                  paddingTop: '15px',
                }}></i>
            </div> */}
          </div>
        </div>

        <div className="payment-card-bottom">
          <div className="payment-card-icons">
            <div
              className="payment-icon"
              onClick={() => {
                // handleShowDtail(item);
              }}>
              <i class="fa-solid fa-phone"></i>
            </div>

            <div
              className="payment-icon"
              onClick={() => {
                if (localStorage.getItem('id') !== userData.data.id) {
                  handleBlock && handleBlock(userData.data, 'chat');
                } else if (localStorage.getItem('id') === item.creator_id) {
                  

                  handleBlock &&
                    handleBlock(item.user_details, 'otherUserCHat');
                } else {
                  handleBlock &&
                    handleBlock(item.creator_details, 'otherUserCHat');
                }
              }}>
              <i className="fa-solid icon-plane"></i>
            </div>
          </div>
              {(item.creator_status || item.user_status) &&
          <div
            className="pay-btn calendar-btn"
            onClick={() => {
              handleCommand && handleCommand(item, 'appointmentStatus');
            }}>
            <Button className="new-btn btn-green ml-3 " onClick={() => {}}>
             {localStorage.getItem('id') === item.creator_details.id ? changeStatus(item.creator_status) : changeStatus(item.user_status)}
            </Button>
          </div>
}
        </div>
      </div>
    </div>
  );
};

export default AppointmentItem;
