import React, {useState, useEffect,useLayoutEffect} from 'react';
import API from './Services/SessionServices';
import {useLocation, useHistory, useParams} from 'react-router-dom';
import {errorMessage} from './Utils/ToastMessages';
import {
  getAccomplishmentCategory,
  getAppointmentType,
  getOpportunityType,
} from './Store/Actions/FilterAction';
import {useDispatch,useSelector} from 'react-redux';
//import {onForegroundMessage} from '../src/Firebase/firebase';
import {callChatApi} from './Store/Actions/ChatAction';

import commonApi from './Services/CommonService';
import {DEFAULT_IMAGE} from './Utils/env';
// import CryptoJS from 'crypto-js';
import {setItem, getItem, removeItem} from './Utils/LocalStorage';
import {getSessionCategory} from './Store/Actions/SessionAction';
import {
  getCurrentUserData,
  getUserCompanyData,
  getUrlData,
} from './Store/Actions/DeepLinkAction';
import Scrollbars from 'react-custom-scrollbars';
import {getUserMenuPermesionList} from './Store/Actions/MenuAction';

import {
  parseUrl,
  checkURLAndLogin,
  staticUrls,
  staticUrlsLogout,
  checkUrlForDeepLink,
  checkMenuExists,
  goToZoom
} from './Utils/commonUtils';
import LoaderSpinner from './Components/Modals/LoaderSpinner';

const PasteLinks = props => {
  // USE-STATES

  // const userMenuList = useSelector(state => state.menuActiveList);
  // useLayoutEffect(() => {
  
  //   const {pathname} = window.location;
  //   const urlParts = pathname.split('/').filter(Boolean);


  //   const result = checkUrlForDeepLink()
  //   if(result && localStorage.getItem('id')){
    
  //     console.log(userMenuList,'userMenuList')
  //     if(urlParts.includes('invite') && urlParts.includes('recruitment')){
  //       const result =  checkMenuExists('recruitment', userMenuList);

  //       if(result){

  //       }else{
  //         errorMessage('This Menu is not available to you, Please contact your Administrator');
  //             history.push(userMenuList.menus.menu_details[0].link);
  //       }
  //     }
     
  //   }
  
  //   // const url = window.location.href.split('/').filter(Boolean);
   
  //   // const lastSegment = url[url.length - 1];
  
  //   // console.log('lastSegment:', lastSegment);
  
  //   // const queryString = lastSegment.split('?')[1];
  //   // console.log(queryString,'queryParamsqueryParams')
  
  //   // const queryParams = new URLSearchParams(queryString);
  //   // if (queryParams && queryParams.has('id')) {
  //   //   AddUserInCompany(urlParts)
  //   // }
  
  //   // console.log(queryParams,'queryParamsqueryParams')
  // }, [])
  
  const location = useLocation();
  const [ShowSSModal, setShowSSModal] = useState(false);
  // const [notificationFullData, setnotificationFullData] = useState([]);
  const [notificationData, setnotificationData] = useState([]);
  const history = useHistory({
    forceRefresh: true,
  });
  const showLoader = useSelector(state => state.companyLoader);
  const loginUser = getItem('user');
  const {pathname} = window.location;

  // const searchParams = new URLSearchParams(search);
  // const hasTypeParam = searchParams.has('type');
  const urlPart = pathname.split('/').filter(Boolean);
  const urlString = String(window.location);

  const urlData = parseUrl(urlString);

  const {id: userProfileId} = useParams();

  // REACT-REDUX
  const dispatch = useDispatch();
  let infoUrl = window.location.pathname;

  // FUNCTIONS
  // useEffect(() => {
  
  // if((localStorage.getItem('accountType') && urlPart[0]==='registercompany') || (localStorage.getItem('token') && localStorage.getItem('accountType') )){
  //   history.push('/registercompany')
  // }
  // }, [])
  

  useEffect(() => {
  
    if(getItem('signup')){
      removeItem('signup')
    }
    }, [])

  const getVisitUserData = async data => {
    const modifiedUrl = infoUrl.substring('/c/'.length);

    let payload = {
      location: data.location.address,
      latitude: data.location.latitude,
      longitude: data.location.longitude,
      login_id: localStorage.getItem('id') ? localStorage.getItem('id') : '',
      // ip_address:'',
      platform: data.browserName ? 'web' : 'ios',
      ip_address: data.ip,
      model: data.browserName,
      code: modifiedUrl,
    };
    await commonApi.add_compaign_logs(payload);
    history.push('/');
  };
  // getuserData who visit web

  const getIpAddress = async () => {
    try {
      const response = await fetch('https://api.ipify.org?format=json');
      const data = await response.json();

      return data.ip;
    } catch (error) {
      console.error('Error fetching IP address:', error);
      return null;
    }
  };

  const getLocationAndGeocode = async () => {
    try {
      // Step 1: Obtain Geographic Location (Latitude and Longitude)
      const location = await getLocation();

      // Step 2: Obtain City, Country, and Address through Reverse Geocoding
      if (location) {
        const {latitude, longitude} = location;
        const geocodeData = await reverseGeocode(latitude, longitude);

        return {
          latitude,
          longitude,
          city: geocodeData.city,
          country: geocodeData.country,
          address: geocodeData.address,
        };
      } else {
        console.error('Geolocation not supported or denied by the user.');
        return null;
      }
    } catch (error) {
      console.error('Error fetching location:', error);
      return null;
    }
  };

  const reverseGeocode = async (latitude, longitude) => {
    try {
      const response = await fetch(
        `https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}`,
      );
      if (response.ok) {
        const data = await response.json();
        return {
          city: data.address.city,
          country: data.address.country,
          address: data.display_name,
        };
      }
    } catch (error) {
      console.error('Error reverse geocoding:', error);
    }
    return {};
  };

  const getLocation = async () => {
    return new Promise((resolve, reject) => {
      if ('geolocation' in navigator) {
        navigator.geolocation.getCurrentPosition(
          position => {
            const {latitude, longitude} = position.coords;
            resolve({latitude, longitude});
          },
          error => {
            console.error('Error getting location:', error);
            resolve(null);
          },
        );
      } else {
        resolve(null);
      }
    });
  };

  const checkCompanyUserExist = async urlParts => {
  
    let payload;

    if (/^\d+$/.test(urlParts[0])) {
      payload = {
        company_name: '',
        user_id: urlParts[0],
      };
    } else {
      payload = {
        company_name: urlParts[0].replace(/-/g, ' '),
        user_id: urlParts[1],
      };
    }

    try {
      // response.user_exist || response.company_exist
      const response = await commonApi.company_info(payload);

      // localStorage.setItem('companyExist',response.company_exist)
    


      if (
        urlData.companyName !== '' &&
        response.company_exist &&
        urlData.userId !== '' &&
        response.user_exist
      ) {
        hitApi();
        // dispatch(getCurrentUserData({user_id: '', username: urlParts[0]}));
        
        if (/^\d+$/.test(urlParts[0])) {
          dispatch(
            getUserCompanyData({company_name: '', user_id: urlData.userId}),
          );
        } else {
          dispatch(
            getUserCompanyData({
              company_name: urlParts[0].replace(/-/g, ' '),
              user_id: urlData.userId,
            }),
          );
        }
      } else if (/^\d+$/.test(urlParts[0]) && response.user_exist) {
        hitApi();
        // dispatch(getCurrentUserData({user_id: '', username: urlParts[0]}));

        if (/^\d+$/.test(urlParts[0])) {
          dispatch(
            getUserCompanyData({company_name: '', user_id: urlParts[0]}),
          );
        } else {
          dispatch(
            getUserCompanyData({
              company_name: urlParts[0].replace(/-/g, ' '),
              user_id: urlParts[1],
            }),
          );
        }
      } else if (userProfileId) {
        errorMessage('Invalid url.');
      } else {
        // errorMessage('Invalid url');
        history.push('/');
      }
    } catch (error) {
      // Handle errors here
      console.error('Error:', error);
    }
  };

  const checkCompany_UserExist = async urlParts => {
   
   
    let payload;

    if (/^\d+$/.test(urlParts[0])) {
      payload = {
        // company_name: '',
        user_id: urlParts[0],
      };
    } else {
      payload = {
        company_name: urlParts[0] ? urlParts[0].replace(/-/g, ' ') : '',
        // user_id: urlParts[1],
      };
    }

    try {
      // response.user_exist || response.company_exist
      const response = await commonApi.company_info(payload);

      if (
        (urlParts[0] !== '' && response.company_exist) ||
        (urlData.userId !== '' && response.user_exist)
      ) {

        hitApi();
        // dispatch(getCurrentUserData({user_id: '', username: urlParts[0]}));

        if (/^\d+$/.test(urlParts[0])) {
          dispatch(
            getUserCompanyData({company_name: '', user_id: urlParts[0]}),
          );
        } else {
          dispatch(
            getUserCompanyData({company_name: urlParts[0].replace(/-/g, ' ')}),
          );
        }
      } else if (/^\d+$/.test(urlParts[0]) && response.user_exist) {
        hitApi();
        // dispatch(getCurrentUserData({user_id: '', username: urlParts[0]}));

        if (/^\d+$/.test(urlParts[0])) {
          dispatch(
            getUserCompanyData({company_name: '', user_id: urlParts[0]}),
          );
        } else {
          dispatch(
            getUserCompanyData({
              company_name: urlParts[0].replace(/-/g, ' '),
              user_id: urlParts[1],
            }),
          );
        }
      }

      // else if(userProfileId) {
      //   errorMessage("Invalid url");

      // }
      else {
        const result = checkURLAndLogin(
          staticUrls,
          urlParts[0] === undefined ? '' : urlParts[0],
          getItem('id') ? true : false,
        );

      
        if (result) {
          // alert('aya3');
        } else {
          if (getItem('id') && urlParts[0] !=='') {
           
            history.push('/');
            if(urlParts[0] !=='registercompany'){
              errorMessage('Invalid url.');
            }
           
          } else {
            const result = checkURLAndLogin(
              staticUrlsLogout,
              urlParts[0],
              getItem('id') ? false : true,
            );

            if (result || (urlParts[0] ==='registercompany' && localStorage.getItem('loginNum')) ) {
            } else if (urlParts[0]) {
              history.push('/');
              errorMessage('Invalid url.');
            }
          }
        }
      }
    } catch (error) {
      // Handle errors here
      console.error('Error:', error);
    }
  };

  // const userAgent = navigator.userAgent;
  // const browserName = (userAgent.match(
  //   /(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i,
  // ) || [])[1];
  // const deviceName = navigator.platform;
  ///end
  // USE-EFFECTS





  


  useEffect(() => {
    setItem('consult', '');
    // var urlString = 'https://www.tryingtobuild.com/asadumairs/session?id=211';
    // var urlString = https://www.tryingtobuild.com/asadumairs/opportunity?id=16
    // var urlString =   www.tryingtobuild.com/optptpp/event?id=114
    // var urlString = https://www.tryingtobuild.com/muhammadnadeem/profile?id=1
    const urlString = String(window.location);
    // let url = window.location.pathname;
    // let infoUrl = window.location.pathname;
    // const id = urlString.split('=')[1];
    // url = url.split(/[.\-/]/);
    var currentURL = window.location.href;

    // const lowercasePath = infoUrl.toLowerCase();
    // var regex = /c\/\d+/;
    if (currentURL.includes('c/')) {
      // getVisitUserData()
      //   history.push('/')

      const fetchData = async () => {
        const ip = await getIpAddress();
        const location = await getLocationAndGeocode();
        const userAgent = navigator.userAgent;
        const browserName = (userAgent.match(
          /(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i,
        ) || [])[1];
        const deviceName = navigator.platform;

        getVisitUserData({ip, location, browserName, deviceName});
      };

      fetchData();
    }

    // hitApi();
    dispatch(getAccomplishmentCategory());
    const {pathname} = window.location;

    // const searchParams = new URLSearchParams(search);
    // const hasTypeParam = searchParams.has('type');
    const urlParts = pathname.split('/').filter(Boolean);

    const urlData = parseUrl(urlString);

    dispatch(getUrlData(urlData));

    if (loginUser && urlParts[0] === 'login') {
      history.push('/');
    }

    // if (urlParts[0] === 'notify_profile') {
    //   dispatch(getCurrentUserData({user_id: urlParts[1]}));
    // }

    if (
      (/^\d+$/.test(urlParts[0]) && !urlParts[1]) ||
      (!/^\d+$/.test(urlParts[0]) && !urlParts[1])
    ) {
      if (urlData.userId === getItem('id')) {
        errorMessage("Can't use your own qr.");
        history.push('/');
      } else {
        checkCompany_UserExist(urlParts);
      }
    } else if (
      // (pathname && (pathname.endsWith('s') || /^\d+$/.test(urlParts[0]) || /^\d+$/.test(urlParts[1])))
      (/^\d+$/.test(urlParts[0]) && urlData.userId !== '') ||
      /^\d+$/.test(urlParts[1])
    ) {
      // const urlParts = pathname.split('/').filter(Boolean);
      if (urlData.userId === getItem('id')) {
        errorMessage("Can't use your own qr.");
        history.push('/');
      } else {
        checkCompanyUserExist(urlParts);

        // if (urlParts[1] && urlParts[1].endsWith('s')) {
        //   history.push('/qrlinks/' + urlParts[1]);
        // } else if (urlParts[1]) {
        //   const query = searchParams.get('type');
        //   const profileId = searchParams.get('id');
        //   if (query) {
        //     history.push('/qrlinks/' + urlParts[1] + '/' + query);
        //   }
        //   if (profileId) {
        //     history.push('/qrlinks/' + urlParts[1] + '/' + profileId);
        //   }
        // }
      }
    } else {
      const pathSegments =  window.location.pathname.split('/');

     
      if (pathSegments.length > 1) {
        pathSegments[1] = ':companyName';
      }

      const dynamicPath = pathSegments.join('/');
      const result = checkURLAndLogin(
        staticUrls,
        dynamicPath,
        getItem('id') ? true : false,
      );

      

      if (result) {

        const idExist = checkUrlForDeepLink()
        if(idExist){
         
        }else{
          history.push('/');
          errorMessage('Invalid url.');
        }
       

        // alert('aya3');
      } else {
        if (getItem('id')) {
          // alert('aya2');
          history.push('/');
          errorMessage('Invalid url.');
        } else {

          const pathSegments =  window.location.pathname.split('/');

     
      if (pathSegments.length > 1) {
        pathSegments[1] = ':companyName';
      }

      const dynamicPath = pathSegments.join('/');


          const result = checkURLAndLogin(
            staticUrlsLogout,
            dynamicPath,
            getItem('id') ? false : true,
          );

         

          if (result) {
            const idExist = checkUrlForDeepLink()
            if(idExist){
    
            }else{
              history.push('/');
              errorMessage('Invalid url.');
            }
          } else {
            
            history.push('/');
          }
        }
      }
      // errorMessage("Invalid url");
      // history.push('/');

      //  errorMessage('Invalid url')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);



  const AddUserInCompany = ( urlParts) => {
    const payload = {
      company_name: urlParts[0] ? urlParts[0].replace('-', ' ') : '',
      user_id: localStorage.getItem('id'),
    };

    commonApi
      .add_user_in_company(payload)
      .then(res => {
        
        

      })
      .catch(err => {
        console.log('Err', err);
        return { type: 'error', message: err.message };
      });
  };





  // onForegroundMessage()
  //   .then(payload => {

  //     // setnotificationFullData(payload);

  //     let notify = JSON.parse(payload.data.key);

  //     setnotificationData(notify);

  //     if (['following', 'location', 'accomplishment'].includes(notify.type)) {
  //       dispatch(getCurrentUserData({user_id: notify.id}));
  //       setShowSSModal('in ');
  //     } else if (
  //       [
  //         'opportunityMessage',
  //         'personalMessage',
  //         'personalChatInvitation',
  //         'sessionChatInvitation',
  //         'personalChatAccept',
  //         'emoji',
  //         'opportunity_emoji',
  //       ].includes(notify.type) &&
  //       location.pathname === '/messages'
  //     ) {
  //       dispatch(callChatApi());
  //     }
  //     // else if (
  //     //   notify.type === 'session' &&
  //     //   (location.pathname === '/sessions-events'
  //     //   || location.pathname === '/')
  //     // ) {
  //     //   alert('')
  //     // }
  //     else if (
  //       notify.type === 'opportunityMessage' &&
  //       location.pathname === '/opportunities'
  //     ) {
  //     } else if (
  //       [
  //         'referBuilder',
  //         'referEvent',
  //         'referSession',
  //         'referOpportunity',
  //         'feedback',
  //         'referQuestion',
  //         'referGroupchat',
  //         'referAppointment',
  //       ].includes(notify.type) &&
  //       location.pathname === '/referrals'
  //     ) {
  //     } else {
  //       let chatName = getItem('currentChatRoom');
  //       if (
  //         notify.type === 'personalMessage' &&
  //         notify.chat_detail.chat_room_name === chatName
  //       ) {
  //         dispatch(callChatApi());
  //       } else {
  //         setShowSSModal('in ');
  //       }
  //     }

  //     // else if (notify.type === "personalChatInvitation") {

  //     // }
  //   })
  //   .catch(err =>
  //     console.log(
  //       'An error occured while retrieving foreground message. ',
  //       err,
  //     ),
  //   );
  useEffect(() => {

    //add by daniyal for landing page and link profile
    if ((getItem('outsiderforlivechat') ==='remove' || getItem('token')) && !getItem('id')) {
      localStorage.clear();
    }
  }, []);
  const hitApi = () => {
    dispatch(getAccomplishmentCategory());
    dispatch(getAppointmentType());
    dispatch(getOpportunityType());
    dispatch(getSessionCategory());
  };

  const joinTheSessionNew = async id => {
    let payload = {conference_id: id};

    try {
      const response = await API.is_session_started(payload);

      if (response.started) {
        if (response.session_id) {
          // alert(localStorage.getItem('conferenceId'));
          // localStorage.setItem('conferenceId', response.session_id);
          // localStorage.setItem('refresh_call', 'yes');
          // localStorage.setItem('conf_id', id);
          // history.push('/session-details');
          goToZoom(response.session_id);
          // window.location.reload();
        } else {
        }
      } else {
        //  errorMessage(response.message);
        // history.push('/');
        // window.location.reload();
      }
    } catch (err) {}
  };

  useEffect(() => {
    if (getItem('id')) {
      dispatch(getUserMenuPermesionList());
      var session_started = localStorage.getItem('session_started');
      // var hideCheck = localStorage.getItem('hide_case');
      // if (hideCheck == 'yes') {
      localStorage.setItem('session_started', '');
      localStorage.setItem('hide_case', '');

      // joinTheSessionNew(session_started);
    }

    //}
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const joinTheSessionNotification = async id => {
    let payload = {conference_id: id};
    const response = await API.is_session_started(payload);

    setShowSSModal('');
    if (response.started) {
      if (response.session_id) {
        goToZoom(response.session_id);
      } else {
      }
    } else {
      errorMessage(response.message);
    }
  };

  // pushnotification redirect
  const openProfile = async id => {
    //   http://localhost:3000/bachajhamura/profile?id=1386
    //  history.push('/bachajhamura/profile?id=' + id);
    setShowSSModal('');
    // history.push('/events/profile_invite/' + id);
    history.push('/notify_profile/' + id);
    // window.location.reload();
  };
  const openProfileForTickets = async id => {
    setShowSSModal('');

    history.push('/profile');
  };

  const openPersonalChat = () => {
    setShowSSModal('');
    history.push({
      pathname: '/messages',
      state: {
        typeIs: 'chats', // group_chat_detail
        chatDetail: notificationData.chat_detail
          ? notificationData.chat_detail
          : notificationData.group_chat_detail,
      },
    });
  };
  const openOpportunitiesChat = () => {
    setShowSSModal('');
    history.push({
      pathname: '/messages',
      state: {
        typeIs: 'opportunityMessages',
        chatDetail: notificationData.opportunity_detail,
      },
    });
  };

  const openPersonalChatInvitation = () => {
    setShowSSModal('');
    history.push({
      pathname: '/messages',
      state: {
        typeIs: 'subInvites',
        chatDetail: notificationData.group_chat_detail,
      },
    });
  };

  const openEvents = () => {
    setShowSSModal('');
    history.push({
      pathname: '/meetups',
      state: {
        typeIs: 'events',
        event: notificationData.data,
      },
    });
  };
  // const openEventsMedia= () => {
  //   setShowSSModal('');
  //   history.push({
  //     pathname: '/sessions-events',
  //     state: {
  //       typeIs: 'events',
  //       event: notificationData.event,
  //     },
  //   });
  // };

  const openSession = () => {
    setShowSSModal('');
    history.push({
      pathname: '/meetups',
      state: {
        typeIs: 'session',
        session: notificationData.data, // session
      },
    });
  };
  const openSessionInvite = () => {
    setShowSSModal('');
    history.push({
      pathname: '/meetups',
      state: {
        typeIs: 'openSessionInvite',
        session: notificationData.session, // session
      },
    });
  };

  const openOpportunities = () => {
    setShowSSModal('');
    history.push({
      pathname: '/opportunities',
      state: {
        typeIs: notificationData.type,
        status: notificationData.status,
        opportunityDetail: notificationData.opportunity_detail,
      },
    });
  };
  const OpportunityCall = () => {
    setShowSSModal('');
    history.push({
      pathname: '/messages',
      state: {
        typeIs: notificationData.type,
        // status: notificationData.status,
        opportunity_id: notificationData.opportunity_id,
        chat_room_id: notificationData.chat_room_id,
      },
    });
  };
  const messagesEmoji = () => {
    setShowSSModal('');
    history.push({
      pathname: '/messages',
      state: {
        typeIs: notificationData.type,
        // status: notificationData.status,
        id: notificationData.type_id,
      },
    });
  };

  const openScheduleAppointment = () => {
    setShowSSModal('');
    history.push({
      pathname: '/profile',
      state: {
        typeIs: notificationData.type,
      },
    });
  };

  const openReferPage = () => {
    setShowSSModal('');
    history.push({
      pathname: '/referrals',
      state: {
        typeIs: notificationData.type,
        // status: notificationData.status,
        referredId: notificationData.referred_id,
      },
    });
  };
  const openQuestionTab = () => {
    setShowSSModal('');
    history.push({
      pathname: '/opportunities',
      state: {
        typeIs: notificationData.type,
      },
    });
  };

  //// ========== Google Calendar Integration ========== ////

  // const getRefreshToken = () => {
  //   commonApi.get_refresh_token().then(response => {
  //     if (response.refresh_token.length > 0) {
  //       response.refresh_token.forEach(element => {
  //         getAccessTokenFromRefreshToken(element.refresh_token);
  //       });
  //     }
  //   });
  // };

  // const getEventsData = accessToken => {
  //   const calendarId = 'primary';
  //   const timeMin = new Date().toISOString();
  //   const maxResults = 100;

  //   const apiUrl = `https://www.googleapis.com/calendar/v3/calendars/${calendarId}/events?timeMin=${timeMin}&orderBy=startTime&singleEvents=true&maxResults=${maxResults}`;

  //   // Set up the request headers
  //   const headers = {
  //     Authorization: `Bearer ${accessToken}`,
  //     'Content-Type': 'application/json',
  //   };

  //   // Make the API request
  //   fetch(apiUrl, {headers})
  //     .then(response => {
  //       if (response.ok) {
  //         return response.json();
  //       }
  //       throw new Error('Failed to fetch events data');
  //     })
  //     .then(data => {

  //       commonApi.add_google_calenders_events({
  //         items: data.items,
  //         calender_id: '',
  //       });
  //     })
  //     .catch(error => {
  //       console.error('Error fetching events data:', error);
  //     });
  // };

  const okayButton = () => {
    if (
      ['create_session', 'session_started', 'session'].includes(
        notificationData.type,
      )
    ) {
      if (notificationData.conference_id) {
        joinTheSessionNotification(notificationData.conference_id);
      } else {
        joinTheSessionNotification(notificationData.session.id);
      }
    }

    if (
      ['following', 'location', 'accomplishment'].includes(
        notificationData.type,
      )
    ) {
      openProfile(notificationData.id);
    }

    if (
      [
        'personalMessage',
        'sessionChatInvitation',
        'personalChatAccept',
      ].includes(notificationData.type)
    ) {
      openPersonalChat();
    }

    if (
      ['personalChatInvitation', 'opportunityChatInvitation'].includes(
        notificationData.type,
      )
    ) {
      openPersonalChatInvitation();
    }

    // if (['event'].includes(notificationData.type)) {
    //   openEvents();
    // }

    if (
      notificationData &&
      notificationData.data &&
      notificationData.data.event === 'yes'
    ) {
      openEvents();
    }

    // if (['event_media'].includes(notificationData.type)) {
    //   openEventsMedia();
    // }

    if (
      (notificationData &&
        notificationData.data &&
        notificationData.data.event === 'no') ||
      ['session_reminder', 'upcomingSession'].includes(notificationData.type)
    ) {
      openSession();
    }
    if (['session'].includes(notificationData.type)) {
      openSessionInvite();
    }

    if (
      ['opportunity', 'favouriteOpportunity'].includes(notificationData.type)
    ) {
      openOpportunities();
    }

    if (['opportunityMessage'].includes(notificationData.type)) {
      openOpportunitiesChat();
    }

    if (['scheduleAppointment'].includes(notificationData.type)) {
      openScheduleAppointment();
    }

    if (['send_ticket'].includes(notificationData.type)) {
      openProfileForTickets();
    }

    if (['favouriteQuestion'].includes(notificationData.type)) {
      openQuestionTab();
    }

    if (
      [
        'referBuilder',
        'referEvent',
        'referSession',
        'referOpportunity',
        'feedback',
        'referQuestion',
        'referGroupchat',
        'referAppointment',
      ].includes(notificationData.type)
    ) {
      openReferPage();
    }

    if (['emoji', 'opportunity_emoji'].includes(notificationData.type)) {
      messagesEmoji();
    }

    if (['opportunity_call'].includes(notificationData.type)) {
      OpportunityCall();
    }
  };

  useEffect(() => {
    // getRefreshToken();
    setItem('lastOppoCall', '');
    setItem('lastOppoCallData', '');
  }, []);
  useEffect(() => {
    if (ShowSSModal) {
      setTimeout(() => {
        setShowSSModal('');
      }, 5000);
    }
  }, [ShowSSModal]);

  

  return (
    <>
    
   
    {ShowSSModal &&
    notificationData &&
    notificationData && (
      <div className="app-notifications-wrapper">
        <Scrollbars>
          <div className="app-notifications-wrapper-inner">
            <div
              className={`notification-items ${ShowSSModal}`}
              onClick={() => {
                okayButton();
              }}>
              <div className="notification-userImg">
                <img
                  src={
                    notificationData.image
                      ? notificationData.image
                      : DEFAULT_IMAGE
                  }
                  alt=""
                />
              </div>
              <div className="notification-content">
                <h3>{notificationData.title}</h3>
                <p>
                  {notificationData.body
                    ? notificationData.body
                    : 'Tap in to check it out.'}
                </p>
              </div>
              <span
                className="close-btn"
                onClick={e => {
                  e.stopPropagation();
                  setShowSSModal('');
                }}>
                <i className="fas fa-times" />
              </span>
            </div>
          </div>
        </Scrollbars>
      </div>
    )}


    {showLoader && (
        <div id="loader" className="loader comapny_changeLoader">

          
          <div className="">
            {/* <img src={RoiLogo} alt="" /> */}
          
            <LoaderSpinner/>
            <div className='comapny_changeLoader_text'>Just a moment while we prepare everything for you.</div>
          </div>
        </div>
      )}
    </>

    
  );
};
export default PasteLinks;
