import React, {useState, useRef, useEffect, useCallback} from 'react';
import {useHistory} from 'react-router-dom';
import {
  Col,
  Row,
  Form,
  Popover,
  OverlayTrigger,
  Tooltip,
} from 'react-bootstrap';
import Sidebar from '../Sidebar';
// import Slider from 'react-slick';
// import { PersonWebSlider, StageAudience, SessionVideoGrid } from "../../Components";
// import { Scrollbars } from "react-custom-scrollbars";
import {labels} from '../../Constants/Translations';
import {errorMessage, successMessage} from '../../Utils/ToastMessages';
import {selected_lang} from '../../Utils/env';
import {db} from '../../Firebase/firebase';
import {ref, set, onValue,  off} from 'firebase/database';
import VoxeetSDK from '@voxeet/voxeet-web-sdk';
import {Button} from 'react-bootstrap';
// import { ProfileModal } from "../../Components";
import ProfileComponent from '../../Components/ProfilePage/ProfileComponent';
import messageService from '../../Services/MessageService';
// import StageRequestModal from "../../Components/SessionDetails/StageRequestModal";
import API from '../../Services/SessionServices';
import {emojisData} from '../../Constants/emojis';
// import { S3_EMOJI_DEV, DEFAULT_IMAGE } from "../../Utils/env";
// import { Link } from "react-router-dom";
// import { ThreeDots } from "react-loader-spinner";
// import LoaderModal from '../../Components/Modals/LoaderModal';
import LoaderSpinner from '../../Components/Modals/LoaderSpinner';
import {CustomModal, EmojiModal, Pannels, SlidingPanel} from '../../Components';
import ParticipantStream from '../../Components/SessionDetails/ParticipantStream';
import SmartChatPage from '../../Components/Common/SmartChatPage';
import CreateSession from '../../Components/SessionDetails/CreateSession';
import {DEFAULT_IMAGE} from '../../Utils/env';
// import  {consts} from 'react-elastic-carousel';
import {Swiper, SwiperSlide} from 'swiper/react';
import { Navigation} from 'swiper';
import {App_key_Dolby, App_Secret_key_Dolby} from '../../Utils/env';
import commonApi from '../../Services/CommonService';

//import {sessionBgImgs} from '../../Constants';
import {setMenuState} from '../../Store/Actions/CommanAction';
import {connect} from 'react-redux';
import {chatroomInfo, fixName} from '../../Utils/commonUtils';
import Scrollbars from 'react-custom-scrollbars';
import {getItem, setItem} from '../../Utils/LocalStorage';
import {
  ScreenShareActive,
  ScreenShareDisable,
  SessionGroupLight,
  SessionVideoLight,
  VideoCameraActive,
  VideoMuteLine,
  // screenshot2,
} from '../../Constants/Images';
import CreateWorkSpace from '../../Components/WorkSpace/CreateWorkSpace';
import Invite from '../../Components/SingleComponent/Invite';
import CircleListBuilders from './StaticPages/CircleListBuilders';
import SendInvitation from './StaticPages/SendInvitation';



// import { getMessageCounter } from '../../Store/Actions/SessionAction';
// const params = new URLSearchParams(window.location.search);
// const cell = params.get('cell') || 'test987654321';
// const params = new URLSearchParams(window.location.search);
// const paraID = params.get("id");

// const consumerKey = "ZE3H_Isja0ubHO0meLLpEQ==";
// const consumerSecret = "L-Clj_3PQRzN6Xbev3UNjePbdwAP3fczzA5OJZVey5w=";
const consumerKey = App_key_Dolby;
const consumerSecret = App_Secret_key_Dolby;

VoxeetSDK.initialize(consumerKey, consumerSecret);
//class SessionDetails extends Component {
const SessionDetails = props => {
  // let {sessionId} = useParams();


  const history = useHistory();
  const [ProfileState, setProfileState] = useState(false);
  const [stageRequest, setStageRequest] = useState(false);
  const [stageRequestLoader, setStageRequestLoader] = useState(false);
  const [chatLoader, setChatLoader] = useState(false);
  // const [paraID, setParaID] = useState(params.get('id'));
  const [emojisDataArray, setemojisDataArray] = useState([]);
  // const [allVideos, setVideos] = useState([]);
  const [leaveModal, setleaveModal] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showEmojiModal, setshowEmojiModal] = useState(false);
  const [allSesionData, setAllSesionData] = useState([]);
  const [sessionBgImgs, setsessionBgImgs] = useState([]);
  const [selectedParticipitant, setselectedParticipitant] = useState([]);
  const [LastRequest, setLastRequest] = useState([]);
  const [glryButton, setglryButton] = useState(true);
  const [persons, setPersons] = useState([]);
  // const usersRef = useRef(persons);

  const [workspaceData, setWorkspaceData] = useState([]);

  // const [allParticipitants, setParticipitant] = useState([]);
  // const [lastPerson, setLastPerson] = useState([]);
  // const [stage, setStage] = useState([]);
  // const [videoBtn, setvideoBtn] = useState(false);
  // const [moderator, setModerator] = useState([]);
  // const [audiance, setAudiance] = useState([]);
  const [isVideoEnable, setVideoVisible] = useState(true);
  const [isAudioEnable, setAudioEnable] = useState(false);
  const [iamModerator, setiamModerator] = useState(false);
  // const [onStage, setonStage] = useState(false);
  const isVideoClass = isVideoEnable ? '' : 'active';
  const [activeVideos, setActivevideos] = useState(0);
  const videoCountRef = useRef(activeVideos);
  const [showBtns, setshowBtns] = useState(false);
  // var iamModerator = false;
  // const [selected, setSelectedVideo] = useState(0);
  const [gridType, setGridType] = useState(true);
  const listTypeClass = !gridType ? 'active' : '';
  const gridTypeClass = gridType ? 'active' : '';
  // const [inviteExternal, setExternal] = useState(false);
  // const [chooseCircle, setChooseCircle] = useState(false);
  const [inviteUser, setInviteUser] = useState(false);
  const [notInviteUser, setNotInviteUser] = useState([]);
  const [inviUserType, setInviUserType] = useState('Moderators');
  // const [searchQry, setSearchQry] = useState('');
  const [showLoader, setShowLoader] = useState(false);
  const [allRequest, setallRequest] = useState([]);
  const [shareScreenstatus, setshareshareScreenstatus] = useState(false);
  // const [stageModerator, setstageModerator] = useState(false);
  const [showApplink, setshowApplink] = useState(false);
  const [showMessages, setShowMessages] = useState(false);
  const [websiteLink, setwebsiteLink] = useState('');
  const [titleOFwebLinks, setTitleOFwebLinks] = useState('');
  const [allLinks, setallLinks] = useState([]);
  const [linkData, setlinkData] = useState([]);
  const [chatroom, setchatroom] = useState({});

  const [moderatorList, setModeratorList] = useState([]);
  const [audienceList, setAudienceList] = useState([]);
  const [videoStreamList, setVideoStreamList] = useState([]);
  const [jdSpecialM, setJdSpecialM] = useState([]);
  const [jdSpecialA, setJdSpecialA] = useState([]);
  const [jdSpecialS, setJdSpecialS] = useState([]);

  const [allJdEmojis, setAllJdEmojis] = useState([]);
  const [jdAllUsers, setJdAllUsers] = useState([]);

  const [sampleStateV, setSampleStateV] = useState(0);
  // const [sampleStateP, setSampleStateP] = useState(0);

  const [isHandActive, setHandActive] = useState(false);
  const [superModerator, setSuperModerator] = useState(false);
  const [isUserLeftSession, setUserLeftSession] = useState(false);
  const [ShowSSModal, setShowSSModal] = useState(false);
  const [showNotes, setShowNotes] = useState(false);
  const [showCreateEvent, setShowCreateEvent] = useState(false);
  const [wittenNotes, setWittenNotes] = useState('');
  const [totalVideo, setTotalVideo] = useState(0);
  const [sessionData, setSessionData] = useState('');

  const [showComponent, setShowComponent] = useState('');
  // const [refresh, setRefresh] = useState(false);
  // const [userTypeLoader, setUserTypeLoader] = useState(false);

  // const [isDisabled, setIsDisabled] = useState(false);
  const [showBgImgs, toggleBgImgs] = useState(false);
  const [selectedSessionBg, setSessionBg] = useState('');
  const bgImgsClass = showBgImgs ? 'active' : '';
  const activeBgCss = selectedSessionBg
    ? {backgroundImage: `url(${selectedSessionBg})`}
    : {};
  const defaultCheckClass = selectedSessionBg === '' ? 'fas' : 'far';

  // const [permissionGranted, setPermissionGranted] = useState(false);

  const [subTabs, setSubTabs] = useState('moderator');
  const [moderator, setModerator] = useState([]);
  const [audience, setAudience] = useState([]);
  const [selectedModData, setSelectedModData] = useState([]);
  const [selectedAudData, setSelectedAudData] = useState([]);
  const [originalDataOfMod, setOriginalDataOfMod] = useState([]);
  const [originalDataOfAud, setOriginalDataOfAud] = useState([]);
  const [contact, setContact] = useState([]);
  const [unRead, setUnRead] = useState(false);
  // const [count, setCount] = useState(0);
  // const dispatch = useDispatch();
  // const [xfbFolder, setxFbFolder] = useState('chats');
  const xfbFolder = 'chats'
  const [groupChatName, setGroupChatName] = useState('');

  const [inviteLoader, setInviteLoader] = useState(true);
  const [inviteFollowers, setInviteFollowers] = useState([]);
  const [followersList, setFollowersList] = useState([]);
  const [inviteQry, setInviteQry] = useState('');


  const popover = (
    <Popover id="popover-basic">
      {/* <Popover.Header as="h3">Popover right</Popover.Header> */}
      <Popover.Body>{allSesionData.session_description}</Popover.Body>
    </Popover>
  );

  // const showInvites = () => {
  //   setExternal(false);
  //   setChooseCircle(false);
  // };
  // const myRefs = useRef([]);

  // const settings = {
  //   dots: false,
  //   arrows: true,
  //   infinite: false,
  //   slidesToShow: 4,
  //   slidesToScroll: 1,
  // };

  // const [conference_id, setconference_id] = useState(sessionId);
 

  // const settingsWeb = {
  //   dots: false,
  //   arrows: false,
  //   infinite: false,
  //   slidesToShow: 2,
  //   slidesToScroll: 1,
  //   autoplay: true,
  //   speed: 500,
  //   autoplaySpeed: 3000,
  // };
  const handleChange = e => {
    setWittenNotes(e.target.value);
    // setShowNotes()
  };
  const UpdateBroadcastMessage = (type, subType, message) => {
   
    

    

    const database = ref(
      db,
      type + '/' + subType + '/' + localStorage.getItem('conf_id'),
    );

    set(database, message).then(
      () => {},
      () => {},
    );
  };
  const handleSubmit = e => {
    // e.preventDefault();
    addSessionNotes(wittenNotes);
    setShowNotes(false);
    successMessage('Note added successfully.');
  };

  useEffect(() => {
    setTotalVideoz();
  }, [isVideoEnable, shareScreenstatus]);

  const PersonWebItem = ({item}) => {
    
    return (
      <div className="person-web-item">
        <div className="person-img">
          <img src={item.img ? item.img : DEFAULT_IMAGE} alt="" />
        </div>
        <div className="person-web-link">
          <a href={item.link} target="_blank" rel="noopener noreferrer">
            {/* {fixName(item.name.split(" ")[0], item.name.split(" ")[1])}’s
            website */}
            {item.title}
          </a>
        </div>
      </div>
    );
  };
  
  const getWebsitelinks = id => {
    var data = {session_id: id};
    commonApi.get_website_link(data).then(response => {
      if (response.links && response.links.website_links) {
        
        setlinkData(JSON.parse(response.links.website_links));
        makeLinks(JSON.parse(response.links.website_links));
        // setallLinks(links);
      }
    });
  };

  const handleWeblink = () => {
    try {
      if (Boolean(new URL(websiteLink))) {
        const xyzurl = new URL(websiteLink);
        addLink(xyzurl.href, titleOFwebLinks);
      }
    } catch (e) {
      errorMessage('Please enter a valid URL.');
    }
  };

  const makeLinks = data => {
    var links = [];
    // data.forEach((e1) => {
    data.forEach(e2 => {
      var ar = {
        name: e2.linkCreator.name,
        img: e2.linkCreator.avatarUrl,
        link: e2.url,
        title: e2.title,
      };
      links.push(ar);
    });
    // });
    setallLinks(links);
  };
  const addLink = (urlIs, titleLink) => {
    setShowLoader(true);
    if (false && linkData.length > 0) {
      linkData.forEach(e => {
        if (e.user_detail.id === localStorage.getItem('user')) {
          e.website_links.push(websiteLink);
          var data = {
            user_id: localStorage.getItem('user'),
            session_id: allSesionData.id,
            website_links: e.website_links,
          };
          commonApi
            .add_app_link(data)
            .then(res => {
              if (res) {
                var array_links = {
                  name: e.user_detail.first_name,
                  img: e.user_detail.profile_img,
                  link: websiteLink,
                };
                messageService.sendMessage({
                  id: localStorage.getItem('user'),
                  name: 'add_link',
                  link: array_links,
                });
                setwebsiteLink('');
                successMessage(res.message);
                VoxeetSDK.command
                  .send({
                    dataPayload: {
                      id: localStorage.getItem('user'),
                      name: 'add_link',
                    },
                  })
                  .then(succ => {})
                  .catch(err => {
                    console.log(err);
                  });

                UpdateBroadcastMessage(
                  'sessionEvent',
                  'reload_links',
                  JSON.stringify({
                    type: 'reload_links',
                    sent_at: new Date().getTime(),
                  }),
                );

                setShowLoader(false);
              }
            })
            .catch(err => {
              console.log('Err', err);
              setShowLoader(false);
              return {type: 'error', message: err.message};
            });
        }
      });
    } else {
      var arr = [
        {
          title: titleLink,
          id: localStorage.getItem('id'),
          url: urlIs,
          linkCreator: {
            name:
              localStorage.getItem('firstName') +
              ' ' +
              localStorage.getItem('lastName').slice(0, 1).toUpperCase() +
              '.',
            externalId: localStorage.getItem('id'),
            avatarUrl: localStorage.getItem('profilePic'),
          },
          // title: titleOFwebLinks,
        },
      ];
      // arr.push(urlIs);
      if (linkData.length > 0) {
        let allLinkz = linkData;
        allLinkz.push(arr[0]);
        arr = allLinkz;
      }
      var data = {
        // user_id: localStorage.getItem("user"),
        session_id: allSesionData.id,
        website_links: JSON.stringify(arr),
      };

      commonApi
        .add_app_link(data)
        .then(res => {
          if (res) {
            var array_links = {
              name: localStorage.getItem('firstName'),
              img: localStorage.getItem('profilePic'),
              link: urlIs,
              title: titleLink,
            };
            messageService.sendMessage({
              id: localStorage.getItem('user'),
              name: 'add_link',
              link: array_links,
            });
            setwebsiteLink('');
            successMessage(res.message);
            VoxeetSDK.command
              .send({
                dataPayload: {
                  id: localStorage.getItem('user'),
                  name: 'add_link',
                },
              })
              .then(succ => {})
              .catch(err => {
                console.log(err);
              });
            UpdateBroadcastMessage(
              'sessionEvent',
              'reload_links',
              JSON.stringify({
                type: 'reload_links',
                sent_at: new Date().getTime(),
              }),
            );
            setShowLoader(false);
            closeApplink();
          }
        })
        .catch(err => {
          console.log('Err', err);
          setShowLoader(false);
          return {type: 'error', message: err.message};
        });
    }
  };

  const collectingUsers = userArr => {
    const chatUsers = [];
    if (Object.entries(userArr).length > 0) {
      userArr.forEach(item => {
        const user = {
          id: item.id,
          first_name: item.name,
          last_name: '',
          profile_img: item.profile_img,
          type: item.type,
        };
        chatUsers.push(user);
      });
    }
    return chatUsers;
  };

  const addSessionNotes = notes => {
    var data = {
      conference_id: localStorage.getItem('conf_id'),
      note: notes,
    };

    commonApi.add_session_notes_dolby(data).then(response => {
      
    });
  };
  const userLeftSession = () => {
    var data = {
      conference_id: localStorage.getItem('conf_id'),
    };

    API.leave(data).then(response => {
      if (response) {
        // successMessage(response.message);

        VoxeetSDK.command
          .send({
            dataPayload: {
              id: localStorage.getItem('user'),
              name: 'left_session',
            },
          })
          .then(succ => {
            const subscriptions_to_disable = [
              {
                type: 'Participant.Joined',
                conferenceAlias: localStorage.getItem('conferenceAlias'),
              },
              {
                type: 'Participant.Left',
                conferenceAlias: localStorage.getItem('conferenceAlias'),
              },
            ];
            VoxeetSDK.notification.unsubscribe(subscriptions_to_disable);
            const participants = VoxeetSDK.conference.participants;
            participants.forEach(element => {
              if (element.info.externalId === localStorage.getItem('user')) {
                VoxeetSDK.conference
                  .leave(element)
                  .then(() => {
                    VoxeetSDK.session.close();
                    history.push('/meetups');
                    detachesChatroom();
                  })
                  .catch(err => console.error(err));
              }
            });
          })
          .catch(err => {
           
            history.push('/meetups');
          });
      }
    });
  };

  const getAllSessionDetails = conf_id => {
    var data = {conf_id: conf_id};
    API.session_data(data)
      .then(response => {
        if (response.data) {
          localStorage.setItem(
            'session_started_name',
            response.data.session_title,
          );
          setSessionData(response.data);
          var UserJoinModerator = false;
          setJdSpecialM([]);
          setJdSpecialA([]);
          setJdSpecialS([]);
          setModeratorList(response.data.moderator_list);
          setAudienceList(response.data.audience_list);
          if (response.data.user_type === 'stage') {
            UserJoinModerator = true;
          }
          if (response.data.user_type === 'moderator') {
            UserJoinModerator = true;
          }
          response.data.moderator_list &&
            response.data.moderator_list.length > 0 &&
            response.data.moderator_list.forEach(item => {
              if (
                parseInt(item.id) === parseInt(localStorage.getItem('user'))
              ) {
                if (item.type === 'moderator') {
                  setSuperModerator(true);
                } else {
                  setSuperModerator(false);
                }
                UserJoinModerator = true;
                setiamModerator(true);
                setshowBtns(true);
              }
              if (item.type === 'stage') {
                setJdSpecialS(arr => [...arr, item.id]);
              }
              setJdSpecialM(arr => [...arr, item.id]);
              setJdAllUsers(current => {
                const found = current.find(objs => objs.id === item.id);
                if (typeof found === 'object') {
                  return current;
                } else {
                  return [...current, item];
                }
              });
            });
          response.data.audience_list &&
            response.data.audience_list.length > 0 &&
            response.data.audience_list.forEach(item => {
              if (
                parseInt(item.id) === parseInt(localStorage.getItem('user'))
              ) {
                setiamModerator(false);
                setshowBtns(false);
                setSuperModerator(false);
              }
              setJdSpecialA(arr => [...arr, item.id]);
              setJdAllUsers(current => {
                const found = current.find(objs => objs.id === item.id);
                if (typeof found === 'object') {
                  return current;
                } else {
                  return [...current, item];
                }
              });
            });
          localStorage.setItem('creator_id', response.data.creator_id);
          setAllSesionData(response.data);
          setTimeout(() => {
            JoinConference(
              localStorage.getItem('conferenceId'),
              UserJoinModerator,
            );
            getWebsitelinks(response.data.id);
          }, 300);
          localStorage.setItem('SessionData', JSON.stringify(response.data));
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };
  const UpdateSessionDetails = conf_id => {
    var data = {conf_id: conf_id};
    API.session_data(data)
      .then(response => {
        if (response.data) {
          if (
            response.data.status === 'finished' &&
            response.data.creator_id === getItem('id')
          ) {
            creatorAcknoledgeLeave();
            return false;
          }
          setJdSpecialM([]);
          setJdSpecialA([]);
          setJdSpecialS([]);
          setModeratorList(response.data.moderator_list);
          setAudienceList(response.data.audience_list);
          response.data.moderator_list &&
            response.data.moderator_list.length > 0 &&
            response.data.moderator_list.forEach(item => {
              if (
                parseInt(item.id) === parseInt(localStorage.getItem('user'))
              ) {
                if (item.type === 'moderator') {
                  setSuperModerator(true);
                } else {
                  setSuperModerator(false);
                }
                setiamModerator(true);
                setshowBtns(true);
              }
              if (item.type === 'stage') {
                setJdSpecialS(arr => [...arr, item.id]);
              }

              setJdSpecialM(arr => [...arr, item.id]);
              setJdAllUsers(current => {
                const found = current.find(objs => objs.id === item.id);
                if (typeof found === 'object') {
                  return current;
                } else {
                  return [...current, item];
                }
              });
            });
          response.data.audience_list &&
            response.data.audience_list.length > 0 &&
            response.data.audience_list.forEach(item => {
              if (
                parseInt(item.id) === parseInt(localStorage.getItem('user'))
              ) {
                setiamModerator(false);
                setshowBtns(false);
                setSuperModerator(false);
              }
              setJdSpecialA(arr => [...arr, item.id]);
              setJdAllUsers(current => {
                const found = current.find(objs => objs.id === item.id);
                if (typeof found === 'object') {
                  return current;
                } else {
                  return [...current, item];
                }
              });
            });
          localStorage.setItem('creator_id', response.data.creator_id);
          localStorage.setItem('SessionData', JSON.stringify(response.data));
          setAllSesionData([]);
          setAllSesionData(response.data);
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };
  const videoStartBtn = () => {
    
    const participants = VoxeetSDK.conference.participants;
    
    if (typeof participants === 'object') {
      const values = Array.from(participants.values());
      const found = values.find(
        objs => objs.info.externalId === localStorage.getItem('user'),
      );
      if (typeof found === 'object') {
        if (shareScreenstatus) {
          VoxeetSDK.conference
            .stopScreenShare()
            .then(() => {
              localStorage.setItem('checkLastVideoEnabled', '');
              UpdateBroadcastMessage(
                'sessionEvent',
                'close_large_video',
                JSON.stringify({
                  type: 'close_large_video',
                  user_id: found,
                  sent_at: new Date().getTime(),
                }),
              );
              setshareshareScreenstatus(false);

              VoxeetSDK.conference
                .startVideo(found)
                .then(() => {
                  localStorage.setItem('checkLastVideoEnabled', 'video');
                  setVideoVisible(false);
                })
                .catch(err => {
                  console.error(err);
                });
            })
            .catch(err => console.error(err));
        } else {
          VoxeetSDK.conference
            .startVideo(found)
            .then(() => {
              localStorage.setItem('checkLastVideoEnabled', 'video');
              setVideoVisible(false);
            })
            .catch(err => console.error(err));
        }
      }
    }
  };
  const changeMic = () => {
    if (isAudioEnable) {
      // setAudioEnable(false);
      const participants = VoxeetSDK.conference.participants;
      participants.forEach(element => {
        if (element.info.externalId === localStorage.getItem('user')) {
          VoxeetSDK.conference
            .stopAudio(element)
            .then(() => {
              setAudioEnable(false);
              UpdateAudioStatys('off', element.info.externalId);
            })
            .catch(err => console.error(err));
        }
      });
    } else {
      // setAudioEnable(true);
      const participants = VoxeetSDK.conference.participants;
      participants.forEach(element => {
        
        if (element.info.externalId === localStorage.getItem('user')) {
          VoxeetSDK.conference
            .startAudio(element)
            .then(() => {
              setAudioEnable(true);
              UpdateAudioStatys('on', element.info.externalId);
            })
            .catch(err => console.error(err));
        }
      });
    }
  };
  const audioStartBtn = () => {
    var micStatus;
    if (isAudioEnable) {
      micStatus = 'off';
    } else {
      micStatus = 'on';
    }
    var data = {
      user_id: localStorage.getItem('user'),
      conference_id: allSesionData.id,
      mic: micStatus,
    };
    API.update_mic_status(data)
      .then(res => {
        if (res) {
          changeMic();
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  // const makeUesrBtn = () => {
  //   var micStatus;
  //   if (isAudioEnable) {
  //     micStatus = 'off';
  //   } else {
  //     micStatus = 'on';
  //   }
  //   var data = {
  //     user_id: localStorage.getItem('user'),
  //     conference_id: allSesionData.id,
  //     mic: micStatus,
  //   };
  //   API.update_mic_status(data)
  //     .then(res => {
  //       if (res) {
  //         const participants = VoxeetSDK.conference.participants;
  //         participants.forEach(element => {
  //           if (element.info.externalId === localStorage.getItem('user')) {
  //             VoxeetSDK.conference
  //               .leave(element)
  //               .then(() => {
  //                 VoxeetSDK.session.close();
  //                 setTimeout(() => {
  //                   JoinConference(
  //                     localStorage.getItem('conferenceId'),
  //                     true,
  //                     true,
  //                   );
  //                 }, 500);
  //                 setiamModerator(true);
  //               })
  //               .catch(err => console.error(err));
  //           }
  //         });
  //         // VoxeetSDK.session.close();
  //         // setTimeout(() => {
  //         //   JoinConference(localStorage.getItem("conferenceId"), true, true);
  //         // }, 100);
  //       }
  //     })
  //     .catch(err => {
  //       console.log('Err', err);
  //       return {type: 'error', message: err.message};
  //     });
  // };

  const kickUser = id => {
    VoxeetSDK.command
      .send({dataPayload: {id: id, name: 'do_leave'}})
      .then(succ => {})
      .catch(err => {
        console.log(err);
      });
    // const participants = VoxeetSDK.conference.participants;
    // participants.forEach((element) => {
    //     if (
    //         element.info.externalId == id) {
    //         VoxeetSDK.conference.kick(element);
    //         VoxeetSDK.command
    //             .send({ dataPayload: { id: id, name: "ban_user" } })
    //             .then((succ) => { })
    //             .catch((err) => {
    //                 console.log(err);
    //             });
    //     }
    // });
  };

  const stopVideo = () => {
    const participants = VoxeetSDK.conference.participants;
    participants.forEach(element => {
      
      if (element.info.externalId === localStorage.getItem('user')) {
        const found =
          element.info.externalId === localStorage.getItem('user')
            ? localStorage.getItem('user')
            : '';
        VoxeetSDK.conference
          .stopVideo(element)
          .then(() => {
            setVideoVisible(true);
            UpdateBroadcastMessage(
              'sessionEvent',
              'close_large_video',
              JSON.stringify({
                type: 'close_large_video',
                user_id: found,
                sent_at: new Date().getTime(),
                check: '4',
              }),
            );
          })
          .catch(err => console.error(err));
      }
    });
  };
  const ShowFullVideo = (id, streamId, stream) => {
    document.getElementById('large-video-container').style.display = 'flex';
    let perspective = 'self-view';
    let videoNode = document.getElementById('video-objectlarge');
    if (!videoNode) {
      videoNode = document.createElement('video');
      videoNode.setAttribute('id', id);
      videoNode.setAttribute('height', '100%');
      videoNode.setAttribute('width', '100%');
      videoNode.muted = false;
      videoNode.autoplay = true;
      videoNode.playsinline = true;

      const videoContainer = document.getElementById(perspective);

      videoContainer.lastElementChild.replaceWith(videoNode);
      videoContainer.firstElementChild.innerText = 'asadss';
    }
    videoNode.volume = 0.0;
    localStorage.setItem('largeVideo', streamId);
    navigator.attachMediaStream(videoNode, stream);
  };

  const JoinConference = (id, moderator, listen) => {
    document.getElementById('ListviewContent').style.display = 'none';
    document.getElementById('GridviewContent').style.display = 'block';
    if (listen) {
    } else {
      localStorage.setItem('allStreamIds', '');
    }

    let data = {
      id: localStorage.getItem('user'),
      conf_id: localStorage.getItem('conf_id'),
    };
    // const optionx = {
    //     method: "POST",
    //     headers: {
    //         "Content-Type": "application/x-www-form-urlencoded",
    //     },
    //     body: JSON.stringify({
    //         id: localStorage.getItem("user"),
    //         conf_id: localStorage.getItem("conf_id"),
    //     }),
    // };
    // fetch("https://apibuild.tryingtobuild.com/api/Circles/haha_user", optionx)
    //     .then((response) => response.json())
    API.haha_user(data).then(response => {
      let micStatusIs = response.data.mic === 'on' ? true : false;
      let last_name = response.data.last_name;
      last_name = last_name.charAt(0).toUpperCase();
      const userName = response.data.first_name + ' ' + last_name + '.';
      let avatarPic = response.data.profile_img
        ? response.data.profile_img
        : DEFAULT_IMAGE;
      const externalId = localStorage.getItem('user');
      const mixer = {
        name: userName,
        externalId: externalId,
        avatarUrl: avatarPic,
      };
      let joinOptions;
      var checkAlreadyStarted = localStorage.getItem('session_started');
      var hideCase = localStorage.getItem('hide_case');
      var checkLastVideo = localStorage.getItem('checkLastVideoEnabled');
      if (checkAlreadyStarted && hideCase === 'yes') {
        if (checkLastVideo === 'video') {
          setTimeout(() => {
            videoStartBtn();
          }, 3000);
        }
        if (checkLastVideo === 'screen') {
          setTimeout(() => {
            stopScreen();
          }, 2000);
          setTimeout(() => {
            shareScreen();
          }, 4000);
        }
        var AllStreams = localStorage.getItem('videoStreams');
        if (AllStreams) {
          setVideoStreamList(JSON.parse(AllStreams));
        }

        streamEvents();
        // var allStreams = localStorage.getItem('videoStreams');
        // if (allStreams) {
        //   console.log(allStreams);
        //   allStreams = JSON.parse(allStreams);
        //   console.log('yooo yooo yooo yooo yooo yooo yooo');
        //   console.log(allStreams);
        //   allStreams.forEach(element => {
        //     console.log('yooobrooo ');
        //     console.log(element);

        //   });
        // }

        const participants = VoxeetSDK.conference.participants;
        
        participants.forEach(element => {
          if (
            element.streams[0] &&
            element?.info?.externalId === getItem('id')
          ) {
            if (element.streams[0].type === 'Camera') {
              if (
                element.streams[0].getAudioTracks() &&
                element.streams[0].getAudioTracks().length > 0
              ) {
                setAudioEnable(true);
              }
              if (
                element.streams[0].getVideoTracks() &&
                element.streams[0].getVideoTracks().length > 0
              ) {
                setVideoVisible(false);
              }
            } else {
              if (
                element.streams[0].getVideoTracks() &&
                element.streams[0].getVideoTracks().length > 0
              ) {
                setshareshareScreenstatus(true);
              }
            }
          }

          if (
            element.streams[1] &&
            element?.info?.externalId === getItem('id')
          ) {
            if (element.streams[1].type === 'Camera') {
              if (
                element.streams[1].getAudioTracks() &&
                element.streams[1].getAudioTracks().length > 0
              ) {
                setAudioEnable(true);
              }
              if (
                element.streams[1].getVideoTracks() &&
                element.streams[1].getVideoTracks().length > 0
              ) {
                setVideoVisible(false);
              }
            } else {
              if (
                element.streams[1].getVideoTracks() &&
                element.streams[1].getVideoTracks().length > 0
              ) {
                setshareshareScreenstatus(true);
              }
            }
          }

          if (element.streams[0] && element.streams[0].getVideoTracks()) {
            setVideoStreamList(current => {
              const found = current.find(
                objs =>
                  objs.participant.info.externalId === element.info.externalId,
              );
              if (typeof found === 'object') {
                return current.map(obj => {
                  if (
                    obj.participant.info.externalId === element.info.externalId
                  ) {
                    return {
                      ...obj,
                      participant: element,
                      stream: element.streams[0],
                    };
                  }
                  return obj;
                });
              } else {
                return [
                  ...current,
                  {
                    participant: element,
                    stream: element.streams[0],
                  },
                ];
              }
            });
          } else if (
            element.streams[1] &&
            element.streams[1].getVideoTracks()
          ) {
            setVideoStreamList(current => {
              const found = current.find(
                objs =>
                  objs.participant.info.externalId === element.info.externalId,
              );
              if (typeof found === 'object') {
                return current.map(obj => {
                  if (
                    obj.participant.info.externalId === element.info.externalId
                  ) {
                    return {
                      ...obj,
                      participant: element,
                      stream: element.streams[1],
                    };
                  }
                  return obj;
                });
              } else {
                return [
                  ...current,
                  {
                    participant: element,
                    stream: element.streams[1],
                  },
                ];
              }
            });
          }
        });
        localStorage.setItem('checkLastVideoEnabled', '');
        localStorage.setItem('hide_case', '');
        UpdateSessionDetails(localStorage.getItem('conf_id'));
      } else {
        var refresh_call = localStorage.getItem('refresh_call');
         checkLastVideo = localStorage.getItem('checkLastVideoEnabled');
        if (refresh_call === 'yes') {
          if (checkLastVideo === 'video') {
            setTimeout(() => {
              videoStartBtn();
            }, 3000);
          }
          if (checkLastVideo === 'screen') {
            setTimeout(() => {
              stopScreen();
            }, 2000);
            setTimeout(() => {
              shareScreen();
            }, 4000);
          }
          // var AllStreams = localStorage.getItem('videoStreams');

          // if (AllStreams) {
          //   setTimeout(() => {
          //     setVideoStreamList(JSON.parse(AllStreams));
          //   }, 3000);
          // }
        }

        setTimeout(() => {
          localStorage.setItem('hide_case', '');
          localStorage.setItem('checkLastVideoEnabled', '');
        }, 1000);
        localStorage.setItem(
          'session_started',
          localStorage.getItem('conf_id'),
        );
        if (moderator) {
          joinOptions = {
            constraints: {video: false, audio: listen ? listen : micStatusIs},
          };
          setAudioEnable(micStatusIs);
          setshowBtns(true);
        } else {
          joinOptions = {constraints: {video: false, audio: false}};
          setAudioEnable(false);
          setshowBtns(false);
        }
        // checkUserLeaveByUrl();
        VoxeetSDK.session
          .open(mixer)
          .then(() => VoxeetSDK.conference.fetch(id))
          // Join the conference
          .then(conference => {
            try {
             
              
              if (moderator) {
                let payload = {
                  conference_id: localStorage.getItem('conf_id'),
                };
                API.is_session_joined(payload);
                VoxeetSDK.conference
                  .join(conference, joinOptions)
                  .then(() => {
                    const subscriptions_to_enable = [
                      {
                        type: 'Participant.Joined',
                        conferenceAlias:
                          localStorage.getItem('conferenceAlias'),
                      },
                      {
                        type: 'Participant.Left',
                        conferenceAlias:
                          localStorage.getItem('conferenceAlias'),
                      },
                    ];
                    VoxeetSDK.notification.subscribe(subscriptions_to_enable);
                    allFirebaseEvents();
                  })
                  .catch(err => {
                    // API.leave({conference_id: localStorage.getItem('conf_id')});
                    // errorMessage(err.message);
                    // history.push('/meetups');
                    // creatorAcknoledgeLeave();
                    console.error(err);
                  });
              } else {
                let payload = {
                  conference_id: localStorage.getItem('conf_id'),
                };
                // API.is_session_started(payload);
                API.is_session_joined(payload);
                VoxeetSDK.conference
                  .listen(conference, joinOptions)
                  .then(() => {
                    const subscriptions_to_enable = [
                      {
                        type: 'Participant.Joined',
                        conferenceAlias:
                          localStorage.getItem('conferenceAlias'),
                      },
                      {
                        type: 'Participant.Left',
                        conferenceAlias:
                          localStorage.getItem('conferenceAlias'),
                      },
                    ];
                    VoxeetSDK.notification.subscribe(subscriptions_to_enable);
                    allFirebaseEvents();
                    setTimeout(() => {
                      addListner();
                    }, 1000);
                  })
                  .catch(err => {
                    console.error(err);
                    console.error(err.code);
                    if (err.code === 100) {
                      localStorage.setItem('session_started', '');
                      history.push('/meetups');
                      errorMessage(err.message);
                    }
                  });
              }
              if (listen) {
                streamEvents();
                UpdateSessionDetails(localStorage.getItem('conf_id'));
              } else {
                streamEvents();
                UpdateSessionDetails(localStorage.getItem('conf_id'));
              }
            } catch (error) {
              console.error(error);
            }
          })
          .catch(err => {
            console.error(err);
            VoxeetSDK.session.close();
          });
      }
    });
  };
  const unMuterequest = data => {
    setShowModal(true);
  };

  const handleShareScreen = () => {
    setShowSSModal(false);
    VoxeetSDK.conference
      .startScreenShare()
      .then(() => {
        localStorage.setItem('checkLastVideoEnabled', 'screen');
        setshareshareScreenstatus(true);
      })
      .catch(err => console.error(err));
  };

  const shareScreen = () => {
    if (window.safari !== undefined && !isVideoEnable) {
      const participants = VoxeetSDK.conference.participants;
      if (typeof participants === 'object') {
        const values = Array.from(participants.values());
        const found = values.find(
          objs => objs.info.externalId === localStorage.getItem('user'),
        );
        if (typeof found === 'object') {
          VoxeetSDK.conference
            .stopVideo(found)
            .then(() => {
              UpdateBroadcastMessage(
                'sessionEvent',
                'close_large_video',
                JSON.stringify({
                  type: 'close_large_video',
                  user_id: found,
                  sent_at: new Date().getTime(),
                  check: '3',
                }),
              );
              setVideoVisible(true);
              setShowSSModal(true);
            })
            .catch(err => console.error(err));
        }
      }
    } else {
      const participants = VoxeetSDK.conference.participants;
      if (typeof participants === 'object') {
        const values = Array.from(participants.values());
        const found = values.find(
          objs => objs.info.externalId === localStorage.getItem('user'),
        );
        if (typeof found === 'object') {
          if (!isVideoEnable && isAudioEnable) {
            VoxeetSDK.conference
              .stopAudio(found)
              .then(() => {
                VoxeetSDK.conference
                  .stopVideo(found)
                  .then(() => {
                    UpdateBroadcastMessage(
                      'sessionEvent',
                      'close_large_video',
                      JSON.stringify({
                        type: 'close_large_video',
                        user_id: found,
                        sent_at: new Date().getTime(),
                        check: '2',
                      }),
                    );
                    setVideoVisible(true);
                    UpdateAudioStatys('off', found);
                    VoxeetSDK.conference
                      .startScreenShare()
                      .then(() => {
                        localStorage.setItem('checkLastVideoEnabled', 'screen');
                        setshareshareScreenstatus(true);
                        VoxeetSDK.conference
                          .startAudio(found)
                          .then(() => {
                            UpdateAudioStatys('on', found);
                          })
                          .catch(err => {
                            errorMessage(err.message);
                            console.error(err);
                          });
                      })
                      .catch(err => {
                        errorMessage(err.message);
                        console.error(err);
                        VoxeetSDK.conference
                          .startAudio(found)
                          .then(() => {
                            // VoxeetSDK.conference
                            //   .startVideo(found)
                            //   .then(() => {
                            //     setVideoVisible(false);
                            //   })
                            //   .catch(err => console.error(err));
                            UpdateAudioStatys('on', found);
                          })
                          .catch(err => console.error(err));
                      });
                  })
                  .catch(err => console.error(err));
              })
              .catch(err => console.error(err));
          } else if (isAudioEnable) {
            // VoxeetSDK.conference
            //     .stopAudio(found)
            //     .then(() => {
            VoxeetSDK.conference
              .startScreenShare()
              .then(() => {
                localStorage.setItem('checkLastVideoEnabled', 'screen');
                setshareshareScreenstatus(true);
                VoxeetSDK.conference
                  .startAudio(found)
                  .then(() => {
                    UpdateAudioStatys('on', found);
                  })
                  .catch(err => console.error(err));
              })
              .catch(err => {
                errorMessage(err.message);
                console.error(err);
                // VoxeetSDK.conference
                //     .startAudio(found)
                //     .then(() => {})
                //     .catch((err) => console.error(err));
              });
            // })
            // .catch((err) => console.error(err));
          } else if (!isVideoEnable) {
            VoxeetSDK.conference
              .stopVideo(found)
              .then(() => {
                setVideoVisible(true);
                VoxeetSDK.conference
                  .startScreenShare()
                  .then(() => {
                    localStorage.setItem('checkLastVideoEnabled', 'screen');
                    setshareshareScreenstatus(true);
                  })
                  .catch(err => {
                    errorMessage(err.message);
                    console.error(err);
                    // VoxeetSDK.conference
                    //   .startVideo(found)
                    //   .then(() => {
                    //     setVideoVisible(false);
                    //   })
                    //   .catch(err => console.error(err));
                  });
              })
              .catch(err => {
                errorMessage(err.message);
                console.error(err);
              });
          } else {
            VoxeetSDK.conference
              .startScreenShare()
              .then(() => {
                localStorage.setItem('checkLastVideoEnabled', 'screen');
                setshareshareScreenstatus(true);
              })
              .catch(err => {
                errorMessage(err.message);
                console.error(err);
              });
          }
        }
      }
    }
  };

  const stopScreen = founduser => {
    VoxeetSDK.conference
      .stopScreenShare()
      .then(() => {
        UpdateBroadcastMessage(
          'sessionEvent',
          'close_large_video',
          JSON.stringify({
            type: 'close_large_video',
            user_id: founduser ? founduser : localStorage.getItem('user'),
            sent_at: new Date().getTime(),
          }),
        );
        setshareshareScreenstatus(false);
      })
      .catch(e => {});
  };
  const closeModal = () => {
    setShowModal(false);
  };
  const leavecloseModal = () => {
    setleaveModal(false);
  };
  const RequestStatusUpdate = status => {
    setShowModal(false);
    if (status === 'Accept') {
      RequestUpdate('Accept');
      microphoneRequestAccepted(LastRequest[0].info.externalId);
    }
    if (status === 'Ignore') {
      RequestUpdate('Ignore');
      microphoneRequestCancel(LastRequest[0].info.externalId);
    }
  };
  const RequestUpdate = status => {
    var data;
    if (status === 'Accept') {
      data = {
        user_id: LastRequest[0].info.externalId,
        conference_id: localStorage.getItem('conf_id'),
        mic: 'on',
        mic_status: 'approved',
      };
    }
    if (status === 'Ignore') {
      data = {
        user_id: LastRequest[0].info.externalId,
        conference_id: localStorage.getItem('conf_id'),
        mic: 'off',
        mic_status: 'ignored',
      };
    }
    API.update_mic_status(data)
      .then(res => {
        if (res) {
          if (status === 'Accept') {
            const obj = JSON.stringify({
              id: LastRequest[0].info.externalId,
              type: 'stage',
              sent_at: new Date().getTime(),
            });
            UpdateBroadcastMessage('sessionEvent', 'stage', obj);

            const obj1 = JSON.stringify({
              id: LastRequest[0].info.externalId,
              type: 'user_un_muted',
              sent_at: new Date().getTime(),
            });
            UpdateBroadcastMessage('sessionEvent', 'user_un_muted', obj1);

            UpdateBroadcastMessage(
              'sessionEvent',
              'microphone_requests',
              JSON.stringify({
                id: '',
                name: '',
                type: 'microphone_requests',
                sent_at: '',
              }),
            );

            UpdateBroadcastMessage(
              'sessionEvent',
              'microphone_request_accepted',
              JSON.stringify({
                id: LastRequest[0].info.externalId,
                name: '',
                type: 'microphone_request_accepted',
                sent_at: new Date().getTime(),
              }),
            );
            // VoxeetSDK.command
            //   .send({
            //     dataPayload: {
            //       user_id: LastRequest[0].info.externalId,
            //       mic: 'on',
            //       conference_id: localStorage.getItem('conf_id'),
            //     },
            //   })
            //   .then(succ => {
            //     VoxeetSDK.command
            //       .send({
            //         dataPayload: {
            //           id: LastRequest[0].info.externalId,
            //           name: 'request_accepted',
            //         },
            //       })
            //       .then(succ => {
            //         console.log(succ);
            //         UpdateSessionDetails(localStorage.getItem('conf_id'));
            //       })
            //       .catch(err => {
            //         console.log(err);
            //       });
            //   })
            //   .catch(err => {
            //     console.log(err);
            //   });
            UpdateSessionDetails(localStorage.getItem('conf_id'));
          }
          if (status === 'Ignore') {
            // VoxeetSDK.command
            //   .send({
            //     dataPayload: {
            //       user_id: LastRequest[0].info.externalId,
            //       mic: 'off',
            //       conference_id: localStorage.getItem('conf_id'),
            //     },
            //   })
            //   .then(succ => {
            //     UpdateSessionDetails(localStorage.getItem('conf_id'));
            //   })
            //   .catch(err => {
            //     console.log(err);
            //   });

            UpdateBroadcastMessage('sessionEvent', 'microphone_request', {
              id: '',
              name: '',
              type: 'microphone_requests',
              sent_at: '',
            });
            UpdateBroadcastMessage(
              'sessionEvent',
              'microphone_request_cancel',
              JSON.stringify({
                id: LastRequest[0].info.externalId,
                name: '',
                type: 'microphone_request_cancel',
                sent_at: new Date().getTime(),
              }),
            );
            UpdateSessionDetails(localStorage.getItem('conf_id'));
          }
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  const checkIsmoderatorOnly = id => {
    var data = localStorage.getItem('SessionData');
    data = JSON.parse(data);
    var found = false;
    
    
    if (
      data.hasOwnProperty('moderator_list') &&
      data.moderator_list.length > 0
    ) {
      data.moderator_list.forEach(element => {
        if (element.id === id && element.type === 'moderator') {
          found = true;
        }
      });

      if (data.creator_id === id) {
        found = true;
      }

      return found;
    }
  };

  const leave_ban = () => {
    const participants = VoxeetSDK.conference.participants;
    participants.forEach(element => {
      if (element.info.externalId === localStorage.getItem('user')) {
        VoxeetSDK.conference
          .leave(element)
          .then(() => {
            var data = {
              conference_id: localStorage.getItem('conf_id'),
            };

            API.leave(data).then(r => {
              localStorage.setItem('hide_case', '');
              localStorage.setItem('checkLastVideoEnabled', '');
              localStorage.setItem('session_started', '');

              if (r) {
                VoxeetSDK.session.close();
                history.push({
                  pathname: '/meetups',
                  //      search: '?close_session=yes',
                  state: {banu_user: 'yes'},
                });
              }
            });
          })
          .catch(err => console.error(err));
      }
    });

    // VoxeetSDK.command
    //   .send({
    //     dataPayload: {id: localStorage.getItem('user'), name: 'left_session'},
    //   })
    //   .then(succ => {
    //     const participants = VoxeetSDK.conference.participants;
    //     participants.forEach(element => {
    //       if (element.info.externalId == localStorage.getItem('user')) {
    //         VoxeetSDK.conference
    //           .leave(element)
    //           .then(() => {
    //             VoxeetSDK.session.close();
    //             history.push('/meetups');
    //           })
    //           .catch(err => console.error(err));
    //       }
    //     });
    //   })
    //   .catch(err => {
    //     console.log(err);
    //   });
  };
  const leaveJoinAsListner = dataParsed => {
    const mixer = {
      name:
        localStorage.getItem('firstName') +
        ' ' +
        localStorage.getItem('lastName').charAt(0).toUpperCase() +
        '.',
      externalId: localStorage.getItem('user'),
      avatarUrl: localStorage.getItem('profilePic'),
    };
    VoxeetSDK.session
      .open(mixer)
      .then(() =>
        VoxeetSDK.conference.fetch(localStorage.getItem('conferenceId')),
      )
      // Join the conference
      .then(conference => {
        VoxeetSDK.conference
          .listen(conference, {constraints: {video: false, audio: false}})
          .catch(err => console.error(err));
        addListner();
        allFirebaseEvents();
        const participants = VoxeetSDK.conference.participants;
        
        participants.forEach(element => {
          if (element.streams[0] && element.streams[0].getVideoTracks()) {
            setVideoStreamList(current => {
              const found = current.find(
                objs =>
                  objs.participant.info.externalId === element.info.externalId,
              );
              if (typeof found === 'object') {
                return current.map(obj => {
                  if (
                    obj.participant.info.externalId === element.info.externalId
                  ) {
                    return {
                      ...obj,
                      participant: element,
                      stream: element.streams[0],
                    };
                  }
                  return obj;
                });
              } else {
                return [
                  ...current,
                  {
                    participant: element,
                    stream: element.streams[0],
                  },
                ];
              }
            });
          } else if (
            element.streams[1] &&
            element.streams[1].getVideoTracks()
          ) {
            setVideoStreamList(current => {
              const found = current.find(
                objs =>
                  objs.participant.info.externalId === element.info.externalId,
              );
              if (typeof found === 'object') {
                return current.map(obj => {
                  if (
                    obj.participant.info.externalId === element.info.externalId
                  ) {
                    return {
                      ...obj,
                      participant: element,
                      stream: element.streams[1],
                    };
                  }
                  return obj;
                });
              } else {
                return [
                  ...current,
                  {
                    participant: element,
                    stream: element.streams[1],
                  },
                ];
              }
            });
          }
        });
      });
  };
  const streamEvents = () => {
    startFirebaseEvents();
    fbParticipantAdded();
    messageService.getMessage().subscribe(message => {
      
      
      UpdateSessionDetails(localStorage.getItem('conf_id'));
      if (message.text && message.text === 'update_api') {
        UpdateSessionDetails(localStorage.getItem('conf_id'));
      }
      if (
        message.text &&
        message.text.name &&
        message.text.name === 'show_emoji'
      ) {
        // AddEmojiMessage(message);

        setAllJdEmojis(current => {
          const found = current.find(objs => objs.id === message.text.id);
          if (typeof found === 'object') {
            return current.map(obj => {
              if (obj.id === message.text.id) {
                return {...obj, emoji: message.text.emoji, time: new Date()};
              }
              return obj;
            });
          } else {
            return [
              ...current,
              {
                id: message.text.id,
                emoji: message.text.emoji,
                time: new Date(),
              },
            ];
          }
        });
      }
      if (
        message.text &&
        message.text.name &&
        message.text.name === 'add_link'
      ) {
        getWebsitelinks(localStorage.getItem('conf_id'));
      }
      if (
        message.text &&
        message.text.name &&
        message.text.name === 'ban_user' &&
        message.text.login_id === localStorage.getItem('user')
      ) {
        kickUser(message.text.user_id);
      }
    });
    VoxeetSDK.command.on('received', (participant, message) => {
      UpdateSessionDetails(localStorage.getItem('conf_id'));
      let dataParsed = JSON.parse(message);
      
      
      if (dataParsed.dataPayload === 'unmute') {
        if (checkIsmoderatorOnly(localStorage.getItem('user'))) {
          setLastRequest([participant]);
          unMuterequest(participant);
        }
      }
      if (dataParsed.dataPayload.name === 'add_link') {
        getWebsitelinks(localStorage.getItem('conf_id'));
      }
      if (
        dataParsed.dataPayload.name === 'do_leave' &&
        dataParsed.dataPayload.id === 'comment' &&
        dataParsed.dataPayload.id === localStorage.getItem('user')
      ) {
        leave_ban();
      }
      if (
        dataParsed.dataPayload.name === 'ban_alert' &&
        dataParsed.dataPayload.id === localStorage.getItem('user')
      ) {
        errorMessage('You are banned for this session.');
        localStorage.setItem('session_started', '');
      }
      // if (dataParsed.dataPayload.name == "ban_user") {
      //     setTimeout(() => {
      //         const participants = VoxeetSDK.conference.participants;
      //         var allPersons = [];
      //         participants.forEach((element) => {
      //             if (element.status !== "Left") {
      //                 allPersons.push(element);
      //             }
      //         });
      //         setPersons(allPersons);
      //     }, 2000);
      // }
      if (dataParsed.dataPayload.name === 'request_accepted') {
        // const participants = VoxeetSDK.conference.participants;
        // participants.forEach(element => {
        //   if (
        //     element.info.externalId == dataParsed.dataPayload.id &&
        //     element.info.externalId == localStorage.getItem('user') &&
        //     !element.audio
        //   ) {
        //     setAudioEnable(true);
        //     // audioStartBtn();
        //     makeUesrBtn();
        //     UpdateSessionDetails(localStorage.getItem('conf_id'));
        //     setshowBtns(true);
        //   } else {
        //     UpdateSessionDetails(localStorage.getItem('conf_id'));
        //   }
        // });

        if (dataParsed.dataPayload.id === localStorage.getItem('id')) {
         
          // setUserTypeLoader(true);
          // setTimeout(() => {
          //   setUserTypeLoader(false);
          // }, 5000);
          const participants = VoxeetSDK.conference.participants;
          participants.forEach(element => {
            if (element.info.externalId === localStorage.getItem('user')) {
              VoxeetSDK.conference
                .leave(element)
                .then(() => {
                  VoxeetSDK.session.close();
                
                  setTimeout(() => {
                  
                    const mixer = {
                      name:
                        localStorage.getItem('firstName') +
                        ' ' +
                        localStorage
                          .getItem('lastName')
                          .charAt(0)
                          .toUpperCase() +
                        '.',
                      externalId: localStorage.getItem('user'),
                      avatarUrl: localStorage.getItem('profilePic'),
                    };

                    VoxeetSDK.session
                      .open(mixer)
                      .then(() =>
                        VoxeetSDK.conference.fetch(
                          localStorage.getItem('conferenceId'),
                        ),
                      )
                      // Join the conference
                      .then(conference => {
                        try {
                          // if (dataParsed.dataPayload.type === 'audience') {
                          //   VoxeetSDK.conference
                          //     .listen(conference, {
                          //       constraints: {video: false, audio: false},
                          //     })
                          //     .catch(err => console.error(err));
                          // } else {
                          VoxeetSDK.conference
                            .join(conference, {
                              constraints: {video: false, audio: false},
                            })
                            .catch(err => console.error(err));
                          allFirebaseEvents();
                          let payload = {
                            conference_id: localStorage.getItem('conf_id'),
                          };
                          API.is_session_joined(payload);
                          setTimeout(() => {
                            //  addListner();
                          }, 1000);
                          // }
                        } catch (error) {
                          console.error(error);
                        }
                      })
                      .catch(err => console.error(err));
                  }, 500);
                })
                .catch(err => console.error(err));
            }
          });
        }
        UpdateSessionDetails(localStorage.getItem('conf_id'));
      }
      if (dataParsed.dataPayload.name === 'moderator_mute') {
        const participants = VoxeetSDK.conference.participants;
        participants.forEach(element => {
          if (
            element.info.externalId === dataParsed.dataPayload.id &&
            element.info.externalId === localStorage.getItem('user')
          ) {
            setAudioEnable(false);
            VoxeetSDK.conference
              .stopAudio(element)
              .then(() => {
                UpdateAudioStatys('off', element.info.externalId);
                if (
                  element.streams.length > 0 &&
                  element.streams[0].getVideoTracks().length > 0
                ) {
                  // const found = element.info.externalId == dataParsed.dataPayload.id &&
                  // element.info.externalId == localStorage.getItem('user')
                  VoxeetSDK.conference
                    .stopVideo(element)
                    .then(() => {
                      UpdateBroadcastMessage(
                        'sessionEvent',
                        'close_large_video',
                        JSON.stringify({
                          type: 'close_large_video',
                          user_id: element.info.externalId,
                          sent_at: new Date().getTime(),
                          check: '1',
                        }),
                      );
                      setVideoVisible(true);
                    })
                    .catch(err => console.error(err));
                }
              })
              .catch(err => console.error(err));
          }
        });
      }

      if (dataParsed.dataPayload.name === 'moderator_unmute') {
        const participants = VoxeetSDK.conference.participants;
        participants.forEach(element => {
          if (
            element.info.externalId === dataParsed.dataPayload.id &&
            element.info.externalId === localStorage.getItem('user')
          ) {
            setAudioEnable(true);
            VoxeetSDK.conference
              .startAudio(element)
              .then(() => {
                UpdateAudioStatys('on', element.info.externalId);
              })
              .catch(err => console.error(err));
          }
        });
      }
      if (dataParsed.dataPayload.name === 'show_emoji') {
        if (dataParsed.dataPayload.hasOwnProperty('id')) {
          setAllJdEmojis(current => {
            const found = current.find(
              objs => objs.id === dataParsed.dataPayload.id,
            );
            if (typeof found === 'object') {
              return current.map(obj => {
                if (obj.id === dataParsed.dataPayload.id) {
                  return {
                    ...obj,
                    emoji: dataParsed.dataPayload.emoji,
                    time: new Date(),
                  };
                }
                return obj;
              });
            } else {
              return [
                ...current,
                {
                  id: dataParsed.dataPayload.id,
                  emoji: dataParsed.dataPayload.emoji,
                  time: new Date(),
                },
              ];
            }
          });
        }
      }
      if (dataParsed.dataPayload === 'close_session') {
        const participants = VoxeetSDK.conference.participants;
        participants.forEach(element => {
          
          if (element.info.externalId === localStorage.getItem('user')) {
            var arr = {
              reason: '',
              leaveRoom: true,
              keepAlive: false,
            };
            VoxeetSDK.conference
              .leave(arr)
              .then(() => {
                VoxeetSDK.session.close();
                history.push({
                  pathname: '/meetups',
                  //      search: '?close_session=yes',
                  state: {
                    close_session: 'yes',
                    creator_id: {
                      id: localStorage.getItem('creator_id'),
                      profile_img: localStorage.getItem('profilePic'),
                      first_name: localStorage.getItem('firstName'),
                      last_name: localStorage.getItem('lastName'),
                    },
                  },
                });
              })
              .catch(err => console.error(err));
          }
        });
      }
      if (dataParsed.dataPayload.name === 'left_session') {
        if (dataParsed.dataPayload.id === localStorage.getItem('creator_id')) {
          const participants = VoxeetSDK.conference.participants;
          participants.forEach(element => {
            
            if (element.info.externalId === localStorage.getItem('user')) {
              var arr = {
                reason: '',
                leaveRoom: true,
                keepAlive: false,
              };
              VoxeetSDK.conference
                .leave(arr)
                .then(() => {
                  VoxeetSDK.session.close();
                  history.push({
                    pathname: '/meetups',
                    //      search: '?close_session=yes',
                    state: {close_session: 'yes'},
                  });
                })
                .catch(err => console.error(err));
            }
          });
        }
        setTimeout(() => {
          const participants = VoxeetSDK.conference.participants;
          var allPersons = [];
          let count = 0;
          participants.forEach(element => {
            if (element.status !== 'Left') {
              allPersons.push(element);
              if (
                element.info.externalId === localStorage.getItem('creator_id') &&
                count > 0
              ) {
                let b = allPersons[count];
                allPersons[count] = allPersons[0];
                allPersons[0] = b;
              }
              count++;
            }
          });
          setPersons(allPersons);
        }, 2000);
      }
      if (dataParsed.dataPayload.name === 'update_api') {
    
        if (
          dataParsed.dataPayload.id === 'comment' &&
          dataParsed.dataPayload.id === localStorage.getItem('id') &&
          dataParsed.dataPayload.type !== 'ban' &&
          ((!dataParsed.dataPayload.moderator &&
            dataParsed.dataPayload.type !== 'audience') ||
            (dataParsed.dataPayload.moderator &&
              dataParsed.dataPayload.type === 'audience') ||
            (!dataParsed.dataPayload.moderator &&
              dataParsed.dataPayload.type === 'audience'))
        ) {
          
          setHandActive(false);
          const participants = VoxeetSDK.conference.participants;
          participants.forEach(element => {
            if (element.info.externalId === localStorage.getItem('user')) {
              if (dataParsed.dataPayload.previous_type === 'audience') {
                VoxeetSDK.conference
                  .leave(element)
                  .then(() => {
                    VoxeetSDK.session.close();
                  
                    VoxeetSDK.initialize(consumerKey, consumerSecret);
                    setTimeout(() => {
                     
                      const mixer = {
                        name:
                          localStorage.getItem('firstName') +
                          ' ' +
                          localStorage
                            .getItem('lastName')
                            .charAt(0)
                            .toUpperCase() +
                          '.',
                        externalId: localStorage.getItem('user'),
                        avatarUrl: localStorage.getItem('profilePic'),
                      };

                      VoxeetSDK.session
                        .open(mixer)
                        .then(() =>
                          VoxeetSDK.conference.fetch(
                            localStorage.getItem('conferenceId'),
                          ),
                        )
                        // Join the conference
                        .then(conference => {
                          try {
                            var payloadApi = {
                              conference_id: localStorage.getItem('conf_id'),
                            };
                            API.is_session_joined(payloadApi);
                            if (dataParsed.dataPayload.type === 'audience') {
                              VoxeetSDK.conference
                                .listen(conference, {
                                  constraints: {video: false, audio: false},
                                })
                                .catch(err => console.error(err));
                              allFirebaseEvents();
                            } else {
                              VoxeetSDK.conference
                                .join(conference, {
                                  constraints: {video: false, audio: false},
                                })
                                .then(() => {
                                  allFirebaseEvents();
                                  // UpdateAudioStatys(
                                  //   'on',
                                  //   element.info.externalId,
                                  // );
                                })

                                .catch(err => console.error(err));
                              // setTimeout(() => {
                              //   console.log(
                              //     element.info.externalId,
                              //     element,
                              //     '@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@',
                              //   );
                              //   VoxeetSDK.conference
                              //     .stopAudio(element)
                              //     .then(() => {
                              //       UpdateAudioStatys(
                              //         'off',
                              //         element.info.externalId,
                              //       );
                              //     })
                              //     .catch(err => console.error(err));
                              // }, 2000);
                            }
                            const participants =
                              VoxeetSDK.conference.participants;
                          
                            participants.forEach(element => {
                              if (
                                element.streams[0] &&
                                element.streams[0].getVideoTracks()
                              ) {
                                setVideoStreamList(current => {
                                  const found = current.find(
                                    objs =>
                                      objs.participant.info.externalId ===
                                      element.info.externalId,
                                  );
                                  if (typeof found === 'object') {
                                    return current.map(obj => {
                                      if (
                                        obj.participant.info.externalId ===
                                        element.info.externalId
                                      ) {
                                        return {
                                          ...obj,
                                          participant: element,
                                          stream: element.streams[0],
                                        };
                                      }
                                      return obj;
                                    });
                                  } else {
                                    return [
                                      ...current,
                                      {
                                        participant: element,
                                        stream: element.streams[0],
                                      },
                                    ];
                                  }
                                });
                              } else if (
                                element.streams[1] &&
                                element.streams[1].getVideoTracks()
                              ) {
                                setVideoStreamList(current => {
                                  const found = current.find(
                                    objs =>
                                      objs.participant.info.externalId ===
                                      element.info.externalId,
                                  );
                                  if (typeof found === 'object') {
                                    return current.map(obj => {
                                      if (
                                        obj.participant.info.externalId ===
                                        element.info.externalId
                                      ) {
                                        return {
                                          ...obj,
                                          participant: element,
                                          stream: element.streams[1],
                                        };
                                      }
                                      return obj;
                                    });
                                  } else {
                                    return [
                                      ...current,
                                      {
                                        participant: element,
                                        stream: element.streams[1],
                                      },
                                    ];
                                  }
                                });
                              }
                            });
                            setTimeout(() => {
                              if (dataParsed.dataPayload.type === 'audience') {
                                addListner();
                              }
                            }, 1000);

                            UpdateSessionDetails(
                              localStorage.getItem('conf_id'),
                            );
                          } catch (error) {
                            console.error(error);
                          }
                        })
                        .catch(err => console.error(err));
                    }, 1500);
                  })
                  .catch(err => console.error(err));
              }
              if (dataParsed.dataPayload.previous_type !== 'audience') {
                setTimeout(() => {
                

                  try {
                    var payloadApi = {
                      conference_id: localStorage.getItem('conf_id'),
                    };
                    API.is_session_joined(payloadApi);
                    if (dataParsed.dataPayload.type === 'audience') {
                      leaveJoinAsListner(dataParsed);
                    } else {
                      const participants = VoxeetSDK.conference.participants;
                     
                      participants.forEach(element => {
                        if (
                          element.streams[0] &&
                          element.streams[0].getVideoTracks()
                        ) {
                          setVideoStreamList(current => {
                            const found = current.find(
                              objs =>
                                objs.participant.info.externalId ===
                                element.info.externalId,
                            );
                            if (typeof found === 'object') {
                              return current.map(obj => {
                                if (
                                  obj.participant.info.externalId ===
                                  element.info.externalId
                                ) {
                                  return {
                                    ...obj,
                                    participant: element,
                                    stream: element.streams[0],
                                  };
                                }
                                return obj;
                              });
                            } else {
                              return [
                                ...current,
                                {
                                  participant: element,
                                  stream: element.streams[0],
                                },
                              ];
                            }
                          });
                        } else if (
                          element.streams[1] &&
                          element.streams[1].getVideoTracks()
                        ) {
                          setVideoStreamList(current => {
                            const found = current.find(
                              objs =>
                                objs.participant.info.externalId ===
                                element.info.externalId,
                            );
                            if (typeof found === 'object') {
                              return current.map(obj => {
                                if (
                                  obj.participant.info.externalId ===
                                  element.info.externalId
                                ) {
                                  return {
                                    ...obj,
                                    participant: element,
                                    stream: element.streams[1],
                                  };
                                }
                                return obj;
                              });
                            } else {
                              return [
                                ...current,
                                {
                                  participant: element,
                                  stream: element.streams[1],
                                },
                              ];
                            }
                          });
                        }
                      });
                    }

                    UpdateSessionDetails(localStorage.getItem('conf_id'));
                  } catch (error) {
                    console.error(error);
                  }
                }, 1500);
              }
            }
          });
        }

        if (
          dataParsed.dataPayload.id === localStorage.getItem('id') &&
          dataParsed.dataPayload.type === 'ban' &&
          dataParsed.dataPayload.id === 'comment'
        ) {
        
          setHandActive(false);
          const participants = VoxeetSDK.conference.participants;
          participants.forEach(element => {
            if (
              element.info.externalId === localStorage.getItem('user') &&
              dataParsed.dataPayload.id === localStorage.getItem('user')
            ) {
              VoxeetSDK.conference
                .leave(element)
                .then(() => {
                  //  alert('ban leave')
                  localStorage.setItem('session_started', '');
                  var data = {
                    conference_id: localStorage.getItem('conf_id'),
                  };

                  API.leave(data).then(response => {
                    VoxeetSDK.session.close();
                    history.push({
                      pathname: '/meetups',
                      //      search: '?close_session=yes',
                      state: {
                        banu_ser: 'yes',
                        // creator_id: {
                        //   id: localStorage.getItem('creator_id'),
                        //   profile_img: localStorage.getItem('profilePic'),
                        //   first_name: localStorage.getItem('firstName'),
                        //   last_name: localStorage.getItem('lastName'),
                        // },
                      },
                    });
                  });

               
                })
                .catch(err => console.error(err));
            }
          });
        }
        UpdateSessionDetails(localStorage.getItem('conf_id'));
      }
      //moderator_mute
      if (dataParsed.dataPayload.type === 'congratulation') {
        if (dataParsed.dataPayload.id === getItem('id')) {
          successMessage('Moderator updated your status');
        }
      }
    });

    
    VoxeetSDK.conference.on('joined', () => {
      
      const participants = VoxeetSDK.conference.participants;
      
      var allPersons = [];
      let count = 0;
      participants.forEach(element => {
        if (element.status !== 'Left') {
          allPersons.push(element);
          if (
            element.info.externalId === localStorage.getItem('creator_id') &&
            count > 0
          ) {
            let b = allPersons[count];
            allPersons[count] = allPersons[0];
            allPersons[0] = b;
          }
          count++;
        }
      });
      // var make_arr = {
      //   type: 'joined',
      //   person: allPersons,
      // };
      // setLastPerson(make_arr);
      UpdateSessionDetails(localStorage.getItem('conf_id'));
      setPersons(allPersons);
    });
    VoxeetSDK.conference.on('participantAdded', participant => {
     

      // var make_arr = {
      //     type: "add",
      //     person: allPersons
      // }
      // setPersons(make_arr);
      const participants = VoxeetSDK.conference.participants;
      UpdateSessionDetails(localStorage.getItem('conf_id'));
      var allPersons = [];
      let count = 0;
      participants.forEach(element => {
        if (element.status !== 'Left') {
          allPersons.push(element);
          if (
            element.info.externalId === localStorage.getItem('creator_id') &&
            count > 0
          ) {
            let b = allPersons[count];
            allPersons[count] = allPersons[0];
            allPersons[0] = b;
          }
          count++;
        }
      });
      // var onePersons = participant;
      //setPersons(...persons, onePersons);
      //   setPersons(persons => [...persons, onePersons]);
      setPersons(allPersons);
      setShowLoader(false);
    });

    VoxeetSDK.conference.on('participantUpdated', participant => {
     
      const participants = VoxeetSDK.conference.participants;
      UpdateSessionDetails(localStorage.getItem('conf_id'));
      var allPersons = [];
      let count = 0;
      
      participants.forEach(element => {
        if (element.status !== 'Left') {
          allPersons.push(element);
          if (
            element.info.externalId === localStorage.getItem('creator_id') &&
            count > 0
          ) {
            let b = allPersons[count];
            allPersons[count] = allPersons[0];
            allPersons[0] = b;
          }
          count++;
        }
      });
      // var onePersons = participant;
      // setPersons(allPersons);
      // setTimeout(() => {
      //   console.log(`Event - participantUpdated`, VoxeetSDK.conference.participants.values());
      //   setVideoStreamList(VoxeetSDK.conference.participants.values());
      // }, 2000);
      setShowLoader(false);
    });
    VoxeetSDK.conference.on('left', () => {
      
      const participants = VoxeetSDK.conference.participants;
      var allPersons = [];
      let count = 0;
      participants.forEach(element => {
        if (element.status !== 'Left') {
          allPersons.push(element);
          if (
            element.info.externalId === localStorage.getItem('creator_id') &&
            count > 0
          ) {
            let b = allPersons[count];
            allPersons[count] = allPersons[0];
            allPersons[0] = b;
          }
          count++;
        }
      });
     
      
      
      UpdateSessionDetails(localStorage.getItem('conf_id'));
    });
    VoxeetSDK.conference.on('streamAdded', (participant, stream) => {
     
      if (
        stream &&
        stream.getVideoTracks() &&
        stream.getVideoTracks().length > 0
      ) {
        
        setVideoStreamList(current => {
          const found = current.find(
            objs =>
              objs.participant.info.externalId === participant.info.externalId,
          );
          if (typeof found === 'object') {
            return current.map(obj => {
              if (
                obj.participant.info.externalId === participant.info.externalId
              ) {
                return {...obj, participant: participant, stream: stream};
              }
              return obj;
            });
          } else {
            return [
              ...current,
              {
                participant: participant,
                stream: stream,
              },
            ];
          }
        });
      }
      setTotalVideoz();
    
     
      UpdateSessionDetails(localStorage.getItem('conf_id'));
      if (stream.type === 'ScreenShare') {
        //  addScreenShareNode(stream);
        //  setActivevideos((count) => count + 1);
        setTotalVideos();
        // setSampleStateV((count) => count + 1);
        const participants = VoxeetSDK.conference.participants;
        var allPersons = [];
        let count = 0;
        participants.forEach(element => {
          if (element.status !== 'Left') {
            allPersons.push(element);
            if (
              element.info.externalId === localStorage.getItem('creator_id') &&
              count > 0
            ) {
              let b = allPersons[count];
              allPersons[count] = allPersons[0];
              allPersons[0] = b;
            }
            count++;
          }
        });
        // setPersons(allPersons);
        // var make_arr = {
        //   type: "streamAdded",
        //   person: participant,
        // };
        //   setLastPerson(make_arr);
        // checkVideoIsPlaying(stream.id, stream);
      }
      if (stream.type === 'Camera') {
        if (stream.getVideoTracks().length > 0) {
          // setActivevideos((count) => count + 1);
          setTotalVideos();
          const participants = VoxeetSDK.conference.participants;
          allPersons = [];
          let count = 0;
          participants.forEach(element => {
            if (element.status !== 'Left') {
              allPersons.push(element);
              if (
                element.info.externalId === localStorage.getItem('creator_id') &&
                count > 0
              ) {
                let b = allPersons[count];
                allPersons[count] = allPersons[0];
                allPersons[0] = b;
              }
              count++;
            }
          });
          
          
          // setPersons(allPersons);
          // var onePersons = participant;
          //setPersons(...persons, onePersons);
          //   setPersons(persons => [...persons, onePersons]);

          // var make_arr = {
          //   type: 'streamAdded',
          //   person: participant,
          // };
          //  setLastPerson(make_arr);

          
        } else {
          const participants = VoxeetSDK.conference.participants;
           allPersons = [];
          let count = 0;
          participants.forEach(element => {
            if (element.status !== 'Left') {
              allPersons.push(element);
              if (
                element.info.externalId === localStorage.getItem('creator_id') &&
                count > 0
              ) {
                let b = allPersons[count];
                allPersons[count] = allPersons[0];
                allPersons[0] = b;
              }
              count++;
            }
          });
          
          
          // var make_arr = {
          //   type: 'streamAdded',
          //   person: participant,
          // };
          // setLastPerson(make_arr);
          // setPersons(allPersons);
          // var onePersons = participant;
          //setPersons(...persons, onePersons);
          // setPersons(persons => [...persons, onePersons]);
        }
        // checkVideoIsPlaying(stream.id, stream);
      }
    });
    //      VoxeetSDK.conference.onStreamsChange((data, type) => {
    // When a video stream is updated from the conference
    VoxeetSDK.conference.on('streamUpdated', (participant, stream) => {
      setTotalVideoz();
    
      
      // setSampleStateV((count) => count + 1);
      // UpdateSessionDetails(localStorage.getItem('conf_id'));
      
      

      // var make_arr = {
      //     type: "streamUpdated",
      //     person: participant
      // }
      //       setLastPerson(make_arr);
      if (stream.type === 'ScreenShare') return;
      if (stream.getVideoTracks().length > 0) {
        //  setActivevideos((count) => count + 1);
        setTotalVideos();
        // if (CheckVideoAlredyAdded(stream.id) == false) {
        //     AddOnlyOneVideo(stream.id, stream)
        // } else {
        //     AddOnlyOneVideo(stream.id, stream)
        // }
        // checkVideoIsPlaying(stream.id, stream);
      } else {
        if (videoCountRef.current !== 0) {
          //  setActivevideos((count) => count - 1);
          setTotalVideos();
        }

        // var element = document.getElementById("video-object-" + stream.id);
        // var element1 = document.getElementById("video-objects-" + stream.id);
        // var element2 = document.getElementById("video-objectlarge-" + stream.id);
        // element?.remove();
        // element1?.remove();
        // element2?.remove();
      }

      if (
        stream &&
        stream.getVideoTracks() &&
        stream.getVideoTracks().length > 0
      ) {
        
        setVideoStreamList(current => {
          const found = current.find(
            objs =>
              objs.participant.info.externalId === participant.info.externalId,
          );
          if (typeof found === 'object') {
            return current.map(obj => {
              if (
                obj.participant.info.externalId === participant.info.externalId
              ) {
                return {...obj, participant: participant, stream: stream};
              }
              return obj;
            });
          } else {
            return [
              ...current,
              {
                participant: participant,
                stream: stream,
              },
            ];
          }
        });
      }
    });

    // When a video stream is removed from the conference
    VoxeetSDK.conference.on('streamRemoved', (participant, stream) => {
      setTotalVideoz();
      UpdateSessionDetails(localStorage.getItem('conf_id'));


      if (stream.type === 'ScreenShare') {
        let largeVideoShow = localStorage.getItem('largeVideo');
        if (largeVideoShow === stream.id) {
          document.getElementById('large-video-container').style.display =
            'none';
        }
        if (participant.info.externalId === localStorage.getItem('user')) {
          setshareshareScreenstatus(false);
        }
      } else {
        if (stream.type === 'Camera') {
          if (videoCountRef.current !== 0) {
            //  setActivevideos((count) => count - 1);
            setTotalVideos();
          }
          let largeVideoShow = localStorage.getItem('largeVideo');
          var mystreamIdShow = stream.id;
          var checkstream = mystreamIdShow
            ? mystreamIdShow.split('_')
            : mystreamIdShow;
          var largeVideoShowCheck = largeVideoShow;
          largeVideoShowCheck = largeVideoShowCheck
            ? largeVideoShowCheck.split('_')
            : largeVideoShowCheck;
          // 
          // 
          // 
          // 
          if (largeVideoShow === stream.id) {
            document.getElementById('large-video-container').style.display =
              'none';
          }
          if (
            checkstream &&
            largeVideoShowCheck &&
            checkstream[2] === largeVideoShowCheck[2]
          ) {
            document.getElementById('large-video-container').style.display =
              'none';
          }
        }
        // var element = document.getElementById("video-object-" + stream.id);
        // var element1 = document.getElementById("video-objects-" + stream.id);
        // var element2 = document.getElementById("video-objectlarge-" + stream.id);
        // element?.remove();
        // element1?.remove();
        // element2?.remove();
      }

      setVideoStreamList(current =>
        current.filter(
          item =>
            item.participant.info.externalId !== participant.info.externalId ||
            (item.participant.info.externalId === participant.info.externalId &&
              participant.streams.length > 0 &&
              participant.streams[0].getVideoTracks() &&
              participant.streams[0].getVideoTracks().length > 0),
        ),
      );

      if (participant.info.id === localStorage.getItem('creator_id')) {
        onLeaveClick();
      }
    });

    VoxeetSDK.notification.on('participantJoined', e => {
      // setIsDisabled(false);
      
    });

    VoxeetSDK.notification.on('participantLeft', e => {
      
      // if (e.participant.info.externalId === getItem('id')) {
      //   setIsDisabled(true);
      // }
      var dataOfSession = localStorage.getItem('SessionData');
      dataOfSession = JSON.parse(dataOfSession);
    
      // var hideCheck = localStorage.getItem('hide_case');
      //   if (hideCheck == 'yes') {
      // const participants = VoxeetSDK.conference.participants;
      if (e.participant.info.externalId === localStorage.getItem('user')) {
        VoxeetSDK.conference
          .leave(e.participant)
          .then(() => {})
          .catch(err => console.error(err));
      }
      // all casses of mobile
      if (!e.participant.info.sdkVersion) {
        if (e.participant.info.externalId !== dataOfSession?.creator_id) {
          var data = {
            conference_id: localStorage.getItem('conf_id'),
            user_id: e.participant.info.externalId,
          };

          API.user_left_session_by_user_id(data).then(response => {
            UpdateSessionDetails(localStorage.getItem('conf_id'));
          });
        }
        if (e.participant.info.externalId === dataOfSession?.creator_id) {
           data = {
            conference_id: localStorage.getItem('conf_id'),
            user_id: localStorage.getItem('user'),
          };

          API.user_left_session_by_user_id(data).then(response => {});
           data = {
            conference_id: localStorage.getItem('conf_id'),
          };
          API.leave(data).then(response => {
            if (response) {
              creatorLeftMobile(e.participant.info.externalId);
            }
          });
        }
      }
      UpdateSessionDetails(localStorage.getItem('conf_id'));
    });
  };

  const creatorLeftMobile = id => {
    UpdateBroadcastMessage(
      'sessionEvent',
      'creator_left',
      JSON.stringify({
        id: id,
        type: 'creator_left',
        sent_at: new Date().getTime(),
      }),
    );
    VoxeetSDK.command
      .send({dataPayload: 'close_session'})
      .then(succ => {
        history.push('/meetups');
      })
      .catch(err => {
        
        history.push('/meetups');
      });
  };
  // VoxeetSDK.conference.on('streamAdded', async (participant, stream) => {
  //     // TODO add in track check
  //     if (stream.getVideoTracks().length) {
  //       // Only add the video node if there is a video track
  //       if (participant.id === VoxeetSDK.session.participant.id) {
  //         setState({
  //           localParticipant: participant,
  //           localStream: stream,
  //           isConnecting: false,
  //         });
  //         // otherwise, if the joining participant does not yourself (based on participant id),
  //         // the stream will be rendered in guest view component
  //       }
  //     //    else {
  //     //     // since a second participant has joined, we can start a stream chat channel with the two joined members
  //     //     let channel = await setUpStreamChatChannel(
  //     //       state.streamChatClient
  //     //     );
  //     //     setState({
  //     //       remoteParticipant: participant,
  //     //       remoteStream: stream,
  //     //       streamChannel: channel,
  //     //     });
  //     //   }
  //     }
  //   });

  const setViewVideos = type => {
    setGridType(type);
    if (!type) {
      document.getElementById('GridviewContent').style.display = 'none';
      document.getElementById('ListviewContent').style.display = 'flex';
      // document.getElementById('large-video-container').style.display = 'none';
      const myMap = VoxeetSDK.conference.participants;

      for (let entry of myMap.values()) {
        if (
          entry.status === 'Connected' &&
          entry.streams.length > 0 &&
          entry.streams[0].getVideoTracks().length > 0
        ) {
          ShowFullVideo(entry.id, entry.streams[0].id, entry.streams[0]);
          break;
        }
        if (
          entry.status === 'Connected' &&
          entry.streams.length > 1 &&
          entry.streams[1].getVideoTracks().length > 0
        ) {
          ShowFullVideo(entry.id, entry.streams[1].id, entry.streams[1]);
          break;
        }
      }
    } else {
      document.getElementById('ListviewContent').style.display = 'none';
      document.getElementById('GridviewContent').style.display = 'block';
      //  document.getElementById("large-video-container").style.display = "none";
    }
    // let videoNode = document.getElementById("video-objectlarge-" + item.streams[1].id);
  };

  const setTotalVideoz = () => {
    setTimeout(() => {
      setTotalVideo(0);
      Array.from(VoxeetSDK.conference.participants.values()).forEach(item => {
        if (
          item.status === 'Connected' &&
          item.streams.length > 0 &&
          item.streams[0].getVideoTracks().length > 0
        ) {
          setTotalVideo(current => current + 1);
          
        }
        if (
          item.status === 'Connected' &&
          item.streams.length > 1 &&
          item.streams[1].getVideoTracks().length > 0
        ) {
          setTotalVideo(current => current + 1);
          
        }
      });
    }, 1000);
  };

  const setTotalVideos = () => {
    const participants = VoxeetSDK.conference.participants;
    setActivevideos(0);
    for (const participant of participants) {
      if (VoxeetSDK.conference.peerConnectionStatus === 'connected') {
        // VoxeetSDK.conference.audioLevel(
        //     VoxeetSDK.conference.participants.get(participant[0]),
        //     (level) => {

        if (participant && participant[1]?.streams[0]?.id) {
          var all_streams = participant[1]?.streams;
          if (all_streams) {
            if (all_streams.length > 1) {
              setActivevideos(count => count + 1);
            } else {
              if (participant[1].streams[0].getVideoTracks().length > 0) {
                setActivevideos(count => count + 1);
              }

              // for (const stream of item.all_streams) {
              //     if (stream.type == "ScreenShare" || stream?.getVideoTracks().length > 0) {
              //         appendLargeVideoScreenShare(stream)
              //     }
              // }
            }
          }

          // if (participant[1].streams[0].getVideoTracks().length > 0) {
          //     setActivevideos((count) => count + 1);
          // } else {
          //     return false;
          // }
        }

        // })
      }
    }
  };
  const GetUserNotInSession = (id, userType) => {
    setShowLoader(true);
    setInviUserType(inviUserType);
    // setSearchQry('');
    var data = {id: localStorage.getItem('conf_id')};
    API.users_not_in_conference(data)
      .then(response => {
        if (response) {
          setNotInviteUser(response.data);
          setShowLoader(false);
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };


  // const GetAllUsers = (id, qry) => {
  //   // setShowLoader(true);
  //   var data = {conference_id: id, search_query: qry.trim()};
  //   API.all_users(data)
  //     .then(response => {
  //       if (response) {
  //         // 
  //         setNotInviteUser(response.data);
  //         // setShowLoader(false);
  //       }
  //     })
  //     .catch(err => {
  //       console.log('Err', err);
  //       return {type: 'error', message: err.message};
  //     });
  // };


  // const AddUserInSession = (id, confId) => {
  //   // setShowLoader(true);
  //   var data = {
  //     conference_id: localStorage.getItem('conf_id'),
  //     user_id: id,
  //     type: inviUserType,
  //   };
  //   API.add_user_in_conference(data)
  //     .then(response => {
  //       if (response) {
  //         // 
  //         // 
  //         successMessage(response.message);
  //       }
  //     })
  //     .catch(err => {
  //       console.log('Err', err);
  //       return {type: 'error', message: err.message};
  //     });
  // };

  const ParticipantView = useCallback(({videoStreamList, videoCss}) => {
    // 

    return (
      <>
        {Object.entries(videoStreamList).length > 0 &&
          videoStreamList.map(item => {
            return (
              // item.status === "Connected" && item.streams.length > 0 && item.streams[0].getVideoTracks().length > 0 ?
              item.participant.status === 'Connected' &&
              item.stream &&
              item.stream.getVideoTracks().length > 0 && (
                <div
                  className={
                    item.participant &&
                    item.participant.info &&
                    item.participant.info.sdkVersion
                      ? 'video-item'
                      : 'video-item portrait-view'
                  }
                  key={`ses-vid-${item.participant.id}`}
                  style={
                    videoCss && videoCss.sessionVideoItem
                      ? videoCss.sessionVideoItem
                      : {}
                  }>
                  <ParticipantStream
                    participant={item.participant}
                    stream={item.stream}
                  />
                </div>
              )
              // : item.status === "Connected" && item.streams.length > 1 && item.streams[1].getVideoTracks().length > 0 ?
              //   <Col key={`ses-vid-${item.id}`} md={3} sm={4} xs={6}>
              //     <div className="video-item">
              //       <ParticipantStream participant={item} stream={item.streams[1]} />
              //     </div>
              //   </Col>
              // : null
            );
          })}
        {/* <div className="video-item portrait-view">
           <img src={screenshot2} alt="" /> 
          <img src={VideoCameraActive} alt="" />
        </div> */}
      </>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  // const myArrow = ({type, onClick, isEdge}) => {
  //   // const pointer =
  //   //   type === consts.PREV ? (
  //   //     <i className="fas fa-angle-left"></i>
  //   //   ) : (
  //   //     <i className="fas fa-angle-right"></i>
  //   //   );
    
  //   const directionClass = type === consts.PREV ? 'slide-prev' : 'slide-next';
  //   const pointer =
  //     type === consts.PREV ? (
  //       <i className="fas fa-angle-up"></i>
  //     ) : (
  //       <i className="fas fa-angle-down"></i>
  //     );
  //   return (
  //     <button
  //       className={`slider-nav ${directionClass}`}
  //       onClick={onClick}
  //       disabled={isEdge}>
  //       {pointer}
  //     </button>
  //   );
  // };

  const ParticipantViewList = useCallback(tempProps => {
    // 
    const direction = tempProps.windowWidth > 767 ? 'vertical' : 'horizontal';
    const perSlide = tempProps.windowWidth > 767 ? 'auto' : 3;
    return (
      <Swiper
        direction={direction}
        slidesPerView={perSlide}
        spaceBetween={15}
        navigation={true}
        modules={[Navigation]}
        className="mySwiper">
        {/* <SwiperSlide>
          <div className="video-items">
            <img src={dummyImg} alt="" />
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="video-items">
            <img src={dummyImg} alt="" />
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="video-items">
            <img src={dummyImg} alt="" />
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="video-items">
            <img src={dummyImg} alt="" />
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="video-items">
            <img src={dummyImg} alt="" />
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="video-items">
            <img src={dummyImg} alt="" />
          </div>
        </SwiperSlide> */}
        {Object.entries(Array.from(VoxeetSDK.conference.participants.values()))
          .length > 0 &&
          Array.from(VoxeetSDK.conference.participants.values()).map(item => {
            return item.status === 'Connected' &&
              item.streams.length > 0 &&
              item.streams[0].getVideoTracks().length > 0 ? (
              <SwiperSlide key={`ses-vid-c-${item.id}`}>
                <div
                  className="video-items"
                  onClick={() =>
                    ShowFullVideo(item.id, item.streams[0].id, item.streams[0])
                  }>
                  <ParticipantStream
                    participant={item}
                    stream={item.streams[0]}
                  />
                </div>
              </SwiperSlide>
            ) : item.status === 'Connected' &&
              item.streams.length > 1 &&
              item.streams[1].getVideoTracks().length > 0 ? (
              <SwiperSlide key={`ses-vid-s-${item.id}`}>
                <div
                  className="video-items"
                  onClick={() =>
                    ShowFullVideo(item.id, item.streams[1].id, item.streams[1])
                  }>
                  <ParticipantStream
                    participant={item}
                    stream={item.streams[1]}
                  />
                </div>
              </SwiperSlide>
            ) : null;
          })}
      </Swiper>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  const GridviewContent = useCallback(
    ({videoStreamList, videoCss}) => {
      // 
      return (
        <>
          {videoStreamList && videoStreamList.length < 5 && (
            <div
              className="session-videos-grid flex-grid-layout"
              style={
                videoCss && videoCss.sessionVideoGridMargin
                  ? videoCss.sessionVideoGridMargin
                  : {textAlign: 'center'}
              }>
              <ParticipantView
                videoStreamList={videoStreamList}
                videoCss={videoCss}
              />
            </div>
          )}

          {videoStreamList && videoStreamList.length > 5 && (
            <Scrollbars>
              <div
                className="session-videos-grid grid-layout"
                style={
                  videoCss && videoCss.sessionVideoGridMargin
                    ? videoCss.sessionVideoGridMargin
                    : {}
                }>
                <ParticipantView
                  videoStreamList={videoStreamList}
                  videoCss={videoCss}
                />
              </div>
            </Scrollbars>
          )}
        </>
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [sampleStateV],
  );

  const ListviewContent = useCallback(
    tempProps => {
      const directionClass =
        tempProps.windowWidth > 767 ? 'verticle-slider' : '';
      // 

      return (
        <div className="session-videos-list">
          <div className="session-video">
            <div
              className="video-item"
              id="large-video-container"
              style={{display: 'none'}}>
              <video
                id={`video-objectlarge`}
                className="participant-grid-item__video"
                playsInline
                autoPlay
              />
            </div>
          </div>

          <div className={`session-videos-slider ${directionClass}`}>
            <ParticipantViewList windowWidth={tempProps.windowWidth} />
          </div>

          {/* <div className="video-action-icons">
          <div className="icon-item hangup">
            <span className="icon-hangup" />
          </div>
          <div className="icon-item">
            <span className="icon-clone" />
          </div>
          <div className="icon-item">
            <span className="icon-camera-rotate" />
          </div>
          <div className="icon-item">
            {// <span className="icon-mic-2" /> }
            <span className="icon-mute-icon" />
          </div>
        </div> */}
        </div>
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [sampleStateV],
  );

  const showRequests = () => {
    setStageRequestLoader(true);
    OpenStageRequest();
  };
  const addRequest = () => {
    setHandActive(true);
    successMessage('Request send successfully.');
    const obj = JSON.stringify({
      id: localStorage.getItem('user'),
      name: fixName(
        localStorage.getItem('firstName'),
        localStorage.getItem('lastName'),
      ),
      type: 'microphone_requests',
      sent_at: new Date().getTime(),
    });
    UpdateBroadcastMessage('sessionEvent', 'microphone_requests', obj);
    getCancelMicRequestPusher();
    var data = {
      conference_id: localStorage.getItem('conf_id'),
      mic_status: 'requested',
      user_id: localStorage.getItem('user'),
    };
    API.add_request(data).then(response => {
      VoxeetSDK.command
        .send({dataPayload: 'unmute'})
        .then(succ => {
          
          console.log(succ);
        })
        .catch(err => {
          console.log(err);
        });
    });
  };
  const onLeaveClickUserRefresh = () => {
    
    return;
    // localStorage.setItem('session_started', '');
    // var data = {
    //   conference_id: localStorage.getItem('conf_id'),
    // };
    // if (allSesionData.creator_id == localStorage.getItem('user')) {
    //   API.leave(data).then(response => {
    //     VoxeetSDK.command
    //       .send({dataPayload: 'close_session'})
    //       .then(succ => {
    //         const participants = VoxeetSDK.conference.participants;
    //         participants.forEach(element => {
    //           if (element.info.externalId == localStorage.getItem('user')) {
    //             VoxeetSDK.conference
    //               .leave(element)
    //               .then(() => {
    //                 VoxeetSDK.session.close();
    //               })
    //               .catch(err => console.error(err));
    //           }
    //         });
    //       })
    //       .catch(err => {
    //         console.log(err);
    //       });
    //   });
    // } else {
    //   API.leave(data).then(response => {
    //     if (response) {
    //       VoxeetSDK.command
    //         .send({
    //           dataPayload: {
    //             id: localStorage.getItem('user'),
    //             name: 'left_session',
    //           },
    //         })
    //         .then(succ => {
    //           const participants = VoxeetSDK.conference.participants;
    //           participants.forEach(element => {
    //             if (element.info.externalId == localStorage.getItem('user')) {
    //               VoxeetSDK.conference
    //                 .leave(element)
    //                 .then(() => {
    //                   VoxeetSDK.session.close();
    //                 })
    //                 .catch(err => console.error(err));
    //             }
    //           });
    //         })
    //         .catch(err => {
    //           console.log(err);
    //         });
    //     }
    //   });
    // }
  };
  // const userLeavebyUrl = () => {
  //   localStorage.setItem('session_started', '');
  //   var data = {
  //     conference_id: localStorage.getItem('conf_id'),
  //   };

  //   API.leave(data).then(response => {
  //     if (response) {
  //       VoxeetSDK.command
  //         .send({
  //           dataPayload: {
  //             id: localStorage.getItem('user'),
  //             name: 'left_session',
  //           },
  //         })
  //         .then(succ => {
  //           const participants = VoxeetSDK.conference.participants;
  //           participants.forEach(element => {
  //             if (element.info.externalId === localStorage.getItem('user')) {
  //               VoxeetSDK.conference
  //                 .leave(element)
  //                 .then(() => {
  //                   VoxeetSDK.session.close();
  //                   history.push('/meetups');
  //                 })
  //                 .catch(err => console.error(err));
  //             }
  //           });
  //         })
  //         .catch(err => {
  //           console.log(err);
  //         });
  //     }
  //   });
  // };
  const onLeaveClick = () => {
    localStorage.setItem('checkLastVideoEnabled', '');
    localStorage.setItem('hide_case', '');
    localStorage.setItem('session_started', '');
    // var data = {
    //   conference_id: localStorage.getItem('conf_id'),
    // };
    if (allSesionData.creator_id === localStorage.getItem('user')) {
      setleaveModal(true);
    } else {
      setUserLeftSession(true);
    }
  };
  const creatorAcknoledgeLeave = () => {
    var data = {
      conference_id: localStorage.getItem('conf_id'),
    };
    API.leave(data).then(response => {
      setItem('session_started', '');
      localStorage.setItem('hide_case', '');
      localStorage.setItem('checkLastVideoEnabled', '');
      UpdateBroadcastMessage(
        'sessionEvent',
        'creator_left',
        JSON.stringify({
          id: localStorage.getItem('user'),
          type: 'creator_left',
          sent_at: new Date().getTime(),
        }),
      );
      VoxeetSDK.command
        .send({dataPayload: 'close_session'})
        .then(succ => {
          const participants = VoxeetSDK.conference.participants;
          participants.forEach(element => {
            if (element.info.externalId === localStorage.getItem('user')) {
              VoxeetSDK.conference
                .leave(element)
                .then(() => {
                  VoxeetSDK.session.close();
                  // history.push('/meetups')
                  history.push({
                    pathname: '/meetups',
                    state: {creator: 'yes'},
                  });
                  detachesChatroom();
                })
                .catch(err => console.error(err));
            }
          });
        })
        .catch(err => {
          
          history.push('/meetups');
        });
    });
  };
  const UpdateAudioStatys = (status, id) => {
    const body = {
      conference_id: localStorage.getItem('conf_id'),
      user_id: id,
      status: status,
    };
    commonApi.change_user_mic_status(body).then(res => {
      // setsessionBgImgs(res.all_patterns);
    });
  };

  const getBckGrounds = () => {
    API.get_session_patterns('').then(res => {
      
      
      setsessionBgImgs(res.all_patterns);
    });
  };
  const changeBackground = item => {
    if (item) {
      setSessionBg(item.image);

      UpdateBroadcastMessage(
        'sessionEvent',
        'change_bck',
        JSON.stringify({
          id: localStorage.getItem('user'),
          type: 'change_bck',
          color: item.image,
          sent_at: new Date().getTime(),
        }),
      );
    } else {
      setSessionBg('');
      UpdateBroadcastMessage(
        'sessionEvent',
        'change_bck',
        JSON.stringify({
          id: localStorage.getItem('user'),
          type: 'change_bck',
          color: '',
          sent_at: new Date().getTime(),
        }),
      );
    }
  };
  useEffect(() => {
    //  alert(localStorage.getItem('conferenceId'));
    getBckGrounds();
    var constraints = {
      video: true,
      Audio: true,
    };
    navigator.mediaDevices
      .getUserMedia(constraints)
      .then(stream => {})
      .catch(err => {});

    document.getElementById('ListviewContent').style.display = 'none';
    // 
    // getApiUsers();
    // getData();
  
    GetUserNotInSession('', '');
    const fastInterval = setInterval(() => {
      for (const participant of VoxeetSDK.conference.participants) {
        // if (VoxeetSDK.conference.peerConnectionStatus == 'connected') {
        var person = VoxeetSDK.conference.participants.get(participant[0]);
        if (person) {
          VoxeetSDK.conference.audioLevel(person, level => {
            if (level > 0.05) {
              document
                .getElementById(
                  'speaking-person-' + participant[1].info.externalId,
                )
                ?.classList?.add('active');
            } else {
              document
                .getElementById(
                  'speaking-person-' + participant[1].info.externalId,
                )
                ?.classList?.remove('active');
            }
          });
        }
        //   }
      }
    }, 100);

    setemojisDataArray(emojisData);

    getAllSessionDetails(localStorage.getItem('conf_id'));

    const interval = setInterval(() => {
      // if (typeof allJdEmojis === "object" && allJdEmojis.length > 0) {
      const date = new Date();
      date.setSeconds(date.getSeconds() - 16);
      setAllJdEmojis(current => current.filter(item => item.time > date));
      // }
    }, 1000);
    return () => {
      onLeaveClickUserRefresh();
      clearInterval(interval);
      clearInterval(fastInterval);
      localStorage.removeItem('conf_id');
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    
    if(contact.length > 0){
      setInviteLoader(true)
      setInviteQry('')
    }
  }, [contact])

  // const applink = () => {
  //   setshowApplink(true);
  // };
  const closeApplink = () => {
    setshowApplink(false);
  };

  const openProfile = id => {
    if (id !== localStorage.getItem('user')) {
      let userNotPresent = true;
      const selectParticipant = [
        {
          info: {
            externalId: id,
          },
        },
      ];
      const participants = VoxeetSDK.conference.participants;
      participants.forEach(element => {
        
        if (element.info.externalId === id) {
          userNotPresent = false;
          setselectedParticipitant([element]);
        }
      });
      if (userNotPresent) {
        setselectedParticipitant(selectParticipant);
      }
      setProfileState(true);
      // setTimeout(() => {
      // }, 300);
    }
  };
  const acceptStatus = id => {
    microphoneRequestAccepted(id);
    var data = {
      user_id: id,
      conference_id: allSesionData.id,
      mic: 'on',
      mic_status: 'approved',
    };
    API.update_mic_status(data)
      .then(res => {
        OpenStageRequest();
        if (res) {
          const data = {
            user_id: id,
            user_type: 'stage',
            conference_id: allSesionData.id,
          };
          API.manage_user(data).then(res => {
            const obj = JSON.stringify({
              id: id,
              type: 'stage',
              sent_at: new Date().getTime(),
            });
            UpdateBroadcastMessage('sessionEvent', 'stage', obj);

            const obj1 = JSON.stringify({
              id: id,
              type: 'user_un_muted',
              sent_at: new Date().getTime(),
            });
            UpdateBroadcastMessage('sessionEvent', 'user_un_muted', obj1);

            UpdateBroadcastMessage(
              'sessionEvent',
              'microphone_requests',
              JSON.stringify({
                id: '',
                name: '',
                type: 'microphone_requests',
                sent_at: '',
              }),
            );

            UpdateBroadcastMessage(
              'sessionEvent',
              'microphone_request_accepted',
              JSON.stringify({
                id: id,
                name: '',
                type: 'microphone_request_accepted',
                sent_at: new Date().getTime(),
              }),
            );
          });
          // VoxeetSDK.command
          //   .send({
          //     dataPayload: {
          //       user_id: id,
          //       mic: 'on',
          //       conference_id: allSesionData.id,
          //     },
          //   })
          //   .then(succ => {
          //     VoxeetSDK.command
          //       .send({dataPayload: {id: id, name: 'request_accepted'}})
          //       .then(succ => {
          //         console.log(succ);
          //         messageService.sendMessage('update_api');
          //         successMessage('Request accepted successfully.');
          //       })
          //       .catch(err => {
          //         console.log(err);
          //       });
          //   })
          //   .catch(err => {
          //     console.log(err);
          //   });
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };
  const rejectStatus = id => {
    microphoneRequestCancel(id);
    var data = {
      user_id: id,
      conference_id: allSesionData.id,
      mic: 'off',
      mic_status: 'ignored',
    };

    API.update_mic_status(data)
      .then(res => {
        OpenStageRequest();
        if (res) {
          UpdateBroadcastMessage('sessionEvent', 'microphone_request', {
            id: '',
            name: '',
            type: 'microphone_requests',
            sent_at: '',
          });
          UpdateBroadcastMessage(
            'sessionEvent',
            'microphone_request_cancel',
            JSON.stringify({
              id: id,
              name: '',
              type: 'microphone_request_cancel',
              sent_at: new Date().getTime(),
            }),
          );
          errorMessage('Request rejected successfully.');
          // VoxeetSDK.command
          //   .send({
          //     dataPayload: {
          //       user_id: id,
          //       mic: 'off',
          //       conference_id: allSesionData.id,
          //     },
          //   })
          //   .then(succ => {
          //     console.log(succ);
          //     errorMessage('Request rejected successfully.');
          //   })
          //   .catch(err => {
          //     console.log(err);
          //   });
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };
  const generateName = length => {
    let result = '';
    const characters =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result + localStorage.getItem('user');
  };
  const showMessagesAll = () => {
    // setShowLoader(true);
    setChatLoader(true);
    const chatroome = 'web_chatroom_' + generateName(5);
    let all_moderators = '';
    if (allSesionData.moderator_ids) {
      all_moderators = allSesionData.moderator_ids;
    }
    let data = {
      audience_ids: allSesionData.audience_ids,
      chat_room_name: chatroome,
      creator_id: allSesionData.creator_id,
      group_image: DEFAULT_IMAGE,
      group_name: allSesionData.session_title,
      moderator_ids: all_moderators,
      type: 'session',
      type_id: allSesionData.id,
      left_removed_users: '',
    };

    API.create_session_group_chat_dolby(data)
      .then(res => {
        if (res) {
          setchatroom(res.group_chat_detail);
          setGroupChatName(res.group_chat_detail.chat_room_name);
          setTimeout(() => {
            setShowMessages(true);
            setChatLoader(false);
            setShowLoader(false);
          }, 1000);
        }
      })
      .catch(err => {
        setChatLoader(false);
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };
  const OpenStageRequest = () => {
    var data = {conference_id: allSesionData.id};
    API.allmicRequests(data)
      .then(res => {
        if (res) {
          setallRequest(res.data);
          setStageRequest(true);
          setStageRequestLoader(false);
          setShowLoader(false);
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  const CloseInviteModal = () => {
    // if(inviteUser){
    //   setInviteUser(false)
    // }else{
    //   setInviteUser(true)
    // }
    setInviteUser(false);
    setModerator([]);
    setAudience([]);
    setOriginalDataOfMod([]);
    setSelectedAudData([]);
    setOriginalDataOfMod([]);
    setOriginalDataOfAud([]);
  };

  // const OpenEmojiModal = () => {
  //   if (!showEmojiModal) {
  //     setShowLoader(true);
  //     setTimeout(() => {
  //       setShowLoader(false);
  //     }, 2000);
  //   }
  //   setshowEmojiModal(true);
  // };

  const closeProfile = () => {
    setProfileState(false);
  };

  const fbParticipantAdded = () => {
    const database = ref(
      db,
      'sessionEvent' +
        '/' +
        'update_api' +
        '/' +
        localStorage.getItem('conf_id'),
    );

    off(database);

    UpdateBroadcastMessage(
      'sessionEvent',
      'update_api',
      JSON.stringify({
        id: localStorage.getItem('user'),
        name: 'update_api',
      }),
    );

    onValue(database, snapshot => {
      // const data = JSON.parse(snapshot.val());
      UpdateSessionDetails(localStorage.getItem('conf_id'));
    });
  };
  const comeOnStageFromAudience = () => {
    setHandActive(false);
    setAudioEnable(false);
    const participants = VoxeetSDK.conference.participants;
    participants.forEach(element => {
      if (element.info.externalId === localStorage.getItem('user')) {
        hitLeaveAPi();
        VoxeetSDK.conference
          .leave(element)
          .then(() => {
            VoxeetSDK.session.close();
          
            VoxeetSDK.initialize(consumerKey, consumerSecret);
            setTimeout(() => {
            
              const mixer = {
                name:
                  localStorage.getItem('firstName') +
                  ' ' +
                  localStorage.getItem('lastName').charAt(0).toUpperCase() +
                  '.',
                externalId: localStorage.getItem('user'),
                avatarUrl: localStorage.getItem('profilePic'),
              };

              VoxeetSDK.session
                .open(mixer)
                .then(() =>
                  VoxeetSDK.conference.fetch(
                    localStorage.getItem('conferenceId'),
                  ),
                )
                // Join the conference
                .then(conference => {
                  try {
                    var payloadApi = {
                      conference_id: localStorage.getItem('conf_id'),
                    };
                    API.is_session_joined(payloadApi);
                    VoxeetSDK.conference
                      .join(conference, {
                        constraints: {video: false, audio: false},
                      })
                      .catch(err => console.error(err));
                    //  allFirebaseEvents();

                    const participants = VoxeetSDK.conference.participants;
                  
                    participants.forEach(element => {
                      if (
                        element.streams[0] &&
                        element.streams[0].getVideoTracks()
                      ) {
                        setVideoStreamList(current => {
                          const found = current.find(
                            objs =>
                              objs.participant.info.externalId ===
                              element.info.externalId,
                          );
                          if (typeof found === 'object') {
                            return current.map(obj => {
                              if (
                                obj.participant.info.externalId ===
                                element.info.externalId
                              ) {
                                return {
                                  ...obj,
                                  participant: element,
                                  stream: element.streams[0],
                                };
                              }
                              return obj;
                            });
                          } else {
                            return [
                              ...current,
                              {
                                participant: element,
                                stream: element.streams[0],
                              },
                            ];
                          }
                        });
                      } else if (
                        element.streams[1] &&
                        element.streams[1].getVideoTracks()
                      ) {
                        setVideoStreamList(current => {
                          const found = current.find(
                            objs =>
                              objs.participant.info.externalId ===
                              element.info.externalId,
                          );
                          if (typeof found === 'object') {
                            return current.map(obj => {
                              if (
                                obj.participant.info.externalId ===
                                element.info.externalId
                              ) {
                                return {
                                  ...obj,
                                  participant: element,
                                  stream: element.streams[1],
                                };
                              }
                              return obj;
                            });
                          } else {
                            return [
                              ...current,
                              {
                                participant: element,
                                stream: element.streams[1],
                              },
                            ];
                          }
                        });
                      }
                    });
                    UpdateSessionDetails(localStorage.getItem('conf_id'));
                  } catch (error) {
                    console.error(error);
                  }
                })
                .catch(err => console.error(err));
            }, 1500);
          })
          .catch(err => console.error(err));
      }
    });
  };
  const hitLeaveAPi = () => {
    var data = {
      conference_id: localStorage.getItem('conf_id'),
    };

    API.leave(data).then(response => {});
  };
  const goToAudiance = () => {
    setAudioEnable(false);
    const participants = VoxeetSDK.conference.participants;
    participants.forEach(element => {
      if (element.info.externalId === localStorage.getItem('user')) {
        hitLeaveAPi();
        VoxeetSDK.conference
          .leave(element)
          .then(() => {
            VoxeetSDK.session.close();
           
            VoxeetSDK.initialize(consumerKey, consumerSecret);
            setTimeout(() => {
             
              const mixer = {
                name:
                  localStorage.getItem('firstName') +
                  ' ' +
                  localStorage.getItem('lastName').charAt(0).toUpperCase() +
                  '.',
                externalId: localStorage.getItem('user'),
                avatarUrl: localStorage.getItem('profilePic'),
              };

              VoxeetSDK.session
                .open(mixer)
                .then(() =>
                  VoxeetSDK.conference.fetch(
                    localStorage.getItem('conferenceId'),
                  ),
                )
                // Join the conference
                .then(conference => {
                  try {
                    var payloadApi = {
                      conference_id: localStorage.getItem('conf_id'),
                    };
                    API.is_session_joined(payloadApi);
                    VoxeetSDK.conference
                      .listen(conference, {
                        constraints: {video: false, audio: false},
                      })
                      .catch(err => console.error(err));
                    //    allFirebaseEvents();

                    const participants = VoxeetSDK.conference.participants;
                  
                    participants.forEach(element => {
                      if (
                        element.streams[0] &&
                        element.streams[0].getVideoTracks()
                      ) {
                        setVideoStreamList(current => {
                          const found = current.find(
                            objs =>
                              objs.participant.info.externalId ===
                              element.info.externalId,
                          );
                          if (typeof found === 'object') {
                            return current.map(obj => {
                              if (
                                obj.participant.info.externalId ===
                                element.info.externalId
                              ) {
                                return {
                                  ...obj,
                                  participant: element,
                                  stream: element.streams[0],
                                };
                              }
                              return obj;
                            });
                          } else {
                            return [
                              ...current,
                              {
                                participant: element,
                                stream: element.streams[0],
                              },
                            ];
                          }
                        });
                      } else if (
                        element.streams[1] &&
                        element.streams[1].getVideoTracks()
                      ) {
                        setVideoStreamList(current => {
                          const found = current.find(
                            objs =>
                              objs.participant.info.externalId ===
                              element.info.externalId,
                          );
                          if (typeof found === 'object') {
                            return current.map(obj => {
                              if (
                                obj.participant.info.externalId ===
                                element.info.externalId
                              ) {
                                return {
                                  ...obj,
                                  participant: element,
                                  stream: element.streams[1],
                                };
                              }
                              return obj;
                            });
                          } else {
                            return [
                              ...current,
                              {
                                participant: element,
                                stream: element.streams[1],
                              },
                            ];
                          }
                        });
                      }
                    });
                    setTimeout(() => {
                      addListner();
                    }, 1000);

                    UpdateSessionDetails(localStorage.getItem('conf_id'));
                  } catch (error) {
                    console.error(error);
                  }
                })
                .catch(err => console.error(err));
            }, 1500);
          })
          .catch(err => console.error(err));
      }
    });
  };
  const allFirebaseEvents = () => {
    const microphone_requests = ref(
      db,
      'sessionEvent' +
        '/' +
        'microphone_requests' +
        '/' +
        localStorage.getItem('conf_id'),
    );
    onValue(microphone_requests, snapshot => {
      // const data = JSON.parse(snapshot.val());
      
    });
    const user_on_call = ref(
      db,
      'sessionEvent' +
        '/' +
        'user_on_call' +
        '/' +
        localStorage.getItem('conf_id'),
    );
    onValue(user_on_call, snapshot => {
      // const data = JSON.parse(snapshot.val());
      
    });

    const message_group_created = ref(
      db,
      'sessionEvent' +
        '/' +
        'message_group_created' +
        '/' +
        localStorage.getItem('conf_id'),
    );
    onValue(message_group_created, snapshot => {
      // const data = JSON.parse(snapshot.val());
      
    });
    const user_muted = ref(
      db,
      'sessionEvent' +
        '/' +
        'user_muted' +
        '/' +
        localStorage.getItem('conf_id'),
    );
    onValue(user_muted, snapshot => {
      const data = JSON.parse(snapshot.val());
      
      if (data.id === localStorage.getItem('user')) {
        const participants = VoxeetSDK.conference.participants;
        participants.forEach(element => {
          if (
            element.info.externalId === data.id &&
            element.info.externalId === localStorage.getItem('user')
          ) {
            setAudioEnable(false);
            VoxeetSDK.conference
              .stopAudio(element)
              .then(() => {
                UpdateAudioStatys('off', element.info.externalId);
                if (
                  element.streams.length > 0 &&
                  element.streams[0].getVideoTracks().length > 0
                ) {
                  VoxeetSDK.conference
                    .stopVideo(element)
                    .then(() => {
                      UpdateBroadcastMessage(
                        'sessionEvent',
                        'close_large_video',
                        JSON.stringify({
                          type: 'close_large_video',
                          user_id: element.info.externalId,
                          sent_at: new Date().getTime(),
                        }),
                      );
                      setVideoVisible(true);
                    })
                    .catch(err => console.error(err));
                }
              })
              .catch(err => console.error(err));
          }
        });
      }
    });

    const close_large_video = ref(
      db,
      'sessionEvent' +
        '/' +
        'close_large_video' +
        '/' +
        localStorage.getItem('conf_id'),
    );
    onValue(close_large_video, snapshot => {
      // const data = JSON.parse(snapshot.val());
      
    });
    const add_listner = ref(
      db,
      'sessionEvent' +
        '/' +
        'add_listener' +
        '/' +
        localStorage.getItem('conf_id'),
    );
    onValue(add_listner, snapshot => {
      // const data = JSON.parse(snapshot.val());
      
    });

    const ban_user = ref(
      db,
      `sessionEvent/ban_user/${localStorage.getItem('conf_id')}`
    );
    onValue(ban_user, snapshot => {
      const data = JSON.parse(snapshot.val());
      
      if (data.id === localStorage.getItem('user')) {
        const diffInMinutes = (new Date().getTime() - data.sent_at) / 60000;
        if (data && data.sent_at !== '' && diffInMinutes <= 1) {
          leave_ban();
          const obj1 = JSON.stringify({
            type: 'user_left',
            sent_at: new Date().getTime(),
          });
          UpdateBroadcastMessage('sessionEvent', 'user_left', obj1);
        }
      }
    });

    const switch_creator = ref(
      db,
      'sessionEvent' +
        '/' +
        'switch_creator' +
        '/' +
        localStorage.getItem('conf_id'),
    );
    onValue(switch_creator, snapshot => {
      const data = JSON.parse(snapshot.val());
      
      UpdateSessionDetails(localStorage.getItem('conf_id'));
      if (data.id === localStorage.getItem('user')) {
        if (data.previous_type === 'moderator') {
          UpdateSessionDetails(localStorage.getItem('conf_id'));
          setSuperModerator(true);
          setiamModerator(true);
        }
        if (data.previous_type === 'audience') {
          comeOnStageFromAudience();
          //   setTimeout(() => {
          setSuperModerator(true);
          setiamModerator(true);
        }
        if (data.previous_type === 'stage') {
          UpdateSessionDetails(localStorage.getItem('conf_id'));
          //   setTimeout(() => {
          setSuperModerator(true);
          setiamModerator(true);
        }
      }
    });

    const creator_left = ref(
      db,
      'sessionEvent' +
        '/' +
        'creator_left' +
        '/' +
        localStorage.getItem('conf_id'),
    );
    onValue(creator_left, snapshot => {
      // const data = JSON.parse(snapshot.val());
      
    });

    const reload_links = ref(
      db,
      'sessionEvent' +
        '/' +
        'reload_links' +
        '/' +
        localStorage.getItem('conf_id'),
    );
    onValue(reload_links, snapshot => {
      // const data = JSON.parse(snapshot.val());
      
      UpdateSessionDetails(localStorage.getItem('conf_id'));
    });

    const reload_api = ref(
      db,
      'sessionEvent' +
        '/' +
        'reload_api' +
        '/' +
        localStorage.getItem('conf_id'),
    );
    onValue(reload_api, snapshot => {
      // const data = JSON.parse(snapshot.val());
      
      UpdateSessionDetails(localStorage.getItem('conf_id'));
    });

    const moderator = ref(
      db,
      'sessionEvent' +
        '/' +
        'moderator' +
        '/' +
        localStorage.getItem('conf_id'),
    );
    onValue(moderator, snapshot => {
      const data = JSON.parse(snapshot.val());
      
      if (
        superModerator === false &&
        iamModerator === false &&
        data.id === localStorage.getItem('user')
      ) {
        const diffInMinutes = (new Date().getTime() - data.sent_at) / 60000;
        if (data && data.sent_at !== '' && diffInMinutes <= 1) {
          comeOnStageFromAudience();
          //   setTimeout(() => {
          setSuperModerator(true);
          setiamModerator(true);
          // }, 1500);
        }
      }
    });

    const audience = ref(
      db,
      `sessionEvent/audience/${localStorage.getItem('conf_id')}`
    );
    onValue(audience, snapshot => {
      const data = JSON.parse(snapshot.val());
      
      if (data.id === localStorage.getItem('user')) {
        const diffInMinutes = (new Date().getTime() - data.sent_at) / 60000;
        if (data && data.sent_at !== '' && diffInMinutes <= 1) {
          goToAudiance();

          stopScreen(data.id);

          // stopVideo();

          setSuperModerator(false);
          setiamModerator(false);
        }
      }
    });

    const stage = ref(
      db,
      `sessionEvent/stage/${localStorage.getItem('conf_id')}`
    );
    
    onValue(stage, snapshot => {
      const data = JSON.parse(snapshot.val());
      

      //  alert(superModerator + ' stage ' + iamModerator);
      if (
        superModerator === false &&
        iamModerator === false &&
        data.id === localStorage.getItem('user')
      ) {
        const diffInMinutes = (new Date().getTime() - data.sent_at) / 60000;
        if (data && data.sent_at !== '' && diffInMinutes <= 1) {
          comeOnStageFromAudience();
          //    setTimeout(() => {
          setSuperModerator(false);
          setiamModerator(true);
          //  }, 1500);
        }
      }
    });

    const user_left = ref(
      db,
      `sessionEvent/user_left/${localStorage.getItem('conf_id')}`
    );
    
    onValue(user_left, snapshot => {
      // const data = JSON.parse(snapshot.val());
      
    });
  };
  const startFirebaseEvents = () => {
    closeSessionEventRequests();

    const database = ref(
      db,
      'sessionEvent' +
        '/' +
        'show_emoji' +
        '/' +
        localStorage.getItem('conf_id'),
    );
    onValue(database, snapshot => {
      const data = JSON.parse(snapshot.val());
      const date = new Date();
      date.setSeconds(date.getSeconds() - 16);
      if (data && Object.entries(data).length > 0) {
        setAllJdEmojis(current => {
          const found = current.find(objs => objs.id === data.id);
          if (new Date(data.time) > date) {
            if (typeof found === 'object') {
              return current.map(obj => {
                if (obj.id === data.id) {
                  return {...obj, emoji: data.emoji, time: new Date()};
                }
                return obj;
              });
            } else {
              return [
                ...current,
                {
                  id: data.id,
                  emoji: data.emoji,
                  time: new Date(),
                },
              ];
            }
          } else {
            return current;
          }
        });
      }
    });

    const databaseForBck = ref(
      db,
      'sessionEvent' +
        '/' +
        'change_bck' +
        '/' +
        localStorage.getItem('conf_id'),
    );
    onValue(databaseForBck, snapshot => {
      const data = JSON.parse(snapshot.val());
     
      if (data) {
        setSessionBg(data.color);
      }
    });

    getCancelMicRequestPusher();
  };

  const getCancelMicRequestPusher = () => {
    cancelMicRequestPusherOff();
    const databaseMicRequest = ref(
      db,
      'sessionEvent' +
        '/' +
        'microphone_request_cancel' +
        '/' +
        localStorage.getItem('conf_id'),
    );
    onValue(databaseMicRequest, snapshot => {
      const date = new Date();
      date.setSeconds(date.getSeconds() - 2);
      const data = JSON.parse(snapshot.val());
     
     
      if (data && data.id === getItem('id') && date.getTime() < data.sent_at) {
        
        setHandActive(false);
      }
    });
  };

  const microphoneRequestAccepted = id => {
    UpdateBroadcastMessage(
      'sessionEvent',
      'microphone_request_accepted',
      JSON.stringify({
        id: id,
        name: '',
        type: 'microphone_request_accepted',
        sent_at: new Date().getTime(),
      }),
    );
    const obj1 = JSON.stringify({
      id: id,
      type: 'user_un_muted',
      sent_at: new Date().getTime(),
    });
    UpdateBroadcastMessage('sessionEvent', 'user_un_muted', obj1);
    const obj = JSON.stringify({
      id: id,
      type: 'stage',
      sent_at: new Date().getTime(),
    });
    UpdateBroadcastMessage('sessionEvent', 'stage', obj);
  };

  const microphoneRequestCancel = id => {
    UpdateBroadcastMessage(
      'sessionEvent',
      'microphone_request_cancel',
      JSON.stringify({
        id: id,
        name: '',
        type: 'microphone_request_cancel',
        sent_at: new Date().getTime(),
      }),
    );
    // UpdateBroadcastMessage('sessionEvent', 'microphone_request', {
    //   id: '',
    //   name: '',
    //   type: 'microphone_requests',
    //   sent_at: '',
    // });
  };

  const addListner = () => {
    const allparticipitants = VoxeetSDK.conference.participants;
    let userListner = {
      // audioReceivingFrom: false,
      // audioTransmitting: true,
      id: '',
      info: {
        avatarUrl: DEFAULT_IMAGE,
        externalId: localStorage.getItem('id'),
        name: localStorage.getItem('firstName'),
      },
      status: 'CONNECTING',
      // streams: [],
      type: 'LISTENER',
    };

    for (const [ value] of allparticipitants) {
      
      if (value.info.externalId === localStorage.getItem('user')) {
        // userListner.push(value);
        // userListner = {participant: value};
        userListner.id = value.id;
        userListner.info.avatarUrl = value.info.avatarUrl;
        userListner.info.externalId = value.info.externalId;
        userListner.info.name = value.info.name;
        // userListner.participant.streams = value.streams;
      }
    }
  
   
    const obj = JSON.stringify({
      id: localStorage.getItem('user'),
      type: 'add_listner',
      all_participitants: [userListner],
      sent_at: new Date().getTime(),
    });
    UpdateBroadcastMessage('sessionEvent', 'add_listner', obj);
  };

  const closeSessionEventRequests = () => {
    const database = ref(
      db,
      'sessionEvent' +
        '/' +
        'show_emoji' +
        '/' +
        localStorage.getItem('conf_id'),
    );
    off(database);

    cancelMicRequestPusherOff();
  };

  const cancelMicRequestPusherOff = () => {
    const dbMicRequest = ref(
      db,
      'sessionEvent' +
        '/' +
        'microphone_request_cancel' +
        '/' +
        localStorage.getItem('conf_id'),
    );
    off(dbMicRequest);
  };

  const sendEmoji = data => {
    // 

    UpdateBroadcastMessage(
      'sessionEvent',
      'show_emoji',
      JSON.stringify({
        id: localStorage.getItem('user'),
        name: 'show_emoji',
        emoji: data,
        time: new Date(),
      }),
    );

    VoxeetSDK.command
      .send({
        dataPayload: {
          id: localStorage.getItem('user'),
          name: 'show_emoji',
          emoji: data,
        },
      })
      .then(succ => {
        messageService.sendMessage({
          id: localStorage.getItem('user'),
          name: 'show_emoji',
          emoji: data,
        });
        setshowEmojiModal(false);
      })
      .catch(err => {
        console.log(err);
        setshowEmojiModal(false);
      });
  };
  const StageRequestClose = () => {
    setStageRequest(false);
  };
  
  // const checkUserLeaveByUrl = () => {
  //   const database = ref(db, 'session_users_status_web');
  //   onValue(database, snapshot => {
  //     var data = snapshotToArray(snapshot);

      
      
  //     if (data) {
  //       data.forEach(e => {
  //         if (e.session_id === localStorage.getItem('conf_id')) {
  //           if (e.creator_id) {
  //             userLeavebyUrl();
  //           } else {
  //             UpdateSessionDetails(localStorage.getItem('conf_id'));
  //           }
  //         }
  //       });
  //     }
  //   });
  // };
  
  
  // const snapshotToArray = snapshot => {
  //   let returnArr = [];
  //   snapshot.forEach(childSnapshot => {
  //     let item = childSnapshot.val();
  //     item.key = childSnapshot.key;
  //     returnArr.push(item);
  //   });

  //   return returnArr;
  // };

  const [isFloated, toggleFloatedPanel] = useState(true);
  const [showHiddenPanel, toggleHiddenPanel] = useState(false);
  const expandActive = showHiddenPanel ? 'active' : '';

  useEffect(() => {
    const newState = !isVideoEnable || shareScreenstatus ? true : false;
    toggleFloatedPanel(newState);
    localStorage.setItem('shareScreenstatus', shareScreenstatus);
  }, [isVideoEnable, shareScreenstatus]);

  useEffect(() => {
    if (videoStreamList.length !== 0) {
      localStorage.setItem('videoStreams', JSON.stringify(videoStreamList));
    }
  }, [videoStreamList]);
  const goback = () => {
    localStorage.setItem('hide_case', 'yes');
    localStorage.setItem('videoStreams', JSON.stringify(videoStreamList));
    history.push('/meetups');
  };

  /**
   * New Video Layout
   */

  const [videoCss, setVideoCss] = useState({});

  const reOrganizeGrid = () => {
    const videosCount = videoStreamList.length;

    if (videoStreamList.length > 0) {
      const personAspect =
        16 / 9; /* SET TO WHAT YOU WANT IT TO BE FOR EACH PERSON DIV */
      const maxCols = 7;

      let cols = 1;
      for (cols; cols <= maxCols; cols++) {
        if (videosCount <= cols * cols) {
          break;
        }
      }

      let w = 0; //will be of the container in px
      let h = 0; //will be height of the container in px

      const gridViewContainer = document.getElementById('GridviewContent');
      const windowContainerHeight = gridViewContainer.offsetHeight;

      if (videosCount > cols * (cols - 1)) {
        // > OK for 5 and 6 not OK for 7
        h = windowContainerHeight;
        w = h * personAspect;
      } else {
        w = gridViewContainer.offsetWidth;
        h = w / personAspect;

        if (h > windowContainerHeight && window.innerWidth > 1100) {
          h = windowContainerHeight - 40;
          w = h * personAspect;
        }
      }

      if (videosCount > cols * cols) {
        // > OK for 5 and 6 not OK for 7
        h = windowContainerHeight;
        w = h * personAspect;
      } else {
        w = gridViewContainer.offsetWidth;
        h = w / personAspect;

        if (h > windowContainerHeight && window.innerWidth > 1100) {
          h = windowContainerHeight - 40;
          w = h * personAspect;
        }
      }

      

      const tempWidth = 100 / cols;
      const tempHeight = h / cols;

      
      

      if (videosCount <= cols * (cols - 1)) {
        h = h - h / cols;
      } // for when last row is empty
      const topMarign = -h / 2;

      const videoCss = {
        sessionVideoGridMargin: {
          width: w,
          marginTop: `${topMarign}px`,
        },
        sessionVideoItem: {
          flex: `0 0 ${tempWidth}%`,
          height: `${tempHeight}px`,
        },
      };

      setVideoCss(videoCss);
    }
  };

  useEffect(() => {
    window.onresize = () => {
      reOrganizeGrid();
      
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setTimeout(() => {
      
      reOrganizeGrid();
    }, 1000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.menuState, showHiddenPanel]);

  useEffect(() => {
    
    if (videoStreamList.length > 0) {
      // console.log("videoStreamList test ");
      reOrganizeGrid();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [videoStreamList]);

  const requestPermission = async type => {
    try {
       await navigator.mediaDevices.getUserMedia(
        type === 'audio'
          ? {
              audio: true,
            }
          : type === 'video' && {
              video: true,
            },
      );
      if (type === 'audio') {
        // alert('audio')
        audioStartBtn();
      } else if (type === 'video') {
        // alert('video')
        videoStartBtn();
      }
    } catch (error) {
      // Permission denied or an error occurred
      errorMessage(
        ' Please grant permission from browser to access this feature',
      );
      // Handle permission denied here
    }
  };

  const CircleUserListData = item => {
    if (item.length > 0) {
      if (subTabs === 'moderator') {
        item.forEach((miniItem, index) => {
          if (
            !moderator.includes(miniItem.id) &&
            !audience.includes(miniItem.id)
          ) {
            setModerator(previous => [...previous, miniItem.id]);
            setSelectedModData(previous => [...previous, miniItem]);
            setOriginalDataOfMod(previous => [...previous, miniItem]);
          }
        });
      }
      if (subTabs === 'audience') {
        item.forEach((miniItem, index) => {
          if (
            !audience.includes(miniItem.id) &&
            !moderator.includes(miniItem.id)
          ) {
            setAudience(previous => [...previous, miniItem.id]);
            setSelectedAudData(previous => [...previous, miniItem]);
            setOriginalDataOfAud(previous => [...previous, miniItem]);
          }
        });
      }
    }
  };

  const handleCircle = () => {
    setShowComponent('Builders');
  };



  useEffect(() => {
    if (groupChatName !== '') {
      openChatroom(groupChatName);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupChatName]);

  const openChatroom = chatName => {
    // localStorage.removeItem('isCorrectPath');
    // localStorage.removeItem('isCorrectMessage');
    // console.log('chatrooms/' + xfbFolder + '/' + groupChatName);
    // localStorage.setItem(
    //   'chatPath',
    //   'chatrooms/' + xfbFolder + '/' + groupChatName,
    // );

    // const chatPath = localStorage.getItem('chatPath');
    // let database;
    // if (chatPath) {
    //    database = ref(db, chatPath);}

    // else{

    const database = ref(db, 'chatrooms/' + xfbFolder + '/' + groupChatName);
    // }

    onValue(database, snapshot => {
      if (snapshot.val() && Object.entries(snapshot.val()).length > 0) {
        // alert('')
        
        if (!showMessages) {
          setUnRead(true);
        }
        
        // messageCounter++;
        

        //         const previousLength = localStorage.getItem('prevmessageLength')
        //         if (previousLength){
        //            var previousMessageLength = Number(previousLength)
        //         }
        //         else{
        //           var previousMessageLength = 0
        //         }
        // console.log(previousLength,'previousLength')
        // console.log(previousMessageLength,'previousMessageLength')
        //         // const currentLength = Object.entries(snapshot.val()).length
        //         const date = Object.keys(snapshot.val())[0]
        //        const currentMessageLength = Object.keys(snapshot.val()[date]).length

        //         setallChat(snapshot.val());
        //         console.log('snapshot.val()snapshot.val()', snapshot.val());
        //         let type = 'newMsg';
        //         if (currentMessageLength > previousMessageLength){
        //          dispatch(getMessageCounter(type));
        //      }

        //      localStorage.setItem('prevmessageLength',currentMessageLength)
      }
    });
  };

  const detachesChatroom = () => {
    const chatPath = localStorage.getItem('chatPath');
    if (chatPath) {
      const database = ref(db, chatPath);
      off(database);
    }
  };

  return (
    <>
      {/* {showLoader && <LoaderSpinner show={showLoader} />} */}

      {/* <Modal className="medium" show={showModal} onHide={() => closeModal()}>
        <Modal.Header>
          <Modal.Title>
            <h2>Request</h2>
          </Modal.Title>
          <div className="modal-header-btns">
            <span className="close as-link" onClick={() => closeModal()}>
              <i className="fas fa-times" />
            </span>
          </div>
        </Modal.Header>
        <Modal.Body>
          <div>
            <h3>Request recieved for stage</h3>
          </div>
          <div className="session-profile-wrapper profile-modal">
            <Button onClick={() => RequestStatusUpdate('Accept')}>
              Accept
            </Button>
            <Button
            setShowModal(false); // RequestStatusUpdate("Ignore")
            onClick={() => {
              }}>
              Ignore
            </Button>
          </div>
        </Modal.Body>
      </Modal> */}

      {/* <Modal
        className="medium"
        show={leaveModal}
        onHide={() => leavecloseModal()}>
        <Modal.Header>
          <Modal.Title>
            <h2>Session</h2>
          </Modal.Title>
          <div className="modal-header-btns">
            <span className="close as-link" onClick={() => leavecloseModal()}>
              <i className="fas fa-times" />
            </span>
          </div>
        </Modal.Header>
        <Modal.Body>
          <div>
            <h3>Are you sure you want to close this session?</h3>
          </div>
          <div className="session-profile-wrapper profile-modal">
            <Button onClick={() => creatorAcknoledgeLeave()}>
              End Session
            </Button>
          </div>
        </Modal.Body>
      </Modal> */}

      {/* <Modal
        className="medium"
        show={ShowSSModal}
        onHide={() => setShowSSModal(false)}>
        <Modal.Header>
          <Modal.Title>
            <h2>Screen Share</h2>
          </Modal.Title>
          <div className="modal-header-btns">
            <span
              className="close as-link"
              onClick={() => setShowSSModal(false)}>
              <i className="fas fa-times" />
            </span>
          </div>
        </Modal.Header>
        <Modal.Body>
          <div>
            <h3>Your video is stopped.</h3>
            <h3>Are you sure you want to share screen?</h3>
          </div>
          <div className="session-profile-wrapper profile-modal">
            <Button onClick={() => handleShareScreen()}>Start sharing</Button>
          </div>
        </Modal.Body>
      </Modal> */}

      <div className="page-wrapper" id="sessionPage">
        <Sidebar
        isFloated={isFloated}
          location={props.location}
          setWorkSpaceItem={setWorkspaceData}
          workspaceData={workspaceData}
          setShowComponent={setShowComponent}
          showComponent={showComponent}
        />
        <div className="page-content-wrapper">
          {/* {showLoader && <LoaderSpinner show={showLoader} />} */}
          <Pannels
            isFloated={totalVideo !== 0 ? true : false}
            showHiddenPanel={showHiddenPanel}
            updateHiddenPanelState={nState => toggleHiddenPanel(nState)}
            primary={
              <>
                {showLoader && <LoaderSpinner show={showLoader} />}
                <div className="sidebar-heading-wrapper mb-3">
                  <div className="heading">
                    <div
                      onClick={() => {
                        startFirebaseEvents();
                        goback();
                      }}
                      className="backbtn">
                      <i className="fas fa-angle-left" /> Back
                    </div>
                  </div>

                  <span
                    className="close logout as-link"
                    onClick={() => onLeaveClick()}>
                    <i className="icon-logout"></i>
                  </span>
                </div>

                <div className="sessions-details-content-wrapper">
                  <div className="session-content">
                    <h2>{allSesionData.session_title}</h2>
                    <span className="auther-name">
                      {labels.creator_text[selected_lang]}:{' '}
                      {/* {allSesionData.creator_name} */}
                      {allSesionData.creator_name &&
                        fixName(
                          allSesionData.creator_name.split(' ')[0],
                          allSesionData.creator_name.split(' ')[1],
                        )}
                      {allSesionData.session_description && (
                        <span className="info-icon">
                          <OverlayTrigger placement="bottom" overlay={popover}>
                            <i className="fas fa-circle-info" />
                          </OverlayTrigger>
                        </span>
                      )}
                    </span>

                    <div className="user-info">
                      <span className="icon-text groupIcon">
                        <img src={SessionGroupLight} alt="" />{' '}
                        <span>{allSesionData.total_connections}</span>
                      </span>
                      <span className="icon-text videoIcon">
                        <img src={SessionVideoLight} alt="" />
                        <span>{totalVideo}</span>
                      </span>
                      {localStorage.getItem('creator_id') ===
                        localStorage.getItem('user') && (
                        <span
                          onClick={() => {
                            setShowCreateEvent(true);
                          }}
                          className="icon-text as-link">
                          <i className="icon-tickets" />
                        </span>
                      )}
                    </div>
                  </div>

                  {/* <PersonWebSlider websites={sessionDetails.webistes} /> */}
                  {Object.entries(allLinks).length > 0 && superModerator && (
                    <div className="session-website-slider">
                      {/* <Slider {...settingsWeb}> */}
                      <Swiper
                        slidesPerView={2}
                        spaceBetween={10}
                        centeredSlides={true}>
                        {allLinks.map((item, index) => (
                          <SwiperSlide key={`website-slider-${index}`}>
                            <PersonWebItem item={item} />
                          </SwiperSlide>
                        ))}
                      </Swiper>
                      {/* </Slider> */}
                    </div>
                  )}

                  <Scrollbars>
                    {/* <StageAudience persons={sessionDetails.persons} /> */}
                    <div className="stage-audience-wrapper conferenceSession">
                      <div className="stage-header">
                        <h3>Stage</h3>
                        {allSesionData.video === 'yes' && showBtns ? (
                          <div className="video-icon">
                            {/* <span className="stage-header-icon">Share</span> */}
                            {superModerator && (
                              <OverlayTrigger
                                placement="top"
                                overlay={<Tooltip>Add Link</Tooltip>}>
                                <span
                                  className="stage-header-icon"
                                  onClick={() => setshowApplink(true)}>
                                  <i className="icon-link" />
                                </span>
                              </OverlayTrigger>
                            )}

                            {!shareScreenstatus ? (
                              <OverlayTrigger
                                placement="top"
                                overlay={<Tooltip>Share Screen</Tooltip>}>
                                <span
                                  className="stage-header-icon"
                                  onClick={() => shareScreen()}>
                                  {/* <i className="icon-share" /> */}
                                  <img src={ScreenShareDisable} alt="" />
                                </span>
                              </OverlayTrigger>
                            ) : (
                              <OverlayTrigger
                                placement="top"
                                overlay={<Tooltip>Stop Share Screen</Tooltip>}>
                                <span
                                  className="stage-header-icon active"
                                  onClick={() => stopScreen()}>
                                  <img src={ScreenShareActive} alt="" />
                                </span>
                              </OverlayTrigger>
                            )}

                            {showBtns ? (
                              isVideoEnable ? (
                                <OverlayTrigger
                                  placement="top"
                                  overlay={<Tooltip>Share Video</Tooltip>}>
                                  <span
                                    className={`stage-header-icon ${isVideoClass}`}
                                    onClick={() => {
                                      requestPermission('video');
                                    }}>
                                    <img src={VideoMuteLine} alt="" />
                                  </span>
                                </OverlayTrigger>
                              ) : (
                                <OverlayTrigger
                                  placement="top"
                                  overlay={<Tooltip>Stop Video</Tooltip>}>
                                  <span
                                    className={`stage-header-icon ${isVideoClass}`}
                                    onClick={() => stopVideo()}>
                                    <img src={VideoCameraActive} alt="" />
                                  </span>
                                </OverlayTrigger>
                              )
                            ) : (
                              <></>
                            )}
                          </div>
                        ) : (
                          <></>
                        )}
                      </div>

                      <div className="stage-list-wrapper">
                        <Row>
                          {
                            // Object.entries(
                            //   Array.from(
                            //     VoxeetSDK.conference.participants.values()
                            //   )
                            // ).length > 0 &&
                            //   Array.from(
                            //     VoxeetSDK.conference.participants.values()
                            //   )
                            moderatorList &&
                              moderatorList.length > 0 &&
                              moderatorList.map((item, i) => {
                                return (
                                  item &&
                                  jdSpecialM.includes(item.id) && (
                                    <Col md={4} key={`stage-person-${item.id}`}>
                                      <div
                                        className="stage-list-item as-link"
                                        onClick={() => openProfile(item.id)}>
                                        <div
                                          id={`speaking-person-${item.id}`}
                                          className={`stage-person-img`}>
                                          <img
                                            src={
                                              item.profile_img
                                                ? item.profile_img
                                                : DEFAULT_IMAGE
                                            }
                                            alt=""
                                          />
                                          {item.id ===
                                            allSesionData.creator_id && (
                                            <span className="owner-icon">
                                              <i className="fas fa-crown" />
                                            </span>
                                          )}
                                          {item && (
                                            <span className="online-icon"></span>
                                          )}

                                          {/* {(typeof(allJdEmojis) === 'object') && (typeof(allJdEmojis.find(objz => objz.id === item.info.externalId)) === 'object') && 
                                            <span
                                                key={`emoji-person-${obj.id}`}
                                                className="emoji-icon"
                                            >
                                                <img
                                                    style={{border: '1px solid golden', borderRadius: '30px'}}
                                                    src={obj.emoji}
                                                    alt=""
                                                />
                                            </span>
                                        } */}

                                          {typeof allJdEmojis === 'object' &&
                                            allJdEmojis.forEach(obj => {
                                              if (obj.id === item.id) {
                                                return (
                                                  <span
                                                    key={`emoji-person-${obj.id}`}
                                                    className="emoji-icon"
                                                    // style={{border: '1px solid red', borderRadius: '30px'}}
                                                    // id={`showemoji-${item.info.externalId}`}
                                                  >
                                                    <img
                                                      style={{
                                                        border:
                                                          '1px solid golden',
                                                        borderRadius: '30px',
                                                      }}
                                                      src={obj.emoji}
                                                      alt=""
                                                    />
                                                  </span>
                                                );
                                              }
                                            })}
                                        </div>
                                        <div className="stage-person-name">
                                          <h3>
                                            {fixName(
                                              item.name.split(' ')[0],
                                              item.name.split(' ')[1],
                                            )}
                                          </h3>
                                        </div>
                                      </div>
                                    </Col>
                                  )
                                );
                              })
                          }
                        </Row>
                      </div>

                      {jdSpecialA.length > 0 ? (
                        <div className="stage-header">
                          <h3>Audience</h3>
                        </div>
                      ) : (
                        <></>
                      )}

                      <div className="stage-list-wrapper">
                        <Row>
                          {
                            // Object.entries(
                            //   Array.from(
                            //     VoxeetSDK.conference.participants.values()
                            //   )
                            // ).length > 0 &&
                            //   Array.from(
                            //     VoxeetSDK.conference.participants.values()
                            //   )
                            audienceList &&
                              audienceList.length > 0 &&
                              audienceList.map((items, i) => {
                                return (
                                  jdSpecialA.includes(items.id) && (
                                    <Col
                                      md={4}
                                      key={`audiance-person-${items.id}`}>
                                      <div className="stage-list-item">
                                        <div
                                          className="stage-person-img as-link"
                                          onClick={() => openProfile(items.id)}>
                                          <img
                                            src={
                                              items.profile_img
                                                ? items.profile_img
                                                : DEFAULT_IMAGE
                                            }
                                            alt=""
                                          />

                                          {/* <span
                                                                                style={{border: '1px solid red', borderRadius: '30px'}}
                                                                                    // id={`showemoji-${items.info.externalId}`}
                                                                                >
                                                                                    <img
                                                                                        src=DEFAULT_IMAGE
                                                                                        alt=""
                                                                                    />
                                                                                </span> */}

                                          {/* {(typeof(allJdEmojis) === 'object') && (typeof(allJdEmojis.find(objz => objz.id === items.info.externalId)) === 'object') && 
                                                                                    <span
                                                                                        key={`emoji-person-${obj.id}`}
                                                                                        className="emoji-icon"
                                                                                    >
                                                                                        <img
                                                                                            style={{border: '1px solid golden', borderRadius: '30px'}}
                                                                                            src={obj.emoji}
                                                                                            alt=""
                                                                                        />
                                                                                    </span>
                                                                                } */}

                                          {typeof allJdEmojis === 'object' &&
                                            allJdEmojis.forEach(obj => {
                                              if (obj.id === items.id) {
                                                return (
                                                  <span
                                                    key={`emoji-person-${obj.id}`}
                                                    className="emoji-icon"
                                                    // style={{border: '1px solid red', borderRadius: '30px'}}
                                                    // id={`showemoji-${item.info.externalId}`}
                                                  >
                                                    <img
                                                      style={{
                                                        border:
                                                          '1px solid golden',
                                                        borderRadius: '30px',
                                                      }}
                                                      src={obj.emoji}
                                                      alt=""
                                                    />
                                                  </span>
                                                );
                                              }
                                            })}
                                        </div>
                                        <div className="stage-person-name">
                                          <h3>
                                            {fixName(
                                              items.name.split(' ')[0],
                                              items.name.split(' ')[1],
                                            )}
                                          </h3>
                                        </div>
                                      </div>
                                    </Col>
                                  )
                                );
                              })
                          }
                        </Row>
                      </div>
                    </div>
                  </Scrollbars>

                  <div className="session-action-btns">
                    <span
                      onClick={() => {
                        setInviteUser(!inviteUser);
                        // const participants = VoxeetSDK.conference.participants;
                      
                      }}
                      className="action-icon">
                      <i className="fas fa-plus" />
                    </span>

                    {/* <span
                      className="action-icon file-icon"
                      onClick={() => {
                        setShowNotes(true);
                       
                      }}>
                      <i className="fas fa-file-lines" />
                    </span> */}

                    <span
                      onClick={() => {
                        showMessagesAll();
                        setUnRead(false);
                        setshowEmojiModal(false);
                        setProfileState(false);
                        setStageRequest(false);
                        setInviteUser(false);
                        setshowApplink(false);
                      }}
                      className="action-icon">
                      <i className="fas fa-paper-plane" />
                      {/* <i className={`fas fa-paper-plane ${
               !showMessages && unRead ?
               'redDot'
              : ''
                  
              }`}
              
              /> */}

                      <i
                        className={`fas  ${
                          !showMessages && unRead ? 'redDot' : ''
                        }`}
                      />
                    </span>

                    {/* {allSesionData.creator_id ==
                      localStorage.getItem('user') ? (
                        <span
                          className="action-icon logout"
                          onClick={() => onLeaveClick()}>
                          <i class="icon-logout"></i>
                        </span>
                      ) : (
                        <span
                          className="action-icon logout"
                          onClick={() => onLeaveClick()}>
                          <i class="icon-logout"></i>
                        </span>
                      )} */}

                    <span
                      className="action-icon"
                      onClick={() => setshowEmojiModal(true)}>
                      <i className="fas fa-face-smile-wink" />
                    </span>

                    {showBtns && (
                      <span
                        className={`action-icon audio-icon ${
                          isAudioEnable ? 'active' : ''
                        }`}
                        onClick={() => {
                          requestPermission('audio');
                        }}>
                        <i
                          className={`fas ${
                            isAudioEnable
                              ? 'icon-mic' // icon-mic
                              : 'icon-mute-2'
                          }`}
                        />
                      </span>
                    )}

                    {superModerator && (
                      <span
                        className="action-icon"
                        onClick={() => showRequests()}>
                        <i className="fas fa-hand" />
                      </span>
                    )}

                    {!iamModerator &&
                      (isHandActive ? (
                        <span className="action-icon audio-icon active">
                          <i className="fas fa-hand" />
                        </span>
                      ) : (
                        <span
                          className="action-icon"
                          onClick={() => addRequest()}>
                          <i className="fas fa-hand" />
                        </span>
                      ))}
                  </div>
                </div>

                {showEmojiModal && (
                  <EmojiModal
                    emojisDataArray={emojisDataArray}
                    onClose={() => setshowEmojiModal(false)}
                    sendEmoji={icon => sendEmoji(icon)}
                  />
                )}

                {showNotes && (
                  <CustomModal
                    onClose={() => setShowNotes(false)}
                    position="bottom">
                    <div className="session-notes-form">
                      <form onSubmit={handleSubmit}>
                        <label className="form-label">Add your notes</label>
                        <textarea
                          autoFocus
                          placeholder="Write your notes"
                          rows={5}
                          value={wittenNotes}
                          onChange={handleChange}
                        />

                        <Button
                          className="mt-3 btn-dark"
                          onClick={handleSubmit}>
                          Save
                        </Button>
                      </form>
                    </div>
                  </CustomModal>
                )}

                {ProfileState && selectedParticipitant.length > 0 && (
                  <div className="session-details-profile-block">
                    <ProfileComponent
                      userId={selectedParticipitant[0].info.externalId}
                      show={ProfileState}
                      closeModal={() => closeProfile()}
                      onClose={() => closeProfile()}
                      userData={selectedParticipitant[0]}
                      moderator={iamModerator}
                      // setParticipantType={setParticipantType}
                      VoxeetSDK={VoxeetSDK}
                      conferenceInfo={allSesionData}
                      jdSpecialM={jdSpecialM}
                      jdSpecialA={jdSpecialA}
                      jdSpecialS={jdSpecialS}
                      glryButton={glryButton}
                      setglryButton={setglryButton}
                      withHeadingSpace={true}
                    />
                  </div>
                )}

                {(stageRequest || stageRequestLoader) && (
                  <CustomModal
                    position="bottom"
                    onClose={() => StageRequestClose()}>
                    {stageRequestLoader && <LoaderSpinner />}
                    <div className="w-100">
                      <div className="sidebar-heading-wrapper">
                        <div className="heading">
                          <h2>Stage Requests</h2>
                        </div>
                      </div>

                      <div className="stage-request-wrapper">
                        {/* {!showLoader ? ( */}

                        {Object.entries(allRequest).length > 0 ? (
                          allRequest.map((item, index) => {
                            return (
                              <div
                                key={`all-request-${index}`}
                                className="stage-request-item">
                                <div className="stage-img">
                                  <img
                                    src={
                                      item.profile_img
                                        ? item.profile_img
                                        : DEFAULT_IMAGE
                                    }
                                    alt=""
                                  />
                                </div>
                                <div className="stage-name">
                                  {fixName(item.first_name, item.last_name)}
                                </div>
                                <div className="stage-likes-icons">
                                  <span
                                    className="as-link"
                                    onClick={() => {
                                      acceptStatus(item.user_id);
                                      // setShowLoader(true);
                                    }}>
                                    <i className="icon-like-icon" />
                                  </span>
                                  <span
                                    className="as-link"
                                    onClick={() => {
                                      rejectStatus(item.user_id);
                                      // setShowLoader(true);
                                    }}>
                                    <i className="icon-unlike" />
                                  </span>
                                </div>
                              </div>
                            );
                          })
                        ) : (
                          <p className="text-center">No Record Found</p>
                        )}
                        {/* ) : (
                        <ThreeDots
                            color="#C48E1D"
                            height={80}
                            width={80}
                            wrapperStyle={{ justifyContent: "center" }}
                        />
                    )} */}
                      </div>
                    </div>
                  </CustomModal>
                )}

                {inviteUser && (
                  <SlidingPanel
                    // position="bottom"
                    hidePanel={() => CloseInviteModal()}>
                    <div className="invite-page-wrapper">
                      
 <div className="refferal-list-wrapper">
                  {inviteLoader && (<div className="invite_loader_hieght">
                  <LoaderSpinner />
                  </div>
                 )}
                      <Scrollbars>
                        <Invite
                          type={'sessionDetail'}
                          moderator={moderator}
                          setModerator={setModerator}
                          selectedModData={selectedModData}
                          setSelectedModData={setSelectedModData}
                          audience={audience}
                          setAudience={setAudience}
                          selectedAudData={selectedAudData}
                          setSelectedAudData={setSelectedAudData}
                          contact={contact}
                          setContact={setContact}
                          originalDataOfMod={originalDataOfMod}
                          setOriginalDataOfMod={setOriginalDataOfMod}
                          originalDataOfAud={originalDataOfAud}
                          setOriginalDataOfAud={setOriginalDataOfAud}
                          subTabs={subTabs}
                          setSubTabs={setSubTabs}
                          // alreadyAddedMod={alreadyAddedMod}
                          handleCircle={handleCircle}
                          onClick={() => {
                            setShowComponent('inviteUser')
                            setTimeout(() => {
                              if(contact.length ===0 && inviteQry !==''){
                                setInviteFollowers(followersList)
                                      setInviteQry('')
                              } 
                            }, 1000);
                           
                          }}
                          onClose={() => CloseInviteModal()}

                          showLoader={inviteLoader}
                          setshowLoader={setInviteLoader}
                          followers={inviteFollowers}
                          setFollowers={setInviteFollowers}
                          setFollowersList={setFollowersList}
                          followersList={followersList}
                          setSearchQry={setInviteQry}
                          searchQry={inviteQry}
                          // noRecord={true}
                        />
                      </Scrollbars>
                      </div>
                    </div>
                  </SlidingPanel>
                )}

                {showApplink && (
                  <CustomModal position="bottom" onClose={() => closeApplink()}>
                    <div className="session-notes-form">
                      <Form>
                        <Form.Group className="search-input mb-3">
                          <Form.Label>Link Title</Form.Label>
                          <Form.Control
                            onChange={e => {
                              setTitleOFwebLinks(e.target.value);
                            }}
                            onKeyDown={e => {
                              if (e.key === 'Enter') {
                                e.preventDefault();
                                handleWeblink();
                              }
                            }}
                            value={titleOFwebLinks}
                            type="text"
                            placeholder="Title"
                          />
                        </Form.Group>
                        <Form.Group className="search-input">
                          <Form.Label>URL</Form.Label>
                          <Form.Control
                            onChange={e => {
                              setwebsiteLink(e.target.value);
                            }}
                            onKeyDown={e => {
                              if (e.key === 'Enter') {
                                e.preventDefault();
                                handleWeblink();
                              }
                            }}
                            value={websiteLink}
                            type="url"
                            placeholder="Add Link"
                          />
                        </Form.Group>
                        <Button
                          className="btn-dark mt-3"
                          onClick={handleWeblink}>
                          <i className="fas fa-plus" /> Add
                        </Button>
                      </Form>
                    </div>
                  </CustomModal>
                )}
                {chatLoader && <LoaderSpinner />}
                {showMessages &&
                  chatroom &&
                  Object.entries(chatroom).length > 0 && (
                    <div className="session-right-panel">
                      {/* <div className="content-sidebar">
                      <div className="sidebar-heading-wrapper">
                        <div className="heading">
                          <h2>Messages</h2>
                        </div>
                        <span
                          className="close as-link"
                          onClick={() => setShowMessages(false)}
                        >
                          <i className="fas fa-times" />
                        </span>
                      </div>

                      <Chat
                        chatroom={chatroom.chat_room_name}
                        chat_id={chatroom.id}
                        chat_room_type={chatroom.chat_room_type}
                        allSesionData={allSesionData}
                        allUsers={jdAllUsers}
                      />
                    </div> */}
                      {/* <CommonChatPage
                      type={'session-group'}
                      item={[]}
                      oppoDetails={allSesionData}
                      chatDetails={allSesionData}
                      allChatUsers={collectingUsers(jdAllUsers)}
                      chatRoomName={chatroom.chat_room_name}
                      chatRoomId={chatroom.id}
                      onClose={() => {
                        setShowMessages(false);
                      }}
                      // handleCommonChat={(data, type)=>{handleCommonChat(data, type)}}
                    /> */}

                      <SmartChatPage
                        // allmsg={true}
                        type={'session-group'}
                        item={[]}
                        chatroomInfo={chatroomInfo(chatroom)}
                        oppoDetails={allSesionData}
                        chatDetails={allSesionData}
                        allChatUsers={collectingUsers(jdAllUsers)}
                        chatRoomName={chatroom.chat_room_name}
                        chatRoomId={chatroom.id}
                        marginTop={true}
                        onClose={() => {
                          setUnRead(false);
                          setShowMessages(false);
                        }}
                        withHeadingSpace={true}
                        handleCommonChat={(data, type) => {
                          // handleCommonChat(data, type);
                        }}
                      />
                    </div>
                  )}

                {showCreateEvent && (
                  <CreateSession
                    onClose={() => {
                      setShowCreateEvent(false);
                    }}
                    createSesion={allSesionData}
                    showEvent={true}
                    // createEvent={showCreateEvent}
                    eventType={true}
                    sessionData={sessionData}
                    // Close={()=>props.onClose()}
                  />
                )}

                {isUserLeftSession && (
                  <CustomModal onClose={() => setUserLeftSession(false)}>
                    <div className="leave-session-content">
                      <h3>Are you sure you want to leave this meetup?</h3>

                      <Button
                        className="btn-dark"
                        onClick={() => userLeftSession()}>
                        Leave Meetup
                      </Button>
                    </div>
                  </CustomModal>
                )}

                {leaveModal && (
                  <CustomModal onClose={() => leavecloseModal()}>
                    <div className="leave-session-content">
                      <h3>Are you sure you want to close this meetup?</h3>

                      <Button
                        className="btn-dark"
                        onClick={() => creatorAcknoledgeLeave()}>
                        End Meetup
                      </Button>
                    </div>
                  </CustomModal>
                )}

                {showModal && (
                  <CustomModal onClose={() => closeModal()}>
                    <div className="leave-session-content">
                      <h3>Request recieved for stage</h3>

                      <Button
                        className="btn-dark"
                        onClick={() => RequestStatusUpdate('Accept')}>
                        Accept
                      </Button>
                      <Button
                        className="btn-dark"
                        onClick={() => {
                          setShowModal(false); /*RequestStatusUpdate("Ignore")*/
                        }}>
                        Ignore
                      </Button>
                    </div>
                  </CustomModal>
                )}

                {ShowSSModal && (
                  <CustomModal onClose={() => setShowSSModal(false)}>
                    <div className="leave-session-content">
                      <h3>Your video is stopped.</h3>
                      <p>Are you sure you want to share screen?</p>

                      <Button
                        className="btn-dark"
                        onClick={() => handleShareScreen()}>
                        Start sharing
                      </Button>
                    </div>
                  </CustomModal>
                )}
                {showComponent === 'WorkSpace' && (
                  <CreateWorkSpace
                    onClose={() => {
                      setShowComponent('');
                    }}
                  />
                )}

                {showComponent === 'Builders' && (
                  <CircleListBuilders
                    onClose={() => {
                      setShowComponent('');
                    }}
                    // setInviteCircle={setInviteCircle}
                    hide={true}
                    CircleUserListData={CircleUserListData}
                  />
                )}

                {showComponent === 'inviteUser' && (
                  <SendInvitation
                    setContact={setContact}
                    onClose={() => {
                      setShowComponent('');
                    }}

                    //Temporary State for remove warnings
                    setSampleStateV={setSampleStateV}
                    notInviteUser={notInviteUser}
                  />
                )}
              </>
            }
            secondary={
              <div className="session-video-wrapper">
                <div className="session-video-header">
                  {bgImgsClass && superModerator && iamModerator && (
                    <div className="bg-images-list-wrapper">
                      <div className="bg-images-list">
                        {sessionBgImgs.length &&
                          sessionBgImgs.map((item, index) => {
                            const activeClass =
                              item.image === selectedSessionBg ? 'active' : '';
                            return (
                              <div
                                key={`images-list-item-${index}`}
                                className={`images-list-item as-link ${activeClass}`}
                                onClick={() => changeBackground(item)}
                                style={{
                                  backgroundImage: `url(${item.image})`,
                                }}
                              />
                            );
                          })}
                      </div>
                      <span
                        onClick={() => changeBackground('')}
                        className={`default-list-item as-link ${
                          defaultCheckClass === 'fas' ? 'active' : ''
                        }`}>
                        <i className={`${defaultCheckClass} fa-circle-check`} />
                        Default
                      </span>
                    </div>
                  )}

                  <div className="grid-icons">
                    {superModerator && iamModerator && (
                      <span
                        className={`grid-icon ${bgImgsClass}`}
                        onClick={() => toggleBgImgs(!showBgImgs)}>
                        <i className="fas fa-image" />
                      </span>
                    )}
                    <span
                      className={`grid-icon ${gridTypeClass}`}
                      onClick={() => setViewVideos(!gridType)}>
                      <i className="fas fa-border-all" />
                    </span>
                    <span
                      className={`grid-icon ${listTypeClass}`}
                      onClick={() => setViewVideos(!gridType)}>
                      <i className="fas fa-list" />
                    </span>

                    {totalVideo !== 0 && (
                      <span
                        className={`grid-icon expand-icon ${expandActive}`}
                        onClick={() => {
                          toggleHiddenPanel(!showHiddenPanel);

                          //reOrganizeGrid();
                        }}>
                        <i
                          className={`fas ${
                            !showHiddenPanel
                              ? 'fa-down-left-and-up-right-to-center'
                              : 'fa-up-right-and-down-left-from-center'
                          } `}
                        />
                      </span>
                    )}
                  </div>
                </div>

                {/* <div className="session-videos">
                      {gridType && <GridviewContent persons={persons} />}
                      {!gridType && <ListviewContent persons={persons} />}
                  </div> */}

                <div className="session-videos" id="ListviewContent">
                  {/* <Scrollbars> */}
                  {/* {userTypeLoader ? (
                    <LoaderSpinner />
                  ) : ( */}
                  <ListviewContent
                    persons={persons}
                    windowWidth={props.windowWidth}
                  />
                  {/* )} */}
                  {/* </Scrollbars> */}
                </div>

                <div
                  className="session-videos custom-scrollbar"
                  id="GridviewContent">
                  {/* {userTypeLoader ? (
                    <LoaderSpinner />
                  ) : ( */}
                  <GridviewContent
                    persons={persons}
                    videoStreamList={videoStreamList}
                    videoCss={videoCss}
                  />
                  {/* )} */}
                </div>
              </div>
            }
            secondaryBgCss={activeBgCss}
          />
        </div>
      </div>
    </>
  );
};

const mapStateProps = state => ({
  menuState: state.menuState,
  windowWidth: state.windowWidth,
});

export default connect(mapStateProps, {setMenuState})(SessionDetails);
