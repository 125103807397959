import React, {useState, useEffect} from 'react';
import {Button, Form} from 'react-bootstrap';
import CustomModal from '../CustomModal';
import {capitalizeFirst} from '../../Utils/commonUtils';

const CustomerSearchFilter = ({
  show,
  onClose,
  filter: initialFilter,
  setFilter,
  filterList,
  onCloseHitApi,
  setshowLoader,
}) => {
  const [filter, setFilterState] = useState(initialFilter);

  useEffect(() => {
    setFilterState(initialFilter);
  }, [initialFilter]);

  const closeModal = () => {
    onClose();
  };

  const handleCheckAll = () => {
    if (!isAllChecked) {
      const allIds = filterList.map(role => role.id);
      setFilterState([...filter, ...allIds]);
    } else {
      setFilterState([]);
    }
  };

  const handleCheckboxChange = id => {
    if (!filter.includes(id)) {
      // Add the id to filter if it's not already included
      setFilterState([...filter, id]);
    } else {
      // Remove the id from filter if it's already included
      setFilterState(prevFilter => prevFilter.filter(item => item !== id));
    }
  };

  const handleSubmit = () => {
    setshowLoader(true)
    setFilter(filter);
    onCloseHitApi(filter);
  };


  const isAllChecked = filterList.every(role => filter.includes(role.id));

  return (
    <CustomModal onClose={closeModal} show={show && true}>
      <div className="filter-wrapper customer-search-filter-holder">
        <div className="filter-headings text-center">
          <h2>Customer Types</h2>
        </div>
        <div className="filter-category-list">
          <ul>
            <li className={isAllChecked ? 'active' : ''}>
              <Form.Label>
                All
                <input
                  type="checkbox"
                  onChange={handleCheckAll}
                  checked={isAllChecked}
                />
                <span />
              </Form.Label>
            </li>
            {filterList.map(role => (
              <li
                key={role.id}
                className={filter.includes(role.id) ? 'active' : ''}>
                <Form.Label>
                  {capitalizeFirst(role.role_alias)}
                  <input
                    type="checkbox"
                    onChange={() => handleCheckboxChange(role.id)}
                    checked={filter.includes(role.id)}
                  />
                  <span />
                </Form.Label>
              </li>
            ))}
          </ul>
          <div className="text-center bg_transparent">
            <Button className="btn-dark" type="submit" onClick={handleSubmit}>
              Filter Customers
            </Button>
          </div>
        </div>
      </div>
    </CustomModal>
  );
};

export default CustomerSearchFilter;
