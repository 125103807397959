import React, {useState, useEffect, useRef} from 'react';
import Sidebar from '../../Pages/Sidebar';
import {selected_lang} from '../../Utils/env';
import Pannels from '../../Components/Pannels';

import {arrow_up, rewardStar, taskCheckMark} from '../../Constants/Images';
import {labels} from '../../Constants/Translations';
import {Button, Form} from 'react-bootstrap';

import Slider from 'react-slick';
import CreateWorkSpace from '../../Components/WorkSpace/CreateWorkSpace';
import Scrollbars from 'react-custom-scrollbars';
import {CustomModal, CustomSlidingButtons} from '../../Components';

import {getAllActions} from '../../Store/Actions/SessionAction';

import {useDispatch, useSelector} from 'react-redux';
import {
  capitalizeFirst,
  checkPermissions,
  fixName,
  updateTaskStatusToCompleted,
} from '../../Utils/commonUtils';
import CreateAction from '../../Components/Snapshot/CreateAction';
import ActionDetail from '../../Components/Actions/ActionDetail';
import commonApi from '../../Services/CommonService';
import {errorMessage, successMessage} from '../../Utils/ToastMessages';
import {TasksNoRecordFound} from '../../Utils/NoRecordConstant';
import {DragDropContext, Droppable, Draggable} from 'react-beautiful-dnd';
import NorecordFound from '../../Components/NoRecord/NorecordFound';
import {getRelativeDate} from '../../Utils/commonUtils';
import LoaderSpinner from '../../Components/Modals/LoaderSpinner';
import {updateBoardListWithTasks} from '../../Utils/commonUtils';
import CheckMarkSvg from '../../Svg/CheckMarkSvg';
import ProfileComponent from '../../Components/ProfilePage/ProfileComponent';
import CustomDropdown from '../../Components/CustomDropdown';
import CreateBoard from '../../Components/Actions/CreateBoard';
const Task = props => {
  const [isFloated, toggleFloatedPanel] = useState(false);
  const [showHiddenPanel, toggleHiddenPanel] = useState(false);
  const [showComponent, setShowComponent] = useState('');
  const [tabType, setTabType] = useState('list');
  const [threeDots, setThreeDots] = useState('');
  const [workspaceData, setWorkspaceData] = useState([]);
  const [actions, setActions] = useState([]);
  const [analycticsSlider, setAnalycticsSlider] = useState(false);

  const [actionsBoardList, setActionsBoardList] = useState([]);
  const [actionsBoardListId, setActionsBoardListId] = useState('');

  const dispatch = useDispatch();
  const menuPermesions = useSelector(state => state.menuPermesionList);

  // const [actionType, setActionType] = useState('');
  const [searchQry, setSearchQry] = useState('');
  const [searchFilter, setSearchFilterModal] = useState(false);
  const [boardFilter, setBoardFilterModal] = useState(false);
  const [checkFilterOption, setCheckFilterOption] = useState([1]);
  const [filterOptionValue, setFilterOptionValue] = useState(['All']);
  const [actionType, setActionType] = useState([1]);
  const [actionTypeValue, setActionTypeValue] = useState(['All']);
  const [s3howModel, setshowModel] = useState('');
  const [dataOfAction, setDataOfAction] = useState('');
  const [boardList, setBoardList] = useState([]);
  const [boardId, setBoardId] = useState('');
  const [userId, setUserId] = useState('');
  const ActionCategories = [
    {id: 1, title: 'All'},
    {id: 2, title: 'Me'},
    {id: 3, title: 'Watching'},
  ];

  const filterOption = [
    {id: 1, title: 'All'},
    {id: 2, title: 'Pending'},
    {id: 3, title: 'Completed'},
    {id: 4, title: 'Expired'},
  ];
  const [showLoader, setShowLoader] = useState(true);
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const isFetching = useRef(false);
  const hasMoreData = useRef(false);

  const handleSearchQry = q => {
    getActions(q);
  };

  const getActions = pageN => {
    if (
      (hasMoreData.current && pageN !== 1) ||
      (isFetching.current && pageN !== 1)
    ) {
      return;
    } else {
      if (pageN > 1) {
        setIsLoading(true);
      }
    }

    isFetching.current = !isFetching.current;

    const Action = actionTypeValue.map(element => {
      let lowerCaseElement = element.toLowerCase();
      if (lowerCaseElement === 'expired') {
        lowerCaseElement = lowerCaseElement.slice(0, -1); // Remove the last character 'd'
      }
      return lowerCaseElement;
    });

    const options = filterOptionValue.map(element => {
      return element.toLowerCase();
    });

    var Payload = {
      board_id: actionsBoardListId,
      user_id: localStorage.getItem('id'),
      type: props.tabType
        ? ['other']
        : options.includes('watching')
        ? ['other']
        : options,
      search_query: searchQry,
      filter: Action === 'expired' ? ['expire'] : Action,
      page: pageN,
      data_type: '',
    };
    localStorage.setItem('action_paylaod', JSON.stringify(Payload));

    commonApi
      .get_actions(Payload)
      .then(res => {
        if (res.status === 200) {
          if (pageN > 1) {
            if (res.all_actions.length > 0) {
              setPage(pageN);
              setTimeout(() => {
                setActions([...actions, ...res.all_actions]);
              }, 1000);
            } else {
              hasMoreData.current = !hasMoreData.current;
            }
          } else {
            setPage(1);
            setActions(res.all_actions);
          }
          setTimeout(() => {
            setShowLoader(false);
          }, 500);

          // if(res?.all_actions.length > 0){
          //   // setActions()
          // }
        }
        setTimeout(() => {
          isFetching.current = !isFetching.current;

          setIsLoading(false);
        }, 1000);
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });

    // dispatch(getAllActions(Payload));
  };

  const handleScroll = values => {
    const {clientHeight, scrollHeight, scrollTop} = values.target;
    // // Check if the user has reached the end of the content
    if (clientHeight + scrollTop >= scrollHeight) {
      // Increment the page number and call the API

      getActions(page + 1);
    }
  };

  const handleBoardScroll = values => {
    if (boardList.every(action => action?.actions.length === 0)) {
      return;
    }
    const {clientHeight, scrollHeight, scrollTop} = values.target;
    // // Check if the user has reached the end of the content
    if (clientHeight + scrollTop >= scrollHeight) {
      // Increment the page number and call the API
      getBoardTasks(page + 1);
    }
  };

  const handleClickList = (id, value) => {
    setCheckFilterOption([id]);
    setFilterOptionValue([value]);
  };

  // useEffect(() => {
  //   getActions(1);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [props.hitApi, actionTypeValue, props.tabType]);

  useEffect(() => {
    setSearchQry('');
  }, [actionTypeValue, filterOptionValue]);

  useEffect(() => {
    if (tabType === 'list') {
      hasMoreData.current = false;
      isFetching.current = false;
      getActions(1);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    searchQry,
    props.hitApi,
    actionTypeValue,
    props.tabType,
    actionsBoardListId,
  ]);

  useEffect(() => {
    if (tabType === 'board') {
      hasMoreData.current = false;
      isFetching.current = false;
      getBoardTasks(1);
    }
  }, [searchQry, actionTypeValue, tabType, actionsBoardListId]);

  useEffect(() => {
    setShowComponent('');
  }, [tabType]);

  const handleReset = () => {
    setShowLoader(true);
    setThreeDots(false);
    setSearchQry('');
    setFilterOptionValue(['All']);
    setActionTypeValue(['All']);
    setCheckFilterOption([1]);
    setActionType([1]);
  };

  const handleType = e => {
    const {id, value} = e.target;

    setActionType(id);
    setActionTypeValue([value]);
  };

  const assigneDetail = item => {
    const userId = localStorage.getItem('id');
    const check =
      item.watchers_detail &&
      item.watchers_detail.length > 0 &&
      item.watchers_detail.some(obj => obj.id === userId);

    return item.created_by === userId && item.assigne !== '0' ? (
      <p className="p-reg m-0 actionsCategories single-line-text">
        {/* From you to{' '} */}
        Assignee:{' '}
        {fixName(item.assigne_detail.first_name, item.assigne_detail.last_name)}
      </p>
    ) : item.assigne === userId || check ? (
      <p className="p-reg m-0 actionsCategories single-line-text">
        {/* For you from{' '} */}
        Assignee:{' '}
        {fixName(item.creator_detail.first_name, item.creator_detail.last_name)}
      </p>
    ) : (
      <></>
    );
  };

  const TaskPermessions =
    menuPermesions && checkPermissions(menuPermesions, 'Tasks');

  // const actions = useSelector(state => state.AllActionsList);

  const TaskList = [
    {
      id: '342',
      title: 'New Task',
      priority: 'medium',
      due_date: '2024-01-19 21:09:00',
      due_time: '21:09:00',
      description: 'Anything',
      association_id: '2515',
      association_type: 'profile',
      assigne: '1737',
      watchers: '',
      workspace_ids: '172',
      personal: 'yes',
      status: 'pending',
      images: '',
      created_by: '2515',
      created_at: '2024-01-01 05:10:12',
      updated_by: '0',
      updated_at: '2024-01-01 05:10:12',
      creator_detail: {
        id: '2515',
        first_name: 'Hello',
        last_name: 'World',
      },
      assigne_detail: {
        id: '1737',
        first_name: 'Free',
        last_name: 'Tickets',
      },
      watchers_detail: [],
      association_name: 'helloworld879321',
    },
  ];

  const taskSlider = {
    // className: "center",
    // centerMode: true,
    dots: false,
    arrows: false,
    infinite: false,
    // centerPadding : "60px",
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,

    responsive: [
      {
        breakpoint: 768, // This is the breakpoint for mobile devices (e.g., tablets)
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480, // This is the breakpoint for smaller mobile devices
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
    // adaptiveHeight: true,
  };

  const taskSliderData = [
    {
      taskStatus: 'Backlog',
    },

    {
      taskStatus: 'Pending',
    },
    {
      taskStatus: 'Awaiting QA',
    },
    {
      taskStatus: 'Complete',
    },
  ];
  useEffect(() => {
    setTimeout(() => {
      const {pathname} = window.location;
      const urlPart = pathname.split('/').filter(Boolean);

      if (
        Object.keys(workspaceData).length > 0 &&
        urlPart.length === 1 &&
        showComponent !== ''
      ) {
        setShowComponent('');
      }
    }, 1000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workspaceData]);

  useEffect(() => {
    if (showComponent !== '') {
      toggleHiddenPanel(true);
    } else {
      toggleHiddenPanel(false);
    }
  }, [showComponent]);
  useEffect(() => {
    get_boards();
  }, []);

  const get_boards = () => {
    // var payLoad = {
    //   search_query: qry,
    //   user_id: localStorage.getItem('user'),
    // };
    commonApi
      .get_boards()
      .then(res => {
        if (res.status === 200) {
          if (res.data.length > 0) {
            setActionsBoardList(res.data);
            setActionsBoardListId(res.data[0].id);
          } else {
            setActionsBoardList([]);
            setActionsBoardListId('');
          }
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  const rewardSlider = {
    dots: false,
    arrows: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
  };
  const rewardSliderData = [
    {
      name: 'Completion',
      money: '2.4%',
      time: 'versus last month',
      svgIcon: rewardStar,
    },

    {
      name: 'High Impact',
      money: '85%',
      time: 'versus last month',
      svgIcon: rewardStar,
    },

    {
      name: 'Customer',
      money: '45%',
      time: 'versus last month',
      svgIcon: rewardStar,
    },
  ];

  const dynamicStyle = {
    padding: 0,
  };

  const onDragEnd = result => {
    const {source, destination} = result;

    // Dropped outside the list
    if (!destination) {
      return;
    }

    const sInd = +source.droppableId;
    const dInd = +destination.droppableId;

    const updatePermission = boardList[sInd].update_permission;

    if (
      boardList[sInd].actions[source.index].created_by !==
      localStorage.getItem('id')
    ) {
      errorMessage("You don't have Permission");
      return;
    }

    // If dragging within the same phase
    if (sInd === dInd) {
      const phaseIndex = sInd;
      const items = reorder(
        boardList[phaseIndex].actions,
        source.index,
        destination.index,
      );

      const newState = [...boardList];
      newState[phaseIndex] = {
        ...newState[phaseIndex],
        actions: items,
      };

      setBoardList(newState);
    } else {
      // Moving to a different phase
      const sourcePhaseIndex = sInd;
      const destPhaseIndex = dInd;
      const {result, removed} = move(
        boardList[sourcePhaseIndex].actions,
        boardList[destPhaseIndex].actions,
        source,
        destination,
        boardList[destPhaseIndex].actions,
      );

      const newState = [...boardList];
      newState[sourcePhaseIndex] = {
        ...newState[sourcePhaseIndex],
        actions: result[sourcePhaseIndex],
      };
      newState[destPhaseIndex] = {
        ...newState[destPhaseIndex],
        actions: result[destPhaseIndex],
      };

      updateTaskBoard(removed.id, boardList[destPhaseIndex].id);

      setBoardList(newState);
    }
  };

  const updateTaskBoard = (taskId, boardId) => {
    const payload = {
      task_id: taskId,
      board_id: boardId,
    };

    commonApi
      .change_task_board(payload)
      .then(res => {
        if (res.status === 200) {
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const move = (
    source,
    destination,
    droppableSource,
    droppableDestination,
    movedPhase,
  ) => {
    const sourceClone = Array.from(source);
    const destClone = Array.from(destination);
    const [removed] = sourceClone.splice(droppableSource.index, 1);

    // updateDealPhase(removed.id,movedPhase.id)

    destClone.splice(droppableDestination.index, 0, removed);

    const result = {};
    result[droppableSource.droppableId] = sourceClone;
    result[droppableDestination.droppableId] = destClone;

    return {result, removed};
  };
  const grid = 8;

  const getListStyle = isDraggingOver => ({
    padding: grid,
    width: 349,
  });

  const getBoardTasks = pageN => {
    if (
      (hasMoreData.current && pageN !== 1) ||
      (isFetching.current && pageN !== 1)
    ) {
      return;
    } else {
      if (pageN > 1) {
        setIsLoading(true);
      }
    }

    isFetching.current = !isFetching.current;

    const Action = actionTypeValue.map(element => {
      let lowerCaseElement = element.toLowerCase();
      if (lowerCaseElement === 'expired') {
        lowerCaseElement = lowerCaseElement.slice(0, -1); // Remove the last character 'd'
      }
      return lowerCaseElement;
    });

    const options = filterOptionValue.map(element => {
      return element.toLowerCase();
    });

    var Payload = {
      user_id: localStorage.getItem('id'),
      type: props.tabType
        ? ['other']
        : options.includes('watching')
        ? ['other']
        : options,
      search_query: searchQry,
      filter: Action === 'expired' ? ['expire'] : Action,
      page: pageN,
      board_id: actionsBoardListId,
      data_type: 'board',
    };
    commonApi
      .get_actions(Payload)
      .then(res => {
        if (res.status === 200) {
          if (pageN > 1) {
            if (
              res.all_actions.length > 0 &&
              res.all_actions.some(item => item.actions.length > 0)
            ) {
              setPage(pageN);
              setBoardList(res.all_actions);

              // setTimeout(() => {
              //   const newBoardList = updateBoardListWithTasks(
              //     boardList,
              //     res.all_actions,
              //   );

              //   setBoardList(newBoardList);
              //   // setBoardList([...boardList, ...res.all_actions]);
              // }, 1000);
            } else {
              hasMoreData.current = !hasMoreData.current;
            }
          } else {
            setPage(1);
            setBoardList(res.all_actions);
          }
          setTimeout(() => {
            setShowLoader(false);
          }, 500);
        }
        setTimeout(() => {
          isFetching.current = !isFetching.current;

          setIsLoading(false);
        }, 1000);
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  const changeActionStatus = (boardId, id) => {
    var Payload = {
      action_id: id,
      status: 'completed',
    };

    commonApi
      .change_action_status(Payload)
      .then(res => {
        if (res.status === 200 && !res.success) {
          const updatedBoardList = updateTaskStatusToCompleted(
            boardList,
            boardId,
            id,
          );
          setBoardList(updatedBoardList);

          successMessage(res.message);
        } else if (res.success === false) {
          errorMessage(res.message);
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  const [groupedTasks, setGroupedTasks] = useState({});

  // Group tasks by phase_title when component mounts or data changes
  useEffect(() => {
    const groupByPhase = actions.reduce((acc, task) => {
      // Check if phase already exists in groupedTasks
      if (!acc[task.phase_title]) {
        acc[task.phase_title] = [];
      }
      acc[task.phase_title].push(task);
      return acc;
    }, {});
    setGroupedTasks(groupByPhase);
  }, [actions]);

  return (
    <div className="page-wrapper">
      <Sidebar
        location={props.location}
        showComponent={showComponent}
        setShowComponent={setShowComponent}
        setWorkSpaceItem={setWorkspaceData}
        workspaceData={workspaceData}
      />
      <div className="page-content-wrapper">
        <div className="sessions-list-wrapper slider-screen-main">
          {TaskPermessions &&
            (TaskPermessions === 'allow' ||
              TaskPermessions?.analytics_row === true) && (
              <div className="Reward_main analytics_slider_bg">
                <span
                  className="analytics_slider_heading "
                  style={{color: analycticsSlider ? '' : '#999999'}}
                  onClick={() => {
                    setAnalycticsSlider(!analycticsSlider);
                  }}>
                  Analytics Row
                </span>

                {analycticsSlider && (
                  <div className="reward-top mt-3 mb-2">
                    <Slider {...rewardSlider}>
                      {rewardSliderData.length > 0 &&
                        rewardSliderData.map((item, index) => (
                          <div className="reward-main">
                            <div className="reward-card">
                              <span style={{fontWeight: 700, fontSize: '13px'}}>
                                {item.name}
                              </span>
                              <span
                                style={{
                                  display: 'flex',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                }}>
                                <span style={{fontSize: '17px'}}>
                                  {item.money}
                                </span>
                                {item.name === 'Ratings' && (
                                  <img src={item.svgIcon} alt="" />
                                )}
                                {item.name === 'Conversion' ? (
                                  <i
                                    className="fa-solid fa-arrow-down"
                                    style={{color: '#BD4141'}}></i>
                                ) : (
                                  <i
                                    class="fa-solid fa-arrow-up"
                                    style={{color: '#52bd41'}}></i>
                                )}
                              </span>
                              <div class="message-date-info">
                                <span
                                  style={{color: '#333'}}
                                  class="message-date">
                                  {item.time}
                                </span>
                              </div>
                            </div>
                          </div>
                        ))}
                    </Slider>
                  </div>
                )}
              </div>
            )}
          <div className="add_border"></div>

          {tabType === 'list' && (
            <Pannels
              isFloated={isFloated}
              showHiddenPanel={showHiddenPanel}
              updateHiddenPanelState={nState => toggleHiddenPanel(nState)}
              customCss={dynamicStyle}
              toggleFloatedPanel={toggleFloatedPanel}
              primary={
                <>
                  <div className="task-page primary_panel_wraper primary_panel_content">
                    <div className="messages-header">
                      <div className="event-tabs tabs-style-2 mb-0">
                        {' '}
                        {/* Merging */}
                        <ul className="LabelTabs">
                          <li className="ml-20 d-flex align-items-center">
                            <Button
                              className={tabType === 'list' ? 'active' : ''}
                              onClick={() => {
                                handleReset();
                                setTabType('list');
                              }}>
                              List
                            </Button>
                          </li>

                          <li className="ml-20 d-flex align-items-center">
                            <Button
                              className={tabType === 'board' ? 'active' : ''}
                              onClick={() => {
                                handleReset();
                                setTabType('board');
                              }}>
                              Board
                            </Button>
                          </li>

                          {/* <li className="ml-20 d-flex align-items-center">
                            <Button
                              className={
                                tabType === 'checklists' ? 'active' : ''
                              }
                              onClick={() => {
                                setTabType('list');
                              }}>
                              {labels.CHECKLISTS[selected_lang]}
                            </Button>
                          </li> */}
                        </ul>
                      </div>

                      <div className="search-tabs-icons">
                      {TaskPermessions &&
                      (TaskPermessions === 'allow' ||
                        TaskPermessions?.create === true) && (
                        <span
                          className={
                            showComponent === 'addAction'
                              ? 'icon active'
                              : 'icon'
                          }>
                          <i
                            className="icon-plus"
                            onClick={() => {
                              if (showComponent === 'addAction') {
                                setShowComponent('');
                              } else {
                                setShowComponent('addAction');
                              }
                            }}
                          />
                        </span>)}

                        <span
                          onClick={() => {
                            setThreeDots(!threeDots);
                          }}
                          className={threeDots ? 'icon active' : 'icon'}>
                          <i className="fas fa-ellipsis-vertical" />
                        </span>
                      </div>
                    </div>
                    {threeDots && (
                      <>
                        <div className="person-search-wrapper  mt-0 messages-users-wrapper pt-2">
                          <div className="search-form d-flex">
                            <Form.Group className="search-input">
                              <Form.Control
                                type="text"
                                placeholder="Search"
                                value={searchQry}
                                onChange={e => {
                                  setSearchQry(e.target.value);
                                }}
                              />
                            </Form.Group>
                            <Button
                              onClick={() => {
                                setSearchFilterModal('filter');
                              }}>
                              <i className="icon-filter-icon" />
                            </Button>
                          </div>
                        </div>
                        <div>
                          <div>
                            <div className="category-btns-wrapper category-btns-wrapper-slider mt-0 mb-4">
                              <CustomSlidingButtons
                                items={filterOption}
                                activeButtons={actionType}
                                onItemClick={e => {
                                  handleType(e);
                                }}
                                showItems={3}
                                value={actionTypeValue}
                              />
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                    {actionsBoardList && actionsBoardList.length > 0 && (
                      <div className="fieldset mt-2">
                        <CustomDropdown
                          // type={'credential'}
                          setDropdownId={setActionsBoardListId}
                          dropdownId={actionsBoardListId}
                          DropDownValues={actionsBoardList}
                          itemName={['title']}
                          className={'mb-3 recruitment_dropdown'}
                          // handleDropdownValue={handleAccomplishmentChange}
                          // disabled={props.id ? true : false}
                        />
                      </div>
                    )}
                    <div
                      className={
                        threeDots
                          ? 'refferal-list-wrapper'
                          : 'refferal-list-wrapper'
                      }>
                      {showLoader && <LoaderSpinner />}
                      <Scrollbars
                        className="custom-refferal-vertical-scrollbar"
                        autoHide
                        onScroll={handleScroll}>
                        <div className="task-list-wrapper">
                          {groupedTasks &&
                          Object.keys(groupedTasks)?.length > 0 ? (
                            Object.keys(groupedTasks).map((phase, index) => (
                              <div key={index}>
                                <h2 style={{marginBottom: '6px'}}>{phase}</h2>
                                {/* Check if tasks exist for this phase */}
                                {groupedTasks[phase]?.length > 0 ? (
                                  groupedTasks[phase].map((item, index) => (
                                    <div
                                      key={item.id || index} // Unique key for each task
                                      className="refferals-list action-listing as-link"
                                      onClick={() => {
                                        setDataOfAction(item);
                                        setShowComponent('actionDetails');
                                      }}>
                                      <div className="task-item">
                                        {/* Task Title */}
                                        <h2 className="truncate_2_lines actions-title mb-2">
                                          {item.title}
                                        </h2>
                                        {/* <p className="truncate_2_lines actions-title mb-2">
                                        {item.phase_title}
                                      </p> */}

                                        {/* Task Priority */}
                                        <div className="d-flex align-items-center session-cam mb-2">
                                          <p className="p-reg m-0 single-line-text">
                                            {capitalizeFirst(item.priority)}
                                          </p>
                                        </div>

                                        {/* Task Status */}
                                        <p className="p-reg m-0 mb-2">
                                          {capitalizeFirst(item.status)}
                                        </p>

                                        {/* Assignee Details */}
                                        <div
                                          className="d-flex align-items-center justify-content-between"
                                          style={{alignItems: 'center'}}>
                                          <div className="d-flex align-items-center">
                                            <p className="p-reg m-0 actionsCategories single-line-text">
                                              {assigneDetail(item)}{' '}
                                              {/* Render Assignee */}
                                            </p>
                                          </div>

                                          <div className="arrow-up">
                                            {/* Optional: Add arrow icon here if necessary */}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  ))
                                ) : (
                                  <div className="no-results-wrapper payment-no-results no-record-notification">
                                    <div className="img-bg-wrapper">
                                      <img
                                        src={require('../../Assets/images/norecord/no-actions.png')}
                                        alt="No tasks"
                                      />
                                    </div>

                                    <div className="no-results-content">
                                      <h2>
                                        {`No Tasks ${
                                          actionTypeValue.includes('All')
                                            ? 'Available'
                                            : actionTypeValue
                                        }`}
                                      </h2>
                                      <p>
                                        {labels.NoAction_Desc[selected_lang]}
                                      </p>
                                      <div className="btn-wrapper mt-3">
                                        <Button
                                          className="btn btn-dark"
                                          onClick={() => {
                                            setShowComponent('addAction');
                                          }}>
                                          Create Task
                                        </Button>
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </div>
                            ))
                          ) : (
                            <div className="no-results-wrapper payment-no-results no-record-notification">
                              <div className="img-bg-wrapper">
                                <img
                                  src={require('../../Assets/images/norecord/no-actions.png')}
                                  alt="No tasks"
                                />
                              </div>

                              <div className="no-results-content">
                                <h2>
                                  {`No Tasks ${
                                    actionTypeValue.includes('All')
                                      ? 'Available'
                                      : actionTypeValue
                                  }`}
                                </h2>
                                <p>{labels.NoAction_Desc[selected_lang]}</p>
                                <div className="btn-wrapper mt-3">
                                  <Button
                                    className="btn btn-dark"
                                    onClick={() => {
                                      setShowComponent('addAction');
                                    }}>
                                    Create Task
                                  </Button>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </Scrollbars>
                    </div>
                  </div>

                  {/* Filter Modal */}
                  {searchFilter && (
                    <CustomModal
                      className="shareables-workspace-modal"
                      size="small"
                      onClose={() => setSearchFilterModal(false)}>
                      <div className="filter-wrapper">
                        <div className="filter-category-list">
                          <ul>
                            {/* <li
                  className={
                    checkFilterOption.includes('all') ||
                    filterOptionValue.includes('all')
                      ? 'active'
                      : ''
                  }>
                  <Form.Label>
                    All
                    <input
                      type="checkbox"
                      id="All"
                      value="All"
                      onChange={() => {
                        handleSelectAll('all');
                      }}
                      checked={
                        checkFilterOption.includes('all') ||
                        filterOptionValue.includes('all')
                      }
                    />
                    <span />
                  </Form.Label>
                </li> */}

                            {ActionCategories.map((item, index) => (
                              <li
                                key={`sessionC-${index}`}
                                className={
                                  checkFilterOption.includes(item.id) ||
                                  filterOptionValue.includes(item.title)
                                    ? 'active'
                                    : ''
                                }>
                                <Form.Label>
                                  {item.title}
                                  <input
                                    type="checkbox"
                                    id={item.id}
                                    value={item.title}
                                    onChange={e => {
                                      handleClickList(item.id, e.target.value);
                                    }}
                                    checked={
                                      checkFilterOption.includes(item.id) ||
                                      filterOptionValue.includes(item.title)
                                    }
                                  />
                                  <span />
                                </Form.Label>
                              </li>
                            ))}
                          </ul>

                          {/* <Button
                // type="submit"
                className="btn-dark"
                onClick={() => {
                  getActions();
                  setSearchFilterModal(false);
                }}>
                Filter
              </Button> */}
                          <div className="text-center">
                            <Button
                              type="submit"
                              className="btn-dark "
                              onClick={() => {
                                getActions(1);
                                setSearchFilterModal(false);
                              }}>
                              Filter
                            </Button>
                          </div>
                        </div>
                      </div>
                    </CustomModal>
                  )}
                </>
              }
              secondary={
                <>
                  {showComponent === 'addAction' && (
                    <CreateAction
                      onClose={() => {
                        setShowComponent('');
                      }}
                      onCloseHitApi={() => {
                        getActions(1);
                      }}
                    />
                  )}

                  {showComponent === 'actionDetails' && (
                    <ActionDetail
                      dataOfAction={dataOfAction}
                      setDataOfAction={setDataOfAction}
                      actions={actions}
                      onClose={() => {
                        setShowComponent('');
                      }}
                      onCloseHitApi={() => {
                        getActions(1);
                      }}
                    />
                  )}
                </>
              }
            />
          )}

          {tabType === 'board' && (
            <div style={{display: 'flex', flex: 1}}>
              <div
                className="task-page primary_panel_content"
                style={{padding: '20px 20px 15px'}}>
                <div className="messages-header" style={{maxWidth: '379px'}}>
                  <div className="event-tabs tabs-style-2 mb-0">
                    {' '}
                    {/* Merging */}
                    <ul className="LabelTabs">
                      <li className="ml-20 d-flex align-items-center">
                        <Button
                          className={tabType === 'list' ? 'active' : ''}
                          onClick={() => {
                            handleReset();
                            setTabType('list');
                          }}>
                          {labels.LIST[selected_lang]}
                        </Button>
                      </li>

                      <li className="ml-20 d-flex align-items-center">
                        <Button
                          className={tabType === 'board' ? 'active' : ''}
                          onClick={() => {
                            handleReset();
                            setTabType('board');
                          }}>
                          {labels.BOARD[selected_lang]}
                        </Button>
                      </li>

                      {/* <li className="ml-20 d-flex align-items-center">
                        <Button
                          className={tabType === 'checklists' ? 'active' : ''}
                          onClick={() => {
                            setTabType('list');
                          }}>
                          {labels.CHECKLISTS[selected_lang]}
                        </Button>
                      </li> */}
                    </ul>
                  </div>

                  {/* <span className="add-icon">
                          <i
                            className="icon-plus"
                            onClick={() => setSecondComponent("group-component")}
                          />
                        </span>
                        <span className="add-icon">
                          <i
                            className="icon-filter-icon"
                            onClick={() => setSearchFilterModal(true)}
                          />
                        </span> */}

                  <div className="search-tabs-icons">
                    {TaskPermessions &&
                      (TaskPermessions === 'allow' ||
                        TaskPermessions?.create === true) && (
                        <span
                          className={
                            showComponent === 'addAction_inboard'
                              ? 'icon active'
                              : 'icon'
                          }>
                          <i
                            className="icon-plus"
                            onClick={() => {
                              if (showComponent === 'addAction_inboard') {
                                setShowComponent('');
                              } else {
                                setShowComponent('addAction_inboard');
                              }
                            }}
                          />
                        </span>
                      )}

                    <span
                      onClick={() => {
                        setThreeDots(!threeDots);
                      }}
                      className={threeDots ? 'icon active' : 'icon'}>
                      <i className="fas fa-ellipsis-vertical" />
                    </span>
                  </div>
                </div>
                {threeDots && (
                  <div style={{maxWidth: '379px'}}>
                    <div className="person-search-wrapper  mt-0 messages-users-wrapper pt-2">
                      <div className="search-form d-flex">
                        <Form.Group className="search-input">
                          <Form.Control
                            type="text"
                            placeholder="Search"
                            value={searchQry}
                            onChange={e => {
                              setSearchQry(e.target.value);
                            }}
                          />
                        </Form.Group>
                        <Button
                          onClick={() => {
                            setBoardFilterModal(true);
                          }}>
                          <i className="icon-filter-icon" />
                        </Button>
                      </div>
                    </div>
                    <div>
                      <div>
                        <div className="category-btns-wrapper category-btns-wrapper-slider mt-0 mb-4">
                          <CustomSlidingButtons
                            items={filterOption}
                            activeButtons={actionType}
                            onItemClick={e => {
                              handleType(e);
                            }}
                            showItems={3}
                            value={actionTypeValue}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {actionsBoardList && actionsBoardList.length > 0 && (
                  <div
                    className="fieldset mt-2"
                    style={{
                      width: '30%',
                    }}>
                    <CustomDropdown
                      // type={'credential'}
                      setDropdownId={setActionsBoardListId}
                      dropdownId={actionsBoardListId}
                      DropDownValues={actionsBoardList}
                      itemName={['title']}
                      className={'mb-3 recruitment_dropdown'}
                    // handleDropdownValue={handleAccomplishmentChange}
                    // disabled={props.id ? true : false}
                    />
                  </div>
                )}

                <div className="refferal-list-wrapper">
                  {showLoader && <LoaderSpinner />}
                  <Scrollbars
                    autoHide
                    // Hide the vertical scrollbar
                    renderTrackVertical={props => (
                      <div {...props} style={{display: 'none'}} />
                    )}
                    onScroll={handleBoardScroll}>
                    <div className="board-list-wrapper d-flex mt-4">
                      {/* <Slider {...taskSlider}>
                        {taskSliderData.length > 0 &&
                          taskSliderData.map((item, index) => (
                            <>
                              <div className="board-header">
                                <h1>{item.taskStatus}</h1>

                                <div className="add-task-btn as-link">
                                  Add Task
                                </div>
                              </div>
                              <div
                                className="refferals-list action-listing as-link"
                                onClick={() => {}}>
                                <div className="task-item">
                                  <div className="task-img"></div>
                                  <div className="red-bar"></div>

                                  <div className="task-board-list-card">
                                    <div className="task-profile-img">
                                      <img
                                        src="https://s3.amazonaws.com/build-dev/event/slider/1692340229593.jpg"
                                        alt=""
                                      />
                                    </div>

                                    <div className="task-content">
                                      <h2 className="truncate_2_lines actions-title mb-2 mt-3">
                                        Task Title
                                      </h2>
                                      <div className="d-flex align-items-center session-cam mb-2">
                                        <p className="p-reg m-0 single-line-text">
                                          {'[Project Name]'}
                                        </p>
                                      </div>

                                      <div
                                        className="d-flex align-items-center justify-content-between"
                                        style={{alignItems: 'center'}}>
                                        <div className="d-flex align-items-center">
                                          <p className="p-reg m-0 actionsCategories single-line-text">
                                            Tomorrow
                                          </p>
                                        </div>

                                        <img
                                          height={20}
                                          width={20}
                                          src={taskCheckMark}
                                          alt="checkmark"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div
                                className="refferals-list action-listing as-link task-bottom-card"
                                onClick={() => {}}>
                                <div className="task-item">
                                  <div className="task-board-list-card">
                                    <div className="task-profile-img">
                                      <img
                                        src="https://s3.amazonaws.com/build-dev/event/slider/1692340229593.jpg"
                                        alt=""
                                      />
                                    </div>

                                    <div className="task-content">
                                      <h2 className="truncate_2_lines actions-title mb-2 mt-3">
                                        Task Title
                                      </h2>
                                      <div className="d-flex align-items-center session-cam mb-2">
                                        <p className="p-reg m-0 single-line-text">
                                          {'[Project Name]'}
                                        </p>
                                      </div>

                                      <div
                                        className="d-flex align-items-center justify-content-between"
                                        style={{alignItems: 'center'}}>
                                        <div className="d-flex align-items-center">
                                          <p className="p-reg m-0 actionsCategories single-line-text">
                                            Tomorrow
                                          </p>
                                        </div>

                                        <img
                                          height={20}
                                          width={20}
                                          src={taskCheckMark}
                                          alt="checkmark"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </>
                          ))}
                      </Slider> */}

                      <DragDropContext onDragEnd={onDragEnd}>
                        {boardList.length > 0 ? (
                          boardList.map((el, ind) => (
                            <Droppable key={ind} droppableId={`${ind}`}>
                              {(provided, snapshot) => (
                                <div
                                  ref={provided.innerRef}
                                  style={getListStyle(snapshot.isDraggingOver)}
                                  {...provided.droppableProps}>
                                  <div
                                    className={`text-center mb-3
                                   
                                    `}>
                                    <h1>{el.title}</h1>
                                  </div>
                                  {/* 
                                  {Boolean(
                                    pipelinePermissionItem?.create_permission,
                                  ) && ( */}
                                  <div className="d-flex justify-content-center align-items-center pb-3">
                                    <div
                                      className=" add-task-btn as-link"
                                      onClick={() => {
                                        setShowComponent('addAction_inboard');
                                        // setBoardId(el.id);
                                      }}>
                                      Add Task
                                    </div>
                                  </div>
                                  {/* )} */}

                                  {el.actions.length > 0 ? (
                                    el.actions.map((item, index) => (
                                      <Draggable
                                        key={item.id}
                                        draggableId={item.id}
                                        index={index}>
                                        {(provided, snapshot) => (
                                          <div
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            // style={getItemStyle(
                                            //   snapshot.isDragging,
                                            //   provided.draggableProps.style
                                            // )}
                                          >
                                            {/* {index === 0 ? ( */}
                                            <div
                                              className="refferals-list action-listing as-link"
                                              onClick={() => {}}>
                                              <div
                                                className="task-item"
                                                onClick={() => {
                                                  setDataOfAction(item);
                                                  setShowComponent(
                                                    'actionDetails_inboard',
                                                  );
                                                }}>
                                                {item.images &&
                                                item.images !== '' ? (
                                                  <div className="task_board_img">
                                                    <img
                                                      src={
                                                        item.images.split(
                                                          ',',
                                                        )[0]
                                                      }
                                                      alt=""
                                                    />
                                                  </div>
                                                ) : (
                                                  // <div className="task-img"></div>
                                                  <></>
                                                )}

                                                <div
                                                  className={`red-bar ${
                                                    item.images === ''
                                                      ? 'sub_task'
                                                      : ''
                                                  } ${
                                                    item.priority === 'low'
                                                      ? 'green'
                                                      : item.priority ===
                                                        'medium'
                                                      ? 'yellow'
                                                      : ''
                                                  }`}></div>

                                                <div className="task-board-list-card">
                                                  <div className="task-profile-img">
                                                    <img
                                                      src={
                                                        item.assigne_detail &&
                                                        item.assigne_detail
                                                          .profile_img
                                                          ? item.assigne_detail
                                                              .profile_img
                                                          : 'https://s3.amazonaws.com/build-dev/event/slider/1692340229593.jpg'
                                                      }
                                                      alt=""
                                                      onClick={e => {
                                                        e.stopPropagation();
                                                        setUserId(
                                                          item?.assigne_detail &&
                                                            item.assigne_detail
                                                              ?.id,
                                                        );
                                                        setShowComponent(
                                                          'profileComponent',
                                                        );
                                                      }}
                                                    />
                                                  </div>

                                                  <div className="task-content">
                                                    <h2 className="truncate_2_lines actions-title mb-2 mt-3">
                                                      {item.title}
                                                    </h2>
                                                    {/* <div className="d-flex align-items-center session-cam mb-2">
                                                      <p className="p-reg m-0 single-line-text">
                                                        [{el.title}]
                                                      </p>
                                                    </div> */}

                                                    <div
                                                      className="d-flex align-items-center justify-content-between"
                                                      style={{
                                                        alignItems: 'center',
                                                      }}>
                                                      <div className="d-flex align-items-center">
                                                        <p className="p-reg m-0 actionsCategories single-line-text">
                                                          {/* Tomorrow */}
                                                          {item.due_date &&
                                                          item.due_date !==
                                                            '' &&
                                                          item.due_date !==
                                                            '0000-00-00 00:00:00'
                                                            ? 'Due ' +
                                                              getRelativeDate(
                                                                item.due_date,
                                                              )
                                                            : ''}
                                                        </p>
                                                      </div>

                                                      {item.status ===
                                                      'pending' ? (
                                                        <div
                                                          onClick={e => {
                                                            e.stopPropagation();
                                                            changeActionStatus(
                                                              el.id,
                                                              item.id,
                                                            );
                                                          }}>
                                                          <CheckMarkSvg fill="#848174" />
                                                        </div>
                                                      ) : (
                                                        <div className="task_completed">
                                                          <CheckMarkSvg fill="#68b245" />
                                                        </div>
                                                      )}

                                                      {/* <img
                                                         height={20}
                                                         width={20}
                                                         src={taskCheckMark}
                                                         alt="checkmark"
                                                       /> */}
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                            {/* ) : (
                                              <div
                                                className="refferals-list action-listing as-link task-bottom-card "
                                                onClick={() => {
                                                  setDataOfAction(item);
                                                  setShowComponent(
                                                    'actionDetails_inboard',
                                                  );
                                                }}>
                                                <div className="task-item ">
                                                  <div
                                                    className={`red-bar sub_task ${
                                                      item.priority === 'low'
                                                        ? 'green'
                                                        : item.priority ===
                                                          'medium'
                                                        ? 'yellow'
                                                        : ''
                                                    }`}></div>
                                                  <div className="task-board-list-card">
                                                    <div className="task-profile-img">
                                                      <img
                                                        src={
                                                          item.assigne_detail &&
                                                          item.assigne_detail
                                                            .profile_img
                                                            ? item
                                                                .assigne_detail
                                                                .profile_img
                                                            : 'https://s3.amazonaws.com/build-dev/event/slider/1692340229593.jpg'
                                                        }
                                                        onClick={e => {
                                                          e.stopPropagation();
                                                          setUserId(
                                                            item?.assigne_detail &&
                                                              item
                                                                .assigne_detail
                                                                ?.id,
                                                          );
                                                          setShowComponent(
                                                            'profileComponent',
                                                          );
                                                        }}
                                                        alt=""
                                                      />
                                                    </div>

                                                    <div className="task-content">
                                                      <h2 className="truncate_2_lines actions-title mb-2 mt-3">
                                                        {item.title}
                                                      </h2>
                                                      <div className="d-flex align-items-center session-cam mb-2">
                                                        <p className="p-reg m-0 single-line-text">
                                                         
                                                          [{el.title}]
                                                        </p>
                                                      </div>

                                                      <div
                                                        className="d-flex align-items-center justify-content-between"
                                                        style={{
                                                          alignItems: 'center',
                                                        }}>
                                                        <div className="d-flex align-items-center">
                                                          <p className="p-reg m-0 actionsCategories single-line-text">
                                                            {item.due_date &&
                                                            item.due_date !==
                                                              '' &&
                                                            item.due_date !==
                                                              '0000-00-00 00:00:00'
                                                              ? 'Due ' +
                                                                getRelativeDate(
                                                                  item.due_date,
                                                                )
                                                              : ''}
                                                          </p>
                                                        </div>

                                                        {item.status ===
                                                        'pending' ? (
                                                          <div
                                                            onClick={e => {
                                                              e.stopPropagation();
                                                              changeActionStatus(
                                                                el.id,
                                                                item.id,
                                                              );
                                                            }}>
                                                            <CheckMarkSvg fill="#848174" />
                                                          </div>
                                                        ) : (
                                                          <div className="task_completed">
                                                            <CheckMarkSvg fill="#68b245" />
                                                          </div>
                                                        )}
                                                        
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            )} */}
                                          </div>
                                        )}
                                      </Draggable>
                                    ))
                                  ) : (
                                    <NorecordFound
                                      className="draggableWidth"
                                      noRecordData={TasksNoRecordFound}
                                      buttonPermesion={
                                        true
                                        // Boolean(
                                        //   pipelinePermissionItem?.create_permission,
                                        // )
                                        //   ? true
                                        //   : false
                                      }
                                      ButtonAction={() => {
                                        setShowComponent('addAction_inboard');
                                        setBoardId(el.board_id);
                                        // setShowComponent('createDeal');
                                        // setPhaseId(el.id);
                                      }}
                                    />
                                  )}
                                  {provided.placeholder}
                                </div>
                              )}
                            </Droppable>
                          ))
                        ) : (
                          <NorecordFound
                            noRecordData={TasksNoRecordFound}
                            buttonPermesion={
                              true
                              // PipelinePermission ? true : false
                            }
                            ButtonAction={() => {
                              if (showComponent === 'addAction_inboard') {
                                setShowComponent('');
                              } else {
                                setShowComponent('addAction_inboard');
                              }

                              // setSecondaryComponent('createDeal');
                              // setPhaseId(phaseItem.id);
                            }}
                            firstDivStyle={{width: '371px'}}
                          />
                        )}
                      </DragDropContext>
                    </div>
                  </Scrollbars>
                </div>
              </div>           
            </div>
          )}

          {showComponent === 'open_board' && (
            <CreateBoard
              onClose={() => {
                setShowComponent('');
              }}
            />
          )}

          {showComponent === 'WorkSpace' && (
            <CreateWorkSpace
              onClose={() => {
                setShowComponent('');
              }}
            />
          )}
          {showComponent === 'addAction_inboard' && (
            <CreateAction
              onClose={() => {
                setShowComponent('');
                setBoardId('');
              }}
              boardId={boardId}
              type={'board'}
              onCloseHitApi={() => {
                getBoardTasks(1);
              }}
            />
          )}

          {showComponent === 'actionDetails_inboard' && (
            <ActionDetail
              dataOfAction={dataOfAction}
              setDataOfAction={setDataOfAction}
              actions={actions}
              onClose={() => {
                setShowComponent('');
              }}
              onCloseHitApi={() => {
                getBoardTasks(1);
              }}
            />
          )}

          {userId && showComponent === 'profileComponent' && (
            <ProfileComponent
              userId={userId}
              show={false}
              closeModal={() => {
                setShowComponent('');
              }}
              onClose={() => {
                setShowComponent('');
              }}
              userData={[]}
              moderator={false}
              // setParticipantType={setParticipantType}
              VoxeetSDK={[]}
              conferenceInfo={[]}
              jdSpecialM={[]}
              jdSpecialA={[]}
            />
          )}

          {boardFilter && (
            <CustomModal
              className="shareables-workspace-modal"
              size="small"
              onClose={() => setBoardFilterModal(false)}>
              <div className="filter-wrapper">
                <div className="filter-category-list">
                  <ul>
                    {/* <li
                  className={
                    checkFilterOption.includes('all') ||
                    filterOptionValue.includes('all')
                      ? 'active'
                      : ''
                  }>
                  <Form.Label>
                    All
                    <input
                      type="checkbox"
                      id="All"
                      value="All"
                      onChange={() => {
                        handleSelectAll('all');
                      }}
                      checked={
                        checkFilterOption.includes('all') ||
                        filterOptionValue.includes('all')
                      }
                    />
                    <span />
                  </Form.Label>
                </li> */}

                    {ActionCategories.map((item, index) => (
                      <li
                        key={`sessionC-${index}`}
                        className={
                          checkFilterOption.includes(item.id) ||
                          filterOptionValue.includes(item.title)
                            ? 'active'
                            : ''
                        }>
                        <Form.Label>
                          {item.title}
                          <input
                            type="checkbox"
                            id={item.id}
                            value={item.title}
                            onChange={e => {
                              handleClickList(item.id, e.target.value);
                            }}
                            checked={
                              checkFilterOption.includes(item.id) ||
                              filterOptionValue.includes(item.title)
                            }
                          />
                          <span />
                        </Form.Label>
                      </li>
                    ))}
                  </ul>

                  <div className="text-center">
                    <Button
                      type="submit"
                      className="btn-dark "
                      onClick={() => {
                        getBoardTasks(1);
                        setBoardFilterModal(false);
                      }}>
                      Filter
                    </Button>
                  </div>
                </div>
              </div>
            </CustomModal>
          )}
        </div>
      </div>
    </div>
  );
};

export default Task;
