import React, {useState, useEffect} from 'react';
import {Button, Dropdown} from 'react-bootstrap';
import commonApi from '../../Services/CommonService';
import {fixName} from '../../Utils/commonUtils';
import Scrollbars from 'react-custom-scrollbars';
import SlidingPanel from '../SlidingPanel';
import LoaderSpinner from '../Modals/LoaderSpinner';
import {DEFAULT_IMAGE} from '../../Utils/env';
import moment from 'moment/moment';
import {errorMessage} from '../../Utils/ToastMessages';
import ProfileComponent from '../ProfilePage/ProfileComponent';
import AppointmentCall from './AppointmentCall';
import {amplitudeEvent} from '../../Services/AmplitudeServices';
import {selected_lang} from '../../Utils/env';
import {labels} from '../../Constants/Translations';
import {FormattedText} from '../../Utils/Helpers';
import DropdownMenu from 'react-bootstrap';
import {successMessage} from '../../Utils/ToastMessages';
import {getAllAppointments} from '../../Store/Actions/AppointmentAction';
import {useSelector, useDispatch} from 'react-redux';
import {RoiLogo} from '../../Constants/Images';
const AppointmentCallDetail = props => {
  // const [appointmentList, setAppointmentList] = useState([]);

  const [showLoader, setshowLoader] = useState(false);
  const [showComponent, setShowComponent] = useState('');
  const [profileId, setProfileId] = useState('');
  const [conferenceId, setConferenceId] = useState(null);
  const [showMore, setShowMore] = useState(false);
  const showMoreClass = showMore ? 'show-all' : '';

  const dispatch = useDispatch();

  const [userStatus, setUserStatus] = useState(
    props.subTabType === 'to_me'
      ? props.appointmentData.creator_status
      : props.appointmentData.user_status,
  );

  const dropdownMenu = [
    {id: 'RUNNING_LATE', title: 'Running Late'},
    {id: 'ON_MY_WAY', title: 'On my way'},
    {id: 'IM_OUTSIDE', title: "I'm outside"},
    {id: 'IM_DONE', title: "I'm Done"},
  ];

  const lines = props.appointmentData.description.split('\n');

  //   useEffect(() => {
  //     serverTimeCheck()
  //   }, [])

  //   const serverTimeCheck = () => {

  //     commonApi
  //       .check_server_time()
  //       .then(res => {
  //         setServerTime(res.server_time);
  //       })
  //       .catch(err => {
  //         console.log('Err', err);
  //         return {type: 'error', message: err.message};
  //       });
  //   };

  const handleProfileComponent = userId => {
    setProfileId(userId);
    setShowComponent('profileComponent');
  };
  // alert('props.appointmentDataprops.appointmentData');

 

  const makeCallDolby = id => {
    let payLoad = {appointment_id: id, call_type: 'other'};
    commonApi
      .make_call_dolby(payLoad)
      .then(res => {
        setshowLoader(false);
        if (res.success === true) {
          amplitudeEvent('START_APPOINTMENT_CALL');
          // setConferenceId(res.session_id);
          setConferenceId(res);
          setShowComponent('AppointmentCall');
        } else {
          errorMessage(res.message);
        }
      })
      .catch(err => {
        return {type: 'error', message: err.message};
      });
  };

  // const getConsultHistoryByType = type => {
  //   setshowLoader(true);
  //   const data = {type: type, search_query: ''};
  //   commonApi
  //     .get_consult_history_by_type(data)
  //     .then(res => {
  //       setAppointmentList(res.history);

  //       setTimeout(() => {
  //         setshowLoader(false);
  //       }, 1000);
  //     })
  //     .catch(err => {
  //       console.log('Err', err);
  //       return {type: 'error', message: err.message};
  //     });
  // };

  // const handleSubmit = () => {
  //   errorMessage('waiting for developer ');
  // };

  const changeUserMeetingStatus = status => {
    const data = {meeting_id: props.appointmentData.id, user_status: status};

    commonApi
      .change_button_status(data)
      .then(res => {
        const data = {type: props.subTabType, search_query: ''};

        if (props.type !== 'userProfile') {
          dispatch(getAllAppointments(data));
        }
        if (props.type === 'userProfile') {
          props.refreshUserAppointmentList &&
            props.refreshUserAppointmentList();

          props.changeMeetingStatusForOverView &&
            props.changeMeetingStatusForOverView(props.appointmentData, status);
        }
        successMessage(res.message);
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  useEffect(() => {
    setUserStatus(
      props.subTabType === 'to_me'
        ? props.appointmentData.creator_status
        : props.appointmentData.user_status,
    );
  }, [props.appointmentData]);

  const renderHeader = () => {
    return (
      <>
        <div className="appointment-call gap-3 mb-0">
          <div className="persons-img-list">
            {props.subTabType === 'from_me' && (
              <div
                className="img-item as-link"
                onClick={() => {
                  handleProfileComponent(
                    props.appointmentData.creator_details.id,
                  );
                }}>
                <img
                  src={
                    props.appointmentData.creator_details.profile_img
                      ? props.appointmentData.creator_details.profile_img
                      : DEFAULT_IMAGE
                  }
                  alt=""
                />
              </div>
            )}
            {props.subTabType === 'to_me' && (
              <div
                className="img-item as-link"
                onClick={() => {
                  handleProfileComponent(props.appointmentData.user_details.id);
                }}>
                <img
                  src={
                    props.appointmentData.user_details.profile_img
                      ? props.appointmentData.user_details.profile_img
                      : DEFAULT_IMAGE
                  }
                  alt=""
                />
              </div>
            )}
          </div>

          <div className="heading">
            <h3 style={{fontSize: '17px'}}>
              {' '}
              {props.subTabType === 'from_me' &&
                props.appointmentData.creator_details &&
                fixName(
                  props.appointmentData.creator_details.first_name,
                  props.appointmentData.creator_details.last_name,
                )}
              {props.subTabType === 'to_me' &&
                props.appointmentData.creator_details &&
                fixName(
                  props.appointmentData.user_details.first_name,
                  props.appointmentData.user_details.last_name,
                )}
            </h3>
          </div>
        </div>
      </>
    );
  };


  return (
    <>
      {showComponent === 'profileComponent' && profileId && (
        <ProfileComponent
          userId={profileId}
          show={false}
          closeModal={() => {
            setShowComponent('');
          }}
          onClose={() => {
            setShowComponent('');
          }}
          userData={[]}
          moderator={false}
          // setParticipantType={setParticipantType}
          VoxeetSDK={[]}
          conferenceInfo={[]}
          jdSpecialM={[]}
          jdSpecialA={[]}
        />
      )}
      {showComponent === 'AppointmentCall' && conferenceId && (
        <AppointmentCall
          onClose={() => {
            setShowComponent('');
            props.onClose();
            props.setShowComponent('praiseCaution');
          }}
          confData={conferenceId}
          subTabType={props.subTabType}
          getMyAppointmentsByType={props.getMyAppointmentsByType}
        />
      )}
      {showComponent === '' && (
        <div className="page-wrapper">
          <SlidingPanel
            hidePanel={() => props.onClose()}
            renderData={renderHeader}
            showBlackHeader={true}
            headerPadding={'16px 20px'}
            lastDivStyle={{borderRadius: '35px', background: '#f7f6f2'}}
            firstDivStyle={{
              padding: 0,
              background:
                'linear-gradient(to bottom, #424242, #424242, #f7f6f2 50%)',
            }}>
            <div className="sessions-content-wrapper appointment">
              {/* <div className="d-flex justify-content-between align-items-center mb-2">
                <div />
              </div> */}

              <Scrollbars>
                <>
                  {showLoader && (
                    <LoaderSpinner className={'curved-spinner-bg'} />
                  )}
                  <div className="serach-filter-wrapper pt-3">
                    {/* <div className=" fixed-width-inputs black-head-form-pad-left fix-width-forms">
                      <div className="appointment-call">
                        <div className="heading">
                          <h3>
                            {' '}
                            {props.subTabType === 'from_me' &&
                              props.appointmentData.creator_details &&
                              fixName(
                                props.appointmentData.creator_details
                                  .first_name,
                                props.appointmentData.creator_details.last_name,
                              )}
                            {props.subTabType === 'to_me' &&
                              props.appointmentData.creator_details &&
                              fixName(
                                props.appointmentData.user_details.first_name,
                                props.appointmentData.user_details.last_name,
                              )}
                          </h3>
                        </div>

                        <div className="persons-img-list">
                          {props.subTabType === 'from_me' && (
                            <div
                              className="img-item as-link"
                              onClick={() => {
                                handleProfileComponent(
                                  props.appointmentData.creator_details.id,
                                );
                              }}>
                              <img
                                src={
                                  props.appointmentData.creator_details
                                    .profile_img
                                    ? props.appointmentData.creator_details
                                        .profile_img
                                    : DEFAULT_IMAGE
                                }
                                alt=""
                              />
                            </div>
                          )}
                          {props.subTabType === 'to_me' && (
                            <div
                              className="img-item as-link"
                              onClick={() => {
                                handleProfileComponent(
                                  props.appointmentData.user_details.id,
                                );
                              }}>
                              <img
                                src={
                                  props.appointmentData.user_details.profile_img
                                    ? props.appointmentData.user_details
                                        .profile_img
                                    : DEFAULT_IMAGE
                                }
                                alt=""
                              />
                            </div>
                          )}
                        </div>
                      </div>
                    </div> */}

                    <div className="fix-width-forms fixed-width-inputs black-head-form-pad-left ">
                      {showLoader && <LoaderSpinner />}

                      <div className="events-wrapper">
                        <span className="d-block mb-2">
                          <b>{props.appointmentData.appointment_type_title}</b>
                        </span>

                        <div className="events-headings">
                          <span className="category-name">
                            {/* {props.appointmentData.description} */}

                            <FormattedText
                              className={`profile-description category-name ${showMoreClass}`}
                              content={props.appointmentData.description}
                            />
                          </span>
                          {(props.appointmentData.description.length > 250 ||
                            lines.length > 3) && (
                            <>
                              {' '}
                              <span
                                className="show-more-link"
                                onClick={() => setShowMore(!showMore)}>
                                {!showMore
                                  ? labels.read_more[selected_lang]
                                  : labels.read_less[selected_lang]}
                              </span>
                            </>
                          )}
                        </div>

                        <div className="events-desc-wrapper opportunies-content-wrapper">
                          <div className="skillset-desired-bg-wrapper">
                            <span className="d-block">
                              <p>
                                <b>Duration</b>
                              </p>
                              <p>{props.appointmentData.duration} min</p>
                            </span>
                            <span className="d-block">
                              <p>
                                <b>Cost</b>
                              </p>
                              <p>
                                {props.appointmentData.cost
                                  ? '$' + props.appointmentData.cost
                                  : 'Free'}{' '}
                              </p>
                            </span>
                            <span className="d-block">
                              <div className="d-flex align-items-center justify-content-between">
                                <span className="event-address d-block mt-0">
                                  <p>
                                    <b>Time</b>
                                  </p>
                                  <p>
                                    {' '}
                                    {moment(
                                      props.appointmentData.start_date
                                        ? props.appointmentData.start_date
                                        : props.appointmentData.start,
                                    ).format(' MMM D, YYYY @ h:mm A')}
                                  </p>
                                </span>
                              </div>
                            </span>
                          </div>
                        </div>
                      </div>

                      <div className="appointment_detail_drpdown">
                        <span className=" ">
                          <b>Update Status</b>
                        </span>

                        <div className="roi-form-dropdown ">
                          <div className="select-wrapper">
                            <Dropdown
                              onSelect={(eventKey, event) => {
                                setUserStatus(eventKey);
                                changeUserMeetingStatus(eventKey);
                                if (props.type === 'userProfile') {
                                  props.setUserApStatus(eventKey);
                                }
                              }}
                              drop="down">
                              <Dropdown.Toggle variant="primary">
                                {userStatus
                                  ? dropdownMenu.find(
                                      item => item.id === userStatus,
                                    ).title
                                  : 'Select Role'}
                              </Dropdown.Toggle>

                              <Dropdown.Menu>
                                {dropdownMenu.map((item, index) => (
                                  <Dropdown.Item
                                    key={`st-${index}`}
                                    eventKey={item.id.toString()}
                                    active={item.id === userStatus}>
                                    {item.title}
                                  </Dropdown.Item>
                                ))}
                              </Dropdown.Menu>
                            </Dropdown>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              </Scrollbars>

              <div className="fix-width-forms fixed-width-inputs ">
                <div className="event-btn-wrapper  text-center m-auto pb-2">
                  {props.appointmentData.meeting_status == 'pending' &&
                    !props.appointmentData.start_call &&
                    props.appointmentData.created_by ===
                      localStorage.getItem('id') && (
                      <div
                        className="as-link"
                        style={{
                          color: '#52bd41',
                        }}
                        onClick={() => {
                          props.cancelAppointment();
                        }}>
                        Cancel Appointment
                      </div>
                    )}
                  <Button
                    onClick={() => {
                      makeCallDolby(props.appointmentData.appointment_id);
                    }}>
                    {' '}
                    {props.subTabType === 'from_me'
                      ? 'Join Call'
                      : props.subTabType === 'to_me' && 'Start Call'}
                  </Button>
                </div>
              </div>
            </div>
          </SlidingPanel>
        </div>
      )}
    </>
  );
};
export default AppointmentCallDetail;
