import React, {useState, useRef, useEffect} from 'react';
import SlidingPanel from '../SlidingPanel';
import {RoiLogo} from '../../Constants/Images';
import {Button, Form, Row, Col} from 'react-bootstrap';
import Scrollbars from 'react-custom-scrollbars';

const ImgPreviews = ({onClose, images, handleDeleteImg}) => {
  const [hiddenImages, setHiddenImages] = useState([]);

  const handleHideImage = item => {
    setHiddenImages(prev => [...prev, item]); // Store the hidden image
    if (hiddenImages.length + 1  === images.length) {
      onClose();
    }
  };
  const renderHeader = () => {
    return (
      <>
        <img src={RoiLogo} alt="" />
      </>
    );
  };
  return (
    <SlidingPanel
      hidePanel={() => {
        onClose();
      }}
      renderData={renderHeader}
      showBlackHeader={true}
      headerPadding={'24px 20px'}
      lastDivStyle={{borderRadius: '35px', background: '#f7f6f2'}}
      firstDivStyle={{
        padding: 0,
        background: 'linear-gradient(to bottom, #424242, #424242, #f7f6f2 50%)',
      }}>
      <div className="serach-filter-wrapper">
        <div className="m-0 w-100 d-flex align-items-center justify-content-between fixed-width-inputs fix-width-forms pt-3 black-head-form-pad-left black-head-form-pad-right">
          <h3>Images</h3>
        </div>

        <Scrollbars className="custom-scroll">
          <div className="snapshot-preview-wrapper fix-width-forms">
            <Row>
              {Object.entries(images).length > 0 ? (
                Object.entries(images).length > 0 &&
                images.map((item, index) => {
                  return (
                    <Col md={4} sm={4} key={`image-${index}`}>
                      <div
                        className={`snapshot-item ${
                          hiddenImages.includes(item) ? 'hidden' : ''
                        }`}>
                        <span
                          className="remove-icon"
                          onClick={() => {
                            const updatedImgArray = images.filter(
                              img => img !== item,
                            );
                            // setTest('')
                            handleHideImage(item);
                            handleDeleteImg(updatedImgArray);
                          }}>
                          <i className="fas fa-times" />
                        </span>

                        <img className="as-link" src={item} alt="" />
                      </div>
                    </Col>
                  );
                })
              ) : (
                <p className="text-center">No Images Found</p>
              )}
            </Row>
          </div>
        </Scrollbars>
      </div>
    </SlidingPanel>
  );
};

export default ImgPreviews;
