import React, {useState, useEffect} from 'react';
import {Button, Form} from 'react-bootstrap';
import SlidingPanel from '../SlidingPanel';
import LoaderSpinner from '../Modals/LoaderSpinner';
import Scrollbars from 'react-custom-scrollbars';
import Input from '../Input/Input';
import {errorMessage} from '../../Utils/ToastMessages';
import ConfirmationModel from '../ConfirmationModel/ConfirmationModel';
import CustomDropdown from '../CustomDropdown';

const PropertyFietdsType = props => {
  const [tabType, setTabType] = useState('Type');
  const [typeFiled, setTypeFiled] = useState('');
  const [typeArray, setTypeArray] = useState([]);
  const [fieldArray, setFieldArray] = useState([]);
  const [showModel, setshowModel] = useState('');
  const [showDeleteIndex, setShowDeleteIndex] = useState('');
  const [dropdownTypeId, setDropdownTypeId] = useState('');

  const handlePushInArray = () => {
    if (tabType === 'Type') {
      if (!typeArray.some(item => item.title === typeFiled)) {
        const newType = {
            id: (typeArray.length + 1).toString(),
          title: typeFiled,
        };
        setTypeArray(prevArray => [...prevArray, newType]);
        setTypeFiled('');
      } else {
        errorMessage('Type is already added');
      }
    } else if (tabType === 'Fields') {
      if (!fieldArray.some(item => item.title === typeFiled)) {
        const newField = {
        id: (typeArray.length + 1).toString(),
          title: typeFiled,
          type_id: dropdownTypeId, // assuming first type as type_id
        };
        setFieldArray(prevArray => [...prevArray, newField]);
        setTypeFiled('');
      } else {
        errorMessage('Field is already added');
      }
    }
  };

  const handlePopInArray = index => {
    if (tabType === 'Type') {
      const updatedArray = typeArray.filter((_, idx) => idx !== index);
      setTypeArray(updatedArray);
    } else {
      const updatedArray = fieldArray.filter((_, idx) => idx !== index);
      setFieldArray(updatedArray);
    }
    setShowDeleteIndex('');
  };

  const DecessionArray = tabType === 'Type' ? typeArray : fieldArray;

  useEffect(() => {
    setTypeFiled('');
  }, [tabType]);

 

  return (
    <>
      <SlidingPanel hideCloseicon={true}>
        <div className="serach-filter-wrapper">
          <div className="sessions-content-wrapper d-flex">
            <div className="serach-filter-wrapper pt-0">
              <div
                className={`sidebar-heading-wrapper fix-width-forms ${
                  props.windowWidth < 1300 ? 'with-panel' : ''
                }`}>
                <h2>Property</h2>
              </div>
              <div className="messages-header messages-roi-custom-header">
                <div className="event-tabs tabs-style-2 mb-0">
                  {' '}
                  <ul className="LabelTabs">
                    <li className="ml-20 d-flex align-items-center">
                      <Button
                        className={tabType === 'Type' ? 'active' : ''}
                        onClick={() => {
                          setTabType('Type');
                        }}>
                        Type
                      </Button>
                    </li>

                    <li className="ml-20 d-flex align-items-center">
                      <Button
                        className={tabType === 'Fields' ? 'active' : ''}
                        onClick={() => {
                          setTabType('Fields');
                        }}>
                        {'Fields'}
                      </Button>
                    </li>
                  </ul>
                </div>
              </div>
              {tabType === 'Fields' && (
                <div className="row">
                  <div className="col-6">
                    <div className="fieldset">
                      <Form.Label style={{width: 'auto'}}>Type</Form.Label>
                      <CustomDropdown
                        dropdownId={dropdownTypeId}
                        setDropdownId={value => setDropdownTypeId(value)}
                        DropDownValues={typeArray}
                        itemName={['title']}
                      />
                    </div>
                  </div>
                </div>
              )}

              <div className="row">
                <div className="col-6">
                  <Input
                    label={tabType}
                    name={tabType}
                    value={typeFiled}
                    onChange={e => {
                      setTypeFiled(e.target.value);
                    }}
                    placeholder={`Enter ${tabType}`}
                    feedback="Type is required."
                  />
                </div>
                <div className="col-3">
                  <div
                    className="filter-btn-wrapper text-center pt-2 pb-2 "
                    style={{
                      marginTop: '14px',
                    }}>
                    <Button
                      className="btn-dark mb-0"
                      style={{
                        padding: '10px 30px',
                      }}
                      onClick={() => {
                        if (typeFiled) {
                          handlePushInArray();
                        } else {
                          errorMessage('Fields are required ');
                        }
                      }}>
                      Add {tabType}
                    </Button>
                  </div>
                </div>
              </div>
              <div
                className="preferences-forms"
                style={{flex: 1, position: 'relative'}}>
                <Scrollbars
                  style={{
                    position: 'absolute',
                    width: '100%',
                    height: '100%',
                  }}>
                  <div className="list-container">
                    {DecessionArray.length > 0 && (
                      <ul className="col-6">
                        {DecessionArray.map((item, index) => (
                          <li
                            key={item.id}
                            style={{
                              display: 'flex',
                              justifyContent: 'space-between',
                              backgroundColor: 'white',
                              padding: '15px',
                              marginBottom: '10px',
                            }}>
                            {item.title}
                            <button
                              className="delete-btn"
                              onClick={() => {
                                setShowDeleteIndex(index);
                                setshowModel('deleteModel');
                              }}
                              aria-label="Delete">
                              <i className="fas fa-times" />
                              {/* FontAwesome cross icon */}
                            </button>
                          </li>
                        ))}
                      </ul>
                    )}
                  </div>
                </Scrollbars>
              </div>

              <div className="filter-btn-wrapper text-center pt-2 pb-2 fix-width-forms bg_transparent bottom-0">
                <Button className="btn-dark mb-0" type="submit">
                  Save
                </Button>
              </div>
            </div>
          </div>

          {showModel === 'deleteModel' && showDeleteIndex && (
            <ConfirmationModel
              closeModel={() => {
                setshowModel('');
              }}
              discription={`Are you sure you want to delete this ${tabType}?`}
              buttonOne={'Yes'}
              buttonTwo={'No Thanks'}
              ButtonOneAction={() => {
                handlePopInArray(showDeleteIndex);
              }}
            />
          )}
        </div>
      </SlidingPanel>
    </>
  );
};

export default PropertyFietdsType;
