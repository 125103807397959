import React, {useState, } from 'react';
import {Button, Col, Row} from 'react-bootstrap';
import {CustomModal, SlidingPanel} from '../../../Components';
import {DEFAULT_IMAGE} from '../../../Utils/env';
import {fixName} from '../../../Utils/commonUtils';
import Scrollbars from 'react-custom-scrollbars';
import {errorMessage} from '../../../Utils/ToastMessages';
// import { fixName } from '../../../Utils/commonUtils';

const SelectedUsers = props => {
  const [showModaL, setShowModel] = useState(false);
  const [clearAllModal, setclearAllModal] = useState(false);
  const [popId, setPopId] = useState('');
  const handleSelectedAud = id => {
   
    props.setUsers(props.users.filter(item => item !== popId.id));
    props.setSelectedAllUser(
      props.selectedAllUser.filter(item => item.id !== popId.id),
    );
    if (props.removeObject) {
      props.setRemoveObject(
        props.removeObject.filter(item => item.user_id !== popId.id),
      );
    }

    if (props.selectedAllUser.length === 1) {
      props.onClose();
    }
  };

  return (
    <>
      <SlidingPanel hidePanel={() => props.onClose()}>
        <div className="serach-filter-wrapper pt-0">
          <div className="sidebar-heading-wrapper mt-0 with-panel">
            <div className="invite-persons-heading">
              {props.selectedAllUser.length > 0 && (
                <span
                  className="builder-text as-link"
                  onClick={() => {
                    setclearAllModal(true);
                  }}>
                  Clear all ({props.selectedAllUser.length})
                </span>
              )}
            </div>
          </div>

          <Scrollbars>
            <span className="builder-text"> </span>
            <div className="invite-persons-list fix-width-forms">
              <Row>
                {Object.entries(props.selectedAllUser).length > 0 ? (
                  Object.entries(props.selectedAllUser).length > 0 &&
                  props.selectedAllUser.map((item, index) => (
                    <Col md={4} xl={3} key={`f-item-${index}`}>
                      <div
                        className="stage-list-item"
                        id={item.id}
                        onClick={() => {
                          setPopId(item);
                          setShowModel(true);
                        }}>
                        <div className="stage-person-img as-link">
                          <img
                            src={
                              item.profile_img === ''
                                ? DEFAULT_IMAGE
                                : item.profile_img
                            }
                            alt=""
                            id={item.id}
                          />
                        </div>
                        <div className="stage-person-name as-link">
                          <h3>{fixName(item.first_name, item.last_name)}</h3>
                        </div>
                      </div>
                    </Col>
                  ))
                ) : (
                  <p className="text-center"> No user found.</p>
                )}
              </Row>
            </div>
          </Scrollbars>
        </div>
      </SlidingPanel>

      {showModaL && (
        <CustomModal size="small" onClose={() => setShowModel(false)}>
          <div className="permission-modal-content text-center">
            <h2>Alert</h2>
            <p>
              Are you sure you want to remove{' '}
              <b>
              {fixName(popId.first_name, popId.last_name)}</b>?
            </p>
            <div className="event-btn-wrapper permission-modal-btns">
              <Button
                variant="secondary"
                onClick={() => {
                  if (props.alreadyAddedUser.includes(popId.id)) {
                    errorMessage('This user is  already associated');
                    setShowModel(false);
                  } else {
                    handleSelectedAud();
                    setShowModel(false);
                  }
                }}>
                Yes
              </Button>
              <Button
                variant="primary"
                onClick={() => {
                  setShowModel(false);
                }}>
                No Thanks!
              </Button>
            </div>
          </div>
        </CustomModal>
      )}
      {clearAllModal && (
        <CustomModal size="small" onClose={() => setclearAllModal(false)}>
          <div className="permission-modal-content text-center">
            <h2>Alert</h2>
            <p>Are you sure you want to clear all?</p>
            <div className="event-btn-wrapper permission-modal-btns">
              <Button
                variant="secondary"
                onClick={() => {
                  const usersIds = props.users.filter(id =>
                    props.alreadyAddedUser.includes(id),
                  );
                  if (props.alreadyAddedUser.length > 0 && usersIds.length > 0) {
                    const userDetails = props.selectedAllUser.filter(item =>
                      props.alreadyAddedUser.includes(item.id),
                    );

                    props.setUsers(usersIds);
                    props.setSelectedAllUser(userDetails);

                    setclearAllModal(false);
                    errorMessage('These users  are already associated');
                  } else {
                    setclearAllModal(false);
                    props.setUsers([]);
                    props.setSelectedAllUser([]);
                    if (props.removeObject) {
                      props.setRemoveObject([]);
                    }

                    props.onClose();
                  }
                }}>
                Yes
              </Button>
              <Button
                variant="primary"
                onClick={() => {
                  setclearAllModal(false);
                }}>
                No
              </Button>
            </div>
          </div>
        </CustomModal>
      )}
    </>
  );
};
export default SelectedUsers;
