import {useState, useEffect, useRef} from 'react';
import {Form} from 'react-bootstrap';
import {FaBars, FaTrash, FaAsterisk} from 'react-icons/fa';
import MultiSelectionDropdown from '../MultiSelectionDropdown';
import {Trash} from '../../Constants/Images';
import EditPencilSvg from '../../Svg/EditPencilSvg';
import FieldSuggestion from './FieldSuggestion';
import {capitalizeFirst, editSection} from '../../Utils/commonUtils';
import {DragDropContext, Droppable, Draggable} from 'react-beautiful-dnd';
const FormStep = ({
  setSteps,
  steps,
  handleWizardStep,
  SubmitFormApi,
  userInEdit,
  updateForm,
}) => {
  const [openSections, setOpenSections] = useState(new Set());
  const [openStep, setOpenStep] = useState(new Set());
  const [fieldsList, setFieldsList] = useState([]);
  const [sectionName, setSectionName] = useState('');
  const [tempSectionNames, setTempSectionNames] = useState({});
  const [activeIndex, setActiveIndex] = useState(null);
  const [activeSectionIndex, setActiveSectionIndex] = useState({
    step: null,
    section: null
  });
  const [openSectionINdex, setOpenSectionINdex] = useState({
    step: null,
    section: null
  });
  const [error, setError] = useState(false);
  const [errorIndex, setErrorIndex] = useState(null);
  const [sectionIndex, setSectionIndex] = useState({
    index: null,
    type: '',
    sectionStepIndex: null,
  });
  const [showSelectedFieldsIndex, setShowSelectedFieldsIndex] = useState(null);
  const prevIndexRef = useRef(null);

  useEffect(() => {
    if (
      prevIndexRef.current !== null &&
      prevIndexRef.current !== showSelectedFieldsIndex
    ) {
      // setFieldsList([]);
      // setTempSectionNames({}) // Reset fields list only if the index changes
    }
    prevIndexRef.current = showSelectedFieldsIndex; // Store the previous index
  }, [showSelectedFieldsIndex]);

  // Toggle Section Collapse
  const toggleSection = (index, stepIndex)=> {
    setOpenSections(prevOpenSections => {
      const newOpenSections = new Set(prevOpenSections);
      if (newOpenSections.has(index)) {
        newOpenSections.delete(index);
      } else {
        newOpenSections.add(index);
      }
      return newOpenSections;
    });

    
    setOpenSectionINdex({
      step: stepIndex,
      section:openSectionINdex.step === stepIndex && openSectionINdex.section === index ? null : index
    })
  };
  const toggleStep = index => {
    setOpenStep(prevOpenSections => {
      const newOpenSections = new Set(prevOpenSections);
      if (newOpenSections.has(index)) {
        newOpenSections.delete(index);
      } else {
        newOpenSections.add(index);
      }
      return newOpenSections;
    });
  };

  

  // Step Drag and Drop
  const handleStepDragStart = (e, stepIndex) => {
    e.dataTransfer.setData('stepIndex', stepIndex);
  };

  const handleStepDrop = (e, targetIndex) => {
    const fromIndex = parseInt(e.dataTransfer.getData('stepIndex'));
    if (fromIndex === targetIndex) return;

    setSteps(prevSteps => {
      const newSteps = [...prevSteps];
      const [movedStep] = newSteps.splice(fromIndex, 1);
      newSteps.splice(targetIndex, 0, movedStep);
      return newSteps;
    });
  };

  // Section Drag and Drop
  const handleSectionDragStart = (e, stepIndex, sectionIndex) => {
    e.dataTransfer.setData('stepIndex', stepIndex);
    e.dataTransfer.setData('sectionIndex', sectionIndex);
  };

  const handleSectionDrop = (e, targetStepIndex, targetSectionIndex) => {
    const fromStepIndex = parseInt(e.dataTransfer.getData('stepIndex'));
    const fromSectionIndex = parseInt(e.dataTransfer.getData('sectionIndex'));

    if (fromStepIndex !== targetStepIndex) return;

    setSteps(prevSteps => {
      const newSteps = [...prevSteps];
      const sections = newSteps[targetStepIndex].section;
      const [movedSection] = sections.splice(fromSectionIndex, 1);
      sections.splice(targetSectionIndex, 0, movedSection);
      return newSteps;
    });
  };

  // Field Drag and Drop
  const handleFieldDragStart = (e, stepIndex, sectionIndex, fieldIndex) => {
    e.dataTransfer.setData('stepIndex', stepIndex);
    e.dataTransfer.setData('sectionIndex', sectionIndex);
    e.dataTransfer.setData('fieldIndex', fieldIndex);
  };

  const handleFieldDrop = (
    e,
    targetStepIndex,
    targetSectionIndex,
    targetFieldIndex,
  ) => {
    const fromStepIndex = parseInt(e.dataTransfer.getData('stepIndex'));
    const fromSectionIndex = parseInt(e.dataTransfer.getData('sectionIndex'));
    const fromFieldIndex = parseInt(e.dataTransfer.getData('fieldIndex'));

    setSteps(prevSteps => {
      const newSteps = [...prevSteps];
      const sourceFields =
        newSteps[fromStepIndex].section[fromSectionIndex].field_ids;
      const targetFields =
        newSteps[targetStepIndex].section[targetSectionIndex].field_ids;
      const [movedField] = sourceFields.splice(fromFieldIndex, 1);
      targetFields.splice(targetFieldIndex, 0, movedField);
      return newSteps;
    });
  };

 

  // Delete Field
  const deleteField = (stepIndex, sectionIndex, fieldIndex) => {
    setSteps(prevSteps => {
      const newSteps = [...prevSteps];
      newSteps[stepIndex].section[sectionIndex].field_ids.splice(fieldIndex, 1);
      return newSteps;
    });
  };

  const deleteStep = stepIndex => {};

  // Delete Section
  const deleteSection = (stepIndex, sectionIndex) => {
    setSteps(prevSteps => {
      const newSteps = [...prevSteps];
      newSteps[stepIndex].section = newSteps[stepIndex].section.filter(
        (_, index) => index !== sectionIndex,
      );
      return newSteps;
    });
    setTempSectionNames({
      [stepIndex]: '', // Update only the specific step input
    });
    setFieldsList([]);
    setSectionIndex({
      index: null,
      type: '',
      sectionStepIndex: null,
    });
  };

  // Toggle Required Status
  const toggleRequired = (stepIndex, sectionIndex, fieldIndex) => {
    setSteps(prevSteps => {
      const newSteps = [...prevSteps];
      newSteps[stepIndex].section[sectionIndex].field_ids[fieldIndex].required =
        !newSteps[stepIndex].section[sectionIndex].field_ids[fieldIndex]
          .required;
      return newSteps;
    });
  };

  const handleSection = stepIndex => {
    if (
      !tempSectionNames[stepIndex] ||
      tempSectionNames[stepIndex] === '' ||
      fieldsList.length === 0
    ) {
      setErrorIndex(stepIndex);
      setError(true);
    } else {
      if (
        sectionIndex.index !== null &&
        sectionIndex.sectionStepIndex === stepIndex
      ) {
        editSection(
          steps,
          setSteps,
          tempSectionNames[stepIndex],
          fieldsList,
          stepIndex,
          'editWizardSection',
          sectionIndex.index,
        );
      } else if (sectionIndex.index === null) {
        const newSection = {
          name: tempSectionNames[stepIndex],
          field_ids: fieldsList,
        };

        const newArray = steps?.map((item, itemIndex) =>
          itemIndex === stepIndex
            ? {
                ...item,
                section: [
                  ...(Array.isArray(item.section) ? item.section : []),
                  newSection,
                ], // Preserve previous sections and add new one
              }
            : item,
        );

        setSteps(newArray);
      }
      resetSection(stepIndex);
      setError(false);
    }
  };

  const resetSection = stepIndex => {
    // setTempSectionNames({});
    setTempSectionNames({
      [stepIndex]: '', // Update only the specific step input
    });
    setFieldsList([]);
    setSectionIndex({
      index: null,
      type: '',
      sectionStepIndex: null,
    });
  };

  const errorStyle = {
    color: '#dc3545',
    fontSize: '.875em',
    marginTop: '.25rem',
  };

  const handleForm = (itemIndex, type) => {
    if (type === 'multi_select_field') {
      setFieldsList(prev => prev.filter((_, index) => index !== itemIndex));
    }
  };



  const hnadelEditSection = (item, itemINdex, stepIndex) => {
    setTempSectionNames({
      [stepIndex]: item.name, // Update only the specific step input
    });
    setFieldsList(item.field_ids);
    setSectionIndex({
      index: itemINdex,
      type: 'edit_section',
      sectionStepIndex: stepIndex,
    });
  };

  const cancelSection = stepIndex => {
    setTempSectionNames({
      [stepIndex]: '', // Update only the specific step input
    });
    setFieldsList([]);
    setSectionIndex({
      index: null,
      type: '',
      sectionStepIndex: null,
    });
    setError(false)
  };

  const decideButtonName = stepIndex => {
    if (
      sectionIndex.index === null &&
      sectionIndex.sectionStepIndex === stepIndex
    ) {
      return 'Add Section';
    } else if (
      sectionIndex.index !== null &&
      sectionIndex.sectionStepIndex === stepIndex
    ) {
      return 'Update Section';
    } else {
      return 'Add Section';
    }
    // {(sectionIndex.index === null && sectionIndex.sectionStepIndex !== stepIndex)
    //   ? 'Add Section'
    //   : 'Update Section'}
  };

  const onDragEnd = result => {
    if (!result.destination) return;

    const {source, destination, type} = result;

    if (type === 'step') {
      // Sorting Steps
      const reorderedSteps = Array.from(steps);
      const [movedStep] = reorderedSteps.splice(source.index, 1);
      reorderedSteps.splice(destination.index, 0, movedStep);
      setSteps(reorderedSteps);
    } else if (type === 'section') {
      // Sorting Sections within a Step
      const stepIndex = source.droppableId.split('-')[1];
      const step = steps[stepIndex];
      const reorderedSections = Array.from(step.section);
      const [movedSection] = reorderedSections.splice(source.index, 1);
      reorderedSections.splice(destination.index, 0, movedSection);

      const updatedSteps = [...steps];
      updatedSteps[stepIndex].section = reorderedSections;
      setSteps(updatedSteps);
    } else if (type === 'field') {
      // Sorting Fields within a Section
      const [stepIndex, sectionIndex] = source.droppableId.split('-').slice(1);
      const section = steps[stepIndex].section[sectionIndex];
      const reorderedFields = Array.from(section.field_ids);
      const [movedField] = reorderedFields.splice(source.index, 1);
      reorderedFields.splice(destination.index, 0, movedField);

      const updatedSteps = [...steps];
      updatedSteps[stepIndex].section[sectionIndex].field_ids = reorderedFields;
      setSteps(updatedSteps);
    }
  };

  return (
    <DragDropContext onDragEnd={userInEdit ? onDragEnd : () => {}}>
      <Droppable droppableId="steps" type="step" isDropDisabled={!userInEdit}>
        {provided => (
          <div
            className="flex flex-col"
            {...provided.droppableProps}
            ref={provided.innerRef}
          >
            {steps?.map((step, stepIndex) => 
              {
                const isActive = stepIndex === activeIndex;
  
                return (
              <Draggable
                key={`step-${stepIndex}`}
                draggableId={`step-${stepIndex}`}
                index={stepIndex}
                isDragDisabled={!userInEdit}
              >
                {(provided) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    className="w-full bg-[#EDEBE5] mb-2 rounded-lg"
                  >
                    {/* Step Header */}
                    <div className="flex justify-between items-center p-3 rounded-t-lg">
                      <div 
                        className="flex items-center gap-2"
                        {...provided.dragHandleProps}
                      >
                        <FaBars className="text-gray-600" />
                        <span
                          className="text-lg font-medium"
                          onClick={() => toggleStep(stepIndex)}
                        >
                          {step.name}
                        </span>
                      </div>
                      {userInEdit && (
                          <div className="flex items-center gap-2">
                            {isActive && (
                              <p
                                className="as-link"
                                onClick={
                                  () => {
                                    handleWizardStep(
                                      stepIndex,
                                      'delete_Wizard_Step',
                                      step,
                                      'wizard_section',
                                    );
                                  }
                                  //  deleteStep(stepIndex)
                                }
                                style={{color: '#52bd41'}}>
                                Delete All
                              </p>
                            )}
                            <p
                              className="as-link"
                              onClick={() => {
                                setActiveIndex(isActive ? null : stepIndex);
                                setActiveSectionIndex(
                                  {
                                    step: null,
                                    section: null
                                  }
                                );
                              }}>
                              <img src={Trash} alt="" />
                            </p>
                            <span
                              className="as-link"
                              onClick={() => {
                                handleWizardStep(
                                  stepIndex,
                                  'edit_Wizard_Step',
                                  step,
                                  'wizard_section',
                                );
                              }}>
                              <EditPencilSvg fill={'#52bd41'} h={15} w={15} />
                            </span>
                          </div>
                        )}
                      {/* ... (keep existing step header buttons) */}
                    </div>

                    {openStep.has(stepIndex)  && ((!userInEdit && step.section.length > 0) ||  (userInEdit))  && (
                      <div className={` ${userInEdit ?'bg-white p-4 rounded-b-lg' : 'bg-white p-2 rounded-b-lg'} `}>
                        {/* Section creation UI */}
                        {userInEdit && (
                            <>
                              <div className="roi-form-input">
                                <Form.Control
                                  type="text"
                                  value={tempSectionNames[stepIndex] || ''} // Keep it empty per step
                                  pattern="^\S.*$"
                                  required
                                  placeholder="Section Name"
                                  onChange={e => {
                                    setTempSectionNames({
                                      [stepIndex]: e.target.value, // Update only the specific step input
                                    });
                                    // setSectionName(e.target.value);
                                  }}
                                />

                                <span style={errorStyle}>
                                  {error &&
                                    errorIndex === stepIndex &&
                                    (!tempSectionNames[stepIndex] ||
                                      tempSectionNames[stepIndex] === '') &&
                                    'Section name is required.'}
                                </span>
                              </div>

                              <FieldSuggestion
                                groupFields={fieldsList}
                                setGroupFields={setFieldsList}
                                setAnySeacrh={setError}
                                handleDelete={handleForm}
                                showData={
                                  tempSectionNames[stepIndex] ? true : false
                                }
                                type="wizard"
                                setShowSelectedFieldsIndex={
                                  setShowSelectedFieldsIndex
                                }
                                showSelectedFieldsIndex={
                                  showSelectedFieldsIndex
                                }
                                stepIndex={stepIndex}
                              />

                              <span style={errorStyle}>
                                {error &&
                                  errorIndex === stepIndex &&
                                  fieldsList?.length === 0 &&
                                  'Please select atleast one field.'}
                              </span>

                              <div
                                style={{margin: '5px'}}
                                className="text-center">
                                {' '}
                                <button
                                  style={{
                                    borderRadius: '6.25px',
                                    border: '1px solid #888888',
                                    padding: '10px',
                                  }}
                                  onClick={() => {
                                    handleSection(stepIndex);
                                  }}
                                  className="as-link  mb-3 mt-3  add-button">
                                  {decideButtonName(stepIndex)}
                                </button>
                                {sectionIndex.index !== null &&
                                  sectionIndex.sectionStepIndex ===
                                    stepIndex && (
                                    <h2
                                      className="mb-2 as-link"
                                      onClick={() => {
                                        cancelSection(stepIndex);
                                      }}>
                                      Cancel
                                    </h2>
                                  )}
                              </div>
                            </>
                          )}

                        {/* Sections list */}
                        <Droppable
                          droppableId={`sections-${stepIndex}`}
                          type="section"
                          isDropDisabled={!userInEdit}
                        >
                          {(provided) => (
                            <div
                              {...provided.droppableProps}
                              ref={provided.innerRef}
                              className="space-y-2"
                            >
                              {step.section?.map((section, sectionIndex) => {
                                  const isActiveSection =
                                  sectionIndex === activeSectionIndex.section;
                              
                                  const matchStepSectionIndex = activeSectionIndex.step === stepIndex && activeSectionIndex.section === sectionIndex

                                  return (
                                <Draggable
                                  key={`section-${stepIndex}-${sectionIndex}`}
                                  draggableId={`section-${stepIndex}-${sectionIndex}`}
                                  index={sectionIndex}
                                  isDragDisabled={!userInEdit}
                                >
                                  {(provided) => (
                                    <div
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      className="rounded-lg p-2"
                                    >
                                      {/* Section Header */}
                                      <div className="flex justify-between items-center p-2">
                                        <div
                                          className="flex items-center gap-2"
                                          {...provided.dragHandleProps}
                                          onClick={() => toggleSection(sectionIndex, stepIndex)}
                                        >
                                          <FaBars className="text-gray-500" />
                                          <span className="font-medium">{section.name}</span>
                                        </div>
                                        {/* ... (keep existing section buttons) */}
                                        {userInEdit && (
                                              <div
                                                className="flex justify-between items-center "
                                                style={{
                                                  gap: '10px',
                                                }}>
                                                {/* <FaTrash className="text-gray-600 cursor-pointer hover:text-red-500" /> */}
                                                {(isActive || matchStepSectionIndex) && (
                                                  <p
                                                    className="as-link"
                                                    onClick={() =>
                                                      deleteSection(
                                                        stepIndex,
                                                        sectionIndex,
                                                      )
                                                    }
                                                    style={{color: '#52bd41'}}>
                                                    Delete All
                                                  </p>
                                                )}
                                                <p className="as-link">
                                                  <img
                                                    onClick={() => {
                                                      setActiveSectionIndex(
                                                        {
                                                          step: activeSectionIndex.step === stepIndex ? null : stepIndex,
                                                          section:activeSectionIndex.step === stepIndex  && activeSectionIndex.section  == sectionIndex   ? null : sectionIndex
                                                        }
                                                      );
                                                    }}
                                                    src={Trash}
                                                    alt=""
                                                  />
                                                </p>
                                                <span
                                                  className="as-link"
                                                  onClick={() => {
                                                    setShowSelectedFieldsIndex(stepIndex)
                                                    hnadelEditSection(
                                                      section,
                                                      sectionIndex,
                                                      stepIndex,
                                                    );
                                                  }}>
                                                  <EditPencilSvg
                                                    fill={'#52bd41'}
                                                    h={15}
                                                    w={15}
                                                  />
                                                </span>
                                              </div>
                                            )}
                                      </div>

                                      {/* Fields list */}
                                      {(openSectionINdex.step === stepIndex && openSectionINdex.section === sectionIndex) && section.field_ids && section.field_ids.length > 0 && (
                                        <Droppable
                                          droppableId={`fields-${stepIndex}-${sectionIndex}`}
                                          type="field"
                                          isDropDisabled={!userInEdit}
                                        >
                                          {(provided) => (
                                            <div
                                              {...provided.droppableProps}
                                              ref={provided.innerRef}
                                              className="space-y-2 mt-2"
                                            >
                                              {section.field_ids.map((field, fieldIndex) => (
                                                <Draggable
                                                  key={`field-${stepIndex}-${sectionIndex}-${fieldIndex}`}
                                                  draggableId={`field-${stepIndex}-${sectionIndex}-${fieldIndex}`}
                                                  index={fieldIndex}
                                                  isDragDisabled={!userInEdit}
                                                >
                                                  {(provided) => (
                                                    <div
                                                      ref={provided.innerRef}
                                                      {...provided.draggableProps}
                                                      className="bg-white p-3 rounded-md shadow-sm"
                                                    >
                                                      <div className="flex justify-between items-center">
                                                        <div
                                                          className="flex items-center gap-2"
                                                          {...provided.dragHandleProps}
                                                        >
                                                          <FaBars className="text-gray-400" />
                                                          <div>
                                                            <div className="font-medium">
                                                              {field.name}
                                                            </div>
                                                            <div className="text-sm text-gray-500">
                                                              {capitalizeFirst(field.type)}
                                                            </div>
                                                          </div>
                                                        </div>
                                                        {/* ... (keep existing field buttons) */}
                                                        <div className="flex items-center gap-2">
                                                                <p className="as-link">
                                                                  {(isActive ||
                                                                    matchStepSectionIndex) &&
                                                                    userInEdit && (
                                                                      <span className="poc_delete as-link">
                                                                        <i
                                                                          class="fa-solid fa-trash-can"
                                                                          onClick={() =>
                                                                            deleteField(
                                                                              stepIndex,
                                                                              sectionIndex,
                                                                              fieldIndex,
                                                                            )
                                                                          }></i>
                                                                      </span>
                                                                    )}
                                                                </p>
                                                                <FaAsterisk
                                                                  fill={
                                                                    field.required
                                                                      ? '#52bd41'
                                                                      : 'black'
                                                                  }
                                                                  className={`${
                                                                    userInEdit
                                                                      ? 'as-link'
                                                                      : ''
                                                                  }`}
                                                                  onClick={() => {
                                                                    if (
                                                                      userInEdit
                                                                    ) {
                                                                      toggleRequired(
                                                                        stepIndex,
                                                                        sectionIndex,
                                                                        fieldIndex,
                                                                      );
                                                                    }
                                                                  }}
                                                                />
                                                              </div>
                                                        
                                                      </div>
                                                    </div>
                                                  )}
                                                </Draggable>
                                              ))}
                                              {provided.placeholder}
                                            </div>
                                          )}
                                        </Droppable>
                                      )}
                                    </div>
                                  )}
                                </Draggable>
                              )})}
                              {provided.placeholder}
                            </div>
                          )}
                        </Droppable>
                      </div>
                    )}
                  </div>
                )}
              </Draggable>
            )})}
            {provided.placeholder}
          </div>
        )}
      </Droppable>

      {userInEdit && (
        <div className="flex justify-center mt-4">
          <button
          style={{
            height: '42px',
            width: '150px'
          }}
            onClick={SubmitFormApi}
            className="bg-black text-white px-6 py-2 rounded-md"
          >
            {updateForm ? 'Update Form' : 'Create Form'}
          </button>
        </div>
      )}
    </DragDropContext>
  );
};

export default FormStep;
