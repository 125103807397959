import React, {useState} from 'react';
import Scrollbars from 'react-custom-scrollbars';
import CustomModal from '../CustomModal';
import {Button, Form} from 'react-bootstrap';

const InvoiceListingForProject = ({
  setSelectedInvoice,
  selectedInvoice,
  onClose,
  templatesList,
}) => {
  // Function to handle adding/removing invoices from the array
  const handleInvoiceSelection = invoiceId => {
    setSelectedInvoice(
      prevSelected =>
        prevSelected.includes(invoiceId)
          ? prevSelected.filter(id => id !== invoiceId) // Remove if already selected
          : [...prevSelected, invoiceId], // Add if not selected
    );
  };

  return (
    <div className="profile-gallery-wrapper">
      <CustomModal
        size="medium"
        style={{height: '85%'}}
        onClose={() => {
          onClose();
        }}>
        <div className="filter-wrapper custom-modal-filter mb-0">
          <div className="filter-headings">
            <h2 style={{textAlign: 'center'}}>Select Invoice</h2>
          </div>
          <Scrollbars autoHide>
            <div className="filter-category-list">
              <ul>
                {templatesList.length > 0 &&
                  templatesList.map((item, index) => (
                    <li
                      key={`sessionC-${index}`}
                      className={
                        selectedInvoice.includes(item.id) ? 'active' : ''
                      }>
                      <Form.Label>
                        {item.invoice_title.length > 10
                          ? item.invoice_title.substring(0, 10) + '...'
                          : item.invoice_title}
                        <input
                          type="checkbox"
                          value={item.id}
                          onChange={() => handleInvoiceSelection(item.id)}
                          checked={selectedInvoice.includes(item.id)}
                        />
                        <span />
                      </Form.Label>
                    </li>
                  ))}
              </ul>
            </div>
          </Scrollbars>
          <div className="filter-category-list">
            <Button
              className="btn-dark"
              onClick={() => {
               
                onClose(); // Close the modal after selection
              }}>
              Select
            </Button>
          </div>
        </div>
      </CustomModal>
    </div>
  );
};

export default InvoiceListingForProject;
