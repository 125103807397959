
import commonApi from '../../Services/CommonService';

export const getDispatch = (type, data) => ({
    type: type,
    payload: data,
  });

export const getAvailabilityData = data => {
    return async dispatch => {
      const response = await  commonApi
      .get_user_availability(data);
      dispatch(getDispatch('get_availability', response));
      return response;
    };
  };