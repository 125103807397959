import React, {useState, useEffect} from 'react';
import {Button, Modal} from 'react-bootstrap';
import API from '../../Services/SessionServices';
import ProfileBlock from '../ProfilePage/ProfileBlock';
import ProfileGallery from '../ProfilePage/ProfileGallery';
import messageService from '../../Services/MessageService';
import commonApi from '../../Services/CommonService';

function ProfileModal(props) {
  const [show, setShow] = useState(props.show);
  var dataOfPersons = props.userData;
  const [userInfo, setUserInfo] = useState([]);
  const [conferenceInfo, setconferenceInfo] = useState([]);
  const [showUserInfo, setShowUserInfo] = useState(false);
  const [UserisModerator, setUserisModerator] = useState(false);
  const [audio, setaudio] = useState(true);

  const [userSnapshots, setSnapshotData] = useState([]);
  const [showSnapshots, setShowSnapshot] = useState(false);

  // const [userUpcomming, setUpcommingData] = useState([]);
  // const [showUpcomming, setShowUpcomming] = useState(false);

  const [userOpportunity, setOpportunityData] = useState([]);
  const [showOpportunity, setShowOpportunity] = useState(false);

  const closeModal = () => {
    setShow(false);
    props.closeModal();
  };
  const sendMessage = type => {
    // send message to subscribers via observable subject
    messageService.sendMessage('update_api');
  };
  const mute = () => {
    var data = {
      user_id: props.userData.info.externalId,
      conference_id: conferenceInfo.id,
      mic: 'off',
    };
    API.update_mic_status(data)
      .then(res => {
        if (res) {
          props.VoxeetSDK.command
            .send({
              dataPayload: {
                id: props.userData.info.externalId,
                name: 'moderator_mute',
              },
            })
            .then(succ => {
              dataOfPersons.audio = false;
              setaudio(false);
            })
            .catch(err => {
              
            });
        }
      })
      .catch(err => {
        
        return {type: 'error', message: err.message};
      });
  };
  const unmute = () => {
    var data = {conference_id: props.conferenceInfo.id};
    API.update_mic_status(data)
      .then(res => {
        if (res) {
          props.VoxeetSDK.command
            .send({
              dataPayload: {
                id: props.userData.info.externalId,
                name: 'moderator_unmute',
              },
            })
            .then(succ => {
              dataOfPersons.audio = true;
              setaudio(true);
              sendMessage();
              props.VoxeetSDK.command
                .send({
                  dataPayload: {
                    id: props.userData.info.externalId,
                    name: 'request_accepted',
                  },
                })
                .then(succ => {})
                .catch(err => {
                  
                });
            })
            .catch(err => {
              
            });
        }
      })
      .catch(err => {
       
        return {type: 'error', message: err.message};
      });
  };
  const getUserData = () => {
    var data = {user_id: props.userData.info.externalId};
    commonApi
      .user_info(data)
      .then(res => {
        if (res) {
          setUserInfo(res);
          setShowUserInfo(true);
        }
      })
      .catch(err => {
        
        return {type: 'error', message: err.message};
      });
  };
  const switchCreator = () => {
    var data = {
      user_id: props.userData.info.externalId,
      conference_id: props.conferenceInfo.id,
    };
    API.switch_creator(data)
      .then(res => {
        if (res) {
          sendMessage('');
        }
      })
      .catch(err => {
        
        return {type: 'error', message: err.message};
      });
  };
  const manageUser = type => {
    // var typeTf = (type === 'audience') ? false : true;
    // props.setParticipantType(typeTf);
    var data = {
      user_id: props.userData.info.externalId,
      conference_id: props.conferenceInfo.id,
      user_type: type,
    };
    API.manage_user(data)
      .then(res => {
        if (res) {
          sendMessage(type);
          props.VoxeetSDK.command
            .send({
              dataPayload: {
                id: props.userData.info.externalId,
                name: 'update_api',
              },
            })
            .then(succ => {})
            .catch(err => {
              
            });
        }
      })
      .catch(err => {
       
        return {type: 'error', message: err.message};
      });
  };
  const getSnapshotData = () => {
    var data = {
      user_id: props.userData.info.externalId,
      categories: '',
    };
    commonApi
      .get_accomplishment(data)
      .then(res => {
        if (res) {
          setSnapshotData(res.accomplishment);
          setShowSnapshot(true);
        }
      })
      .catch(err => {
        
        return {type: 'error', message: err.message};
      });
  };
  // const getUpcommingData = () => {
  //     var data = {
  //         user_id: props.userData.info.externalId,
  //         type: 'all'
  //     }
  //     commonApi.get_user_sessions(data).then((res) => {
  //         if (res) {
  //             setUpcommingData(res);
  //             setShowUpcomming(true);
  //         }
  //     })
  //         .catch((err) => {
  //             console.log("Err", err);
  //             return { type: "error", message: err.message };
  //         });
  // }
  const getOpportunityData = () => {
    var data = {
      user_id: props.userData.info.externalId,
      type: 'all',
    };
    commonApi
      .get_user_available_opportunities(data)
      .then(res => {
        if (res) {
          setOpportunityData(res.user_available_opportunity);
          setShowOpportunity(true);
        }
      })
      .catch(err => {
       
        return {type: 'error', message: err.message};
      });
  };
  const CheckPersonIsModerator = id => {
    var found = false;
    if (props.conferenceInfo.moderator_list.length > 0) {
      props.conferenceInfo.moderator_list.forEach(element => {
        if (element.id === id) {
          found = true;
        }
      });
      if (props.conferenceInfo.creator_id === id) {
        found = true;
      }
      return found;
    }
  };
//   const CheckPersonIsAudiance = id => {
//     var found = false;
//     if (props.conferenceInfo.audience_list.length > 0) {
//       props.conferenceInfo.audience_list.forEach(element => {
//         if (element.id == id) {
//           found = true;
//         }
//       });
//       return found;
//     }
//   };
  useEffect(() => {
    getUserData();
    getSnapshotData();
    // getUpcommingData();
    setconferenceInfo(props.conferenceInfo);
    getOpportunityData();
    var check = false;
    check = CheckPersonIsModerator(props.userData.info.externalId);
    if (check) {
      setUserisModerator(true);
    }
    if (props.userData.audio) {
      setaudio(true);
    } else {
      setaudio(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [audio, UserisModerator]);

  return (
    <Modal className="medium" show={show} onHide={() => closeModal()}>
      <Modal.Header>
        <Modal.Title>
          {props.moderator ? (
            <div className="modal-header-icons">
              <span>
                <i className="fas fa-ellipsis-vertical" />
              </span>
              {audio ? (
                <span onClick={() => mute()}>
                  <i className="fas fa-microphone-slash" />
                </span>
              ) : (
                <span onClick={() => unmute()}>
                  <i className="fas fa-microphone" />
                </span>
              )}
              <span onClick={() => switchCreator()}>
                <i className="far fa-star" />
              </span>
            </div>
          ) : (
            <></>
          )}
        </Modal.Title>
        <div className="modal-header-btns">
          {props.moderator ? (
            UserisModerator ? (
              <Button onClick={() => manageUser('audience')}>
                Send To Audience
              </Button>
            ) : (
              <Button onClick={() => manageUser('moderator')}>
                Make Moderator
              </Button>
            )
          ) : (
            <></>
          )}
          <span className="close as-link" onClick={() => closeModal()}>
            <i className="fas fa-times" />
          </span>
        </div>
      </Modal.Header>
      <Modal.Body>
        <div className="session-profile-wrapper profile-modal">
          {showUserInfo && showSnapshots && showOpportunity ? (
            <>
              <ProfileBlock profile={userInfo} />
              <div className="invited-by-wrapper">
                <div className="user-img">
                  <img
                    src={
                      userInfo.data.invited_by.profile_img
                        ? userInfo.data.invited_by.profile_img
                        : 'https://build-dev.s3.us-east-1.amazonaws.com/build-default-profile.jpg'
                    }
                    alt=""
                  />
                </div>
                <div className="user-info">
                  <span className="info-name">
                    Invited By{' '}
                    <b>
                      {userInfo.data.invited_by.first_name +
                        ' ' +
                        userInfo.data.invited_by.last_name}
                    </b>
                  </span>
                  <span className="info-date">
                    {userInfo.data.invited_by.invitation_date}
                  </span>
                </div>
              </div>
              <ProfileGallery
                gallery={userSnapshots}
                isNewSession={true}
                oppos={userOpportunity}
              />
            </>
          ) : (
            <></>
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default ProfileModal;
