import React, {useState, useEffect} from 'react';
import {Button, Form} from 'react-bootstrap';
import {registerCompany} from '../../Constants/Images';
import Scrollbars from 'react-custom-scrollbars';
import {CustomModal} from '../../Components';
import { errorMessage } from '../../Utils/ToastMessages';

const SliderComponent2 = props => {
  const [ShowModal, setShowModal] = useState(false);
  const addServices = (item) => {
    if (props.selectedCompanyDescription.includes(item.id)) {
      return;
    }
    props.setSelectedCompanyDescription([item.id]);
    setCategories(item.categories);

    props.setIsCheckValue([]);
    props.setIsCheck([]);
  };
  const [tempCheck, setTempCheck] = useState([]);
  const [tempCheckValue, setTempCheckValue] = useState([]);
  const [isCheckValue, setIsCheckValue] = useState([]);
  const [isCheck, setIsCheck] = useState([]);
  const [catError, setCatError] = useState(false);
  const [categories, setCategories] = useState([]);
  const [isCheckAll, setIsCheckAll] = useState(false);
  const [redraw, setRedraw] = useState(false);

  const handleClick = e => {
    const {id, value, checked} = e.target;
    props.setIsCheck([...props.isCheck, id]);
    props.setIsCheckValue([...props.isCheckValue, value]);
    if (!checked) {
      props.setIsCheck(props.isCheck.filter(item => item !== id));
    }
    if (!checked) {
      props.setIsCheckValue(props.isCheckValue.filter(item => item !== value));
    }
  };
   useEffect(() => {
    setCategories(props.workSpaceTypes[0].categories);
  }, []);


  const openModal = () => {
    setTempCheck([...props.isCheck]);
    setTempCheckValue([...props.isCheckValue]);
    setShowModal(true);
  };

  // Handle temporary checkbox selections inside modal
  const handleTempClick = (e) => {
    const { id, value, checked } = e.target;
    if (checked) {
      setTempCheck([...tempCheck, id]);
      setTempCheckValue([...tempCheckValue, value]);
    } else {
      setTempCheck(tempCheck.filter((item) => item !== id));
      setTempCheckValue(tempCheckValue.filter((item) => item !== value));
    }
  };

  const handleSelectAllTemp = () => {
    if (tempCheck.length === categories.length) {
      setTempCheck([]); // Clear selections
      setTempCheckValue([]);
    } else {
      setTempCheck(categories.map((item) => item.id));
      setTempCheckValue(categories.map((item) => item.category_name));
    }
  };
  const handleSelect = () => {
    if (tempCheck.length > 2) {
      setShowModal(false);
    } else {
      errorMessage('At least 3 Categories are required')
    }

    // Save valid selections
    props.setIsCheck([...tempCheck]);
    props.setIsCheckValue([...tempCheckValue]);

 
  };

  const handleSelectAll = e => {
    props.setIsCheckAll(!props.isCheckAll);
    props.setIsCheck(categories.map(item => item.id));
    props.setIsCheckValue(categories.map(item => item.category_name));
    if (props.isCheckAll) {
      props.setIsCheck([]);
      props.setIsCheckValue([]);
    }
  };
 
  useEffect(() => {
    if (categories.length > 0) {
      if (props.isCheck.length === categories.length) {
        props.setIsCheckAll(true);
      } else {
        props.setIsCheckAll(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.isCheck]);

  const dotsArray = [1, 2, 3];

  
  return (
    <>
      <div className="slider-component create_company_form">
        {/* <div className="slider-component-img"> */}
        {/* {!props.hide &&  */}
        <div
          className="slider-component-img"
          style={{
            backgroundImage: `url(${registerCompany})`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
          }}>
          {/* <img src={registerCompany} alt="" /> */}

          <div className="slider-component-img-text">
            Welcome to the ROI Family!
          </div>
        </div>
        {/* } */}

        <div className="slider-component-content">
          <Scrollbars>
            <div className="slider-content ">
              <h1 className="mb-4 tell-us">Which best describes your company?</h1>

              <div className="d-flex gap-3 mt-3 mb-5">
                {dotsArray.map((dot, index) => (
                  <span
                    key={index}
                    className={`company_dots ${
                      props.currentStep === 2 && index === 1 ? 'bg_green' : ''
                    }  ${
                      props.currentStep === 2 && index === 0 ? 'as-link' : ''
                    }`}
                    onClick={() => {
                      
                      if (index === 0) {
                        props.setStep(1);
                      }
                    }}></span>
                ))}
              </div>

              <div className="input-container3 mt-5">
                {props.workSpaceTypes &&
                  Object.entries(props.workSpaceTypes).length > 0 &&
                  props.workSpaceTypes.map((item, index) => (
                    <div className="mb-3">
                      <div
                        className={`box mb-2  ${
                          props.selectedCompanyDescription.includes(item.id)
                            ? 'selected-box'
                            : ''
                        }`}
                        key={`b-item-${index}`}
                        onClick={() => {
                          addServices(item);
                        }}>
                        <div className="blank-box"></div>

                        <div className="box-content">
                          <h5>{item.name}</h5>
                          <p>{item.description}</p>
                          {/* <h5>Examples</h5>
                    <p>{item.examples}</p> */}
                        </div>
                      </div>

                      {props.selectedCompanyDescription.includes(item.id) && (
                        <div className="fieldset slct-catgris-fieldset">
                          <Form.Label>Select Categories</Form.Label>
                          <div className="d-flex align-items-center justify-content-between cutsom-control-menu">
                            <Form.Control
                              className="as-link truncate"
                              readOnly={'readOnly'}
                              // value={isCheckValue.length > 0 ? isCheckValue.join(', ') : "" }
                              value={
                                props.isCheck.length > 0 &&
                                props.isCheck.length === categories.length
                                  ? 'All'
                                  : props.isCheckValue.length > 0
                                  ? props.isCheckValue.join(', ')
                                  : ''
                              }
                              required
                              placeholder="Select Categories"
                              onClick={openModal}
                            />
                            <span className="drop-icon as-link"  onClick={openModal}>
                              <i class="fa-solid fa-chevron-down"></i>
                            </span>
                          </div>
                          {((props.isCheckValue.length === 0 &&
                            props.catError === true) ||
                            (props.isCheckValue.length < 3 &&
                              props.catError === true)) && (
                            <div className="text-danger textforerror">
                              {props.isCheck.length > 0
                                ? 'At least 3 Categories are required'
                                : 'Categories are required'}
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  ))}
              </div>
            </div>
          </Scrollbars>

          {ShowModal && (
            <CustomModal
              size="small"
              onClose={() => setShowModal(false)}
              className={'categories_modal'}
              paddingBottomZero={true}>
              <div className="edit-opportunity-modal edit-opportunity-slide-modal">
                <div className="filter-headings">
                  <h2>Select Categories</h2>
                </div>
                <div className="filter-wrapper filter-pad-bottom-wrap">
                  <div className="filter-category-list">
                    <ul style={{paddingBottom:'50px'}}>
                    <li className={tempCheck.length === categories.length ? 'active' : ''}>
                        <Form.Label>
                          All{' '}
                          <input
                            type="checkbox"
                            id="ALL"
                            name="ALL"
                            value="ALL"
                            onChange={handleSelectAllTemp}
                           checked={tempCheck.length === categories.length}
                          />{' '}
                          <span />
                        </Form.Label>
                      </li>
                      {Object.entries(categories).length > 0 &&
                        categories.map((item, index) => (
                          <li key={`sessionC-${index}`} className={tempCheck.includes(item.id) ? 'active' : ''}>
                            <Form.Label>
                              {item.category_name}
                              <input
                                type="checkbox"
                                id={item.id}
                                value={item.category_name}
                                onChange={handleTempClick}
                                checked={tempCheck.includes(item.id)}
                              />
                              <span />
                            </Form.Label>
                          </li>
                        ))}
                    </ul>
                  </div>
                </div>
                <div
                  className="bg_transparent text-center"
                  style={{bottom: '0px', paddingBottom: "10px"}}>
                  <Button
                    type="submit"
                    className="btn-dark mt-0"
                    onClick={handleSelect}>
                    Select
                  </Button>
                </div>
              </div>
            </CustomModal>
          )}
        </div>
      </div>
    </>
  );
};

export default SliderComponent2;
