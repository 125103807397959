import React, {useState, useEffect} from 'react';
import commonApi from '../../Services/CommonService';
import Moment from 'moment';
import Slider from 'react-slick';
import SlidingPanel from '../SlidingPanel';
import LoaderSpinner from '../Modals/LoaderSpinner';
import Scrollbars from 'react-custom-scrollbars';

const SnapShotDetails = props => {

 
  const [data, setData] = useState([]);
  const [images, setImages] = useState([]);
  const [showLoader, setshowLoader] = useState(false);

  const imgSliderSettings = {
    dots: true,
    arrows: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  const openInNewTab = url => {
    window.open(url, '_blank', 'noopener,noreferrer');
  };
  const date = new Date(data.date);

  useEffect(() => {
    detailAccomplishment();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const detailAccomplishment = () => {
    setshowLoader(true);
    var payLoad = {
      accomplishment_id: props.id,
    };
    commonApi
      .detail_accomplishment(payLoad)
      .then(res => {
        if (res.status === 200) {
          setImages(res.accomplishment_detail.images);
          setData(res.accomplishment_detail);
          setshowLoader(false);
        }
      })
      .catch(err => {
        
        return {type: 'error', message: err.message};
      });
  };
  

  return (
    <>
      {/* {showLoader && <LoaderModal show={showLoader} />} */}
      {showLoader && <LoaderSpinner />}
      {/* <span className="text-right" onClick={() => props.onClose()}>
        <i className="fas fa-times" />
      </span> */}
      {!showLoader && (
        <SlidingPanel hidePanel={() => props.onClose()}>
          <Scrollbars>
            <div className="serach-filter-wrapper">
              <div className="headings fix-width-forms">
                <h2>{data.title}</h2>
              </div>
              <div className="events-wrapper fix-width-forms">
                <div className="events-img-slider">
                  <Slider {...imgSliderSettings}>
                    {Object.entries(images).length > 0 ? (
                      Object.entries(images).length > 0 &&
                      images.map((item, index) => (
                        <img src={item} alt="" key={index} />
                      ))
                    ) : (
                      <>No Data</>
                    )}
                  </Slider>
                </div>

                <div className="events-icons-wrapper">
                  <div className="left-icons">
                    {data.website_url && (
                      <span
                        onClick={() => openInNewTab(data.website_url)}
                        className="icon-globe"></span>
                    )}
                  </div>
                </div>

                <div className="events-headings">
                  {/* <h2>{data.title}</h2> */}
                  <div className="d-flex align-items-center justify-content-between">
                    <span className="category-name">{data.category_name}</span>
                    {data.date !== '0000-00-00' && (
                      <span className="category-name">
                        {Moment(date).format('L')}
                      </span>
                    )}
                  </div>
                </div>

                <div className="events-desc-wrapper">
                  <p>{data.description}</p>

                  {data.location && (
                    <span className="event-address">
                      <i className="icon-map-pin" /> {data.location}
                    </span>
                  )}
                </div>
              </div>
            </div>
          </Scrollbars>
        </SlidingPanel>
      )}
    </>
  );
};
export default SnapShotDetails;
