import React, { useState } from 'react';
import {Button, Form} from 'react-bootstrap';
import { capitalizeFirst, fixName } from '../../Utils/commonUtils';
import commonApi from '../../Services/CommonService';
import { errorMessage } from '../../Utils/ToastMessages';

const PropertyPoc = (props) => {

    const {pocAliase, buttonRef, handle, validated, setValidated, setPocAliase, setDeleteItem, pocList, setPocList, setShowDeleteModal} = props;
    const [pocName, setPocName] = useState('')


    const get_all_team_for_project = value => {
      const payload = {search_query: value};
  
      commonApi
        .get_all_team_for_project(payload)
        .then(res => {
          if (res.status === 200) {
            setPocAliase(res.data);
          }
        })
        .catch(err => {
          
          return {type: 'error', message: err.message};
        });
    };

    const PocExists = (pocList, itemId) => {
        return pocList.some(customer => customer.id === itemId);
      };

      const handlePoc = item => {
        // const exist = customerExist(customersList, item.id)
        // console.log(exist,'===>exist')
        if (!PocExists(pocList, item.id)) {
          setPocList(prev => [...prev, item]);
          setPocName('');
          setPocAliase([]);
        } else {
          errorMessage('You have Already added this Poc');
        }
      };

  return (
    <div>
        
    <Form noValidate validated={validated} onSubmit={handle}>
        <div className="fieldset flex-1" style={{margin: '0px'}}>
          <div className="d-flex justify-content-between input-plus-icon-holder">
            <Form.Label controlid="validationCustom01">POC Name</Form.Label>
          </div>

          <div className="input-plus-icon-holder">
            <Form.Control
              name="phase"
              value={pocName}
              // pattern="^\S.*$"
              placeholder="Enter Name"
              onChange={e => {
                setPocName(e.target.value);
                get_all_team_for_project(e.target.value);
              }}
            />
          </div>
          <Form.Control.Feedback type="invalid">
            Poc name is required.
          </Form.Control.Feedback>
        </div>

      {pocName && pocAliase.length > 0 && (
        <ul className="custom-suggestions invoices_suggestion">
          {pocAliase.map(suggestion => (
            <li
              className="as-link"
              key={'suggestion' + suggestion.id}
              onClick={() => {
                handlePoc(suggestion);
              }}>
              {capitalizeFirst(
                suggestion.first_name + ' ' + suggestion.last_name,
              )}
            </li>
          ))}
        </ul>
      )}

      <div className="mb-3">
        {Object.entries(pocList).length > 0 &&
          pocList.map((item, index) => (
            <div class="d-flex align-items-center phases_item mt-3" key={index}>
              <div className="customer_name flex-1">
                <div className="alias_name">
                  {fixName(item.first_name, item.last_name)}
                </div>

                <span
                  class="as-link  cross-icon-field"
                  onClick={() => {
                    setShowDeleteModal(true)
                    setDeleteItem(item);
                  }}>
                  <i
                    class="fas fa-times text-secondary"
                    style={{fontSize: '1.5rem'}}></i>
                </span>
              </div>
            </div>
          ))}
      </div>
      
      <Button
            type="submit"
            style={{display: 'none'}}
            ref={buttonRef}></Button>
      </Form>
    </div>
  );
};

export default PropertyPoc;
