import React, {useEffect, useState} from 'react';
import {Button, Dropdown, Form} from 'react-bootstrap';
import {errorMessage, successMessage} from '../../Utils/ToastMessages';
import commonApi from '../../Services/CommonService';
import {emergencyRelations, formatPhoneNumber} from '../../Utils/commonUtils';
import Input from '../Input/Input';
import EditPencilSvg from '../../Svg/EditPencilSvg';

const EmergencyConatct = ({
  userData,
}) => {
  const [emergencyContact, setEmergencyConatct] = useState({
    name: '',
    phoneOrEmail: '',
    // phoneNo: '',
    role: '',
  });
  const [index, setIndex] = useState(null);
  const [validated, setValidated] = useState(false);
  const [contactRelation, setContactRelation] = useState([]);
  const [emergencyContactListing, setEmergencyContactListing] = useState([]);
  const [showContactDetails, setShowContactDetails] = useState(true);

  const handleChange = e => {
    setEmergencyConatct({
      ...emergencyContact,
      [e.target.name]: e.target.value,
    });
  };

  const handleEdit = (item, index, type) => {
      setEmergencyConatct({
        name: item.name,
        phoneOrEmail: item.phoneOrEmail,
        // phoneNo: item.phoneNo,
        role: item.role,
      });
    setIndex(index);
  };

  const handleDelete = (item, type) => {
      const updatedData = emergencyContact.filter(
        (_, i) => i !== emergencyContact.indexOf(item),
      );
      setEmergencyConatct(updatedData);
  };

  const handleSubmit = event => {
    const form = event.currentTarget;

    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      setValidated(true);
    } else {
      event.preventDefault();
      setValidated(false);
      add_emergency_contact();
    }
  };

  const reset = () => {
    setEmergencyConatct({
      name: '',
      phoneOrEmail: '',
      role: emergencyRelations?.[0]?.id || ''
    });
  };

  const add_emergency_contact = () => {
    // let phoneFormat;

    // if (emergencyContact.phoneNo) {
    //   phoneFormat = formatPhoneNumber(emergencyContact.phoneNo);
    // }
    const payload = {
      first_name: emergencyContact.name,
      relation: emergencyContact.role,
      phone_email: emergencyContact.phoneOrEmail ? emergencyContact.phoneOrEmail : '',
      user_id: userData?.data?.id,
      // contact_id: userData?.data?.id,
    };

    commonApi
      .add_emergency_contact(payload)
      .then(res => {
        if (res.status === 200) {
          //   resePoc();
          successMessage(res.message);
          reset()
          get_emergency_contacts();
        }
      })
      .catch(err => {
        return {type: 'error', message: err.message};
      });
  };

  const get_emergency_contacts = () => {
    const payload = {user_id: userData?.data?.id};

    commonApi
      .get_emergency_contacts(payload)
      .then(res => {
        if (res.status) {
          setEmergencyContactListing(res.data);
         
        }
      })
      .catch(err => {
        return {type: 'error', message: err.message};
      });
  };

  const getContactRelationTypes = () => {
    commonApi
      .get_contact_relation_types()
      .then(res => {
        if (res.data.length > 0) {
          setContactRelation(res.data);
          setEmergencyConatct({
            ...emergencyContact,
            role: res.data[0].id,
          });
        }
      })
      .catch(err => {
        return {type: 'error', message: err.message};
      });
  };

  useEffect(() => {
    get_emergency_contacts();
    setEmergencyConatct({
      ...emergencyContact,
      role: emergencyRelations[0].id,
    });
    // getContactRelationTypes();
  }, []);

  return (
    <>
      <Form
        style={{paddingBottom: '0px'}}
        className="send-refferal-form-input-holder"
        noValidate
        validated={validated}
        onSubmit={handleSubmit}>
        <div
          style={{backgroundColor: 'white'}}
          className="rounded-md shadow-md mb-2">
          <div
            className="flex justify-between items-center p-3 cursor-pointer"
            onClick={() => setShowContactDetails(!showContactDetails)}>
            <h2 className="text-base font-semibold">Add Contact</h2>
          </div>
          <div
            className={`overflow-hidden overflow-y-auto custom-scrollbar transition-all duration-300 ${
              showContactDetails ? 'max-h-screen' : 'max-h-0'
            }`}>
            <hr class="border-t border-gray-300 pb-3 ml-[28px] mr-[28px]"></hr>
            <div className="p-4 pt-0 space-y-2">
              <Input
                type="text"
                label={''}
                name={'name'}
                value={emergencyContact.name}
                onChange={handleChange}
                placeholder={'Name'}
                feedback={'Name is required.'}
                required={true}
              />

              <Input
                type="text"
                label={''}
                name={'phoneOrEmail'}
                value={emergencyContact.phoneOrEmail}
                onChange={handleChange}
                placeholder={'Phone or email.'}
                // feedback={emergencyContact.email ? 'Invalid email' : 'Email is required'}
                required={false}
              />

              {/* <Input
                type="number"
                label={''}
                name={'phoneNo'}
                value={emergencyContact.phoneNo}
                onChange={handleChange}
                placeholder={'Phone number'}
                feedback={emergencyContact.phoneNo !== '' ? 'Invalid phone number' : 'Phone number is required'}
                required={false}
              /> */}

              <div className="roi-form-dropdown ">
                <div className="select-wrapper">
                  <Dropdown
                    onSelect={(eventKey, event) => {
                      setEmergencyConatct({
                        ...emergencyContact,
                        role: eventKey,
                      });
                    }}
                    drop="down">
                    <Dropdown.Toggle variant="primary">
                      {emergencyContact.role
                        ? emergencyRelations.find(
                            item => item.id === emergencyContact.role,
                          )?.title
                        : 'Select Relation'}
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      {emergencyRelations.map((item, index) => (
                        <Dropdown.Item
                          key={`st-${index}`}
                          eventKey={item.id}
                          active={item.id === emergencyContact.role}>
                          {item.title}
                        </Dropdown.Item>
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>

              <div className="flex justify-center items-center mt-3">
                <button
                  type="submit"
                  className="bg-black text-white px-4 py-2 rounded-md">
                  Add Contact
                </button>
              </div>
            </div>
          </div>
        </div>
      </Form>

      {emergencyContactListing?.length > 0 && <h1 style={{margin: '13px 0px'}}>Contacts</h1>}
      {emergencyContactListing?.length > 0 && (
        <>
          {emergencyContactListing?.map((item, index) => (
            <div key={index} className="mt-2 flex justify-between items-center bg-white p-3 rounded-lg ">
            <div className="flex items-center gap-2">
              <div>
                <div className="text-sm font-medium truncate_2_lines">{item.first_name}</div>
                <div className="text-xs text-gray-500">{item.phone_email}</div>
              </div>
            </div>
            {/* <div className="flex items-center gap-2">
              <p className="as-link">
              <EditPencilSvg fill={'#a39f8d'} />
              </p>
              <p className="as-link">
                <span className="poc_delete as-link">
                  <i className="fa-solid fa-trash-can" />
                </span>
              </p>
            </div> */}
          </div>
          ))}
        </>
      )}
    </>
  );
};

export default EmergencyConatct;
