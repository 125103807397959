import React from 'react';
import {useState, useRef, useEffect} from 'react';
import {Button, Form, Dropdown} from 'react-bootstrap';
import commonApi from '../../Services/CommonService';
import {
  capitalizeFirst,
  formatMinutesTime,
  checkSubscriptionType,
  formatValueWithTwoDecimals,
  calculateTotal,
  calculateSubtotal,
  calculateItemSubtotal,
  extractFirstLevelAndFrequency,
  addProductQuantity,
  checkProductQuantity,
  checkProductQuantityInStandard,
  updateOutOfStockStatus,
} from '../../Utils/commonUtils';
import {errorMessage} from '../../Utils/ToastMessages';
import NorecordFound from '../NoRecord/NorecordFound';
import {NoOfferingsFound} from '../../Utils/NoRecordConstant';
import InvoiceProduct from './InvoiceProduct';
import {labels} from '../../Constants/Translations';
import {selected_lang} from '../../Utils/env';
import EditPencilSvg from '../../Svg/EditPencilSvg';

const ComparisonItem = ({
  type,
  item,
  ItemIndex,
  comparisonList,
  setComparisonList,
  comparisonDraft,
  selectedItems,
  redraw,
  setShowComponent,
  setItemToUpdate,
  handleUpdateItem,
  deleteCatalogItem,
  setDeleteCatalogItem,
  checkPermesionOfCreate,
  outOfStockItems,
  setOutOfStockItems,
  builders,
  expensesList,
}) => {
  const [estimateService, setEstimateService] = useState('');
  const [showSearchProduct, setShowSearchProduct] = useState('');
  const [showAll, setShowAll] = useState(true);

  const [groupName, setGroupName] = useState(item.group_name);
  const [addOn, setAddOn] = useState(item.add_on);
  const [productDetail, setProductDetail] = useState([]);
  const [deleteCatalog, setDeleteCatalog] = useState({});
  const [isExpandedBilling, setIsExpandedBilling] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(
    selectedItems.length > 0 ? selectedItems : [],
  );
  const [note, setNote] = useState(
    selectedItems.length > 0 ? item.customer_note : '',
  );
  const [noResults, setNoResults] = useState(false);
  // const [outOfStockItems, setOutOfStockItems] = useState([]);

  const dropdownRef = useRef(null);
  const inputRef = useRef(null);
  const comparisonListRef = useRef(comparisonList);
  let hasDoubleClick = false;
  let timeoutId;
  // const [addUser, setAddUser] = useState('');

  // const [name, setName] = useState('');
  // const [subscription, setSubscription] = useState(item.subscripton  ? item.subscripton : '0.00');
  // const [subtotal, setSubtotal] = useState(item.subscripton ? item.subscripton : '0.00');
  // const [tax, setTax] = useState(item.tax  ? item.tax : '0.00');
  // const [discount, setDiscount] = useState(item.discount ? item.discount : '0.00');
  // const [shipping, setShipping] = useState(item.shipping ? item.shipping : '0.00');
  // const [total, setTotal] = useState(item.total ? item.total : '0.00');
  // const [paymentSchedule, setPaymentSchedule] = useState('');

  const [invoiceData, setInVoiceData] = useState({
    name: '',
    subscripton: item.subscripton ? item.subscripton : '0.00',
    subtotal: item.subscripton ? item.subscripton : '0.00',
    tax: item.tax ? item.tax : '0.00',
    discount: item.discount ? item.discount : '0.00',
    shipping: item.shipping ? item.shipping : '0.00',
    total: item.total ? item.total : '0.00',
    payment_Schedule: '',
  });

  const taxRef = useRef(null);
  const discountRef = useRef(null);
  const shippingRef = useRef(null);
  const [width, setWidth] = useState(34);
  const [shippingWidth, setShippingW] = useState(34);
  const [discountWidth, setDiscountW] = useState(34);
  const [moreOptions, setMoreOptions] = useState(false);
  const [produtAvailability, setProductAvailabilty] = useState([]);
  const [catalogIds, setCatalogIds] = useState(
    item.catalog_ids ? item.catalog_ids.split(',') : [],
  );
  const offeringButtonRef = useRef(null);

  let StandardItem = item;
  const handleInputChange = e => {
    const {name, value} = e.target;

    if (parseFloat(value) > 1000000000000000) {
      return;
    }

    const regex = /^(?!0\d)\d*(\.\d{0,2})?$/;
    if (value === '' || regex.test(value) || value === '0') {
      if (name === 'discount') {
        const discountValue = parseFloat(value);
        const subtotalValue = parseFloat(invoiceData.subtotal);
        const taxValue = parseFloat(invoiceData.tax);
        const shippingValue = parseFloat(invoiceData.shipping);

        if (discountValue >= subtotalValue + taxValue + shippingValue) {
          errorMessage('Discount cannot exceed total.');
          return;
        }
      }
      setInVoiceData({...invoiceData, [name]: value === '' ? '' : value});
    }
  };

  const handleBlur = event => {
    const {name, value} = event.target;
    let parsedValue = parseFloat(value.trim());
    let formattedValue = isNaN(parsedValue) ? '0.00' : parsedValue.toFixed(2);

    setInVoiceData({...invoiceData, [name]: formattedValue});
  };

  useEffect(() => {
    if (selectedProduct.length > 0) {
      setInVoiceData({
        ...invoiceData,
        total:
          Number(invoiceData.subtotal) +
          Number(invoiceData.tax) +
          Number(invoiceData.shipping) -
          Number(invoiceData.discount),
      });
    }
  }, [invoiceData.tax, invoiceData.shipping, invoiceData.discount]);

  useEffect(() => {
    if (taxRef.current) {
      setWidth(taxRef.current.offsetWidth + 2);
    }
    if (discountRef.current) {
      setDiscountW(discountRef.current.offsetWidth + 2);
    }
    if (shippingRef.current) {
      setShippingW(shippingRef.current.offsetWidth + 2);
    }
  }, [invoiceData, moreOptions]);

  const get_catalogs_for_invoices = value => {
    const payload = {search_query: value, type: 'web'};
    commonApi
      .get_catalogs_for_invoices(payload)
      .then(res => {
        if (res.status === 200) {
          // if (!value || value === '') {
          //   setProductDetail([]);
          // } else {

          if (res.catalog_data.length === 0) {
            setNoResults(true);
          } else {
            setNoResults(false);
          }
          if (value === '') {
            setProductDetail([]);
          } else {
            setProductDetail(res.catalog_data);
          }
          // }
        }
      })
      .catch(err => {
        
        return {type: 'error', message: err.message};
      });
  };

  const objectValues = item => {
    let outOfStockCount = 0;
    if (StandardItem.sub_type === 'standard') {
      const processItem = miniItem => {
        let checkQuantity = checkProductQuantityInStandard(
          comparisonList,
          StandardItem,
          miniItem,
        );

        if (checkQuantity) {
          setSelectedProduct(prev => [
            ...prev,
            {
              title: miniItem.title ? miniItem.title : '',
              catalog_id: miniItem.id ? miniItem.id : '',
              taxes_fee: miniItem.taxes_fee
                ? formatValueWithTwoDecimals(miniItem.taxes_fee)
                : '0.00',
              discount: miniItem.discount
                ? formatValueWithTwoDecimals(miniItem.discount)
                : '0.00',
              shipping_handling: miniItem.shipping_handling
                ? formatValueWithTwoDecimals(miniItem.shipping_handling)
                : '0.00',
              late_fee: miniItem.late_fee
                ? formatValueWithTwoDecimals(miniItem.late_fee)
                : '0.00',
              cost: miniItem.cost ? miniItem.cost : '0.00',
              duration: miniItem.duration ? miniItem.duration : '',
              grace_period: miniItem.grace_period ? miniItem.grace_period : '',
              catalog_type: miniItem.catalog_type ? miniItem.catalog_type : '',
              subscription_month: miniItem.subscription
                ? miniItem.subscription
                : '',
              total: calculateItemSubtotal(miniItem),
              payment_milestones:
                miniItem.catalog_type === 'membership'
                  ? extractFirstLevelAndFrequency(miniItem)?.frequency
                      ?.payment_milestones || []
                  : miniItem.payment_milestones &&
                    miniItem.payment_milestones.length > 0
                  ? miniItem.payment_milestones
                  : [],
              payment_type:
                miniItem.catalog_type === 'membership'
                  ? extractFirstLevelAndFrequency(miniItem)?.frequency
                      ?.payment_type || []
                  : miniItem.payment_type && miniItem.payment_type.length > 0
                  ? miniItem.payment_type
                  : [],
              // payment_milestone_id: miniItem.payment_milestones && miniItem.payment_milestones.length > 0 ? [miniItem.payment_milestones[0].id] :'',
              ...(miniItem.catalog_type === 'membership' &&
              miniItem.membership_levels.length > 0
                ? {
                    level: extractFirstLevelAndFrequency(miniItem),
                    membership_levels:
                      miniItem.catalog_type === 'membership' &&
                      miniItem.membership_levels.length > 0
                        ? miniItem.membership_levels
                        : [],
                  }
                : {}),
              ...(miniItem.catalog_type === 'product' && {
                out_of_stock: false,
              }),

              ...(miniItem.catalog_type === 'product' && {
                left_item: miniItem.left_item,
              }),
              ...(miniItem.catalog_type === 'product' && {
                quantity: item.quantity,
              }),
              ...(miniItem.catalog_type === 'product' && {
                total_used: item.total_used,
              }),
              // membership_levels : miniItem.catalog_type === 'membership' && miniItem.membership_levels.length > 0 ?  miniItem.membership_levels : []
            },
          ]);
          setCatalogIds(prev => [...prev, miniItem.id]);
          if (miniItem.catalog_type === 'product') {
            const productsQuantity = addProductQuantity(
              miniItem.id,
              produtAvailability,
            );
            setProductAvailabilty(productsQuantity);
          }
          setShowSearchProduct('');
        } else {
          outOfStockCount++;
        }
      };
      if (item.catalog_details && item.catalog_details.length > 0) {
        // setSelectedProduct([...selectedProduct, ...item.catalog_details]);
        item.catalog_details.forEach((miniItem, index) => {
          // setSelectedProduct(prev => [...prev, miniItem]);

          processItem(miniItem);

          setInVoiceData(prevData => ({
            ...prevData,
            subscripton: Number(prevData.subscripton) + Number(miniItem.cost),
            subtotal: Number(prevData.subtotal) + Number(miniItem.cost),
            total: Number(prevData.total) + Number(miniItem.cost),
          }));
        });

        // setShowSearchProduct('');
      } else {
        // setSelectedProduct(prev => [...prev, item]);

        let checkQuantity = checkProductQuantityInStandard(
          comparisonList,
          StandardItem,
          item,
        );

       
        if (checkQuantity) {
          setSelectedProduct(prev => [
            ...prev,
            {
              title: item.title ? item.title : '',
              catalog_id: item.id ? item.id : '',
              taxes_fee: item.taxes_fee
                ? formatValueWithTwoDecimals(item.taxes_fee)
                : '0.00',
              discount: item.discount
                ? formatValueWithTwoDecimals(item.discount)
                : '0.00',
              shipping_handling: item.shipping_handling
                ? formatValueWithTwoDecimals(item.shipping_handling)
                : '0.00',
              late_fee: item.late_fee
                ? formatValueWithTwoDecimals(item.late_fee)
                : '0.00',
              cost: item.cost ? item.cost : '0.00',
              duration: item.duration ? item.duration : '',
              grace_period: item.grace_period ? item.grace_period : '',
              catalog_type: item.catalog_type ? item.catalog_type : '',
              subscription_month: item.subscription ? item.subscription : '',
              total: calculateItemSubtotal(item),
              payment_milestones:
                item.payment_milestones && item.payment_milestones.length > 0
                  ? item.payment_milestones
                  : [],
              payment_type:
                item.payment_type && item.payment_type.length > 0
                  ? item.payment_type
                  : [],
              // payment_milestone_id: item.payment_milestones && item.payment_milestones.length > 0 ? [item.payment_milestones[0].id] :'',
              membership_levels:
                item.catalog_type === 'membership' &&
                item.membership_levels.length > 0
                  ? item.membership_levels
                  : [],
              ...(item.catalog_type === 'product' && {
                out_of_stock: false,
              }),

              ...(item.catalog_type === 'product' && {
                left_item: item.left_item,
              }),
              ...(item.catalog_type === 'product' && {
                quantity: item.quantity,
              }),
              ...(item.catalog_type === 'product' && {
                total_used: item.total_used,
              }),
            },
          ]);
          setCatalogIds(prev => [...prev, item.id]);
          if (item.catalog_type === 'product') {
            const productsQuantity = addProductQuantity(
              item.id,
              produtAvailability,
            );
            setProductAvailabilty(productsQuantity);
          }
          setShowSearchProduct('');
          get_catalogs_for_invoices('');
        } else {
          outOfStockCount++;
        }

        setInVoiceData({
          ...invoiceData,
          subscripton: Number(invoiceData.subscripton) + Number(item.cost),
          subtotal: Number(invoiceData.subtotal) + Number(item.cost),
          total: Number(invoiceData.total) + Number(item.cost),
        });
      }

      if (outOfStockCount > 1) {
        errorMessage(
          `${outOfStockCount} items ${labels.OUT_OF_STOCK[selected_lang]}`,
        );
      }
      if (outOfStockCount === 1) {
        errorMessage(`${labels.OUT_OF_STOCK[selected_lang]}`);
      }
    } else {
      const processItem = miniItem => {
        let checkQuantity = checkProductQuantity(produtAvailability, miniItem);

        if (checkQuantity) {
          setSelectedProduct(prev => [
            ...prev,
            {
              title: miniItem.title ? miniItem.title : '',
              catalog_id: miniItem.id ? miniItem.id : '',
              taxes_fee: miniItem.taxes_fee
                ? formatValueWithTwoDecimals(miniItem.taxes_fee)
                : '0.00',
              discount: miniItem.discount
                ? formatValueWithTwoDecimals(miniItem.discount)
                : '0.00',
              shipping_handling: miniItem.shipping_handling
                ? formatValueWithTwoDecimals(miniItem.shipping_handling)
                : '0.00',
              late_fee: miniItem.late_fee
                ? formatValueWithTwoDecimals(miniItem.late_fee)
                : '0.00',
              cost: miniItem.cost ? miniItem.cost : '0.00',
              duration: miniItem.duration ? miniItem.duration : '',
              grace_period: miniItem.grace_period ? miniItem.grace_period : '',
              catalog_type: miniItem.catalog_type ? miniItem.catalog_type : '',
              subscription_month: miniItem.subscription
                ? miniItem.subscription
                : '',
              total: calculateItemSubtotal(miniItem),
              payment_milestones:
                miniItem.catalog_type === 'membership'
                  ? extractFirstLevelAndFrequency(miniItem)?.frequency
                      ?.payment_milestones || []
                  : miniItem.payment_milestones &&
                    miniItem.payment_milestones.length > 0
                  ? miniItem.payment_milestones
                  : [],
              payment_type:
                miniItem.catalog_type === 'membership'
                  ? extractFirstLevelAndFrequency(miniItem)?.frequency
                      ?.payment_type || []
                  : miniItem.payment_type && miniItem.payment_type.length > 0
                  ? miniItem.payment_type
                  : [],
              // payment_milestone_id: miniItem.payment_milestones && miniItem.payment_milestones.length > 0 ? [miniItem.payment_milestones[0].id] :'',
              ...(miniItem.catalog_type === 'membership' &&
              miniItem.membership_levels.length > 0
                ? {
                    level: extractFirstLevelAndFrequency(miniItem),
                    membership_levels:
                      miniItem.catalog_type === 'membership' &&
                      miniItem.membership_levels.length > 0
                        ? miniItem.membership_levels
                        : [],
                  }
                : {}),
              ...(miniItem.catalog_type === 'product' && {
                out_of_stock: false,
              }),
              ...(miniItem.catalog_type === 'product' && {
                left_item: miniItem.left_item,
              }),
              ...(miniItem.catalog_type === 'product' && {
                quantity: item.quantity,
              }),
              ...(miniItem.catalog_type === 'product' && {
                total_used: item.total_used,
              }),
              // membership_levels : miniItem.catalog_type === 'membership' && miniItem.membership_levels.length > 0 ?  miniItem.membership_levels : []
            },
          ]);
          setCatalogIds(prev => [...prev, miniItem.id]);
          if (miniItem.catalog_type === 'product') {
            const productsQuantity = addProductQuantity(
              miniItem.id,
              produtAvailability,
            );
            setProductAvailabilty(productsQuantity);
          }
          setShowSearchProduct('');
        } else {
          outOfStockCount++;
        }
      };
      if (item.catalog_details && item.catalog_details.length > 0) {
        // setSelectedProduct([...selectedProduct, ...item.catalog_details]);
        item.catalog_details.forEach((miniItem, index) => {
          // setSelectedProduct(prev => [...prev, miniItem]);
          processItem(miniItem);

          setInVoiceData(prevData => ({
            ...prevData,
            subscripton: Number(prevData.subscripton) + Number(miniItem.cost),
            subtotal: Number(prevData.subtotal) + Number(miniItem.cost),
            total: Number(prevData.total) + Number(miniItem.cost),
          }));
        });

        // setShowSearchProduct('');
      } else {
        // setSelectedProduct(prev => [...prev, item]);

        let checkQuantity = checkProductQuantity(produtAvailability, item);
        if (checkQuantity) {
          setSelectedProduct(prev => [
            ...prev,
            {
              title: item.title ? item.title : '',
              catalog_id: item.id ? item.id : '',
              taxes_fee: item.taxes_fee
                ? formatValueWithTwoDecimals(item.taxes_fee)
                : '0.00',
              discount: item.discount
                ? formatValueWithTwoDecimals(item.discount)
                : '0.00',
              shipping_handling: item.shipping_handling
                ? formatValueWithTwoDecimals(item.shipping_handling)
                : '0.00',
              late_fee: item.late_fee
                ? formatValueWithTwoDecimals(item.late_fee)
                : '0.00',
              cost: item.cost ? item.cost : '0.00',
              duration: item.duration ? item.duration : '',
              grace_period: item.grace_period ? item.grace_period : '',
              catalog_type: item.catalog_type ? item.catalog_type : '',
              subscription_month: item.subscription ? item.subscription : '',
              total: calculateItemSubtotal(item),
              payment_milestones:
                item.payment_milestones && item.payment_milestones.length > 0
                  ? item.payment_milestones
                  : [],
              payment_type:
                item.payment_type && item.payment_type.length > 0
                  ? item.payment_type
                  : [],
              // payment_milestone_id: item.payment_milestones && item.payment_milestones.length > 0 ? [item.payment_milestones[0].id] :'',
              membership_levels:
                item.catalog_type === 'membership' &&
                item.membership_levels.length > 0
                  ? item.membership_levels
                  : [],
              ...(item.catalog_type === 'product' && {
                out_of_stock: false,
              }),
              ...(item.catalog_type === 'product' && {
                left_item: item.left_item,
              }),
              ...(item.catalog_type === 'product' && {
                quantity: item.quantity,
              }),
              ...(item.catalog_type === 'product' && {
                total_used: item.total_used,
              }),
            },
          ]);
          setCatalogIds(prev => [...prev, item.id]);
          if (item.catalog_type === 'product') {
            const productsQuantity = addProductQuantity(
              item.id,
              produtAvailability,
            );
            setProductAvailabilty(productsQuantity);
          }
          setShowSearchProduct('');
          get_catalogs_for_invoices('');
        } else {
          outOfStockCount++;
        }

        setInVoiceData({
          ...invoiceData,
          subscripton: Number(invoiceData.subscripton) + Number(item.cost),
          subtotal: Number(invoiceData.subtotal) + Number(item.cost),
          total: Number(invoiceData.total) + Number(item.cost),
        });
      }

      if (outOfStockCount > 1) {
        errorMessage(
          `${outOfStockCount} items ${labels.OUT_OF_STOCK[selected_lang]}`,
        );
      }
      if (outOfStockCount === 1) {
        errorMessage(`${labels.OUT_OF_STOCK[selected_lang]}`);
      }
    }
  };

  const removeProduct = (item, itemIndex) => {
    
    setSelectedProduct(previous =>
      previous.filter((_, index) => index !== itemIndex),
    );

    // const updatedProducts = selectedProduct.filter((_, index) => index !== itemIndex);

    // const updateItems = updateOutOfStockStatus(updatedProducts,showErrorFromApi)
    // setSelectedProduct(updateItems)

    setCatalogIds(previous =>
      previous.filter((_, index) => index !== itemIndex),
    );

    setInVoiceData({
      ...invoiceData,
      subscripton: Number(invoiceData.subscripton) - Number(item.cost),
      subtotal: Number(invoiceData.subtotal) - Number(item.cost),
      total: Number(invoiceData.total) - Number(item.cost),
    });
    setDeleteCatalog({});
    setDeleteCatalogItem(false);
  };

  const removeMileStone = (itemIndex, mileStoneIndex) => {
    const newSelectedProduct = [...selectedProduct];
    const catalogItem = newSelectedProduct[itemIndex];
    catalogItem.payment_milestones.splice(mileStoneIndex, 1);

    setSelectedProduct(newSelectedProduct);
    setDeleteCatalog({});
    setDeleteCatalogItem(false);
  };

  function formatNumberWithTwoDecimals(number) {
    const roundedNumber = Number(number).toFixed(2);

    return roundedNumber.toString();
  }

  // const showOutOfStockError = (item, itemIndex) => {
  //   const newData = [...outOfStockItems];

  //   let itemToUpdate = outOfStockItems.find(obj => obj.id == item.catalog_id);

  //   if (itemToUpdate) {
  //     let allowedIndexs = itemToUpdate.allowed ? itemToUpdate.allowed : [];
  //     let errorIndexs = itemToUpdate.not_allowed
  //       ? itemToUpdate.not_allowed
  //       : [];

  //     const quantity = builders.length > 0 ? builders.length : 1;
  //     // const quantity = 1;
  //     itemToUpdate.total_used = Number(itemToUpdate.total_used) + quantity;
  //     itemToUpdate.item_left = Number(itemToUpdate.item_left) - quantity;

  //     if (Number(itemToUpdate.item_left) >= 0) {
  //       const index = outOfStockItems.findIndex(
  //         obj => obj.id == item.catalog_id,
  //       );
  //       newData[index] = itemToUpdate;

  //       allowedIndexs.push(itemIndex);
  //       itemToUpdate.allowed = allowedIndexs;

  //       setOutOfStockItems(newData);
  //     } else if (
  //       !allowedIndexs.includes(itemIndex) &&
  //       !errorIndexs.includes(itemIndex)
  //     ) {
  //       const index = outOfStockItems.findIndex(
  //         obj => obj.id == item.catalog_id,
  //       );
  //       newData[index] = itemToUpdate;

  //       errorIndexs.push(itemIndex);
  //       itemToUpdate.not_allowed = errorIndexs;

  //       setOutOfStockItems(newData);
  //     }

  //     return itemToUpdate;
  //   } else {
  //     return {not_allowed: []};
  //   }
  // };

  const showOutOfStockError = (item, itemIndex) => {
    const newData = [...outOfStockItems];

    let itemToUpdate = outOfStockItems.find(
      obj => obj.id === item.catalog_id && obj.itemIndex === ItemIndex,
    );

    if (itemToUpdate && itemToUpdate.itemIndex === ItemIndex) {
      let allowedIndexs = itemToUpdate.allowed ? itemToUpdate.allowed : [];
      let errorIndexs = itemToUpdate.not_allowed
        ? itemToUpdate.not_allowed
        : [];
      const quantity = builders.length > 0 ? builders.length : 1;

      if (Number(itemToUpdate.item_left) > 0) {
        itemToUpdate.total_used = Number(itemToUpdate.total_used) + quantity;
        itemToUpdate.item_left = Number(itemToUpdate.item_left) - quantity;
        allowedIndexs.push(itemIndex);
        itemToUpdate.allowed = allowedIndexs;
        const index = outOfStockItems.findIndex(
          obj => obj.id == item.catalog_id && obj.itemIndex === ItemIndex,
        );
        newData[index] = itemToUpdate;
        setOutOfStockItems(newData);
      } else if (
        Number(itemToUpdate.item_left) == 0 &&
        !allowedIndexs.includes(itemIndex) &&
        !errorIndexs.includes(itemIndex)
      ) {
        errorIndexs.push(itemIndex);
        itemToUpdate.not_allowed = errorIndexs;
        const index = outOfStockItems.findIndex(
          obj => obj.id == item.catalog_id && obj.itemIndex === ItemIndex,
        );
        newData[index] = itemToUpdate;
        setOutOfStockItems(newData);
      }

      return itemToUpdate;
    } else {
      return {not_allowed: []};
    }
  };

  useEffect(() => {
    

    setGroupName(item.group_name);

    setInVoiceData({
      name: '',
      subscripton: item.subscripton ? item.subscripton : '0.00',
      subtotal: item.subscripton ? item.subscripton : '0.00',
      tax: item.tax ? item.tax : '0.00',
      discount: item.discount ? item.discount : '0.00',
      shipping: item.shipping ? item.shipping : '0.00',
      total: item.total ? item.total : '0.00',
      payment_Schedule: '',
    });

    const updatedArray = calculateSubtotal(
      selectedItems.length > 0 && selectedItems,
    );
    setSelectedProduct(updatedArray.length > 0 ? updatedArray : []);
    setNote(selectedItems.length > 0 ? item.customer_note : '');
    setCatalogIds(selectedItems.length > 0 ? item?.catalog_ids?.split(',') : []);
    setShowAll(true);
  }, [redraw]);

  useEffect(() => {
    // const filterOutLevels = (catalogDetails) => {
    //   return catalogDetails.map(detail => {

    //     const newDetail = { ...detail };

    //     delete newDetail.membership_levels;

    //     return newDetail;
    //   });
    // };

    // const filteredCatalogDetails = filterOutLevels(selectedProduct);

    if (type === 'standard') {
      const Total = calculateTotal(selectedProduct);

      const updatedGroup = comparisonList.map((item, index) => {
        if (index === ItemIndex) {
          return {
            ...item,
            group_name: groupName,
            add_on: addOn,
            catalog_ids: catalogIds.toString(','),
            customer_note: note,
            catalog_details: selectedProduct,
            total: Total ? Total.toString() : '0.00',
            // ...invoiceData,
          };
        }
        return item;
      });

      setComparisonList(updatedGroup);
    } else {
      const Total = calculateTotal(selectedProduct);
      const updatedGroup = comparisonList.map((item, index) => {
        if (index === ItemIndex) {
          return {
            ...item,
            group_name: groupName,
            catalog_ids: catalogIds ? catalogIds.toString(',') : '',
            customer_note: note,
            catalog_details: selectedProduct,
            total: Total ? Total.toString() : '0.00',
          };
        }
        return item;
      });
      setComparisonList(updatedGroup);
    }
    // }
  }, [selectedProduct, invoiceData, groupName, addOn, note, catalogIds]);

  useEffect(() => {
   
    if (deleteCatalogItem && deleteCatalog.hasOwnProperty('mileStoneIndex')) {
      removeMileStone(deleteCatalog.itemIndex, deleteCatalog.mileStoneIndex);
    } else if (deleteCatalogItem && Object.keys(deleteCatalog).length > 0) {
      removeProduct(deleteCatalog.item, deleteCatalog.index);
    }
  }, [deleteCatalogItem]);

  const singleClickMethod = () => {
    setShowAll(!showAll);
  };

  const doubleClickMethod = () => {};

  const onClickHandler = event => {
    event.preventDefault();
    event.stopPropagation();

    clearTimeout(timeoutId);

    timeoutId = setTimeout(() => {
      if (hasDoubleClick) {
        hasDoubleClick = false;
        return;
      }

      setShowAll(!showAll);
    }, 200);
  };

  const onDblClickHandler = (type, Item, index) => {
    hasDoubleClick = true;

    handleUpdateItem(type, Item, index);
  };

  useEffect(() => {}, []);

  const handleClickOutside = event => {
    if (
      inputRef.current &&
      !inputRef.current.contains(event.target) &&
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target) &&
      offeringButtonRef.current &&
      !offeringButtonRef.current.contains(event.target)
    ) {
      setShowSearchProduct('');
      setProductDetail([]);
      setNoResults(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, [showSearchProduct]);

  
  

  return (
    <div className="billing-detail mb-3">
      {!type && (
        <div
        style={{display: 'flex', justifyContent: 'space-between'}}
          className="pt-2 pb-2 as-link"
          onClick={onClickHandler}>
          <h1 className="mb-0">{item.group_name}</h1>
          <p
            style={{padding: '2px'}}
            onClick={() => onDblClickHandler('comparison', item, ItemIndex)}
            className="as-link cross-icon-field">
            <EditPencilSvg fill={'#a39f8d'} />
          </p>
        </div>
      )}
      {/* <div
              className={`transition-all duration-300 ease-in-out overflow-hidden ${
                isExpandedBilling ? 'max-h-screen' : 'max-h-0'
              }`}> */}

      {type === 'standard' && (
        <>
          {/* <hr></hr> */}
          <div
            style={{
              backgroundColor: 'white',
              color: 'black',
              fontWeight: 'bold',
              padding: '3px 12px',
              borderBottom: '0px',
            }}
            className="template-flex as-link "
            onClick={onClickHandler}
            onDoubleClick={() => {
              onDblClickHandler('standard', item, ItemIndex);
            }}>
            <span>{item.group_name}</span>

            <span>{item.add_on.toUpperCase()}</span>
          </div>
          {/* {productDetail.length === 0 && (
          <NorecordFound
            noRecordData={NoOfferingsFound}
            buttonPermesion={checkPermesionOfCreate}
            ButtonAction={() => {
              handleUpdateItem('createCatalog');
            }}
          />
        )} */}
        </>
      )}

      {showAll && (
        <>
          <hr class="border-t border-gray-300 pb-3"></hr>

          <div className="roi-form-input">
            <Form.Control
              type="text"
              // value={invoiceService}
              pattern="^\S.*$"
              // required
              placeholder="Add product or service"
              value={showSearchProduct}
              ref={inputRef}
              onChange={e => {
                setShowSearchProduct(e.target.value);
                get_catalogs_for_invoices(e.target.value);
              }}
            />
            {showSearchProduct && (
              <ul
                className="custom-suggestions invoices_suggestion"
                ref={dropdownRef}>
                {checkPermesionOfCreate && (
                  <li
                    className="as-link"
                    onClick={() => {
                      handleUpdateItem('createCatalog');
                    }}>
                    Add offerings
                  </li>
                )}
                {productDetail.length > 0 &&
                  productDetail.map((suggestion, index) => (
                    <li
                      className="as-link"
                      key={index + '-' + suggestion.id}
                      onClick={() => {
                        objectValues(suggestion);
                      }}>
                      {capitalizeFirst(suggestion.title)}
                    </li>
                  ))}
              </ul>
            )}
          </div>
          {showSearchProduct && noResults && (
            <NorecordFound
              noRecordData={NoOfferingsFound}
              buttonPermesion={true}
              ButtonAction={() => {
                handleUpdateItem('createCatalog');
              }}
              btnRef={offeringButtonRef}
            />
          )}

          {selectedProduct.length > 0 && (
            <div>
              {/* {selectedProduct.map((item, index) => (
                <div key={item.id}>
                  <div className="d-flex justify-content-between mb-3">
                    <h1>{item.title}</h1>

                    <span
                      className=" as-link invoice_delete"
                      onClick={() => {
                        // removeProduct(item, index);
                        // setDeleteCatalogItem({
                        //   item :item,
                        //   index : index,
                        //   comparsionItemIndex : ItemIndex
                        // })
                        setDeleteCatalog({
                          item: item,
                          index: index,
                        });
                        setShowComponent('deleteCatalog');
                      }}>
                      <i className="fa fa-trash-can" />
                    </span>
                  </div>

                

                  <div className="template-flex">
                    <span className="template-text">
                      {item.duration ? formatMinutesTime(item.duration) : ''}
                    </span>

                    <span className="template-text">
                      ${formatNumberWithTwoDecimals(item.cost)}
                    </span>
                  </div>
                </div>
              ))} */}

              {selectedProduct.map((item, index) => (
                <InvoiceProduct
                  item={item}
                  ItemIndex={index}
                  setSelectedProduct={setSelectedProduct}
                  selectedProduct={selectedProduct}
                  setShowComponent={setShowComponent}
                  setDeleteCatalog={setDeleteCatalog}
                  showOutOfStockError={showOutOfStockError}
                  outOfStockItems={outOfStockItems}
                  expensesList={expensesList}
                />
              ))}

              {/* {moreOptions && (
                <>
                  <div className="template-flex">
                    <span>Subtotal</span>

                    <span>
                      ${formatNumberWithTwoDecimals(invoiceData.subtotal)}
                    </span>
                  </div>

                  <div className="template-flex">
                    <span>Taxes and Fees</span>

                    <div className="d-flex ">
                      <span className="invoice_cost f_600">$</span>
                      <span id="invoice_cost_span" ref={taxRef}>
                        {invoiceData.tax}
                      </span>

                      <Form.Control
                        name="tax"
                        type="number"
                        className="invoice_input_field template-text f_600"
                        min="0.00"
                        step="0.01"
                        style={{width}}
                        // style={{ width: `${cost.length}ch` }}
                        value={invoiceData.tax}
                        onChange={handleInputChange}
                        onBlur={handleBlur}
                      />
                    </div>
                  </div>

                  <div className="template-flex">
                    <span>Discount</span>

                    <div className="d-flex ">
                      <span className="invoice_cost f_600">$</span>
                      <span id="invoice_cost_span" ref={discountRef}>
                        {invoiceData.discount}
                      </span>

                      <Form.Control
                        name="discount"
                        type="number"
                        className="invoice_input_field template-text f_600"
                        min="0.00"
                        step="0.01"
                        style={{width: discountWidth}}
                        // style={{ width: `${cost.length}ch` }}
                        value={invoiceData.discount}
                        onChange={handleInputChange}
                        onBlur={handleBlur}
                      />
                    </div>
                  </div>

                  <div className="template-flex">
                    <span>Shipping & handling</span>

                    <div className="d-flex ">
                      <span className="invoice_cost f_600">$</span>
                      <span id="invoice_cost_span" ref={shippingRef}>
                        {invoiceData.shipping}
                      </span>

                      <Form.Control
                        name="shipping"
                        type="number"
                        className="invoice_input_field template-text f_600"
                        min="0.00"
                        step="0.01"
                        style={{width: shippingWidth}}
                        // style={{ width: `${cost.length}ch` }}
                        value={invoiceData.shipping}
                        onChange={handleInputChange}
                        onBlur={handleBlur}
                      />
                    </div>
                  </div>
                </>
              )} */}

              <div className="template-flex-total" style={{backgroundColor: type === 'standard' ? 'transparent' : 'white'}}>
                <span className="template-text">Total</span>

                <span>${calculateTotal(selectedProduct)}</span>
              </div>

              <div className="template-flex payment-schedule">
                <span>Payment Schedule</span>

                <span className="template-text">
                  {checkSubscriptionType(selectedProduct) === 'monthly'
                    ? 'Recurring Monthly'
                    : checkSubscriptionType(selectedProduct)}
                </span>
              </div>
            </div>
          )}

          {selectedProduct.length > 0 && !moreOptions && (
            <div
              className="more-option template-text as-link mb-3"
              onClick={() => {
                setMoreOptions(!moreOptions);
              }}>
              More Options
            </div>
          )}

          {selectedProduct.length > 0 && moreOptions && (
            <div
              className="more-option template-text as-link mb-3"
              onClick={() => {
                setMoreOptions(!moreOptions);
              }}>
              Less Options
            </div>
          )}

          {moreOptions && selectedProduct.length > 0 && (
            <Form.Group className="fieldset mt-2">
              <Form.Control
                as="textarea"
                required
                rows={4}
                // pattern="^\S.*$"
                placeholder="Note to Customer"
                value={note}
                onChange={e => {
                  setNote(e.target.value);
                }}
              />
              <Form.Control.Feedback type="invalid">
                Feedback is required.
              </Form.Control.Feedback>
            </Form.Group>
          )}
        </>
      )}
    </div>
  );
};

export default ComparisonItem;
