import React, {useEffect, useRef, useState} from 'react';
import Input from './Input/Input';
import {cameraicon} from '../Constants/Images';
import {Button, Dropdown, Form} from 'react-bootstrap';
import Upload from './Project/Upload';
import {
  changeFileVariable,
  formatPhoneNumber,
  getCityStateFromAddress,
  getMaxDateFromToday,
  validPhoneNumber,
} from '../Utils/commonUtils';
import EditPencilSvg from '../Svg/EditPencilSvg';
import {errorMessage, successMessage} from '../Utils/ToastMessages';
import commonApi from '../Services/CommonService';
import GooglePlacesAutocomplete, {
  geocodeByPlaceId,
  getLatLng,
} from 'react-google-places-autocomplete';
import $ from 'jquery';
import Calender from './FeedBack/Calender';
import moment from 'moment';
import LoaderSpinner from './Modals/LoaderSpinner';

const ContactDetail = ({
  showContactDetails,
  contactData,
  handleChange,
  type,
  uploadFilesListing,
  setUploadFilesListing,
  setShowFileComponent,
  setContactData,
  userData,
  otherType,
  additionalDetail,
  setAdditionalDetail,
  handleBlock,
  image,
  setImage,
}) => {
  const [editProject, setEditProject] = useState(true);
  const [documentInfo, setDocumentInfo] = useState([]);
  const [pocDetail, setPocDetail] = useState({
    name: '',
    username: '',
    email: '',
    phoneNo: '',
    role: '',
    platform: '',
    url: '',
  });
  const [index, setIndex] = useState(null);
  const [pocRoles, setPocRoles] = useState([]);
  const [contactDetail, setContactDetail] = useState([]);
  const [validated, setValidated] = useState(false);
  const [companyAddress, setCompanyAddress] = useState('');
  const [contactRelation, setContactRelation] = useState([]);
  const [showAdditionalDetail, setShowAdditionalDetail] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const fileInput = useRef(null); // Define the reference to the file input

  const handleFiles = (item, type) => {
    if (type === 'delete') {
      //   setShowModal('confirmation_Model');
      setDocumentInfo(item);
    } else if (type === 'upload') {
      setUploadFilesListing([
        ...uploadFilesListing,
        {
          file: item?.file_url,
        },
      ]);
    } else if (type === 'open') {
      setShowFileComponent(true);

      setDocumentInfo(changeFileVariable(item));
    }
  };

  const handleListing = (e, type) => {
    if (type === 'social') {
      if (
        pocDetail.username !== '' &&
        pocDetail.platform !== '' &&
        pocDetail.url !== ''
      ) {
        if (index !== null) {
          const updatesocialListing = [...contactData.socialListing];
          updatesocialListing[index] = {...pocDetail};
          setContactData(prev => ({
            ...prev,
            socialListing: updatesocialListing,
          }));
          setIndex(null);
          setPocDetail({});
        } else {
          setContactData(prevData => ({
            ...prevData,
            socialListing: [...prevData.socialListing, pocDetail],
          }));
          setPocDetail({});
        }
      } else {
        errorMessage('Please enter values.');
      }
    } else {
      if (
        pocDetail.name !== '' &&
        pocDetail.email !== '' &&
        pocDetail.phoneNo !== '' &&
        pocDetail.role !== ''
      ) {
        if (index !== null) {
          // add_user_pocs()
          const updatedPocListing = [...contactData.pocListing];
          updatedPocListing[index] = {...pocDetail};
          setContactData(prev => ({...prev, pocListing: updatedPocListing}));
          setIndex(null);
          setPocDetail({});
        } else {
          setContactData(prevData => ({
            ...prevData,
            pocListing: [...prevData.pocListing, pocDetail],
          }));
          add_user_pocs();

          setPocDetail({});
        }
      } else {
        errorMessage('Please enter values.');
      }
    }
  };

  const handle = e => {
    setPocDetail({
      ...pocDetail,
      [e.target.name]: e.target.value,
    });
  };

  const handleEdit = (item, index, type) => {
    if (type === 'pocs') {
      setPocDetail({
        name: item.name,
        email: item.email,
        phoneNo: item.phoneNo,
        role: item.role,
      });
    } else {
      setPocDetail({
        platform: item.platform,
        url: item.url,
        username: item.username,
      });
    }
    setIndex(index);
  };

  const handleDelete = (item, type) => {
    if (type === 'pocs') {
      const updatedPocListing = contactData.pocListing.filter(
        (_, i) => i !== contactData.pocListing.indexOf(item),
      );
      setContactData(prev => ({...prev, pocListing: updatedPocListing}));
    } else {
      const updateSocialListing = contactData.socialListing.filter(
        (_, i) => i !== contactData.socialListing.indexOf(item),
      );
      setContactData(prev => ({...prev, socialListing: updateSocialListing}));
    }
  };

  const handleSubmit = event => {
    const form = event.currentTarget;

    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      setValidated(true);
    } else {
      event.preventDefault();
      setValidated(false);

      if (type === 'company' && !otherType) {
        // alert('h')

        let place_id =
          typeof companyAddress === 'object'
            ? companyAddress.value.place_id
            : typeof companyAddress === 'string'
            ? ''
            : '';

        if (place_id) {
          geocodeByPlaceId(place_id)
            .then(results => {
              const geocodingResult = results[0];
              return Promise.all([
                getLatLng(geocodingResult),
                getCityStateFromAddress(geocodingResult),
              ]);
            })
            .then(([latLngResult, cityStateResult]) => {
              const {lat, lng} = latLngResult;
              add_customer_company(lat, lng);
            })
            .catch(error => {
              return {type: 'error', message: error.message};
            });
        } else {
          add_customer_company();
        }
      } else if (type === 'pocs') {
        if (pocDetail.phoneNo || pocDetail.email) {
          add_user_pocs();
        } else {
          errorMessage('Please enter email or phone number.');
        }
      } else if (type === 'company' && otherType) {
        setContactDetail(prevDetails => [...prevDetails, contactData]);
        reset();
      }
    }
  };

  const reset = () => {
    setContactData({
      name: '',
      email: '',
      phoneNo: '',
      address: '',
      type: contactRelation[0].id,
      socialListing: [],
      pocListing: [],
    });
  };
  const resePoc = () => {
    setPocDetail({
      name: '',
      username: '',
      email: '',
      phoneNo: '',
      role: pocRoles[0].id,
      platform: '',
      url: '',
    });
    setCompanyAddress('');
    setImage([])
  };

  const add_customer_company = (lat, lng) => {
    const payload = {
      user_id: userData?.data.id,
      company_name: contactData.name,
      email: contactData.email ? contactData.email : '',
      phone: contactData.phoneNo ? contactData.phoneNo : '',
      home_location: companyAddress.label ? companyAddress.label : '',
      home_latitude: companyAddress.label ? lat : '',
      home_longitude: companyAddress.label ? lng : '',
      contact_type: contactData.type ? contactData.type : '',
    };

    // return

    commonApi
      .add_customer_company(payload)
      .then(res => {
        if (res.status === 200) {
          successMessage(res.message);
          reset();
          get_user_poc_company_details();

          setTimeout(() => {
            // setShowLoader(false);
          }, 1200);
        } else {
        }
      })
      .catch(err => {
        return {type: 'error', message: err.message};
      });
  };

  const add_user_pocs = () => {
    let phoneFormat;

    if (pocDetail.phoneNo) {
      phoneFormat = formatPhoneNumber(pocDetail.phoneNo);
    }

    const pocDetailFormat = 
      {
        poc_phone: pocDetail.phoneNo ? pocDetail.phoneNo : '',
        poc_name: pocDetail.name,
        poc_email: pocDetail.email ? pocDetail.email : '',
        role: pocDetail.role,
        profile_image: image ? image.toString() : ''
      }
    
    const payload = {
      user_id: userData?.data?.id,
      poc_details: pocDetailFormat,
    };

    commonApi
      .add_user_pocs(payload)
      .then(res => {
        if (res.status === 200) {
          if (res.success) {
            resePoc();
            successMessage(res.message);
            get_user_poc_company_details();
          }else{
            errorMessage(res.message)
          }
         
        }
      })
      .catch(err => {
        return {type: 'error', message: err.message};
      });
  };


  const get_poc_roles = () => {
    setShowLoader(true)
    commonApi
      .get_poc_roles()
      .then(res => {
        if (res.user_poc_roles.length > 0) {
          setPocRoles(res.user_poc_roles);
          setPocDetail({
            ...pocDetail,
            role: res.user_poc_roles[0].id,
          });
          setTimeout(() => {
            setShowLoader(false);
          }, 1200);
        }
      })
      .catch(err => {
        return {type: 'error', message: err.message};
      });
  };
  const getContactRelationTypes = () => {
    setShowLoader(true)
    commonApi
      .get_contact_relation_types()
      .then(res => {
        if (res.data.length > 0) {
          setContactRelation(res.data);
          setContactData({
            ...contactData,
            type: res.data[0].id,
          });
          setTimeout(() => {
            setShowLoader(false);
          }, 1200);
        }
      })
      .catch(err => {
        return {type: 'error', message: err.message};
      });
  };

  const get_user_poc_company_details = () => {
    const body = {
      user_id: userData?.data.id,
      type:
        type === 'pocs'
          ? 'user_pocs'
          : type === 'company'
          ? 'user_company'
          : type === 'attachment'
          ? 'attachment'
          : 'user_social',
    };

    commonApi
      .get_user_poc_company_details(body)
      .then(res => {
        if (res?.data?.length > 0) {
          setContactDetail(res.data);
        }
      })
      .catch(err => {
        return {type: 'error', message: err.message};
      });
  };

  useEffect(() => {
    if (type === 'pocs') {
      get_poc_roles();
    }
    if (type === 'company') {
      getContactRelationTypes();
    }
    if (setImage) {
      setImage([])
    }
    // 
  }, []);

  useEffect(() => {
    if (!otherType) {
      get_user_poc_company_details();
    }
  }, [type]);

  let jugar =
    typeof companyAddress === 'string' && companyAddress !== ''
      ? companyAddress
      : 'Address';

  const clearAll = () => {
    setCompanyAddress('');
    $('.css-1uccc91-singleValue').html('Address');
  };

  const style = {
      backgroundColor: 'rgb(247, 246, 242)'
  }

  return (
    <>
    {(type === 'pocs' || type === 'company') && showLoader && 
    <LoaderSpinner
    firstDivStyle={style}
    />}
      {type !== 'attachment' && (
        <Form
          style={{paddingBottom: '0px'}}
          className="send-refferal-form-input-holder"
          noValidate
          validated={validated}
          onSubmit={handleSubmit}>
          {type !== 'attachment' && type !== 'social' && (
            <div
              style={{backgroundColor: type === 'pocs' ? '#EDEBE5' : 'white'}}
              className="rounded-md shadow-md mb-2">
              <div
                className="flex justify-between items-center p-3 cursor-pointer"
                >
                <h2 
                onClick={() => handleChange('open_closed')}
                className="text-base font-semibold">
                  {type === 'pocs'
                    ? 'POCs'
                    : otherType
                    ? 'Member Details'
                    : 'Company Details'}
                </h2>
                {type === 'pocs' &&
                <div>
                  <input
                    type="file"
                    accept="image/*"
                    onChange={e => {
                      handleBlock('', 'changeImg', e)
                    }}
                    style={{display: 'none'}} // Hide the input field
                    ref={fileInput} // Set the reference to the input
                  />
                  <div onClick={() => fileInput.current?.click()}>
                    {image?.length > 0 ? (
                      <img
                        style={{
                          borderRadius: '6px',
                          border: 'none',
                          width: '37px',
                          height: '24px',
                          marginLeft: 'auto',
                        }}
                        src={image.toString()}
                        alt=""
                      />
                    ) : (
                      <img style={{
                        borderRadius: '6px',
                        border: 'none',
                        width: '37px',
                        height: '24px',
                        marginLeft: 'auto',
                      }}
                      src={cameraicon} alt="Camera icon" />
                    )}
                  </div>
                </div>
}
                {/* <button className="text-gray-500">
            {showContactDetails ? '▲' : '▼'}
          </button> */}
              </div>
              <div
                className={`overflow-hidden transition-all duration-300 ${
                  showContactDetails ? 'max-h-screen' : 'max-h-0'
                }`}>
                <hr class="border-t border-gray-300 pb-3 ml-[28px] mr-[28px]"></hr>
                <div className="p-4 pt-0 space-y-2">
                  <Input
                    type="text"
                    label={''}
                    name={'name'}
                    value={type === 'pocs' ? pocDetail.name : contactData.name}
                    onChange={e => {
                      if (type === 'pocs') {
                        handle(e, 'pocs');
                      } else if (otherType) {
                        handleChange('change_value', e, otherType);
                      } else {
                        handleChange('change_value', e);
                      }
                    }}
                    placeholder={
                      type === 'pocs' || otherType ? 'Name' : 'Company Name'
                    }
                    feedback={
                      type === 'pocs' || otherType
                        ? 'Name is required.'
                        : 'Company name is required.'
                    }
                  />

                  {type === 'company' && (
                    <div className="roi-form-dropdown ">
                      <div className="select-wrapper">
                        <Dropdown
                          onSelect={(eventKey, event) => {
                            setContactData({
                              ...contactData,
                              type: eventKey,
                            });
                          }}
                          drop="down">
                          <Dropdown.Toggle variant="primary">
                            {contactData.type
                              ? contactRelation.find(
                                  item => item.id === contactData.type,
                                )?.relations
                              : 'Select Role'}
                          </Dropdown.Toggle>

                          <Dropdown.Menu>
                            {contactRelation.map((item, index) => (
                              <Dropdown.Item
                                key={`st-${index}`}
                                eventKey={item.id.toString()}
                                active={item.id === contactData.type}>
                                {item.relations}
                              </Dropdown.Item>
                            ))}
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    </div>
                  )}

                  <Input
                    type="email"
                    label={''}
                    name={'email'}
                    value={
                      type === 'pocs' ? pocDetail.email : contactData.email
                    }
                    onChange={e => {
                      if (type === 'pocs') {
                        handle(e, 'pocs');
                      } else if (otherType) {
                        handleChange('change_value', e, otherType);
                      } else {
                        handleChange('change_value', e);
                      }
                    }}
                    placeholder={'Email'}
                    feedback={'Invalid email.'}
                    required={false}
                  />

                  <Input
                    type="number"
                    label={''}
                    name={'phoneNo'}
                    value={
                      type === 'pocs' ? pocDetail.phoneNo : contactData.phoneNo
                    }
                    onChange={e => {
                      if (type === 'pocs') {
                        handle(e, 'pocs');
                      } else if (otherType) {
                        handleChange('change_value', e, otherType);
                      } else {
                        handleChange('change_value', e);
                      }
                    }}
                    placeholder={'Phone number'}
                    feedback={'Phone number is required.'}
                    required={false}
                  />

                  {type === 'company' && (
                    <div
                      style={{padding: '0px'}}
                      className="location-field form-control schedule_location">
                      <GooglePlacesAutocomplete
                        selectProps={{
                          companyAddress,
                          onChange: setCompanyAddress,
                          name: 'city',
                          placeholder: jugar,
                        }}
                      />

                      {companyAddress !== '' && (
                        <span
                          className="location_clear_icon"
                          onClick={() => {
                            clearAll();
                          }}>
                          <i className="fas fa-close" />
                        </span>
                      )}
                    </div>
                  )}

                  {type === 'pocs' && (
                    <div className="roi-form-dropdown ">
                      <div className="select-wrapper">
                        <Dropdown
                          onSelect={(eventKey, event) => {
                            setPocDetail({
                              ...pocDetail,
                              role: eventKey,
                            });
                          }}
                          drop="down">
                          <Dropdown.Toggle variant="primary">
                            {pocDetail.role
                              ? (() => {
                                  const role =
                                    pocRoles.find(
                                      item => item.id === pocDetail.role,
                                    )?.role_name || '';
                                  return (
                                    role.charAt(0).toUpperCase() + role.slice(1)
                                  );
                                })()
                              : 'Select Role'}
                          </Dropdown.Toggle>

                          <Dropdown.Menu>
                            {pocRoles.map((item, index) => (
                              <Dropdown.Item
                                key={`st-${index}`}
                                eventKey={item.id.toString()}
                                active={item.id === pocDetail.role}>
                                {item.role_name.charAt(0).toUpperCase() +
                                  item.role_name.slice(1)}
                              </Dropdown.Item>
                            ))}
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    </div>
                  )}

                  {type === 'company' && (
                    <div className="flex justify-center items-center mt-3">
                      <button
                        type="submit"
                        className="bg-black text-white px-4 py-2 rounded-md">
                        {otherType ? 'Add Member' : 'Add Company'}
                      </button>
                    </div>
                  )}

                  {type === 'pocs' && (
                    <div className="flex justify-center items-center mt-3">
                      <button
                        type="submit"
                        className="bg-black text-white px-4 py-2 rounded-md">
                        Add POC
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
          {type === 'social' && (
            <div
              style={{backgroundColor: '#EDEBE5'}}
              className="rounded-md shadow-md mb-2">
              <div
                className="flex justify-between items-center p-3 cursor-pointer"
                onClick={() => handleChange('open_closed')}>
                <h2 className="text-base font-semibold">Handles</h2>
              </div>
              <div
                className={`overflow-hidden transition-all duration-300 ${
                  showContactDetails ? 'max-h-screen' : 'max-h-0'
                }`}>
                <hr class="border-t border-gray-300 pb-3 ml-[28px] mr-[28px]"></hr>
                <div className="p-4 pt-0 space-y-2 height-input">
                  <Input
                    type="text"
                    label={''}
                    name={'platform'}
                    value={pocDetail.platform}
                    onChange={handle}
                    placeholder={'Platform'}
                    feedback={'Patform is required.'}
                  />

                  <Input
                    type="text"
                    label={''}
                    name={'username'}
                    value={pocDetail.username}
                    onChange={handle}
                    placeholder={'Handle or username'}
                    feedback={'Handle or username is required.'}
                  />

                  <Input
                    type="url"
                    label={''}
                    name={'url'}
                    value={pocDetail.url}
                    onChange={handle}
                    placeholder={'URL'}
                    feedback={'URL is required.'}
                  />

                  <div className="flex justify-center items-center mt-3">
                    <button
                      type="button"
                      onClick={e => handleListing(e, 'social')}
                      className="bg-black text-white px-4 py-2 rounded-md">
                      Add Social
                    </button>
                  </div>
                  {contactData?.socialListing?.length > 0 && (
                    <>
                      {contactData?.socialListing.map((item, index) => (
                        <div
                          className="d-flex align-items-center phases_item mt-2 icon-alias-holder"
                          key={index}>
                          <div
                            style={{padding: '15px 15px'}}
                            className="customer_name flex-1">
                            <div className="alias_name">{item.username}</div>
                            {/* <div style={{display: 'flex'}}>
                          <span
                            style={{padding: '2px'}}
                            onClick={() => handleEdit(item, index, 'social')}
                            className="as-link cross-icon-field">
                            <EditPencilSvg fill={'#a39f8d'} />
                          </span>
                          <span className="cross-icon-field">
                            <i
                              className="as-link fas fa-times"
                              onClick={() => handleDelete(item, 'social')}
                              style={{
                                fontSize: '1.5rem',
                                color: '#a39f8d',
                              }}></i>
                          </span>
                        </div> */}
                          </div>
                        </div>
                      ))}
                    </>
                  )}
                </div>
              </div>
            </div>
          )}
        </Form>
      )}

      {contactDetail?.length > 0 && (
        <>
          {contactDetail?.map((item, index) => (
            <div
              className="d-flex align-items-center phases_item mt-2 icon-alias-holder"
              key={index}>
              <div className="customer_name flex-1">
                <div className="alias_name">
                  {type === 'pocs'
                    ? item.poc_name
                    : otherType
                    ? item.name
                    : item.company_name}
                </div>
                {/* <div style={{display: 'flex'}}>
                          <span
                            style={{padding: '2px'}}
                            onClick={() => handleEdit(item, index, 'pocs')}
                            className="as-link cross-icon-field">
                            <EditPencilSvg fill={'#a39f8d'} />
                          </span>
                          <span className="cross-icon-field">
                            <i
                              className="as-link fas fa-times"
                              onClick={() => handleDelete(item, 'pocs')}
                              style={{
                                fontSize: '1.5rem',
                                color: '#a39f8d',
                              }}></i>
                          </span>
                        </div> */}
              </div>
            </div>
          ))}
        </>
      )}
      {type === 'attachment' && (
        <div className="bg-white rounded-md shadow-md mb-2">
          <div
            className="flex justify-between items-center p-3 cursor-pointer"
            onClick={() => handleChange('open_closed')}>
            <h2 className="text-base font-semibold">Attachments</h2>
          </div>
          <div
            className={`overflow-hidden transition-all duration-300 ${
              showContactDetails ? 'max-h-screen' : 'max-h-0'
            }`}>
            <hr class="border-t border-gray-300 pb-3 ml-[28px] mr-[28px]"></hr>
            <div
              onClick={() => {
                setEditProject(true);
              }}
              className="p-4  pt-0 space-y-2 height-input">
              <Upload
                uploadFilesListing={uploadFilesListing}
                setUploadFilesListing={setUploadFilesListing}
                handleFiles={handleFiles}
                setShowFileComponent={setShowFileComponent}
                type={'dynamic_attachment'}
              />
            </div>
          </div>
        </div>
      )}
      {otherType && (
        <div className="bg-white rounded-md shadow-md mb-2">
          <div
            className="flex justify-between items-center p-3 cursor-pointer"
            onClick={() => setShowAdditionalDetail(!showAdditionalDetail)}>
            <h2 className="text-base font-semibold">Additional Details</h2>
            {/* <button className="text-gray-500">
                              {showAdditionalDetail ? '▲' : '▼'}
                            </button> */}
          </div>
          <div
            className={`overflow-hidden transition-all duration-300 ${
              showAdditionalDetail ? 'max-h-screen' : 'max-h-0'
            }`}>
            <hr class="border-t border-gray-300 pb-3 ml-[28px] mr-[28px]"></hr>
            <div className="p-4  pt-0 space-y-2 height-input">
              <Input
                type="url"
                label={''}
                name={'website'}
                value={additionalDetail.website}
                onChange={e => {
                  handleChange('change_value', e, 'additionalDetail');
                }}
                placeholder={'Website'}
                feedback={'Website is required.'}
              />

              <Input
                type="text"
                label={''}
                name={'timeZone'}
                value={additionalDetail.timeZone}
                onChange={e => {
                  handleChange('change_value', e, 'additionalDetail');
                }}
                placeholder={'TimeZone'}
                feedback={'TimeZone is required.'}
              />

              <Input
                type="text"
                label={''}
                name={'contactSource'}
                value={additionalDetail.contactSource}
                onChange={e => {
                  handleChange('change_value', e, 'additionalDetail');
                }}
                placeholder={'ContactSource'}
                feedback={'ContactSource is required.'}
              />

              <Calender
                minDate={new Date()}
                maxDate={getMaxDateFromToday()}
                setSelectedDate={selectedDate => {
                  if (selectedDate) {
                    setAdditionalDetail({
                      ...additionalDetail,
                      birthDate: selectedDate,
                    });
                  } else {
                    setAdditionalDetail({
                      ...additionalDetail,
                      birthDate: '',
                    });
                  }
                }}
                selectedDate={
                  additionalDetail.birthDate &&
                  moment(
                    additionalDetail.birthDate,
                    'YYYY-MM-DD',
                    true,
                  ).isValid()
                    ? new Date(additionalDetail.birthDate)
                    : ''
                }
                showDate={true}
                showTime={false}
                type={'contact'}
                placeholder={'Birth Date'}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ContactDetail;
