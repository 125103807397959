import React, {useState,useEffect} from 'react';
import {Form, Button} from 'react-bootstrap';
import Scrollbars from 'react-custom-scrollbars';
import commonApi from '../../Services/CommonService';
import SnapshotList from '../Snapshot/SnapshotList';
import CircleListBuilders from '../../Pages/Front/StaticPages/CircleListBuilders';
import {errorMessage, successMessage} from '../../Utils/ToastMessages';
import SendInvitation from '../../Pages/Front/StaticPages/SendInvitation';
import SlidingPanel from '../SlidingPanel';
import ProfileComponent from '../ProfilePage/ProfileComponent';
import {amplitudeEvent} from '../../Services/AmplitudeServices';
import {fixName} from '../../Utils/commonUtils';
import {connect} from 'react-redux';
import CustomTabs from '../CustomTabs';
import LoaderSpinner from '../Modals/LoaderSpinner';
import Invite from '../SingleComponent/Invite';

const ReferSnapshot = props => {
  const [chooseCircle, setChooseCircle] = useState(false);
  const [addSnapshot, setAddSnapshot] = useState(false);
  const [showProfile, setShowProfile] = useState('');
  const [referOut, setReferOut] = useState(false);
  const [snapshotIds, setSnapshotIds] = useState([props.snapshotData.id]);
  const [snapshotImages, setSnapshotImages] = useState([
    props.snapshotData.images[0],
  ]);
  const [selectedFollowers, setSelectedFollowers] = useState([]);
  const [allBuilders, setAllBuilders] = useState([]);
  const [tabType, setTabType] = useState('Details');
  const [notes, setNotes] = useState('');
  const [contact, setContact] = useState([]);
  const [showLoader, setShowLoader] = useState(false);
  const [showComponent, setShowComponent] = useState('');
  const [originalDataOfMod, setOriginalDataOfMod] = useState([]);
  const [inviteLoader, setInviteLoader] = useState(true);
  const [inviteFollowers, setInviteFollowers] = useState([]);
  const [followersList, setFollowersList] = useState([]);
  const [inviteQry, setInviteQry] = useState('');

  const referSnapshot = () => {
    if (
      selectedFollowers.length + contact.length > 0 &&
      snapshotIds.length > 0
    ) {
      setShowLoader(true);
      var initData = {
        accomplishment_ids: snapshotIds,
        user_ids: selectedFollowers,
        refer_notes: notes,
        contacts: contact,
      };
      commonApi
        .refer_accomplishment(initData)
        .then(res => {
          if (res.success) {
            successMessage(res.message);
            amplitudeEvent('REFER_SNAPSHOT');
            props.onClose();
          } else {
            errorMessage(res.message);
          }
          setShowLoader(false);
          // setSelectedFollowers([]);
        })
        .catch(err => {
          
          return {type: 'error', message: err.message};
        });
    }
  };

  // useEffect(() => {
  //   setFollowers(
  //     allFollowers.filter(item => !selectedFollowers.includes(item.id)),
  //   );
  // }, [selectedFollowers]);

  const CircleUserListData = item => {
    if (item.length > 0) {
      item.forEach((miniItem, index) => {
        if (!selectedFollowers.includes(miniItem.id)) {
          setSelectedFollowers(previous => [...previous, miniItem.id]);
          setAllBuilders(previous => [...previous, miniItem]);
          setOriginalDataOfMod(previous => [...previous, miniItem]);
        }
      });
    }
  };

  const handleCircle = () => {
    setChooseCircle(true);
  };

  useEffect(() => {
    if (contact.length > 0) {
      setInviteLoader(true);
      setInviteQry('');
    }
  }, [contact]);

  return (
    <>
      <SlidingPanel hidePanel={() => props.onClose()}>
        <div className="serach-filter-wrapper pt-0">
          {showLoader && <LoaderSpinner />}
          <div
            className={`sidebar-heading-wrapper mt-0 fix-width-forms ${
              props.withHeadingSpace || props.windowWidth ? 'with-panel' : ''
            }`}>
            <div className="with-user-img with-user-snapshot-img">
              <div
                className="user-profile-img as-link"
                onClick={() => {
                  setShowProfile(props.userData.id);
                }}>
                <img src={props.userData.profile_img} alt="" />
              </div>
              {/* <span className="close as-link" onClick={() => props.onClose()}>
                                  <i className="fas fa-times" />
                              </span> */}
            </div>
            <div className="heading">
              <h2>
                {fixName(props.userData.first_name, props.userData.last_name)}
              </h2>
            </div>
          </div>

          <Scrollbars>
            <div className="create-ticket-tabs text-center">
              <CustomTabs
                darkmode
                tabs={['Details', 'Recipient']}
                active={tabType}
                onSelect={item => {
                  setTabType(item);
                  if (item === 'Details' && inviteQry !== '') {
                    setInviteFollowers(followersList);
                    setInviteQry('');
                  }
                }}
              />
            </div>
            <div className="with-user-img fix-width-padd-remove">
              {/* <div
              className="share-externally-link"
              onClick={() => {
                setReferOut(true);
              }}>
              {' '}
              Share Externally
            </div> */}
              {tabType === 'Details' && (
                <>
                  <div className="images-list mb-3">
                    {/* {Object.entries(snapshotImages).length > 0 &&
                                snapshotImages.map((item, index) => {
                                    return (
                                        <div key={`ss-${index}`} className="images-list-item">
                                            <img src={item} alt="" />
                                        </div>
                                    )
                                })
                            } */}

                    {snapshotImages.length > 0 && (
                      <div className="images-list-item share-image-count">
                        <img src={snapshotImages[0]} alt="" />
                        <span>{snapshotImages.length}</span>
                      </div>
                    )}
                    <div className="images-list-item">
                      <div
                        onClick={() => {
                          setAddSnapshot(true);
                        }}
                        className="camera-icon">
                        <i className="fas fa-plus" />
                        {/* <input type="file" /> */}
                      </div>
                    </div>
                  </div>
                  <div className="serach-filter-wrapper">
                    <div
                      className="sidebar-heading-wrapper sidebar-snapshot-head-wrapper
                    ">
                      <div className="heading">
                        <label>Notes (optional)</label>
                      </div>
                    </div>
                    <div className="fix-width-forms fix-width-padd-remove">
                      <div
                        className="form-outline form-snapshot-outline"
                        style={{maxWidth: 'inherit'}}>
                        <Form>
                          <textarea
                            // disabled={props.disabled}
                            className="form-control"
                            id="textAreaExample1"
                            rows="12"
                            placeholder="Write a note..."
                            value={notes}
                            onChange={e => {
                              setNotes(e.target.value);
                            }}></textarea>
                        </Form>
                      </div>
                    </div>
                    {/* <div className="invite-persons-heading mt-3">
                    <span
                      className="builder-text as-link"
                      onClick={() => {
                        navigator.clipboard.writeText(
                          'www.' +
                            ENV_HOST +
                            'tryingtobuild.com/' +
                            lowerName +
                            '/profile?id=' +
                            props.userData.id,
                        );
                        storeReferral();
                        successMessage(labels.copy_link[selected_lang]);
                      }}>
                      Copy Profile Link
                    </span>
                  </div> */}
                  </div>
                </>
              )}
              {tabType === 'Recipient' && (
                <>
                  <div className="refferal-list-wrapper">
                    {inviteLoader && (
                      <div className="invite_loader_hieght">
                        <LoaderSpinner />
                      </div>
                    )}
                    {!inviteLoader && (
                      <>
                        {' '}
                        <div className="invite-persons-heading justify-content-center">
                          <span
                            className="builder-text as-link "
                            onClick={() => {
                              setReferOut(true);
                              setTimeout(() => {
                                if (contact.length === 0 && inviteQry !== '') {
                                  setInviteFollowers(followersList);
                                  setInviteQry('');
                                }
                              }, 1000);
                            }}>
                            Share Externally
                          </span>
                        </div>
                        <p>Refer to…</p>
                      </>
                    )}

                    <Invite
                      type={'refer'}
                      moderator={selectedFollowers}
                      setModerator={setSelectedFollowers}
                      selectedModData={allBuilders}
                      setSelectedModData={setAllBuilders}
                      contact={contact}
                      setContact={setContact}
                      originalDataOfMod={originalDataOfMod}
                      setOriginalDataOfMod={setOriginalDataOfMod}
                      handleCircle={handleCircle}
                      onClick={() => {
                        setShowComponent('inviteUser');
                        setTimeout(() => {
                          if (contact.length === 0 && inviteQry !== '') {
                            setInviteFollowers(followersList);
                            setInviteQry('');
                          }
                        }, 1000);
                      }}
                      classForCol={2}
                      forApiHit={referOut || showComponent}
                      showLoader={inviteLoader}
                      setshowLoader={setInviteLoader}
                      followers={inviteFollowers}
                      setFollowers={setInviteFollowers}
                      setFollowersList={setFollowersList}
                      followersList={followersList}
                      setSearchQry={setInviteQry}
                      searchQry={inviteQry}
                    />
                  </div>
                </>
              )}
              {tabType === 'Details' && (
                <div className="invite-btns">
                  <Button
                    disabled={
                      selectedFollowers.length + contact.length > 0 &&
                      snapshotIds.length > 0
                        ? false
                        : true
                    }
                    onClick={() => {
                      referSnapshot();
                    }}>
                    Send Snapshot
                  </Button>
                </div>
              )}
            </div>
          </Scrollbars>
        </div>
      </SlidingPanel>
      {addSnapshot && (
        <SnapshotList
          show={{sendButton: false}}
          snapshotData={props.snapshotData}
          userData={props.userData}
          snapshotIds={snapshotIds}
          setSnapshotIds={setSnapshotIds}
          setSnapshotImages={setSnapshotImages}
          onClose={() => {
            setAddSnapshot(false);
          }}
        />
      )}
      {chooseCircle && (
        <CircleListBuilders
          onClose={() => {
            setChooseCircle(false);
          }}
          // setInviteCircle={setInviteCircle}
          hide={true}
          CircleUserListData={CircleUserListData}
        />
      )}
      {referOut && (
        <SendInvitation
          id={snapshotIds}
          referType={'snapshot'}
          onClose={() => setReferOut(false)}
          setContact={setContact}
        />
      )}
      {showComponent === 'inviteUser' && (
        <SendInvitation
          onClose={() => {
            setShowComponent('');
          }}
          setContact={setContact}
        />
      )}
      {showProfile && (
        <ProfileComponent
          userId={showProfile}
          closeModal={() => {
            setShowProfile('');
          }}
          onClose={() => {
            setShowProfile('');
          }}
          userData={[]}
          VoxeetSDK={[]}
          conferenceInfo={[]}
          jdSpecialM={[]}
          jdSpecialA={[]}
        />
      )}
      {/* {selectedFollowers.length + contact.length > 0 && showSelectedUser && (
        <SelectedReferUser
          selectedAllUser={allBuilders}
          users={selectedFollowers}
          setUsers={setSelectedFollowers}
          setSelectedAllUser={setAllBuilders}
          onClose={() => setShowSelectedUser(false)}
          alreadyAddedUser={[]}
          referOutSide={contact}
          setReferOutSide={setContact}
        />
      )} */}
    </>
  );
};

const mapStateProps = state => ({
  windowWidth: state.windowWidth,
});

export default connect(mapStateProps, {})(ReferSnapshot);
