import React, {useState, useEffect, useCallback} from 'react';
import VoxeetSDK from '@voxeet/voxeet-web-sdk';
// import {consts} from 'react-elastic-carousel';
import ParticipantStream from '../../Components/SessionDetails/ParticipantStream';
import {
  DEFAULT_IMAGE,
  App_key_Dolby,
  App_Secret_key_Dolby,
} from '../../Utils/env';
import {
  startVideo,
  startAudio,
  stopVideo,
  stopAudio,
  startScreen,
  stopScreen,
} from '../../Utils/voxeetUtils';
import LoaderSpinner from '../Modals/LoaderSpinner';
import commonApi from '../../Services/CommonService';
import {getItem, setItem} from '../../Utils/LocalStorage';
import {Row, Col} from 'react-bootstrap';
import {Swiper, SwiperSlide} from 'swiper/react';
import { Navigation} from 'swiper';
import {successMessage} from '../../Utils/ToastMessages';
import {ref, onValue} from 'firebase/database';
import {db} from '../../Firebase/firebase';
import $ from 'jquery';

const consumerKey = App_key_Dolby;
const consumerSecret = App_Secret_key_Dolby;

VoxeetSDK.initialize(consumerKey, consumerSecret);
const OpportunityCall = props => {
  // const consumerKey = 'qJLj9URBabgmOo2vg27O3w==';
  // const consumerSecret = '1CB5KpefEhLWRZ1QvkYcQHs3GP7tmRMz_7BM5DHigSg=';

  // VoxeetSDK.initialize(consumerKey, consumerSecret);
  
  // const [sampleStateV, setSampleStateV] = useState(0);
  // const [sampleStateP, setSampleStateP] = useState(0);
  const [isVideoEnable, setVideoEnable] = useState(true);
  const [isAudioEnable, setAudioEnable] = useState(true);
  const [isScreenEnable, setScreenEnable] = useState(false);
  const [showLoader, setShowLoader] = useState(true);
  const [videoStreamList, setVideoStreamList] = useState([]);

  useEffect(() => {
    $('.session-videos')
      .parent('.panel-secondary')
      .addClass('OpportunityCall-Panel');
  });

  useEffect(() => {
    if (getItem('lastOppoCall')) {
    } else {
      setItem('lastOppoCall', props.confData.session_id);
      setItem('lastOppoCallData', JSON.stringify(props.confData));
      JoinConference(props.confData.session_id);
      if (
        props.confData &&
        props.confData.data &&
        props.confData.data.chat_room_id
      ) {
        checkOpportunityChatStatus(props.confData.data.chat_room_id);
      }
    }
    // setTimeout(() => {
    //   setSampleStateV(prev => prev + 1);
    // }, 3000);
    // setTimeout(() => {
    //   setShowLoader(false);
    //   setSampleStateV(prev => prev + 1);
    // }, 5000);
    // setTimeout(() => {
    //   setSampleStateV(prev => prev + 1);
    // }, 5500);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // useEffect(() => {
  //   if (isVideoEnable || isScreenEnable) {
  //     setTimeout(() => {
  //       setSampleStateP(prev => prev + 1);
  //     }, 2000);
  //   }
  // }, [isVideoEnable, isScreenEnable]);

  useEffect(() => {
   
    
  }, [videoStreamList]);

  // useEffect(() => {
  //   if (props.chatIsEnable) {
  //   } else {
  //     endCall();
  //   }
  // }, [props.chatIsEnable]);

  const JoinConference = id => {
    const userName =
      localStorage.getItem('firstName') +
      ' ' +
      localStorage.getItem('lastName');
    const avatarPic = localStorage.getItem('profilePic')
      ? localStorage.getItem('profilePic')
      : DEFAULT_IMAGE;
    const externalId = localStorage.getItem('id');
    const mixer = {
      name: userName,
      externalId: externalId,
      avatarUrl: avatarPic,
    };
    const joinOptions = {constraints: {video: true, audio: true}};
    // checkUserLeaveByUrl();
    VoxeetSDK.session
      .open(mixer)
      .then(() => VoxeetSDK.conference.fetch(id))
      // Join the conference
      .then(conference => {
        try {
          // 
          VoxeetSDK.conference
            .join(conference, joinOptions)
            .then(() => {
            if(Object.entries(Array.from(VoxeetSDK.conference.participants.values()))
            .length > 0 )  {
                Array.from(VoxeetSDK.conference.participants.values()).forEach(item => {
                  // 
                  if (localStorage.getItem('id') === item.info.externalId ) {
                    // alert(item.info.externalId)
                    setTimeout(() => {
                      ShowFullVideo(item.id, item.streams[0].id, item.streams[0])
                    }, 3000);
                  
                  }
                  })}
            })
            .catch(err => console.error(err));
          // streamEvents();
          // UpdateSessionDetails(localStorage.getItem("conf_id"));
          // setTimeout(() => {
          //   setSampleStateP(prev => prev + 1);
          // }, 2000);
          
          streamEvents();
        } catch (error) {
          console.error(error);
        }
      })
      .catch(err => console.error(err));
  };

  const endCall = () => {
    setItem('lastOppoCall', '');
    setItem('lastOppoCallData', '');
    if (props.confData.started_by === getItem('id')) {
      VoxeetSDK.command
        .send({dataPayload: 'close_session'})
        .then(success => {
          
          VoxeetSDK.conference
            .leave(VoxeetSDK.session.participant)
            .then(() => {
              VoxeetSDK.session.close();
              userLeftCall(props.confData.conference_id);
              props.onClose();
              //   history.push("/sessions-events");
            })
            .catch(err => console.error(err));
        })
        .catch(error => {
         
        });
    } else {
      VoxeetSDK.conference
        .leave(VoxeetSDK.session.participant)
        .then(() => {
          VoxeetSDK.session.close();
          userLeftCall(props.confData.conference_id);
          props.onClose();
          //   history.push("/sessions-events");
        })
        .catch(err => console.error(err));
    }
  };

  const userLeftCall = async id => {
    const payload = {conference_id: id};
    const response = await commonApi.user_left_opportunity_call(payload);
    if (response) {
    }
  };

  // const myArrow = ({type, onClick, isEdge}) => {
  //   const directionClass = type === consts.PREV ? 'slide-prev' : 'slide-next';
  //   const pointer =
  //     type === consts.PREV ? (
  //       <i className="fas fa-angle-up"></i>
  //     ) : (
  //       <i className="fas fa-angle-down"></i>
  //     );
  //   return (
  //     <button
  //       className={`slider-nav ${directionClass}`}
  //       onClick={onClick}
  //       disabled={isEdge}>
  //       {pointer}
  //     </button>
  //   );
  // };

  // const ListviewContent = useCallback(() => {
  //   return (
  //     <Row className="row-reverse">
  //       <Col md={9}>
  //         <div className="session-video">
  //           <div
  //             className="video-item"
  //             id="large-video-container"
  //             style={{display: 'none'}}>
  //             <video
  //               id={`video-objectlarge`}
  //               className="participant-grid-item__video"
  //               playsInline
  //               autoPlay
  //             />
  //           </div>
  //         </div>
  //       </Col>
  //       <Col md={3}>
  //         <div className="session-videos-slider verticle-slider">
  //           <ParticipantViewList />
  //         </div>
  //       </Col>
  //     </Row>
  //   );
  // }, [sampleStateV]);

  const ParticipantViewList = useCallback(() => {
    // return (
    //   <Carousel
    //     verticalMode={true}
    //     renderArrow={myArrow}
    //     pagination={false}
    //     itemsToShow={4}
    //     itemPadding={[10, 0, 10, 0]}>
    //     {Object.entries(Array.from(VoxeetSDK.conference.participants.values()))
    //       .length > 0 &&
    //       Array.from(VoxeetSDK.conference.participants.values()).map(item => {
    //         return item.status === 'Connected' &&
    //           item.streams.length > 0 &&
    //           item.streams[0].getVideoTracks().length > 0 ? (
    //           <div
    //             key={`ses-vid-l-${item.id}`}
    //             className="video-items"
    //             onClick={() =>
    //               ShowFullVideo(item.id, item.streams[0].id, item.streams[0])
    //             }>
    //             <ParticipantStream
    //               participant={item}
    //               stream={item.streams[0]}
    //             />
    //           </div>
    //         ) : item.status === 'Connected' &&
    //           item.streams.length > 1 &&
    //           item.streams[1].getVideoTracks().length > 0 ? (
    //           <div
    //             key={`ses-vid-l-${item.id}`}
    //             className="video-items"
    //             onClick={() =>
    //               ShowFullVideo(item.id, item.streams[1].id, item.streams[1])
    //             }>
    //             <ParticipantStream
    //               participant={item}
    //               stream={item.streams[1]}
    //             />
    //           </div>
    //         ) : null;
    //       })}
    //   </Carousel>
    // );
    return (
      <Swiper
        direction="horizontal"
        slidesPerView={3} // Display 3 slides at a time
        spaceBetween={5} // Adjust this value as needed
        navigation={true}
        modules={[Navigation]}
        className="mySwiper">
        {Object.entries(Array.from(VoxeetSDK.conference.participants.values()))
          .length > 0 &&
          Array.from(VoxeetSDK.conference.participants.values()).map(item => {
            return item.status === 'Connected' &&
              item.streams.length > 0 &&
              item.streams[0].getVideoTracks().length > 0 ? (
              <SwiperSlide key={'ses-id-l-' + item.id}>
                <div
                  key={`ses-vid-l-${item.id}`}
                  className="video-items"
                  onClick={() =>
                    ShowFullVideo(item.id, item.streams[0].id, item.streams[0])
                  }>
                  <ParticipantStream
                    participant={item}
                    stream={item.streams[0]}
                  />
                </div>
              </SwiperSlide>
            ) : item.status === 'Connected' &&
              item.streams.length > 1 &&
              item.streams[1].getVideoTracks().length > 0 ? (
              <SwiperSlide key={'ses-id-' + item.id}>
                <div
                  key={`ses-vid-l-${item.id}`}
                  className="video-items"
                  onClick={() =>
                    ShowFullVideo(item.id, item.streams[1].id, item.streams[1])
                  }>
                  <ParticipantStream
                    participant={item}
                    stream={item.streams[1]}
                  />
                </div>
              </SwiperSlide>
            ) : null;
          })}
      </Swiper>
    );
  }, []);

  const ShowFullVideo = (id, streamId, stream) => {
    
    document.getElementById('large-video-container').style.display = 'block';
    let perspective = 'self-view';
    let videoNode = document.getElementById('video-objectlarge');
    if (!videoNode) {
      videoNode = document.createElement('video');
      videoNode.setAttribute('id', id);
      videoNode.setAttribute('height', '100%');
      videoNode.setAttribute('width', '100%');
      videoNode.muted = false;
      videoNode.autoplay = true;
      videoNode.playsinline = true;

      const videoContainer = document.getElementById(perspective);

      videoContainer.lastElementChild.replaceWith(videoNode);
      videoContainer.firstElementChild.innerText = 'asadss';
    }
    videoNode.volume = 0.0;
    localStorage.setItem('largeVideo', streamId);
    navigator.attachMediaStream(videoNode, stream);
  };

  const manageScreenSharing = async () => {
    if (isScreenEnable) {
      const response = await stopScreen();
      if (response) {
        setScreenEnable(false);
      }
    } else {
      const response = await startScreen();
      if (response) {
        setScreenEnable(true);
      }
    }
  };

  const streamEvents = () => {
    
    let moderatorEndedCall = false; // Flag variable to track if the 'Moderator ended the call' message has been displayed

    VoxeetSDK.command.on('received', (participant, message) => {
      let broadcast = JSON.parse(message);
      
      
      if (broadcast.dataPayload === 'close_session' && !moderatorEndedCall) {
        moderatorEndedCall = true; // Set the flag to true after displaying the message
        successMessage('Moderator ended the call');
        endCall();
      }
    });

    VoxeetSDK.conference.on('joined', () => {
      
    });
    VoxeetSDK.conference.on('participantAdded', participant => {
     
      
      // setSampleStateV(prev => prev + 1);
      // setSampleStateP(prev => prev + 1);
    });
    VoxeetSDK.conference.on('participantUpdated', participant => {
     
      
      // setSampleStateV(prev => prev + 1);
      // setSampleStateP(prev => prev + 1);
    });
    VoxeetSDK.conference.on('left', () => {
      

      // const participants = VoxeetSDK.conference.participants;
      // var allPersons = [];
      // let count = 0;
      // participants.forEach(element => {
      //   if (element.status !== 'Left') {
      //     allPersons.push(element);
      //     if (
      //       element.info.externalId == localStorage.getItem('creator_id') &&
      //       count > 0
      //     ) {
      //       let b = allPersons[count];
      //       allPersons[count] = allPersons[0];
      //       allPersons[0] = b;
      //     }
      //     count++;
      //   }
      // });
     
      // 
      // 
      // UpdateSessionDetails(localStorage.getItem('conf_id'));
    });

    VoxeetSDK.conference.on('streamAdded', (participant, stream) => {
     
     
   
      // setSampleStateV(prev => prev + 1);
      // setSampleStateP(prev => prev + 1);
      if (
        stream &&
        stream.getVideoTracks() &&
        stream.getVideoTracks().length > 0
      ) {
        // setVideoStreamList(current => {
        //   const found = current.find(
        //     objs =>
        //       objs.participant.info.externalId === participant.info.externalId,
        //   );
        //   if (typeof found === 'object') {
        //     return current.map(obj => {
        //       if (
        //         obj.participant.info.externalId === participant.info.externalId
        //       ) {
        //         return {...obj, participant: participant, stream: stream};
        //       }
        //       return obj;
        //     });
        //   } else {
        //     return [
        //       ...current,
        //       {
        //         participant: participant,
        //         stream: stream,
        //       },
        //     ];
        //   }
        // });
        setVideoStreamList(current => [
          ...current,
          participant.info.externalId,
        ]);
        // setSampleStateV(prev => prev + 1);
      }
      // if (stream.type === 'ScreenShare') {
      //   setSampleStateV(prev => prev + 1);
      // }
    });

    // When a video stream is updated from the conference
    VoxeetSDK.conference.on('streamUpdated', (participant, stream) => {
     
      
      

      if (
        stream &&
        stream.getVideoTracks() &&
        stream.getVideoTracks().length > 0
      ) {
        // setVideoStreamList(current => {
        //   const found = current.find(
        //     objs =>
        //       objs.participant.info.externalId === participant.info.externalId,
        //   );
        //   if (typeof found === 'object') {
        //     return current.map(obj => {
        //       if (
        //         obj.participant.info.externalId === participant.info.externalId
        //       ) {
        //         return {...obj, participant: participant, stream: stream};
        //       }
        //       return obj;
        //     });
        //   } else {
        //     return [
        //       ...current,
        //       {
        //         participant: participant,
        //         stream: stream,
        //       },
        //     ];
        //   }
        // });
        setVideoStreamList(current => {
          if (current.includes(participant.info.externalId)) {
            return current;
          } else {
            // setSampleStateV(prev => prev + 1);
            return [...current, participant.info.externalId];
          }
        });
      } else {
        setVideoStreamList(current =>
          current.filter(item => {
            if (item !== participant.info.externalId) {
              return true;
            } else {
              // setSampleStateV(prev => prev + 1);
              return false;
            }
          }),
        );
        let largeVideoShow = localStorage.getItem('largeVideo');
        if (largeVideoShow === stream.id) {
          document.getElementById('large-video-container').style.display =
            'none';
        }
      }

      // setSampleStateV(prev => prev + 1);
      // setSampleStateP(prev => prev + 1);
    });

    // When a video stream is removed from the conference
    VoxeetSDK.conference.on('streamRemoved', (participant, stream) => {
     
      
      
      let largeVideoShow = localStorage.getItem('largeVideo');
      if (largeVideoShow === stream.id) {
        document.getElementById('large-video-container').style.display = 'none';
      }
      if (participant.info.externalId === getItem('id')) {
        if (stream.type === 'ScreenShare') {
          setScreenEnable(false);
        }
      }
      // setVideoStreamList(current =>
      //   current.filter(
      //     item =>
      //       item.participant.info.externalId !== participant.info.externalId,
      //   ),
      // );
      setVideoStreamList(current =>
        current.filter(item => {
          if (item !== participant.info.externalId) {
            return true;
          } else {
            // setSampleStateV(prev => prev + 1);
            return false;
          }
        }),
      );
      // setSampleStateV(prev => prev + 1);
      // setSampleStateP(prev => prev + 1);
    });
  };

  const checkOpportunityChatStatus = chatId => {
    const database = ref(db, 'chatroom_status/opportunityChat/' + chatId);
    onValue(database, snapshot => {
      if (snapshot.val() && Object.entries(snapshot.val()).length > 0) {
        if (snapshot.val().left) {
          // if (newSubstring === 'history') {
          //   endCall();
          // } else
          if (
            snapshot.val().time + 60 >
            Math.floor(new Date().getTime() / 1000)
          ) {
            endCall();
          }
        }
      }
    });
  };

  return showLoader ? (
    <LoaderSpinner setShowLoader={setShowLoader} />
  ) : (
    <div className="session-videos" id="ListviewContent">
      <div className="session-videos-list OpportunityCall">
        {/* <ListviewContent sampleStateP={sampleStateP} /> */}
        <Row className="row-reverse">
          <Col md={12}>
            <div className="Opportunity-Container">
              <div className="session-video">
                <div
                  className="video-item"
                  id="large-video-container"
                  style={{display: 'none'}}>
                  <video
                    id={`video-objectlarge`}
                    className="participant-grid-item__video"
                    playsInline
                    autoPlay
                  />
                </div>
              </div>
              <div className="CallActions w-100">
                <div className="video-action-icons m-0">
                  <div
                    className="icon-item hangup"
                    onClick={() => {
                      endCall();
                    }}>
                    <span className="icon-hangup" />
                  </div>
                  {!isVideoEnable && (
                    <div
                      className={`icon-item ${isScreenEnable ? 'active' : ''}`}
                      onClick={() => {
                        manageScreenSharing();
                      }}>
                      <span className="icon-clone" />
                    </div>
                  )}
                  {!isScreenEnable && (
                    <div
                      className={`icon-item ${isVideoEnable ? 'active' : ''}`}
                      onClick={() => {
                        if (isVideoEnable) {
                          stopVideo();
                          setVideoEnable(false);
                        } else {
                          startVideo();
                          setVideoEnable(true);
                        }
                      }}>
                      <span className="icon-camera-rotate" />
                    </div>
                  )}
                  <div className="icon-item">
                    {isAudioEnable ? (
                      <span
                        className={'icon-mic-2'}
                        onClick={() => {
                          stopAudio();
                          setAudioEnable(false);
                        }}></span>
                    ) : (
                      <span
                        className={'icon-mute-icon'}
                        onClick={() => {
                          startAudio();
                          setAudioEnable(true);
                        }}></span>
                    )}
                  </div>
                </div>
                <div className="session-videos-slider verticle-slider m-0 mt-4">
                  <ParticipantViewList />
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </div>
      {/* <div className="video-icon">
        {isVideoEnable ? (
          <span
            className={`stage-header-icon`}
            onClick={() => {
              // videoStartBtn();
              stopVideo();
              // 
              setVideoEnable(false);
            }}>
            Disable <i className="fas fa-video" />
          </span>
        ) : (
          <span
            className={`stage-header-icon active`}
            onClick={() => {
              // stopVideo();
              startVideo();
              // 
              setVideoEnable(true);
            }}>
            Enable <i className="fas fa-video" />
          </span>
        )}
        {isAudioEnable ? (
          <span
            className={'action-icon audio-icon active'}
            onClick={() => {
              stopAudio();
              setAudioEnable(false);
            }}>
            <i className="fas fa-microphone" />
          </span>
        ) : (
          <span
            className={`action-icon audio-icon `}
            onClick={() => {
              startAudio();
              setAudioEnable(true);
            }}>
            <i className="fas fa-microphone" />
          </span>
        )}
        <div
          onClick={() => {
            VoxeetSDK.conference
              .leave(VoxeetSDK.session.participant)
              .then(() => {
                VoxeetSDK.session.close();
                userLeftCall(props.confData.conference_id);
                props.onClose();
                //   history.push("/sessions-events");
              })
              .catch(err => console.error(err));
          }}>
          {props.confData.started_by == getItem('id') ? 'End Call' : 'Leave'}
        </div>
      </div> */}
    </div>
  );
};

export default OpportunityCall;
