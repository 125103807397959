import React from 'react';

const OpenHalfComponent = ({ primary }) => {
  if (!primary) return null; // Prevent rendering when primary is empty

  return (
    <div
      style={{
        position: 'absolute',
        top: '0%',
        left: '55%',
        width: '45%',
        height: '100%',
        zIndex: '999',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      {primary}
    </div>
  );
};

export default OpenHalfComponent;
