import React, {useEffect, useState} from 'react';
import {labels} from '../../Constants/Translations';
import {selected_lang} from '../../Utils/env';
import {Button, Col, Form, Row} from 'react-bootstrap';
import CustomDropdown from '../CustomDropdown';
import Calender from '../FeedBack/Calender';
import Moment from 'react-moment';
import moment from 'moment';
import {errorMessage, successMessage} from '../../Utils/ToastMessages';
import CustomModal from '../CustomModal';
import commonApi from '../../Services/CommonService';
import {
  capitalizeFirst,
  filterWithoutIds,
  getMaxDateFromToday,
} from '../../Utils/commonUtils';
import {getAvailabilityData} from '../../Store/Actions/AvailabilityDataAction';
import {useDispatch} from 'react-redux';
import {useSelector} from 'react-redux';

const Availability = props => {
  const {availabilityData, setAvailabilityData} = props;
  const [selectedDay, setSelectedDay] = useState('monday');
  const [openTime, setOpenTime] = useState('');
  const [openCalendar, setOpenCalendar] = useState({
    close: false,
    open: false,
  });

  const [closeTime, setCloseTime] = useState('');
  const [subTabType, setSubTabsType] = useState('avalability');
  const [dropdownId, setDropdownId] = useState('schedule');
  const [duration, setDuration] = useState('15');
  const [daysForTime, setDaysForTime] = useState([]);
  const [temporaryGetTimes, setTemporaryGetTimes] = useState({});
  const [temporaryDate, setTemporaryDate] = useState('');
  const [showTime, setShowTime] = useState(false);
  const dispatch = useDispatch();
  const getAvailability = useSelector(state => state.availabilityListing);
  const enableAddEdit =
    localStorage.getItem('id') === props.userId
      ? true
      : (localStorage.getItem('id') !== props.userId &&
          localStorage.getItem('userRole_in_company') === 'Owner') ||
        localStorage.getItem('userRole_in_company') === 'owner'
      ? true
      : false;

  const workingDays = [
    {label: 'S', value: 'sunday'},
    {label: 'M', value: 'monday'},
    {label: 'T', value: 'tuesday'},
    {label: 'W', value: 'wednesday'},
    {label: 'R', value: 'thursday'},
    {label: 'F', value: 'friday'},
    {label: 'S', value: 'saturday'},
  ];

  const showDatesListing = () => {
    if (dropdownId === 'sick') {
      return availabilityData.selectMultipleSickDates;
    } else if (dropdownId === 'vacation') {
      return availabilityData.selectMultipleVacationDates;
    } else if (dropdownId === 'holiday') {
      return availabilityData.selectMultipleHolidayDates;
    }
  };

  const compareTime = (day, startTime, endTime, duration) => {
    // Find the day's existing schedules
    const daySchedule =
      temporaryGetTimes?.selectedTime?.length > 0
        ? temporaryGetTimes?.selectedTime.filter(slot => slot.day === day)
        : [];

    const alreadySelectedDaySchedule =
      availabilityData?.selectedTime?.length > 0
        ? availabilityData?.selectedTime.filter(slot => slot.day === day)
        : [];

    // return
    // If schedule exists, check for overlapping time slots
    if (daySchedule.length > 0) {
      const same = daySchedule.find(
        obj =>
          !(
            (obj.start_time > moment(startTime, 'hh:mm A').format('HH:mm:ss') &&
              obj.start_time > moment(endTime, 'hh:mm A').format('HH:mm:ss')) ||
            (obj.end_time < moment(startTime, 'hh:mm A').format('HH:mm:ss') &&
              obj.end_time < moment(endTime, 'hh:mm A').format('HH:mm:ss'))
          ),
      );
      if (same) {
        errorMessage(`Time already reserved on ${day}`);
        return;
      }
    }

    if (alreadySelectedDaySchedule.length > 0) {
      const same = alreadySelectedDaySchedule.find(
        obj =>
          !(
            (obj.start_time > moment(startTime, 'hh:mm A').format('HH:mm:ss') &&
              obj.start_time > moment(endTime, 'hh:mm A').format('HH:mm:ss')) ||
            (obj.end_time < moment(startTime, 'hh:mm A').format('HH:mm:ss') &&
              obj.end_time < moment(endTime, 'hh:mm A').format('HH:mm:ss'))
          ),
      );
      if (same) {
        errorMessage(`Time already reserved on ${day}`);
        return;
      }
    }

    // Validate time constraints
    if (
      !startTime ||
      !endTime ||
      startTime === endTime ||
      startTime > endTime
    ) {
      errorMessage('Close time must be greater than open time');
      return;
    }

    if (
      moment(startTime, 'hh:mm A').add(duration, 'minutes').format('HH:mm A') >
      moment(endTime, 'hh:mm A').format('HH:mm A')
    ) {
      errorMessage(`Duration must be within ${duration} minutes`);
      return;
    }

    // Add the new schedule
    setTemporaryGetTimes(prev => {
      const updatedSelectedTime = [...(prev.selectedTime || [])]; // Copy old state
      updatedSelectedTime.push({
        // Push new object
        day,
        start_time: moment(startTime, 'hh:mm A').format('HH:mm:ss'),
        end_time: moment(endTime, 'hh:mm A').format('HH:mm:ss'),
      });

      return {
        ...prev,
        selectedTime: updatedSelectedTime, // Update state with new array
      };
    });

    successMessage(`Schedule added for ${day}`);
    // return

    // Reset input fields (if using state)

    // Call additional UI functions if needed
    showModelFordays(temporaryGetTimes?.selectedTime, startTime, endTime, day);
    // setButton(true);
  };

  const showModelFordays = (day, Stime, Etime, type) => {
    setDaysForTime([type]);
    handleOpenCalendar('add-time');
    // setStartTimeForSectedDays(moment(Stime, 'hh:mm A').format('hh:mm A'));
    // setEndTimeForSectedDays(moment(Etime, 'hh:mm A').format('hh:mm A'));
  };

  // const handleDelete = itemIndex => {
  //   if (dropdownId === 'sick') {
  //     const updatedSickDates = selectMultipleSickDates.filter(
  //       (_, i) => i !== itemIndex,
  //     );
  //     setSelectMultipleSickDates(updatedSickDates);
  //   } else if (dropdownId === 'vacation') {
  //     const updatedVacationDates = selectMultipleVacationDates.filter(
  //       (_, i) => i !== itemIndex,
  //     );
  //     setSelectMultipleVacationDates(updatedVacationDates);
  //   } else if (dropdownId === 'holiday') {
  //     const updatedHoliday = selectMultipleHolidayDates.filter(
  //       (_, i) => i !== itemIndex,
  //     );
  //     setSelectMultipleHolidayDates(updatedHoliday);
  //   }
  // };

  const dropdownValues = [
    {
      id: 'schedule',
      name: 'Schedule',
    },
    {
      id: 'holiday',
      name: 'Holiday',
    },
    {
      id: 'vacation',
      name: 'Vacation',
    },
    {
      id: 'sick',
      name: 'Sick',
    },
  ];

  const checkForDays = day => {
    if (selectedDay !== day) {
      setDaysForTime(prevDays =>
        prevDays.includes(day)
          ? prevDays.filter(d => d !== day)
          : [...prevDays, day],
      );
    }
  };

  const handleOpenCalendar = type => {
    if (type === 'open') {
      props.passComponent2(
        <Calender
          type="multipleTimes"
          otherType={'openCalender'}
          setStartDaysTime={e => {
            setOpenTime(e);
          }}
          setIsOpenCalendar={() => {
            setOpenCalendar(false);
            props.onClose();
          }}
          isOpenCalendar={true}
          startDaysTime={openTime}
          showDate={false}
          showTime={true}
        />,
      );
      props.handleBlock('', 'openCalendar');
    } else if (type === 'close') {
      props.passComponent2(
        <Calender
          type="multipleTimes"
          otherType={'openCalender'}
          setStartDaysTime={e => {
            setCloseTime(e);
          }}
          startDaysTime={closeTime}
          setIsOpenCalendar={() => {
            setOpenCalendar({
              open: false,
              close: false,
            });
            props.onClose();
          }}
          isOpenCalendar={true}
          showDate={false}
          showTime={true}
        />,
      );
      props.handleBlock('', 'openCalendar');
    } else if (type === 'add-time') {
      props.passComponent2(
        <CustomModal
          size="medium"
          onClose={() => {
            resetTime();
            props.onClose();
          }}
          className="availability-modal">
          <div className="new-session-modal-wrapper text-center createSessionModal ">
            <div className="new-session-modal">
              <p className="p-reg mb-2">
                Would you like to apply the same time to differents days?
              </p>
              <div className="availability-days my-4">
                {workingDays.map((item, index) => {
                  return (
                    <span
                      id={`day-${item.value}`}
                      key={`week-day-${index}`}
                      onClick={() => checkForDays(item.value)}
                      className={
                        daysForTime.includes(item.value) ? 'active' : ''
                      }>
                      {item.label}
                    </span>
                  );
                })}
              </div>
              <Button
                id="add-time-btn"
                className="btn-dark add-time"
                onClick={() => {
                  setShowTime(true);
                }}>
                Add Time
              </Button>
              <Button
                className="no-thanks"
                onClick={() => {
                  resetTime();
                  props.onClose();
                }}>
                No Thanks
              </Button>
            </div>
          </div>
        </CustomModal>,
      );
      props.handleBlock('', 'openCalendar');
    }
  };

  useEffect(() => {
    workingDays.forEach(item => {
      const dayElement = document.getElementById(`day-${item.value}`);
      if (dayElement) {
        if (daysForTime.includes(item.value)) {
          dayElement.classList.add('active');
        } else {
          dayElement.classList.remove('active');
        }
      }
    });

    const addButton = document.getElementById('add-time-btn');

    if (addButton) {
      addButton.disabled = daysForTime.length === 0;
    }
  }, [daysForTime]);

  useEffect(() => {
    dispatch(
      getAvailabilityData({
        type: dropdownId, //  holiday ,vacation ,sick ,schedule
        user_id: props.userId ? props.userId : '',
      }),
    );
    setOpenTime('');
    setCloseTime('');
    setTemporaryDate('');
  }, [dropdownId]);

  useEffect(() => {
    editAvailability();
  }, [getAvailability]);

  const editAvailability = () => {
    const availabilityKeys = {
      schedule: 'selectedTime',
      vacation: 'selectMultipleVacationDates',
      sick: 'selectMultipleSickDates',
      holiday: 'selectMultipleHolidayDates',
    };

    if (availabilityKeys[dropdownId]) {
      setAvailabilityData(prevData => ({
        ...prevData,
        [availabilityKeys[dropdownId]]: getAvailability,
      }));
    }
  };

  useEffect(() => {
    setAvailabilityData({});
  }, []);

  const resetTime = () => {
    setOpenTime('');
    setCloseTime('');
  };
  const addTimeInselectedDays = () => {
    const filteredDays = daysForTime.filter(day => {
      return (
        !temporaryGetTimes.selectedTime.some(
          existingTime => existingTime.day === day,
        ) &&
        !availabilityData?.selectedTime.some(
          existingTime =>
            existingTime.day === day &&
            moment(existingTime.start_time, 'hh:mm A').isSameOrBefore(
              moment(closeTime, 'hh:mm A'),
            ) &&
            moment(existingTime.end_time, 'hh:mm A').isSameOrAfter(
              moment(openTime, 'hh:mm A'),
            ),
        )
      );
    });

    setTemporaryGetTimes(prev => ({
      ...prev,
      selectedTime: [
        ...prev?.selectedTime,
        ...filteredDays.map(day => ({
          day,
          start_time: moment(openTime, 'hh:mm A').format('HH:mm:ss'),
          end_time: moment(closeTime, 'hh:mm A').format('HH:mm:ss'),
        })),
      ],
    }));

    props.onClose();
    resetTime();
    successMessage(`Schedule added`);
    setShowTime(false);
  };

  useEffect(() => {
    if (showTime) {
      addTimeInselectedDays();
    }
  }, [showTime]);

  const getTimeSlotsByDay = (selectedTime, day) => {
    return selectedTime?.length > 0
      ? selectedTime?.filter(
          slot => slot.day.toLowerCase() === day.toLowerCase(),
        )
      : [];
  };

  const deleteSlot = obj => {
    setTemporaryGetTimes(prevData => ({
      ...prevData,
      selectedTime: prevData?.selectedTime
        ? prevData.selectedTime.filter(
            slot =>
              slot.start_time !== obj.start_time ||
              slot.end_time !== obj.end_time ||
              slot.day !== obj.day,
          )
        : [],
    }));
  };

  const create_user_availability = newDateObj => {
    const payload = {
      type: dropdownId,
      user_id: props?.userId ? props?.userId : localStorage.getItem('id'),
      ...(dropdownId === 'schedule' && {
        schedule: filterWithoutIds(temporaryGetTimes?.selectedTime),
      }),
      ...(dropdownId === 'vacation' && {
        vacation: extractDates([newDateObj]),
      }),
      ...(dropdownId === 'sick' && {
        sick: extractDates([newDateObj]),
      }),
      ...(dropdownId === 'holiday' && {
        holiday: extractDates([newDateObj]),
      }),
    };

    commonApi
      .create_user_availability(payload)
      .then(res => {
        if (res.status === 200) {
          successMessage(res.message);
          dispatch(
            getAvailabilityData({
              type: dropdownId,
              user_id: props.userId ? props.userId : '',
            }),
          );
          setTemporaryGetTimes({});
        }
      })
      .catch(err => {
        return {type: 'error', message: err.message};
      });
  };

  // const resetAvailaility = () => {
  //   setSelectMultipleHolidayDates([]);
  //   setSelectMultipleSickDates([]);
  //   setSelectMultipleVacationDates([]);
  //   setSelectedTime([]);
  // };

  const showbuttonHasNotIds = () => {
    return filterWithoutIds(temporaryGetTimes?.selectedTime || []);
  };

  const handleSubmit = newDateObj => {
    if (subTabType === 'avalability' && dropdownId !== 'schedule') {
      if (newDateObj) {
        handleDateSelect(newDateObj);
      } else {
        errorMessage('Please select date.');
      }
    } else {
      if (temporaryGetTimes?.selectedTime?.length > 0) {
        create_user_availability();
      } else {
        errorMessage('Please add at least one slot.');
      }
    }
  };

  const extractDates = data => {
    // return data
    return data?.map(item => item.date) ?? [];
  };

  const addUniqueDate = (existingDates, newDateObj) => {
    const dateSet = new Set(existingDates.map(obj => obj.date));

    if (dateSet.has(newDateObj.date)) {
      errorMessage('Date already exists!');
      return existingDates;
    }
    setTemporaryDate('');
    create_user_availability(newDateObj);
    return [...existingDates, newDateObj];
  };

  const handleDateSelect = e => {
    const date = {
      date: e,
    };
    const fieldMap = {
      sick: 'selectMultipleSickDates',
      holiday: 'selectMultipleHolidayDates',
      vacation: 'selectMultipleVacationDates',
    };

    if (fieldMap[dropdownId]) {
      setAvailabilityData(prevData => ({
        ...prevData,
        [fieldMap[dropdownId]]: addUniqueDate(
          prevData[fieldMap[dropdownId]],
          date,
        ),
      }));
    }
  };

  return (
    <div className="bg-white w-full p-3 md:p-5 lg:p-7">
      <div className="event-tabs tabs-style-2 mb-0 fixed-width-inputs ">
        <ul className="flex flex-wrap justify-center md:justify-start space-x-2">
          {/* <li>
            <Button
              className={subTabType === 'general' ? 'active' : ''}
              onClick={() => setSubTabsType('general')}>
              {labels.sessions_type_general[selected_lang]}
            </Button>
          </li> */}
          <li>
            <Button
              className={subTabType === 'avalability' ? 'active' : ''}
              onClick={() => {
                setSubTabsType('avalability');
                //
              }}>
              {labels.avalability[selected_lang]}
            </Button>
          </li>
          <li>
            <Button
              className={subTabType === 'Geo_Coverage' ? 'active' : ''}
              onClick={() => {
                setSubTabsType('Geo_Coverage');
                //
              }}>
              Geo Coverage
            </Button>
          </li>
        </ul>
      </div>

      {subTabType === 'avalability' && (
        <>
          <div className="mb-3 mt-3">
            <CustomDropdown
              // type={'credential'}
              setDropdownId={setDropdownId}
              dropdownId={dropdownId}
              DropDownValues={dropdownValues}
              itemName={['name']}
              className={
                'recruitment_dropdown recruitement_dropdown_setInvites'
              }
              // handleDropdownValue={handleInvitesAssociation}
              // disabled={props.id ? true : false}
            />
          </div>

          {(dropdownId === 'holiday' ||
            dropdownId === 'vacation' ||
            dropdownId === 'sick') && (
            <>
              {enableAddEdit && (
                <>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                    className="fieldset custom-datepicker-field">
                    <Calender
                      minDate={new Date()}
                      maxDate={getMaxDateFromToday()}
                      setSelectedDate={selectedDate => {
                        if (selectedDate) {
                          setTemporaryDate(selectedDate);
                        } else {
                          setTemporaryDate('');
                        }
                      }}
                      selectedDate={
                        temporaryDate &&
                        moment(temporaryDate, 'YYYY-MM-DD', true).isValid()
                          ? new Date(temporaryDate)
                          : ''
                      }
                      showDate={true}
                      showTime={false}
                      type={'profile'}
                    />
                    <Button
                      onClick={() => {
                        handleSubmit(temporaryDate);
                      }}
                      className="btn-dark"
                      type="submit">
                      Add
                    </Button>
                  </div>
                </>
              )}
              {showDatesListing() &&
                showDatesListing()?.length > 0 &&
                showDatesListing().map((item, index) => (
                  <div
                    style={{marginBottom: '10px'}}
                    className="flex justify-between">
                    <p key={index} className="block text-sm font-medium">
                      {/* {item.date} */}
                      {moment(item.date, 'YYYY-MM-DD').format('MMM DD, YYYY')}
                    </p>
                    {enableAddEdit && (
                      <p className="as-link">
                        <span
                          className={`as-link`}
                          onClick={() => {
                            props.handleBlock(
                              index,
                              'deleteConfirmation',
                              item,
                              dropdownId,
                            );
                          }}>
                          <i className="fas fa-times" />
                        </span>
                      </p>
                    )}
                  </div>
                ))}
            </>
          )}
          {dropdownId === 'schedule' && (
            <>
              <h3 className="text-lg font-semibold mb-2">
                Setup Schedule Per Day
              </h3>
            <div className='flex justify-center items-center '>
              <div className="flex justify-center items-center bg-[#52bd41] rounded-full p-2 md:p-3 mb-4 w-auto max-w-100">
                {workingDays.map((day, index) => (
                  <button
                    key={index}
                    className={`w-10 h-10 rounded-full flex items-center justify-center  font-bold transition-all duration-300 ${
                      selectedDay === day.value
                        ? 'bg-white text-black shadow-md'
                        : ' text-white bg-transparent'
                    }`}
                    onClick={() => setSelectedDay(day.value)}>
                    {day.label}
                  </button>
                ))}
              </div>
              </div>
              {enableAddEdit && (
                <>
                  <Row>
                    <Col md={5} className="p-2">
                      <div className="fieldset mb-0">
                        <Form.Label controlid="validationCustom01">
                          Open Time
                        </Form.Label>
                        <Calender
                          type="time"
                          setStartDaysTime={e => {
                            if (e) {
                              setOpenTime(
                                moment(e, 'HH:mm A').format('HH:mm A'),
                              );
                            } else {
                              setOpenTime('');
                            }
                          }}
                          startDaysTime={
                            openTime === '' ? '' : moment(openTime, 'HH:mm:ss')
                          }
                          showDate={false}
                          showTime={true}
                        />
                      </div>
                    </Col>
                    <Col md={5} className="p-2">
                      <div className="fieldset mb-0">
                        <Form.Label controlid="validationCustom01">
                          Close Time
                        </Form.Label>
                        <Calender
                          type="time"
                          setStartDaysTime={e => {
                            if (e) {
                              setCloseTime(
                                moment(e, 'HH:mm A').format('HH:mm A'),
                              );
                            } else {
                              setCloseTime('');
                            }
                          }}
                          startDaysTime={
                            closeTime === ''
                              ? ''
                              : moment(closeTime, 'HH:mm:ss')
                          }
                          showDate={false}
                          showTime={true}
                        />
                      </div>
                    </Col>
                    <Col md={2} className="p-2">
                      <div className="search-tabs-icons mt-4 mr-3">
                        <span
                          className="icon active as-link"
                          onClick={() => {
                            compareTime(
                              selectedDay,
                              openTime,
                              closeTime,
                              duration,
                            );
                          }}>
                          <i className="icon-plus" />
                        </span>
                      </div>
                    </Col>
                  </Row>
                </>
              )}

              <Form.Label className="mt-2 mb-2">
                Added Hours For {capitalizeFirst(selectedDay)}
              </Form.Label>
              {temporaryGetTimes?.selectedTime &&
                temporaryGetTimes?.selectedTime?.length > 0 && (
                  <div className="availability-selected-times">
                    <table>
                      <thead>
                        <tr>
                          <th width="130">Open Time</th>
                          <th>Close Time</th>
                          <th>&nbsp;</th>
                        </tr>
                      </thead>
                      {temporaryGetTimes?.selectedTime &&
                        getTimeSlotsByDay(
                          temporaryGetTimes?.selectedTime,
                          selectedDay,
                        ).map((obj, index) => {
                          return (
                            <tbody key={'sunday' + index}>
                              <tr>
                                <td>
                                  {moment(obj.start_time, 'HH:mm').format(
                                    'hh:mm A',
                                  )}
                                </td>
                                <td>
                                  {moment(obj.end_time, 'HH:mm').format(
                                    'hh:mm A',
                                  )}
                                </td>
                                {enableAddEdit && (
                                  <td>
                                    <span
                                      className="remove-btn"
                                      onClick={() => {
                                        deleteSlot(obj);
                                      }}>
                                      <i className="fa-solid fa-circle-xmark" />
                                    </span>
                                  </td>
                                )}
                              </tr>
                            </tbody>
                          );
                        })}
                    </table>
                  </div>
                )}
              {showbuttonHasNotIds().length > 0 && (
                <div className="flex content-center justify-center mt-3">
                  <Button
                    onClick={() => {
                      setAvailabilityData(prevData => ({
                        ...prevData,
                        selectedTime: temporaryGetTimes?.selectedTime,
                      }));
                      handleSubmit();
                    }}
                    className="btn-dark"
                    type="submit">
                    Add
                  </Button>
                </div>
              )}
              {availabilityData?.selectedTime && getTimeSlotsByDay(
                      availabilityData?.selectedTime,
                      selectedDay,
                    ).length > 0 &&
              <div className="availability-selected-times">
                <table>
                  <thead>
                    <tr>
                      <th width="130">Open Time</th>
                      <th>Close Time</th>
                      <th>&nbsp;</th>
                    </tr>
                  </thead>
                  {availabilityData?.selectedTime &&
                    getTimeSlotsByDay(
                      availabilityData?.selectedTime,
                      selectedDay,
                    ).map((obj, index) => {
                      return (
                        <tbody key={'sunday' + index}>
                          <tr>
                            <td>
                              {moment(obj.start_time, 'HH:mm').format(
                                'hh:mm A',
                              )}
                            </td>
                            <td>
                              {moment(obj.end_time, 'HH:mm').format('hh:mm A')}
                            </td>
                            {enableAddEdit && (
                              <td>
                                <span
                                  className="remove-btn"
                                  onClick={() => {
                                    // deleteSlot(obj);
                                    props.handleBlock(
                                      '',
                                      'deleteConfirmation',
                                      obj,
                                      dropdownId,
                                    );
                                  }}>
                                  <i className="fa-solid fa-circle-xmark" />
                                </span>
                              </td>
                            )}
                          </tr>
                        </tbody>
                      );
                    })}
                </table>
              </div>
}
            </>
          )}
        </>
      )}
      {/* {subTabType === 'Geo_Coverage' && (
        <>
          <div className="mb-3 mt-3">
            <CustomDropdown
              setDropdownId={setDropdownId}
              dropdownId={dropdownId}
              DropDownValues={dropdownValues}
              itemName={['name']}
              className={
                'recruitment_dropdown recruitement_dropdown_setInvites'
              }
            />
          </div>

          <div className="fieldset">
            <Form.Control
              name="Offering"
              pattern="^\S.*$"
              required
              placeholder="Add"
            />
            
          </div>
        </>
      )} */}
    </div>
  );
};

export default Availability;
