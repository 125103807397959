import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import API from "../../Services/SessionServices";
import messageService from "../../Services/MessageService";
import { ThreeDots } from 'react-loader-spinner'

const StageRequestModal = (props) => {
    const [showLoader, setShowLoader] = useState(false);
    const [allRequest, setallRequest] = useState([]);
    const [show, setShow] = useState(props.show);
    const [conferenceInfo, setconferenceInfo] = useState([]);
    const closeModal = () => {
        setShow(false);
        props.closeModal();
    };
    const getAllRequests = () => {
        setShowLoader(true);
        var data = { conference_id: props.conferenceInfo.id };
        API.allmicRequests(data)
            .then((res) => {
                if (res) {
                    setallRequest(res.data);
                    setTimeout(() => {
                        setShowLoader(false);
                    }, 100);
                }
            })
            .catch((err) => {
                
                return { type: "error", message: err.message };
            });
    }
    const acceptStatus = (id) => {

        var data = { user_id: id, conference_id: conferenceInfo.id, mic: 'on', mic_status: 'approved' };
        API.update_mic_status(data)
            .then((res) => {
                if (res) {
                    props.VoxeetSDK.command.send({ dataPayload: { user_id: id, mic: 'on', conference_id: conferenceInfo.id, } })
                        .then((succ) => {
                            props.VoxeetSDK.command
                                .send({ dataPayload: { id: id, name: 'request_accepted' } })
                                .then((succ) => {
                                    
                                    messageService.sendMessage('update_api');
                                })
                                .catch((err) => {
                                  
                                });

                        })
                        .catch((err) => {
                          
                        });
                }
            })
            .catch((err) => {
                
                return { type: "error", message: err.message };
            });

    }
    const rejectStatus = (id) => {
        var data = { user_id: id, conference_id: conferenceInfo.id, mic: 'off', mic_status: 'ignored' };

        API.update_mic_status(data)
            .then((res) => {
                if (res) {
                    props.VoxeetSDK.command
                        .send({ dataPayload: { user_id: id, mic: 'off', conference_id: conferenceInfo.id, } })
                        .then((succ) => {
                            
                        })
                        .catch((err) => {
                            
                        });
                }
            })
            .catch((err) => {
               
                return { type: "error", message: err.message };
            });
    }
    useEffect(() => {
        setconferenceInfo(props.conferenceInfo)
        getAllRequests();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (
        <Modal className="medium" show={show} onHide={() => closeModal()}>
            <Modal.Header>
                <Modal.Title>Stage Requests</Modal.Title>
                <span className="close as-link" onClick={() => closeModal()}>
                    <i className="fas fa-times" />
                </span>
            </Modal.Header>
            <Modal.Body>

                <div className="stage-request-wrapper">
                    {(!showLoader) ?
                        Object.entries(allRequest).length > 0 &&
                        allRequest.map((item, index) => {
                            return (
                                <div className="stage-request-item">

                                    <div className="stage-img">
                                        <img src={item.profile_img} alt="" />
                                    </div>
                                    <div className="stage-name">{item.first_name} {item.last_name}</div>
                                    <div className="stage-likes-icons">
                                        <span onClick={() => acceptStatus(item.user_id)}>
                                            <i className="icon-like-icon" />
                                        </span>
                                        <span onClick={() => rejectStatus(item.user_id)}>
                                            <i className="icon-unlike" />
                                        </span>
                                    </div>

                                </div>

                            )

                        }) : <ThreeDots color="#C48E1D" height={80} width={80} wrapperStyle={{ justifyContent: "center" }} />
                    }
                </div>

            </Modal.Body>
        </Modal>
    );
};

export default StageRequestModal;
