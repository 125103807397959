import React, {useState, useEffect} from 'react';
import {Button} from 'react-bootstrap';
import commonApi from '../../../Services/CommonService';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import {geocodeByPlaceId, getLatLng} from 'react-google-places-autocomplete';
import LoaderSpinner from '../../../Components/Modals/LoaderSpinner';
import {successMessage} from '../../../Utils/ToastMessages';
import {CustomModal, SlidingPanel} from '../../../Components';
import Scrollbars from 'react-custom-scrollbars';
import PrefListItems from '../../../Components/Preferences/PrefListItems';
import MineModel from '../../../Components/Modals/MineModel';
import FocusListItems from '../../../Components/Preferences/FocusListItems';

const SessionFocus = props => {
  // const [ethnicity, setEthnicity] = useState([]);
  // const [gender, setGender] = useState([]);
  // const [identifier, setIdentifier] = useState([]);
  const [isCheckButton, setIsCheckButton] = useState([]);
  const [identiferId, setIdentiferId] = useState([]);
  const [ethnicityId, setEthnicityId] = useState([]);
  const [location, setLocation] = useState('');
  const [locationArray, setLocationArray] = useState([]);
  // const [buttonEnable, setButtonEnable] = useState(false);
  const [showSpinner, setShowSpinner] = useState(true);
  const [showModaL, setShowModel] = useState(false);
  const [locationDel, setLocationDel] = useState('');
  const [showCustomModaL, setShowCustomModaL] = useState(false);
  const [appointmentId, setAppointmentId] = useState([]);
  const [appointmentType, setAppointmentType] = useState([]);
  const [categoriesId, setCategoriesId] = useState([]);
  const [persnalTypes, setPersnalTypes] = useState([]);
  const [categoriesValue, setCategoriesValue] = useState([]);
  const [persnalTypesId, setPersnalTypesId] = useState([]);

  useEffect(() => {
    if (props.mineCatgoriesId && props.mineCatgoriesId.length === 0) {
      setShowCustomModaL(true);
    } else {
      // setShowCustomModaL(true);
    }
    getAppointmentType();
    categories();
    getUserFocus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getAppointmentType = () => {
    commonApi
      .get_appointment_types()
      .then(res => {
        if (res.status === 200) {
          setAppointmentType(res.appointment_type);
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  const categories = () => {
    commonApi
      .get_areas_categories()
      .then(res => {
        if (res.status === 200) {
          setCategoriesValue(res.categories);
          setPersnalTypes(res.areas);
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  const makeReact = (arr, idColName, selectColName) => {
    const returnArr = [];
    arr.forEach(item => {
      if (item[selectColName] === true || item[selectColName] === 'true') {
        returnArr.push(item[idColName]);
      }
    });
    return returnArr;
  };
  const Othersidn = (arr, idColName, selectColName) => {
    const returnArr = [];
    arr.forEach(item => {
      if (item[selectColName] === true || item[selectColName] === 'true') {
        returnArr.push(item[idColName]);
      }
    });
    return returnArr;
  };
  const addValues = () => {
    props.setFocusLocations(locationArray);
    props.setIdentiferId(identiferId);
    props.setEthnicityId(ethnicityId);
    props.setGender(isCheckButton);
    props.setPersnalTypesId(persnalTypesId);
    props.setCategoriesId(categoriesId);
    props.setAppointmentId(appointmentId);
  };
  const removeValues = () => {
    props.setPersnalTypesId([]);
    props.setCategoriesId([]);
    props.setAppointmentId([]);
    props.setFocusLocations([]);
    props.setIdentiferId([]);
    props.setEthnicityId([]);
    props.setGender([]);
  };

  // const ethnicityIdvalue = id => {
  //   let tempArr = [...ethnicityId];

  //   if (tempArr && tempArr.includes(id)) {
  //     tempArr = tempArr.filter(item => item !== id);
  //   } else {
  //     tempArr.push(id);
  //   }

  //   setEthnicityId(tempArr);
  // };

  // const identiferIdvalue = id => {
  //   let tempArr = [...identiferId];

  //   if (tempArr && tempArr.includes(id)) {
  //     tempArr = tempArr.filter(item => item !== id);
  //   } else {
  //     tempArr.push(id);
  //   }

  //   setIdentiferId(tempArr);
  // };

  // const handleButton = e => {
  //   const {id} = e.target;

  //   if (isCheckButton && isCheckButton.includes(id)) {
  //     setIsCheckButton(isCheckButton.filter(item => item !== id));
  //   } else if (isCheckButton.length <= 3) {
  //     setIsCheckButton([...isCheckButton, id]);
  //   }
  // };
  useEffect(() => {}, [isCheckButton]);

  const handle = event => {
    createFocus();
   
  };

  const createFocus = () => {
    var payLoad = {
      city_states: locationArray,
      ethnicity_ids: ethnicityId,
      // gender_ids:
      //   typeof isCheckButton === 'string'
      //     ? isCheckButton.split()
      //     : isCheckButton,
      gender: '',
      other_identifier_ids: identiferId,
      on_boarding_areas: persnalTypesId,

      appointment_types: appointmentId,

      on_boarding_categories: categoriesId,
    };
    commonApi
      .create_focus(payLoad)
      .then(res => {
        if (res.status === 200) {
          successMessage(res.message);
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  const getUserFocus = () => {
    // var payLoad = {};
    commonApi
      .get_user_focus()
      .then(res => {
        if (res.status === 200) {
          // setEthnicity(res.data.ethnicity);
          // setGender(res.data.gender);
          // setIdentifier(res.data.other_identifier);
          setCategoriesId(
            makeReact(res.data.on_boarding_categories, 'id', 'selected'),
          );
          setAppointmentId(
            makeReact(res.data.appointment_type, 'id', 'selected'),
          );
          setPersnalTypesId(
            makeReact(res.data.on_boarding_areas, 'id', 'selected'),
          );

          setLocationArray(res.data.locations);
          setIsCheckButton(makeReact(res.data.gender, 'id', 'selected'));
          setIdentiferId(
            Othersidn(res.data.other_identifier, 'id', 'selected'),
          );
          setEthnicityId(makeReact(res.data.ethnicity, 'id', 'selected'));

          setShowSpinner(false);
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };
  const locationData = () => {
    var place_id = !location ? '' : location.value.place_id;
    
    var perfectAddress = !location.label ? '' : location.label;
    if (place_id && perfectAddress) {
      geocodeByPlaceId(place_id)
        .then(results => getLatLng(results[0]))
        .then(({lat, lng}) => {
          
          var adress = {address: perfectAddress, latitude: lat, longitude: lng};

          setLocationArray(curr => [...curr, adress]);
          setLocation('');
        });
    }
  };
  const removeLocation = () => {
    setLocationArray(
      locationArray.filter(item => item.latitude !== locationDel),
    );
  };
  useEffect(() => {
    location.hasOwnProperty('label') && locationData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <>
      <SlidingPanel hidePanel={() => props.onClose()}>
        {showSpinner && <LoaderSpinner />}

        {!showSpinner && (
          <div className="serach-filter-wrapper">
            <div className="headings fix-width-forms">
              <h2> Focus</h2>
            </div>
            <Scrollbars>
              <div className="focus-modal-content fix-width-forms">
                <div className="checkbox-list">
                  <h3>Industries</h3>

                  <FocusListItems
                    items={categoriesValue}
                    active={categoriesId}
                    updateActive={newItems => setCategoriesId(newItems)}
                  />
                </div>
                <div className="checkbox-list">
                  <h3>Personality Types</h3>

                  <PrefListItems
                    items={persnalTypes}
                    active={persnalTypesId}
                    updateActive={newItems => setPersnalTypesId(newItems)}
                  />
                </div>
                <div className="checkbox-list">
                  <h3>1 on 1</h3>

                  <PrefListItems
                    items={appointmentType}
                    active={appointmentId}
                    updateActive={newItems => setAppointmentId(newItems)}
                  />
                </div>

                {/* <div className="checkbox-list">
                  <h3>Self Identifiers</h3>

                  <PrefListItems
                    items={identifier}
                    active={identiferId}
                    updateActive={newItems => setIdentiferId(newItems)}
                  />
                </div> */}

                <div className="location-list-wrapper">
                  <div className="d-flex justify-content-between align-items-center mb-2">
                    <h3>Locations</h3>
                    {location !== '' && (
                      <span className="clear-btn" onClick={() => {}}>
                        Clear
                      </span>
                    )}
                  </div>

                  <div className="location-field form-control">
                    <GooglePlacesAutocomplete
                      location
                      selectProps={{
                        location,
                        onChange: setLocation,
                        name: 'city',
                        placeholder: 'Type city and state',
                      }}
                    />
                  </div>

                  <ul>
                    {Object.entries(locationArray).length > 0 &&
                      locationArray.map((item, index) => (
                        <li key={`label-${index}`}>
                          <span
                            onClick={() => {
                              setLocationDel(item.latitude);
                              setShowModel(true);
                            }}>
                            {item.address}
                            <i className="fas fa-times" />
                          </span>
                        </li>
                      ))}
                  </ul>
                </div>
              </div>
            </Scrollbars>

            <div className="filter-btn-wrapper pt-3">
              <div className="fix-width-forms">
                {props.type === 'show' ? (
                  <div className="invite-btns p-0">
                    <Button
                      onClick={e => {
                        // setButtonEnable(true);
                        handle(e);
                        props.onClose();
                      }}>
                      {props.focus ? 'Save ' : 'Save Focus Criteria'}
                    </Button>
                  </div>
                ) : (
                  <>
                    <div className="d-flex align-items-center justify-content-between">
                      {!props.topIcons.bullseye && (
                        <Button
                          className="btn-dark"
                          onClick={() => {
                            // setButtonEnable(true);
                            props.setTopIcons({
                              ...props.topIcons,
                              bullseye: true,
                            });
                            addValues();
                            props.onClose();
                          }}>
                          Enable Focus
                        </Button>
                      )}

                      {props.topIcons.bullseye && (
                        <Button
                          className="btn-dark"
                          onClick={() => {
                            // setButtonEnable(true);
                            props.setTopIcons({
                              ...props.topIcons,
                              bullseye: false,
                            });
                            removeValues();
                            props.onClose(false);
                          }}>
                          Disable Focus
                        </Button>
                      )}

                      {/* <Form.Check
                        type="switch"
                        id="custom-switch"
                        label="Update Preferences"
                      /> */}
                      <Button className="btn-dark" onClick={handle}>
                        Update Preferences
                      </Button>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        )}
      </SlidingPanel>

      {/* <SessionEvents setButtonEnable={setButtonEnable} buttonEnable={buttonEnable} 
                onClick={() => props.parent(DataIs)} /> */}
      {showModaL && (
        <CustomModal
          size="small"
          onClose={() => {
            setShowModel(false);
          }}>
          <div className="remove-user-text">
            <h2>REMOVE LOCATION!</h2>
            <p>Are you sure you want to remove this location?</p>
            <Button
              className="btn-dark"
              onClick={() => {
                removeLocation();
                setShowModel(false);
              }}>
              Yes
            </Button>
            <Button
              className="btn-dark"
              onClick={() => {
                setShowModel(false);
              }}>
              No
            </Button>
          </div>
        </CustomModal>
      )}
      {showCustomModaL && (
        <MineModel size="medium" showCloseIcon="no">
          <div className="new-session-modal-wrapper text-center">
            <div className="new-session-modal">
              <h3>Let's grow together!</h3>
              <p>
                Update your preferences to unlock features involving other
                users.{' '}
              </p>

              {/* <h3>Generate Guest Pass</h3> */}
              {/* <span>{sessionLink}</span> */}

              <div className="new-session-btn-wrapper">
                <Button
                  className="btn-dark"
                  onClick={() => {
                    props.onClose();
                  }}>
                  Update Background
                </Button>

                {/* <span
              className=" copy-link-text"
              onClick={() => {
                navigator.clipboard.writeText(sessionLink);
                successMessage(labels.copy_link[selected_lang]);
              }}>
              <i className="icon-link" />
              Copy Link
            </span> */}
              </div>
            </div>
          </div>
        </MineModel>
      )}

      {/* <Modal
        show={showModaL}
        onHide={() => {
          setShowModel(false);
        }}>
        <Modal.Header closeButton>
          <Modal.Title>REMOVE LOCATION!</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are yo sure you want to remove this locayion?</Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              removeLocation();
              setShowModel(false);
            }}>
            yes
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              setShowModel(false);
            }}>
            No
          </Button>
        </Modal.Footer>
      </Modal> */}
    </>
  );
};
export default SessionFocus;
