import React, {useState,useEffect, useRef} from 'react';
import {Button, Form, Dropdown} from 'react-bootstrap';
import Scrollbars from 'react-custom-scrollbars';
import SlidingPanel from '../SlidingPanel';
import {RoiLogo} from '../../Constants/Images';
import {successMessage} from '../../Utils/ToastMessages';
import commonApi from '../../Services/CommonService';
import CustomDropdown from '../CustomDropdown';

const CreatePhase = props => {
  const [boardList, setBoardList] = useState('');
  const [pahseName, setPhaseName] = useState('');
  const [boradId, setBoradId] = useState('');
  const [validated, setValidated] = useState(false);
  const buttonRef = useRef(null);

  const handle = event => {
    event.preventDefault();
    event.stopPropagation();

    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      setValidated(true);
      return;
    }

   
    // Manage the data in an object

    const payload = {
      title: pahseName,
      board_id: boradId,
    };
    create_phase(payload);
  };

  useEffect(() => {
    get_boards();
  }, []);

  const get_boards = () => {
    var payLoad = {
      search_query: '',
    };
    commonApi
      .get_boards(payLoad)
      .then(res => {
        if (res.data.length > 0) {
          setBoardList(res.data);
          const filteredBoard = res.data.find(board => board.id === props.taskBoardId)?.id;
          setBoradId(filteredBoard);
          
        } else {
        }
      })
      .catch(err => {
        return {type: 'error', message: err.message};
      });
  };

  const create_phase = payload => {
    // var payLoad = {
    //   search_query: qry,
    //   user_id: localStorage.getItem('user'),
    // };
    commonApi
      .create_phase(payload)
      .then(res => {
        successMessage(res.message);
        props.onClose();
      })
      .catch(err => {
        return {type: 'error', message: err.message};
      });
  };

  const renderProfileTagsButtons = () => {
    // Return the JSX element conditionally based on props.permission
    return (
      <>
        <img src={RoiLogo} alt="" />
      </>
    );
  };
  return (
    <>
      <SlidingPanel
        renderData={renderProfileTagsButtons}
        showBlackHeader={true}
        lastDivStyle={{borderRadius: '35px', background: '#f7f6f2'}}
        firstDivStyle={{
          padding: 0,
          background:
            'linear-gradient(to bottom, #424242, #424242, #f7f6f2 50%)',
        }}
        hidePanel={() => {
          props.onClose();
        }}>
        <div className="referal_page_content">
          <div className="refer_outside add-contacts referal_page_content">
            <div className="sidebar-heading-wrapper fixed-width-inputs fix-width-forms pt-3 black-head-form-pad-left black-head-form-pad-right">
              <p className="p-bold m-0 f_600_h">Create Phase</p>
            </div>

            <Scrollbars className="custom-scroll">
              <div className="send-refferal-form-wrapper fixed-width-inputs black-head-form-pad-left black-head-form-pad-right mt-2">
                <Form
                  className="send-refferal-form-input-holder"
                  noValidate
                  validated={validated}
                  onSubmit={handle}>
                  <div className="roi-form-input">
                    <Form.Label controlid="validationCustom01">
                      Board
                    </Form.Label>
                    <CustomDropdown
                      // type={'credential'}
                      setDropdownId={setBoradId}
                      dropdownId={boradId}
                      DropDownValues={boardList}
                      itemName={['title']}
                      className={'mb-3 recruitment_dropdown'}
                      // handleDropdownValue={handleAccomplishmentChange}
                      // disabled={props.id ? true : false}
                    />
                  </div>
                  {/* POC Name */}
                  <div className="roi-form-input">
                    <Form.Label controlid="validationCustom01">
                      Phase Name
                    </Form.Label>
                    <Form.Control
                      type="text"
                      value={pahseName}
                      pattern="^\S.*$"
                      required
                      placeholder="Enter Name"
                      onChange={e => setPhaseName(e.target.value)}
                    />
                    <Form.Control.Feedback type="invalid">
                      Phase Name is required.
                    </Form.Control.Feedback>
                  </div>

                  {/* Description (Optional) */}

                  {/* Board (Required) */}

                  <Button
                    type="submit"
                    style={{display: 'none'}}
                    ref={buttonRef}>
                    {props.snapshotIds ? 'Send Refferral' : 'Add Contact'}
                  </Button>
                </Form>
              </div>
            </Scrollbars>

            <div className="filter-btn-wrapper bg_transparent bottom-0">
              <div className="invite-btns p-0 pt-1 text-center">
                <Button
                  type="submit"
                  style={{marginRight: '0px'}}
                  onClick={() => {
                    // setValidated(false);

                    setTimeout(() => {
                      buttonRef.current.click();
                    }, 100);
                  }}>
                  {props.snapshotIds ? 'Send Refferral' : 'Create Phase'}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </SlidingPanel>
    </>
  );
};

export default CreatePhase;
