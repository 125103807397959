import React, {useState, useEffect, useCallback} from 'react';
// import PropTypes from 'prop-types';
import VoxeetSDK from '@voxeet/voxeet-web-sdk';
// import  {consts} from 'react-elastic-carousel';
import ParticipantStream from '../../Components/SessionDetails/ParticipantStream';
import {
  App_Secret_key_Dolby,
  App_key_Dolby,
  DEFAULT_IMAGE,
} from '../../Utils/env';
import {
  startVideo,
  startAudio,
  stopVideo,
  stopAudio,
  startScreen,
  stopScreen,
} from '../../Utils/voxeetUtils';

import commonApi from '../../Services/CommonService';
import {getItem} from '../../Utils/LocalStorage';
import {Row, Col} from 'react-bootstrap';

import LoaderSpinner from '../Modals/LoaderSpinner';
import moment from 'moment';
import {successMessage} from '../../Utils/ToastMessages';
import $ from 'jquery';
import {useHistory} from 'react-router-dom';

const consumerKey = App_key_Dolby;
const consumerSecret = App_Secret_key_Dolby;

VoxeetSDK.initialize(consumerKey, consumerSecret);
const AppointmentCall = props => {
  const history = useHistory();
  const [sampleStateV, setSampleStateV] = useState(0);
  const [sampleStateP, setSampleStateP] = useState(0);
  const [isVideoEnable, setVideoEnable] = useState(true);
  const [isAudioEnable, setAudioEnable] = useState(true);
  const [isScreenEnable, setScreenEnable] = useState(false);
  const [showLoader, setShowLoader] = useState(true);
  const [creatorId, setCreatorId] = useState(null);
  const [appointmentId, setAppointmentId] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const [videoStreamList, setVideoStreamList] = useState([]);
  const [remainingTime, setRemainingTime] = useState('');

  useEffect(() => {
    $('.session-videos').parent('.panel-secondary').addClass('AppointmentCall-Panel');
  })

  useEffect(() => {
    if (props.confData.hasOwnProperty('appointment_details')) {
      localStorage.setItem('creator_id', props.confData.appointment_details.creator_id)
      setCreatorId(props.confData.appointment_details.creator_id);
      setAppointmentId(props.confData.appointment_details.id);
      setEndTime(props.confData.appointment_details.end_time);
    } else if (props.confData.hasOwnProperty('appointment_detail')) {
      localStorage.setItem('creator_id', props.confData.appointment_detail[0].creator_id)
      setCreatorId(props.confData.appointment_detail[0].creator_id);
      setAppointmentId(props.confData.appointment_detail[0].appointment_id);
      setEndTime(props.confData.appointment_detail[0].end_time);
    }
    setTimeout(() => {
      setShowLoader(false);
      setSampleStateV(prev => prev + 1);
    }, 5000);
    setTimeout(() => {
      setSampleStateV(prev => prev + 1);
    }, 5500);
    setTimeout(() => {
      JoinConference(props.confData.session_id);
    }, 2000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // 
  const AppointmentCreator=  (props.confData && props.confData.details && props.confData.details.created_by) || (props.confData && props.confData.details && props.confData.streamings_detail.created_by)
  useEffect(() => {
    const currentDate = moment().format('YYYY-MM-DD');
    const endTimeString = `${currentDate} ${endTime}:00`;
    const endTimeMoment = moment(endTimeString);

    const interval = setInterval(() => {
      commonApi.check_server_time().then(res => {
        // 
        // 
        // callTimeUp(res.server_time.slice(11, 16));
        // const currentTime = moment();
        const remainingTime = moment.duration(
          endTimeMoment.diff(res.server_time),
        );

        if (remainingTime.asSeconds() <= 0) {
          setRemainingTime('Time Ends');
          clearInterval(interval);
        } else {
          const remainingMinutes = Math.floor(remainingTime.asMinutes());
          const remainingSeconds = remainingTime.seconds();
          setRemainingTime(
            `${remainingMinutes.toString().padStart(2, '0')}:${remainingSeconds
              .toString()
              .padStart(2, '0')}`,
          );
        }
        let EndedCall = false;
        // setRemainingTime(differenceInMinutes);
        if (endTime && endTime <= res.server_time.slice(11, 16) && !EndedCall) {
          EndedCall = true;
          endCall();
          setTimeout(() => {
            successMessage('Time is ended');
          }, 2000);
        }
      });
    }, 1000);
    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [endTime]);

  useEffect(() => {
    if (isVideoEnable || isScreenEnable) {
      setTimeout(() => {
        setSampleStateP(prev => prev + 1);
      }, 2000);
    }
  }, [isVideoEnable, isScreenEnable]);

  // const callTimeUp = serverTime => {
    
    
  //   if (endTime && endTime <= serverTime) {
      
  //     endCall();
  //   }
  // };

  const JoinConference = id => {
    const userName =
      localStorage.getItem('firstName') +
      ' ' +
      localStorage.getItem('lastName');
    const avatarPic = localStorage.getItem('profilePic')
      ? localStorage.getItem('profilePic')
      : DEFAULT_IMAGE;
    const externalId = localStorage.getItem('id');
    const mixer = {
      name: userName,
      externalId: externalId,
      avatarUrl: avatarPic,
    };
    const joinOptions = {constraints: {video: true, audio: true}};
    // checkUserLeaveByUrl();
    VoxeetSDK.session
      .open(mixer)
      .then(() => VoxeetSDK.conference.fetch(id))
      // Join the conference
      .then(conference => {
        try {
          // 
          VoxeetSDK.conference
            .join(conference, joinOptions)
            .catch(err => console.error(err));
          // streamEvents();
          // UpdateSessionDetails(localStorage.getItem("conf_id"));
          setTimeout(() => {
            setSampleStateP(prev => prev + 1);
          }, 2000);
          streamEvents();
        } catch (error) {
          console.error(error);
        }
      })
      .catch(err => console.error(err));
  };

  const endCall = () => {
    
    if (creatorId === getItem('id')) {
      let payLoad = {appointment_id: appointmentId};
      commonApi
        .end_call(payLoad)
        .then(res => {
          
          VoxeetSDK.command
            .send({dataPayload: 'close_session'})
            .then(success => {
              
              VoxeetSDK.conference
                .leave(VoxeetSDK.session.participant)
                .then(() => {
                  VoxeetSDK.session.close();
                  props.onClose();
                  // history.push('/appointmentpage');
                 
                  if (props.subTabType) {
                    props.getMyAppointmentsByType(props.subTabType);
                  }
                })
                .catch(err => console.error(err));
            })
            .catch(error => {
              console.log(error);
            });
        })
        .catch(err => {
          console.log('Err', err);
          return {type: 'error', message: err.message};
        });
    } else {
      VoxeetSDK.conference
        .leave(VoxeetSDK.session.participant)
        .then(() => {
          VoxeetSDK.session.close();
          // userLeftCall(props.confData.conference_id);
          if(props.type==='sidebar'){
            history.push("/schedule-appointment");
            history.push({
              pathname: '/schedule-appointment',
              state: {
                close_session: 'yes',
                creator_id: {
                  id: localStorage.getItem('creator_id'),
                  profile_img: localStorage.getItem('profilePic'),
                  first_name: localStorage.getItem('firstName'),
                  last_name: localStorage.getItem('lastName'),
                },
              },
            })
          }
          else{
            props.onClose();
          }
          
          
        })
        .catch(err => console.error(err));
    }
  };

  // const endCall = () => {
  //   if (props.confData.started_by === getItem('id')) {
  //     VoxeetSDK.command
  //       .send({dataPayload: 'close_session'})
  //       .then(success => {
  //         
  //         VoxeetSDK.conference
  //           .leave(VoxeetSDK.session.participant)
  //           .then(() => {
  //             VoxeetSDK.session.close();
  //             userLeftCall(props.confData.conference_id);
  //             props.onClose();
  //             //   history.push("/sessions-events");
  //           })
  //           .catch(err => console.error(err));
  //       })
  //       .catch(error => {
  //         console.log(error);
  //       });
  //   } else {
  //     VoxeetSDK.conference
  //       .leave(VoxeetSDK.session.participant)
  //       .then(() => {
  //         VoxeetSDK.session.close();
  //         userLeftCall(props.confData.conference_id);
  //         props.onClose();
  //         //   history.push("/sessions-events");
  //       })
  //       .catch(err => console.error(err));
  //   }
  // };

  // const userLeftCall = async id => {
  //   const payload = {conference_id: id};
  //   const response = await commonApi.user_left_opportunity_call(payload);
  //   if (response) {
  //   }
  // }; sorry

  // const myArrow = ({type, onClick, isEdge}) => {
  //   const directionClass = type === consts.PREV ? 'slide-prev' : 'slide-next';
  //   const pointer =
  //     type === consts.PREV ? (
  //       <i className="fas fa-angle-up"></i>
  //     ) : (
  //       <i className="fas fa-angle-down"></i>
  //     );
  //   return (
  //     <button
  //       className={`slider-nav ${directionClass}`}
  //       onClick={onClick}
  //       disabled={isEdge}>
  //       {pointer}
  //     </button>
  //   );
  // };

  // const ListviewContent = useCallback(() => {
  //   return (
  //     <Row className="row-reverse">
  //       <Col md={12}>
  //         <div className="session-video">
  //           <div
  //             className="video-item"
  //             id="large-video-container"
  //             style={{display: 'none'}}>
  //             <video
  //               id={`video-objectlarge`}
  //               className="participant-grid-item__video"
  //               playsInline
  //               autoPlay
  //             />
  //           </div>
  //         </div>
  //       </Col>
  //       <Col md={9}>
  //         <div className="session-videos-slider verticle-slider">
  //           <ParticipantViewList />
  //         </div>
  //       </Col>
  //     </Row>
  //   );
  // }, [sampleStateV]);

  const ParticipantViewList = useCallback(() => {
    // return (
    //   <Carousel
    //     verticalMode={true}
    //     renderArrow={myArrow}
    //     pagination={false}
    //     itemsToShow={4}
    //     itemPadding={[10, 0, 10, 0]}>
    //     {Object.entries(Array.from(VoxeetSDK.conference.participants.values()))
    //       .length > 0 &&
    //       Array.from(VoxeetSDK.conference.participants.values()).map(item => {
    //         return item.status === 'Connected' &&
    //           item.streams.length > 0 &&
    //           item.streams[0].getVideoTracks().length > 0 ? (
    //           <div
    //             key={`ses-vid-l-${item.id}`}
    //             className="video-items"
    //             onClick={() =>
    //               ShowFullVideo(item.id, item.streams[0].id, item.streams[0])
    //             }>
    //             <ParticipantStream
    //               participant={item}
    //               stream={item.streams[0]}
    //             />
    //           </div>
    //         ) : item.status === 'Connected' &&
    //           item.streams.length > 1 &&
    //           item.streams[1].getVideoTracks().length > 0 ? (
    //           <div
    //             key={`ses-vid-l-${item.id}`}
    //             className="video-items"
    //             onClick={() =>
    //               ShowFullVideo(item.id, item.streams[1].id, item.streams[1])
    //             }>
    //             <ParticipantStream
    //               participant={item}
    //               stream={item.streams[1]}
    //             />
    //           </div>
    //         ) : null;
    //       })}
    //   </Carousel>
    // );
    return (
      <div
      // direction={'vertical'}
      // slidesPerView={'auto'}
      // spaceBetween={15}
      // navigation={true}
      // modules={[Navigation]}
       className="Video-Blocks"
      >
        {Object.entries(Array.from(VoxeetSDK.conference.participants.values()))
          .length > 0 &&
          Array.from(VoxeetSDK.conference.participants.values()).map(item => {
            
            return item.status === 'Connected' &&
              item.streams.length > 0 &&
              item.streams[0].getVideoTracks().length > 0 ? (
              <div key={'ses-id-l-' + item.id} className={ item.info && item.info.externalId  !== AppointmentCreator ? 'Participant-Video' : 'My-Video'}>
                <div
                  key={`ses-vid-l-${item.id}`}
                  className="video-items"
                  onClick={() =>
                    ShowFullVideo(item.id, item.streams[0].id, item.streams[0])
                  }>
                  <ParticipantStream
                    participant={item}
                    stream={item.streams[0]}
                  />
                </div>
              </div>
            ) : item.status === 'Connected' &&
              item.streams.length > 1 &&
              item.streams[1].getVideoTracks().length > 0 ? (
              <div key={'ses-id-' + item.id}>
                <div
                  key={`ses-vid-l-${item.id}`}
                  className="video-items"
                  onClick={() =>
                    ShowFullVideo(item.id, item.streams[1].id, item.streams[1])
                  }>
                  <ParticipantStream
                    participant={item}
                    stream={item.streams[1]}
                  />
                </div>
              </div>
            ) : null;
          })}
      </div>
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const ShowFullVideo = (id, streamId, stream) => {
    
    document.getElementById('large-video-container').style.display = 'block';
    let perspective = 'self-view';
    let videoNode = document.getElementById('video-objectlarge');
    if (!videoNode) {
      videoNode = document.createElement('video');
      videoNode.setAttribute('id', id);
      videoNode.setAttribute('height', '100%');
      videoNode.setAttribute('width', '100%');
      videoNode.muted = false;
      videoNode.autoplay = true;
      videoNode.playsinline = true;

      const videoContainer = document.getElementById(perspective);

      videoContainer.lastElementChild.replaceWith(videoNode);
      videoContainer.firstElementChild.innerText = 'asadss';
    }
    videoNode.volume = 0.0;
    localStorage.setItem('largeVideo', streamId);
    navigator.attachMediaStream(videoNode, stream);
  };

  const manageScreenSharing = async () => {
    if (isScreenEnable) {
      const response = await stopScreen();
      if (response) {
        setScreenEnable(false);
      }
    } else {
      const response = await startScreen();
      if (response) {
        setScreenEnable(true);
      }
    }
  };

  const streamEvents = () => {
    
    let moderatorEndedCall = false; // Flag variable to track if the 'Moderator ended the call' message has been displayed
    VoxeetSDK.command.on('received', (participant, message) => {
      let broadcast = JSON.parse(message);
      
      
      if (broadcast.dataPayload === 'close_session' && !moderatorEndedCall) {
        moderatorEndedCall = true; // Set the flag to true after displaying the message
        successMessage('Call ended by the creator');
        endCall();
      }
    });

    VoxeetSDK.conference.on('joined', () => {
      
    });
    VoxeetSDK.conference.on('participantAdded', participant => {
     
      
      // setSampleStateV(prev => prev + 1);
      // setSampleStateP(prev => prev + 1);
    });
    VoxeetSDK.conference.on('participantUpdated', participant => {
    
     
      setSampleStateV(prev => prev + 1);
      // setSampleStateP(prev => prev + 1);
    });
    VoxeetSDK.conference.on('left', () => {
      

      // const participants = VoxeetSDK.conference.participants;
      // var allPersons = [];
      // let count = 0;
      // participants.forEach(element => {
      //   if (element.status !== 'Left') {
      //     allPersons.push(element);
      //     if (
      //       element.info.externalId == localStorage.getItem('creator_id') &&
      //       count > 0
      //     ) {
      //       let b = allPersons[count];
      //       allPersons[count] = allPersons[0];
      //       allPersons[0] = b;
      //     }
      //     count++;
      //   }
      // });
      // console.log(
      //   '--------------------get all persons list---------------------',
      // );
      // 
      // 
      // UpdateSessionDetails(localStorage.getItem('conf_id'));
    });

    VoxeetSDK.conference.on('streamAdded', (participant, stream) => {
     
     
      
      
      // setSampleStateV(prev => prev + 1);
      // setSampleStateP(prev => prev + 1);
      if (
        stream &&
        stream.getVideoTracks() &&
        stream.getVideoTracks().length > 0
      ) {
        // setVideoStreamList(current => {
        //   const found = current.find(
        //     objs =>
        //       objs.participant.info.externalId === participant.info.externalId,
        //   );
        //   if (typeof found === 'object') {
        //     return current.map(obj => {
        //       if (
        //         obj.participant.info.externalId === participant.info.externalId
        //       ) {
        //         return {...obj, participant: participant, stream: stream};
        //       }
        //       return obj;
        //     });
        //   } else {
        //     return [
        //       ...current,
        //       {
        //         participant: participant,
        //         stream: stream,
        //       },
        //     ];
        //   }
        // });
        setVideoStreamList(current => [
          ...current,
          participant.info.externalId,
        ]);
        setSampleStateV(prev => prev + 1);
      }
      // if (stream.type === 'ScreenShare') {
      //   setSampleStateV(prev => prev + 1);
      // }
    });

    // When a video stream is updated from the conference
    VoxeetSDK.conference.on('streamUpdated', (participant, stream) => {
   
      
      

      if (
        stream &&
        stream.getVideoTracks() &&
        stream.getVideoTracks().length > 0
      ) {
        // setVideoStreamList(current => {
        //   const found = current.find(
        //     objs =>
        //       objs.participant.info.externalId === participant.info.externalId,
        //   );
        //   if (typeof found === 'object') {
        //     return current.map(obj => {
        //       if (
        //         obj.participant.info.externalId === participant.info.externalId
        //       ) {
        //         return {...obj, participant: participant, stream: stream};
        //       }
        //       return obj;
        //     });
        //   } else {
        //     return [
        //       ...current,
        //       {
        //         participant: participant,
        //         stream: stream,
        //       },
        //     ];
        //   }
        // });
        setVideoStreamList(current => {
          if (current.includes(participant.info.externalId)) {
            return current;
          } else {
            setSampleStateV(prev => prev + 1);
            return [...current, participant.info.externalId];
          }
        });
      } else {
        setVideoStreamList(current =>
          current.filter(item => {
            if (item !== participant.info.externalId) {
              return true;
            } else {
              // setSampleStateV(prev => prev + 1);
              return false;
            }
          }),
        );
        let largeVideoShow = localStorage.getItem('largeVideo');
        if (largeVideoShow === stream.id) {
          document.getElementById('large-video-container').style.display =
            'none';
        }
      }

      // setSampleStateV(prev => prev + 1);
      // setSampleStateP(prev => prev + 1);
    });

    // When a video stream is removed from the conference
    VoxeetSDK.conference.on('streamRemoved', (participant, stream) => {
     
      
      
      let largeVideoShow = localStorage.getItem('largeVideo');
      if (largeVideoShow === stream.id) {
        document.getElementById('large-video-container').style.display = 'none';
      }
      if (participant.info.externalId === getItem('id')) {
        setScreenEnable(false);
      }
      // setVideoStreamList(current =>
      //   current.filter(
      //     item =>
      //       item.participant.info.externalId !== participant.info.externalId,
      //   ),
      // );
      setVideoStreamList(current =>
        current.filter(item => {
          if (item !== participant.info.externalId) {
            return true;
          } else {
            // setSampleStateV(prev => prev + 1);
            return false;
          }
        }),
      );
      // setSampleStateV(prev => prev + 1);
      // setSampleStateP(prev => prev + 1);
    });
  };

  return showLoader ? (
  <LoaderSpinner  sampleStateP={sampleStateP} sampleStateV={sampleStateV} videoStreamList={videoStreamList}/>
  ) : (
    <div className="session-videos" id="ListviewContent">
      <div className="session-videos-list AppointmentCall">
        {/* <ListviewContent sampleStateP={sampleStateP} /> */}
        <Row className="row-reverse">
          <Col md={12} id="large-video-container" style={{display: 'none'}}>
            <div className="session-video">
              <div className="video-item" id="large-video-container">
                <video
                  id={`video-objectlarge`}
                  className="participant-grid-item__video"
                  playsInline
                  autoPlay
                />
              </div>
            </div>
          </Col>
          <Col md={12}>
            <div className="session-videos-slider verticle-slider">
              <div className='RemainingCallTime'>
                {remainingTime &&
                  remainingTime !== 'Time Ends' &&
                  'Time Left: ' + remainingTime + ' min'}
                {remainingTime && remainingTime === 'Time Ends' && remainingTime}
                {/* {remainingTime && remainingTime == '00' && 'Remaining time few seconds '} */}
              </div>
              <ParticipantViewList />
              <div className="video-action-icons">
                <div
                  className="icon-item hangup"
                  onClick={() => {
                    endCall();
                  }}>
                  <span className="icon-hangup" />
                </div>
                {!isVideoEnable && (
                  <div
                    className={`icon-item ${isScreenEnable ? 'active' : ''}`}
                    onClick={() => {
                      manageScreenSharing();
                    }}>
                    <span className="icon-clone" />
                  </div>
                )}
                {!isScreenEnable && (
                  <div
                    className={`icon-item ${isVideoEnable ? 'active' : ''}`}
                    onClick={() => {
                      if (isVideoEnable) {
                        stopVideo();
                        setVideoEnable(false);
                      } else {
                        startVideo();
                        setVideoEnable(true);
                      }
                    }}>
                    <span className="icon-camera-rotate" />
                  </div>
                )}
                <div className="icon-item">
                  {isAudioEnable ? (
                    <span
                      className={'icon-mic-2'}
                      onClick={() => {
                        stopAudio();
                        setAudioEnable(false);
                      }}></span>
                  ) : (
                    <span
                      className={'icon-mute-icon'}
                      onClick={() => {
                        startAudio();
                        setAudioEnable(true);
                      }}></span>
                  )}
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </div>
      {/* <div className="video-icon">
        {isVideoEnable ? (
          <span
            className={`stage-header-icon`}
            onClick={() => {
              // videoStartBtn();
              stopVideo();
              // 
              setVideoEnable(false);
            }}>
            Disable <i className="fas fa-video" />
          </span>
        ) : (
          <span
            className={`stage-header-icon active`}
            onClick={() => {
              // stopVideo();
              startVideo();
              // 
              setVideoEnable(true);
            }}>
            Enable <i className="fas fa-video" />
          </span>
        )}
        {isAudioEnable ? (
          <span
            className={'action-icon audio-icon active'}
            onClick={() => {
              stopAudio();
              setAudioEnable(false);
            }}>
            <i className="fas fa-microphone" />
          </span>
        ) : (
          <span
            className={`action-icon audio-icon `}
            onClick={() => {
              startAudio();
              setAudioEnable(true);
            }}>
            <i className="fas fa-microphone" />
          </span>
        )}
        <div
          onClick={() => {
            VoxeetSDK.conference
              .leave(VoxeetSDK.session.participant)
              .then(() => {
                VoxeetSDK.session.close();
                userLeftCall(props.confData.conference_id);
                props.onClose();
                //   history.push("/sessions-events");
              })
              .catch(err => console.error(err));
          }}>
          {props.confData.started_by == getItem('id') ? 'End Call' : 'Leave'}
        </div>
      </div> */}
    </div>
  );
};

// AppointmentCall.propTypes = {};

export default AppointmentCall;
