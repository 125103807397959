import React, {useState, useEffect} from 'react';
import {labels} from '../../Constants/Translations';
import Invite from '../SingleComponent/Invite';
import CircleListBuilders from '../../Pages/Front/StaticPages/CircleListBuilders';
import SlidingPanel from '../SlidingPanel';
import Scrollbars from 'react-custom-scrollbars';
import {selected_lang} from '../../Utils/env';
import {Button, Form} from 'react-bootstrap';
import CustomTabs from '../CustomTabs';
import commonApi from '../../Services/CommonService';
import EventBriteItems from '../EventBriteItems';
import PersnalSvg from '../../Svg/PersnalSvg';
import PersonalCreateModel from '../Modals/PersonalCreateModel';
import {errorMessage, successMessage} from '../../Utils/ToastMessages';
import LoaderSpinner from '../Modals/LoaderSpinner';

const EventBrite = props => {
  const [tabType, setTabType] = useState(
    labels.sessions_type_general[selected_lang],
  );
  const [category, setCategory] = useState('16');

  const [subTabs, setSubTabs] = useState('moderator');
  const [modrator, setModrator] = useState([]);

  const [selectedModData, setSelectedModData] = useState([]);
  const [selectedAudData, setSelectedAudData] = useState([]);
  const [audience, setAudience] = useState([]);
  const [contact, setContact] = useState([]);
  const [originalDataOfMod, setOriginalDataOfMod] = useState([]);
  const [originalDataOfAud, setOriginalDataOfAud] = useState([]);

  const [showComponent, setShowComponent] = useState('');
  const [showSpinner, setShowSpinner] = useState(false);

  const [eventBriteList, setEventBriteList] = useState(
    props.eventBriteEventsData.length > 0 ? props.eventBriteEventsData : [],
  );
  const [sessionCategories, setSessionCategories] = useState(
    props.sessionCategories && props.sessionCategories.length > 0
      ? props.sessionCategories
      : [],
  );
  const [evenBriteEvents, setEvenBriteEvents] = useState([]);

  const [topIcons, setTopIcons] = useState({
    focus: false,
    location: false,
    vaccinate: false,
    dollar: false,
    store: false,
    bullseye: false,
    personal: false,
  });

  const [inviteLoader, setInviteLoader] = useState(true);
  const [inviteFollowers, setInviteFollowers] = useState([]);
  const [followersList, setFollowersList] = useState([]);
  const [inviteQry, setInviteQry] = useState('');

  const handleCircle = () => {
    setShowComponent('Builders');
  };

  const CircleUserListData = item => {
    if (item.length > 0) {
      if (subTabs === 'moderator') {
        item.forEach((miniItem, index) => {
          if (
            !modrator.includes(miniItem.id) &&
            !audience.includes(miniItem.id)
          ) {
            setModrator(previous => [...previous, miniItem.id]);
            setSelectedModData(previous => [...previous, miniItem]);
            setOriginalDataOfMod(previous => [...previous, miniItem]);
          }
        });
      }
      if (subTabs === 'audience') {
        item.forEach((miniItem, index) => {
          if (
            !audience.includes(miniItem.id) &&
            !modrator.includes(miniItem.id)
          ) {
            setAudience(previous => [...previous, miniItem.id]);
            setSelectedAudData(previous => [...previous, miniItem]);
            setOriginalDataOfAud(previous => [...previous, miniItem]);
          }
        });
      }
    }
  };

  useEffect(() => {
    getSessionCatData();
    getEventsData();
  }, []);

  useEffect(() => {
    if (contact.length > 0) {
      setInviteLoader(true);
      setInviteQry('');
    }
  }, [contact]);

  const getEventsData = () => {
    var Payload = {
      user_id: localStorage.getItem('id').toString(),
      account_id: props.selectedEventbriteId
        ? props.selectedEventbriteId.toString()
        : '',
    };

    commonApi
      .get_events_against_user_accounts(Payload)
      .then(res => {
        if (res.status === 200) {
          setEventBriteList(res.data.events);
        }
      })
      .catch(err => {
        
        return {type: 'error', message: err.message};
      });
  };

  const AddMultipleEventsData = () => {
    var Payload = {
      user_id: localStorage.getItem('id').toString(),
      audience_ids: selectedAudData,
      category_id: category.toString(''),
      moderator_ids: selectedModData,
      selectedEventBrite: evenBriteEvents,
      workspace_ids: props.workSpaceIds.toString(','),
    };

    commonApi
      .add_multiple_events(Payload)
      .then(res => {
        if (res.status === 200) {
          //
          successMessage('Events added successfully');
          props.onClose2();
        }
      })
      .catch(err => {
       
        return {type: 'error', message: err.message};
      });
  };

  const getSessionCatData = () => {
    commonApi
      .session_categories()
      .then(res => {
        if (res.status === 200) {
          setSessionCategories(res.data);
        }
      })
      .catch(err => {
        
        return {type: 'error', message: err.message};
      });
  };

  const addEventData = () => {
    if (eventBriteList.length > 0) {
      if (evenBriteEvents.length > 0) {
        AddMultipleEventsData();
      } else {
        errorMessage('Please Select atleast one event');
      }
    } else {
      props.onClose();
    }
  };

  return (
    <>
      <SlidingPanel
        hidePanel={() => {
          props.onClose();
        }}>
        {/* <div className="serach-filter-wrapper create-sessionEvents">
          <div className="ticket-icons-options">
            <ul>
              <li
                className={topIcons.personal ? 'active' : ''}
                
                onClick={() => {
                  setTopIcons({
                    ...topIcons,
                    ['personal']: !topIcons.personal,
                  });
                  setShowComponent('persnal-model');
                }}>
                {topIcons.personal ? (
                <PersnalSvg fill={'white'} />
                 ) : (
                  <PersnalSvg fill={'black'} />
                )} 
              </li>
            </ul>
          </div>
        </div> */}
        <Scrollbars>
          <div className="fix-width-forms">
            <div className="create-ticket-tabs">
              <CustomTabs
                darkmode
                active={tabType}
                onSelect={item => {
                  setTabType(item);
                  if (
                    item === labels.sessions_type_general[selected_lang] &&
                    inviteQry !== ''
                  ) {
                    setInviteFollowers(followersList);
                    setInviteQry('');
                  }
                }}
                tabs={[
                  labels.sessions_type_general[selected_lang],
                  labels.sessions_type_invites[selected_lang],
                ]}
              />
            </div>

            <div className="messages-users-list">
              <div className="create-ticket-form ">
                {/* <Form noValidate validated={validated} onSubmit={handle}> */}
                {tabType === labels.sessions_type_general[selected_lang] && (
                  <>
                    <div className="fieldset ">
                      <Form.Label>What are we connecting about?</Form.Label>
                      <div className="select-wrapper ">
                        <select
                          value={category}
                          onChange={e => {
                            //
                            setCategory(e.target.value);
                          }}>
                          {Object.entries(sessionCategories).length > 0 &&
                            sessionCategories.map((item, index) => (
                              <option
                                id={item.id}
                                value={item.id}
                                key={`sessionCategories-${index}`}>
                                {item.ses_category_name}
                              </option>
                            ))}
                        </select>
                      </div>
                    </div>

                    <div className="">
                      {/* {showSpinner && <LoaderSpinner />} */}

                      {!showSpinner &&
                      Object.entries(eventBriteList).length > 0 ? (
                        eventBriteList.map((item, index) => (
                          <EventBriteItems
                            //   key={`event-upcoming-${index}`}
                            //   item={item}
                            evenBriteEvents={evenBriteEvents}
                            setEvenBriteEvents={setEvenBriteEvents}
                            type={'eventbrite'}
                            item={item}
                            index={index}
                            //   eventDetails={eventDetails}
                            //   componentDecesion={
                            //     componentDecesion
                            //   }
                          />
                        ))
                      ) : (
                        <div>{labels.no_record_found[selected_lang]}</div>
                      )}
                    </div>

                    <Button
                      // disabled={showLoader ? true : false}
                      className="btn-dark"
                      type="submit"
                      onClick={() => {
                        // buttonRef.current.click();

                        addEventData();
                      }}>
                      Add Event
                    </Button>
                  </>
                )}
                {tabType === labels.sessions_type_invites[selected_lang] && (
                  <>
                    <div className="refferal-list-wrapper">
                      {inviteLoader && (
                        <div className="invite_loader_hieght">
                          <LoaderSpinner />
                        </div>
                      )}
                      <Invite
                        // type={'session'}
                        moderator={modrator}
                        setModerator={setModrator}
                        selectedModData={selectedModData}
                        setSelectedModData={setSelectedModData}
                        audience={audience}
                        setAudience={setAudience}
                        selectedAudData={selectedAudData}
                        setSelectedAudData={setSelectedAudData}
                        contact={contact}
                        setContact={setContact}
                        originalDataOfMod={originalDataOfMod}
                        setOriginalDataOfMod={setOriginalDataOfMod}
                        originalDataOfAud={originalDataOfAud}
                        setOriginalDataOfAud={setOriginalDataOfAud}
                        subTabs={subTabs}
                        setSubTabs={setSubTabs}
                        //   alreadyAddedMod={alreadyAddedMod}
                        handleCircle={handleCircle}
                        //   editEvent={
                        //     props.editSession || props.editEvent ? true : false
                        //   }
                        onClick={() => {
                          setShowComponent('inviteUser');
                          setTimeout(() => {
                            if (contact.length === 0 && inviteQry !== '') {
                              setInviteFollowers(followersList);
                              setInviteQry('');
                            }
                          }, 1000);
                        }}
                        showLoader={inviteLoader}
                        setshowLoader={setInviteLoader}
                        followers={inviteFollowers}
                        setFollowers={setInviteFollowers}
                        setFollowersList={setFollowersList}
                        followersList={followersList}
                        setSearchQry={setInviteQry}
                        searchQry={inviteQry}
                      />
                    </div>
                    {/* setShowSpinner={setShowSpinner}
                    setSessionCategories={setSessionCategories}
                    setEventBriteList={setEventBriteList} */}
                  </>
                )}
                {/* </Form> */}
              </div>
            </div>
          </div>
        </Scrollbars>
      </SlidingPanel>

      {showComponent === 'Builders' && (
        <CircleListBuilders
          onClose={() => {
            setShowComponent('');
          }}
          hide={true}
          CircleUserListData={CircleUserListData}
        />
      )}

      {showComponent === 'persnal-model' && (
        <PersonalCreateModel
          list={props.list}
          setWorkSpaceIds={props.setWorkSpaceIds}
          workSpaceIds={props.workSpaceIds}
          onClose={() => {
            setShowComponent('');
            setTopIcons({
              ...topIcons,
              personal: !topIcons.personal,
            });
          }}
        />
      )}
    </>
  );
};

export default EventBrite;
