import React, {useEffect,useRef} from 'react';
import {Button, Form} from 'react-bootstrap';
import Sidebar from '../../Pages/Sidebar';

import {useState} from 'react';
import {Bandage} from '../../Constants/Images';

import {Pannels} from '../../Components';
import Scrollbars from 'react-custom-scrollbars';

import CreateWorkSpace from '../../Components/WorkSpace/CreateWorkSpace';
import {
  ArrowIcon,
  FireIcon,
  MachineIcon,
  WhitePenIcon,
  timeIcon,
  bagIcon,
  heartIcon,
} from '../../Constants/Images';
import {selected_lang} from '../../Utils/env';
import {labels} from '../../Constants/Translations';
import {rewardStar} from '../../Constants/Images';

import Slider from 'react-slick';
import CreateInvoice from '../InvoiceEstimate/CreateInvoice';
import InvoiceDeatil from '../InvoiceEstimate/InvoiceDeatil';
import commonApi from '../../Services/CommonService';

import {DEFAULT_IMAGE} from '../../Utils/env';
import {
  fixName,
  checkPermissions,
  checkPayableAmount,
} from '../../Utils/commonUtils';
import moment from 'moment';
import LeaderBoardDeatil from '../LeaderBoard/LeaderBoardDeatil';
import LoaderSpinner from '../Modals/LoaderSpinner';
import CustomModal from '../../Components/CustomModal';
import {useSelector} from 'react-redux';

import {loadStripe} from '@stripe/stripe-js';

import {
  PaymentElement,
  Elements,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';
import CheckoutForm from '../SessionDetails/CheckoutForm';
import ScrollLoader from '../Modals/ScrollLoader';


const TechnicianPayment = props => {
  const [showComponent, setShowComponent] = useState('');

  const [isFloated, toggleFloatedPanel] = useState(false);
  const [showHiddenPanel, toggleHiddenPanel] = useState(false);
  const [workspaceData, setWorkspaceData] = useState([]);
  const [threeDots, setThreeDots] = useState(false);
  const [chatSearchQry, setChatSearchQry] = useState('');
  const [tabType, setTabType] = useState('invoices');
  const [currentComponent, setCurrentComponent] = useState('');
  const [analycticsSlider, setAnalycticsSlider] = useState(false);
  const [invoices, setInvoices] = useState([]);
  const [paidInvoices, setPaidInvoices] = useState([]);
  const [invoiceItem, setInvoiceItem] = useState({});
  const [estimateItem, setEstimateItem] = useState({});
  const [estimates, setEstimates] = useState([]);
  const [showLoader, setShowLoader] = useState(true);
  const [selectedEstimate, setSelectedEstimate] = useState([]);
  const [selectedEstimateItemCost, setSelectedEstimateItemCost] = useState({});
  const [showFilter, setShowFilter] = useState(false);
  const [value_filter, setValue_filter] = useState('to_me');
  const [selectedFilterValue, setSelectedFilterValue] = useState('to_me');
  const [showStripeModal, setShowStripeModal] = useState(false);

  const [selectedPrice, setSelectedPrice] = useState('');
  const [paymentId, setPaymentId] = useState('');

  const [searchQuery, setSearchQuery] = useState('');

  //Pagination States
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const isFetching = useRef(false);
  const hasMoreData = useRef(false);

  const stripePromise = loadStripe(
    'pk_test_51PEB5a2K8W7Cs25henyw4Ahf3eGMQgtDgRztlGV4POXFghfMEH5DDg5PtNpT916JChWA9ELeby2Znh5mu2IjXyuY00TWUVZN1V',
  );

  const menuPermesions = useSelector(state => state.menuPermesionList);

  const PaymentPermessions =
    menuPermesions && checkPermissions(menuPermesions, 'Payments');

  const filterListArray = [
    {id: '1', value: 'from_me', title: 'From me'},
    {id: '2', value: 'to_me', title: 'To me'},
  ];

  const rewardSlider = {
    dots: false,
    arrows: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
  };

  const rewardSliderData = [
    {
      name: 'Sales',
      money: '$2.4k',
      time: 'versus last month',
    },

    {
      name: 'Paid On-Time',
      money: '85%',
      time: 'versus last month',
    },

    {
      name: 'Conversion',
      money: '25%',
      time: 'versus last month',
    },
    {
      name: 'Rating',
      money: '4.8',
      time: 'versus last month',
    },
  ];

  const dynamicStyle = {
    padding: 0,
  };

  useEffect(() => {
  
    setPage(1)
    if (tabType === 'invoices') {
      getUserInvoices('invoice',1);
    } else if (tabType === 'estimates') {
      getUserInvoices('estimate',1);
    } else {
      getPaidInvoices(1);
    }
  }, [tabType, searchQuery, selectedFilterValue]);


  useEffect(() => {
    if (currentComponent !== '' ) {
      toggleHiddenPanel(true);
      // alert('hehe')
    } else {
      toggleHiddenPanel(false);
      // alert('hehe2')
    }
  }, [currentComponent]);


  const handleFilterOption = alphabet => {
    setValue_filter(alphabet);
  };

  const handleScroll = values => {
    const {clientHeight, scrollHeight, scrollTop} = values.target;
   
    if (clientHeight + scrollTop >= scrollHeight) {
      
      if (tabType === 'invoices') {
        getUserInvoices('invoice',page + 1);
      } else if (tabType === 'estimates') {
        getUserInvoices('estimate',page + 1);
      } else {
        getPaidInvoices(page + 1);
      }
      
    }
  };

  const getUserInvoices = (type,pageN) => {

    if ((hasMoreData.current && pageN !==1)  || (isFetching.current && pageN !==1)) {
      return; 
    } else {
      if (pageN > 1) {
        setIsLoading(true);
      }
    }
    isFetching.current = !isFetching.current;


    const payload = {
      invoice_type: type,
      sub_type: '',
      page: pageN,
      search_query: searchQuery,
      list_type: selectedFilterValue,
    };

    commonApi
      .get_user_invoices(payload)
      .then(res => {
        if (res.status === 200 && res.data) {
         

          if (pageN > 1) {
            if (res.data.length > 0) {
              setPage(pageN);
              setTimeout(() => {
              if (type === 'invoice') {
                setInvoices([...invoices, ...res.data]);
              } else {
                setEstimates([...estimates, ...res.data]);
              }
              }, 1000);

            } else {
              hasMoreData.current = !hasMoreData.current;
            }
          } else {
            setPage(1);
            if (type === 'invoice') {
              setInvoices(res.data);
            } else {
              setEstimates(res.data);
            }
          }


           
         
          setTimeout(() => {
            setShowLoader(false);
          }, 500);
        }
        setTimeout(() => {
          isFetching.current = !isFetching.current;
          setIsLoading(false);
        }, 1000);
      })
      .catch(err => {
        
        return {type: 'error', message: err.message};
      });
  };

  const getPaidInvoices = (pageN) => {
    if ((hasMoreData.current && pageN !==1)  || (isFetching.current && pageN !==1)) {
      return; 
    } else {
      if (pageN > 1) {
        setIsLoading(true);
      }
    }
    isFetching.current = !isFetching.current;

    
    const payload = {
      search_query: searchQuery,
      list_type: selectedFilterValue,
    };

    commonApi
      .get_paid_invoices(payload)
      .then(res => {
        if (res.status === 200 && res.data) {

          if (pageN > 1) {
            if (res.data.length > 0) {
              setPage(pageN);
              setTimeout(() => {
                setPaidInvoices([...paidInvoices, ...res.data]);
              }, 1000);
            } else {
              hasMoreData.current = !hasMoreData.current;
            }
          } else {
            setPage(1);
            setPaidInvoices(res.data);
          }
          
          setTimeout(() => {
            setShowLoader(false);
          }, 500);
        }

        setTimeout(() => {
          isFetching.current = !isFetching.current;
          setIsLoading(false);
        }, 1000);
      })
      .catch(err => {
        
        return {type: 'error', message: err.message};
      });
  };

  const handleStates = () => {
    setPage(1)
    hasMoreData.current = false;
    isFetching.current = false;
    setIsLoading(false)
    setSearchQuery('');
    setSelectedFilterValue('to_me');
    setValue_filter('to_me');
  };

  useEffect(()=>{
    if(tabType === 'paid'){
      handleStates()
    }
  },[tabType])

  const handlePayment = (item) => {
    // console.log(checkPayableAmount(item.catalog_details),'checkPayableAmount(item.catalog_details)checkPayableAmount(item.catalog_details)')
    // const checkPayable = checkPayableAmount(item.catalog_details)
    setSelectedPrice(item.payable_amount ? item.payable_amount : item.total);
    setPaymentId(item.payment_id);
    setShowStripeModal(true);
  };

  useEffect(() => {
    // console.log(menuPermesions,'menuPermesions')
  }, [menuPermesions]);

  useEffect(() => {
    setTimeout(() => {
      const {pathname} = window.location;
      const urlPart = pathname.split('/').filter(Boolean);

      if (
        Object.keys(workspaceData).length > 0 &&
        urlPart.length === 1 &&
        currentComponent !== ''
      ) {
        setCurrentComponent('');
      }
    }, 1000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workspaceData]);

  // console.log(invoices, 'invoicesinvoices');

  // console.log(selectedEstimateItemCost, 'selectedEstimateItemCost');

  // console.log(menuPermesions, 'menuPermesions');
  // console.log(PaymentPermessions, 'PaymentPermessions');

  // console.log(estimates, 'estimates');

  

  return (
    <>
      <div className="page-wrapper">
        <Sidebar
          location={props.location}
          setShowComponent={setShowComponent}
          setWorkSpaceItem={setWorkspaceData}
          workspaceData={workspaceData}
        />
        {/* {showComponent === 'Event' && <SessionEvents eventButton={eventButton} />} */}

        <div className="page-content-wrapper">
          <div className="sessions-list-wrapper slider-screen-main">
            {PaymentPermessions &&
              (PaymentPermessions === 'allow' ||
                PaymentPermessions?.analytics_row === true) && (
                <>
                  <div className="Reward_main analytics_slider_bg">
                    <span
                      className="analytics_slider_heading "
                      style={{color: analycticsSlider ? '' : '#999999'}}
                      onClick={() => {
                        setAnalycticsSlider(!analycticsSlider);
                      }}>
                      Analytics Row
                    </span>

                    {analycticsSlider && (
                      <div className="reward-top mt-3 mb-2">
                        <Slider {...rewardSlider}>
                          {rewardSliderData.length > 0 &&
                            rewardSliderData.map((item, index) => (
                              <div className="reward-main">
                                <div className="reward-card">
                                  <span
                                    style={{
                                      color: '#000',

                                      fontFamily: 'Inter',
                                      // fontSize: '10.833px',
                                      fontStyle: 'normal',
                                      fontWeight: '700',
                                      lineHeight: 'normal',
                                    }}>
                                    {item.name}
                                  </span>
                                  <span
                                    style={{
                                      color: '#000',

                                      fontFamily: 'Inter',
                                      fontSize: '17px',
                                      fontStyle: 'normal',
                                      fontWeight: '400',
                                      lineHeight: 'normal',
                                    }}>
                                    {item.money}
                                    {item.name === 'Rating' && (
                                      <img src={rewardStar} alt="" />
                                    )}
                                    <i
                                      class="fa-solid fa-arrow-up"
                                      style={{color: '#52bd41'}}></i>
                                  </span>
                                  <div
                                    class="message-date-info"
                                    style={
                                      {
                                        // marginLeft: '13px',
                                      }
                                    }>
                                    <span
                                      class="message-date"
                                      style={{
                                        color: '#333',
                                        fontFamily: 'Helvetica',
                                        // fontSize: '9px',
                                        fontStyle: 'normal',
                                        fontWeight: '300',
                                        lineHeight: 'normal',
                                      }}>
                                      {item.time}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            ))}
                        </Slider>
                      </div>
                    )}
                  </div>

                  <div className="add_border"></div>
                </>
              )}
            <Pannels
              isFloated={isFloated}
              toggleFloatedPanel={toggleFloatedPanel}
              showHiddenPanel={showHiddenPanel}
              updateHiddenPanelState={nState => toggleHiddenPanel(nState)}
              customCss={dynamicStyle}
              primary={
                <>
                  <div className="primary_panel_wraper primary_panel_content payment_invoice">
                    <div className="d-flex justify-content-between align-items-center mb-2">
                      <div className="event-tabs tabs-style-2 mb-0">
                        {' '}
                        <ul>
                          <li>
                            <Button
                              className={tabType === 'invoices' ? 'active' : ''}
                              onClick={() => {
                                setShowLoader(true);

                                setSelectedEstimate([]);
                                setTabType('invoices');
                                handleStates();
                              }}>
                              {
                                labels.technician_payment_invoices[
                                  selected_lang
                                ]
                              }
                            </Button>
                          </li>
                          <li>
                            <Button
                              className={
                                tabType === 'estimates' ? 'active' : ''
                              }
                              onClick={() => {
                                setShowLoader(true);

                                setTabType('estimates');
                                handleStates();
                              }}>
                              {
                                labels.technician_payment_estimates[
                                  selected_lang
                                ]
                              }
                            </Button>
                          </li>
                          <li>
                            <Button
                              className={tabType === 'paid' ? 'active' : ''}
                              onClick={() => {
                                setShowLoader(true);
                                setTabType('paid');
                                // setTimeout(() => {
                                //   setShowLoader(false);
                                // }, 700);
                              }}>
                              Paid
                            </Button>
                          </li>
                        </ul>
                      </div>

                      <div className="search-tabs-icons">
                        {PaymentPermessions &&
                          (PaymentPermessions === 'allow' ||
                            PaymentPermessions?.create === true) && (
                            <span
                              className={` mt-0
                               ${ currentComponent === 'createInvoice'
                                  ? 'icon active'
                                  : 'icon'}
                              `}>
                              <i
                                className="icon-plus"
                                onClick={() => {
                                  if (currentComponent === '') {
                                    setCurrentComponent('createInvoice');
                                  } else {
                                    setCurrentComponent('');
                                  }
                                }}
                              />
                            </span>
                          )}
                        <span
                          onClick={() => {
                            setThreeDots(!threeDots);
                          }}
                          className={
                            `mt-0 ${threeDots
                              ? 'icon active threeDots'
                              : 'icon threeDots'}`
                          }>
                          <i className="fas fa-ellipsis-vertical" />
                        </span>
                      </div>
                    </div>

                    {threeDots && (
                      <div className="payment-search-wrapper mb-0 messages-users-wrapper mt-2 mb-2 ">
                        <div className="search-form d-flex">
                          <Form.Group className="search-input">
                            <Form.Control
                              type="text"
                              placeholder="Who are you looking for?"
                              value={searchQuery}
                              onChange={e => {
                                setSearchQuery(e.target.value);
                              }}
                            />
                          </Form.Group>
                          <Button>
                            <i
                              className="icon-filter-icon"
                              onClick={() => setShowFilter(true)}
                            />
                          </Button>
                        </div>
                      </div>
                    )}

                    <div className="refferal-list-wrapper">
                      {showLoader && <LoaderSpinner />}
                      <Scrollbars autoHide onScroll={handleScroll}
                      renderTrackHorizontal={props => <div {...props} style={{ display: 'none' }} />}
                      // Hide the vertical scrollbar
                      renderTrackVertical={props => <div {...props} style={{ display: 'none' }} />}>
                        {tabType === 'invoices' && (
                          <div className="mt-2">
                            {Object.entries(invoices).length > 0 ? (
                              invoices.map((item, index) => (
                                <div className="payment-card-main">
                                  <div
                                    className="as-link"
                                    onClick={() => {
                                      setInvoiceItem(item);
                                      setCurrentComponent('detailInvoice');
                                    }}>
                                    <div className="payment-content-card">
                                      <div className="task-profile-img">
                                        {/* <img
                                        src="https://s3.amazonaws.com/build-dev/event/slider/1692340229593.jpg"
                                        alt=""
                                      /> */}
                                        <img
                                          src={
                                            item.profile_img
                                              ? item.profile_img
                                              : DEFAULT_IMAGE
                                          }
                                          alt=""
                                        />
                                      </div>

                                      <div className="task-content">
                                        <h1
                                          style={{
                                            color: '#000',
                                            fontFamily: 'Inter',
                                            fontSize: '15px',
                                            fontStyle: 'normal',
                                            fontWeight: '700',
                                            lineHeight: 'normal',
                                          }}>
                                          {item.first_name +
                                            ' ' +
                                            item.last_name}
                                          {/* {fixName(item.first_name, item.last_name)} */}
                                          {/* Samantha Ellington */}
                                        </h1>

                                        <span
                                          style={{
                                            color: '#000',
                                            fontFamily: 'Inter',
                                            fontSize: '15px',
                                            fontStyle: 'normal',
                                            fontWeight: '500',
                                            lineHeight: 'normal',
                                            whiteSpace: 'nowrap',
                                            textOverflow: 'ellipsis',
                                            overflow: 'hidden',
                                          }}>
                                          {item.invoice_title
                                            ? item.invoice_title
                                            : ''}
                                        </span>
                                        <span
                                          className="project-title"
                                          style={{
                                            color: '#707070',
                                            fontFamily: 'Inter',
                                            fontSize: '14px',
                                            fontStyle: 'normal',
                                            fontWeight: '400',
                                            lineHeight: 'normal',
                                          }}>
                                          Invoice
                                        </span>
                                      </div>
                                    </div>

                                    <div className="payment-service-icons">
                                      <div className="roi-service-icons">
                                        <div className="service-icon">
                                          <img src={WhitePenIcon} alt="" />
                                        </div>

                                        <div className="service-icon-time">
                                          <img
                                            className=""
                                            src={timeIcon}
                                            alt=""
                                          />
                                          <span>5D</span>
                                        </div>

                                        <div className="service-icon w-25">
                                          <img src={FireIcon} alt="" />
                                        </div>

                                        <div className="service-icon w-29">
                                          <img src={MachineIcon} alt="" />
                                        </div>

                                        <div className="service-icon w-29">
                                          <img src={ArrowIcon} alt="" />
                                        </div>

                                        <div className="service-icon w-29">
                                          <img src={Bandage} alt="" />
                                        </div>

                                        <div className="service-icon w-28">
                                          <img src={heartIcon} alt="" />
                                        </div>

                                        <div className="service-icon w-29">
                                          <img src={bagIcon} alt="" />
                                        </div>
                                        <div className="w-29">
                                          <i
                                            className="fa-solid fa-ellipsis"
                                            style={{
                                              color: '#0a0a0a',
                                              paddingTop: '15px',
                                            }}></i>
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="payment-card-bottom">
                                    <div
                                      className="payment-card-icons"
                                      style={{
                                        color: '#262626',

                                        fontFamily: 'Inter',
                                        fontSize: '15px',
                                        fontStyle: 'normal',
                                        fontWeight: '600',
                                        lineHeight: 'normal',
                                        paddingTop: '11px',
                                      }}>
                                      Due{' '}
                                      {moment(item?.due_date)
                                        .format('MM/DD/YY')
                                        .toString()}
                                      {/* Due 01/15/24 */}
                                      {/* <div className="payment-icon">
          <i class="fa-solid fa-phone"></i>
        </div>

        <div className="payment-icon">
          <i className="fa-solid icon-plane"></i>
        </div> */}
                                    </div>

                                    <div className="pay-btn">
                                      <span
                                        style={{
                                          color: '#262626',
                                          fontFamily: 'Inter',
                                          fontSize: '15px',
                                          fontStyle: 'normal',
                                          fontWeight: '600',
                                          lineHeight: 'normal',
                                        }}>
                                        {' '}
                                        ${item.payable_amount  ? item.payable_amount : item.total}
                                      </span>

                                      {selectedFilterValue !== 'from_me' && (
                                        <Button
                                          className="new-btn btn-green ml-3 "
                                          onClick={() => {
                                            handlePayment(item);
                                          }}>
                                          Pay
                                        </Button>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              ))
                            ) : (
                              <div className="no-results-wrapper mt-5">
                                <div className="no-results-content mt-5">
                                  <span>No Invoice Found</span>

                                  {PaymentPermessions &&
                                    (PaymentPermessions === 'allow' ||
                                      PaymentPermessions?.create === true) && (
                                      <div className="btn-wrapper mt-3">
                                        <Button
                                          className="btn btn-dark"
                                          onClick={() => {
                                            if (currentComponent === '') {
                                              setCurrentComponent(
                                                'createInvoice',
                                              );
                                            } else {
                                              setCurrentComponent('');
                                            }
                                          }}>
                                          Create Invoice
                                        </Button>
                                      </div>
                                    )}
                                </div>
                              </div>
                            )}
                             {isLoading && <ScrollLoader />}
                          </div>
                        )}
                        {tabType === 'estimates' && (
                          <div className="mt-2">
                            {Object.entries(estimates).length > 0 ? (
                              estimates.map((item, index) => (
                                <div
                                  className="payment-card-main"
                                  key={index + 'estimate'}>
                                  <div
                                    className="as-link"
                                    onClick={() => {
                                      setCurrentComponent('leaderBoard-detail');
                                      setEstimateItem(item);
                                    }}>
                                    <div className="payment-content-card">
                                      {/* <div className="task-profile-img">
                        <img
                          src="https://s3.amazonaws.com/build-dev/event/slider/1692340229593.jpg"
                          alt=""
                        />
                      </div> */}

                                      <div className="task-content">
                                        <h1
                                          style={{
                                            color: '#000',
                                            fontFamily: 'Inter',
                                            fontSize: '15px',
                                            fontStyle: 'normal',
                                            fontWeight: '700',
                                            lineHeight: 'normal',
                                          }}>
                                          {item.title}
                                        </h1>
                                        <h1
                                          style={{
                                            color: '#000',
                                            fontFamily: 'Inter',
                                            fontSize: '15px',
                                            fontStyle: 'normal',
                                            fontWeight: '700',
                                            lineHeight: 'normal',
                                          }}>
                                          {item.invoices[0].first_name +
                                            ' ' +
                                            item.invoices[0].last_name}
                                        </h1>

                                        {/* <span
                                        style={{
                                          color: '#000',
                                          fontFamily: 'Inter',
                                          fontSize: '15px',
                                          fontStyle: 'normal',
                                          fontWeight: '500',
                                          lineHeight: 'normal',
                                        }}>
                                        Invoice Title
                                      </span> */}
                                        <span
                                          className="project-title"
                                          style={{
                                            color: '#707070',
                                            fontFamily: 'Inter',
                                            fontSize: '14px',
                                            fontStyle: 'normal',
                                            fontWeight: '400',
                                            lineHeight: 'normal',
                                          }}>
                                          {item.invoices[0].sub_type ===
                                          'comparison'
                                            ? 'Comparsion Estimates'
                                            : 'Standard Estimates'}
                                        </span>
                                      </div>
                                    </div>

                                    <div className="payment-service-icons">
                                      <div className="roi-service-icons">
                                        <div className="service-icon">
                                          <img src={WhitePenIcon} alt="" />
                                        </div>

                                        <div className="service-icon-time">
                                          <img
                                            className=""
                                            src={timeIcon}
                                            alt=""
                                          />
                                          <span>5D</span>
                                        </div>

                                        <div className="service-icon w-25">
                                          <img src={FireIcon} alt="" />
                                        </div>

                                        <div className="service-icon w-29">
                                          <img src={MachineIcon} alt="" />
                                        </div>

                                        <div className="service-icon w-29">
                                          <img src={ArrowIcon} alt="" />
                                        </div>

                                        <div className="service-icon w-29">
                                          <img src={Bandage} alt="" />
                                        </div>

                                        <div className="service-icon w-28">
                                          <img src={heartIcon} alt="" />
                                        </div>

                                        <div className="service-icon w-29">
                                          <img src={bagIcon} alt="" />
                                        </div>
                                        <div className="w-29">
                                          <i
                                            className="fa-solid fa-ellipsis"
                                            style={{
                                              color: '#0a0a0a',
                                              paddingTop: '15px',
                                            }}></i>
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="payment-card-bottom">
                                    <div
                                      className="payment-card-icons"
                                      style={{
                                        color: '#262626',

                                        fontFamily: 'Inter',
                                        fontSize: '15px',
                                        fontStyle: 'normal',
                                        fontWeight: '600',
                                        lineHeight: 'normal',
                                        paddingTop: '11px',
                                      }}>
                                      Due{' '}
                                      {moment(item?.due_date)
                                        .format('MM/DD/YY')
                                        .toString()}
                                      {/* <div className="payment-icon">
                          <i class="fa-solid fa-phone"></i>
                        </div>

                        <div className="payment-icon">
                          <i className="fa-solid icon-plane"></i>
                        </div> */}
                                    </div>

                                    {/* <div className="pay-btn">
                                      <span
                                        style={{
                                          color: '#262626',
                                          fontFamily: 'Inter',
                                          fontSize: '15px',
                                          fontStyle: 'normal',
                                          fontWeight: '600',
                                          lineHeight: 'normal',
                                        }}>
                                        {' '}
                                        $575
                                      </span>
                                    </div> */}
                                    {item.cost && (
                                      <div className="pay-btn">
                                        <span
                                          style={{
                                            color: '#262626',
                                            fontFamily: 'Inter',
                                            fontSize: '15px',
                                            fontStyle: 'normal',
                                            fontWeight: '600',
                                            lineHeight: 'normal',
                                          }}>
                                          {' '}
                                          ${item.cost ? item.cost : ''}
                                        </span>
                                        {selectedFilterValue !== 'from_me' && (
                                          <Button
                                            className="new-btn btn-green ml-3 "
                                            onClick={() => {
                                              setSelectedPrice(item.cost);
                                              setPaymentId(item.payment_id);
                                              setShowStripeModal(true);
                                            }}>
                                            Pay
                                          </Button>
                                        )}
                                      </div>
                                    )}
                                  </div>
                                </div>
                              ))
                            ) : (
                              <div className="no-results-wrapper mt-5">
                                <div className="no-results-content mt-5">
                                  <span>No Estimates found</span>

                                  {PaymentPermessions &&
                                    (PaymentPermessions === 'allow' ||
                                      PaymentPermessions?.create === true) && (
                                      <div className="btn-wrapper mt-3">
                                        <Button
                                          className="btn btn-dark"
                                          onClick={() => {
                                            if (currentComponent === '') {
                                              setCurrentComponent(
                                                'createInvoice',
                                              );
                                            } else {
                                              setCurrentComponent('');
                                            }
                                          }}>
                                          Create Estimates
                                        </Button>
                                      </div>
                                    )}
                                </div>
                              </div>
                            )}
                          </div>
                        )}

                        {tabType === 'paid' && (
                          <div className="mt-2">
                            {Object.entries(paidInvoices).length > 0 ? (
                              paidInvoices.map((item, index) => (
                                <div className="payment-card-main">
                                  <div
                                    className="as-link"
                                    onClick={() => {
                                      setInvoiceItem(item);
                                      setCurrentComponent('paidInvoice');
                                    }}>
                                    <div className="payment-content-card">
                                      <div className="task-profile-img">
                                        {/* <img
                                      src="https://s3.amazonaws.com/build-dev/event/slider/1692340229593.jpg"
                                      alt=""
                                    /> */}
                                        <img
                                          src={
                                            item.profile_img
                                              ? item.profile_img
                                              : DEFAULT_IMAGE
                                          }
                                          alt=""
                                        />
                                      </div>

                                      <div className="task-content">
                                        <h1
                                          style={{
                                            color: '#000',
                                            fontFamily: 'Inter',
                                            fontSize: '15px',
                                            fontStyle: 'normal',
                                            fontWeight: '700',
                                            lineHeight: 'normal',
                                          }}>
                                          {item.first_name +
                                            ' ' +
                                            item.last_name}
                                          {/* {fixName(item.first_name, item.last_name)} */}
                                          {/* Samantha Ellington */}
                                        </h1>

                                        <span
                                          style={{
                                            color: '#000',
                                            fontFamily: 'Inter',
                                            fontSize: '15px',
                                            fontStyle: 'normal',
                                            fontWeight: '500',
                                            lineHeight: 'normal',
                                          }}>
                                          {item.invoice_title
                                            ? item.invoice_title
                                            : ''}
                                        </span>
                                        <span
                                          className="project-title"
                                          style={{
                                            color: '#707070',
                                            fontFamily: 'Inter',
                                            fontSize: '14px',
                                            fontStyle: 'normal',
                                            fontWeight: '400',
                                            lineHeight: 'normal',
                                          }}>
                                          Paid Invoice
                                        </span>
                                      </div>
                                    </div>

                                    <div className="payment-service-icons">
                                      <div className="roi-service-icons">
                                        <div className="service-icon">
                                          <img src={WhitePenIcon} alt="" />
                                        </div>

                                        <div className="service-icon-time">
                                          <img
                                            className=""
                                            src={timeIcon}
                                            alt=""
                                          />
                                          <span>5D</span>
                                        </div>

                                        <div className="service-icon w-25">
                                          <img src={FireIcon} alt="" />
                                        </div>

                                        <div className="service-icon w-29">
                                          <img src={MachineIcon} alt="" />
                                        </div>

                                        <div className="service-icon w-29">
                                          <img src={ArrowIcon} alt="" />
                                        </div>

                                        <div className="service-icon w-29">
                                          <img src={Bandage} alt="" />
                                        </div>

                                        <div className="service-icon w-28">
                                          <img src={heartIcon} alt="" />
                                        </div>

                                        <div className="service-icon w-29">
                                          <img src={bagIcon} alt="" />
                                        </div>
                                        <div className="w-29">
                                          <i
                                            className="fa-solid fa-ellipsis"
                                            style={{
                                              color: '#0a0a0a',
                                              paddingTop: '15px',
                                            }}></i>
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="payment-card-bottom">
                                    <div
                                      className="payment-card-icons"
                                      style={{
                                        color: '#262626',

                                        fontFamily: 'Inter',
                                        fontSize: '15px',
                                        fontStyle: 'normal',
                                        fontWeight: '600',
                                        lineHeight: 'normal',
                                        paddingTop: '11px',
                                      }}>
                                      {moment(item?.due_date)
                                        .format('MM/DD/YY')
                                        .toString()}
                                      {/* Due 01/15/24 */}
                                      {/* <div className="payment-icon">
        <i class="fa-solid fa-phone"></i>
      </div>

      <div className="payment-icon">
        <i className="fa-solid icon-plane"></i>
      </div> */}
                                    </div>

                                    <div className="pay-btn">
                                      <span
                                        style={{
                                          color: '#262626',
                                          fontFamily: 'Inter',
                                          fontSize: '15px',
                                          fontStyle: 'normal',
                                          fontWeight: '600',
                                          lineHeight: 'normal',
                                        }}>
                                        {' '}
                                        ${item.total}
                                      </span>

                                      {/* {selectedFilterValue !== 'from_me' && ( */}
                                      <Button
                                        className="new-btn btn-green ml-3 "
                                        onClick={() => {}}>
                                        Paid
                                      </Button>
                                      {/* )} */}
                                    </div>
                                  </div>
                                </div>
                              ))
                            ) : (
                              <div className="no-results-wrapper mt-5">
                                <div className="no-results-content mt-5">
                                  <span>No Paid Invoices found</span>
                                </div>
                              </div>
                            )}
                          </div>
                        )}
                      </Scrollbars>
                    </div>
                  </div>

                  {showFilter && (
                    <CustomModal
                      size="medium"
                      onClose={() => {
                        setShowFilter(false);
                      }}>
                      <div className="filter-wrapper">
                        <div className="filter-category-list">
                          <ul>
                            {Object.entries(filterListArray).length > 0 &&
                              filterListArray.map((item, index) => (
                                <li key={`sessionC-${index}`}>
                                  <Form.Label>
                                    {item.title}
                                    <input
                                      type="checkbox"
                                      id={item.id}
                                      value={item.value}
                                      checked={value_filter === item.value}
                                      onChange={() =>
                                        handleFilterOption(item.value)
                                      }
                                    />
                                    <span />
                                  </Form.Label>
                                </li>
                              ))}
                          </ul>
                          <div className="filter-btn-wrapper text-center">
                            <Button
                              className="btn-dark"
                              onClick={() => {
                                setPage(1)
                                hasMoreData.current = false;
                                isFetching.current = false;
                                setIsLoading(false)
                                setShowLoader(true)
                                setSelectedFilterValue(value_filter);
                                setShowFilter(false);
                              }}>
                              Select
                            </Button>
                          </div>
                        </div>
                      </div>
                    </CustomModal>
                  )}

                  {showStripeModal && (
                    <CustomModal
                      size="small"
                      onClose={() => {
                        setShowStripeModal(false);
                        setSelectedPrice('');
                        setPaymentId('');
                      }}
                      className="availability-modal">
                      <div
                        className="new-session-modal-wrapper text-center  "
                        style={{width: '-webkit-fill-available'}}>
                        <div className="new-session-modal estimate_name_modal">
                          <Elements
                            stripe={stripePromise}
                            options={{
                              mode: 'payment',
                              amount: 10 * 100,
                              currency: 'usd',
                              paymentMethodTypes: ['card'],
                              payment_method_types: ['card'],
                            }}>
                            <CheckoutForm
                              price={selectedPrice}
                              paymentId={paymentId}
                              transactionType={'invoice'}
                              onClose={() => {
                                setShowStripeModal(false);
                                setSelectedPrice('');
                                setPaymentId('');
                                if (tabType === 'invoices') {
                                  getUserInvoices('invoice',1);
                                } else if (tabType === 'estimates') {
                                  getUserInvoices('estimate',1);
                                }
                              }}
                            />
                          </Elements>
                        </div>
                      </div>
                    </CustomModal>
                  )}
                </>
              }
              secondary={
                <>
                  {currentComponent === 'createInvoice' && (
                    <CreateInvoice
                      tabType={tabType}
                      onClose={() => {
                        setCurrentComponent('');
                      }}
                    />
                  )}

                  {currentComponent === 'leaderBoard-detail' && (
                    <LeaderBoardDeatil
                      selectedEstimate={selectedEstimate}
                      setSelectedEstimate={setSelectedEstimate}
                      setSelectedEstimateItemCost={setSelectedEstimateItemCost}
                      estimateItem={estimateItem}
                      setEstimates={setEstimates}
                      estimates={estimates}
                      selectedFilterValue={selectedFilterValue}
                      onClose={() => {
                        setCurrentComponent('');
                      }}
                      getUserInvoices={getUserInvoices}
                      setSearchQuery={setSearchQuery}
                      setShowLoader={setShowLoader}
                    />
                  )}

                  {currentComponent === 'detailInvoice' && (
                    <InvoiceDeatil
                      invoiceItem={invoiceItem}
                      selectedFilterValue={selectedFilterValue}
                      getUserInvoices={getUserInvoices}
                      handleStates={handleStates}
                      setShowLoader={setShowLoader}
                      onClose={() => {
                        setCurrentComponent('');
                        setInvoiceItem({});
                      }}
                    />
                  )}

                  {currentComponent === 'paidInvoice' && (
                    <InvoiceDeatil
                      type={'paid'}
                      invoiceItem={invoiceItem}
                      selectedFilterValue={selectedFilterValue}
                      onClose={() => {
                        setCurrentComponent('');
                        setInvoiceItem({});
                      }}
                      handleStates={handleStates}
                    />
                  )}
                </>
              }
            />

            {showComponent === 'WorkSpace' && (
              <CreateWorkSpace
                onClose={() => {
                  setShowComponent('');
                }}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default TechnicianPayment;
