import React, {useEffect, useState} from 'react';
import {Button, Form, OverlayTrigger, Tooltip} from 'react-bootstrap';
import {DEFAULT_IMAGE} from '../../Utils/env';
import Moment from 'react-moment';
import commonApi from '../../Services/CommonService';
import OtherUserImages from '../Common/OtherUserImages';
import {connect, useSelector, useDispatch} from 'react-redux';
import SlidingPanel from '../SlidingPanel';
import Scrollbars from 'react-custom-scrollbars';
import EditOpportunities from './EditOpportunities';
import CreateSession from '../SessionDetails/CreateSession';
// import { callOpportunityApi } from '../../Store/Actions/OpportunityAction';
import {getOpportunityChatDetails} from '../../Store/Actions/OpportunityAction';
import {fixName} from '../../Utils/commonUtils';
import LoaderSpinner from '../Modals/LoaderSpinner';
import SmartChatPage from '../Common/SmartChatPage';
import {chatroomInfoOppoMsgList} from '../../Utils/commonUtils';
import EditPencilSvg from '../../Svg/EditPencilSvg';
const OpportunityMessageList = props => {
  const [chatType, setChatType] = useState('pending');
  const [searchQry, setSearchQry] = useState('');
  const [oppoDetails, setOppoDetails] = useState([]);
  const [showLoader, setShowLoader] = useState(false);
  const [showComponent, setShowComponent] = useState('');
  const [availAbleOpp, setAvailAbleOpp] = useState([]);
  const [opportunityTitle, setOpportunityTitle] = useState(props.item.title);
  const [opportunityDesc, setOpportunityDesc] = useState(
    props.item.description,
  );
  const [chatRoomStatus, setChatRoomStatus] = useState('pending');
  const [chatRoomName, setChatRoomName] = useState('');
  const [chatRoomId, setChatRoomId] = useState('');
  const [allChatUsers, setAllChatUsers] = useState([]);
  const [oppoDetailsChat, setOppoDetailsChat] = useState([]);
  const opportunityId = props.item.id;
  //   const opportunityTitle = props.item.title;
  //   const opportunityDesc = props.item.description;
  const dispatch = useDispatch();
  const callCount = useSelector(state => state.opportunityApi);
  const typeforHistory = props.type;

  const getOpportunityDetails = () => {
    var payLoad = {
      opportunity_id: opportunityId,
      page: 1,
      search_query: searchQry,
      status: chatType,
    };
    commonApi
      .get_opportunity_details(payLoad)
      .then(response => {
        if (response.status === 200) {
          setAvailAbleOpp(response.available_opportunity[0]);
          
          const found = response.available_opportunity.find(
            objs => parseInt(objs.id) === parseInt(opportunityId),
          );
          if (typeof found === 'object') {
            setOpportunityTitle(found.title);
            setOpportunityDesc(found.description);
          }
          setOppoDetails(response.opportunity_details);
          setTimeout(() => {
            setShowLoader(false);
          }, 1500);
        }
      })
      .catch(err => {
        setShowLoader(false);
       
        return {type: 'error', message: err.message};
      });
  };
  // let gray = {
  //   color: '#808080',
  // };
  const collectingUsers = item => {
    const oppoUsers = [
      {
        id: item.creator_details.id,
        first_name: item.creator_details.first_name,
        last_name: item.creator_details.last_name,
        profile_img: item.creator_details.profile_img,
        type: 'creator',
      },
    ];
    if (item.other_persons_opportunity_chat_room.length > 0) {
      item.other_persons_opportunity_chat_room.forEach(item => {
        if (item.status !== 'left' && item.status !== 'pending') {
          item.type = 'audience';
          oppoUsers.push(item);
        }
      });
    }
    if (item.team_member_details.length > 0) {
      item.team_member_details.forEach(item => {
        item.type = 'moderator';
        oppoUsers.push(item);
      });
    }
    return oppoUsers;
  };

  // const popover = text => {
  //   return (
  //     <Popover id="popover-basic">
  //       {/* <Popover.Header as="h3">Popover right</Popover.Header> */}
  //       <Popover.Body>{text}</Popover.Body>
  //     </Popover>
  //   );
  // };

  const renderMessageUsers = item => {
    const oppoUsers = collectingUsers(item);

    if (oppoUsers.length > 0) {
      return <OtherUserImages otherUsers={oppoUsers} />;
    }
  };
  useEffect(() => {
    setShowLoader(true);
    getOpportunityDetails();
    setChatRoomStatus(chatType);
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chatType, searchQry, callCount, props.item,showComponent]);

  const renderHeader = () => (
    <>
    
   

   {props.item?.status !== 'finish' && (<span
                  className=" as-link"
                  
                  onClick={() => {
                    setShowComponent('EditOpp');
                  }}>
                  <EditPencilSvg fill={'white'} h={20} w={20} />
                </span>
                )}
   

                <span className="recruitment_detail info-icons">
                <OverlayTrigger
                  placement="bottom"
                  overlay={<Tooltip>{opportunityDesc}</Tooltip>}>
                  <span className="icon-info-down">
                    <span className="path1" />
                    <span className="path2" />
                    <span className="path3" />
                  </span>
                </OverlayTrigger>
              </span>

              {props.item?.status !== 'finish' && ( <span
              className='as-link'
                onClick={() => {
                  if (props.item.status === 'finish') {
                  } else {
                    setShowComponent('SesionCreate');
                  }
                }}>
                <i className="icon-meetups" style={{fontSize:'20px'}}/>
              </span>)}
    </>
  );


  // 
  return (
    <>
      {/* <Scrollbars style={{width: '100%'}}> */}

      <SlidingPanel
        hidePanel={() => {
          props.onClose();
        }}
        renderData={renderHeader}
      
       
        showBlackHeader={true}
        headerPadding={'24px 20px'}
        lastDivStyle={{borderRadius: '35px', background: '#f7f6f2'}}
        firstDivStyle={{
          padding: 0,
          background:
            'linear-gradient(to bottom, #424242, #424242, #f7f6f2 50%)',
        }}
        >
        <div className="serach-filter-wrapper opportunity-details pt-3">
          <div
            className={`sidebar-heading-wrapper fix-width-forms  fixed-width-inputs black-head-form-pad-left mt-0 ${
              props.windowWidth < 1310 ? 'with-panel' : ''
            }`}>
            <div className="heading mt-0">
              <h2>{opportunityTitle}</h2>
            </div>
            {/* <div
              className="leave-btn-wrapper"
              disabled={props.item.status === 'finish' && true}>
              <Button
                onClick={() => {
                  // props.onEditOppo();
                  setShowComponent('EditOpp');
                  // props.setEditOppo(!props.editOppo);
                  
                }}
                disabled={props.item.status === 'finish' && true}>
                Edit Recruitment
              </Button>

              <span className="info-icons">
                <OverlayTrigger
                  placement="bottom"
                  overlay={<Tooltip>{opportunityDesc}</Tooltip>}>
                  <span className="icon-info-down">
                    <span className="path1" />
                    <span className="path2" />
                    <span className="path3" />
                  </span>
                </OverlayTrigger>
              </span>
              <span
                className="users-icons as-link"
                onClick={() => {
                  if (props.item.status === 'finish') {
                  } else {
                    setShowComponent('SesionCreate');
                  }
                }}>
                <i className="fa-solid fa-users"></i>
              </span>
            </div> */}
          </div>
          <Scrollbars>
            <div className="user-profile-list-wrapper fix-width-forms fixed-width-inputs black-head-form-pad-left">
              <div className="event-tabs tabs-style-2">
                <ul>
                  <li>
                    <Button
                      className={chatType === 'pending' ? 'active' : ''}
                      onClick={() => {
                        setShowLoader(true);
                        setChatType('pending');
                      }}>
                      Pending
                    </Button>
                  </li>
                  <li>
                    <Button
                      className={chatType === 'match' ? 'active' : ''}
                      onClick={() => {
                        setShowLoader(true);
                        setChatType('match');
                      }}>
                      Matches
                    </Button>
                  </li>
                  <li>
                    <Button
                      className={chatType === 'decline' ? 'active' : ''}
                      onClick={() => {
                        setShowLoader(true);
                        setChatType('decline');
                      }}>
                      Declined
                    </Button>
                  </li>
                  <li>
                    <Button
                      className={chatType === 'left' ? 'active' : ''}
                      onClick={() => {
                        setShowLoader(true);
                        setChatType('left');
                      }}>
                      Left Chat
                    </Button>
                  </li>
                </ul>
              </div>

              <div className="person-search-wrapper messages-users-wrapper">
                <div className="search-form d-flex">
                  <Form.Group className="search-input">
                    <Form.Control
                      type="text"
                      placeholder="Who are you looking for?"
                      value={searchQry}
                      onChange={e => {
                        setSearchQry(e.target.value);
                      }}
                    />
                  </Form.Group>
                </div>

                <div className="messages-users-list mt-3">
                  <div className="loader-container">
                    {showLoader && <LoaderSpinner />}

                    {!showLoader && Object.entries(oppoDetails).length > 0 ? (
                      oppoDetails.map((item, index) => {
                        const newMessage = item.read ? '' : 'new-message';
                        return (
                          <div
                            className="card mb-3 as-link"
                            key={`oppo-key-${item.user_id}-${index}`}
                            onClick={() => {
                              dispatch(getOpportunityChatDetails(item));
                              setOppoDetailsChat(item);
                              setAllChatUsers(collectingUsers(item));
                              if (props.hasOwnProperty('onShowChat')) {
                                // props.onShowChat();
                                setShowComponent('show-chats');
                              } else {
                                if (props.hasOwnProperty('onCloseExtra')) {
                                  // props.onCloseExtra();
                                  setShowComponent('show-chats');
                                } else {
                                  props.onClose();
                                }
                              }
                              setChatRoomName(item.chat_detail.chat_room_name);
                              setChatRoomId(item.chat_detail.id);
                            }}>
                            <div
                              className={`message-user-item pt-0 pb-0 ${newMessage}`}>
                              <div className="user-img as-link">
                                <img
                                  src={
                                    item.chat_detail.group_image
                                      ? item.chat_detail.group_image
                                      : item.profile_img
                                      ? item.profile_img
                                      : DEFAULT_IMAGE
                                  }
                                  alt=""
                                />
                              </div>
                              <div className="user-content as-link">
                                <h2>
                                  {item.chat_detail.group_image
                                    ? item.chat_detail.group_name
                                    : fixName(item.first_name, item.last_name)}
                                </h2>
                                <p className="single-line-text mb-1">
                                  {item.chat_detail.last_message}
                                </p>
                                <div className="message-date-info as-link">
                                  <span className="message-date">
                                    <Moment format="MM/DD/YY HH:mm A">
                                      {item.chat_detail.updated_at}
                                    </Moment>
                                  </span>
                                  {item.chat_detail.group_image &&
                                    renderMessageUsers(item)}
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })
                    ) : (
                      <div className="user-content mt-4 mb-4">
                        <p className="text-center">No Record Found</p>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </Scrollbars>
        </div>
      </SlidingPanel>

      {/* </Scrollbars> */}

      {showComponent === 'show-chats' &&
        chatRoomName !== '' &&
        allChatUsers.length > 0 && (
          <SmartChatPage
            allmsg={true}
            type={
              typeforHistory === 'history'
                ? 'history-opportunity'
                : 'mine-opportunity'
            }
            item={typeforHistory === 'history' ? [] : props.item}
            chatroomInfo={chatroomInfoOppoMsgList(
              oppoDetailsChat,
              'opportunity',
            )}
            component={'recruitment'}
            oppoDetails={oppoDetailsChat}
            allChatUsers={allChatUsers}
            chatDetails={oppoDetailsChat.chat_detail}
            chatRoomName={chatRoomName}
            chatRoomId={chatRoomId}
            chatRoomStatus={chatRoomStatus}
            onClose={() => {
              setChatRoomName('');
              setShowComponent('');
              // setShowButton(true);
              // setProperComponent('mine-opportunity');
            }}
            paddingBottom={true}
          />
        )}

      {showComponent === 'EditOpp' && (
        <EditOpportunities
          onClose={() => {
            // setInviteUser(false);
            // setProperComponent('');
            setShowComponent('');
          }}
          item={availAbleOpp}
        />
      )}
      {showComponent === 'SesionCreate' && (
        <CreateSession
          onClose={() => {
            // setInviteUser(false);
            // setProperComponent('');
            setShowComponent('');
          }}
          opportunitySession={availAbleOpp}
        />
      )}
    </>
  );
};

const mapStateProps = state => ({
  windowWidth: state.windowWidth,
});

export default connect(mapStateProps, {})(OpportunityMessageList);
