import React, {useState, useEffect} from 'react';
import {Button, } from 'react-bootstrap';
import Scrollbars from 'react-custom-scrollbars';
import commonApi from '../../Services/CommonService';
import moment from 'moment/moment';
import {ticketPrice} from '../../Utils/commonUtils';
import TicketAddCard from '../Forms/TicketAddCard';
import SlidingPanel from '../SlidingPanel';
import CustomModal from '../CustomModal';
import LoaderSpinner from '../Modals/LoaderSpinner';
import {getIntervalEND} from '../../Utils/commonUtils';
import {setServerTime} from '../../Store/Actions/DeepLinkAction';
import {useSelector, useDispatch} from 'react-redux';
import {errorMessage} from '../../Utils/ToastMessages';
import PaymentModal from '../Modals/PaymentModal';

const EventTickets = props => {
  const [tickets, setTickets] = useState([]);

  // const [serverTime, setServerTime] = useState('');
  const [purchaseTicket, setPurchaseTicket] = useState([]);
  // const [hideFirstOption, setHideFirstOption] = useState(false);
  const hideFirstOption = false
  const [cardDeatail, setCardDeatail] = useState('');
  const [showComponent, setShowComponent] = useState('');
  const [showModaL, setShowModel] = useState(false);
  const [priceT, setPriceT] = useState('');
  const [totalTickets, setTotalTickets] = useState('');
  const [tickeType, setTicketType] = useState('');

  const [showSpinner, setShowSpinner] = useState(true);
  const dispatch = useDispatch();
  const serverTime = useSelector(state => state.serverTime);

  const getEventtickets = () => {
    var data = {
      event_id: props.eventData,
    };
    commonApi
      .get_event_tickets(data)
      .then(res => {
        if (res.status === 200) {
          setTickets(res.event_tickets);
          setTicketType(res.event_tickets && res.event_tickets.length > 0  ? res.event_tickets[0].type : '')
        }
        setShowSpinner(false);
      })
      .catch(err => {
        
        return {type: 'error', message: err.message};
      });
  };
  // const serverTimeCheck = () => {
  //   // var payLoad = {
  //   //   search_query: qry,
  //   //   user_id: localStorage.getItem('user'),
  //   // };
  //   commonApi
  //     .check_server_time()
  //     .then(res => {
  //       setServerTime(res.server_time);
  //     })
  //     .catch(err => {
  //       console.log('Err', err);
  //       return {type: 'error', message: err.message};
  //     });
  // };

  useEffect(() => {
    // serverTimeCheck();
    getEventtickets();
    getMyCard();

    const interval = setInterval(() => {
      dispatch(setServerTime());
    }, 10000);
    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getMyCard();
  }, [showComponent]);

  const getMyCard = () => {
    // setshowLoader(true);
    commonApi
      .get_my_card()
      .then(res => {
        if (res.status === 200) {
          // setDefaultCard(res.data);
          // setshowLoader(false);
          // setCardDeatail(res.data[0]);
          const deafaultCard = res.data.find(
            item => item.card_type === 'default',
          );
          setCardDeatail(deafaultCard);
        }
      })
      .catch(err => {
       
        return {type: 'error', message: err.message};
      });
  };

  // program to generate a multiplication table

  // take input from the user
  // const number = parseInt(100);

  // //creating a multiplication table
  // for(let i = 1; i <= 99; i++) {

  //     // multiply i with number
  //     const result = i * number;

  //     // display the result
  //     
  // }

  // 
  // 

  const stripePayment = data => {
    
    // setshowLoader(true);
    commonApi
      .stripe_payment(data)
      .then(response => {
        if (response.stripe === true) {
          props.manageRsvpDolby();
          props.onClose();
        } else {
          errorMessage(response.message);
        }
      })
      .catch(err => {
        
        return {type: 'error', message: err.message};
      });
  };
  // useEffect(() => {
  //   if (purchaseTicket !== '') {
  //     const obj = JSON.parse(purchaseTicket);
  //     props.setNumOfTickets(obj.No);
  //     props.setTicketId(obj.id);
  //     setPriceT(obj.price);
  //   }
  // }, [purchaseTicket]);
  useEffect(() => {
    const total = purchaseTicket.reduce(
      (sum, item) => sum + parseInt(item.price),
      0,
    );
    setPriceT(total);
    props.setPaidTickets(purchaseTicket);
    // 
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [purchaseTicket]);

  const handleAmount = e => {
    
    const obj = JSON.parse(e);
    const index = purchaseTicket.findIndex(
      item => item.event_ticket_type_id === obj.event_ticket_type_id,
    );
    const previousTotalTickets = index !== -1 ? purchaseTicket[index].total_tickets : 0;
    if (index !== -1) {
      const updatedArray = [...purchaseTicket];
      updatedArray[index] = {
        event_ticket_type_id: obj.event_ticket_type_id,
        total_tickets: obj.total_tickets,
        price: obj.price,
        title: obj.title,
      };
      // alert('dani')
      setPurchaseTicket(updatedArray);
      setTotalTickets(totalTickets - previousTotalTickets + obj.total_tickets);
    } else {
      setPurchaseTicket(previous => [...previous, obj]);
      setTotalTickets(totalTickets - previousTotalTickets + obj.total_tickets);
    }
    // props.setPaidTickets()
    //  
  };
  const resetAllStatesOfPurchaseTickets = e => {
    setPurchaseTicket([]);

    // props.setPaidTickets()
    //  
  };

  // console.log(tickets,'---------->tickets')
  const remainingTickets = Math.max(0, 10 - props.totalPurchaseTickets);

  // console.log(purchaseTicket,'purchaseTicket---------')
  return (
    <>
      {/* {showComponent === 'cardDetail' && (
        <TicketAddCard
          dataOne={priceT}
          dataTwo={props.eventData}
          setShowForm={setShowComponent}
          stripePayment={stripePayment}
          resetValues={resetAllStatesOfPurchaseTickets}
          tickeType={tickeType}
          onclose={() => {
            props.onClose();
          }}
        />
      )} */}

{showComponent === 'cardDetail' && (
       <PaymentModal
       price={priceT}
       transactionId={props.eventData}
       onClose={() => {
         setShowComponent('')
         resetAllStatesOfPurchaseTickets()
       }}
       onCloseHitApi={()=>{
        props.manageRsvpDolby();
        props.onClose();
       }}
       transactionType={'event'}
     />
      )}

      {showComponent === '' && (
        <SlidingPanel hidePanel={() => props.onClose()}>
          <div className="serach-filter-wrapper">
            <Scrollbars>
              {showSpinner && <LoaderSpinner />}
              <div className="fix-width-forms">
                <div className="sidebar-heading-wrapper"></div>

                {/* <div className="text-right">
              <span className="close as-link" onClick={() => props.onClose()}>
                <i className="fas fa-times" />
              </span>
            </div> */}
                <div className="event-tabs tabs-style-2 mt-3 mb-0" />
                {Object.entries(tickets).length > 0 ? (
                  Object.entries(tickets).length > 0 &&
                  tickets.map((item, index) => {
                    return (
                      <div
                        className="opportunity-item event-ticket-pusrchase card"
                        key={item.id}>
                        <div className="events-headings mb-0">
                          <span className="category-name date">
                            {item.title}
                          </span>
                        </div>
                        <div className="events-headings mb-0">
                          <span className="category-name">
                            {item.cost === '' || item.cost === null
                              ? 'Free'
                              : '$' + item.cost}
                          </span>
                        </div>

                        <div className="events-desc-wrapper">
                          <p>{item.description}</p>
                          <span className="event-address">
                            {item.sale_end !== '' &&
                              item.sale_end !== null &&
                              getIntervalEND(item.sale_end, serverTime)}
                            {/* {moment(serverTime).format(
                              'MMMM Do YYYY, h:mm:ss a',
                            ) >=
                              moment(item.sale_end).format(
                                'MMMM Do YYYY, h:mm:ss a',
                              ) && 'Sale end' + getIntervalEND(item.sale_end)} */}
                          </span>
                        </div>

                        <div className="oppo-address">
                          <span className="injeaction-text">
                            <div className="select-wrapper w-100">
                              <select
                                className="form-control"
                                // value={priceT}
                                disabled={
                                  item.available_tickets === 0 ||
                                  (moment(serverTime).format() >=
                                    moment(item.sale_end).format() &&
                                    remainingTickets >= 10)
                                    ? true
                                    : false
                                }
                                onChange={e => {
                                  handleAmount(e.target.value);

                                  // 
                                }}>
                                {' '}
                                {/* <option>All</option> */}
                                {Object.entries(
                                  ticketPrice(
                                    parseInt(item.available_tickets),
                                    parseInt(item.cost),
                                  ),
                                ).length > 0 &&
                                  ticketPrice(
                                    parseInt(item.available_tickets),
                                    parseInt(item.cost),
                                    remainingTickets,
                                  ).map((miniItem, index) =>
                                    index > 0 ? (
                                      <option
                                        // disabled={miniItem === "0" ? true: false}
                                        value={JSON.stringify({
                                          event_ticket_type_id: item.id,
                                          total_tickets: miniItem.number,
                                          price:
                                            miniItem.price === 'Free'
                                              ? 0
                                              : miniItem.price,
                                          title: item.title,
                                        })}
                                        key={`appointmentType-${index}`}>
                                        {miniItem.price === 'Free'
                                          ? miniItem.number +
                                            '-' +
                                            // miniItem.price 
                                            'Tickets'
                                          : miniItem.number +
                                            '- $' +
                                            miniItem.price}
                                      </option>
                                    ) : (
                                      !hideFirstOption && (
                                        <option
                                          style={{display: 'none'}}
                                          key={`appointmentType-${index}`}>
                                          {miniItem.number}
                                        </option>
                                      )
                                    ),
                                  )}
                              </select>
                            </div>

                            {/* {ticketPrice(item.available_tickets,item.cost)} */}
                          </span>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <>
                    {' '}
                    <p className="text-center">No Record Found</p>
                  </>
                )}
                <div className="d-flex align-items-center justify-content-between">
                  <span>Total Amount</span>
                  <span>
                    {' '}
                    {priceT !== '' && priceT !== 'Free' ? '$' + priceT : '$0'}
                  </span>
                </div>
              </div>
            </Scrollbars>

            <div className="filter-btn-wrapper pt-3">
              <Button
                className="btn-dark"
                disabled={purchaseTicket.length > 0 ? false : true}
                onClick={() => {
               
                    //  if (cardDeatail.length > 0 ) {

                    //  } else {
                    //   setShowComponent("cardDetail")
                    //  }
                    if (
                      cardDeatail &&
                      priceT !== '' &&
                      priceT !== 'Free' &&
                      priceT !== 0
                    ) {
                      if (totalTickets <= remainingTickets) {
                        setShowModel(true);
                      } else {
                        errorMessage(
                          'Remaining no of tickets ' +
                            parseInt(remainingTickets),
                        );
                      }
                      // show modal
                    } else {
                      if (totalTickets <= remainingTickets) {
                        if (
                          priceT !== '' &&
                          priceT !== 'Free' &&
                          priceT !== 0
                        ) {
                          setShowComponent('cardDetail');
                        } else {
                          props.manageRsvpDolby();
                          props.onClose();
                        }
                      } else {
                        errorMessage(
                          'Remaining no of tickets' + {remainingTickets},
                        );
                      }
                    }
                
                }}>
                Purchase
              </Button>
            </div>
          </div>
        </SlidingPanel>
      )}

      {showModaL && (
        <CustomModal onClose={() => setShowModel(false)}>
          <div className="leave-session-content text-left">
            <h3>Please confirm you would like to purchase ticket</h3>
            <p>${priceT} will be deducted from your card listed below.</p>
            <div className="mt-3">
              <p>
                Card Last 4 Digits:{' '}
                <b>
                  {typeof cardDeatail === 'object' &&
                  !Array.isArray(cardDeatail)
                    ? cardDeatail.card_number
                    : ''}
                </b>
              </p>
            </div>
            <div className="">
              <Button
                className="btn-dark"
                onClick={() => {
                  // setAnotherCard(true)
                  setShowModel(false);
                  // hide modal
                  setShowComponent('cardDetail');
                }}>
                Use A Different Card
              </Button>
              <Button
                className="btn-dark"
                onClick={() => {
                  let data = {
                    amount: priceT,
                    card: '',
                    card_id: cardDeatail.id,
                    cardholder_name: cardDeatail.cardholder_name,
                    // complete_time: payLoadIs.complete_time,
                    device_type: 'web',
                    id_transaction_type: props.eventData,
                    stripe_token: '',
                    transaction_type: tickeType === 'virtual' ? 'session' : 'event',
                    save_card: 'no',
                  };
                  stripePayment(data);
                  setShowModel(false);
                }}>
                Proceed
              </Button>
              <Button
                className="btn-dark"
                onClick={() => {
                  setShowComponent('');
                  setShowModel(false);
                }}>
                I Changed My Mind
              </Button>
            </div>
          </div>
        </CustomModal>
      )}

      {/* <Modal
        show={showModaL}
        onHide={() => {
          setShowModel(false);
        }}>
        <Modal.Header closeButton>
          <Modal.Title>
            Please confirm you would like to purchase Consult
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          ${priceT} will be deducted from your card listed below.
          <div className="mt-3">
            Card Last 4 Digits:
            <br />
            {typeof cardDeatail === 'object' && !Array.isArray(cardDeatail)
              ? cardDeatail.card_number
              : ''}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              // setAnotherCard(true)
              setShowModel(false);
              // hide modal
              setShowComponent('cardDetail');
            }}>
            Use A Different Card
          </Button>
          <br />
          <Button
            variant="primary"
            onClick={() => {
              let data = {
                amount: priceT,
                card: '',
                card_id: cardDeatail.id,
                cardholder_name: cardDeatail.cardholder_name,
                // complete_time: payLoadIs.complete_time,
                device_type: 'web',
                id_transaction_type: props.eventData,
                stripe_token: '',
                transaction_type: 'event',
                save_card: 'no',
              };
              stripePayment(data);
              setShowModel(false);
            }}>
            PROCEED
          </Button>
          <br />
          <Button
            variant="primary"
            onClick={() => {
              setShowComponent('');
              setShowModel(false);
            }}>
            I Changed My Mind
          </Button>
        </Modal.Footer>
      </Modal> */}
    </>
  );
};
export default EventTickets;
