import React, {useEffect} from 'react';
import {Form, Dropdown} from 'react-bootstrap';
import {useState} from 'react';
import {RoiLogo, Trash} from '../../Constants/Images';
import {DragDropContext, Droppable, Draggable} from 'react-beautiful-dnd';
import {
  capitalizeFirst,
  fieldDropdown,
  fieldDropdownTypes,
  fieldTypeFormat,
} from '../../Utils/commonUtils';
import {FaAsterisk, FaBars, FaTrash} from 'react-icons/fa';
import {errorMessage, successMessage} from '../../Utils/ToastMessages';
import EditPencilSvg from '../../Svg/EditPencilSvg';
import ConfirmationModel from '../ConfirmationModel/ConfirmationModel';
import commonApi from '../../Services/CommonService';

const CreateField = ({
  fieldData,
  setFieldData,
  setShowDeleteModal,
  fieldIndex,
  setFieldIndex,
  setTypeIndex,
  typeIndex,
  setFieldTypeArray,
  fieldTypeArray,
  selectField,
  setSelectField,
  setGroupIndex,
  setGroupFields,
  groupFields,
  typeOfField,
  hideButtons,
  editFieldData,
  updateFieldListing,
  onClose,
  userInEdit,
}) => {
  const [fieldType, setFieldType] = useState('text');
  const [visibleForm, setVisibleForm] = useState(true);
  const [fieldName, setFieldName] = useState('');
  const [placeholderText, setPlaceholderText] = useState('');
  const [fieldValues, setFieldValues] = useState('');
  const [search, setSearch] = useState('');
  const [searchField, setSearchField] = useState([]);
  const [validated, setValidated] = useState(false);
  const [fieldRequired, setFieldRequired] = useState(true);
  const [draggedIndex, setDraggedIndex] = useState(null);

  const mouseStyles = {
    cursor: !userInEdit ? 'default' : 'text',
  };

  const handleDragStart = (e, index) => {
    setDraggedIndex(index);
    e.dataTransfer.effectAllowed = 'move';
  };

  const handleDragOver = e => {
    e.preventDefault(); // Allow dropping
  };

  const handleDrop = (e, dropIndex) => {
    e.preventDefault();
    if (draggedIndex === null || draggedIndex === dropIndex) return;

    // Clone array and swap elements
    const newFields = [...groupFields];
    const draggedItem = newFields.splice(draggedIndex, 1)[0];
    newFields.splice(dropIndex, 0, draggedItem);

    setGroupFields(newFields);
    setDraggedIndex(null);
  };

  const onDragEnd = result => {
    const {source, destination} = result;
    if (!destination) return;

    const newFields = [...groupFields];
    const [movedItem] = newFields.splice(source.index, 1);
    newFields.splice(destination.index, 0, movedItem);

    setGroupFields(newFields);
  };

  useEffect(() => {
    if (editFieldData?.type) {
      setFieldIndex({
        editing: true,
        index: -1,
        delete_id: editFieldData?.id ? editFieldData.id : null,
      });

      // setSelectField(item.type);
      setFieldType(editFieldData?.type);
      setFieldName(editFieldData?.name);
      setFieldTypeArray(editFieldData?.options);
      setFieldRequired(editFieldData?.required);
      setPlaceholderText(editFieldData?.placeholder);
    } else {
      setSearchField([]);
      setValidated(false);
      setFieldIndex({
        editing: false,
        index: null,
        delete_id: null,
      });
      setGroupFields([]);
      setSearch('');
      setPlaceholderText('');
      setFieldName('');
      setFieldTypeArray([]);
    }
  }, [selectField, editFieldData]);

  const handle = event => {
    event.preventDefault();
    event.stopPropagation();
    const form = event.currentTarget;

    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      setValidated(true);
    } else {
      event.preventDefault();

      setValidated(false);

      if (
        selectField === 'field' &&
        (fieldType === 'radio' ||
          fieldType === 'checkbox' ||
          fieldType === 'select' ||
          fieldType === 'multi_select') &&
        fieldTypeArray?.length === 0
      ) {
        errorMessage('Please add options.');
      } else if (selectField === 'field_group' && groupFields.length === 0) {
        errorMessage('Please add one field.');
      } else {
        // handleFields();
        if (fieldIndex.delete_id) {
          if (selectField === 'field') {
            create_update_fields(fieldIndex.delete_id);
          } else {
            create_update_fields_group(fieldIndex.delete_id);
          }
        } else {
          if (selectField === 'field') {
            create_update_fields();
          } else {
            create_update_fields_group();
          }
        }
      }
    }
  };
  const handleFields = item => {
    let newField;

    if (!item.group_name) {
      newField = {
        fields: selectField,
        id: item.id,
        name: item.name || '',
        type: item.type,
        options: item.options,
        required: item.required,
        placeholder: item.placeholder,
      };
    } else {
      newField = {
        id: item.id,
        group_name: item.group_name || '',
        // group_id:item.group_id,
        field_details: item.field_details,
      };
    }

    setFieldData(prev => {
      const updatedFields = {...prev};

      if (fieldIndex.editing) {
        // Determine correct field type array
        const targetArray = item.type ? 'fields' : 'group_fields';

        // Update existing field
        updatedFields[targetArray] = updatedFields[targetArray].map(
          (item, index) => (index === fieldIndex.index ? newField : item),
        );
      } else {
        // Add new field
        if (item.type) {
          updatedFields.fields = [newField, ...updatedFields.fields];
        } else {
          updatedFields.group_fields = [
            newField,
            ...updatedFields.group_fields,
          ];
        }
      }

      return updatedFields;
    });

    reset();
  };

  const reset = () => {
    // setSelectField('field_group');
    // setFieldType('text');
    setFieldIndex({
      editing: false,
      index: null,
      delete_id: null,
    });
    setFieldName('');
    setFieldTypeArray([]);
    setPlaceholderText('');
    setFieldRequired(true);
    setGroupFields([]);
    setValidated(false);
  };

  const handleValues = type => {
    if (type === 'add') {
      if (fieldValues !== '') {
        if (!typeIndex.editing) {
          setFieldTypeArray(prev => [...prev, fieldValues]);
        } else {
          setFieldTypeArray(prev =>
            prev.map((item, idx) =>
              idx === typeIndex.index ? fieldValues : item,
            ),
          );
          setTypeIndex({
            editing: false,
            index: null,
          });
        }
        setFieldValues('');
      } else {
        errorMessage('Please add options.');
      }
    }
  };

  const cancelEdit = () => {
    setFieldValues('');
    setTypeIndex({
      editing: false,
      index: null,
    });
  };

  const handleEdit = (item, index) => {
    setFieldIndex({
      editing: true,
      index: index,
      delete_id: item?.id ? item.id : null,
    });

    if (item.type) {
      // setSelectField(item.type);
      setFieldType(item.type);
      setFieldName(item.name);
      setFieldTypeArray(item.options);
      setFieldRequired(item.required);
      setPlaceholderText(item.placeholder);
    } else {
      setFieldName(item.group_name);
      setGroupFields(item.field_details);
    }
  };

  const getFieldList = () => {
    return selectField === 'field_group'
      ? fieldData.group_fields
      : fieldData.fields;
  };
  
  const create_update_fields = id => {
    const payload = {
      field_id: id ? id : '',
      name: fieldName,
      type: fieldType,
      required: fieldRequired,
      options: fieldTypeArray?.length > 0 ? fieldTypeArray : [],
      placeholder: placeholderText ? placeholderText : '',
    };
    // return;
    commonApi
      .create_update_fields(payload)
      .then(res => {
        if (res.status === 200) {
          if (res.data) {
            if (hideButtons) {
              successMessage(res.message);
              updateFieldListing(res.data, id ? 'edit' : 'add');
              onClose();
            } else {
              handleFields(res.data);
              successMessage(res.message);
            }
          }
        } else {
          errorMessage(res.message);
        }
      })
      .catch(err => {
        
        return {type: 'error', message: err.message};
      });
  };
  const create_update_fields_group = id => {
    const filterIds = groupFields.map(item => item.id);
    const payload = {
      group_id: id ? id : '',
      group_name: fieldName,
      field_ids: filterIds?.length > 0 ? filterIds : [],
    };

    // console.log(payload, id);
    // return;
    commonApi
      .create_update_fields_group(payload)
      .then(res => {
        if (res.status === 200) {
          // if (res.data.length > 0) {
          handleFields(res.data);
          successMessage(res.message);
          // }
        } else {
          errorMessage(res.message);
        }
      })
      .catch(err => {
       
        return {type: 'error', message: err.message};
      });
  };

  const get_search_fields = value => {
    const payload = {
      search_query: search ? search : value,
    };

    // return;
    commonApi
      .get_search_fields(payload)
      .then(res => {
        if (res.status === 200 && res.success) {
          const filterFields = res?.fields_details?.filter(item =>
            groupFields?.every(field => field?.id !== item.id),
          );

          setSearchField(filterFields);
        } else {
          errorMessage(res.message);
        }
      })
      .catch(err => {
        
        return {type: 'error', message: err.message};
      });
  };

  useEffect(() => {
    get_search_fields(search);
  }, [search]);

  const fieldsExists = (groupFields, itemId) => {
    return groupFields.some(field => field.id === itemId);
  };

  const handleGroupFields = item => {
    if (!fieldsExists(groupFields, item.id)) {
      setGroupFields(prev => [...prev, item]);
      setSearch('');
    } else {
      errorMessage('You have Already added this field.');
    }
  };

  const decideButtnName = () => {
    if (selectField === 'field') {
      if (fieldIndex.editing) {
        return 'Update Field';
      } else {
        return 'Add Field';
      }
    } else {
      if (fieldIndex.editing) {
        return 'Update Field Group';
      } else {
        return 'Add Field Group';
      }
    }
  };

  // useEffect(()=>{
  //   if(fieldType !== 'radio' &&
  //     fieldType !== 'checkbox' &&
  //     fieldType !== 'select' &&
  //     fieldType !== 'multi_select'){
  //       setFieldTypeArray([])
  //       if(fieldType === 'radio' ||
  //         fieldType === 'checkbox'){
  //           setPlaceholderText('')
  //       }
  //     }
  // },[fieldType])

  return (
    <>
      <Form noValidate validated={validated} onSubmit={handle}>
        <div className="invoice mt-3">
          <div className="billing-detail" style={{backgroundColor: '#EDEBE5'}}>
            <div
              style={{justifyContent: 'space-between'}}
              className="d-flex align-items-center">
              <h1
                className="pt-2 pb-2 as-link"
                onClick={() => {
                  setVisibleForm(!visibleForm);
                }}>
                New Field
              </h1>
              {selectField === 'field' && (
                <div className="toggle_btn flex">
                   <label
                    htmlFor="custom-switchy"
                    className="mr-1 text-sm font-medium">
                    Required
                  </label>
                  <Form.Check
                    type="switch"
                    className="form-switch"
                    id="custom-switch"
                    // label={`Required`}
                    value={fieldRequired ? true : false}
                    checked={fieldRequired ? true : false}
                    disabled={userInEdit ? false : true}
                    onClick={() => {
                      setFieldRequired(!fieldRequired);
                    }}
                  />
                </div>
              )}
            </div>

            <div
              className={`overflow-hidden overflow-y-auto custom-scrollbar ease-in-out transition-all duration-300 ${
                visibleForm ? 'max-h-100' : 'max-h-0'
              }`}>
              <hr class="border-t border-gray-300 pb-3"></hr>
              <div
                onClick={() => {
                  // setEditProject(true);
                }}
                className="  pt-0 space-y-2 height-input">
                <div className="mt-3">
                  {!hideButtons && (
                    <div className="roi-form-dropdown mb-3">
                      <div className="select-wrapper">
                        <Dropdown
                          onSelect={(eventKey, event) => {
                            setSelectField(eventKey);
                          }}
                          drop="down"
                          disabled={hideButtons ? true : false}>
                          <Dropdown.Toggle
                            variant="primary"
                            disabled={hideButtons ? true : false}
                            style={{opacity: 1}}>
                            {selectField
                              ? fieldDropdown?.find(
                                  item => item.id === selectField,
                                ).name
                              : 'Select Role'}
                          </Dropdown.Toggle>

                          <Dropdown.Menu>
                            {fieldDropdown?.map((item, index) => (
                              <Dropdown.Item
                                key={`st-${index}`}
                                eventKey={item.id.toString()}
                                active={item.id === selectField}>
                                {item.name}
                              </Dropdown.Item>
                            ))}
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    </div>
                  )}

                  <div className="roi-form-input">
                    <Form.Control
                      type="text"
                      value={fieldName}
                      pattern="^\S.*$"
                      required
                      placeholder={
                        selectField === 'field' ? 'Field Name' : 'Group Name'
                      }
                      readOnly={!userInEdit}
                      onChange={e => {
                        setFieldName(e.target.value);
                      }}
                      style={mouseStyles}
                    />
                    <Form.Control.Feedback type="invalid">
                      {selectField === 'field'
                        ? 'Field name is required.'
                        : 'Group name is required.'}
                    </Form.Control.Feedback>
                  </div>
                  {selectField === 'field_group' && (
                    <div className="roi-form-input">
                      <Form.Control
                        type="text"
                        value={search}
                        placeholder="Search fields"
                        readOnly={!userInEdit}
                        onChange={e => {
                          setSearch(e.target.value);
                        }}
                        style={mouseStyles}
                      />
                    </div>
                  )}

                  {selectField === 'field_group' && search && (
                    <ul className="custom-suggestions invoices_suggestion">
                      {searchField?.length > 0 ? (
                        searchField?.map(suggestion => (
                          <li
                            className="as-link"
                            key={'suggestion' + suggestion.id}
                            onClick={() => {
                              handleGroupFields(suggestion);
                            }}>
                            {suggestion.name}
                          </li>
                        ))
                      ) : (
                        <li className="as-link">No Fields available.</li>
                      )}
                    </ul>
                  )}

                  {selectField === 'field_group' && groupFields.length > 0 && (
                    <>
                      <hr class="border-t border-gray-300 pb-3"></hr>

                      <h1 className="pt-2 pb-2 as-link">Added Fields</h1>
                    </>
                  )}
                  <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="groupFieldsList" type="FIELD">
                      {provided => (
                        <div
                          {...provided.droppableProps}
                          ref={provided.innerRef}>
                          {selectField === 'field_group' &&
                            groupFields?.length > 0 &&
                            groupFields.map((item, index) => (
                              <Draggable
                                key={index.toString()}
                                draggableId={index.toString()}
                                index={index}>
                                {provided => (
                                  <div
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    className="mt-2 flex justify-between items-center bg-white p-3 rounded-lg">
                                    <div
                                      className="flex items-center gap-2"
                                      {...provided.dragHandleProps}>
                                      <FaBars className="text-gray-400 " />
                                      <div>
                                        <div className="text-sm font-medium">
                                          {item.name}
                                        </div>
                                        <div className="text-xs text-gray-500">
                                          {capitalizeFirst(fieldTypeFormat(item.type))}
                                        </div>
                                      </div>
                                    </div>
                                    <div className="flex items-center gap-2">
                                      {/* <FaAsterisk
                                        fill={
                                          item.required ? '#52bd41' : 'black'
                                        }
                                      /> */}
                                      <p className="as-link">
                                        <span className="poc_delete as-link">
                                          <i
                                            className="fa-solid fa-trash-can"
                                            onClick={() => {
                                              // setGroupIndex({
                                              //   editing: false,
                                              //   index: index,
                                              //   type: 'goup_fields_del',
                                              // });

                                              // setShowDeleteModal(true);
                                              setGroupFields(prev =>
                                                prev.filter((_, itemIndex) => itemIndex !== index),
                                              );
                                            }}
                                          />
                                        </span>
                                      </p>
                                    </div>
                                  </div>
                                )}
                              </Draggable>
                            ))}
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </DragDropContext>

                  {selectField === 'field' &&
                    (fieldType === 'multi_select' ||
                      fieldType === 'select' ||
                      fieldType === 'text' ||
                      fieldType === 'number' ||
                      fieldType === 'textarea' ||
                      fieldType === 'date_picker' ||
                      fieldType === 'date_time_picker' ||
                      fieldType === 'e_signature'
                    ) && (
                      <div className="roi-form-input">
                        <Form.Control
                          type="text"
                          value={placeholderText}
                          pattern="^\S.*$"
                          placeholder={'Placeholder'}
                          readOnly={!userInEdit}
                          required
                          onChange={e => {
                            setPlaceholderText(e.target.value);
                          }}
                          style={mouseStyles}
                        />
                        <Form.Control.Feedback type="invalid">
                          Placeholder is required.
                        </Form.Control.Feedback>
                      </div>
                    )}

                  {selectField === 'field' && (
                    <div className="mt-1 roi-form-dropdown mb-3">
                      <div className="select-wrapper">
                        <Dropdown
                          onSelect={(eventKey, event) => {
                            setFieldType(eventKey);
                            // setPlaceholderText('');
                            setFieldValues('');
                            // setFieldTypeArray([]);
                          }}
                          drop="down"
                          disabled={userInEdit ? false : true}>
                          <Dropdown.Toggle
                            variant="primary"
                            disabled={userInEdit ? false : true}
                            style={{opacity: 1}}>
                            {fieldType
                              ? fieldDropdownTypes.find(
                                  item => item.id === fieldType,
                                ).name
                              : 'Select Role'}
                          </Dropdown.Toggle>

                          <Dropdown.Menu>
                            {fieldDropdownTypes.map((item, index) => (
                              <Dropdown.Item
                                key={`st-${index}`}
                                eventKey={item.id.toString()}
                                active={item.id === fieldType}>
                                {item.name}
                              </Dropdown.Item>
                            ))}
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    </div>
                  )}

                  {selectField === 'field' &&
                    (fieldType === 'radio' ||
                      fieldType === 'checkbox' ||
                      fieldType === 'select' ||
                      fieldType === 'multi_select') && (
                      <>
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                          }}>
                          <h1 className="pt-2 pb-2">Options</h1>
                          {userInEdit && (
                            <span
                              className={'icon as-link'}
                              onClick={() => {
                                handleValues('add');
                              }}>
                              <i className="icon-plus"></i>
                            </span>
                          )}
                        </div>
                        {userInEdit && (
                          <div className="roi-form-input">
                            <Form.Control
                              type="text"
                              value={fieldValues}
                              pattern="^\S.*$"
                              // required
                              readOnly={!userInEdit}
                              placeholder={'Option title'}
                              onChange={e => {
                                setFieldValues(e.target.value);
                              }}
                              style={mouseStyles}
                            />
                            <Form.Control.Feedback type="invalid">
                              Option is required.
                            </Form.Control.Feedback>
                          </div>
                        )}
                        {/* <div className="m-2 flex justify-center items-center mt-3">
                        <button
                          type="button"
                          onClick={() => {
                            handleValues('add');
                          }}
                          className="bg-black text-white px-4 py-2 rounded-md">
                          {typeIndex.editing ? 'Update' : 'Add'}
                        </button>
                      </div>
                      <div className="m-2 flex justify-center items-center mt-3">
                        {typeIndex.editing && (
                          <h2
                            className="mb-2 as-link"
                            onClick={() => {
                              cancelEdit();
                            }}>
                            Cancel
                          </h2>
                        )}
                      </div> */}
                      </>
                    )}

                  {selectField === 'field' && 
                  (fieldType === 'radio' ||
                      fieldType === 'checkbox' ||
                      fieldType === 'select' ||
                      fieldType === 'multi_select') && fieldTypeArray?.length > 0 && (
                    <>
                      {fieldTypeArray?.map((item, index) => (
                        <div
                          key={item.id}
                          className="mt-2 flex justify-between items-center bg-white p-3 rounded-lg">
                          <div className="flex items-center gap-2">
                            <div>
                              <div className="text-sm font-medium">{item}</div>
                              {/* <div className="text-xs text-gray-500">
                      {item.fieldType}
                    </div> */}
                            </div>
                          </div>
                          {userInEdit && (
                            <div className="flex items-center gap-2">
                              <p className="as-link">
                                <span className="poc_delete as-link">
                                  <i
                                    class="fa-solid fa-trash-can"
                                    onClick={() => {
                                      // handleValues('delete', index);

                                      setShowDeleteModal(true);
                                      setTypeIndex({
                                        editing: false,
                                        index: index,
                                        type: 'delete_option',
                                      });
                                    }}
                                  />
                                </span>
                              </p>
                              {/* <p
                              onClick={() => {
                                setFieldValues(item);
                                setTypeIndex({
                                  editing: true,
                                  index: index,
                                });
                              }}
                              className="as-link cross-icon-field">
                              <EditPencilSvg fill={'#52bd41'} h={15} w={15} />
                            </p> */}
                            </div>
                          )}
                        </div>
                      ))}
                    </>
                  )}
                  {userInEdit && (
                    <div
                      className="flex justify-center items-center mt-3"
                      style={{
                        flexDirection: 'column',
                      }}>
                      <button
                        style={{
                          height: '42px',
                        }}
                        type="submit"
                        // onClick={()=>{create_update_fields(fieldIndex.delete_id)}}
                        className="bg-black text-white px-4 py-2 rounded-md">
                        {decideButtnName()}
                      </button>
                      {!hideButtons && fieldIndex.editing && (
                        <h2
                          className="mt-2 as-link"
                          onClick={() => {
                            reset();
                          }}>
                          Cancel
                        </h2>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          {!typeOfField && getFieldList().length > 0 && (
            <>
              {getFieldList().map((item, index) => (
                <div
                  key={index}
                  className="mt-2 flex justify-between items-center bg-white p-3 rounded-lg ">
                  <div className="flex items-center gap-2">
                    <div>
                      <div className="text-sm font-medium truncate_2_lines">
                        {item.name ? item.name : item?.group_name}
                      </div>
                      {item.field_details?.length > 0 && (
                        <div className="text-xs text-gray-500">
                          {`${item.field_details?.length} Field`}
                        </div>
                      )}
                      {item.type && (
                        <div className="text-xs text-gray-500">
                          {capitalizeFirst(fieldTypeFormat(item.type))}
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="flex items-center gap-2">
                    {item.type && (
                      <FaAsterisk fill={item.required ? '#52bd41' : 'black'} />
                    )}
                    {/* <FaTrash
                      className="text-gray-600 cursor-pointer hover:text-red-500"
                      onClick={() => {
                        setFieldIndex({
                          editing: false,
                          index: index,
                          delete_id: item?.id ? item?.id : null,
                          type: item.type ? 'delete_field' : 'delete_group_field'
                        });
                        setShowDeleteModal(true);
                      }}
                    /> */}
                    <p className="as-link">
                      <span className="poc_delete as-link">
                        <i
                          class="fa-solid fa-trash-can"
                          onClick={() => {
                            setFieldIndex({
                              editing: false,
                              index: index,
                              delete_id: item?.id ? item?.id : null,
                              type: item.type
                                ? 'delete_field'
                                : 'delete_group_field',
                            });
                            setShowDeleteModal(true);
                          }}
                        />
                      </span>
                    </p>
                    <p
                      className="as-link"
                      onClick={() => {
                        handleEdit(item, index);
                      }}>
                      <EditPencilSvg fill={'#52bd41'} h={15} w={15} />
                    </p>
                  </div>
                </div>
              ))}
            </>
          )}
        </div>
      </Form>
    </>
  );
};

export default CreateField;
