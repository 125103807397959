import React, {useEffect} from 'react';
import {Button, Form} from 'react-bootstrap';
import Sidebar from '../Sidebar';
import {useState} from 'react';
import {CustomSlidingButtons, Pannels} from '../../Components';
import CreateWorkSpace from '../../Components/WorkSpace/CreateWorkSpace';
import Scrollbars from 'react-custom-scrollbars';
import CreateForm from '../../Components/ROIForm/CreateForm';
import Slider from 'react-slick';
import {rewardStar} from '../../Constants/Images';
import CreateDeal from '../../Components/Deals/CreateDeal';
import {capitalizeFirst, fieldTypeFormat, FomsPermmesion, formFilterOption} from '../../Utils/commonUtils';
import SvgIconComponent from '../../Constants/SvgIconComponent';
import commonApi from '../../Services/CommonService';
import NorecordFound from '../../Components/NoRecord/NorecordFound';
import {filedNoFound, formNoFound} from '../../Utils/NoRecordConstant';
import { FaAsterisk } from 'react-icons/fa';
import LoaderSpinner from '../../Components/Modals/LoaderSpinner';

const FormPage = props => {
  const [threeDots, setThreeDots] = useState(false);
  const [chatSearchQry, setChatSearchQry] = useState('');
  const [tabType, setTabType] = useState('Forms');
  const [showComponent, setShowComponent] = useState('');
  const [currentComponent, setCurrentComponent] = useState('');
  const [workspaceData, setWorkspaceData] = useState([]);
  const [showHiddenPanel, toggleHiddenPanel] = useState(false);
  const [isFloated, toggleFloatedPanel] = useState(false);
  const [analycticsSlider, setAnalycticsSlider] = useState(false);
  const [formButtonsFilter, setFormButtonsFilter] = useState('');
  const [formListing, setFormListing] = useState([]);
  const [editFormItem, setEditFormItem] = useState({});
  const [editFieldItem, setEditFieldItem] = useState({});
  const [fieldListing, setFieldListing] = useState([]);
  const [showLoader, setShowLoader] = useState(true);
  const [formFiledPermmession, setFormFiledPermmession] = useState({});

  const rewardSlider = {
    dots: false,
    arrows: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
  };
  const rewardSliderData = [
    {
      name: 'On-Time',
      money: '$2.4k',
      time: 'versus last month',
      svgIcon: rewardStar,
    },

    {
      name: 'Duration',
      money: '5m 20s',
      time: 'versus last month',
      svgIcon: rewardStar,
      // icon: 'fa-solid fa-star',
    },

    {
      name: 'Completion',
      money: '45%',
      time: 'versus last month',
      svgIcon: rewardStar,
    },
    {
      name: 'Rating',
      money: '4.8',
      time: 'versus last month',
      svgIcon: rewardStar,
    },
  ];

  const dynamicStyle = {
    padding: '0px 0px 15px',
  };

  useEffect(() => {
    if (currentComponent !== '') {
      toggleHiddenPanel(true);
    } else {
      toggleHiddenPanel(false);
    }
  }, [currentComponent]);
  useEffect(() => {
    get_form_field_permissions();
  }, []);
  useEffect(() => {
    if (tabType === 'Fields') {
      get_field_and_group(chatSearchQry)
    } else {
      get_all_forms(chatSearchQry)
    }
  }, [chatSearchQry,tabType]);
  useEffect(() => {
    setChatSearchQry('')
  }, [tabType]);

  const get_all_forms = (search) => {
    const payload = {
      search_query: search ? search : chatSearchQry,
    };

    
    commonApi
      .get_all_forms(payload)
      .then(res => {
        if (res.status === 200) {
          setFormListing(res.data);
          setTimeout(() => {
            setShowLoader(false)
          }, 1200);
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  useEffect(()=>{
    setShowLoader(true)
  },[tabType])
  const get_field_and_group = (search) => {
    const payload = {
      search_query: search ? search : chatSearchQry,
    };

    
    commonApi
      .get_field_and_group(payload)
      .then(res => {
        if (res.status === 200) {
          setFieldListing(res.fields);

          setTimeout(() => {
            setShowLoader(false)
          }, 1200);
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };
  const get_form_field_permissions  = (search) => {
       commonApi
      .get_form_field_permissions ()
      .then(res => {
        if (res.status === 200) {
          setFormFiledPermmession(res)
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  const handleEdit = (item,type) => {
    setEditFormItem(item);
    setCurrentComponent(type);
  };

  const handleAddUpdateForm = (item, type) => {
    if (type === 'add') {
      setFormListing(prev => [item, ...prev,]);
    } else if (type === 'edit') {
      setFormListing(prev =>
        prev.map(existingItem =>
          existingItem.id === item.id ? item : existingItem,
        ),
      );
    }
  };
  const handleAddUpdateFields = (item, type) => {
    if (type === 'add') {
      setFieldListing(prev => [item, ...prev,]);
    } else if (type === 'edit') {
      setFieldListing(prev =>
        prev.map(existingItem =>
          existingItem.id === item.id ? item : existingItem,
        ),
      );
    }
  };
  return (
    <>
      <div className="page-wrapper">
        <Sidebar
          location={props.location}
          setShowComponent={setShowComponent}
          setWorkSpaceItem={setWorkspaceData}
          showComponent={showComponent}
          workspaceData={workspaceData}
        />
        <div className="page-content-wrapper">
          <div className="sessions-list-wrapper slider-screen-main">
            <div className="Reward_main analytics_slider_bg">
              <span
                className="analytics_slider_heading "
                style={{color: analycticsSlider ? '' : '#999999'}}
                onClick={() => {
                  setAnalycticsSlider(!analycticsSlider);
                }}>
                Analytics Row
              </span>

              {analycticsSlider && (
                <div className="reward-top mt-3 mb-2">
                  <Slider {...rewardSlider}>
                    {rewardSliderData.length > 0 &&
                      rewardSliderData.map((item, index) => (
                        <div className="reward-main">
                          <div className="reward-card">
                            <span style={{fontWeight: 700, fontSize: '13px'}}>
                              {item.name}
                            </span>
                            <span
                              style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                              }}>
                              <span style={{fontSize: '17px'}}>
                                {item.money}
                              </span>
                              {item.name === 'Ratings' && (
                                <img src={item.svgIcon} alt="" />
                              )}
                              {item.name === 'Conversion' ? (
                                <i
                                  className="fa-solid fa-arrow-down"
                                  style={{color: '#BD4141'}}></i>
                              ) : (
                                <i
                                  class="fa-solid fa-arrow-up"
                                  style={{color: '#52bd41'}}></i>
                              )}
                            </span>
                            <div class="message-date-info">
                              <span
                                style={{color: '#333'}}
                                class="message-date">
                                {item.time}
                              </span>
                            </div>
                          </div>
                        </div>
                      ))}
                  </Slider>
                </div>
              )}
            </div>
            <div className="add_border"></div>

            <Pannels
              isFloated={isFloated}
              showHiddenPanel={showHiddenPanel}
              updateHiddenPanelState={nState => toggleHiddenPanel(nState)}
              customCss={dynamicStyle}
              toggleFloatedPanel={toggleFloatedPanel}
              primary={
                <>
                  <div className="form-page primary_panel_wraper primary_panel_content">
                    <div className="messages-header mb-2">
                      <div className="event-tabs tabs-style-2 mb-2">
                        {' '}
                        <ul>
                          <li>
                            <Button
                              className={tabType === 'Forms' ? 'active' : ''}
                              onClick={() => {
                                setTabType('Forms');
                              }}>
                              Forms
                            </Button>
                          </li>

                          <li>
                            <Button
                              className={tabType === 'Fields' ? 'active' : ''}
                              onClick={() => {
                                setTabType('Fields');
                              }}>
                              Fields
                            </Button>
                          </li>
                        </ul>
                      </div>

                      <div className="search-tabs-icons">
                       {formFiledPermmession?.success && FomsPermmesion(tabType,formFiledPermmession) === true && 
                        <span
                          className={
                            currentComponent === 'create-form' ||    currentComponent === 'create-field'
                              ? 'icon active'
                              : 'icon'
                          }>
                          <i
                            className="icon-plus"
                            onClick={() => {
                              if (currentComponent !== '') {
                                setCurrentComponent('');
                              } else {
                                if (tabType === 'Fields') {
                                  setCurrentComponent('create-field')
                                }else{
                                  setCurrentComponent('create-form');
                                }
                               
                              }
                              setEditFormItem({})
                            }}
                          />
                        </span>
              }
                        <span
                          onClick={() => {
                            setThreeDots(!threeDots);
                          }}
                          className={
                            threeDots
                              ? 'icon active threeDots'
                              : 'icon threeDots'
                          }>
                          <i className="fas fa-ellipsis-vertical" />
                        </span>
                      </div>
                    </div>

                    {threeDots && (
                      <>
                        <div className="payment-search-wrapper mb-0 messages-users-wrapper mb-4">
                          <div className="search-form d-flex">
                            <Form.Group className="search-input">
                              <Form.Control
                                type="text"
                                placeholder="Search"
                                value={chatSearchQry}
                                onChange={e => {
                                  setChatSearchQry(e.target.value);
                                 
                                }}
                              />
                            </Form.Group>
                            <Button>
                              <i
                                className="icon-filter-icon"
                                // onClick={() => setSearchFilterModal(true)}
                              />
                            </Button>
                          </div>
                        </div>
                        <div className="category-btns-wrapper category-btns-wrapper-slider mt-0 mb-4">
                          <CustomSlidingButtons
                            items={formFilterOption}
                            activeButtons={formButtonsFilter}
                            // onItemClick={e => {
                            //   handleType(e);

                            // }}
                            showItems={3}
                            value={formButtonsFilter}
                          />
                        </div>
                      </>
                    )}
                    <div className="refferal-list-wrapper">
                    {showLoader && <LoaderSpinner/>}

                      <Scrollbars
                        className="custom-refferal-vertical-scrollbar"
                        autoHide>
                          
                          {tabType === 'Forms' && 
                        <div className="form-page-content">
                          {formListing?.length > 0 ? (
                            formListing.map((item, index) => (
                              <div
                                className="form-card-main as-link"
                                key={index}
                                onClick={() => {
                                  handleEdit(item,'create-form');
                                }}>
                                <div className="form-listing-card">
                                  <h1 className='form-listing-truncate'>{item.name}</h1>
                                  <SvgIconComponent
                                    icon={'info'}
                                    fill={'#a39f8d'}
                                    h={'20'}
                                    w={'20'}
                                  />
                                </div>

                                <div
                                  className="form-card-bottom"
                                  style={{
                                    marginTop: '15PX',
                                  }}>
                                  <div>
                                    Submissions <strong>35</strong>
                                  </div>
                                  <span>
                                    {' '}
                                    Campaigns <strong>4</strong>
                                  </span>

                                  {/* <span> Tue, December 5 2024</span> */}
                                </div>
                              </div>
                            ))
                          ) : (
                            <div style={{paddingTop: '20px'}}>
                              <NorecordFound
                                noRecordData={formNoFound}
                                buttonPermesion={formFiledPermmession?.success && FomsPermmesion(tabType,formFiledPermmession) === true}
                                ButtonAction={() => {
                                  setCurrentComponent('create-form');
                                }}
                              />
                            </div>
                          )}
                        </div>}
                        {tabType === 'Fields' && 
                        <div className="form-page-content">
                          {fieldListing?.length > 0 ? (
                            fieldListing.map((item, index) => (
                              <div
                                className="form-card-main as-link"
                                key={index}
                                onClick={() => {
                                  handleEdit(item,'create-field');
                                }}>
                                <div className="form-listing-card">
                                  <h1 className='form-listing-truncate'>{item.name}</h1>
                                  <FaAsterisk fill={item.required ? '#52bd41' : 'black'} />
                                </div>

                                <div
                                  className="form-card-bottom"
                                  style={{
                                    marginTop: '15PX',
                                  }}>
                                  <div>
                                     {capitalizeFirst(fieldTypeFormat(item.type))}
                                  </div>
                                  <span>
                                    {' '}
                                   
                                  </span>

                                  {/* <span> Tue, December 5 2024</span> */}
                                </div>
                              </div>
                            ))
                          ) : (
                            <div style={{paddingTop: '20px'}}>
                              <NorecordFound
                                noRecordData={filedNoFound}
                                buttonPermesion={formFiledPermmession?.success && FomsPermmesion(tabType,formFiledPermmession) === true}
                                ButtonAction={() => {
                                  setCurrentComponent('create-field');
                                }}
                              />
                            </div>
                          )}
                        </div>}
                      </Scrollbars>
                    </div>
                  </div>
                </>
              }
              secondary={
                <>
                  {currentComponent === 'create-form' && (
                    <CreateForm                             
                      onClose={() => {
                        setCurrentComponent('');
                        setEditFormItem({});
                      }}
                      permesions={formFiledPermmession?.form?.create_form? true : false}
                      formPermesion={formFiledPermmession?.form?.create_form? true : false}
                      fieldPermesion={formFiledPermmession?.field?.create_field? true : false}
                       Formtype='forms'
                      hideButtons={editFormItem?.type ? true : false}
                      editFormItem={editFormItem}
                      handleAddUpdateForm={handleAddUpdateForm}
                    />
                  )}
                  {currentComponent === 'create-field' && (
                    <CreateForm
                      onClose={() => {
                        setCurrentComponent('');
                        setEditFormItem({});
                      }}
                      permesions={formFiledPermmession?.field?.create_field? true : false}
                      formPermesion={formFiledPermmession?.form?.create_form? true : false}
                      fieldPermesion={formFiledPermmession?.field?.create_field? true : false}
                      type='field'
                      hideButtons={true}
                      editFieldData={editFormItem}
                      handleAddUpdateFields={handleAddUpdateFields}
                    />
                  )}
                  {currentComponent === 'create-deal' && (
                    <CreateDeal
                      onClose={() => {
                        setCurrentComponent('');
                      }}
                    />
                  )}
                </>
              }
            />

            {showComponent === 'WorkSpace' && (
              <CreateWorkSpace
                onClose={() => {
                  setShowComponent('');
                }}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};
export default FormPage;
