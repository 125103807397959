import {Button, Form} from 'react-bootstrap';
import Sidebar from '../../Pages/Sidebar';
import {Cart, cupcake} from '../../Constants/Images';
import Scrollbars from 'react-custom-scrollbars';
import React, {useState, useEffect, useRef} from 'react';
import {
  CustomSlidingButtons,
  CustomSlidingTabs,
  Pannels,
} from '../../Components';

import CreateWorkSpace from '../WorkSpace/CreateWorkSpace';
import Slider from 'react-slick';
import SvgIconComponent from '../../Constants/SvgIconComponent';
import LoaderSpinner from '../Modals/LoaderSpinner';
import commonApi from '../../Services/CommonService';
import ScrollLoader from '../Modals/ScrollLoader';
import {DEFAULT_IMAGE_COMPANYLOGO} from '../../Constants/Images';

import {CustomModal} from '../../Components';
import MarketFilterModal from '../Modals/MarketFilterModal';
import {marketTabs} from '../../Utils/commonUtils';
import CompanyList from './CompanyList';
import CompanyProfile from './CompanyProfile';
import {flushSync} from 'react-dom';
import TransformDetail from '../Transformation/TransformDetail';
import SnapshotDetail from '../Snapshot/SnapshotDetail';
import SendInvitation from '../../Pages/Front/StaticPages/SendInvitation';
const CustomerProduct = props => {
  const [subTabType, setSubTabsType] = useState('All');
  const [showComponent, setShowComponent] = useState('');
  const [isFloated, toggleFloatedPanel] = useState(false);
  const [showHiddenPanel, toggleHiddenPanel] = useState(false);
  const [workspaceData, setWorkspaceData] = useState([]);
  const [threeDots, setThreeDots] = useState(false);
  const [tabType, setTabType] = useState('services');
  const [showLoader, setShowLoader] = useState(true);
  const [vendorsList, setVendorsList] = useState([]);
  const [productList, setProductList] = useState([]);
  const [serviceList, setServiceList] = useState([]);
  const [catalogType, setCatalogType] = useState('service');
  const [showAllIndustryId, setShowAllIndustryId] = useState('');

  const [seconadaryComponent, setSecondaryComponent] = useState('');
  const [primaryComponent, setPrimaryComponent] = useState('');

  const [categories, setCategories] = useState([]);
  const [industryData, setIndustryData] = useState([]);

  const [activeIndustryId, setActiveIndustryId] = useState('');
  const [isCheckAll, setIsCheckAll] = useState(false);
  const [isCheck, setIsCheck] = useState([]);
  const [list, setList] = useState([]);

  const [categoryCompanies, setCategoryCompanies] = useState([]);
  const [categoriesIds, setCategoriesIds] = useState([]);
  const [companyId, setCompanyId] = useState('');
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const isFetching = useRef(false);
  const hasMoreData = useRef(false);
  const [searchQuery, setSearchQuery] = useState('');

  const [dataFroNextComponent, setDataFroNextComponent] = useState([]);

  const marketTabs = [
    {
      id: 'all',
      key: 'All',
      label: 'All',
      icon: '',
      img: '',
    },
    {
      id: '1',
      key: 'Trade Services',
      label: 'Trade Services',
      icon: '',
      img: (
        <SvgIconComponent
          icon={'home_services'}
          fill={subTabType === 'Trade Services' ? 'white' : '#262626'}
          h={'27'}
          w={'26'}
        />
      ),
    },
    {
      id: '2',
      key: 'Retail',
      label: 'Retail',
      icon: 'fa-solid fa-cart-shopping',
      img: '',
    },

    // {
    //   key: 'consults',
    //   label: 'Consults',
    // },
    // {
    //   key: 'rideshare',
    //   label: 'Rideshare',
    //   img: (
    //     <SvgIconComponent
    //       icon={'rideshare'}
    //       fill={
    //         subTabType === 'rideshare'
    //           ? 'white'
    //           : '#262626'
    //       }
    //       h={'25'}
    //       w={'30'}
    //     />
    //   ),
    // },
    {
      id: '3',
      key: 'Delivery / Pickup',
      label: 'Delivery / Pickup',
      img: (
        <SvgIconComponent
          icon={'pickup_drop_off'}
          fill={subTabType === 'Delivery / Pickup' ? 'white' : '#262626'}
          h={'25'}
          w={'30'}
        />
      ),
    },

    {
      id: '4',
      key: 'Consulting',
      label: 'Consulting',
      img: (
        <SvgIconComponent
          icon={'consulting'}
          fill={subTabType === 'Consulting' ? 'white' : '#262626'}
          h={'23'}
          w={'30'}
        />
      ),
    },
    {
      id: '5',
      key: 'Nonprofit',
      label: 'Nonprofit',
      img: (
        <SvgIconComponent
          icon={'nonprofit'}
          fill={subTabType === 'Nonprofit' ? 'white' : '#262626'}
          h={'23'}
          w={'30'}
        />
      ),
    },
    // {
    //   key: 'dining',
    //   label: 'Dining',
    //   img: (
    //     <SvgIconComponent
    //       icon={'dining'}
    //       fill={
    //         subTabType === 'dining' ? 'white' : '#262626'
    //       }
    //       h={'23'}
    //       w={'30'}
    //     />
    //   ),
    // },
    {
      id: '6',
      key: 'Fleet Rental',
      label: 'Fleet Rental',
      img: (
        <SvgIconComponent
          icon={'rentals'}
          fill={subTabType === 'Fleet Rental' ? 'white' : '#262626'}
          h={'25'}
          w={'30'}
        />
      ),
    },
    {
      id: '7',
      key: 'Event Based',
      label: 'Event Based',
      img: (
        <SvgIconComponent
          icon={'event_management'}
          fill={subTabType === 'Event Based' ? 'white' : '#262626'}
          h={'25'}
          w={'30'}
        />
      ),
    },

    // {
    //   key: 'real_estate',
    //   label: 'Real Estate',
    //   img: (
    //     <SvgIconComponent
    //       icon={'real_estate'}
    //       fill={
    //         subTabType === 'real_estate'
    //           ? 'white'
    //           : '#262626'
    //       }
    //       h={'25'}
    //       w={'30'}
    //     />
    //   ),
    // },
    // {
    //   key: 'co-working space',
    //   label: 'Co-Working Space',
    //   img: (
    //     <SvgIconComponent
    //       icon={'cp_worker'}
    //       fill={
    //         subTabType === 'co-working space'
    //           ? 'white'
    //           : '#262626'
    //       }
    //       h={'25'}
    //       w={'30'}
    //     />
    //   ),
    // },
    // {
    //   key: 'trade shops',
    //   label: 'Trade Shops',
    //   img: (
    //     <SvgIconComponent
    //       icon={'trade_shops'}
    //       fill={
    //         subTabType === 'trade shops'
    //           ? 'white'
    //           : '#262626'
    //       }
    //       h={'23'}
    //       w={'30'}
    //     />
    //   ),
    // },
  ];

  const productSlider = {
    // className: "center",
    // centerMode: true,
    dots: false,
    arrows: false,
    infinite: false,
    // centerPadding : "60px",
    speed: 500,
    slidesToShow: 1.5,
    slidesToScroll: 1,
    // adaptiveHeight: true,
  };

  const productSliderData = [
    {
      name: 'Cupcake Catering',
      price: 'Starting $1,250',
    },

    {
      name: 'Caller Headsets',
      price: 'Starting $2,100',
    },
    {
      name: 'Cupcake Catering',
      price: 'Starting $1,250',
    },

    {
      name: 'Caller Headsets',
      price: 'Starting $2,100',
    },
  ];

  const allProductSlider = {
    // className: "center",
    // centerMode: true,
    dots: false,
    arrows: false,
    infinite: false,
    // centerPadding : "60px",
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    // adaptiveHeight: true,
  };

  const allProductSliderData = [
    {
      name: 'Cupcake Catering',
      price: 'Starting $1,250',
    },

    {
      name: 'Caller Headsets',
      price: 'Starting $2,100',
    },
    {
      name: 'Cupcake Catering',
      price: 'Starting $1,250',
    },

    {
      name: 'Caller Headsets',
      price: 'Starting $2,100',
    },
  ];

  useEffect(() => {
    if (showComponent !== '') {
      toggleHiddenPanel(true);
    } else {
      toggleHiddenPanel(false);
    }
  }, [showComponent]);

  //

  const imageUrl =
    'https://s3.amazonaws.com/build-dev/profile_img/1696311960976_thumb.jpg';
  // const title = 'Property Name';
  // const date = 'Washington, DC (Petworth)'; // Replace with your actual date format

  const cardStyle = {
    display: 'flex',
    width: '376px',
    height: '122px',

    borderRadius: '5.015px',
    border: ' 1.003px solid #F6F5F5',
    background: '#FFF',
    marginTop: '14px',
  };

  const imageStyle = {
    width: '94.276px',
    height: '94.276px',
    borderRadius: '5.015px',
    margin: '12px',
    objectFit: 'cover',
  };

  const contentStyle = {
    flex: 1,
    margin: ' 1px',
    marginTop: '14px',
  };

  const listForDropDown = [
    // {title: 'All', id: '1'},
    {title: 'All', id: '1'},
    {title: 'Trending', id: '2'},
    {title: 'Verified', id: '3'},
    {title: 'Contractors', id: '4'},
  ];

  const [areas_Filter, setAreas_Filter] = useState([listForDropDown[0].id]);

  const dropDownSelection = e => {
    const {id} = e.target;

    setAreas_Filter(id);
    // setdropdownValue([innerText]);
  };

  const getCatalogListOfCompanies = (catalog, categories) => {
    var payload = {
      type: activeIndustryId ? activeIndustryId : '',
      catalog_type: catalog ? catalog : catalogType,
      search_query: searchQuery,
      categories_ids: categories
        ? categories
        : categoriesIds
        ? categoriesIds
        : '',
    };
    
    let type = catalog ? catalog : catalogType;
    commonApi
      .catalog_list_of_companies(payload)
      .then(res => {
        if (res.status === 200) {
          if (res.data) {
            if (type === 'service') {
              setServiceList(res.data);
            } else if (type === 'product') {
              setProductList(res.data);
            }

            setTimeout(() => {
              setShowLoader(false);
            }, 500);
          }
        }
      })
      .catch(err => {
       
        return {type: 'error', message: err.message};
      });
  };

  const getVendorsList = (pageN, categories) => {
    // setShowSpinner(true);

    // setTimeout(() => {
    //   setShowSpinner(false);
    // }, 1000);
    if (hasMoreData.current || isFetching.current) {
      return; // Don't fetch if no more data or already fetching
    } else {
      if (pageN > 1) {
        setIsLoading(true);
      }
    }
    // setIsFetching(true);
    isFetching.current = !isFetching.current;

    var payload = {
      type: activeIndustryId ? activeIndustryId : '',
      catalog_type: 'vendor',
      page: pageN,
      search_query: searchQuery,
      categories_ids: categories
        ? categories
        : categoriesIds
        ? categoriesIds
        : '',
    };
    commonApi
      .vendors_list_of_companies(payload)
      .then(res => {
        if (res.status === 200) {
          if (pageN > 1) {
            if (res.data.length > 0) {
              setPage(pageN);
              setTimeout(() => {
                setVendorsList([...vendorsList, ...res.data]);
              }, 1000);
            } else {
              hasMoreData.current = !hasMoreData.current;
            }
          } else {
            setPage(1);
            setVendorsList(res.data);
          }
          setTimeout(() => {
            setShowLoader(false);
          }, 500);
        }

        setTimeout(() => {
          isFetching.current = !isFetching.current;
          // setIsFetching(false);
          setIsLoading(false);
        }, 1000);
      })
      .catch(err => {
        
        return {type: 'error', message: err.message};
      });
  };

  const handleScroll = values => {
    const {clientHeight, scrollHeight, scrollTop} = values.target;
    // // Check if the user has reached the end of the content
    if (clientHeight + scrollTop >= scrollHeight) {
      // Increment the page number and call the API

      getVendorsList(page + 1);
    }
  };

  const getIndustriesCategories = () => {
    commonApi
      .get_workspace_types()
      .then(res => {
        if (res.status === 200) {
          if (res.workspace_types?.length > 0) {
            setIndustryData(res.workspace_types);
            const categoriess = getCategoryIds(res.workspace_types, 'All');

            
            setCategories(categoriess);
            setList(categoriess);
          }
        }
      })
      .catch(err => {
        
        return {type: 'error', message: err.message};
      });
  };

  const handleShowAll = id => {
    if (showAllIndustryId === id) {
      setShowAllIndustryId('');
    } else {
      setShowAllIndustryId(id);
    }
  };

  useEffect(() => {
    if (tabType === 'services' || tabType === 'products') {
      getCatalogListOfCompanies();
    } else if (tabType === 'vendors') {
      getVendorsList(1);
    }
  }, [activeIndustryId, searchQuery, categoriesIds]);

  // useEffect(() => {
  //   if (tabType === 'services' || tabType === 'products') {
  //     getCatalogListOfCompanies();
  //   } else if (tabType === 'vendors') {
  //     getVendorsList(1);
  //   }
  // }, [tabType]);

  useEffect(() => {
    getIndustriesCategories();
  }, []);

  const getCategoryIds = (array, workSpaceId) => {
    let categoryIds = [];
    if (workSpaceId == 'All') {
      array.forEach(item => {
        item.categories.forEach(category => {
          categoryIds.push({
            id: category.id,
            category_name: category.category_name,
          });
        });
      });
    } else {
      const selectedIndustry = array.filter(ind => ind.name == workSpaceId);

      selectedIndustry[0].categories.forEach(category => {
        categoryIds.push({
          id: category.id,
          category_name: category.category_name,
        });
      });
    }
    return categoryIds;
  };

  const handleStates = id => {
    setShowLoader(true);
    setPage(1);
    setPrimaryComponent('');
    setSearchQuery('');
 
    const categoriess = getCategoryIds(industryData, id);

    const filterData = industryData.filter(ind => ind.name == id);

    const industryId = filterData.length > 0 ? filterData[0]?.id : '';

    setActiveIndustryId(industryId);

    setCategories(categoriess);
    setList(categoriess);
  };

  const handleTabs = type => {
    flushSync(() => {
      setCategoriesIds([]);
      setIsCheck([]);
      setIsCheckAll(false);
      setSearchQuery('');
    });

    // if(type === 'service'){
    //   getCatalogListOfCompanies('service',[]);
    // }else if (type === 'product'){
    //   getCatalogListOfCompanies('product',[]);
    // }else if (type === 'vendor'){
    //   getVendorsList(1,[])
    // }
  };

  const handleCompanyProfile = item => {
    setCompanyId(item);
    setSecondaryComponent('showCompanyProfile');
  };

  const handleCategoryCompanies = item => {
    setCategoryCompanies(item.category_companies);
    setPrimaryComponent('showCompanies');
  };

  

  
  return (
    <>
      <div className="page-wrapper">
        <Sidebar
          location={props.location}
          setShowComponent={setShowComponent}
          setWorkSpaceItem={setWorkspaceData}
          showComponent={showComponent}
          workspaceData={workspaceData}
        />
        {/* {showComponent === 'Event' && <SessionEvents eventButton={eventButton} />} */}

        <div className="page-content-wrapper">
          <div className="sessions-list-wrapper">
            <Pannels
              isFloated={isFloated}
              toggleFloatedPanel={toggleFloatedPanel}
              showHiddenPanel={showHiddenPanel}
              updateHiddenPanelState={nState => toggleHiddenPanel(nState)}
              primary={
                <>
                  <div className="market_home_page primary_panel_content">
                    <div className="event-tabs sliding-tabs mb-4 customer-product-page">
                      <CustomSlidingTabs
                        items={marketTabs}
                        showItems={3}
                        activeTab={subTabType}
                        onItemClick={item => {
                          handleStates(item);
                          handleTabs('');
                          setSubTabsType(item);
                          

                          
                        }}
                        component={'productComponent'}
                      />
                    </div>

                    {/* <div className='refferal-list-wrapper'> */}

                    <div className="messages-header mb-3">
                      <div className="event-tabs tabs-style-2 mb-0">
                        <ul>
                          <li>
                            <Button
                              className={tabType === 'services' ? 'active' : ''}
                              onClick={() => {
                                setCatalogType('service');
                                setTabType('services');
                                setShowLoader(true);
                                setPrimaryComponent('');
                                handleTabs('service');
                                
                                
                               
                                // getCatalogListOfCompanies('service');
                              }}>
                              Services
                            </Button>
                          </li>
                          <li>
                            <Button
                              className={tabType === 'products' ? 'active' : ''}
                              onClick={() => {
                                setCatalogType('product');
                                setTabType('products');
                                
                                setShowLoader(true);
                                setPrimaryComponent('');
                                handleTabs('product');
                               
                                
                                // getCatalogListOfCompanies('product');
                              }}>
                              Products
                            </Button>
                          </li>

                          <li>
                            <Button
                              className={tabType === 'vendors' ? 'active' : ''}
                              onClick={() => {
                                setCatalogType('vendor');
                                setTabType('vendors');
                               
                                setShowLoader(true);
                                setPrimaryComponent('');
                                handleTabs('vendor');
                               
                                
                                // getVendorsList(1)
                              }}>
                              Vendors
                            </Button>
                          </li>
                        </ul>
                      </div>

                      <div className="search-tabs-icons customer-tabs-product-icons">
                        <span
                          onClick={() => {
                            setThreeDots(!threeDots);
                          }}
                          className={
                            threeDots
                              ? 'icon active threeDots'
                              : 'icon threeDots'
                          }>
                          <i className="fas fa-ellipsis-vertical" />
                        </span>
                      </div>
                    </div>
                    {threeDots && (
                      <>
                        <div className="person-search-wrapper mb-3 mt-0 messages-users-wrapper">
                          <div className="search-form d-flex">
                            <Form.Group className="search-input">
                              <Form.Control
                                type="text"
                                placeholder="Search"
                                onChange={e => {
                                  setSearchQuery(e.target.value);
                                }}
                                value={searchQuery}
                              />
                            </Form.Group>
                            <Button
                              onClick={() => {
                                setPrimaryComponent('filterModal');
                              }}>
                              <i className="icon-filter-icon" />
                            </Button>
                          </div>
                        </div>
                        <div className="category-btns-wrapper category-btns-wrapper-slider mt-3 mb-3">
                          <CustomSlidingButtons
                            items={listForDropDown}
                            activeButtons={areas_Filter}
                            onItemClick={e => {
                              dropDownSelection(e);
                            }}
                            showItems={2}
                          />
                        </div>
                      </>
                    )}
                    {(tabType === 'services' || tabType === 'products') && (
                      <>
                        <div className="refferal-list-wrapper">
                          {showLoader && <LoaderSpinner />}
                          <Scrollbars className="custom-refferal-vertical-scrollbar" autoHide>
                            {primaryComponent === 'showCompanies' && (
                              <CompanyList
                                companies={categoryCompanies}
                                handleCompanyProfile={handleCompanyProfile}
                                onClose={() => {
                                  setPrimaryComponent('');
                                }}
                              />
                            )}

                            {tabType === 'services' &&
                              primaryComponent === '' && (
                                <>
                                  {serviceList &&
                                  Object.entries(serviceList).length > 0 ? (
                                    serviceList.map((industry, index) => (
                                      <React.Fragment key={index}>
                                        {industry.industry_category.length >
                                          0 && (
                                          <div className="industry_name mt-3">
                                            {industry.name}
                                          </div>
                                        )}

                                        {industry.industry_category.length >
                                          0 &&
                                          industry.industry_category.map(
                                            (category, categoryIndex) => {
                                              let limit =
                                                industry.id == showAllIndustryId
                                                  ? industry.industry_category
                                                      .length
                                                  : 2;

                                              let img = category
                                                .category_companies[0]
                                                .company_logo
                                                ? category.category_companies[0]
                                                    .company_logo
                                                : category.category_companies[0]
                                                    .image
                                                ? category.category_companies[0]
                                                    .image
                                                : DEFAULT_IMAGE_COMPANYLOGO;
                                              if (categoryIndex < limit) {
                                                return (
                                                  <div
                                                    className="as-link"
                                                    style={cardStyle}
                                                    key={categoryIndex}
                                                    onClick={() => {
                                                      handleCategoryCompanies(
                                                        category,
                                                      );
                                                    }}>
                                                    <img
                                                      src={img}
                                                      alt="Card"
                                                      style={imageStyle}
                                                    />
                                                    <div style={contentStyle}>
                                                      <h3
                                                        style={{
                                                          color: '#000',
                                                          fontFamily: 'Inter',
                                                          fontSize: '15px',
                                                          fontStyle: 'normal',
                                                          fontWeight: '500',
                                                          lineHeight: 'normal',
                                                          paddingBottom: '2px',
                                                        }}>
                                                        {category.category_name}
                                                      </h3>
                                                      <p
                                                        style={{
                                                          color: '#000',
                                                          fontFamily: 'Inter',
                                                          fontSize: '14px',
                                                          fontStyle: 'normal',
                                                          fontWeight: '400',
                                                          lineHeight: 'normal',
                                                          paddingBottom: '2px',
                                                        }}>
                                                        {
                                                          category
                                                            .category_companies
                                                            .length
                                                        }
                                                      </p>
                                                      {/* Additional JSX elements can be added here */}
                                                    </div>
                                                  </div>
                                                );
                                              }
                                            },
                                          )}

                                        {industry.industry_category.length >
                                          0 &&
                                          industry.id !== showAllIndustryId && (
                                            <div
                                              className="mt-3 show_all_btn"
                                              onClick={() => {
                                                handleShowAll(industry.id);
                                              }}>
                                              Show All
                                            </div>
                                          )}

                                        {industry.industry_category.length >
                                          0 &&
                                          industry.id == showAllIndustryId && (
                                            <div
                                              className="mt-3 show_all_btn"
                                              onClick={() => {
                                                handleShowAll(industry.id);
                                              }}>
                                              Show Less
                                            </div>
                                          )}
                                      </React.Fragment>
                                    ))
                                  ) : (
                                    <div className="no-results-wrapper no_record_market">
                                      <div className="no-results-content">
                                        <p>There are no Services Available</p>

                                        <div className="btn-wrapper mt-3">
                                          <Button
                                            className="btn btn-dark"
                                            onClick={() => {
                                              setSecondaryComponent(
                                                'inviteBusiness',
                                              );
                                            }}>
                                            Invite Business
                                          </Button>
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                </>
                              )}

                            {tabType === 'products' &&
                              primaryComponent === '' && (
                                <>
                                  {productList &&
                                  Object.entries(productList).length > 0 ? (
                                    productList.map((industry, index) => (
                                      <React.Fragment key={index}>
                                        {industry.industry_category.length >
                                          0 && (
                                          <div className="industry_name mt-3">
                                            {industry.name}
                                          </div>
                                        )}

                                        {industry.industry_category.length >
                                          0 &&
                                          industry.industry_category.map(
                                            (category, categoryIndex) => {
                                              let limit =
                                                industry.id == showAllIndustryId
                                                  ? industry.industry_category
                                                      .length
                                                  : 2;
                                              let img = category
                                                .category_companies[0]
                                                .company_logo
                                                ? category.category_companies[0]
                                                    .company_logo
                                                : category.category_companies[0]
                                                    .image
                                                ? category.category_companies[0]
                                                    .image
                                                : DEFAULT_IMAGE_COMPANYLOGO;
                                            
                                              if (categoryIndex < limit) {
                                                return (
                                                  <div
                                                    className="as-link"
                                                    style={cardStyle}
                                                    key={categoryIndex}
                                                    onClick={() => {
                                                      handleCategoryCompanies(
                                                        category,
                                                      );
                                                    }}>
                                                    <img
                                                      src={img}
                                                      alt="Card"
                                                      style={imageStyle}
                                                    />
                                                    <div style={contentStyle}>
                                                      <h3
                                                        style={{
                                                          color: '#000',
                                                          fontFamily: 'Inter',
                                                          fontSize: '15px',
                                                          fontStyle: 'normal',
                                                          fontWeight: '500',
                                                          lineHeight: 'normal',
                                                          paddingBottom: '2px',
                                                        }}>
                                                        {category.category_name}
                                                      </h3>
                                                      <p
                                                        style={{
                                                          color: '#000',
                                                          fontFamily: 'Inter',
                                                          fontSize: '14px',
                                                          fontStyle: 'normal',
                                                          fontWeight: '400',
                                                          lineHeight: 'normal',
                                                          paddingBottom: '2px',
                                                        }}>
                                                        {
                                                          category
                                                            .category_companies
                                                            .length
                                                        }
                                                      </p>
                                                      {/* Additional JSX elements can be added here */}
                                                    </div>
                                                  </div>
                                                );
                                              }
                                            },
                                          )}

                                        {industry.industry_category.length >
                                          0 &&
                                          industry.id !== showAllIndustryId && (
                                            <div
                                              className="mt-3 show_all_btn"
                                              onClick={() => {
                                                handleShowAll(industry.id);
                                              }}>
                                              Show All
                                            </div>
                                          )}

                                        {industry.industry_category.length >
                                          0 &&
                                          industry.id == showAllIndustryId && (
                                            <div
                                              className="mt-3 show_all_btn"
                                              onClick={() => {
                                                handleShowAll(industry.id);
                                              }}>
                                              Show Less
                                            </div>
                                          )}
                                      </React.Fragment>
                                    ))
                                  ) : (
                                    <div className="no-results-wrapper no_record_market">
                                      <div className="no-results-content">
                                        <p>There are no Products Available</p>

                                        <div className="btn-wrapper mt-3">
                                          <Button
                                            className="btn btn-dark"
                                            onClick={() => {
                                              setSecondaryComponent(
                                                'inviteBusiness',
                                              );
                                            }}>
                                            Invite Business
                                          </Button>
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                </>
                              )}
                          </Scrollbars>
                        </div>
                      </>
                    )}

                    {tabType === 'vendors' && (
                      <>
                        <div className="refferal-list-wrapper">
                          {showLoader && <LoaderSpinner />}
                          <Scrollbars autoHide onScroll={handleScroll}>
                            {vendorsList &&
                            Object.entries(vendorsList).length > 0 ? (
                              vendorsList.map((item, index) => {
                                let img = item.company_logo
                                  ? item.company_logo
                                  : // : item.image
                                    // ? item.image
                                    // :

                                    DEFAULT_IMAGE_COMPANYLOGO;
                                return (
                                  <div className="customer-product-vendor-list-holder">
                                    <div
                                      className="vendor_item"
                                      key={index}
                                      onClick={() => {
                                        handleCompanyProfile(item);
                                      }}>
                                      <div className="vendor_item_img">
                                        <img src={img} alt="" />
                                      </div>

                                      <div className="vendor_item_detail">
                                        <div className="v_company_name">
                                          {item.company_name}
                                        </div>

                                        <p className="v_company_categories">
                                          {item.categories_name.length === 0
                                            ? 'No Category'
                                            : item.categories_name.toString(',')}
                                        </p>

                                        <div className="v_company_bio">
                                          {item.bio}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                );
                              })
                            ) : (
                              <div className="no-results-wrapper no_record_market">
                                <div className="no-results-content">
                                  <p>There are no Vendors Available</p>

                                  <div className="btn-wrapper mt-3">
                                    <Button className="btn btn-dark"
                                    onClick={()=>{
                                      setSecondaryComponent('inviteBusiness')
                                    }}
                                    >
                                      Invite Business
                                    </Button>
                                  </div>
                                </div>
                              </div>
                            )}
                          </Scrollbars>

                          {/* <div className="vendor_item">
                            <div className="vendor_item_img">
                              <img src={DEFAULT_IMAGE_COMPANYLOGO} alt="" />
                            </div>

                            <div className="vendor_item_detail">
                              <div className="v_company_name">Roi</div>

                              <p className="v_company_categories">
                                food company
                              </p>

                              <span className="v_company_bio">
                                tell you are a customer
                              </span>
                            </div>
                          </div> */}
                        </div>

                        {isLoading && <ScrollLoader />}
                      </>
                    )}
                  </div>

                  {/* </div> */}

                  {primaryComponent === 'filterModal' && (
                    <MarketFilterModal
                      onClose={() => setPrimaryComponent('')}
                      isCheckAll={isCheckAll}
                      setIsCheckAll={setIsCheckAll}
                      isCheck={isCheck}
                      setIsCheck={setIsCheck}
                      list={list}
                      setList={setList}
                      categoryData={categories}
                      setCategoriesIds={setCategoriesIds}
                    />
                  )}
                </>
              }
              secondary={
                <>
                  {seconadaryComponent === 'showCompanyProfile' && (
                    <CompanyProfile
                      company={companyId}
                      onClose={() => {
                        setSecondaryComponent('');
                      }}
                    />
                  )}

                  {seconadaryComponent === 'inviteBusiness' && (
                    <SendInvitation 
                    onClose={()=>{
                      setSecondaryComponent('')
                    }}
                    />
                  )}
                </>
              }
            />
            {showComponent === 'WorkSpace' && (
              <CreateWorkSpace
                onClose={() => {
                  setShowComponent('');
                }}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default CustomerProduct;
