import React, {useState, useEffect, useRef} from 'react';
import {Button, Form, Row, Col, Dropdown} from 'react-bootstrap';
import {
  blockInvalidChar,
  PortFolioNoRecordFound,
  truncateText,
  WithBottomClass,
} from '../../Utils/commonUtils';
import Scrollbars from 'react-custom-scrollbars';
import TransparentButton from '../TransparentButton/TransparentButton';
import CustomDropdown from '../CustomDropdown';
import commonApi from '../../Services/CommonService';
import {errorMessage, successMessage} from '../../Utils/ToastMessages';
import CustomModal from '../CustomModal';
import CropImage from '../Cropper/CropImage';
import SlidingPanel from '../SlidingPanel';
import {RoiLogo} from '../../Constants/Images';
import ImgPreviews from './ImgPreviews';
import LoaderSpinner from '../Modals/LoaderSpinner';
import {getPortFolioData} from '../../Store/Actions/portfolioListingAction';
import {useDispatch} from 'react-redux';
import {useSelector} from 'react-redux';
import EditPencilSvg from '../../Svg/EditPencilSvg';
import TransformationCatgory from './TransformationCatgory';
import {getPortfolioTransformationListing} from '../../Store/Actions/getPortfolioTransformationListingAction';
import NorecordFound from '../NoRecord/NorecordFound';

const CreatePortfolio = props => {
  const [validated, setValidated] = useState(false);
  const [dropdownId, setDropdownId] = useState('case_study');
  const [dropdownIdStory, setDropdownIdStory] = useState('1');
  const [categoryTypeId, setCategoryTypeId] = useState('');
  const [CategoryTypes, setCategoryTypes] = useState([]);
  const [AddNewCatgory, setAddNewCatgory] = useState('');
  const [showSpinner, setShowSpinner] = useState(false);
  const [show, setShow] = useState(true);
  const [showComponent, setShowComponent] = useState('');
  const [file, setFile] = useState('');
  const [afterImages, setAfterImages] = useState('');
  const [beforeImages, setBeforeImages] = useState('');
  const [portFolioImg, setPortFolioImg] = useState([]);
  const [imgType, setImgType] = useState('');
  const allowedImageTypes = ['image/jpeg', 'image/png', 'image/jpg'];
  const buttonRef = useRef(null);
  const dispatch = useDispatch();
  const getPortFolio = useSelector(state => state.portfolioListing);
  const getTransformation = useSelector(
    state => state.portfolioTransformationLiisting,
  );

  const [editData, setEditData] = useState(null);

  const [expense, setExpense] = useState({
    title: '',
    cost: '',
    description: '',
    obstacle: '',
    solution: '',
    impact: '',
  });

  const handle = event => {
    const form = event.currentTarget;

    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      setValidated(true);
    } else {
      event.preventDefault();
      if (dropdownId === 'before_after') {
        CreateTransformation();
      } else {
        createPortfolio();
      }
      setValidated(false);
    }
  };

  useEffect(() => {
    dispatch(
      getPortFolioData({
        search_query: '',
        type: dropdownId,
        user_id:
          localStorage.getItem('id') === props.userId ? '' : props.userId,
      }),
    );
    dispatch(
      getPortfolioTransformationListing({
        category_ids: '',
        user_id: props.userId ? props.userId : localStorage.getItem('id'),
      }),
    );
    // get_company_transformation();
    reset();
    setValidated(false);
    setEditData(null);
  }, [dropdownId]);

  useEffect(() => {
    setEditData(null);
    reset();
  }, [getPortFolio]);

  const reset = () => {
    setEditData(null);
    setExpense({
      title: '',
      cost: '',
      description: '',
      obstacle: '',
      solution: '',
      impact: '',
    });
    setBeforeImages([]);
    setAfterImages([]);
    setPortFolioImg([]);
    setCategoryTypeId(CategoryTypes[0]?.id || '');
  };
  useEffect(() => {
    if (editData && Object.keys(editData).length > 0) {
      setExpense({
        obstacle: editData?.obstacle || editData.story || '',
        solution: editData?.solution || editData.pain || '',
        impact: editData?.impact || editData.success || '',
        description: editData?.description || '',
        title: editData?.title || '',
      });
      setDropdownId(editData?.type || 'before_after');
      setPortFolioImg(editData?.images || []);
      if (editData?.category) {
        setDropdownIdStory(editData?.category);
      }
      if (editData?.category_id) {
        setCategoryTypeId(editData.category_id);
      }
      setAfterImages(editData?.after_images || []);
      setBeforeImages(editData?.before_images || []);
    }
  }, [editData]);

  useEffect(() => {
    // if (dropdownId === 'before_after') {
    get_transformation_category();
    // }
  }, [dropdownId]);

  // const get_company_transformation = () => {
  //   const payload = {
  //     category_ids: '',
  //     user_id: props.userId ? props.userId : localStorage.getItem('id'),
  //   };
  //   commonApi
  //     .get_company_transformation(payload)
  //     .then(res => {
  //       if (res.status === 200) {
  //         setTransformationList(res.transformation);
  //       }
  //     })
  //     .catch(err => {
  //       return {type: 'error', message: err.message};
  //     });
  // };

  const get_transformation_category = () => {
    setShowSpinner(true);
    var payload = {};
    commonApi
      .get_transformation_category(payload)
      .then(res => {
        setCategoryTypes(res.transformation_categories);
        setCategoryTypeId(res.transformation_categories[0].id);

        setTimeout(() => {
          setShowSpinner(false);
        }, 300);
      })
      .catch(err => {
        return {type: 'error', message: err.message};
      });
  };

  const createPortfolio = () => {
    const type = dropdownId; // Example type
    const payload = {
      type,
      title: expense.title,
      portfolio_id: editData?.id ? editData?.id : '',
      description: expense.description,
      images: portFolioImg,
      ...(type === 'case_study'
        ? {
            obstacle: expense.obstacle,
            solution: expense.solution,
            impact: expense.impact,
          }
        : {
            story: expense.obstacle,
            pain: expense.solution,
            success: expense.impact,
            category: dropdownIdStory,
          }),
    };

    commonApi
      .create_update_portfolio(payload)
      .then(res => {
        if (res.status === 200) {
          successMessage(res.message);
          dispatch(getPortFolioData({search_query: '', type: dropdownId}));
          setEditData(null);
          reset();
        }
      })
      .catch(err => {
        return {type: 'error', message: err.message};
      });
  };

  const handleInputChange = e => {
    const {name, value} = e.target;
    setExpense({...expense, [name]: value});
  };

  const dropdownValues = [
    {
      id: 'case_study',
      name: 'Case Study',
    },
    {
      id: 'before_after',
      name: 'Transformation',
    },
    {
      id: 'story',
      name: 'Story ',
    },
  ];
  const dropdownValuesStory = [
    {
      id: '1',
      name: 'Category 1',
    },
    {
      id: '2',
      name: 'Category 1',
    },
    {
      id: '3',
      name: 'Category 2 ',
    },
  ];

  const buttons = [
    {id: 1, label: 'Before', views: 3},
    {id: 2, label: 'After', views: 2},
  ];

  const ShowButtons =
    dropdownId === 'case_study' || dropdownId === 'story'
      ? buttons.slice(0, 1)
      : buttons || [];

  const changeTicketImg = (e, type) => {
    if (e.target.files.length === 0) return false;
    const file = e.target.files[0];

    if (!/^image\//.test(file.type)) {
      errorMessage(`File is not an image.`);
      return false;
    } else if (allowedImageTypes.includes(file.type)) {
      setFile(file);

      setShowComponent('UploadImage');
      HandleShowComponent('UploadImage', file, type);

      e.target.value = null;
    } else {
      errorMessage('The file format is not supported.');
      e.target.value = null; // reset the file input
    }
  };

  const renderHeader = () => {
    return (
      <>
        <img src={RoiLogo} alt="" />
      </>
    );
  };

  const handleDelete = data => {
    dropdownId === 'case_study' || dropdownId === 'story'
      ? setPortFolioImg(data)
      : imgType === 'Before'
      ? setBeforeImages(data)
      : setAfterImages(data);
  };

  const CreateTransformation = () => {
    if (beforeImages.length === 0) {
      errorMessage('Before images are required. ');
      return;
    }
    if (afterImages.length === 0) {
      errorMessage('After images are required. ');
      return;
    }
    const payload = {
      category_id: categoryTypeId,
      title: expense.title,
      description: expense.description,
      before_images: beforeImages.length > 0 ? beforeImages.toString(',') : '',
      after_images: afterImages.length > 0 ? afterImages.toString(',') : '',
      transformation_id: editData?.id ? editData?.id : '',
    };
    commonApi
      .create_company_transformation(payload)
      .then(res => {
        successMessage(res.message);
        // get_company_transformation();
        dispatch(
          getPortfolioTransformationListing({
            category_ids: '',
            user_id: props.userId ? props.userId : localStorage.getItem('id'),
          }),
        );
        setEditData(null);
        reset();
      })
      .catch(err => {
        return {type: 'error', message: err.message};
      });
  };
  // const deleteUpdatedImage = img => {
  //   var payload = {image: img};

  //   commonApi
  //     .delete_offering_image(payload)
  //     .then(res => {
  //       if (res.status === 200) {

  //       }
  //     })
  //     .catch(err => {
  //       console.log('Err', err);
  //       return {type: 'error', message: err.message};
  //     });
  // };

  const HandleShowComponent = (type, filePass, imgType) => {
    if (type === 'UploadImage') {
      props.passComponent2(
        <CustomModal
          size="large"
          onClose={() => {
            setShowComponent('');
            setFile(null);
            props.onClose();
          }}>
          <CropImage
            type="transformImages"
            ratio={25 / 24}
            file={filePass || file}
            tabType={
              dropdownId === 'case_study' || dropdownId === 'story'
                ? 'portfolio'
                : imgType
            }
            setFile={setFile}
            setCropImg={setShowComponent}
            afterImages={afterImages}
            setAfterImages={setAfterImages}
            beforeImages={beforeImages}
            setBeforeImages={setBeforeImages}
            setPortFolioImg={setPortFolioImg}
            portFolioImg={portFolioImg}
            onClose={() => {
              setShowComponent('');
              setFile(null);
              props.onClose();
            }}

            // setshowLoader={setshowLoader}
          />
        </CustomModal>,
      );
      props.handleBlock('', 'pass_component');
    } else if (type === 'showImages') {
      props.passComponent2(
        <ImgPreviews
          images={
            dropdownId === 'case_study' || dropdownId === 'story'
              ? portFolioImg
              : imgType === 'Before'
              ? beforeImages
              : afterImages
          }
          handleDeleteImg={value => {
            dropdownId === 'case_study' || dropdownId === 'story'
              ? setPortFolioImg(value)
              : imgType === 'Before'
              ? setBeforeImages(value)
              : setAfterImages(value);
          }}
          onClose={() => {
            setShowComponent('');
            props.onClose();
          }}
        />,
      );
      props.handleBlock('', 'pass_component');
    } else {
      props.passComponent2(
        <TransformationCatgory
          onClose={() => {
            setShowComponent('');
            props.onClose();
          }}
          get_transformation_category={get_transformation_category}
        />,
      );
      props.handleBlock('', 'pass_component');
    }
  };

  const editTransformation = item => {
    console.log(item, 'itemitemitem');
    setEditData(item);
  };

  // const delete_company_transformation = id => {
  //   const payload = {
  //     id: id,
  //   };
  //   commonApi
  //     .delete_company_transformation(payload)
  //     .then(res => {
  //       successMessage(res.message);
  //       get_company_transformation();
  //       setEditData(null);
  //       reset();
  //     })
  //     .catch(err => {
  //       return {type: 'error', message: err.message};
  //     });
  // };
  const delete_portfolio = id => {
    const payload = {
      id: id,
    };
    commonApi
      .delete_portfolio(payload)
      .then(res => {
        successMessage(res.message);
        dispatch(getPortFolioData({search_query: '', type: dropdownId}));
        setEditData(null);
        reset();
      })
      .catch(err => {
        return {type: 'error', message: err.message};
      });
  };
  return (
    <>
      {/* <SlidingPanel
        hidePanel={() => {
          props.onClose();
        }}
        renderData={renderHeader}
        showBlackHeader={true}
        headerPadding={'24px 20px'}
        lastDivStyle={{borderRadius: '35px', background: '#f7f6f2'}}
        firstDivStyle={{
          padding: 0,
          background:
            'linear-gradient(to bottom, #424242, #424242, #f7f6f2 50%)',
        }}> */}
      <div
        style={{
          backgroundColor: '#ffff',
          borderRadius: '5px',
          paddingBottom: '5px',
          height:
            (getPortFolio?.length > 0 && dropdownId !== 'before_after') ||
            (dropdownId === 'before_after' && getTransformation?.length > 0)
              ? ''
              : '50dvh',
        }}>
        {/* {showSpinner && <LoaderSpinner />} */}
        <div className="w-100 d-flex align-items-center justify-content-between fixed-width-inputs black-head-form-pad-left fix-width-forms mb_12 py-3">
          <div className="d-flex as-link">
            <p
              className="p-bold m-0 f_600_h"
              onClick={() => {
                setShow(!show);
              }}>
              {' '}
              {`${
                props.editData?.id
                  ? 'Update'
                  : localStorage.getItem('id') !== props.userId
                  ? ''
                  : 'Add'
              }`}{' '}
              Portfolio
            </p>
          </div>
        </div>
        {/* <Scrollbars> */}
        {localStorage.getItem('id') !== props.userId && (
          <div className="mb-3 p-2">
            <CustomDropdown
              // type={'credential'}
              setDropdownId={setDropdownId}
              dropdownId={dropdownId}
              DropDownValues={dropdownValues}
              itemName={['name']}
              className={
                'recruitment_dropdown recruitement_dropdown_setInvites'
              }
              // handleDropdownValue={handleInvitesAssociation}
              disabled={editData?.id ? true : false}
            />
          </div>
        )}
        {show && localStorage.getItem('id') === props.userId && (
          <>
            <Form noValidate validated={validated} onSubmit={handle}>
              <div className=" fixed-width-inputs black-head-form-pad-left fix-width-forms">
                <div className="mb-3">
                  <CustomDropdown
                    // type={'credential'}
                    setDropdownId={setDropdownId}
                    dropdownId={dropdownId}
                    DropDownValues={dropdownValues}
                    itemName={['name']}
                    className={
                      'recruitment_dropdown recruitement_dropdown_setInvites'
                    }
                    // handleDropdownValue={handleInvitesAssociation}
                    disabled={editData?.id ? true : false}
                  />
                </div>
                {dropdownId === 'story' ? (
                  <div className="mb-3">
                    <CustomDropdown
                      // type={'credential'}
                      setDropdownId={setDropdownIdStory}
                      dropdownId={dropdownIdStory}
                      DropDownValues={dropdownValuesStory}
                      itemName={['name']}
                      className={
                        'recruitment_dropdown recruitement_dropdown_setInvites'
                      }
                      // handleDropdownValue={handleInvitesAssociation}
                      disabled={props.id ? true : false}
                    />
                  </div>
                ) : (
                  <div className="fieldset">
                    <Form.Control
                      name="title"
                      value={expense.title}
                      pattern="^\S.*$"
                      required
                      placeholder="Title"
                      onChange={handleInputChange}
                    />
                    <Form.Control.Feedback type="invalid">
                      Title is required.
                    </Form.Control.Feedback>
                  </div>
                )}

                {dropdownId === 'before_after' && (
                  <div className="mb-3 roi-form-dropdown recruitment_dropdown recruitement_dropdown_setInvites">
                    <Form.Label
                      style={{justifyContent: 'flex-end'}}
                      controlid="validationCustom01"
                      className="d-flex align-items-center">
                      <span
                        className="icon as-link"
                        onClick={() => {
                          setShowComponent('Add_Catgory');
                          HandleShowComponent('Add_Catgory');
                        }}>
                        <i className="icon-plus"></i>
                      </span>
                    </Form.Label>
                    <div className="select-wrapper">
                      <Dropdown
                        onSelect={(eventKey, event) => {
                          setCategoryTypeId(eventKey);
                        }}
                        drop="down">
                        <Dropdown.Toggle variant="primary">
                          {categoryTypeId &&
                            CategoryTypes.length > 0 &&
                            CategoryTypes.find(
                              item => item.id === categoryTypeId,
                            )?.category_title}
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          {CategoryTypes.length > 0 &&
                            CategoryTypes.map((item, index) => (
                              <Dropdown.Item
                                key={`st-${index}`}
                                eventKey={item.id}
                                active={item.id === categoryTypeId}>
                                {item.category_title}
                              </Dropdown.Item>
                            ))}
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </div>
                )}

                {dropdownId !== 'case_study' && dropdownId !== 'story' && (
                  <div className="fieldset ">
                    <Form.Control
                      name="description"
                      value={expense.description}
                      as="textarea"
                      rows={6}
                      type="text"
                      placeholder="Description"
                      onChange={handleInputChange}
                    />
                    <Form.Control.Feedback type="invalid">
                      Description is required.
                    </Form.Control.Feedback>
                  </div>
                )}
                {(dropdownId === 'case_study' || dropdownId === 'story') && (
                  <>
                    <div className="fieldset">
                      <Form.Control
                        name="obstacle"
                        value={expense.obstacle}
                        as="textarea"
                        rows={6}
                        type="text"
                        placeholder={
                          dropdownId === 'story' ? 'Story' : 'Obstacle'
                        }
                        onChange={handleInputChange}
                        required={dropdownId === 'story' ? true : false}
                      />
                      <Form.Control.Feedback type="invalid">
                        {dropdownId === 'story' ? 'Story' : 'Obstacle'} is
                        required.
                      </Form.Control.Feedback>
                    </div>
                    <div className="fieldset">
                      <Form.Control
                        name="solution"
                        value={expense.solution}
                        as="textarea"
                        rows={6}
                        type="text"
                        // placeholder="Solution"
                        placeholder={
                          dropdownId === 'story'
                            ? 'Lessons Learned'
                            : 'Solution'
                        }
                        onChange={handleInputChange}
                      />
                      <Form.Control.Feedback type="invalid">
                        {dropdownId === 'story'
                          ? 'Lessons Learned'
                          : 'Solution'}{' '}
                        is required.
                      </Form.Control.Feedback>
                    </div>
                    <div className="fieldset">
                      <Form.Control
                        name="impact"
                        value={expense.impact}
                        as="textarea"
                        rows={6}
                        type="text"
                        placeholder={
                          dropdownId === 'story' ? 'Gold in journey' : 'Impact'
                        }
                        onChange={handleInputChange}
                      />
                      <Form.Control.Feedback type="invalid">
                        {dropdownId === 'story' ? 'Gold in journey' : 'Impact'}{' '}
                        is required.
                      </Form.Control.Feedback>
                    </div>
                  </>
                )}
                {(dropdownId === 'case_study' || dropdownId === 'story') && (
                  <div className="flex gap-4 justify-center items-center  pb-[20px]">
                    <div className="flex flex-col items-center">
                      <label
                        htmlFor="fileUpload"
                        className="flex flex-col items-center justify-center w-24 h-24 bg-[#9B9787] rounded-lg shadow-md text-white cursor-pointer relative">
                        <i className="icon-camera text-3xl" />

                        <input
                          id="fileUpload"
                          type="file"
                          accept="image/*"
                          className="absolute inset-0 opacity-0 cursor-pointer"
                          onChange={e => changeTicketImg(e)}
                        />
                      </label>
                      {portFolioImg.length > 0 && (
                        <span
                          className="text-gray-700 mt-2 text-sm underline cursor-pointer"
                          onClick={() => {
                            setShowComponent('showImages');
                            HandleShowComponent('showImages');
                          }}>
                          view ({portFolioImg.length})
                        </span>
                      )}
                    </div>
                  </div>
                )}
                {dropdownId === 'before_after' && (
                  <div className="flex gap-4 justify-center items-center  pb-[12px]">
                    <div className="flex flex-col items-center">
                      <label
                        htmlFor="fileUpload"
                        className="flex flex-col items-center justify-center w-24 h-24 bg-[#9B9787] rounded-lg shadow-md text-white cursor-pointer relative">
                        <i className="icon-camera text-3xl" />
                        <span>Before</span>
                        <input
                          id="fileUpload"
                          type="file"
                          accept="image/*"
                          className="absolute inset-0 opacity-0 cursor-pointer"
                          onChange={e => {
                            setImgType('Before');
                            changeTicketImg(e, 'Before');
                          }}
                        />
                      </label>
                      {beforeImages.length > 0 && (
                        <span
                          className="text-gray-700 mt-2 text-sm underline cursor-pointer"
                          onClick={() => {
                            setImgType('Before');
                            setShowComponent('showImages');
                            HandleShowComponent('showImages', '', 'Before');
                          }}>
                          view ({beforeImages.length})
                        </span>
                      )}
                    </div>
                    <div className="flex flex-col items-center">
                      <label
                        htmlFor="fileUpload"
                        className="flex flex-col items-center justify-center w-24 h-24 bg-[#9B9787] rounded-lg shadow-md text-white cursor-pointer relative">
                        <i className="icon-camera text-3xl" />
                        <span>After</span>
                        <input
                          id="fileUpload"
                          type="file"
                          accept="image/*"
                          className="absolute inset-0 opacity-0 cursor-pointer"
                          onChange={e => {
                            setImgType('After');
                            changeTicketImg(e, 'After');
                          }}
                        />
                      </label>
                      {afterImages.length > 0 && (
                        <span
                          className="text-gray-700 mt-2 text-sm underline cursor-pointer"
                          onClick={() => {
                            setImgType('After');
                            setShowComponent('showImages');
                            HandleShowComponent('showImages', '', 'After');
                          }}>
                          view ({afterImages.length})
                        </span>
                      )}
                    </div>
                  </div>
                )}

                <div className="flex content-center justify-center mb-3">
                  <Button className="btn-dark" type="submit">
                    {editData && editData.id ? 'Update' : 'Add'} Portfolio
                  </Button>
                </div>
                {editData && editData.id && (
                  <div className="flex content-center justify-center text-[#52bd41] as-link">
                    <span
                      onClick={() => {
                        reset();
                      }}>
                      {'Cancel'}
                    </span>
                  </div>
                )}
              </div>
            </Form>
          </>
        )}
        <div className="before_after_dropdown_block">
          {(dropdownId === 'case_study' ||
          dropdownId === 'story') && getPortFolio.length > 0
            ? getPortFolio.map((item, index) => (
                <div
                  key={index}
                  className="portfolio_before_after_holder  bg-white border">
                  <div className="flex items-center justify-between portfolio-items-holder">
                    <div className="flex items-center space-x-4">
                      <img
                        src={
                          item.images.length > 0
                            ? item.images[0]
                            : 'https://s3.amazonaws.com/build-dev/profile_img/1725615761650_thumb.jpg'
                        }
                        alt="Thumbnail"
                        className="w-12 h-12 object-cover portfolio-small-round-img"
                      />
                      <div>
                        <h2 className="portfolio-txt-small-head">
                        {item?.title &&truncateText(item.title,localStorage.getItem('id') === props.userId ? 20 : 40)}
                        </h2>
                      </div>
                    </div>
                    {localStorage.getItem('id') === props.userId && 
                    <div className="flex space-x-2">
                    
                      <button
                        onClick={() => {
                          props.handleBlock(
                            '',
                            'deleteConfirmation',
                            item,
                            dropdownId,
                          );
                          // delete_portfolio(item.id)
                        }}
                        className="">
                        <i
                          style={{color: 'rgb(82, 189, 65)'}}
                          className="fa-solid fa-trash-can"
                        />
                      </button>
                      <button onClick={() => setEditData(item)} className="">
                        <EditPencilSvg fill={'rgb(82, 189, 65)'} />
                      </button>
                    </div>}
                  </div>
                  <p
                    className=""
                    style={{
                      fontFamily: 'inter',
                      fontSize: '13px',
                      color: '#7e7e7e',
                    }}>
                    {truncateText(item.impact || item.success,100)}
                  </p>
                </div>
              ))
            : localStorage.getItem('id') !== props.userId &&
              (dropdownId === 'case_study' || dropdownId === 'story') && (
                <NorecordFound
                  noRecordData={PortFolioNoRecordFound}
                  buttonPermesion={false}
                />
              )}

          {dropdownId === 'before_after' && getTransformation.length > 0
            ? getTransformation?.map((item, index) => (
                <div className="bg-white portfolio_before_after_holder">
                  <div className="flex justify-between before_after_transform_sec">
                    <h2 className="before_after_transformation_head  overflow-hidden text-ellipsis whitespace-nowrap break-words max-w-full">
                      {truncateText(item.title,localStorage.getItem('id') === props.userId ? 20 : 40)}
                    </h2>
                    <div className="flex space-x-2">
                      {localStorage.getItem('id') === props.userId && 
                      <>
                     
                      <button
                        onClick={() => {
                          props.handleBlock('', 'deleteConfirmation', item);
                          // delete_company_transformation(item.id)
                        }}
                        className="">
                        <i
                          style={{color: 'rgb(82, 189, 65)'}}
                          className="fa-solid fa-trash-can"
                        />
                      </button>
                      <button
                        onClick={() => editTransformation(item)}
                        className="">
                        <EditPencilSvg fill={'rgb(82, 189, 65)'} />
                      </button></>}
                    </div>
                  </div>
                  <div className="flex transform_sec_img_holder">
                    <img
                      src={item.before_images[0]}
                      alt="Before"
                      className="w-1/2 rounded-md object-cover"
                    />
                    <img
                      src={item.after_images[0]}
                      alt="After"
                      className="w-1/2 rounded-md object-cover"
                    />
                  </div>
                </div>
              ))
            : localStorage.getItem('id') !== props.userId &&
              dropdownId === 'before_after' && (
                <NorecordFound
                  noRecordData={PortFolioNoRecordFound}
                  buttonPermesion={false}
                />
              )}
        </div>{' '}
      </div>

      {/* {showComponent === 'UploadImage' && file && (
        <CustomModal
          size="large"
          onClose={() => {
            setShowComponent('');
            setFile(null);
          }}>
          <CropImage
            type="transformImages"
            ratio={100 / 50}
            file={file}
            tabType={
              dropdownId === 'case_study' || dropdownId === 'story'
                ? 'portfolio'
                : imgType
            }
            setFile={setFile}
            setCropImg={setShowComponent}
            afterImages={afterImages}
            setAfterImages={setAfterImages}
            beforeImages={beforeImages}
            setBeforeImages={setBeforeImages}
            setPortFolioImg={setPortFolioImg}
            portFolioImg={portFolioImg}
            // setshowLoader={setshowLoader}
          />
        </CustomModal>
      )}

      {showComponent === 'showImages' && (
        <ImgPreviews
          images={
            dropdownId === 'case_study' || dropdownId === 'story'
              ? portFolioImg
              : imgType === 'Before'
              ? beforeImages
              : afterImages
          }
          handleDeleteImg={handleDelete}
          onClose={() => setShowComponent('')}
        />
      )}

      {showComponent === 'Add_Catgory' && (
        <CustomModal
          size="small"
          onClose={() => {
            setShowComponent('');
          }}
          className="availability-modal">
          <div
            className="new-session-modal-wrapper text-center  "
            style={{width: '-webkit-fill-available'}}>
            <div className="new-session-modal">
              <div className="fieldset">
                <div className="d-flex align-items-center justify-content-between">
                  <Form.Label className="w-auto" controlid="validationCustom01">
                    Add Category
                  </Form.Label>
                </div>

                <Form.Control
                  value={AddNewCatgory}
                  type="text"
                  placeholder="Add Category... "
                  onChange={e => {
                    setAddNewCatgory(e.target.value);
                  }}
                />
              </div>

              <div className="invite-btns  p-0 pt-3 text-center">
                <Button
                  onClick={() => {
                    if (AddNewCatgory === '') {
                      errorMessage('Please enter catgory');
                    } else {
                      create_transformation_category();
                    }
                  }}>
                  Add Category
                </Button>
              </div>
            </div>
          </div>
        </CustomModal>
      )} */}
    </>
  );
};

export default CreatePortfolio;
