import React, {useState, useEffect, useRef} from 'react';
import {Button, Form} from 'react-bootstrap';
// import commonApi from '../../Services/CommonService';
// import {errorMessage, successMessage} from '../../Utils/ToastMessages';
import SlidingPanel from '../SlidingPanel';
import Scrollbars from 'react-custom-scrollbars';
import LoaderSpinner from '../Modals/LoaderSpinner';
import CropImage from '../Cropper/CropImage';
import CustomModal from '../CustomModal';
import ProfileMap from './ProfileMap';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import {geocodeByPlaceId} from 'react-google-places-autocomplete';
import {getLatLng} from 'react-google-places-autocomplete';
import {
  getCityStateFromAddress,
} from '../../Utils/commonUtils';
import $ from 'jquery';
import { RoiLogo } from '../../Constants/Images';
const EditProfile = ({
  profile,
  onClose,
  setProfileImgScrollBar,
  profileBio,
  setProfileBio,
  updateBusinessProfile,
  setInstagramValue,
  setLinkdinValue,
  setTwitterValue,
  setYoutubeValue,
  setWebsite,
  setLocationValue,
  multipleProfileData,
  locationValue,
  instagramValue,
  linkdinValue,
  twitterValue,
  youtubeValue,
  website,
}) => {
  // const allUserData = localStorage.getItem('allUserData')
  //   ? JSON.parse(localStorage.getItem('allUserData'))
  //   : {};

  const [instagram, setInstagram] = useState(true);
  const [linkdin, setLinkdin] = useState(false);
  const [twitter, setTwitter] = useState(false);
  const [youtube, setYoutube] = useState(false);
  const [global, setGlobal] = useState(false);
  const [file, setFile] = useState(null);
  const [sliderImage, setSliderImage] = useState([]);

  // const [field, setField] = useState(true);
  const [bio, setBio] = useState(profileBio);
  const buttonRef = useRef(null);
  const [instagramName, setInstagramName] = useState(
    instagramValue ? instagramValue : multipleProfileData.instagram,
  );
  const [linkdinName, setLinkdinName] = useState(
    linkdinValue ? linkdinValue : multipleProfileData.linkedin,
  );
  const [twitterName, setTwitterName] = useState(
    twitterValue ? twitterValue : multipleProfileData.twitter,
  );
  const [youtubeName, setYoutubeName] = useState(
    youtubeValue ? youtubeValue : multipleProfileData.youtube,
  );
  const [globalName, setGlobalName] = useState(
    website ? website : multipleProfileData.website,
  );

  const [location, setLocation] = useState('');
  const [latitude, setLatitude] = useState('');
  const [longitude, setLongitude] = useState('');

  const [locationName, setLocationName] = useState(locationValue);

  // const [ticketImg, setTicketImg] = useState(
  //   localStorage.getItem('profilePic'),
  // );
  // const [img, setImg] = useState(profile.data && profile.data.profile_img);
  const [img, setImg] = useState(
    localStorage.getItem('profilePic')
      ? localStorage.getItem('profilePic')
      : '',
  );
  const [showLoader, setshowLoader] = useState(false);
  // const [showError, setShowError] = useState(false);
  const [validated, setValidated] = useState(false);
  const [showModel, setShowModel] = useState(false);
  const [showComponent, setShowComponent] = useState('');
  // const allowedImageTypes = ['image/jpeg', 'image/png', 'image/jpg'];
  // 

  // const changeTicketImg = e => {
  //   // If no file selected, return
  //   if (e.target.files.length === 0) return false;
  //   const file = e.target.files[0];

  //   // If no image selected, return
  //   if (!/^image\//.test(file.type)) {
  //     errorMessage(`File is not an image.`);
  //     return false;
  //   } else if (allowedImageTypes.includes(file.type)) {
  //     setFile(file);

  //     setShowModel('modal');

  //     e.target.value = null;
  //     // const filePath = e.target.files[0];
  //     // const objectUrl = URL.createObjectURL(filePath);
  //     // let reader = new FileReader();
  //     // reader.readAsDataURL(filePath);
  //     // reader.onload = e => {
  //     //   // 

  //     //   uploadProfilePicture(e.target.result);
  //     // };

  //     // setTicketImg(objectUrl);
  //   } else {
  //     errorMessage('The file format is not supported');
  //     e.target.value = null; // reset the file input
  //   }
  // };
  useEffect(() => {
    localStorage.setItem('profilePic', img);

    return localStorage.setItem('profilePic', img);
  }, [img]);

  const handle = e => {
    // alert("ASDasd")
    const form = e.currentTarget;

    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();

      setValidated(true);
      // alert('1')
    } else {
      e.preventDefault();

      // if (bio === '') {
      //   setShowError(true);
      // } else {
      //   updateProfile();
      // }
      // alert('2')
      // if (bio === '') {
      //   setShowError(true);
      //   // alert('3')
      // } else {
        // alert('5')
        if (profileBio || bio) {
          // alert('4')
          setProfileBio(bio);
          setInstagramValue(instagramName);
          setLinkdinValue(linkdinName);
          setTwitterValue(twitterName);
          setYoutubeValue(youtubeName);
          setWebsite(globalName);
          setLocationValue(location.label ? location.label : locationValue);
          // multipleProfileData.location = location.label;
          updateBusinessProfile(
            bio,
            instagramName,
            linkdinName,
            twitterName,
            youtubeName,
            globalName,
            location.label ? location.label : locationValue,
            longitude,
            latitude,
          );
          onClose();
        }
      // }
      setValidated(false);
    }
  };

  const clearAll = () => {
    setLocation('');
    setLatitude('');
    setLongitude('');
    setLocationName('');
    // 
    $('.css-1uccc91-singleValue').html('Type city and state');
  };
  



  // const updateProfile = () => {
  //   var payload = {
  //     bio: bio,
  //     firstName: localStorage.getItem('firstName'),
  //     lastName: localStorage.getItem('lastName'),
  //     instagram: instagramName,
  //     linkedin: linkdinName,
  //     twitter: twitterName,
  //     youtube: youtubeName,
  //     web: globalName,
  //   };
  //   commonApi
  //     .update_profile(payload)
  //     .then(res => {
  //       if (res.status === 200) {
  //         res.user.bio = bio;
  //         res.user.instagram = instagramName;
  //         res.user.linkedin = linkdinName;
  //         res.user.twitter = twitterName;
  //         res.user.youtube = youtubeName;
  //         res.user.web = globalName;
  //         localStorage.setItem('bio', bio);
  //         localStorage.setItem('instagram', instagramName);
  //         localStorage.setItem('linkedin', linkdinName);
  //         localStorage.setItem('twitter', twitterName);
  //         localStorage.setItem('youtube', youtubeName);
  //         localStorage.setItem('web', globalName);
  //         onClose();
  //       }
  //     })
  //     .catch(err => {
  //       console.log('Err', err);
  //       return {type: 'error', message: err.message};
  //     });
  // };

  // const uploadProfilePicture = img => {
  //   setshowLoader(true);
  //   var bodyFormData = new FormData();
  //   bodyFormData.append(
  //     'picture',
  //     img.replace(/^data:image\/[a-z]+;base64,/, ''),
  //   );

  //   commonApi
  //     .upload_profile_picture(bodyFormData)
  //     .then(res => {
  //       if (res.status === 200) {
  //         setImg(res.user.profile_img);
  //         localStorage.setItem('profilePic', res.user.profile_img);
  //       }
  //       setshowLoader(false);
  //       setProfileImgScrollBar(res.user.profile_img);
  //     })
  //     .catch(err => {
  //       console.log('Err', err);
  //       return {type: 'error', message: err.message};
  //     });
  // };

  useEffect(() => {
    let place_id =
      typeof location === 'object'
        ? location.value.place_id
        : typeof location === 'string'
        ? ''
        : '';

    if (place_id) {
      geocodeByPlaceId(place_id)
        .then(results => {
          const geocodingResult = results[0];

          // Assuming getLatLng and getCityStateFromAddress are asynchronous functions
          return Promise.all([
            getLatLng(geocodingResult),
            getCityStateFromAddress(geocodingResult),
          ]);
        })
        .then(([latLngResult, cityStateResult]) => {
          const {lat, lng} = latLngResult;
          // const data = cityStateResult;

          
          

          setLongitude(lng);
          setLatitude(lat);
        })
        .catch(error => {
          console.error('An error occurred:', error);
        });
    }
  }, [location]);

  // 

  const renderHeader = () => {
  
    return (
      <>
        
          <img src={RoiLogo} alt="" />
        
      </>
    );
  };
  
  
  
  
  
  let address =
    typeof location === 'object'
      ? location.label
      : typeof locationName === 'string' && locationName !== ''
      ? locationName
      : 'Type city and state';
  return (
    <SlidingPanel hidePanel={() => onClose()} 
    renderData={renderHeader}
    showBlackHeader={true}
    headerPadding={'24px 20px'}
    lastDivStyle={{borderRadius: '35px', background: '#f7f6f2'}}
    firstDivStyle={{
      padding: 0,
      background:
        'linear-gradient(to bottom, #424242, #424242, #f7f6f2 50%)',
    }}
    >
      {showLoader && <LoaderSpinner />}

      <div className="serach-filter-wrapper editProfile">
        <div className="fixed-width-inputs black-head-form-pad-left pt-2">
          <div className="sidebar-heading-wrapper mt-0">
            <div className="heading">
              <h2>Edit Profile</h2>
            </div>
          </div>
        </div>

        <Scrollbars className='custom-scroll' autoHide>
          <div className='fixed-width-inputs black-head-form-pad-left'>

          
          <div className="card profile-block-wrapper " style={{marginBottom:'80px'}}>
            {/* <div className="profile-img-wrapper mt-3">
              <div className="profile-img upload-user-img removeCursor">
                <div style={{position: 'relative'}}>
                  {img && <img src={img} alt="" />}
                  <span className="icon icon-camera"></span>
                  <input
                    type="file"
                    name="my-image"
                    id="image"
                    accept="image/*"
                    onChange={e => {
                      changeTicketImg(e);
                    }}
                  />
                </div>
                <div style={{position: 'relative'}}>
                  <Form.Label>Update Profile Picture</Form.Label>
                  <input
                    type="file"
                    name="my-image"
                    id="image"
                    accept="image/*"
                    onChange={e => {
                      changeTicketImg(e);
                    }}
                  />
                </div>
              </div>
            </div> */}
            <Form noValidate validated={validated} onSubmit={handle}>
              {/* <div className="fieldset mb-0 mt-2">
                <label class="form-label">Add Workspace</label>
                <div class="select-wrapper ">
                  <select class="form-control">
                    <option value="personal" selected>personal</option>
                    <option value="Workspace 1">Workspace 1</option>
                    <option value="Workspace 2">Workspace 2</option>
                    <option value="Workspace 3">Workspace 3</option>
                    <option value="Workspace 4">Workspace 4</option>
                  </select>
                </div>
              </div> */}

              <div className="send-refferal-form-wrapper mt-2">
                <Form.Group className="fieldset">
                  <Form.Label>Bio</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={8}
                    required
                    maxLength={320}
                    className="textarea-scroll"
                    placeholder="Tell other contacts a little about yourself "
                    value={bio}
                    onChange={e => {
                      setBio(e.target.value);
                    }}
                  />
                  <span className="info-text">
                    {bio && bio.length > 0 && 320 - bio.length} Maximum
                    characters limit
                  </span>
                  <Form.Control.Feedback type="invalid">
                    Bio is required
                  </Form.Control.Feedback>
                </Form.Group>
              </div>

              {/* <div
                  className={
                    // profile.data.id == localStorage.getItem('id') ?
                     'profile-address as-link'
                      // : 'profile-address'
                  }
                  onClick={() => {
                    {
                        setShowComponent('homeMap');
                    }
                  }}>
                  <i className="fas fa-home" />{' '}
                  {location
                    ? location
                    : 'Add Location'}
                </div> */}

              <div className="fieldset">
                <div className="d-flex align-items-center justify-content-between mt-2">
                  <Form.Label>Location</Form.Label>
                </div>

                {/* <div className="form-control-with-icon"></div> */}
                <div className="location-field form-control form-control-with-icon create_session business_profile_location">
                  <GooglePlacesAutocomplete
                    location
                    selectProps={{
                      location,
                      onChange: setLocation,
                      name: 'city',
                      placeholder: address,
                    }}
                  />

                  {(location !== '' || locationName !== '') && (
                    <span
                      className="location_clear_icon"
                      onClick={() => {
                        clearAll();
                      }}>
                      <i
                        className="fas fa-close"
                        // style={{paddingLeft: '210px'}}
                      />
                    </span>
                  )}
                </div>
              </div>

              <div className="profile-content-infos mb-4">
                <h2>Social Media Platforms</h2>

                <p>
                  {' '}
                  Tap on the icons below to add your different handles or URLs.
                  <br /> <br /> You can add multiple by tapping the icons below.
                  {/* You can add multiple by tapping the icons below. */}
                </p>
              </div>

              <div className="profile-content-infos mb-2">
                <div className="social-icons mb-3">
                  <ul>
                    <li>
                      <i
                        className={`fab fa-instagram as-link ${
                          instagram ? 'icon_color' : ''
                        }`}
                        onClick={() => {
                          setInstagram(true);
                          setLinkdin(false);
                          setGlobal(false);
                          setYoutube(false);
                          setTwitter(false);
                        }}
                      />
                    </li>

                    <li>
                      <i
                        className={`fa-brands fa-linkedin as-link ${
                          linkdin ? 'icon_color' : ''
                        }`}
                        onClick={() => {
                          setLinkdin(true);
                          setGlobal(false);
                          setYoutube(false);
                          setTwitter(false);
                          setInstagram(false);
                        }}></i>
                    </li>

                    <li>
                      <i
                        className={`fab fa-twitter as-link ${
                          twitter ? 'icon_color' : ''
                        }`}
                        onClick={() => {
                          setTwitter(true);
                          setLinkdin(false);
                          setGlobal(false);
                          setYoutube(false);
                          setInstagram(false);
                        }}
                      />
                    </li>

                    <li>
                      <i
                        className={`fab fa-youtube as-link ${
                          youtube ? 'icon_color' : ''
                        }`}
                        onClick={() => {
                          setYoutube(true);
                          setLinkdin(false);
                          setGlobal(false);
                          setTwitter(false);
                          setInstagram(false);
                        }}
                      />
                    </li>

                    <li>
                      <i
                        className={`icon-link as-link ${
                          global ? 'icon_color' : ''
                        }`}
                        onClick={() => {
                          setGlobal(true);
                          setLinkdin(false);
                          setYoutube(false);
                          setTwitter(false);
                          setInstagram(false);
                        }}
                      />
                    </li>
                  </ul>
                </div>

                {instagram && (
                  <Form.Control
                    placeholder="Add instagram handler"
                    value={instagramName}
                    onChange={e => {
                      setInstagramName(e.target.value);
                    }}
                  />
                )}
                {linkdin && (
                  <Form.Control
                    placeholder="Add linkdin handler"
                    value={linkdinName}
                    onChange={e => {
                      setLinkdinName(e.target.value);
                    }}
                  />
                )}
                {twitter && (
                  <Form.Control
                    placeholder="Add twitter handler"
                    value={twitterName}
                    onChange={e => {
                      setTwitterName(e.target.value);
                    }}
                  />
                )}
                {youtube && (
                  <Form.Control
                    placeholder="Add youtube handler or link"
                    value={youtubeName}
                    onChange={e => {
                      setYoutubeName(e.target.value);
                    }}
                  />
                )}
                {global && (
                  <Form.Control
                    placeholder="Add website handler or link"
                    value={globalName}
                    onChange={e => {
                      setGlobalName(e.target.value);
                    }}
                  />
                )}
              </div>
              <Button
                type="submit"
                style={{display: 'none'}}
                ref={buttonRef}></Button>
            </Form>
          </div>
          </div>
        </Scrollbars>

        <div className="filter-btn-wrapper text-center bg_transparent pt-2 pb-2">
          <Button
            className="btn-dark m-0"
            onClick={() => buttonRef.current.click()}>
            Update Profile
          </Button>
        </div>
      </div>

      {showModel === 'modal' && file && (
        <CustomModal
          size="large"
          onClose={() => {
            setShowModel('');
            setFile(null);
          }}>
          <CropImage
            type="profile"
            ratio={25 / 24}
            file={file}
            setFile={setFile}
            setCropImg={setShowModel}
            setImgUrl={setImg}
            sliderImage={sliderImage}
            setSliderImage={setSliderImage}
            setshowLoader={setshowLoader}
            setProfileImgScrollBar={setProfileImgScrollBar}
          />
        </CustomModal>
      )}

      {showComponent === 'homeMap' && (
        <ProfileMap
          setLocation={setLocation}
          setLongitude={setLongitude}
          setLatitude={setLatitude}
          // home={true}
          onClose={() => {
            setShowComponent('');
          }}
        />
      )}
    </SlidingPanel>
  );
};

export default EditProfile;
