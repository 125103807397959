import React, { Component } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { validateEmail } from "../../Utils/Helpers";

class AppointmentProfileField extends Component {
    state = {
        data: {
            name: "",
            email: "",
            notes: "",
        },
        errors: {},
        loading: false,
    };

    UNSAFE_componentWillMount = () => {
        const tempData = this.props.data;
        this.setState({
            data: tempData,
        });
    };

    UNSAFE_componentWillReceiveProps = (props) => {
        this.setState({
            data: props.data,
        });
    };

    onChange = (e) => {
        const value = e.target.value;

        this.setState({
            data: {
                ...this.state.data,
                [e.target.name]: value,
            },
        });
    };

    validateForm = () => {
        const { data } = this.state;
        const errors = {};

        if (!data.name || data.name === "") {
            errors.name = "Please enter your Name.";
        }
        if (!data.email || data.email === "") {
            errors.email = "Please enter your Email.";
        } else if (!validateEmail(data.email)) {
            errors.email = "Please enter valid Email.";
        }
        if (!data.notes || data.notes === "") {
            errors.notes = "Please enter Notes.";
        }

        return errors;
    };
    submitForm = () => {
        const { loading, data } = this.state;
        const errors = this.validateForm();

        if (!loading && Object.entries(errors).length === 0) {
            this.setState({
                loading: true,
                errors,
            });

            setTimeout(() => {
                this.setState({
                    loading: false,
                    errors,
                });
            }, 5000);

            
        } else {
            this.setState({
                errors,
            });
        }
    };

    render() {
        const { data, errors, loading } = this.state;

        return (
            <div className="appointment-fields-wrapper">
                <div className="headings">
                    <h2>Enter Details</h2>
                </div>
                <Form>
                    <Row>
                        <Col md={6}>
                            <Form.Group className="form-group" controlId="name">
                                <Form.Control
                                    name="name"
                                    type="text"
                                    placeholder="Name"
                                    value={data.name ? data.name : ""}
                                    onChange={(e) => this.onChange(e)}
                                />
                                {errors.name && <span className="errorMsg">{errors.name}</span>}
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group className="form-group" controlId="email">
                                <Form.Control
                                    name="email"
                                    type="email"
                                    placeholder="Email"
                                    value={data.email ? data.email : ""}
                                    onChange={(e) => this.onChange(e)}
                                />
                                {errors.email && <span className="errorMsg">{errors.email}</span>}
                            </Form.Group>
                        </Col>
                        <Col md={12}>
                            <Form.Group className="form-group" controlId="notes">
                                <textarea
                                    rows={5}
                                    name="notes"
                                    placeholder="Notes"
                                    value={data.notes ? data.notes : ""}
                                    onChange={(e) => this.onChange(e)}
                                />
                                {errors.notes && <span className="errorMsg">{errors.notes}</span>}
                            </Form.Group>
                        </Col>
                        <Col md={12}>
                            <div className="btn-wrapper">
                                <Button onClick={() => this.submitForm()}>
                                    {!loading && "Schedule"}
                                    {loading && <i className="fas fa-spinner fa-spin" />}
                                </Button>
                                {!loading && <Button onClick={() => this.props.hideProfileField()}>Cancel</Button>}
                            </div>
                        </Col>
                    </Row>
                </Form>
            </div>
        );
    }
}

export default AppointmentProfileField;
