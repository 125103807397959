import React, {useState, useRef} from 'react';
import {Form, Button} from 'react-bootstrap';
import Scrollbars from 'react-custom-scrollbars';
import commonApi from '../../Services/CommonService';
import {formatPhoneNumber} from '../../Utils/commonUtils';
import {errorMessage, successMessage} from '../../Utils/ToastMessages';
import {getDatabase, ref, get, update} from 'firebase/database';

function LandingForm(props) {
  const [phoneOrEmail, setPhoneOrEmail] = useState('');
  const [validated, setValidated] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [isValid, setIsValid] = useState(true);

  const buttonRef = useRef(null);

  const handleSubmit = event => {
    const form = event.currentTarget;

    const isPhoneValid =
      /^(?:\+92|92)?0?[3]\d{9}$|^(?:\+91|91)?[6-9]\d{9}$|^(?:\+1|1)?\d{10}$/.test(
        phoneOrEmail,
      );

    const isEmailValid =
      /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(phoneOrEmail);

    if (form.checkValidity() === false || (!isPhoneValid && !isEmailValid)) {
      event.preventDefault();
      event.stopPropagation();
      setIsValid(false);
      setValidated(true);
    } else {
      event.preventDefault();
      event.stopPropagation();
      setIsValid(true);
      setValidated(false);
      anonymous_to_live();
    }
  };

  const anonymous_to_live = () => {
    const isPhoneValid =
      /^(?:\+92|92)?0?[3]\d{9}$|^(?:\+91|91)?[6-9]\d{9}$|^(?:\+1|1)?\d{10}$/.test(
        phoneOrEmail,
      );
    const isEmailValid =
      /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(phoneOrEmail);

    let numberWithCountryCode;

    if (isPhoneValid) {
      numberWithCountryCode = formatPhoneNumber(phoneOrEmail);
    }

    const payload = {
      anonymous_id: localStorage.getItem('anonymous_Id'),
      first_name: firstName,
      last_name: lastName,
      ...(isPhoneValid
        ? {phone: numberWithCountryCode}
        : isEmailValid
        ? {email: phoneOrEmail}
        : {}),
    };

    // console.log(payload, '============>>>>>>>....payloaddddddddd');

    // return;

    commonApi
      .anonymous_to_live(payload)
      .then(res => {
        if (res.success) {
          localStorage.setItem('anonymous_Id', res.new_id);
          localStorage.setItem('anonymous_Token', res.token);
          localStorage.setItem('chatUserId', res.new_id);
          localStorage.setItem('user', res.new_id);
          if (res.all_chat_ids.length > 0) {
            updateSentByInChats(res.all_chat_ids, res.previous_id, res.new_id);
          }
          props.handleForm();

          successMessage(res.message);
        } else {
          errorMessage(res.message);
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  const updateSentByInChats = async (chatroomIds, userId, newUserId) => {
    const db = getDatabase();
    const updatedChats = []; // Array to store the updated chats

    try {
      // Loop through each chatroom ID
      for (const chatroomId of chatroomIds) {
        const chatroomRef = ref(db, `chatrooms/chats/${chatroomId}`);

        // Fetch the chatroom data
        const snapshot = await get(chatroomRef);
        if (snapshot.exists()) {
          const chatData = snapshot.val();

          // Create updates object for replacing sent_by
          const updates = {};
          const updatedMessages = {}; // Object to store the updated messages for this chatroom

          Object.keys(chatData).forEach(dateKey => {
            const messages = chatData[dateKey];

            Object.keys(messages).forEach(messageId => {
              if (messages[messageId].sent_by === userId) {
                // Update sent_by field
                updates[
                  `chatrooms/chats/${chatroomId}/${dateKey}/${messageId}/sent_by`
                ] = newUserId.toString();

                // Add updated message to updatedMessages
                if (!updatedMessages[dateKey]) updatedMessages[dateKey] = {};
                updatedMessages[dateKey][messageId] = {
                  ...messages[messageId],
                  sent_by: newUserId,
                };
              }
            });
          });

          // Perform batch update
          if (Object.keys(updates).length > 0) {
            await update(ref(db), updates);
            
            updatedChats.push({chatroomId, updatedMessages});
          } else {
           
          }
        } else {
        
        }
      }

      
      return updatedChats; // Return all updated chats
    } catch (error) {
      console.error('Error updating sent_by:', error);
      throw error; // Re-throw the error for further handling
    }
  };

  //   const addEditValue = () => {
  //     setEmail('');
  //     setPhoneOrEmail('');
  //     setFirstName(props.editData?.phone ? props.editData?.phone : '');
  //     setLastName(props.editData?.phone ? props.editData?.phone : '');
  //   };
  const resetStates = () => {
    setPhoneOrEmail('');
    setLastName('');
    setFirstName('');
  };

  return (
    <div
      style={{paddingTop: '10px'}}
      className="message-notes-full-width-hold serach-filter-wrapper">
      <div className="messages-notes-field-form-block fixed_width_page fixed_padding flex flex-1 main-vertical-scroll-holder">
        <Scrollbars autoHide className="custom-scroll">
          <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <Form.Group className="fieldset">
              <Form.Label>First Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter first name"
                value={firstName}
                onChange={e => setFirstName(e.target.value)}
                required
              />
              <Form.Control.Feedback type="invalid">
                First name is required
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="fieldset">
              <Form.Label>Last Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter last name"
                value={lastName}
                onChange={e => setLastName(e.target.value)}
                required
              />
              <Form.Control.Feedback type="invalid">
                Last name is required
              </Form.Control.Feedback>
            </Form.Group>
            {/* <Form.Group className="fieldset">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                placeholder="Enter email"
                value={email}
                onChange={e => setEmail(e.target.value)}
                required
              />
              <Form.Control.Feedback type="invalid">
                Please provide a valid email.
              </Form.Control.Feedback>

            </Form.Group> */}
            <Form.Group className="fieldset">
              <Form.Label>Phone or Email</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter a phone number or email"
                value={phoneOrEmail}
                onChange={e => setPhoneOrEmail(e.target.value)}
                required
                isInvalid={!isValid}
              />
              <Form.Control.Feedback type="invalid">
                {phoneOrEmail === ''
                  ? 'Phone or email is required.'
                  : 'Please enter a valid phone or email.'}
              </Form.Control.Feedback>
            </Form.Group>

            <div className="filter-btn-wrapper mt-3">
              <div className="invite-btn text-center">
                <Button
                  className="btn-dark"
                  type="submit"
                  style={{marginRight: '0px', display: 'none'}}
                  ref={buttonRef}>
                  Submit
                </Button>
              </div>
            </div>
          </Form>
        </Scrollbars>
        <div className="chatbot-footer">
          <ul>
            <li>
              <Button
                className="btn-dark mb-0"
                type="submit"
                onClick={() => {
                  buttonRef.current.click();
                }}>
                Submit
              </Button>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default LandingForm;
