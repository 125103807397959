import axios from 'axios';
import CryptoJS from 'crypto-js';
import {ENCRYPTION_KEY} from './env';
import commonApi from '../Services/CommonService';
import { getLatestTokenForEmail,isTokenAndEmailUnique } from './commonUtils';
import { errorMessage } from './ToastMessages';

const CLIENT_ID = '9e10c793-a214-47b3-9b6a-9b0d2dd34cdb';
const REDIRECT_URI = window.location.origin;
const TOKEN_ENDPOINT =
  'https://login.microsoftonline.com/common/oauth2/v2.0/token';

export const getOutlookRefreshAccessToken = async refreshToken => {
  if (!refreshToken) {
    console.error('No refresh token found');
    return;
  }
  const decrypted = CryptoJS.AES.decrypt(refreshToken, ENCRYPTION_KEY).toString(
    CryptoJS.enc.Utf8,
  );

  const body = new URLSearchParams({
    client_id: CLIENT_ID,
    grant_type: 'refresh_token',
    refresh_token: decrypted,
    redirect_uri: REDIRECT_URI,
  });

  try {
    const response = await fetch(TOKEN_ENDPOINT, {
      method: 'POST',
      headers: {'Content-Type': 'application/x-www-form-urlencoded'},
      body: body.toString(),
    });

    const tokenData = await response.json();
    

    if (tokenData.access_token) {
      return tokenData;
      // Store new access token and refresh token
      // Update refresh token

    } else {
      console.error('Refresh token failed:', tokenData);
    }
  } catch (error) {
    console.error('Error refreshing token:', error);
  }
};

//   export const addEventToOutlookCalendar = async (token, calendarId, event) => {
//     const headers = {
//       Authorization: `Bearer ${token}`,
//       authorization: `Bearer ${token}`,
//     };

//     try {
//       const config = {
//         headers,
//         data: event,
//         method: 'POST',
//         url: `https://graph.microsoft.com/v1.0/me/calendars/${calendarId}/events`,
//       };
//       const response = await axios(config);
//       return response.data;
//     } catch (error) {
//       return error;
//     }
//   };

export const addEventToOutlookCalendar = async (token, calendarId, event) => {
  const eventData = {
    type: 'singleInstance', // singleInstance, occurrence, exception, seriesMaster
    subject: event.summary,
    bodyPreview: event.description ? event.description : event.summary,
    body: {
      contentType: 'HTML',
      content: event.description ? event.description : event.summary,
    },
    end: event.end,
    start: event.start,
    allowNewTimeProposals: false,
    hasAttachments: false,
    importance: 'normal', // low, normal, high
    isCancelled: false,
    isOrganizer: true,
    isReminderOn: true,
    reminderMinutesBeforeStart: '10',
    // isOnlineMeeting: false,
    // onlineMeeting: {
    //   conferenceId: 'String',
    //   joinUrl: 'String',
    //   phones: [{ '@odata.type': 'microsoft.graph.phone' }],
    //   quickDial: 'String',
    //   tollFreeNumbers: ['String'],
    //   tollNumber: 'String'
    // },
    // onlineMeetingUrl: 'String',
    // recurrence: {
    // https://learn.microsoft.com/en-us/graph/api/resources/recurrencepattern?view=graph-rest-1.0
    //   pattern: {
    //     dayOfMonth: 1024,
    //     daysOfWeek: ['String'],
    //     firstDayOfWeek: 'String',
    //     index: 'String',
    //     interval: 1024,
    //     month: 1024,
    //     type: 'String'
    //   },
    // https://learn.microsoft.com/en-us/graph/api/resources/recurrencerange?view=graph-rest-1.0
    //   range: {
    //     endDate: event.recurrence.dateTime,
    //     numberOfOccurrences: 1024,
    //     recurrenceTimeZone: 'America/New_York',
    //     startDate: event.recurrence.dateTime,
    //     type: 'endDate', // endDate, noEnd, numbered
    //   }
    // },
  };

  try {
    const response = await axios.post(
      `https://graph.microsoft.com/v1.0/me/calendars/${calendarId}/events`,
      eventData, // Send eventData as the body of the request
      {
        headers: {
          Authorization: `Bearer ${token}`, // Ensure token is passed here
          'Content-Type': 'application/json',
        },
      },
    );
    return response.data;
  } catch (error) {
    console.error(
      'Error adding event to Outlook calendar:',
      error.response ? error.response.data : error.message,
    ); // Handle error appropriately
    throw error;
  }
};

export const editEventToOutlookCalendar = async (
  token,
  calendarId,
  event,
  eventId,
) => {
  const eventData = {
    type: 'singleInstance', // singleInstance, occurrence, exception, seriesMaster
    subject: event.summary,
    bodyPreview: event.description ? event.description : event.summary,
    body: {
      contentType: 'HTML',
      content: event.description ? event.description : event.summary,
    },
    end: event.end,
    start: event.start,
    allowNewTimeProposals: false,
    hasAttachments: false,
    importance: 'normal', // low, normal, high
    isCancelled: false,
    isOrganizer: true,
    isReminderOn: true,
    reminderMinutesBeforeStart: '10',
    // isOnlineMeeting: false,
    // onlineMeeting: {
    //   conferenceId: 'String',
    //   joinUrl: 'String',
    //   phones: [{ '@odata.type': 'microsoft.graph.phone' }],
    //   quickDial: 'String',
    //   tollFreeNumbers: ['String'],
    //   tollNumber: 'String'
    // },
    // onlineMeetingUrl: 'String',
    // recurrence: {
    // https://learn.microsoft.com/en-us/graph/api/resources/recurrencepattern?view=graph-rest-1.0
    //   pattern: {
    //     dayOfMonth: 1024,
    //     daysOfWeek: ['String'],
    //     firstDayOfWeek: 'String',
    //     index: 'String',
    //     interval: 1024,
    //     month: 1024,
    //     type: 'String'
    //   },
    // https://learn.microsoft.com/en-us/graph/api/resources/recurrencerange?view=graph-rest-1.0
    //   range: {
    //     endDate: event.recurrence.dateTime,
    //     numberOfOccurrences: 1024,
    //     recurrenceTimeZone: 'America/New_York',
    //     startDate: event.recurrence.dateTime,
    //     type: 'endDate', // endDate, noEnd, numbered
    //   }
    // },
  };

  try {
    const response = await axios.patch(
      `https://graph.microsoft.com/v1.0/me/calendars/${calendarId}/events/${eventId}`,
      eventData, // Send eventData as the body of the request
      {
        headers: {
          Authorization: `Bearer ${token}`, // Ensure token is passed here
          'Content-Type': 'application/json',
        },
      },
    );
    return response.data;
  } catch (error) {
    console.error(
      'Error adding event to Outlook calendar:',
      error.response ? error.response.data : error.message,
    ); // Handle error appropriately
    throw error;
  }
};

export const deleteEventToOutlookCalendar = async (
  token,
  calendarId,
  eventId,
) => {
  try {
    const response = await axios.delete(
      `https://graph.microsoft.com/v1.0/me/calendars/${calendarId}/events/${eventId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      },
    );
    return response.data;
  } catch (error) {
    console.error(
      'Error adding event to Outlook calendar:',
      error.response ? error.response.data : error.message,
    ); // Handle error appropriately
    throw error;
  }
};

export const handleOutlookCalendarEvents = async (outlookEvents,event, type) => {
  // if(type ==='edit'){
  getOutlookRefreshToken(outlookEvents,event, type);
  // }
};

// const getOutlookRefreshToken = async (outlookEvents,event, value) => {
//   var payload = {
//     type: 'outlook',
//   };

//   let outlookEmails = [];
//   let emailWithToken = [];
  
//   commonApi.get_refresh_token(payload).then(async response => {
//     for (const item of outlookEvents) {
//       const {google_calendar_email} = item;
//       const email = google_calendar_email;

//       if (email) {
//         const matchingToken = getLatestTokenForEmail(
//           response.refresh_token,
//           email,
//         );


//         if (matchingToken && matchingToken.refresh_token) {
//           var tokenData;
//           if (!outlookEmails.includes(email)) {
//             tokenData = await getOutlookRefreshAccessToken(
//               matchingToken.refresh_token,
//               item.calendar_id,
//             );
//             if (tokenData.access_token) {
//               outlookEmails.push(email);
//               emailWithToken.push({
//                 email: email,
//                 accessToken: tokenData.access_token,
//               });
//             }

//             const newToken = isTokenAndEmailUnique(
//               response.refresh_token,
//               matchingToken.email,
//               tokenData.refresh_token,
//             );
//             const ciphertext = CryptoJS.AES.encrypt(
//               tokenData.refresh_token,
//               ENCRYPTION_KEY,
//             ).toString();


//             if (newToken) {
//               const body = {
//                 email: matchingToken.email,
//                 type: 'outlook',
//                 refresh_token: ciphertext,
//               };
//               await commonApi.add_refresh_token(body);
//             }
//           }

//           const filteredToken = emailWithToken.filter(
//             item => item.email === email,
//           );

//           const accessToken = tokenData.access_token
//             ? tokenData.access_token
//             : filteredToken[0].accessToken;

//           let res;
//           if (value === 'edit') {
//             const eventData = event;
//             res = await editEventToOutlookCalendar(
//               accessToken,
//               item.calendar_id,
//               eventData,
//               item.calendar_event_id,
//             );
//           }

//           if (value === 'delete') {
//             res = await deleteEventToOutlookCalendar(
//               accessToken,
//               item.calendar_id,
//               item.calendar_event_id,
//             );
//           }

//           // EventId.push({
//           //   calendar_id: item.google_calendar_id,
//           //   calendar_event_id: res.id,
//           //   type: props.accountType,
//           // });



//           // You can use the accessToken as needed
//         } else {
//         }
//       }
//     }
//     // getAccessTokenFromRefreshToken(response.refresh_token[0].refresh_token);
//   });
// };


const getOutlookRefreshToken = async (outlookEvents, event, value) => {
  const payload = {
    type: 'outlook',
  };

  let outlookEmails = [];
  let emailWithToken = [];

  try {
    
    const response = await commonApi.get_refresh_token(payload);

    for (const item of outlookEvents) {
      try {
        const { google_calendar_email } = item;
        const email = google_calendar_email;

        if (email) {
          const matchingToken = getLatestTokenForEmail(response.refresh_token, email);
         

          if (matchingToken && matchingToken.refresh_token) {
            let tokenData;
            if (!outlookEmails.includes(email)) {
              try {
                tokenData = await getOutlookRefreshAccessToken(matchingToken.refresh_token, item.calendar_id);
                if (tokenData.access_token) {
                  outlookEmails.push(email);
                  emailWithToken.push({
                    email: email,
                    accessToken: tokenData.access_token,
                  });
                }
                

                const newToken = isTokenAndEmailUnique(response.refresh_token, matchingToken.email, tokenData.refresh_token);
                const ciphertext = CryptoJS.AES.encrypt(tokenData.refresh_token, ENCRYPTION_KEY).toString();
                

                if (newToken) {
                  const body = {
                    email: matchingToken.email,
                    type: 'outlook',
                    refresh_token: ciphertext,
                  };
                  await commonApi.add_refresh_token(body);
                }
              } catch (tokenError) {
                errorMessage('Session has been expired please Add Your Account in Settings')
                console.error('Error fetching or processing token:', tokenError.message);
                // Optionally, continue to the next item or handle the error as needed
              }
            }

            const filteredToken = emailWithToken.filter(item => item.email === email);
            const accessToken = tokenData?.access_token || filteredToken[0]?.accessToken;

            let res;
            try {
              if (value === 'edit') {
                const eventData = event;
                res = await editEventToOutlookCalendar(accessToken, item.calendar_id, eventData, item.calendar_event_id);
              }

              if (value === 'delete') {
                res = await deleteEventToOutlookCalendar(accessToken, item.calendar_id, item.calendar_event_id);
              }

              // EventId.push({
              //   calendar_id: item.google_calendar_id,
              //   calendar_event_id: res.id,
              //   type: props.accountType,
              // });

              

            } catch (calendarError) {
              console.error('Error editing or deleting event:', calendarError.message);
            }
          } else {
            console.error('No matching token found or invalid refresh token');
          }
        }
      } catch (itemError) {
        console.error('Error processing item:', itemError.message);
      }
    }
  } catch (responseError) {
    console.error('Error fetching refresh tokens:', responseError.message);
  }
};


// const getRefreshToken = async (all_calenders_event, type) => {
//   var payload = {
//     type: 'outlook',
//   };
//   commonApi.get_refresh_token(payload).then(response => {
//     for (const item of all_calenders_event) {
//       const {google_calender_email} = item;

//       if (google_calender_email) {
//         const matchingToken = response.refresh_token.find(
//           token => token.email === google_calender_email,
//         );

//         if (matchingToken && matchingToken.refresh_token) {
//           getAccessTokenFromRefreshToken(
//             matchingToken.refresh_token,
//             item.calendar_id,
//             item.calendar_event_id,
//             type,
//           );

//           // You can use the accessToken as needed
//         } else {
//         }
//       }
//     }
//   });
// };
