import React, {useState, useEffect} from 'react';
import {
  
  Pannels,
} from '../../Components';
import Sidebar from '../Sidebar';
import {useParams} from 'react-router-dom';
import commonApi from '../../Services/CommonService';
import {connect} from 'react-redux';
// import ProfileComponent from '../../Components/ProfilePage/ProfileComponent';
import ProfileComponent from '../../Components/ProfilePage/ProfileComponentPage';
import SnapshotDetail from '../../Components/Snapshot/SnapshotDetail';
import OpportunityDetail from '../../Components/OpportunitiesList/OpportunityDetail';
import ReferOpportunity from '../../Components/Referral/ReferOpportunity';
import SessionDetail from '../../Components/SessionDetails/SessionDetail';
import FavouriteList from '../../Components/ProfilePage/FavouriteList';
import CreateSession from '../../Components/SessionDetails/CreateSession';
import EditOpportunities from '../../Components/OpportunitiesList/EditOpportunities';
import EventDetail from '../../Components/Referral/EventDetail';
import PurchaseTicket from '../../Components/Referral/PurchaseTicket';
import OpportunityMessageList from '../../Components/OpportunitiesList/OpportunityMessageList';
import OpportunityScreen from '../../Components/FrontScreens/OpportunityScreen';
import LoaderSpinner from '../../Components/Modals/LoaderSpinner';
import SmartChatPage from '../../Components/Common/SmartChatPage';
import {chatroomInfoOppo, collectingOppoUsers} from '../../Utils/commonUtils';
import {ProfileNotes} from '../../Constants/Images';
import CreateWorkSpace from '../../Components/WorkSpace/CreateWorkSpace';
import EditUserProfile from '../../Components/ProfilePage/EditUserProfile';
import ProfileMap from '../../Components/ProfilePage/ProfileMap';


const ProfilePage = props => {

  const [showSpinner, setShowSpinner] = useState(true);

  const [userInfo, setUserInfo] = useState([]);

  const [showUserInfo, setShowUserInfo] = useState(false);








 
  const [component, setComponent] = useState('');
  const [inviteUser, setInviteUser] = useState(false);
  const [createSessionEvent, setCreateSessionEvent] = useState(false);
  const [allData, setAllData] = useState([]);
  let loginUserId = localStorage.getItem('id');
  const [showComponent, setShowComponent] = useState({
    followers: false,
    following: false,
    snapshot: false,
    opportunity: false,
    session: false,
    appointment: false,
    praise: false,
    circle: false,
    share: false,
    referOpportunity: false,
    profilenotes: false,
  });

  const [showComponentOpp, setShowComponentOpp] = useState();
  const [snapshotId, setSnapshotId] = useState({});

  const [sessionData, setSessionData] = useState({});

  const [chatRoomName, setChatRoomName] = useState('');
  const [chatRoomId, setChatRoomId] = useState('');
  const [allChatUsers, setAllChatUsers] = useState([]);
  const [oppoDetails, setOppoDetails] = useState([]);
  const [chatRoomStatus, setChatRoomStatus] = useState('pending');
 

  const [showHiddenPanel, toggleHiddenPanel] = useState(false);
  const [opportunityId, setOpportunityId] = useState('');
  const [profileImgScrollBar, setProfileImgScrollBar] = useState('');
  const [workSpaceItem, setWorkSpaceItem] = useState([]);
  const {idss} = useParams();
  const [responsive, setResponsive] = useState(window.innerWidth <= 650);
  const getUserData = () => {
    var data = {
      user_id: localStorage.getItem('user') ? localStorage.getItem('user') : 1,
    };
    commonApi
      .user_info(data)
      .then(res => {
        if (res) {
          setUserInfo(res);
          // setAllUserInfo(res.data);
          setShowUserInfo(true);
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };
  const getSnapshotData = () => {
    var data = {
      user_id: localStorage.getItem('id'),
      categories: '',
    };
    commonApi
      .get_accomplishment(data)
      .then(res => {
        if (res) {
          // setSnapshotData(res.accomplishment);
          // setShowSnapshot(true);
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };
  const getUpcommingData = () => {
    var data = {
      user_id: localStorage.getItem('user'),
      type: 'all',
    };
    commonApi
      .get_user_sessions(data)
      .then(res => {
        if (res) {
          // console.log('bawawawaawwa', res);
          // setUpcommingData(res.user_session);
          // setShowUpcomming(true);
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };
  const getOpportunityData = () => {
    var data = {
      user_id: localStorage.getItem('user'),
      type: 'all',
    };
    commonApi
      .get_user_available_opportunities(data)
      .then(res => {
        if (res) {
          // setOpportunityData(res.user_available_opportunity);
          // setShowOpportunity(true);
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };
  // 
  useEffect(() => {

      if(idss){
      setTimeout(() => {

        getBackgroundData()
      }, 1000);
    }

    getUserData();
    // getSnapshotData();
    // getUpcommingData();
    // getOpportunityData();
    // getTabstatus();
    // setTimeout(() => {
    //   setShowSpinner(false);
    // }, 2000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idss]);

  useEffect(() => {
  
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ chatRoomName, chatRoomId, allChatUsers ,oppoDetails])
  
  useEffect(() => {
    // Function to check window width and update responsive state
    const checkWindowWidth = () => {
      setResponsive(window.innerWidth <= 650);
    };

    // Add event listener for window resize
    window.addEventListener('resize', checkWindowWidth);

    // Clean up the event listener when component unmounts
    return () => {
      window.removeEventListener('resize', checkWindowWidth);
    };
  }, []);

  const getBackgroundData =()=>{
    const DBOpenRequest = window.indexedDB.open("Build", 4);

  DBOpenRequest.onsuccess = (event) => {
  
  const db = DBOpenRequest.result;
  
    getData(db);
  };


  function getData(db) {
    const transaction = db.transaction(["Table1"], "readwrite");
  
    transaction.oncomplete = (event) => {
      
    };
  
    transaction.onerror = (event) => {
    
    };
  
  
    const objectStore = transaction.objectStore("Table1");
  
    
    const objectStoreRequest = objectStore.get("1");
  
    objectStoreRequest.onsuccess = (event) => {

     
  

      


      setTimeout(() => {
        deleteDataFromIndexDb(db)
      }, 3000);
    };
  }

  }


  
  const deleteDataFromIndexDb =(db)=>{



    let transaction = db.transaction(["Table1"], "readwrite");
  
  
    
    transaction.oncomplete = () => {
     
    };


  }






  // 
  // 

  const getTabstatus = () => {
    // setshowLoader(true);
    // setShowSpinner(true);
    commonApi
      .get_tab_status()
      .then(res => {
        // setshowLoader(false);
        // setShowSpinner(false);
        // 
        if (res.status === 200) {
          const item = res.tab_status;
          if (item.length === 0) {
            // setComponent('firstVist');
          } else {
            const newArray = [];
          if(Object.entries(item).length > 0)  {
              
                item.forEach((item, index) => {
                  newArray.push(item.tab);
                });
            }
            const found = newArray.find(element => element === 'profile');
            if (found === 'profile') {
              // setComponent('');
            } else {
              // setComponent('firstVist');
            }
            // 
            // if (newArray === ) {

            // }tunity'
          }
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  const dynamicStyle = {
    padding: 0,
    // Add other styles as needed
  };

  const dynamic = {
    top: 0,
    
  };
  return (
    <div className={`page-wrapper ${responsive ? 'bg_black' :''}`}>
      <Sidebar
       setWorkSpaceItem={setWorkSpaceItem}
       workSpaceItem={workSpaceItem}
        location={window.location.href}
        profileImgScrollBar={profileImgScrollBar}
        setShowComponent={setComponent}
      />

      <div className="page-content-wrapper">
      <div className="sessions-list-wrapper">
        <Pannels
          isFloated={''}
          showHiddenPanel={showHiddenPanel}
          updateHiddenPanelState={nState => toggleHiddenPanel(nState)}
          customCss={showUserInfo ?  dynamicStyle : dynamic}
          primary={
            <>
              {component === 'firstVist' && (
                <OpportunityScreen
                  onClose={() => {
                    setComponent('');
                  }}
                  type={'profile'}
                />
              )}
              {component === 'Favr' && (
                <FavouriteList
                  setAllData={setAllData}
                  allData={allData}
                  setCreateSessionEvent={setCreateSessionEvent}
                  setInviteUser={setInviteUser}
                  onClose={() => {
                    setComponent('');
                  }}
                />
              )}
              {component === 'editProf' && (
                  <EditUserProfile
                  profile={userInfo}
                  onClose={() => {
                    setComponent('');
                  }}
                  setProfileImgScrollBar={setProfileImgScrollBar}
                />
              )}

              {component === 'homeMap' && (
        <ProfileMap
          profile={userInfo}
          home={true}
          onClose={() => {
            setComponent('');
          }}
        />
      )}

{component === 'currentMap' && (
        <ProfileMap
          profile={userInfo}
          home={true}
          onClose={() => {
            setComponent('');
          }}
        />
      )}
              {/* {showSpinner ? (
                <LoaderSpinner />
              ) : ( */}
                <>
                {showSpinner && <LoaderSpinner/>}
                  {component !== 'firstVist' && (
                    <>
                      {/* <Scrollbars> */}
                      {/* {showUserInfo ? ( */}
                        <>
                          <ProfileComponent
                            userId={localStorage.getItem('user')}
                            //   show={false}
                            // closeModal={() => {setNewShow(false)}}
                            toggleHiddenPanel={toggleHiddenPanel}

                            userData={[]}
                            moderator={false}
                            // setParticipantType={setParticipantType}
                            VoxeetSDK={[]}
                            conferenceInfo={[]}
                            jdSpecialM={[]}
                            jdSpecialA={[]}
                            // noshow={''}
                            // setNowShow={}
                            setEditProfile={setComponent}
                            editProfile={component}
                            setShowSpinner={setShowSpinner}
                          />

                          {/* <ProfileBlock profile={userInfo} /> */}
                          {/* <DonationBlock profile={userInfo} /> */}
                        </>
                      {/* ) : (
                        <></>
                      )} */}
                      {/* </Scrollbars> */}
                    </>
                  )}
                </>
              {/* )} */}

              
            </>
          }
          secondary={
            <>
              {/* {!showComponent.snapshot && (
                <div className="serach-filter-wrapper fix-width-forms w-100">
                  <Scrollbars>
                    <ProfileGallery
                      gallery={userSnapshots}
                      isNewSession={true}
                      oppos={userOpportunity}
                      sesList={userUpcomming}
                      noshow={noshow}
                      userId={localStorage.getItem('id')}
                      handleCommand={handleSnapshotId}
                      showComponent={showComponent}
                      showComponentOpp={showComponentOpp}
                    />
                  </Scrollbars>
                </div>
              )} */}

              {inviteUser && (
                <EditOpportunities
                  onClose={() => {
                    setInviteUser(false);
                  }}
                  item={allData}
                />
              )}
              {createSessionEvent && (
                <CreateSession
                  onClose={() => {
                    setCreateSessionEvent(false);
                  }}
                  opportunitySession={allData}
                  showCloseIcon={true}
                />
              )}

              {showComponent.snapshot ? (
                <>
                  {/* <span className="close as-link" onClick={() => setShowComponent({ ...showComponent, ['snapshot']: false })}>
                  Back
              </span> */}
                  <SnapshotDetail
                    snapshotId={snapshotId}
                    setSnapshotId={setSnapshotId}
                    userData={userInfo.data}
                    editProfile={true}
                    // onClose={() =>
                    //   setShowComponent({
                    //     ...showComponent,
                    //     ['snapshot']: false,
                    //   })
                    // }
                    showRefer={true}
                  />
                </>
              ) : showComponent.referOpportunity ? (
                <ReferOpportunity
                  onClose={() =>
                    setShowComponent({
                      ...showComponent,
                      referOpportunity: false,
                    })
                  }
                  userData={opportunityId}
                  setOpportunityId={setOpportunityId}
                  type={'opportunity'}
                />
              ) : showComponent.opportunity ? (
                <div>
                  {showComponentOpp === 'mine-opportunity' && (
                    <OpportunityMessageList
                      item={allData}
                      setOppoDetails={setOppoDetails}
                      setAllChatUsers={setAllChatUsers}
                      setChatRoomId={setChatRoomId}
                      setChatRoomName={setChatRoomName}
                      setChatRoomStatus={setChatRoomStatus}
                      onClose={() => {
                        setShowComponentOpp('');
                        setShowComponent({
                          ...showComponent,
                          opportunity: false,
                        });
                      }}
                      onEditOppo={() => {
                        setInviteUser(true);
                        setCreateSessionEvent(false);
                      }}
                      onCreateSessionEvent={() => {
                        setCreateSessionEvent(true);
                      }}
                      onShowChat={() => {
                        setShowComponentOpp('show-chats');
                      }}
                    />
                  )}

                  {/* {chatRoomName !== '' &&
                    allChatUsers.length > 0 &&
                    showComponentOpp === 'show-chats' && (
                      <SmartChatPage
                        allmsg={true}
                        type={'mine-opportunity'}
                        item={allData}
                        oppoDetails={oppoDetails}
                        allChatUsers={allChatUsers}
                        chatroomInfo={chatroomInfoOppo(
                          oppoDetails,
                          'opportunity',
                        )}
                        chatDetails={oppoDetails.chat_detail}
                        chatRoomName={chatRoomName}
                        chatRoomId={chatRoomId}
                        chatRoomStatus={chatRoomStatus}
                        onClose={() => {
                          // setShowComponent({
                          //   ...showComponent,
                          //   ['opportunity']: false,
                          // });
                          setShowComponentOpp('mine-opportunity');
                        }}
                      />
                    )} */}

                  {showComponentOpp === 'other-opportunity' && (
                    <SmartChatPage
                      allmsg={true}
                      type={'other-opportunity'}
                      item={[]}
                      oppoDetails={allData}
                      chatDetails={allData.chat_detail}
                      chatroomInfo={chatroomInfoOppo(allData, 'opportunity')}
                      allChatUsers={collectingOppoUsers(allData)}
                      // allChatUsers={
                      //   allData.hasOwnProperty('creator_details')
                      //     ? [allData.creator_details]
                      //     : [allData.creator_detail]
                      // }
                      chatRoomName={
                        allData.chat_detail.hasOwnProperty('chat_room_name')
                          ? allData.chat_detail.chat_room_name
                          : ''
                      }
                      chatRoomId={
                        allData.chat_detail.hasOwnProperty('id')
                          ? allData.chat_detail.id
                          : ''
                      }
                      onClose={() => {
                        setShowComponent({
                          ...showComponent,
                          opportunity: false,
                        });
                        setShowComponentOpp('');
                      }}
                      userData={
                        allData.hasOwnProperty('creator_details')
                          ? allData.creator_details
                          : allData.creator_detail
                      }
                      chatRoomStatus={chatRoomStatus}
                      // handleCommonChat={(data, type)=>{handleCommonChat(data, type)}}
                    />
                  )}

                  {showComponentOpp === 'respond-opportunity' && (
                    <OpportunityDetail
                      item={allData}
                      userData={allData.creator_details}
                      onClose={() => {
                        // setShowButton(false);
                        setShowComponent('');
                      }}
                    />
                  )}
                  {/* <OpportunityDetail
                            item={opportunityData}
                            userData={userInfo.data}
                            onClose={() =>
                              setShowComponent({
                                ...showComponent,
                                ["opportunity"]: false,
                              })
                            }
                          /> */}
                </div>
              ) : showComponent.session ? (
                <>
                  <div>
                    {sessionData.event === 'no' &&
                      sessionData.creator_id !== localStorage.getItem('id') && (
                        <SessionDetail
                          item={sessionData}
                          setSessionData={setSessionData}
                          onClose={() =>
                            setShowComponent({
                              ...showComponent,
                              session: false,
                            })
                          }
                        />
                      )}
                    {sessionData.event === 'no' &&
                      sessionData.creator_id === localStorage.getItem('id') && (
                        <CreateSession
                          editSession={sessionData}
                          onClose={() =>
                            setShowComponent({
                              ...showComponent,
                              session: false,
                            })
                          }
                        />
                      )}

                    {sessionData.event === 'yes' &&
                      (sessionData.creator_id === loginUserId ||
                        sessionData.moderator_ids.includes(
                          localStorage.getItem('id'),
                        )) && (
                        <EventDetail
                          onClose={() => {
                            setShowComponent({
                              ...showComponent,
                              session: false,
                            });
                          }}
                          item={sessionData}
                          type={'profile'}
                        />
                      )}

                    {sessionData.event === 'yes' &&
                      sessionData.creator_id !== loginUserId &&
                      !sessionData.moderator_ids.includes(
                        localStorage.getItem('id'),
                      ) && (
                        <PurchaseTicket
                          onClose={() => {
                            setShowComponent('');
                          }}
                          item={sessionData}
                          type={'profile'}
                        />
                      )}
                  </div>
                </>
              ) : showComponent.profilenotes ? (
                <ProfileNotes />
              ) : (
                <></>
              )}
            </>
          }
        />
        {component === 'WorkSpace' && (
                <CreateWorkSpace
                  onClose={() => {
                    setComponent('');
                  }}
                />
              )}
      </div>
      </div>

      {/* <Footer /> */}
    </div>
  );
};

const mapStateProps = state => ({
  menuState: state.menuState,
});

export default connect(mapStateProps, {})(ProfilePage);
