import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { useState } from 'react';
import { FaBars, FaAsterisk } from 'react-icons/fa';
import { Trash } from '../../Constants/Images';
import { handleRequiredToggle } from '../../Utils/commonUtils';
import EditPencilSvg from '../../Svg/EditPencilSvg';

const FormSection = ({
  sections,
  setSections,
  handelSection,
  SubmitFormApi,
  userInEdit,
  updateForm,
}) => {
  const [openSections, setOpenSections] = useState(new Set());
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleSection = index => {
    setOpenSections(prev => {
      const newOpen = new Set(prev);
      newOpen.has(index) ? newOpen.delete(index) : newOpen.add(index);
      return newOpen;
    });
  };

  const onDragEnd = result => {
    const { source, destination, type } = result;

    if (!destination) return;
    if (source.index === destination.index && source.droppableId === destination.droppableId) return;

    if (type === 'SECTION') {
      const newSections = [...sections];
      const [moved] = newSections.splice(source.index, 1);
      newSections.splice(destination.index, 0, moved);
      setSections(newSections);
    } else if (type === 'FIELD') {
      if (source.droppableId !== destination.droppableId) return;

      const sectionIndex = parseInt(source.droppableId);
      if (isNaN(sectionIndex) || sectionIndex < 0 || sectionIndex >= sections.length) return;

      const newSections = [...sections];
      const fields = [...newSections[sectionIndex].field_ids];
      const [moved] = fields.splice(source.index, 1);
      fields.splice(destination.index, 0, moved);
      newSections[sectionIndex].field_ids = fields;
      setSections(newSections);
    }
  };

  const deleteField = (sectionIndex, fieldIndex) => {
    setSections(prev => {
      const newSections = [...prev];
      newSections[sectionIndex].field_ids.splice(fieldIndex, 1);
      return newSections;
    });
  };

  return (
    <DragDropContext onDragEnd={userInEdit ? onDragEnd : () => {}}>
      <Droppable droppableId="sections" type="SECTION" isDropDisabled={!userInEdit}>
        {(provided) => (
          <div {...provided.droppableProps} ref={provided.innerRef} className="flex flex-col">
            {sections.map((sectionItem, sectionIndex) => {
              const isActive = sectionIndex === activeIndex;

              return (
                <Draggable
                  key={sectionIndex.toString()}
                  draggableId={sectionIndex.toString()}
                  index={sectionIndex}
                  isDragDisabled={!userInEdit}
                >
                  {(provided) => (
                    <div
                      ref={provided.innerRef}
                      {...(userInEdit ? provided.draggableProps : {})}
                      className="w-full bg-[#EDEBE5] mb-2 rounded-lg"
                    >
                      <div className="flex justify-between items-center p-3 cursor-pointer rounded-t-lg">
                        <div
                          onClick={() => {
                            if(sectionItem.field_ids?.length > 0){
                              toggleSection(sectionIndex)
                            }
                            }}
                          className="flex items-center gap-2"
                          {...(userInEdit ? provided.dragHandleProps : {})}
                        >
                          <FaBars style={{ color: 'rgb(156 163 175)' }} />
                          <span className="text-lg font-medium">{sectionItem.name}</span>
                        </div>

                        {userInEdit && (
                          <div className="flex items-center gap-2">
                            {isActive && (
                              <p
                                onClick={() => handelSection(sectionIndex, 'delete_section')}
                                style={{ color: '#52bd41' }}
                              >
                                Delete All
                              </p>
                            )}
                            <p
                              className="as-link"
                              onClick={() => setActiveIndex(isActive ? null : sectionIndex)}
                            >
                              <img src={Trash} alt="" />
                            </p>
                            <span
                              onClick={() => handelSection(sectionIndex, 'edit_section', sectionItem)}
                            >
                              <EditPencilSvg fill="#52bd41" h={15} w={15} />
                            </span>
                          </div>
                        )}
                      </div>

                      {openSections.has(sectionIndex) && sectionItem.field_ids?.length > 0 && (
                        <Droppable droppableId={sectionIndex.toString()} type="FIELD" isDropDisabled={!userInEdit}>
                          {(provided) => (
                            <div ref={provided.innerRef} {...provided.droppableProps} className="p-3 space-y-3">
                              {sectionItem.field_ids.map((item, fieldIndex) => (
                                <Draggable
                                  key={`${sectionIndex}-${fieldIndex}`}
                                  draggableId={`${sectionIndex}-${fieldIndex}`}
                                  index={fieldIndex}
                                  isDragDisabled={!userInEdit}
                                >
                                  {(provided) => (
                                    <div
                                      ref={provided.innerRef}
                                      {...(userInEdit ? provided.draggableProps : {})}
                                      className="flex justify-between items-center bg-white p-3 rounded-md shadow"
                                    >
                                      <div className="flex items-center gap-2">
                                        <div
                                          {...provided.dragHandleProps}
                                          className="flex items-center gap-2"
                                        >
                                          <FaBars style={{ color: 'rgb(156 163 175)' }} />
                                          <div>
                                            <div className="text-sm font-medium">{item.name}</div>
                                            <div style={{ color: '#7E7E7E' }} className="text-xs">
                                              {item.type}
                                            </div>
                                          </div>
                                        </div>
                                      </div>

                                      <div className="flex items-center gap-2">
                                        {userInEdit && isActive && (
                                          <span
                                            onClick={() => deleteField(sectionIndex, fieldIndex)}
                                            className="poc_delete as-link"
                                          >
                                            <i className="fa-solid fa-trash-can"></i>
                                          </span>
                                        )}
                                        <FaAsterisk
                                          fill={item.required ? '#52bd41' : 'black'}
                                          className={`${userInEdit ? 'as-link' : ''}`}
                                          onClick={() =>{
                                            if(userInEdit){
                                              handleRequiredToggle(
                                                sections,
                                                setSections,
                                                fieldIndex,
                                                'section_field',
                                                sectionIndex
                                              )}
                                            }
                                             
                                           
                                          }
                                        />
                                      </div>
                                    </div>
                                  )}
                                </Draggable>
                              ))}
                              {provided.placeholder}
                            </div>
                          )}
                        </Droppable>
                      )}
                    </div>
                  )}
                </Draggable>
              );
            })}
            {provided.placeholder}
          </div>
        )}
      </Droppable>

      {userInEdit && (
        <div className="flex justify-center items-center" >
          <button 
          onClick={SubmitFormApi}
            style={{
              height: '42px',
              width: '150px'
            }}
            type="button" className="mt-4 bg-black text-white px-6 py-2 rounded-md">
            {updateForm ? 'Update Form' : 'Add Form'}
          </button>
        </div>
      )}
    </DragDropContext>
  );
};

export default FormSection;
