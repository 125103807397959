import React, {useState} from 'react';
import {Form, Button, Alert} from 'react-bootstrap';
import {successMessage} from '../Utils/ToastMessages';

const SportForm = () => {
  const [formData, setFormData] = useState({
    description: '',
    subject: '',
    email: '',
  });
  const [validated, setValidated] = useState(false);

  function createCounter(initialValue) {
    let count = initialValue;
    return function () {
      count++;
      return count;
    };
  }

  // Handler for form data changes
  const handleChange = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  // Validation check on submit
  const handleSubmit = e => {
    const form = e.currentTarget;
    e.preventDefault();
    e.stopPropagation();
    const counter1 = createCounter(0);
    const counter2 = createCounter(10);
    
    return false;

    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
      setValidated(true);
    } else {
      e.preventDefault();
      setValidated(false);
      resetForm();
      successMessage('The support team will reach out to you soon.');
    }
  };

  const resetForm = () => {
    setFormData({
      description: '',
      email: '',
      subject: '',
    });
  };

  return (
    <div className="container mt-4 mb-4">
      <h2 style={{textAlign: 'center'}}>Support</h2>
      <Form
        noValidate
        validated={validated}
        onSubmit={handleSubmit}
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          maxWidth: '500px',
          margin: 'auto',
        }}>
        {/* Description Field */}
        <Form.Group
          className="mb-3"
          style={{width: '100%'}}
          controlId="formEmail">
          <Form.Label>Email</Form.Label>
          <Form.Control
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            required
          />
          <Form.Control.Feedback type="invalid">
            {formData.email ? 'Email is invalid' : 'Email is required'}
          </Form.Control.Feedback>
        </Form.Group>

        {/* Subject Field */}
        <Form.Group
          className="mb-3"
          style={{width: '100%'}}
          controlId="formSubject">
          <Form.Label>Subject</Form.Label>
          <Form.Control
            type="text"
            name="subject"
            value={formData.subject}
            onChange={handleChange}
            required
          />
          <Form.Control.Feedback type="invalid">
            Subject is required
          </Form.Control.Feedback>
        </Form.Group>

        {/* Email Field */}

        <Form.Group
          className="mb-3"
          style={{width: '100%'}}
          controlId="formDescription">
          <Form.Label>Message</Form.Label>
          <Form.Control
            type="text"
            as="textarea"
            name="description"
            value={formData.description}
            onChange={handleChange}
            required
          />
          <Form.Control.Feedback type="invalid">
            Message is required
          </Form.Control.Feedback>
        </Form.Group>

        {/* Submit Button */}
        <button
          variant="primary"
          type="submit"
          style={{
            padding: '10px 20px',
            fontSize: '16px',
            border: 'none',
            borderRadius: '5px',
            cursor: 'pointer',
            backgroundColor: 'rgb(70 180 78)', // Primary color
            color: 'white',
            transition: 'background-color 0.3s ease',
          }}>
          Submit
        </button>
      </Form>
    </div>
  );
};

export default SportForm;
