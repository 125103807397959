import React, {useState, useEffect, useRef} from 'react';
import InvoiceItem from '../InvoiceEstimate/InvoiceItem';

import AppointmentItem from '../AppointmentPage/AppointmentItem';

import CustomModal from '../CustomModal';
import {Button, Col, Dropdown, Form, Row} from 'react-bootstrap';
import commonApi from '../../Services/CommonService';
import {successMessage} from '../../Utils/ToastMessages';
import NorecordFound from '../NoRecord/NorecordFound';


import { OverviewNoRecordFound } from '../../Utils/NoRecordConstant';

const ProfileOverview = props => {
  const [showComponent, setShowComponent] = useState('');
  const [dealPhases, setdealPhases] = useState(
    props.userInfo?.deals_detail?.all_phases,
  );
  const handleApStatus = alphabet => {
    setSelectedPhase(alphabet);
  };
  const [selectedPhase, setSelectedPhase] = useState('');
  const [contactRelations, setContactRelations] = useState([]);
  const [relationType, setRelationType] = useState(props.userInfo?.data?.linked_user_detail?.relationship);

  

  const changeUseRelation = id => {
    const payload = {
      relationship_id: id,
      user_id: props.userInfo.data.id,
    };

 
    commonApi
      .change_user_relation(payload)
      .then(res => {
        if (res.status === 200) {
          successMessage(res.message);
        }
      })
      .catch(err => {
   
        return {type: 'error', message: err.message};
      });
  };

  const getContactRelationTypes = () => {
    commonApi
      .get_contact_relation_types()
      .then(res => {
        if (res.data.length > 0) {
          setContactRelations(res.data);
        }
      })
      .catch(err => {
        
        return {type: 'error', message: err.message};
      });
  };

  useEffect(() => {
    getContactRelationTypes();
  }, []);

  const deat_Status = props.userInfo?.deals_detail?.deal_status;
  return (
    <>

    {(props.userInfo?.deals_detail?.deal_name || props.userInfo.pending_pay ||  props.userInfo.appointment_detail) ? (
      <div className="profile_overview">
        {props.userInfo?.deals_detail?.deal_name && (
          <>
            <div className="overview_flex mb-4">
              <span className="d-flex gap-2 align-items-center">
                {props.userInfo?.deals_detail?.deal_name}
                <div
                  className={
                    deat_Status === '1'
                      ? 'blue_dot'
                      : deat_Status === '2'
                      ? 'yellow_dot'
                      : deat_Status === '3' && 'green_dot'
                  }>
                  {' '}
                </div>
              </span>

              {Object.keys(props.userInfo?.data?.linked_user_detail).length > 0 && (
                <div className="roi-form-dropdown  lead_button_dropdown ">
                  <div className="">
                    <Dropdown
                      onSelect={(eventKey, event) => {
                        setRelationType(eventKey);
                        changeUseRelation(eventKey);
                      }}
                      drop="down">
                      <Dropdown.Toggle variant="primary">
                        {relationType
                          ? contactRelations.find(
                              item => item.id === relationType,
                            )?.relations
                          : 'Select Role'}
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        {contactRelations.map((item, index) => (
                          <Dropdown.Item
                            key={`st-${index}`}
                            eventKey={item.id.toString()}
                            active={item.id === relationType}>
                            {item.relations}
                          </Dropdown.Item>
                        ))}
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </div>
              )}
            </div>

            <div className="mb-4">
              <div className="overview_flex mb-3">
                <span className="fw-bold">Next Step</span>

                <span className="text_green cursor-pointer">Send Contract</span>
              </div>

              <p className="mb-3">
                We are currently in the {props.userInfo?.deals_detail?.phase}{' '}
                phase.
              </p>

              <div
                className="update_phase"
                onClick={() => {
                  props.handleCommand(props.userInfo?.deals_detail, 'dealDeatil');
                  // setShowComponent('changePhase')
                }}>
                Update Phase
              </div>
            </div>
          </>
        )}

       

        
      </div>
    ) : (
      // <NorecordFound
      // noRecordData={OverviewNoRecordFound}
      // />
      <></>
    ) }
      
      {/* 
    {showComponent ==='changePhase' && (
 <CustomModal
 size="medium"
 onClose={() => {
  setShowComponent('')
 }}>
 <div className="filter-wrapper">
   <div className="filter-category-list">
     <ul>
       {Object.entries(dealPhases).length > 0 &&
         dealPhases.map((item, index) => (
           <li key={`sessionC-${index}`}>
             <Form.Label>
               {item.phase}
               <input
                 type="checkbox"
                 id={item.id}
                 value={item.title}
                 checked={selectedPhase === item.id}
                 onChange={() => handleApStatus(item.id)}
               />
               <span />
             </Form.Label>
           </li>
         ))}
     </ul>
     <div className="filter-btn-wrapper text-center">
       <Button
         type="submit"
         className="btn-dark"
         onClick={() => {
          changeDealPhase(selectedPhase);
         
           
         }}>
         Select
       </Button>
     </div>
   </div>
 </div>
</CustomModal>

)


} */}
    </>
  );
};

export default ProfileOverview;
