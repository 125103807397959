import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';

import CustomTabs from '../CustomTabs';
import {labels} from '../../Constants/Translations';
import {DEFAULT_IMAGE,  selected_lang} from '../../Utils/env';
import commonApi from '../../Services/CommonService';
import {
  ShareablesYellowIcon,
  ShareYellowIcon,
  NoForm,
  coffeecup,
  Dollar,
} from '../../Constants/Images';
import Scrollbars from 'react-custom-scrollbars';
import {Button, Col, Form, Row} from 'react-bootstrap';
import LoaderSpinner from '../Modals/LoaderSpinner';
import {
  arrayColumn,
  filteringUsers,
  fixName,
  formatString,
} from '../../Utils/commonUtils';
import OtherUserImages from '../Common/OtherUserImages';
import Moment from 'react-moment';
import ShareAblesSessions from './ShareAblesSessions';
import ShreAblesEvent from './ShreAblesEvent';
import ShareAableOpportunity from './ShareAableOpportunity';
import CustomModal from '../CustomModal';

import {
  FacebookShareButton,
  WhatsappShareButton,
  EmailShareButton,
  TwitterShareButton,
  LinkedinShareButton,
} from 'react-share';
import {successMessage} from '../../Utils/ToastMessages';

const Shareables = props => {
  // const [areas_Filter, setAreas_Filter] = useState('');
  const [userData, setUserData] = useState([]);
  const [mineAreas, setMineAreas] = useState([]);
  const [mineCat, setMineCat] = useState([]);
  const [showLoader, setShowLoader] = useState(false);
  const [snapShopData, setSnapShopData] = useState([]);
  const [allData, setAllData] = useState([]);
  const [unknownData, setUnknownData] = useState('');
  const [tabType, setTabType] = useState([labels.ELEMENT[selected_lang]]);
  const [searchQry, setSearchQry] = useState('');
  // const [shareUrl, setShareUrl] = useState('');
  const [isactiveToggle, setIsactiveToggle] = useState(false);

  let mineAreaUser = [];
  let CategoryOfUser = [];

  useEffect(() => {
    getUserData();
    getAccomplishment();
    get_user_all_data();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (props.dropdownValue.includes('Network')) {
      setTabType(labels.ELEMENT[selected_lang]);
    }
    setShowLoader(true);
    setTimeout(() => {
      setShowLoader(false);
    }, 2000);

    get_user_all_data('');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    props.dropdownValue,
    props.sessionCatgory,
    props.snapshotFilter,
    props.opportunityFilter,
    props.networkFilter,
    tabType,
    props.workspaceFilter,
    props.workSpaceDropDownIds,
  ]);






  const getAccomplishment = () => {
    var payLoad = {
      categories: [],
      user_id: localStorage.getItem('user'),
    };
    commonApi
      .get_accomplishment(payLoad)
      .then(res => {
        if (res.status === 200) {
          setSnapShopData(res.accomplishment);
        }
      })
      .catch(err => {
        
        return {type: 'error', message: err.message};
      });
  };

  const getUserData = () => {
    var data = {user_id: localStorage.getItem('id')};
    commonApi
      .user_info(data)
      .then(res => {
        if (res) {
          setUserData(res);
          setMineAreas(res.mine_areas);
          setMineCat(res.mine_areas);

          // {
          //   res.mine_areas.length > 0 &&
          //     res.mine_areas.map(item => {
          //       mineAreaUser.push(item.title);
          //     });
          // }
        }
      })
      .catch(err => {
        
        return {type: 'error', message: err.message};
      });
  };
  const get_user_all_data = qry => {
    const payload = {
     
      search_query: qry,
      type:
        tabType === labels.PAGE[selected_lang] &&
        props.dropdownValue === 'Workspace'
          ? ['workspace']
          : tabType === labels.ELEMENT[selected_lang] &&
            props.dropdownValue === 'Workspace'
          ? [
              'sessions',
              'events',
              'opportunities',
              'snapshots',
              'appointments',
              'group_chats',
            ]
          : props.dropdownValue.includes('Sessions')
          ? ['sessions']
          : props.dropdownValue.includes('Events')
          ? ['events']
          : props.dropdownValue === 'Opportunities'
          ? ['opportunities']
          : props.dropdownValue.includes('Appointments')
          ? ['appointments']
          : props.dropdownValue.includes('Group Chats')
          ? ['group_chats']
          : // :
          // props.dropdownValue.includes('Workspace')
          // ? ['workspace']

          props.dropdownValue === 'Snapshots'
          ? ['snapshots']
          : props.dropdownValue === 'Network'
          ? ['networks']
          : props.dropdownValue === 'Links'
          ? ['links']
          : props.dropdownValue === 'Forms'
          ? ['Forms']
          : [
              'sessions',
              'events',
              'opportunities',
              'snapshots',
              'appointments',
              'group_chats',
            ],
      category_id: props.sessionCatgory ? props.sessionCatgory : '',
      events: props.dropdownValue.includes('Sessions')
        ? false
        : props.dropdownValue.includes('Events')
        ? true
        : '',
      sessions: props.dropdownValue.includes('Sessions')
        ? true
        : props.dropdownValue.includes('Events')
        ? false
        : '',
      // categories: props.snapshotFilter,
      //opportunity filter

      amount:
        props.opportunityFilter && props.opportunityFilter.amount
          ? props.opportunityFilter.amount
          : '',
      frequency:
        props.opportunityFilter && props.opportunityFilter.frequency
          ? props.opportunityFilter.frequency
          : '',
      latitude:
        props.opportunityFilter && props.opportunityFilter.latitude
          ? props.opportunityFilter.latitude
          : '',
      like: false,
      location:
        props.opportunityFilter && props.opportunityFilter.location
          ? props.opportunityFilter.location
          : '',
      longitude:
        props.opportunityFilter && props.opportunityFilter.longitude
          ? props.opportunityFilter.longitude
          : '',

      opportunity_types:
        props.opportunityFilter && props.opportunityFilter.opportunity_types
          ? props.opportunityFilter.opportunity_types
          : '',
      page: 1,

      vaccinated: props.opportunityFilter.vaccinated ? 'yes' : 'no',
      virtual: props.opportunityFilter.virtual ? 'yes' : 'no',

      //network filter

      // gender: props.gender.toString(),
      gender: '',
      otherIdentifires:
        props.networkFilter && props.networkFilter.otherIdentifires
          ? props.networkFilter.otherIdentifires
          : '',
     
      // ethnicities: props.ethnicityId.toString(),
      ethnicities: '',
      city_states:
        props.networkFilter && props.networkFilter.city_states
          ? props.networkFilter.city_states
          : '',

      on_boarding_areas:
        props.networkFilter && props.networkFilter.on_boarding_areas
          ? props.networkFilter.on_boarding_areas
          : '',
      appointment_types:
        props.networkFilter && props.networkFilter.appointment_types
          ? props.networkFilter.appointment_types
          : '',
      on_boarding_categories:
        props.networkFilter && props.networkFilter.on_boarding_categories
          ? props.networkFilter.on_boarding_categories
          : '',

      on_boarding_areas_filter:
        props.networkFilter && props.networkFilter.on_boarding_areas_filter
          ? props.networkFilter.on_boarding_areas_filter
          : '',
      other_identifier_filter:
        props.networkFilter && props.networkFilter.other_identifier_filter
          ? props.networkFilter.other_identifier_filter
          : '',

      appointment_type_ids:
        props.networkFilter && props.networkFilter.appointment_type_ids
          ? props.networkFilter.appointment_type_ids
          : '',
      areas:
        props.networkFilter && props.networkFilter.areas
          ? props.networkFilter.areas
          : '',
      categories:
        props.networkFilter && props.networkFilter.categories
          ? props.networkFilter.categories
          : '',
      hometown_location:
        props.networkFilter && props.networkFilter.hometown_location
          ? props.networkFilter.hometown_location
          : '',
      // search_query: '',
      travel_location:
        props.networkFilter && props.networkFilter.travel_location
          ? props.networkFilter.travel_location
          : '',
      // latitude:
      //   props.networkFilter && props.networkFilter.latitude
      //     ? props.networkFilter.latitude
      //     : '',
      // longitude:
      //   props.networkFilter && props.networkFilter.longitude
      //     ? props.networkFilter.longitude
      //     : '',
      //workspace filter
      workspace_ids: props.workSpaceDropDownIds.toString(','),
    };

    commonApi
      .get_user_all_data(payload)
      .then(res => {
        if (res) {
          setAllData(res.all_record);
          
        }
      })
      .catch(err => {
     
        return {type: 'error', message: err.message};
      });
  };
  const change_appearance = (type, item) => {
    const payload = {
      id: item.id,
      type: type === 'conference' ? 'session' : type,
    };
    

    commonApi
      .change_appearance(payload)
      .then(res => {
        successMessage(res.message);
        if (res) {
        }
      })
      .catch(err => {
       
        return {type: 'error', message: err.message};
      });
  };

  const showNoreordFound = allData && allData.length > 0 ? true : false;
  const Messages = [{title: 'Invest in Ghana'}];

  if (mineCat.length > 0) {
    CategoryOfUser = userData.mine_categories.map(item => item.title);
  }
  if (mineAreas.length > 0) {
    mineAreaUser = userData.mine_areas.map(item => item.title);
  }
  const ElementDesign = (type, data) => {
    return (
      <div className="d-flex align-items-center justify-content-between mt-2 p20-horizental">
        <p
          className="p-reg m-0 preview-message as-link"
          onClick={() => {
            props.setShareAbleItem(data);
            props.setType(type);
            props.setShowModel('preview');
          }}>
          Preview
        </p>
        <div className="d-flex align-items-center">
          <img
            className="ScanQr as-link"
            src={ShareYellowIcon}
            alt="scanQr"
            onClick={() => {
              props.ShareAbleRefer(type, data);
            }}
          />
          <img
            className="sharableYellow as-link"
            src={ShareablesYellowIcon}
            alt="shareable"
            onClick={() => {
              props.ShowModelLinks('link_model', type, data);
            }}
          />
        </div>
      </div>
    );
  };
  const PageDesign = (type, item) => {
    return (
      <div className="mt-2 p20-horizental show-on-public">
        <div className="d-flex align-items-center justify-content-center">
          <p className="p-reg m-0">Show on Public Page</p>
          <div>
            <Form.Check
              type="switch"
              className="switchBlock"
              id="custom-switch"
              value={isactiveToggle ? true : false}
              checked={isactiveToggle ? true : false}
              onClick={() => {
                setIsactiveToggle(!isactiveToggle);
                change_appearance(type, item);
              }}
            />
          </div>
        </div>
      </div>
    );
  };

  const componentDecesion = () => {};


  

  return (
    <>
      {props.threeDots && (
        <div className="create-ticket-tabs d-flex justify-content-center mt-0">
          <CustomTabs
            darkmode
            active={tabType}
            onSelect={item => setTabType(item)}
            tabs={
              props.dropdownValue.includes('Recent') ||
              props.dropdownValue.includes('Network')
                ? [
                    labels.ELEMENT[selected_lang],
                    labels.MESSAGES[selected_lang],
                  ]
                : [
                    labels.ELEMENT[selected_lang],
                    labels.PAGE[selected_lang],
                    labels.MESSAGES[selected_lang],
                  ]
            }
          />
        </div>
      )}
     
      {props.threeDots && (
        <>
          <div className="search-tabs-wrapper">
            <div className="search-form d-flex mb-3">
              <div className="search-input">
                <Form.Control
                  type="text"
                  value={searchQry}
                  placeholder="Search"
                  onChange={e => {
                    get_user_all_data(e.target.value);
                    setSearchQry(e.target.value);
                  }}
                />
              </div>
              {props.dropdownValue.includes('Sessions') ||
              props.dropdownValue.includes('Events') ||
              props.dropdownValue.includes('Opportunities') ||
              props.dropdownValue.includes('Snapshots') ||
              // props.dropdownValue.includes('Workspace') ||
              props.dropdownValue.includes('Network') ? (
                <Button
                  onClick={() => {
                    if (
                      props.dropdownValue.includes('Sessions') ||
                      props.dropdownValue.includes('Events')
                    ) {
                      props.setShowComponent('session');
                    } else if (props.dropdownValue.includes('Opportunities')) {
                      props.setShowComponent('opportunity');
                    } else if (props.dropdownValue.includes('Snapshots')) {
                      props.setShowComponent('snapshot');
                    } else if (props.dropdownValue.includes('Network')) {
                      props.setShowComponent('network');
                    } else if (props.dropdownValue.includes('Workspace')) {
                      props.setShowComponent('workspace');
                    }
                  }}>
                  <i className="icon-filter-icon" />
                </Button>
              ) : (
                ''
              )}
            </div>
          </div>
        </>
      )}

      <div style={{position: 'relative', flex: 1}}>
        <Scrollbars
          className="custom-scroll referral-cards"
          autoHide
          // onScroll={}
          style={{
            position: 'absolute',
            width: '100%',
            height: '-webkit-fill-available',
          }}>
          {tabType === labels.ELEMENT[selected_lang] && (
            <div>
              {showLoader && <LoaderSpinner />}
              {showNoreordFound && (
                <>
                  {/* {(props.dropdownValue.includes('Workspace') ||
                    props.dropdownValue.includes('All')) && (
                    <div className="shareables-items">
                      <div className="as-link card-container">
                        <div className="padd-with-borderContaier">
                          <div className="profile-img-wrapper">
                            <div className="profile-img">
                              <img
                                src={
                                  userData &&
                                  userData.data &&
                                  userData.data.profile_img
                                    ? userData.data.profile_img
                                    : DEFAULT_IMAGE
                                }
                                alt=""
                              />
                            </div>

                            <div className="profile-name">
                              <div className="profile-name-headings">
                                <div className="">
                                  <h2 className="m-0 mb-1 p-bold">
                                    {userData &&
                                      userData.data &&
                                      userData.data.first_name}
                                    {userData &&
                                      userData.data &&
                                      userData.data.last_name}
                                  </h2>
                                  {mineAreaUser.length > 0 && (
                                    <h3 className="p-reg m-0">
                                      {mineAreaUser.join(', ')}
                                    </h3>
                                  )}
                                </div>
                              </div>
                              <div className="events-icon dark d-flex align-items-center justify-content-between">
                                <ul>
                                  <li>
                                    <span className="as-link">
                                      <i className="icon-applaud" />
                                    </span>
                                    <span className="event-icon-text">10%</span>
                                  </li>

                                  <li>
                                    <span className="as-link icon">
                                      <i className="icon-circles" />
                                    </span>
                                  </li>
                                </ul>

                                <div className="d-flex align-items-center as-link">
                                  <ul>
                                    <li>
                                      <span className="as-link icon">
                                        <i className="fas fa-share" />
                                      </span>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="profile-content-infos">
                            <div className="skills">
                              {CategoryOfUser.join(', ')}
                            </div>

                            <div className="other-infos">
                              <div className="profile-address as-link profile-address">
                                <i className="fas fa-home" />
                                {userData &&
                                userData.data &&
                                userData.data.home_location
                                  ? userData.data.home_location
                                  : 'Add Location'}
                              </div>
                              <div className="profile-address as-link profile-address">
                                <i className="icon-aroplane" />
                                {userData &&
                                userData.data &&
                                userData.data.current_location
                                  ? userData.data.current_location
                                  : 'Add Location'}
                              </div>
                            </div>
                            <p className="p-reg m-0">
                              {userData && userData.data && userData.data.bio}
                            </p>

                            <div className="social-icons">
                              <ul>
                                <li>
                                  <a
                                    href="#"
                                    target="_blank"
                                    rel="noopener noreferrer">
                                    <i className="fab fa-instagram" />
                                  </a>
                                </li>
                                <li>
                                  <a
                                    href="#"
                                    target="_blank"
                                    rel="noopener noreferrer">
                                    <i className="fab fa-twitter" />
                                  </a>
                                </li>
                                <li>
                                  <a
                                    href=""
                                    target="_blank"
                                    rel="noopener noreferrer">
                                    <i className="fab fa-youtube" />
                                  </a>
                                </li>
                                <li>
                                  <a
                                    href="#"
                                    target="_blank"
                                    rel="noopener noreferrer">
                                    <i className="icon-link" />
                                  </a>
                                </li>
                                <li>
                                  <a
                                    href="#"
                                    target="_blank"
                                    rel="noopener noreferrer">
                                    <i className="fa-brands fa-linkedin" />
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div className="d-flex align-items-center justify-content-between mt-2 p20-horizental">
                          <p className="p-reg m-0 preview-message as-link">
                            Edit
                          </p>
                          <div className="d-flex align-items-center">
                            <img
                              className="ScanQr as-link"
                              src={ShareYellowIcon}
                              alt="scanQr"
                            />
                            <img
                              className="sharableYellow as-link"
                              src={ShareablesYellowIcon}
                              alt="shareable"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  )} */}

                  <div className="shareables-items">
                    {allData && Object.entries(allData)?.length > 0 &&
                      allData.map((item, index) => (
                        <>
                          {item.data_type === 'session' && (
                            <>
                              <ShareAblesSessions
                                key={`session-item-${index}`}
                                item={item}
                                heart={''}
                                componentDecesion={componentDecesion}
                                ElementDesign={ElementDesign}
                              />
                              {/* {ElementDesign()} */}
                            </>
                          )}
                          {item.data_type === 'event' && (
                            <>
                              <ShreAblesEvent
                                key={`event-item-${index}`}
                                item={item}
                                index={index}
                                // eventDetails={eventDetails}
                                componentDecesion={componentDecesion}
                                ElementDesign={ElementDesign}
                              />
                            </>
                          )}
                          {item.data_type === 'opportunity' && (
                            <>
                              <ShareAableOpportunity
                                key={`opportunity-item-${index}`}
                                setShowButton={setUnknownData}
                                unknownData={unknownData}
                                onShare={() => {
                                  // setProperComponent('refer-opportunity');
                                }}
                                setChatData={setUnknownData}
                                handleItem={componentDecesion}
                                item={item}
                                ElementDesign={ElementDesign}
                              />
                            </>
                          )}
                          {item.data_type === 'appointment' && (
                            <>
                              <div
                                className="refferals-list as-link card-container"
                                key={'www' + index}>
                                <div className="padd-with-borderContaier">
                                  <div className="refferals-list-item new-refferal-list mb-0">
                                    <div className="refferal-content">
                                      <h2>
                                        {item.creator_details &&
                                          fixName(
                                            item.creator_details.first_name,
                                            item.creator_details.last_name,
                                          )}
                                      </h2>
                                      <span className="refferal-auther">
                                        {item.appointment_type_title}
                                      </span>
                                      {/* <p>Think there may be some synergy</p> */}
                                      <span className="refferal-date">
                                        {/* {moment(item.start_date).format(
                                        'MM/DD/YYYY',
                                      )}{' '} */}
                                        {item.duration + 'min'}
                                      </span>
                                    </div>
                                    <div className="refferal-img">
                                      <img
                                        src={
                                          item.creator_details &&
                                          item.creator_details.profile_img
                                            ? item.creator_details.profile_img
                                            : DEFAULT_IMAGE
                                        }
                                        alt=""
                                      />
                                      <p className="refferal-info-que text-end">
                                        {item.cost ? '$' + item.cost : 'Free'}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                                {ElementDesign('appointment', item)}
                              </div>
                            </>
                          )}
                          {item.data_type === 'group_chat' && (
                            <>
                              <div className="messages-users-list card-container">
                                <div
                                  key={`msg-${index}`}
                                  className={
                                    'message-user-item no-link padd-with-borderContaier m-0'
                                  }>
                                  <div
                                    className="user-img as-link"
                                    // onClick={() => {
                                    //   amplitudeEvent('OPEN_CHAT_ROOM');
                                    //   handleOpenChatRoom(item);
                                    // }}
                                  >
                                    <img
                                      src={
                                        item.group_image !== ''
                                          ? item.group_image
                                          : item[
                                              parseInt(item.creator_id) ===
                                              parseInt(
                                                localStorage.getItem('user'),
                                              )
                                                ? 'user_detail'
                                                : item.hasOwnProperty(
                                                    'creator_details',
                                                  )
                                                ? 'creator_details'
                                                : 'creator_detail'
                                            ]['profile_img']
                                          ? item[
                                              parseInt(item.creator_id) ===
                                              parseInt(
                                                localStorage.getItem('user'),
                                              )
                                                ? 'user_detail'
                                                : item.hasOwnProperty(
                                                    'creator_details',
                                                  )
                                                ? 'creator_details'
                                                : 'creator_detail'
                                            ]['profile_img']
                                          : DEFAULT_IMAGE
                                      }
                                      alt=""
                                    />
                                    {item.chat_type === 'question' && (
                                      <span>
                                        <i className="fa-solid fa-q"></i>
                                      </span>
                                    )}
                                    {item.chat_type === 'opportunity' && (
                                      <span className="icon icon-posts"></span>
                                    )}
                                    {item.chat_type === 'event' && (
                                      <span className="icon-tickets"></span>
                                    )}
                                    {item.chat_type === 'session' && (
                                      <span className="icon-video-camera-2"></span>
                                    )}
                                    {/* <span className="fa-solid fa-shop"></span> */}
                                  </div>
                                  <div
                                    className="user-content as-link"
                                    onClick={() => {
                                      // handleOpenChatRoom(item);
                                    }}>
                                    <h2 className="single-line-text">
                                      {/* {tabType === 'subInvites' &&
                                      !item.hasOwnProperty('read') */}
                                      {item.group_name !== ''
                                        ? item.group_name
                                        : fixName(
                                            item[
                                              parseInt(item.creator_id) ===
                                              parseInt(
                                                localStorage.getItem('user'),
                                              )
                                                ? 'user_detail'
                                                : item.hasOwnProperty(
                                                    'creator_details',
                                                  )
                                                ? 'creator_details'
                                                : 'creator_detail'
                                            ]['first_name'],
                                            item[
                                              parseInt(item.creator_id) ===
                                              parseInt(
                                                localStorage.getItem('user'),
                                              )
                                                ? 'user_detail'
                                                : item.hasOwnProperty(
                                                    'creator_details',
                                                  )
                                                ? 'creator_details'
                                                : 'creator_detail'
                                            ]['last_name'],
                                          )}
                                    </h2>

                                    {formatString(item.last_message)}

                                    <div className="message-date-info">
                                      <span className="message-date">
                                        <Moment format="MM/DD/YY HH:mm A">
                                          {item.last_message_time
                                            ? item.last_message_time
                                            : item.created_at}
                                        </Moment>
                                      </span>
                                      {/* {renderMessageUsers(item.moreUsers)} */}
                                    </div>
                                  </div>

                                  {!item.hasOwnProperty('invitation_type') &&
                                    item.hasOwnProperty('group_detail') && (
                                      <OtherUserImages
                                        otherUsers={filteringUsers(
                                          item.audience_detail.concat(
                                            item.moderator_detail,
                                            item.creator_detail
                                              ? [item.creator_detail]
                                              : [item.creator_details],
                                          ),
                                          arrayColumn(item.group_detail, 'id'),
                                        )}
                                      />
                                    )}
                                  {!item.hasOwnProperty('invitation_type') &&
                                    !item.hasOwnProperty(
                                      'opportunity_invitaion_status',
                                    ) &&
                                    item.hasOwnProperty('opportunity_id') &&
                                    item.chat_type === 'opportunity' && (
                                      <OtherUserImages
                                        otherUsers={filteringUsers(
                                          item.other_persons_opportunity_chat_room.concat(
                                            item.team_member_details,
                                            item.creator_detail
                                              ? [item.creator_detail]
                                              : [item.creator_details],
                                          ),
                                          arrayColumn(
                                            [
                                              ...item.other_persons_opportunity_chat_room,
                                              ...item.team_member_details,
                                              item.creator_details,
                                            ],
                                            'id',
                                          ),
                                        )}
                                      />
                                    )}
                                </div>
                                {ElementDesign('message', item)}
                              </div>
                            </>
                          )}

                          {item.data_type === 'network' && (
                            <>
                              <div className="as-link card-container">
                                <div className="padd-with-borderContaier">
                                  <div className="profile-img-wrapper align-items-center justify-content-start">
                                    <div className="profile-img">
                                      <img
                                        src={
                                          item.profile_img
                                            ? item.profile_img
                                            : DEFAULT_IMAGE
                                        }
                                        alt=""
                                      />
                                    </div>

                                    <div className="profile-name flex-0">
                                      <div className="profile-name-headings">
                                        <div className="">
                                          <h2 className="m-0 mb-1 p-bold">
                                            {item && item.first_name}
                                            {item && item.last_name}
                                          </h2>
                                          {item.on_boarding_areas.length >
                                            0 && (
                                            <h3 className="p-reg m-0">
                                              {item.on_boarding_areas
                                                .map(area => area.title)
                                                .join(', ')}
                                            </h3>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  {/* <div className="profile-content-infos">
                            {CategoryOfUser.join(', ')}

                            <div className="other-infos">
                              <div className="profile-address as-link profile-address">
                                <i className="fas fa-home" />
                                {userData &&
                                userData.data &&
                                userData.data.home_location
                                  ? userData.data.home_location
                                  : 'Add Location'}
                              </div>
                              <div className="profile-address as-link profile-address">
                                <i className="icon-aroplane" />
                                {userData &&
                                userData.data &&
                                userData.data.current_location
                                  ? userData.data.current_location
                                  : 'Add Location'}
                              </div>
                            </div>
                            <p className="p-reg m-0">
                              {userData && userData.data && userData.data.bio}
                            </p>

                           
                          </div> */}
                                </div>
                                {ElementDesign('profile', item)}
                              </div>
                            </>
                          )}

                          {item.data_type === 'accomplishment' && (
                            <>
                              <div
                                className="card-container shareables-snapshots"
                                key={index + 'userListing'}>
                                <div className="padd-with-borderContaier as-link">
                                  <div className=" d-flex align-items-center mb-3">
                                    <div className="snapshotsImage as-link">
                                      <img src={item.images[0]} alt="" />
                                    </div>
                                    <div
                                      className="snapshots-content as-link"
                                      onClick={() => {
                                        // setSnapShotId(item);
                                        // setShowComponent('SnapShotDetail');
                                      }}>
                                      <h2 className="single-line-text">
                                        {item.title}
                                      </h2>
                                      {item.category_name && (
                                        <p className="p-reg single-line-text category-name">
                                          {item.category_name}
                                        </p>
                                      )}
                                    </div>
                                  </div>
                                  {item.description && (
                                    <p className="p-reg truncate_2_lines">
                                      {item.description}
                                    </p>
                                  )}
                                </div>
                                {ElementDesign('snapShot', item)}
                              </div>
                            </>
                          )}
                        </>
                      ))}
                    {/* <div
                      className="session-item as-link card"
                      style={{padding: '0px 20px'}}>
                      <div style={{padding: '20px 0px'}}>
                        <div className="session-heading">
                          <h2>Invest in Ghana</h2>
                          <div className="events-place-like align-items-start">
                            <div className="w-100">
                              <span className="users-text usercount-type">
                                <img
                                  src={UserGroupMini}
                                  height={16}
                                  width={16}
                                  alt=""
                                />
                                <span className="userCount">3/1</span>
                                <span className="category-text">
                                  Investigator
                                </span>
                              </span>
                            </div>
                            <div className="session-share-favs">
                              <span className="event-heart as-link">
                                <i className="fas fa-share" />
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="persons-img-list">
                          <div className="img-item">
                            <img src={DEFAULT_IMAGE} alt="" />
                          </div>
                          <div className="img-item">
                            <img src={DEFAULT_IMAGE} alt="" />
                          </div>
                          <div className="img-item">
                            <img src={DEFAULT_IMAGE} alt="" />
                          </div>
                          <div className="img-item">
                            <img src={DEFAULT_IMAGE} alt="" />
                          </div>
                        </div>

                        <div className="persons-name-list">
                          <ul>
                            <li>
                              <span className="owner-icon">
                                <i className="fas fa-crown" />
                              </span>
                              Muhammad Ali
                            </li>
                            <li>
                              Muhammad Ali
                              <span className="speaker-icon">
                                <i className="fas fa-microphone" />
                              </span>
                            </li>
                            <li>Muhammad Ali</li>
                          </ul>
                        </div>

                        <div className="events-place-like session-address-wrapper mt-2">
                          <span className="address as-link">
                            <i className="fas fa-location-dot"></i> Linford
                            London.
                          </span>
                          <span>$10</span>
                          <div className="session-share-favs">
                            <span className="event-heart as-link">
                              <img
                                src={ShareablesYellowIcon}
                                alt="shareable-yellow"
                              />
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="as-link card-container">
                      <div className="padd-with-borderContaier">
                        <div className="d-flex align-items-center justify-content-between">
                          <div className="">
                            <p className="p-bold mb-1">Jasmine Patterson</p>
                            <p className="p-reg m-0">Business Consulting +2</p>
                          </div>
                          <img
                            src={require('../../Assets/images/default-img.jpg')}
                            className="mini-image"
                            alt="img"
                          />
                        </div>
                      </div>
                      <div className="events-place-like session-address-wrapper mt-2 p20-horizental">
                        <span className="address as-link">
                          <i className="fas fa-location-dot"></i> Linford
                          London.
                        </span>
                        <span>$10</span>
                        <div className="session-share-favs">
                          <span className="event-heart as-link">
                            <img
                              src={ShareablesYellowIcon}
                              alt="shareable-yellow"
                            />
                          </span>
                        </div>
                      </div>
                    </div>{' '} */}
                  </div>
                </>
              )}
            </div>
          )}

          {tabType === labels.PAGE[selected_lang] && (
            <div className="">
              {showLoader && <LoaderSpinner />}
              {showNoreordFound ? (
                <>
                  <div className="shareables-items">
                    {allData && Object.entries(allData)?.length > 0 &&
                      allData.map((item, index) => (
                        <>
                          {item.data_type === 'session' && (
                            <>
                              <ShareAblesSessions
                                key={`session-item-${index}`}
                                item={item}
                                heart={''}
                                componentDecesion={componentDecesion}
                                ElementDesign={PageDesign}
                              />
                              {/* {ElementDesign()} */}
                            </>
                          )}
                          {item.data_type === 'event' && (
                            <>
                              <ShreAblesEvent
                                key={`event-item-${index}`}
                                item={item}
                                index={index}
                                // eventDetails={eventDetails}
                                componentDecesion={componentDecesion}
                                ElementDesign={PageDesign}
                              />
                            </>
                          )}
                          {item.data_type === 'opportunity' && (
                            <>
                              <ShareAableOpportunity
                                key={`opportunity-item-${index}`}
                                setShowButton={setUnknownData}
                                onShare={() => {
                                  // setProperComponent('refer-opportunity');
                                }}
                                setChatData={setUnknownData}
                                handleItem={componentDecesion}
                                item={item}
                                ElementDesign={PageDesign}
                              />
                            </>
                          )}
                          {item.data_type === 'appointment' && (
                            <>
                              <div
                                className="refferals-list card-container as-link"
                                key={'www' + index}>
                                <div className="padd-with-borderContaier">
                                  <div className="refferals-list-item new-refferal-list mb-0">
                                    <div className="refferal-content">
                                      <h2>
                                        {item.creator_details &&
                                          fixName(
                                            item.creator_details.first_name,
                                            item.creator_details.last_name,
                                          )}
                                      </h2>
                                      <span className="refferal-auther">
                                        {item.appointment_type_title}
                                      </span>
                                      {/* <p>Think there may be some synergy</p> */}
                                      <span className="refferal-date">
                                        {/* {moment(item.start_date).format(
                                        'MM/DD/YYYY',
                                      )}{' '} */}
                                        {item.duration + 'min'}
                                      </span>
                                    </div>
                                    <div className="refferal-img">
                                      <img
                                        src={
                                          item.creator_details &&
                                          item.creator_details.profile_img
                                            ? item.creator_details.profile_img
                                            : DEFAULT_IMAGE
                                        }
                                        alt=""
                                      />
                                      <p className="refferal-info-que text-end">
                                        {item.cost ? '$' + item.cost : 'Free'}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                                {PageDesign('appointment', item)}
                              </div>
                            </>
                          )}

                          {item.data_type === 'group_chat' && (
                            <>
                              <div className="messages-users-list card-container">
                                <div
                                  key={`msg-${index}`}
                                  className={
                                    'message-user-item no-link padd-with-borderContaier m-0'
                                  }>
                                  <div
                                    className="user-img as-link"
                                    // onClick={() => {
                                    //   amplitudeEvent('OPEN_CHAT_ROOM');
                                    //   handleOpenChatRoom(item);
                                    // }}
                                  >
                                    <img
                                      src={
                                        item.group_image !== ''
                                          ? item.group_image
                                          : item[
                                              parseInt(item.creator_id) ===
                                              parseInt(
                                                localStorage.getItem('user'),
                                              )
                                                ? 'user_detail'
                                                : item.hasOwnProperty(
                                                    'creator_details',
                                                  )
                                                ? 'creator_details'
                                                : 'creator_detail'
                                            ]['profile_img']
                                          ? item[
                                              parseInt(item.creator_id) ===
                                              parseInt(
                                                localStorage.getItem('user'),
                                              )
                                                ? 'user_detail'
                                                : item.hasOwnProperty(
                                                    'creator_details',
                                                  )
                                                ? 'creator_details'
                                                : 'creator_detail'
                                            ]['profile_img']
                                          : DEFAULT_IMAGE
                                      }
                                      alt=""
                                    />
                                    {item.chat_type === 'question' && (
                                      <span>
                                        <i className="fa-solid fa-q"></i>
                                      </span>
                                    )}
                                    {item.chat_type === 'opportunity' && (
                                      <span className="icon icon-posts"></span>
                                    )}
                                    {item.chat_type === 'event' && (
                                      <span className="icon-tickets"></span>
                                    )}
                                    {item.chat_type === 'session' && (
                                      <span className="icon-video-camera-2"></span>
                                    )}
                                    {/* <span className="fa-solid fa-shop"></span> */}
                                  </div>
                                  <div
                                    className="user-content as-link"
                                    onClick={() => {
                                      // handleOpenChatRoom(item);
                                    }}>
                                    <h2 className="single-line-text">
                                      {/* {tabType === 'subInvites' &&
                                      !item.hasOwnProperty('read') */}
                                      {item.group_name !== ''
                                        ? item.group_name
                                        : fixName(
                                            item[
                                              parseInt(item.creator_id) ===
                                              parseInt(
                                                localStorage.getItem('user'),
                                              )
                                                ? 'user_detail'
                                                : item.hasOwnProperty(
                                                    'creator_details',
                                                  )
                                                ? 'creator_details'
                                                : 'creator_detail'
                                            ]['first_name'],
                                            item[
                                              parseInt(item.creator_id) ===
                                              parseInt(
                                                localStorage.getItem('user'),
                                              )
                                                ? 'user_detail'
                                                : item.hasOwnProperty(
                                                    'creator_details',
                                                  )
                                                ? 'creator_details'
                                                : 'creator_detail'
                                            ]['last_name'],
                                          )}
                                    </h2>

                                    {formatString(item.last_message)}

                                    <div className="message-date-info">
                                      <span className="message-date">
                                        <Moment format="MM/DD/YY HH:mm A">
                                          {item.last_message_time
                                            ? item.last_message_time
                                            : item.created_at}
                                        </Moment>
                                      </span>
                                      {/* {renderMessageUsers(item.moreUsers)} */}
                                    </div>
                                  </div>

                                  {!item.hasOwnProperty('invitation_type') &&
                                    item.hasOwnProperty('group_detail') && (
                                      <OtherUserImages
                                        otherUsers={filteringUsers(
                                          item.audience_detail.concat(
                                            item.moderator_detail,
                                            item.creator_detail
                                              ? [item.creator_detail]
                                              : [item.creator_details],
                                          ),
                                          arrayColumn(item.group_detail, 'id'),
                                        )}
                                      />
                                    )}
                                  {!item.hasOwnProperty('invitation_type') &&
                                    !item.hasOwnProperty(
                                      'opportunity_invitaion_status',
                                    ) &&
                                    item.hasOwnProperty('opportunity_id') &&
                                    item.chat_type === 'opportunity' && (
                                      <OtherUserImages
                                        otherUsers={filteringUsers(
                                          item.other_persons_opportunity_chat_room.concat(
                                            item.team_member_details,
                                            item.creator_detail
                                              ? [item.creator_detail]
                                              : [item.creator_details],
                                          ),
                                          arrayColumn(
                                            [
                                              ...item.other_persons_opportunity_chat_room,
                                              ...item.team_member_details,
                                              item.creator_details,
                                            ],
                                            'id',
                                          ),
                                        )}
                                      />
                                    )}
                                </div>
                                {PageDesign('group_chat', item)}
                              </div>
                            </>
                          )}
                          {item.data_type === 'accomplishment' && (
                            <>
                              <div
                                className="card-container shareables-snapshots"
                                key={index + 'userListing'}>
                                <div className="padd-with-borderContaier as-link">
                                  <div className=" d-flex align-items-center mb-3">
                                    <div className="snapshotsImage as-link">
                                      <img src={item.images[0]} alt="" />
                                    </div>
                                    <div
                                      className="snapshots-content as-link"
                                      onClick={() => {
                                        // setSnapShotId(item);
                                        // setShowComponent('SnapShotDetail');
                                      }}>
                                      <h2 className="single-line-text">
                                        {item.title}
                                      </h2>
                                      {item.category_name && (
                                        <p className="p-reg single-line-text category-name">
                                          {item.category_name}
                                        </p>
                                      )}
                                    </div>
                                  </div>
                                  {item.description && (
                                    <p className="p-reg truncate_2_lines">
                                      {item.description}
                                    </p>
                                  )}
                                </div>
                                {PageDesign('snapshot', item)}
                              </div>
                            </>
                          )}
                        </>
                      ))}
                  </div>
                </>
              ) : (
                props.dropdownValue.includes('Workspace') && (
                  <div className="shareables-items">
                    <div className="as-link card-container">
                      <div className="padd-with-borderContaier">
                        <div className="profile-img-wrapper">
                          <div className="profile-img">
                            <img
                              src={
                                userData &&
                                userData.data &&
                                userData.data.profile_img
                                  ? userData.data.profile_img
                                  : DEFAULT_IMAGE
                              }
                              alt=""
                            />
                          </div>

                          <div className="profile-name">
                            <div className="profile-name-headings">
                              <div className="">
                                <h2 className="m-0 mb-1 p-bold">
                                  {userData &&
                                    userData.data &&
                                    userData.data.first_name}
                                  {userData &&
                                    userData.data &&
                                    userData.data.last_name}
                                </h2>
                                {mineAreaUser.length > 0 && (
                                  <h3 className="p-reg m-0">
                                    {mineAreaUser.join(', ')}
                                  </h3>
                                )}
                              </div>
                            </div>
                            <div className="events-icon dark d-flex align-items-center justify-content-between">
                              <ul>
                                <li>
                                  <span className="as-link">
                                    <i className="icon-applaud" />
                                  </span>
                                  <span className="event-icon-text">10%</span>
                                </li>

                                <li>
                                  <span className="as-link icon">
                                    <i className="icon-circles" />
                                  </span>
                                </li>
                              </ul>

                              <div className="d-flex align-items-center as-link">
                                <ul>
                                  <li>
                                    <span className="as-link icon">
                                      <i className="fas fa-share" />
                                    </span>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="profile-content-infos">
                          <div className="skills">
                            {CategoryOfUser.join(', ')}
                          </div>

                          <div className="other-infos">
                            <div className="profile-address as-link profile-address">
                              <i className="fas fa-home" />
                              {userData &&
                              userData.data &&
                              userData.data.home_location
                                ? userData.data.home_location
                                : 'Add Location'}
                            </div>
                            <div className="profile-address as-link profile-address">
                              <i className="icon-aroplane" />
                              {userData &&
                              userData.data &&
                              userData.data.current_location
                                ? userData.data.current_location
                                : 'Add Location'}
                            </div>
                          </div>
                          <p className="p-reg m-0">
                            {userData && userData.data && userData.data.bio}
                          </p>

                          <div className="social-icons">
                            <ul>
                              {userData.data &&
                                userData.data.instagram !== '' && (
                                  <li>
                                    <a
                                      href={userData.data.instagram}
                                      target="_blank"
                                      rel="noopener noreferrer">
                                      <i className="fab fa-instagram" />
                                    </a>
                                  </li>
                                )}
                              {userData.data && userData.data.twitter !== '' && (
                                <li>
                                  <a
                                    href={userData.data.twitter}
                                    target="_blank"
                                    rel="noopener noreferrer">
                                    <i className="fab fa-twitter" />
                                  </a>
                                </li>
                              )}
                              {userData.data && userData.data.youtube !== '' && (
                                <li>
                                  <a
                                    href={userData.data.youtube}
                                    target="_blank"
                                    rel="noopener noreferrer">
                                    <i className="fab fa-youtube" />
                                  </a>
                                </li>
                              )}
                              {userData.data && userData.data.web !== '' && (
                                <li>
                                  <a
                                    href={userData.data.web}
                                    target="_blank"
                                    rel="noopener noreferrer">
                                    <i className="icon-link" />
                                  </a>
                                </li>
                              )}
                              {userData.data &&
                                userData.data.linkedin !== '' && (
                                  <li>
                                    <a
                                      href={userData.data.linkedin}
                                      target="_blank"
                                      rel="noopener noreferrer">
                                      <i className="fa-brands fa-linkedin" />
                                    </a>
                                  </li>
                                )}
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className="mt-2 p20-horizental show-on-public">
                        <div className="d-flex align-items-center justify-content-between mb-2">
                          <p className="p-reg m-0">Show on Public Page</p>
                          <div>
                            <Form.Check
                              type="switch"
                              className="switchBlock"
                              id="custom-switch"
                            />
                          </div>
                        </div>
                        <div className="d-flex align-items-center justify-content-between mb-2">
                          <p className="p-reg m-0">Personality Type</p>
                          <div>
                            <Form.Check
                              type="switch"
                              className="switchBlock"
                              id="custom-switch"
                            />
                          </div>
                        </div>
                        <div className="d-flex align-items-center justify-content-between mb-2">
                          <p className="p-reg m-0">Feedback</p>
                          <div>
                            <Form.Check
                              type="switch"
                              className="switchBlock"
                              id="custom-switch"
                            />
                          </div>
                        </div>
                        <div className="d-flex align-items-center justify-content-between mb-2">
                          <p className="p-reg m-0">Industry</p>
                          <div>
                            <Form.Check
                              type="switch"
                              className="switchBlock"
                              id="custom-switch"
                            />
                          </div>
                        </div>
                        <div className="d-flex align-items-center justify-content-between mb-2">
                          <p className="p-reg m-0">Home and Travel</p>
                          <div>
                            <Form.Check
                              type="switch"
                              className="switchBlock"
                              id="custom-switch"
                            />
                          </div>
                        </div>
                        <div className="d-flex align-items-center justify-content-between mb-2">
                          <p className="p-reg m-0">Bio</p>
                          <div>
                            <Form.Check
                              type="switch"
                              className="switchBlock"
                              id="custom-switch"
                            />
                          </div>
                        </div>
                        <div className="d-flex align-items-center justify-content-between mb-2">
                          <p className="p-reg m-0">Social Media</p>
                          <div>
                            <Form.Check
                              type="switch"
                              className="switchBlock"
                              id="custom-switch"
                            />
                          </div>
                        </div>
                        <div className="d-flex align-items-center justify-content-between mb-2">
                          <p className="p-reg m-0">Sessions</p>
                          <div>
                            <Form.Check
                              type="switch"
                              className="switchBlock"
                              id="custom-switch"
                            />
                          </div>
                        </div>
                        <div className="d-flex align-items-center justify-content-between mb-2">
                          <p className="p-reg m-0">Events</p>
                          <div>
                            <Form.Check
                              type="switch"
                              className="switchBlock"
                              id="custom-switch"
                            />
                          </div>
                        </div>
                        <div className="d-flex align-items-center justify-content-between mb-2">
                          <p className="p-reg m-0">Opportunities</p>
                          <div>
                            <Form.Check
                              type="switch"
                              className="switchBlock"
                              id="custom-switch"
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="as-link card-container donation-block-wrapper">
                      <div className="padd-with-borderContaier">
                        <p>
                          If you had a great experience with [name], show your
                          appreciation through a cup of coffee.{' '}
                        </p>
                        <div className="donation-form">
                          <Form>
                            <div className="coffee-field">
                              <div className="numbers-field">
                                <span className="coffee-cup">
                                  <img src={coffeecup} alt="" />
                                </span>
                                <span className="multiply">X</span>
                                <div className="numbers">
                                  <span className="active">1</span>
                                  <span>2</span>
                                  <span className="">3</span>
                                </div>
                                <div className="">
                                  <input
                                    className="form-control"
                                    type="number"
                                    min={1}
                                  />
                                </div>
                              </div>
                              <span className="donation-amount">$5</span>
                            </div>
                            <div className="payment-btns">
                              <Button className="apple-pay">
                                <i className="fab fa-apple" />
                                {labels.pay_text[selected_lang]}
                              </Button>
                              <Button className="pay-with-card">
                                {labels.pay_with_card[selected_lang]}
                              </Button>
                            </div>
                          </Form>
                        </div>
                      </div>
                      <div className="mt-2 p20-horizental show-on-public">
                        <div className="d-flex align-items-center justify-content-between">
                          <p className="p-reg m-0">Show on Public Page</p>
                          <div>
                            <Form.Check
                              type="switch"
                              className="switchBlock"
                              id="custom-switch"
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="as-link card-container donation-block-wrapper">
                      <div className="padd-with-borderContaier">
                        <div className="gallery-imgs">
                          <Row>
                            {snapShopData && Object.entries(snapShopData)?.length > 0 &&
                              snapShopData.map((item, index) => (
                                <Col md={4} sm={4} key={index}>
                                  <div className="gallery-item">
                                    <img src={item.images[0]} alt="" />
                                  </div>
                                </Col>
                              ))}
                          </Row>
                        </div>
                      </div>
                      <div className="mt-2 p20-horizental show-on-public">
                        <div className="d-flex align-items-center justify-content-between">
                          <p className="p-reg m-0">Show on Public Page</p>
                          <div>
                            <Form.Check
                              type="switch"
                              className="switchBlock"
                              id="custom-switch"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )
              )}
            </div>
          )}

          {tabType === labels.MESSAGES[selected_lang] && (
            <>
              {Messages.length > 0 && (
                <div className="shareables-items">
                  <div className="filter-btn-wrapper d-flex justify-content-center mb-3">
                    <Button className="btn-dark" type="submit">
                      Add Message
                    </Button>
                  </div>
                  <div className="card-container">
                    <div className="padd-with-borderContaier as-link">
                      <div className="tab-select">
                        <div className="select-wrapper as-link">
                          <select className="form-control text-align-left">
                            <option>Session Name</option>
                            <option>Custom Link</option>
                            <option>sdgdf</option>
                          </select>
                        </div>
                      </div>
                      <div className="d-flex align-items-center page-snapshots mt-3">
                        <p className="p-reg m-0">
                          Hey [first_name]! I’m so excited about you joining
                          [session_name]. See you soon!!
                        </p>
                      </div>
                    </div>
                    <div className="d-flex align-items-center justify-content-between mt-2 p20-horizental">
                      <p className="p-reg m-0 preview-message as-link">
                        Preview Message
                      </p>
                      <div className="d-flex align-items-center">
                        <img
                          className="ScanQr as-link"
                          src={ShareYellowIcon}
                          alt="scanQr"
                        />
                        <img
                          className="sharableYellow as-link"
                          src={ShareablesYellowIcon}
                          alt="shareable"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {Messages.length === 0 && (
                <div className="no-results-wrapper mt-5">
                  <div className="img-bg-wrapper">
                    <img
                      src={require('../../Assets/images/norecord/no-convos.webp')}
                      alt=""
                    />
                  </div>
                  <div className="no-results-content">
                    <h2>{labels.NO_INVITES_PENDING[selected_lang]}</h2>
                    <p>{labels.NO_INVITES_PENDING_INFO[selected_lang]}</p>
                  </div>
                </div>
              )}
            </>
          )}

          {/* no-records-below */}
          {(tabType === labels.PAGE[selected_lang] ||
            tabType === labels.ELEMENT[selected_lang]) &&
            allData &&
            allData.length === 0 && (
              <>
                {!showNoreordFound &&
                  props.dropdownValue.includes('Sessions') && (
                    <div className="no-results-wrapper">
                      <div className="img-bg-wrapper">
                        <img
                          src={require('../../Assets/images/norecord/session.png')}
                          alt="no-session"
                        />
                      </div>

                      <div className="no-results-content">
                        <h2>Manage Sessions</h2>
                        <p>Meetup with your network virtually</p>

                        <div className="btn-wrapper mt-3">
                          <Button className="btn btn-dark">
                            <Link
                              className="text-light"
                              style={{textDecoration: 'none'}}
                              to={{
                                pathname: '/',
                                state: {
                                  typeIs: 'sessionss',
                                  data: 'createSession',
                                },
                              }}>
                              Create Session
                            </Link>
                          </Button>
                        </div>
                      </div>
                    </div>
                  )}
                {!showNoreordFound &&
                  props.dropdownValue.includes('Events') && (
                    <div className="no-results-wrapper">
                      <div className="img-bg-wrapper">
                        <img
                          src={require('../../Assets/images/norecord/event.png')}
                          alt=""
                        />
                      </div>

                      <div className="no-results-content">
                        <h2>{labels.MANAGE_EVENTS[selected_lang]}</h2>
                        <p>{labels.Connect_Network[selected_lang]}</p>

                        <div className="btn-wrapper mt-3">
                          <Button className="btn btn-dark">
                            <Link
                              className="text-light"
                              style={{textDecoration: 'none'}}
                              to={{
                                pathname: '/',
                                state: {
                                  typeIs: 'events',
                                  data: 'show_create_event',
                                },
                              }}>
                              Create Event
                            </Link>
                          </Button>
                        </div>
                      </div>
                    </div>
                  )}
                {!showNoreordFound &&
                  props.dropdownValue.includes('Opportunities') && (
                    <div className="no-results-wrapper">
                      <div className="img-bg-wrapper">
                        <img
                          src={require('../../Assets/images/norecord/opportunity.png')}
                          alt=""
                        />
                      </div>

                      <div className="no-results-content">
                        <h2>{labels.Share_Opportunity[selected_lang]}</h2>
                        <p>{labels.Someone_Waiting[selected_lang]}</p>

                        <div className="btn-wrapper mt-3">
                          <Button
                            className="btn btn-dark"
                            onClick={() => {
                              props.setProperComponent('inviteUser');
                            }}>
                            {labels.Create_Opportunity[selected_lang]}
                          </Button>
                        </div>
                      </div>
                    </div>
                  )}
                {!showNoreordFound &&
                  props.dropdownValue.includes('Appointments') && (
                    <div className="no-results-wrapper no-appointments mt-5">
                      <div className="img-bg-wrapper">
                        <img
                          src={require('../../Assets/images/norecord/no-appointments.png')}
                          alt=""
                        />
                      </div>
                      <div className="no-results-content">
                        <p className="noRecord-title">No Upcoming 1-on-1s</p>
                        <p className="noRecord-desc">
                          You don't have any upcoming appointments
                        </p>

                        <div className="btn-wrapper mt-3">
                          <Button
                            className={
                              'btn btn-dark'
                              // getItem('userRole') === 'guest'
                              //   ? 'grey-class-btn'
                              //   : 'btn btn-dark'
                            }>
                            <Link
                              // onClick={e => {
                              //   loginUserRole(e);
                              // }}
                              className="text-light"
                              style={{
                                textDecoration: 'none',
                              }}
                              to={{
                                pathname: '/builders',
                                state: {
                                  typeIs: 'search',
                                },
                              }}>
                              Explore Builders
                            </Link>
                          </Button>
                        </div>
                      </div>
                    </div>
                  )}
                {!showNoreordFound &&
                  props.dropdownValue.includes('Snapshots') && (
                    <div className="no-results-wrapper">
                      <div className="img-bg-wrapper">
                        <img
                          src={require('../../Assets/images/norecord/no-snapshot.png')}
                          alt=""
                        />
                      </div>

                      <div className="no-results-content">
                        <h2>Manage Credentials</h2>
                        <p>
                          Create shareable one-pagers for your business(es),
                          affiliations and more
                        </p>

                        <div className="btn-wrapper mt-3">
                          <Button
                            className="btn btn-dark"
                            onClick={() => {
                              props.setProperComponent('create-snapshot');
                            }}>
                            Add Credentials
                          </Button>
                        </div>
                      </div>
                    </div>
                  )}
                {!showNoreordFound && props.dropdownValue.includes('Links') && (
                  <div className="no-results-wrapper">
                    <div className="img-bg-wrapper">
                      <img
                        src={require('../../Assets/images/svg-icons/link.png')}
                        alt=""
                      />
                    </div>

                    <div className="no-results-content">
                      <h2>No Links Available</h2>
                      <p>
                        Add links to quickly promote things that matter most
                      </p>

                      <div className="btn-wrapper mt-3">
                        <Button
                          className="btn btn-dark"
                          onClick={() => {
                            //  setAddNewSnapshot(true);
                          }}>
                          Add Link
                        </Button>
                      </div>
                    </div>
                  </div>
                )}
                {!showNoreordFound &&
                  props.dropdownValue.includes('Group Chats') && (
                    <div className="no-results-wrapper">
                      <div className="img-bg-wrapper">
                        <img
                          src={require('../../Assets/images/svg-icons/noGroup.png')}
                          alt=""
                        />
                      </div>

                      <div className="no-results-content">
                        <h2>No Active Group Chats</h2>
                        <p>Create a group chat to collaborate with others</p>

                        <div className="btn-wrapper mt-3">
                          <Button className="btn btn-dark">
                            <Link
                              // onClick={e => {
                              //   loginUserRole(e);
                              // }}
                              className="text-light"
                              style={{
                                textDecoration: 'none',
                              }}
                              to={{
                                pathname: '/messages',
                                state: {
                                  typeIs: 'create-group',
                                },
                              }}>
                              {' '}
                              Create Group Chat
                            </Link>
                          </Button>
                        </div>
                      </div>
                    </div>
                  )}
                {!showNoreordFound && props.dropdownValue.includes('Forms') && (
                  <div className="no-results-wrapper">
                    <div className="img-bg-wrapper">
                      <img src={NoForm} alt="" />
                    </div>

                    <div className="no-results-content">
                      <h2>No Forms Added</h2>
                      <p>Create forms to learn more and add to your network</p>

                      <div className="btn-wrapper">
                        <Button
                          className="btn btn-dark"
                          onClick={() => {
                            //  setAddNewSnapshot(true);
                          }}>
                          Add Form
                        </Button>
                      </div>
                    </div>
                  </div>
                )}
                {!showNoreordFound &&
                  props.dropdownValue.includes('Network') && (
                    <div className="no-results-wrapper">
                      <div className="img-bg-wrapper">
                        <img
                          src={require('../../Assets/images/svg-icons/network.png')}
                          alt=""
                        />
                      </div>

                      <div className="no-results-content">
                        <h2>No Contacts Added</h2>
                        <p>
                          Add contacts to become more efficient in the way you
                          interact
                        </p>

                        <div className="btn-wrapper mt-3">
                          <Button
                            className="btn btn-dark"
                            onClick={() => {
                              //  setAddNewSnapshot(true);
                            }}>
                            Add Contact
                          </Button>
                        </div>
                      </div>
                    </div>
                  )}

                {!showNoreordFound && props.dropdownValue === 'Gifts' && (
                  <div className="no-results-wrapper">
                    <div className="img-bg-wrapper">
                      <img
                        src={require('../../Assets/images/norecord/gifts2.png')}
                        alt=""
                      />
                    </div>

                    <div className="no-results-content">
                      <h2>No Gifts Available</h2>
                      <p>
                        Keep people excited and motivated while connecting with
                        your network
                      </p>

                      {/* <div className="btn-wrapper">
                          <Button
                            className="btn btn-dark"
                            onClick={() => {
                              //  setAddNewSnapshot(true);
                            }}>
                            Add Record
                          </Button>
                        </div> */}
                    </div>
                  </div>
                )}
                {!showNoreordFound && props.dropdownValue === 'Offerings' && (
                  <div className="no-results-wrapper">
                    <div className="img-bg-wrapper">
                      <img
                        src={require('../../Assets/images/norecord/offerings.png')}
                        alt=""
                      />
                    </div>

                    <div className="no-results-content">
                      <h2>No Offerings Available</h2>
                      <p>
                        Quickly share what your business offers to increase
                        sales
                      </p>

                      {/* <div className="btn-wrapper">
                          <Button
                            className="btn btn-dark"
                            onClick={() => {
                              //  setAddNewSnapshot(true);
                            }}>
                            Add Record
                          </Button>
                        </div> */}
                    </div>
                  </div>
                )}
                {!showNoreordFound && props.dropdownValue === 'Payments' && (
                  <div className="no-results-wrapper">
                    <div className="img-bg-wrapper">
                      <img src={Dollar} alt="" />
                    </div>

                    <div className="no-results-content">
                      <h2>No Payment Options Available</h2>
                      <p>
                        Provide payment options to quickly collect funds from
                        your network
                      </p>

                      {/* <div className="btn-wrapper">
                          <Button
                            className="btn btn-dark"
                            onClick={() => {
                              //  setAddNewSnapshot(true);
                            }}>
                            Add Record
                          </Button>
                        </div> */}
                    </div>
                  </div>
                )}
                {!showNoreordFound && props.dropdownValue === 'Polls' && (
                  <div className="no-results-wrapper">
                    <div className="img-bg-wrapper">
                      <img
                        src={require('../../Assets/images/norecord/polls.png')}
                        alt=""
                      />
                    </div>

                    <div className="no-results-content">
                      <h2>No Polls Available</h2>
                      <p>Ask quick questions to gain impactful insight</p>

                      {/* <div className="btn-wrapper">
                          <Button
                            className="btn btn-dark"
                            onClick={() => {
                              //  setAddNewSnapshot(true);
                            }}>
                            Add Record
                          </Button>
                        </div> */}
                    </div>
                  </div>
                )}

                {!showNoreordFound &&
                  tabType === labels.ELEMENT[selected_lang] &&
                  (props.dropdownValue.includes('Workspace') ||
                    props.dropdownValue.includes('All')) && (
                    <div className="no-results-wrapper mb-3">
                      <div className="img-bg-wrapper">
                        <img
                          src={require('../../Assets/images/norecord/shareable.png')}
                          alt="no-session"
                        />
                      </div>

                      <div className="no-results-content">
                        <h2>No ShareAbles Available</h2>
                        <p>
                          Aadd ShareAbles to increase engagement, revenue and
                          measure what matters
                        </p>
                      </div>
                    </div>
                  )}
              </>
            )}

          {/* no-records-end */}
        </Scrollbars>
        {props.model === 'link_model' && (
          <CustomModal
            position="bottom"
            className={'share-links-modal'}
            onClose={() => props.setModel('')}>
            <div className="camera-options-list w-100 social-links-modal">
              <ul>
                <li className="as-link">
                  <FacebookShareButton url={props.shareUrl} title={false}>
                    <i className="fa-brands fa-facebook"></i>
                  </FacebookShareButton>
                </li>
                <li className="as-link">
                  <WhatsappShareButton url={props.shareUrl}>
                    <i className="fa-brands fa-whatsapp"></i>
                  </WhatsappShareButton>
                </li>
                <li className="as-link">
                  <EmailShareButton url={props.shareUrl}>
                    <i className="fa-solid fa-envelope"></i>
                  </EmailShareButton>
                </li>
                <li className="as-link">
                  <TwitterShareButton url={props.shareUrl}>
                    <i className="fa-brands fa-twitter"></i>
                  </TwitterShareButton>
                </li>
                <li className="as-link">
                  <LinkedinShareButton url={props.shareUrl}>
                    <i className="fa-brands fa-linkedin"></i>
                  </LinkedinShareButton>
                </li>
              </ul>
            </div>
          </CustomModal>
        )}
      </div>
    </>
  );
};

export default Shareables;
