import React, {useState, useEffect} from 'react';
import {Button, Dropdown} from 'react-bootstrap';
import {paypal, zPayment, vPayment, dollar} from '../../Constants/Images';

import img from '../../Assets/images/slider-img.jpeg';

import Slider from 'react-slick';
import RenderSessions from '../HomePage/RenderSessions';
import RenderOpportunities from '../HomePage/RenderOpportunities';
import RenderTransformations from '../HomePage/RenderTransformations';
import {useSelector, useDispatch} from 'react-redux';
import {getUserCompanyRecruitment} from '../../Store/Actions/OpportunityAction';
import {getCompanyMeetUpList} from '../../Store/Actions/SessionAction';
import {getCompanyTransformationList} from '../../Store/Actions/TranformationAction';
import {parseUrl} from '../../Utils/commonUtils';
import commonApi from '../../Services/CommonService';
import RenderOferrings from '../HomePage/RenderOferrings';
import {getCompanyOfferings} from '../../Store/Actions/BusinessProfileAction';

const ProfileTabs = props => {
  const {pathname, search} = window.location;
  const urlParts = pathname.split('/').filter(Boolean);
  const meetupData = useSelector(state => state.companyMeetupListing);
  const recruitmentData = useSelector(state => state.userCompanyRecruitment);

  const companyOfferings = useSelector(state => state.companyOferringsList);
  const transformationList = useSelector(
    state => state.companyTransformationList,
  );
  const searchParams = new URLSearchParams(search);
  const hasRecruitmentTypeParam = searchParams.has('recruitment');
  const [tabType, setTabType] = useState('Offerings');
  const [subTabType, setSubTabType] = useState('services');
  const urlString = String(window.location);
  const urlData = parseUrl(urlString);
  const dispatch = useDispatch();

  const [eventTabType, setEventTabType] = useState(
    hasRecruitmentTypeParam
      ? 'recruitment'
      : meetupData.length > 0
      ? 'meetup'
      : 'recruitment',
  );
  // const [transformationList, setTransformationList] = useState([]);

  const [type, setType] = useState(1);

  const get_company_transformation = urlData => {
    // const filteredArray =
    //   transformation_Filter.length > 0 &&
    //   transformation_Filter.filter(item => item !== 'All');

    const payload = {
      user_id:
        /^\d+$/.test(urlParts[0]) && !urlParts[1]
          ? urlParts[0]
          : /^\d+$/.test(urlData.userId)
          ? urlData.userId
          : '',
      category_ids: [],
      company_name: !/^\d+$/.test(urlParts[0])
        ? urlParts[0].replace('-', ' ')
        : '',
    };
    dispatch(getCompanyTransformationList(payload));

    dispatch(getCompanyOfferings(payload));

    // commonApi
    //   .get_company_transformation(payload)
    //   .then(res => {
    //     if (res.status === 200) {
    //       setTransformationList(res.transformation);

    //       if (res.transformation.length === 0) {
    //         setTabType('Offerings');

    //       }
    //       // props.setDataofTransFormation(res.transformation)
    //     }
    //   })
    //   .catch(err => {
    //     console.log('Err', err);
    //     return {type: 'error', message: err.message};
    //   });
  };

  const allProductSlider = {
    dots: true,
    arrows: false,
    infinite: false,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
  };

  const allProductSliderData = [
    {
      name: 'Cupcake Catering',
      price: 'Starting $1,250',
    },

    {
      name: 'Caller Headsets',
      price: 'Starting $2,100',
    },
    {
      name: 'Cupcake Catering',
      price: 'Starting $1,250',
    },

    {
      name: 'Caller Headsets',
      price: 'Starting $2,100',
    },
  ];

  useEffect(() => {
    get_company_transformation(urlData);

    getMeetupData(urlData);
    getUserCompanyRecruitmentData(urlData);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (transformationList.length > 0) {
      setTabType('services');
    }
  }, [transformationList]);

  const getUserCompanyRecruitmentData = urldata => {
    const data = {
      company_name: urlParts[0] ? urlParts[0].replace('-', ' ') : '',
      user_id: urlParts[0] && !urlParts[1] ? '' : urlData.userId,
      search_query: '',
      opportunity_type_id: urldata.type === 'recruitment' ? urldata.typeId : '',
    };

    dispatch(getUserCompanyRecruitment(data));
  };

  const getMeetupData = urldata => {
    const data = {
      company_name: urlParts[0] ? urlParts[0].replace('-', ' ') : '',
      user_id: urlParts[0] && !urlParts[1] ? '' : urlData.userId,
      search_query: '',
      category_id: urldata.type === 'meetup' ? urldata.typeId : '',
    };

    dispatch(getCompanyMeetUpList(data));
  };

  useEffect(() => {
    if (meetupData.length > 0) {
      setEventTabType('meetup');
    }
  }, [meetupData]);

  // const eventSlider = {
  //   // className: "center",
  //   // centerMode: true,
  //   dots: true,
  //   arrows: false,
  //   infinite: false,
  //   // centerPadding : "60px",
  //   speed: 500,
  //   slidesToShow: 1,
  //   slidesToScroll: 1,
  //   // adaptiveHeight: true,
  // };

  // const allEventData = [
  //   {
  //     name: 'Cupcake Catering',
  //     price: 'Starting $1,250',
  //   },

  //   {
  //     name: 'Caller Headsets',
  //     price: 'Starting $2,100',
  //   },
  //   {
  //     name: 'Cupcake Catering',
  //     price: 'Starting $1,250',
  //   },

  //   {
  //     name: 'Caller Headsets',
  //     price: 'Starting $2,100',
  //   },
  // ];
  const rolesTypes = [{id: 1, name: 'Transformations'}];


  return (
    <>
      <div className="profile-block-sidebar">
        <div
          className="profile-top-card"
          style={{
            height: transformationList.length === 0 ? '181px' : 'auto',
          }}>
          <div className="messages-header mb-2">
            <div className="event-tabs tabs-style-2 mb-2">
              <ul>
                {transformationList?.length > 0 && (
                  <li>
                    <Button
                      className={tabType === 'services' ? 'active' : ''}
                      onClick={() => {
                        setTabType('services');
                      }}>
                      Portfolio
                    </Button>
                  </li>
                )}

                {!/^\d+$/.test(urlParts[0]) && companyOfferings.length > 0 && (
                  <li>
                    <Button
                      className={tabType === 'Offerings' ? 'active' : ''}
                      onClick={() => {
                        setTabType('Offerings');
                      }}>
                      Offerings
                    </Button>
                  </li>
                )}

                {/* <li>
                  <Button
                    className={tabType === 'Contacts' ? 'active' : ''}
                    onClick={() => {
                      setTabType('Contacts');
                    }}>
                    Contacts
                  </Button>
                </li> */}
              </ul>
            </div>
          </div>

          {tabType === 'services' && transformationList?.length > 0 && (
            <div className="portfolio-tab-screen">
              <div className="menus-dropdowns profile_page_dropdown">
                <Dropdown
                  className="select-wrapper"
                  onSelect={(eventKey, event) => {
                    setType(eventKey);
                   
                  }}
                  drop="down">
                  <Dropdown.Toggle variant="primary">
                    {type
                      ? rolesTypes.find(item => item.id === Number(type)).name
                      : 'Select Role'}
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    {rolesTypes.map((item, index) => (
                      <Dropdown.Item
                        key={`sessionCategories-${index}`}
                        eventKey={item.id.toString()}
                        active={item.id === type}>
                        {item.name}
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
              </div>

              <RenderTransformations
                setDataofTransFormation={props.setDataofTransFormation}
                setShowModal={props.setShowModal}
              />
              {/* <div className="mt-4">
                <Slider {...allProductSlider}>
                  {allProductSliderData.length > 0 &&
                    allProductSliderData.map((item, index) => (
                      <div className="slider-image mr-1">
                        <img src={img} alt="" />
                      </div>
                    ))}
                </Slider>
              </div> */}

              {/* <div className="show-more-btn">show more</div> */}
            </div>
          )}

          {tabType === 'Contacts' && (
            <div className="portfolio-tab-screen">
              <p>Under Development</p>
            </div>
          )}

          {tabType === 'Offerings' && (
            // <div className="portfolio-tab-screen">
            <RenderOferrings />
            // </div>
          )}
        </div>

        {!props.userProfile && (
          <>
            {(meetupData.length > 0 || recruitmentData.length > 0) && (
              <div className="profile-middle-card">
                <div className="messages-header mb-2">
                  <div className="event-tabs tabs-style-2 mb-2">
                    <ul>
                      {meetupData.length > 0 && (
                        <li>
                          <Button
                            className={
                              eventTabType === 'meetup' ? 'active' : ''
                            }
                            onClick={() => {
                              //   setTabType('services');
                              setEventTabType('meetup');
                            }}>
                            Meetups
                          </Button>
                        </li>
                      )}
                      {recruitmentData.length > 0 && (
                        <li>
                          <Button
                            className={
                              eventTabType === 'recruitment' ? 'active' : ''
                            }
                            onClick={() => {
                              setEventTabType('recruitment');
                            }}>
                            Recruitments
                          </Button>
                        </li>
                      )}
                    </ul>
                  </div>
                </div>

                {eventTabType === 'meetup' && (
                  // <></>
                  <RenderSessions
                    options={{showSessions: true, showEvents: true}}
                    user={props.user}
                    setShowModal={props.setShowModal}
                    setDataofSession={props.setDataofSession}
                  />

                  //   <div className="profile-meetups-tab">
                  //   <div className=" mt-4">
                  //     <Slider {...eventSlider}>
                  //       {allEventData.length > 0 &&
                  //         allEventData.map((item, index) => (
                  //           <div>
                  //             <div className='event-card'>
                  //               <div className='event-date'> Sat, Jul 21 @ 5:30 PM </div>

                  //               <h1> World Disability Day</h1>
                  //               <div className="event-slider-image ">
                  //                 <img src={event_img} alt="" />
                  //               </div>

                  //               <div className='event-type'> <img className='d-inline mr-1' src={profileIcon} alt="" />165 Networking and Referrals</div>

                  //               <div className="event-location">
                  //                 <span>
                  //                 <i style={{color:'#9B9787',marginRight:'5px'}}className="fas fa-location-dot"></i>
                  //                   Rockville, MD</span>

                  //                 <span>$25</span>
                  //               </div>

                  //               <div className="card-button">
                  //                 <Button
                  //                   className="new-btn btn-green"
                  //                   onClick={() => {}}>
                  //                   Purchase Ticket
                  //                 </Button>
                  //               </div>
                  //             </div>
                  //           </div>
                  //         ))}
                  //     </Slider>
                  //   </div>

                  //   <div className="show-more-btn">show more</div>
                  // </div>
                )}

                {eventTabType === 'recruitment' && (
                  <RenderOpportunities
                    user={props.user}
                    setShowModal={props.setShowModal}
                    setDataofRecruitment={props.setDataofRecruitment}
                  />
                )}
              </div>
            )}

            <div className="profile-bottom-card">
              <div className="messages-header mb-2">
                <div className="event-tabs tabs-style-2 mb-2">
                  <ul>
                    <li>
                      <Button
                        className={subTabType === 'services' ? 'active' : ''}
                        onClick={() => {
                          //   setTabType('services');
                        }}>
                        Quick Payments
                      </Button>
                    </li>
                    <li>
                      <Button
                        className={subTabType === 'products' ? 'active' : ''}
                        onClick={() => {
                          //   setTabType('products');
                        }}>
                        Leave Tip
                      </Button>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="portfolio-tab-screen">
                <div className="card-button">
                  <Button className="new-btn btn-green" onClick={() => {}}>
                    Make Payment
                  </Button>
                </div>

                <div className="payment-methods">
                  <img src={dollar} alt="" />
                  <img src={vPayment} alt="" />
                  <img src={zPayment} alt="" />
                  <img src={paypal} alt="" />
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default ProfileTabs;
