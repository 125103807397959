import React, {useState, useEffect, Fragment} from 'react';
import {Button, Col, Container, Form, Row, Dropdown} from 'react-bootstrap';
import commonApi from '../../Services/CommonService';
import img from '../../Assets/images/norecord/no-circles.png';
// import { NoCircles } from '../../Constants/Images';
import {fixName} from '../../Utils/commonUtils';
// import {DEFAULT_IMAGE} from '../../Utils/env';
import LoaderSpinner from '../Modals/LoaderSpinner';

import {labels} from '../../Constants/Translations';
import {selected_lang, DEFAULT_IMAGE} from '../../Utils/env';
import {errorMessage, successMessage} from '../../Utils/ToastMessages';
import API from '../../Services/SessionServices';
import {getItem} from '../../Utils/LocalStorage';
import Scrollbars from 'react-custom-scrollbars';
import {RoiLogo} from '../../Constants/Images';
import { UserProfileImg } from '../../Constants/Images';
const InvitePoc = props => {
  const [followers, setFollowers] = useState([]);
  const [inviteFollowers, setInviteFollowers] = useState([]);
  const [showSlectedUser, setShowSlectedUser] = useState('');
  const [searchQry, setSearchQry] = useState('');
  const [typeOfUser, setTypeOfUser] = useState('customer');
  const [companyAllias, setCompanyAllias] = useState([]);
  const [selectedAllias, setSelectedAllias] = useState('Co-Founder');
  const [selectedAlliasId, setSelectedAlliasId] = useState('');
  const [selectedPocRole, setSelectedPocRole] = useState('');
  const [selectedPocDetails, setSelectedPocDetails] = useState([]);
  const [orginalSelectedPocUsers, setOrginalSelectedPocUsers] = useState(
    props.selectedPoc ? props.selectedPoc.user_details : [],
  );
  const [oldTitle, setOldTitle] = useState(
    props.selectedPoc ? props.selectedPoc.title : '',
  );
  // const [moderator, setModerator] = useState([]);

  // const [selectedModData, setSelectedModData] = useState([]);

  const [showLoader, setshowLoader] = useState(true);

  useEffect(() => {
    setshowLoader(true);
    setSearchQry('');
    getAllias();
    setTimeout(() => { 
      getfolowers('');
    }, 1000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.forApiHit]);

  // const UpdateForState  =(miniItem)=>{
  //
  //   setSelectedModData([...selectedModData, miniItem]);
  // }

  // const getfolowers = qry => {
  //   // alert('@@@@@')
  //   if (props.type === 'sessionDetail') {
  //     let payLoad = {id: getItem('conf_id'), search_query: qry};
  //     API.users_not_in_conference(payLoad)
  //       .then(res => {
  //         setshowLoader(false);
  //         // if (res.status === 200) {
  //         setFollowers(res.data);

  //         // }
  //       })
  //       .catch(err => {
  //         console.log('Err', err);
  //         return {type: 'error', message: err.message};
  //       });
  //   } else {
  //     var payLoad = {
  //       search_query: qry,
  //       remove_company_creator: props.removeCompnayCreator
  //         ? props.removeCompnayCreator
  //         : '',
  //     };
  //     commonApi
  //       .excluded_followers_list(payLoad)
  //       .then(res => {
  //         setshowLoader(false);

  //         if (res.status === 200) {
  //           setFollowers(res.data);
  //           props.setOriginalFolowers?.(res.data);

  //           // if (
  //           //   props.component === 'businessProfile' &&
  //           //   props.moderator.length > 0
  //           // ) {
  //           //   props.setSelectedModData(
  //           //     res.data.filter(user => props.moderator.includes(user.id)),
  //           //   );
  //           //   props.setOriginalDataOfMod(
  //           //     res.data.filter(user => props.moderator.includes(user.id)),
  //           //   );
  //           // }

  //           if (props.contact?.length > 0) {
  //             const filterData = res.data.filter(
  //               item =>
  //                 item.exist_in_build === 'no' &&
  //                 props.contact.some(
  //                   dataItem =>
  //                     dataItem.fname === item.first_name &&
  //                     dataItem.contact === item.phone,
  //                 ),
  //             );

  //             if (filterData.length > 0) {
  //               filterData.forEach(miniItem => {
  //                 props.setModerator(prev => [...prev, miniItem.id]);
  //                 props.setSelectedModData(previous => [...previous, miniItem]);
  //                 props.setOriginalDataOfMod(previous => [
  //                   ...previous,
  //                   miniItem,
  //                 ]);
  //                 // setSelectedModData(updatedData)
  //               });
  //             }
  //           }
  //           if (props.setContact) {
  //             props.setContact([]);
  //           }
  //         }
  //       })
  //       .catch(err => {
  //         console.log('Err', err);
  //         return {type: 'error', message: err.message};
  //       });
  //   }
  // };

  const getfolowers = qry => {
    // alert('@@@@@')
    if (props.type === 'sessionDetail') {
      let payLoad = {id: getItem('conf_id'), search_query: qry};
      API.users_not_in_conference(payLoad)
        .then(res => {
          setshowLoader(false);
          // if (res.status === 200) {
          setFollowers(res.data);

          // }
        })
        .catch(err => {
          
          return {type: 'error', message: err.message};
        });
    } else {
      var payLoad = {
        search_query: qry,
        remove_company_creator: props.removeCompnayCreator
          ? props.removeCompnayCreator
          : '',
      };
      commonApi
        .get_user_for_pocs(payLoad)
        .then(res => {
          

          if (res.status === 200) {
           
            if(showLoader === true){
              setInviteFollowers(res.data)
            }
            if (props.otherType) {
              const validData = res.data.filter((item) => /^[\d+#*]{0,15}$/.test(item.phone));
              const onlyUsers = validData?.filter((item)=> item.id !== localStorage.getItem('id'))
              setFollowers(onlyUsers); // Sirf valid phone numbers ko set karega
          } else {
              setFollowers(res.data); // Agar `props.otherType` false hai to pura data set karega
          }
          
            props.setOriginalFolowers?.(res.data);
            setshowLoader(false);
            // if (
            //   props.component === 'businessProfile' &&
            //   props.moderator.length > 0
            // ) {
            //   props.setSelectedModData(
            //     res.data.filter(user => props.moderator.includes(user.id)),
            //   );
            //   props.setOriginalDataOfMod(
            //     res.data.filter(user => props.moderator.includes(user.id)),
            //   );
            // }

            if (props.contact?.length > 0) {
              const filterData = res.data.filter(
                item =>
                  item.exist_in_build === 'no' &&
                  props.contact.some(
                    dataItem =>
                      dataItem.fname === item.first_name &&
                      dataItem.contact === item.phone,
                  ),
              );

              if (filterData.length > 0) {
                filterData.forEach(miniItem => {
                  props.setModerator(prev => [...prev, miniItem.id]);
                  props.setSelectedModData(previous => [...previous, miniItem]);
                  props.setOriginalDataOfMod(previous => [
                    ...previous,
                    miniItem,
                  ]);
                  // setSelectedModData(updatedData)
                });
              }
            }
            if (props.setContact) {
              props.setContact([]);
            }
          }
        })
        .catch(err => {
          
          return {type: 'error', message: err.message};
        });
    }
  };
  const getAllias = qry => {
    API.get_company_alias('')
      .then(res => {
        if (res.status === 200) {
          setCompanyAllias(res.alias);

          if (props.selectedPoc) {
            props.setTitle(props.selectedPoc.title);
            setSelectedAlliasId(
              res.alias.filter(
                item =>
                  item.role_alias === props.selectedPoc.alias &&
                  item.role_type_id === props.selectedPoc.poc_role,
              )[0].id,
            );

            setSelectedAllias(props.selectedPoc.alias);

            setSelectedPocRole(props.selectedPoc.poc_role);
            const filteredUserDetails = props.selectedPoc.user_details.filter(
              ({id}) => id !== localStorage.getItem('id'),
            );
            props.setModerator(filteredUserDetails.map(({id}) => id));
            props.setSelectedModData(filteredUserDetails);
            props.setOriginalDataOfMod(filteredUserDetails);
          } else {
            setSelectedAlliasId(res.alias[0].id);
            setSelectedAllias(res.alias[0].role_alias);

            setSelectedPocRole(res.alias[0].role_type_id);
          }

          // setSelectedPocDetails(res.alias[0].poc_details)

          // props.setModerator(res.alias[0].poc_details.map(({id}) => id))
        }
      })
      .catch(err => {
        
        return {type: 'error', message: err.message};
      });
  };

  // useEffect(() => {

  //  if(selectedAlliasId !==''){

  //   console.log(selectedAlliasId,'selectedAlliasId')
  //   const filteredData = companyAllias.filter(item => item.id === selectedAlliasId);

  //   console.log(filteredData,'filteredData')
  //   const userIds = filteredData[0].poc_details.map(({id}) => id)

  //   props.setModerator(userIds);

  // props.setSelectedModData(filteredData[0].poc_details)
  // props.setOriginalDataOfMod(filteredData[0].poc_details)

  //   console.log(userIds,'userIds')
  //  }

  // }, [selectedAlliasId])

  // const getUserList = qry => {
  //   let payLoad = {id: getItem('conf_id'), search_query: qry};
  //   API.users_not_in_conference(payLoad)
  //     .then(res => {
  //       setshowLoader(false);
  //       // if (res.status === 200) {
  //       setFolowers(res.data);
  //       setNewFolowers(
  //         res.data.filter(
  //           item => !audience.includes(item.id) && !modrator.includes(item.id),
  //         ),
  //       );
  //       // }
  //     })
  //     .catch(err => {
  //       console.log('Err', err);
  //       return {type: 'error', message: err.message};
  //     });
  // };


  const ModData = mod => {
    const updatedMod = {
      ...mod,
      // user_role: typeOfUser,
      role_alias: selectedAllias,
      poc_role: selectedPocRole,
    };
    if(props.otherType){
      if (!props.moderator.includes(mod.id)) {
        // Add the selected moderator and reset others
        props.setModerator([mod.id]); // Replace the entire array with a single id
        
        props.setSelectedModData([updatedMod]); // Replace the array with a single updatedMod
        
        props.setOriginalDataOfMod([mod]); // Replace the array with a single mod
        if(props.otherType){
          props.openMessageComp(mod)
        }
      } else {
        // Clear the selected moderator when deselected
        props.setModerator([]); // Clear the array
        
        props.setSelectedModData([]); // Clear the array
        
        props.setOriginalDataOfMod([]); // Clear the array
      }
      
    }else{
    if (!props.moderator.includes(mod.id)) {
      props.setModerator(previous => [...previous, mod.id]);
      // setModerator(previous => [...previous, mod.id]);

      props.setSelectedModData(previous => [...previous, updatedMod]);

      props.setOriginalDataOfMod(previous => [...previous, mod]);
    } else {
      props.setModerator(previous => previous.filter(id => id !== mod.id));

      // setModerator(previous => previous.filter(id => id !== mod.id));
      props.setSelectedModData(previous =>
        previous.filter(item => item.id !== mod.id),
      );
      props.setOriginalDataOfMod(previous =>
        previous.filter(item => item.id !== mod.id),
      );
    }
  }

    //
  };
  const AudData = item => {
    const updatedMod = {
      ...item,
      user_role: typeOfUser,
      role_alias: selectedAllias,
    };
    if (!props.audience.includes(item.id)) {
      props.setAudience(previous => [...previous, item.id]);
      props.setSelectedAudData(previous => [...previous, updatedMod]);
      props.setOriginalDataOfAud(previous => [...previous, item]);
    } else {
      props.setAudience(previous => previous.filter(id => id !== item.id));
      props.setSelectedAudData(previous =>
        previous.filter(miniitem => miniitem.id !== item.id),
      );
      props.setOriginalDataOfAud(previous =>
        previous.filter(miniitem => miniitem.id !== item.id),
      );
    }
  };

  useEffect(() => {
    if (props.audience?.length === 0) {
      setShowSlectedUser('');
    }
  }, [props.audience]);

  useEffect(() => {
    if (props.moderator.length === 0) {
      setShowSlectedUser('');
    }
  }, [props.moderator]);

  const handleSearchQry = q => {
    getfolowers(q);
  };

  const handleSelectedSearchQryForAud = (query, array) => {
    if (!query && props.originalDataOfAud.length > 0) {
      props.setSelectedAudData(props.originalDataOfAud); // Reset to original data when query is empty
      return;
    }
    props.setSelectedAudData(
      array.filter(el => {
        // const fullName = el.first_name.toLowerCase() + el.last_name.toLowerCase();
        const fullNameSpace =
          el.first_name.toLowerCase() + ' ' + el.last_name.toLowerCase();
        return (
          el.first_name.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
          el.last_name.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
          fullNameSpace.toLowerCase().indexOf(query.toLowerCase()) !== -1
        );
      }),
    );
  };
  const handleSelectedSearchQryForMod = (query, array) => {
    if (!query && props.originalDataOfMod.length > 0) {
      props.setSelectedModData(props.originalDataOfMod);

      return;
    }

    props.setSelectedModData(
      array.filter(el => {
        // const fullName = el.first_name.toLowerCase() + el.last_name.toLowerCase();
        const fullNameSpace =
          el.first_name.toLowerCase() + ' ' + el.last_name.toLowerCase();
        return (
          el.first_name.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
          el.last_name.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
          fullNameSpace.toLowerCase().indexOf(query.toLowerCase()) !== -1
        );
      }),
    );
  };

  //

  const handleStates = item => {
    setSelectedAllias(item.role_alias);
    setSelectedAlliasId(item.id);
    setSelectedPocRole(item.role_type_id);

    props.setModerator([]);
    props.setSelectedModData([]);
    props.setOriginalDataOfMod([]);
  };

  const editPocs = () => {
    const originalIds = orginalSelectedPocUsers.map(item => item.id);

    const newSelectedPocs = props.selectedModData.filter(
      item => !originalIds.includes(item.id),
    );

    const filteredRoles = orginalSelectedPocUsers.filter(role1 => {
      return !props.selectedModData.some(role2 => role2.id === role1.id);
    });

    const deletedRoletypeIds = filteredRoles.map(role => role.id).toString();

    const newIds = newSelectedPocs.map(role => role.id).toString();

    var payload = {
      poc_role: selectedPocRole,
      role_alias: selectedAllias,
      title: oldTitle !== props.title ? oldTitle : props.title,
      new_title: oldTitle !== props.title ? props.title : '',
      multiple_poc_detail: newIds,
      delete_poc_ids: deletedRoletypeIds,
    };

    commonApi
      .edit_company_pocs(payload)
      .then(res => {
        successMessage(res.message);
        props.getCompanyDetails();
        props.setModerator([]);
        props.setSelectedModData([]);
        props.setOriginalDataOfMod([]);
        props.setTitle('');
        props.onClose();
      })
      .catch(err => {
       
        return {type: 'error', message: err.message};
      });
  };

  const addPocsInBusinessProfile = () =>{
    var payload = {
      user_ids :props.moderator,
      business_profile_id:props.businessProfileId ? props.businessProfileId :''
    };

    // console.log(payload,'addPocsInBusinessProfile-------->')

    commonApi
      .add_users_in_linked_user_poc(payload)
      .then(res => {
        successMessage(res.message);
        props.onCloseHitApi && props.onCloseHitApi()
        props.onClose();
       
      })
      .catch(err => {
        
        return {type: 'error', message: err.message};
      });
  }

  const renderProfileTagsButtons = () => {
    // Return the JSX element conditionally based on props.permission
    return (
      <>
        {/* {props.expenseItem && Object.keys(props.expenseItem).length > 0 ? (
          <span
            className=" as-link"
            onClick={() => {
              // setDeletedId(props.editTag);
              setShowComponent('show_delete_model');
            }}>
            <i className="fa-solid fa-trash-can" />
          </span>
        ) : ( */}
        <img src={RoiLogo} alt="" />
        {/* )} */}
      </>
    );
  };

  // console.log(inviteFollowers,'inviteFollowers')
  // console.log(followers,'--------->followers')
  return (
    <>
      <div className=" poc_invite_page fixed-width-inputs  primary_panel_content">
        {showLoader && <LoaderSpinner className={'curved-spinner-bg'} />}


{props.page && (
   <div
   className="invitation_outside black-head-form-pad-left mt-3 text-left d-flex gap-2 align-items-center"
  >
    <div style={{display: 'flex'}}
     onClick={() => {
      props.onClick();
     }}
     className='as-link '>
   <div className="stage-person-img addcontact-image">
     <img alt="" src={UserProfileImg} />
   </div>
   <p className="p-reg text-gey" style={{margin: '16px 0px 0px 8px'}}>New Contact</p>
   </div>
 </div>
)}
        {!props.page && (
          <Fragment>
            <div className="fieldset invite-page-search black-head-form-pad-left mt-4">
              <h1 className="mb-3 ">
                {props.editPoc ? 'Update Pocs' : 'Add Pocs'}
              </h1>
              <div className="d-flex align-items-center justify-content-between"></div>
              <Form.Control
                type="text"
                value={props.title}
                pattern="^\S.*$"
                required
                placeholder="Enter Title"
                onChange={e => {
                  props.setTitle(e.target.value);
                }}
              />
              <Form.Control.Feedback type="invalid">
                Title is required.
              </Form.Control.Feedback>
            </div>

            {!props.selectedPoc && (
              <div
                className="roi-template-dropdown poc_invite_dropdown black-head-form-pad-left "
                style={{width: 'auto', height: '43px'}}>
                <div className="select-wrapper">
                  <Dropdown
                    onSelect={(eventKey, event) => {
                      var item = JSON.parse(eventKey);
                      // alert(ret.role_alias)

                      handleStates(item);
                      // if (item.role_type_id == 1) {
                      //   setTypeOfUser('customer');
                      // } else {
                      //   setTypeOfUser('other');
                      // }
                    }}
                    drop="down">
                    <Dropdown.Toggle variant="primary">
                      {selectedAlliasId
                        ? companyAllias.find(
                            item => item.id === selectedAlliasId,
                          ).role_alias
                        : ''}
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      {companyAllias.map((item, index) => (
                        <Dropdown.Item
                          key={`st-${index}`}
                          eventKey={JSON.stringify(item)}
                          active={item.id === selectedAlliasId}>
                          {item.role_alias}
                        </Dropdown.Item>
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
            )}
          </Fragment>
        )}
        <div className="invite-page-search black-head-form-pad-left  mb-0">
          <div
            className="search-form"
            style={{margin: props.selectedPoc ? 0 : ''}}>
            <Form.Group className="search-input">
              <Form.Control
                onChange={e => {
                  setSearchQry(e.target.value);
                  // getAllUsers(e.target.value);

                  if (showSlectedUser === 'selected-moderator') {
                    handleSelectedSearchQryForMod(
                      e.target.value,
                      props.selectedModData,
                    );
                  } else if (showSlectedUser === 'selected-audience') {
                    handleSelectedSearchQryForAud(
                      e.target.value,
                      props.selectedAudData,
                    );
                  } else {
                    handleSearchQry(e.target.value);
                  }
                }}
                value={searchQry}
                type="text"
                placeholder="Search for contacts"
                style={{paddingLeft: '15px'}}
              />
            </Form.Group>
          </div>
        </div>

        <div className="refferal-list-wrapper pb-4">
          <Scrollbars autoHide>
            {typeOfUser === 'customer' && showSlectedUser === '' && (
              <div className="invite-persons-wrapper black-head-form-pad-left   invite-poc-persons-wrapper">
                <div className="invite-persons-list px-0">
                  <Container>
                    {/* {showLoader && <LoaderSpinner />} */}
                    <Row>
                      <div className="invite-persons-heading px-1">
                        <span
                          className={
                            props.moderator.length === 0 &&
                            props.subTabs &&
                            typeOfUser === 'customer' &&
                            showSlectedUser === ''
                              ? 'for-grey f_400 p-bold'
                              : props.moderator.length > 0 &&
                                typeOfUser === 'customer' &&
                                showSlectedUser === ''
                              ? 'for-grey  as-link p-bold'
                              : props.moderator.length > 0 &&
                                showSlectedUser === 'selected-moderator'
                              ? 'builder-text as-link p-bold'
                              : ` for-grey f_400 p-bold ${
                                  props.moderator.length === 0 ? '' : 'as-link'
                                } `
                          }
                          onClick={() => {
                            if (props.moderator.length > 0) {
                              setShowSlectedUser('selected-moderator');
                              setSearchQry('');
                              handleSelectedSearchQryForMod('', '');
                            }
                          }}>
                          {props.moderator.length === 0
                            ? 'Search from your contacts'
                            : props.moderator.length + ' Contacts Selected'}
                        </span>
                        {!props.hideCircle && !props.hideCircleIcon && !props.otherType && (
                          <span
                            className="circle-link-icon"
                            onClick={props.handleCircle}>
                            {/* Circles */}
                            <i className="icon-circles" />
                          </span>
                        )}
                      </div>
                      {Object.entries(followers).length > 0 ? (
                        Object.entries(followers).length > 0 &&
                        followers.map((item, index) => (!props.existingPocIds ||
                          !props.existingPocIds.includes(item.id)) &&(
                          <Col key={`f-item-${index}`} md={4} xl={3}>
                            <div
                              className="stage-list-item "
                              key={`folowers-${index}`}>
                              <div
                                className={
                                  item?.linked_user_detail?.user_type ===
                                  'business'
                                    ? 'stage-person-img business as-link'
                                    : 'stage-person-img as-link'
                                }
                                type="checkbox"
                                onClick={() => {
                                  ModData(item);
                                }}>
                                <img
                                  className={
                                    props.moderator.includes(item.id)
                                      ? 'selercted-user'
                                      : ''
                                  }
                                  src={
                                    item.profile_img === ''
                                      ? DEFAULT_IMAGE
                                      : item.profile_img
                                  }
                                  alt=""
                                  id={item.id}
                                />
                              </div>
                              <div
                                className="stage-person-name as-link"
                                onClick={() => {
                                  ModData(item);
                                }}>
                                <h3>
                                  {fixName(item.first_name, item.last_name)}
                                </h3>
                              </div>
                            </div>
                          </Col>
                        ))
                      ) : props.hideCircle || props.noRecord ? (
                        <Col md={12} xl={12} className="text-center">
                          {props.hideCircle &&
                            labels.no_record_found[selected_lang]}
                          {props.noRecord &&
                            labels.no_user_found[selected_lang]}
                        </Col>
                      ) : (
                        <div className="no-results-wrapper no-appointments my-5">
                          <div className="img-bg-wrapper">
                            <img src={img} alt="" />
                          </div>
                          <div className="no-results-content">
                            <p className="noRecord-title">
                              {searchQry
                                ? `  We didn't find the ${searchQry} match for the name`
                                : `  We didn't find  any match `}
                            </p>

                            <div className="btn-wrapper">
                              <Button
                                className="btn btn-dark"
                                onClick={() => {
                                  props.onClick();
                                }}>
                                {searchQry ? 'Add Contact' : 'Add Contacts'}
                              </Button>
                            </div>
                          </div>
                        </div>
                      )}
                    </Row>
                  </Container>
                </div>
              </div>
            )}

            {typeOfUser === 'other' && showSlectedUser === '' && (
              <div className="invite-persons-wrapper  black-head-form-pad-left px-0 m-0">
                <div className="invite-persons-list px-0">
                  <Container>
                    {/* {showLoader && <LoaderSpinner />} */}
                    <Row>
                      <div className="invite-persons-heading">
                        <span
                          className={
                            props.audience.length === 0 &&
                            typeOfUser === 'other'
                              ? 'for-grey as-link p-bold'
                              : props.audience.length > 0 &&
                                showSlectedUser === 'selected-audience'
                              ? 'builder-text as-link p-bold'
                              : ' for-grey  as-link p-bold'
                          }
                          onClick={() => {
                            if (props.audience.length > 0) {
                              setShowSlectedUser('selected-audience');
                              setSearchQry('');
                              handleSelectedSearchQryForAud('', '');
                            }
                          }}>
                          {props.audience.length === 0
                            ? 'Search from your contacts'
                            : props.audience.length + ' Contacts Selected'}
                        </span>
                        {!props.otherType &&
                        <span
                          className="circle-link-icon"
                          onClick={props.handleCircle}>
                          {/* Circles  */}
                          <i className="icon-circles" />
                        </span> 
                        }
                      </div>
                      {Object.entries(followers).length > 0 ? (
                        Object.entries(followers).length > 0 &&
                        followers.map((item, index) => (
                          <Col key={`f-again-${index}`} md={4} xl={3}>
                            <div
                              className="stage-list-item"
                              key={`folow-${index}`}>
                              <div
                                className={
                                  item?.linked_user_detail?.user_type ===
                                  'business'
                                    ? 'stage-person-img business as-link'
                                    : 'stage-person-img as-link'
                                }
                                onClick={() => {
                                  AudData(item);
                                }}>
                                <img
                                  className={
                                    props.audience.includes(item.id)
                                      ? 'selercted-user'
                                      : ''
                                  }
                                  src={
                                    item.profile_img === ''
                                      ? DEFAULT_IMAGE
                                      : item.profile_img
                                  }
                                  alt=""
                                  id={item.id}
                                />
                              </div>
                              <div
                                className="stage-person-name as-link"
                                onClick={() => {
                                  AudData(item);
                                }}
                                id={item.id}
                                value={item.id}>
                                <h3>
                                  {fixName(item.first_name, item.last_name)}
                                </h3>
                              </div>
                            </div>
                          </Col>
                        ))
                      ) : (
                        <div className="no-results-wrapper no-appointments my-5">
                          <div className="img-bg-wrapper">
                            <img src={img} alt="" />
                          </div>
                          <div className="no-results-content">
                            <p className="noRecord-title">
                              {searchQry
                                ? `  We didn't find the ${searchQry} match for the name`
                                : `  We didn't find  any match `}
                            </p>
                            {/* <p className="noRecord-desc">
        Quickly access your colleagues to
        invite, refer and connect
      </p> */}

                            <div className="btn-wrapper">
                              <Button
                                className="btn btn-dark"
                                onClick={() => {
                                  props.onClick();
                                }}>
                                {searchQry ? 'Add Contact' : 'Add Contacts'}
                              </Button>
                            </div>
                          </div>
                        </div>
                      )}
                    </Row>
                  </Container>
                </div>
              </div>
            )}

            {showSlectedUser === 'selected-moderator' && (
              <div className="invite-persons-wrapper black-head-form-pad-left ">
                <div className="invite-persons-list px-0">
                  <Container>
                    {/* {showLoader && <LoaderSpinner />} */}
                    <Row>
                      <div className="invite-persons-heading px-1">
                        <span
                          className={
                            props.moderator.length > 0
                              ? 'builder-text as-link p-bold'
                              : 'builder-text p-bold'
                          }
                          onClick={() => {
                            setShowSlectedUser('');
                            setSearchQry('');
                            // getfolowers('');
                            setFollowers(inviteFollowers)
                          }}>
                          {props.moderator.length === 0
                            ? ''
                            : props.moderator.length + ' Contacts Selected'}
                        </span>
                        {!props.hideCircle && !props.hideCircleIcon && (
                          <span
                            className="circle-link-icon"
                            onClick={props.handleCircle}>
                            {/* Circles  */}
                            <i className="icon-circles" />
                          </span>
                        )}
                      </div>
                      {Object.entries(props.selectedModData).length > 0 ? (
                        Object.entries(props.selectedModData).length > 0 &&
                        props.selectedModData.map((item, index) => (
                          <Col key={`f-item-${index}`} md={4} xl={3}>
                            <div
                              className="stage-list-item "
                              key={`folowers-${index}`}>
                              <div
                                className={
                                  item?.linked_user_detail?.user_type ===
                                  'business'
                                    ? 'stage-person-img business as-link'
                                    : 'stage-person-img as-link'
                                }
                                type="checkbox"
                                onClick={() => {
                                  ModData(item);
                                }}>
                                <img
                                  className={
                                    props.moderator.includes(item.id)
                                      ? 'selercted-user'
                                      : ''
                                  }
                                  src={
                                    item.profile_img === ''
                                      ? DEFAULT_IMAGE
                                      : item.profile_img
                                  }
                                  alt=""
                                  id={item.id}
                                />
                              </div>
                              <div
                                className="stage-person-name as-link"
                                onClick={() => {
                                  ModData(item);
                                }}>
                                <h3>
                                  {fixName(item.first_name, item.last_name)}
                                </h3>
                              </div>
                            </div>
                          </Col>
                        ))
                      ) : props.hideCircle ? (
                        <Col md={12} xl={12} className="text-center">
                          {labels.no_record_found[selected_lang]}
                        </Col>
                      ) : (
                        <div className="no-results-wrapper no-appointments my-5">
                          <div className="img-bg-wrapper">
                            <img src={img} alt="" />
                          </div>
                          <div className="no-results-content">
                            <p className="noRecord-title">
                              {searchQry
                                ? `  We didn't find the ${searchQry} match for the name`
                                : `  We didn't find  any match `}
                            </p>
                            {/* <p className="noRecord-desc">
          Quickly access your colleagues to
          invite, refer and connect
        </p> */}

                            <div className="btn-wrapper">
                              <Button
                                className="btn btn-dark"
                                onClick={() => {
                                  props.onClick();
                                }}>
                                {searchQry ? 'Add Contact' : 'Add Contacts'}
                              </Button>
                            </div>
                          </div>
                        </div>
                      )}
                    </Row>
                  </Container>
                </div>
              </div>
            )}

            {showSlectedUser === 'selected-audience' && (
              <div className="invite-persons-wrapper black-head-form-pad-left m-0">
                <div className="invite-persons-list">
                  <Container>
                    {/* {showLoader && <LoaderSpinner />} */}
                    <Row>
                      <div className="invite-persons-heading">
                        <span
                          className={
                            props.audience.length > 0
                              ? 'builder-text as-link p-bold'
                              : 'builder-text p-bold'
                          }
                          onClick={() => {
                            setShowSlectedUser('');
                            setSearchQry('');
                            getfolowers('');
                          }}>
                          {props.audience.length === 0
                            ? ''
                            : props.audience.length + ' Contacts Selected'}
                        </span>
                        <span
                          className="circle-link-icon"
                          onClick={props.handleCircle}>
                          {/* Circles */}
                          <i className="icon-circles" />
                        </span>
                      </div>
                      {Object.entries(props.selectedAudData).length > 0 ? (
                        Object.entries(props.selectedAudData).length > 0 &&
                        props.selectedAudData.map((item, index) => (
                          // !audience.includes(item.id) &&
                          <Col key={`f-again-${index}`} md={4} xl={3}>
                            <div
                              className="stage-list-item"
                              key={`folow-${index}`}>
                              <div
                                className={
                                  item?.linked_user_detail?.user_type ===
                                  'business'
                                    ? 'stage-person-img business as-link'
                                    : 'stage-person-img as-link'
                                }
                                onClick={() => {
                                  AudData(item);
                                }}>
                                <img
                                  className={
                                    props.audience.includes(item.id)
                                      ? 'selercted-user'
                                      : ''
                                  }
                                  src={
                                    item.profile_img === ''
                                      ? DEFAULT_IMAGE
                                      : item.profile_img
                                  }
                                  alt=""
                                  id={item.id}
                                />
                              </div>
                              <div
                                className="stage-person-name as-link"
                                onClick={() => {
                                  AudData(item);
                                }}
                                id={item.id}
                                value={item.id}>
                                <h3>
                                  {fixName(item.first_name, item.last_name)}
                                </h3>
                              </div>
                            </div>
                          </Col>
                        ))
                      ) : (
                        <div className="no-results-wrapper no-appointments my-5">
                          <div className="img-bg-wrapper">
                            <img src={img} alt="" />
                          </div>
                          <div className="no-results-content">
                            <p className="noRecord-title">
                              {searchQry
                                ? `  We didn't find the ${searchQry} match for the name`
                                : `  We didn't find the  match `}
                            </p>
                            {/* <p className="noRecord-desc">
        Quickly access your colleagues to
        invite, refer and connect
      </p> */}

                            <div className="btn-wrapper">
                              <Button
                                className="btn btn-dark"
                                onClick={() => {
                                  props.onClick();
                                }}>
                                {searchQry ? 'Add Contact' : 'Add Contacts'}
                              </Button>
                            </div>
                          </div>
                        </div>
                      )}
                    </Row>
                  </Container>
                </div>
              </div>
            )}
          </Scrollbars>
        </div>

        {props.component === 'businessProfile' && followers.length > 0 && !props.otherType &&(
          <div className="filter-btn-wrapper pt-2 bg_transparent">
            <div className="invite-btn text-center">
              <Button
                className="btn-dark"
                type="button"
                onClick={() => {
                  if(props.page){
                    addPocsInBusinessProfile()
                  }else
                  if (props.title === '') {
                    errorMessage('Title is required');
                  } else if (props.moderator.length === 0) {
                    errorMessage('Please Select Pocs');
                  } else if (props.selectedPoc) {
                    editPocs();
                  } else {
                    props.updateCompanyDetails();
                    props.onClose();
                  }
                }}>
                {props.editPoc ? 'Update Pocs' : 'Add Pocs'}
              </Button>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default InvitePoc;
