import CustomModal from '../CustomModal';
import {Form} from 'react-bootstrap';
import React, {useState, useEffect} from 'react';
import commonApi from '../../Services/CommonService';
import {useSelector, useDispatch} from 'react-redux';
import {getHitWorkspace} from '../../Store/Actions/SessionAction';
import CreateWorkSpace from '../WorkSpace/CreateWorkSpace';
import {successMessage} from '../../Utils/ToastMessages';

const PersonalModal = props => {
  const [showComponent, setShowComponent] = useState('');

  const listWorspacerator = useSelector(state => state.hitWorkspace.data);
  const dispatch = useDispatch();

  const [activeWorkspaces, setActiveWorkspacesId] = useState([]);
  const [apiResponseIds, setApiResponseIds] = useState([]);

  useEffect(() => {
    dispatch(
      getHitWorkspace(
        props.type === 'other-Profile'
          ? {user_id: props.userData.id}
          : {page: '1', token: localStorage.getItem('token')},
      ),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showComponent]);
  useEffect(() => {
    // Filter the workspaces with status "active" from the API response
    const filteredWorkspaces = listWorspacerator.filter(
      workspace => workspace.status === 'active',
    );
    const userExist = listWorspacerator.filter(
      workspace => workspace.user_exist === true,
    );

    // Set the active workspaces in the component's state
    setActiveWorkspacesId(
      props.type === 'other-Profile'
        ? userExist.map(workspace => workspace.id)
        : filteredWorkspaces.map(workspace => workspace.id),
    );
    setApiResponseIds(
      props.type === 'other-Profile'
        ? userExist.map(workspace => workspace.id)
        : filteredWorkspaces.map(workspace => workspace.id),
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listWorspacerator]);

  const handleCheckboxChange = event => {
    const workspaceId = event.target.id;
    if (activeWorkspaces.includes(workspaceId)) {
      // Workspace is currently active, remove it from the selection
      setActiveWorkspacesId(prevActiveWorkspaces =>
        prevActiveWorkspaces.filter(id => id !== workspaceId),
      );
    } else {
      // Workspace is not active, add it to the selection
      setActiveWorkspacesId(prevActiveWorkspaces => [
        ...prevActiveWorkspaces,
        workspaceId,
      ]);
    }
  };

  const handleUpdateButtonClick = () => {
    // You can perform your update action here using activeWorkspaces
    //
    if (props.type === 'other-Profile') {
      AddRemoveWorkSpace();
      // Current workspace memberships from the API response
    } else {
      props.setWorkSpaceIds(
        activeWorkspaces.includes('personal')
          ? activeWorkspaces.join(',')
          : activeWorkspaces.concat('network').join(','),
      );
    }

    props.onClose();
  };

  const AddRemoveWorkSpace = () => {
    // const currentWorkspaces = apiResponse.workspaces;

    // Find workspaces that the user has been added to
    const addedWorkspaces = activeWorkspaces.filter(
      item => !apiResponseIds.includes(item),
    );

    // Find workspaces that the user has been removed from
    const removedWorkspaces = apiResponseIds.filter(
      item => !activeWorkspaces.includes(item),
    );

    var payLoad = {
      add_workspace_ids: addedWorkspaces.toString(','),
      remove_workspace_ids: removedWorkspaces.toString(','),
      user_id: props.userData.id,
    };
    commonApi
      .add_and_remove_user_in_workspace(payLoad)
      .then(res => {
        // setshowLoader(false);
        if (res.status === 200) {
          successMessage(res.message);
        }
      })
      .catch(err => {
        
        return {type: 'error', message: err.message};
      });
  };

  return (
    <>
      {' '}
      <CustomModal
        className="shareables-workspace-modal"
        size="small"
        onClose={() => props.onClose()}>
        <div className="filter-wrapper">
          <div className="filter-category-list">
            <div className="filter-headings">
              <h2>Associated Workspace</h2>
            </div>
            <ul>
              <li>
                <Form.Label>
                  Personal
                  <input
                    type="checkbox"
                    id={'personal'}
                    value={'personal'}
                    onChange={handleCheckboxChange}
                    checked={activeWorkspaces.includes('personal')}
                  />
                  <span />
                </Form.Label>
              </li>
              {Object.entries(listWorspacerator).length > 0 &&
                listWorspacerator.map((item, index) => (
                  <li key={`sessionC-${index}`}>
                    <Form.Label>
                      {item.name}
                      <input
                        type="checkbox"
                        id={item.id}
                        value={item.id}
                        onChange={handleCheckboxChange}
                        checked={activeWorkspaces.includes(item.id)}
                      />
                      <span />
                    </Form.Label>
                  </li>
                ))}
            </ul>
            {/* {!props.type && (
              <div className="invite-persons-heading mt-3">
                <span
                  className="builder-text as-link"
                  onClick={() => {
                    setShowComponent('WorkSpace');
                  }}>
                  Manage Workspace
                </span>
              </div>
            )} */}
            {/* <Button
              type="submit"
              className="btn-dark"
              onClick={handleUpdateButtonClick}>
              Update
            </Button> */}
            <p
              className="text-yellow mt-3"
              style={{fontSize: 14}}
              onClick={handleUpdateButtonClick}>
              Remove From Contact
            </p>
          </div>
        </div>
      </CustomModal>
      {showComponent === 'WorkSpace' && (
        <CreateWorkSpace
          onClose={() => {
            setShowComponent('');
          }}
        />
      )}
    </>
  );
};

export default PersonalModal;
