import React, {useEffect, useState} from 'react';
import {RoiLogo, Trash} from '../../Constants/Images';
import {
  capitalizeFirst,
  fieldTypeFormat,
  filterSearchResults,
  handleRequiredToggle,
  selectField,
} from '../../Utils/commonUtils';
import {FaAsterisk, FaBars} from 'react-icons/fa';
import {Form} from 'react-bootstrap';
import commonApi from '../../Services/CommonService';
import {errorMessage} from '../../Utils/ToastMessages';

const FieldSuggestion = ({
  groupFields,
  setAnySeacrh,
  setGroupFields,
  handleDelete,
  showData,
  type,
  stepIndex,
  setShowSelectedFieldsIndex,
  showSelectedFieldsIndex,
}) => {
  const [search, setSearch] = useState('');
  const [searchField, setSearchField] = useState([]);
  const [draggedIndex, setDraggedIndex] = useState(null);
  const [showLisitng, setShowLisitng] = useState(true);

  useEffect(() => {
    if (type) {
      setShowLisitng(showData);
    }
  }, [showData]);

  useEffect(() => {
    get_search_fields_groups();
  }, [search]);

  const handleSuggestions = item => {
    const fields = selectField(item);
    const matchedIds = new Set(groupFields.map(({id}) => id));

    const unmatchedItems = fields.filter(({id}) => !matchedIds.has(id));

    if (unmatchedItems.length < fields.length) {
      errorMessage('Already added this field');
    }

    if (unmatchedItems.length) {
      setGroupFields(prev => [...prev, ...unmatchedItems]);
      setSearch('');
    }
  };

  const get_search_fields_groups = value => {
    const payload = {
      search_query: search ? search : value,
    };
    commonApi
      .get_search_fields_groups(payload)
      .then(res => {
        if (res.status === 200 && res.success) {
          const filterData = filterSearchResults(res, search);
          
          setSearchField(filterData);
        }
      })
      .catch(err => {
        
        return {type: 'error', message: err.message};
      });
  };

  const handleDragStart = (e, index) => {
    setDraggedIndex(index);
    e.dataTransfer.effectAllowed = 'move';
  };

  const handleDragOver = e => {
    e.preventDefault(); // Allow dropping
  };

  const handleDrop = (e, dropIndex) => {
    e.preventDefault();
    if (draggedIndex === null || draggedIndex === dropIndex) return;

    // Clone array and swap elements
    const newFields = [...groupFields];
    const draggedItem = newFields.splice(draggedIndex, 1)[0];
    newFields.splice(dropIndex, 0, draggedItem);

    setGroupFields(newFields);
    setDraggedIndex(null);
  };

  return (
    <>
      <div className="roi-form-input">
        <Form.Control
          type="text"
          value={search}
          placeholder="Field Group (multi-select)"
          onChange={e => {
            setSearch(e.target.value);
            if (setAnySeacrh) {
              setAnySeacrh(false);
            }
            if (setShowSelectedFieldsIndex) {
              if (showSelectedFieldsIndex !== stepIndex) {
                setShowSelectedFieldsIndex(stepIndex);
              }
            }
          }}
        />
      </div>
      <ul className="custom-suggestions invoices_suggestion">
        {search && searchField?.length > 0
          ? searchField?.map(suggestion => (
              <li
                className="as-link"
                key={'suggestion' + suggestion.id}
                onClick={() => {
                  handleSuggestions(suggestion);
                }}>
                {suggestion.name || suggestion.group_name}
                <p
                  className="field-type"
                  style={{
                    fontSize: '12px',
                    color: 'gray',
                  }}>
                  {suggestion?.field_details ? 'Field group' : 'Field'}
                </p>
              </li>
            ))
          : search && <li className="as-link">No Fields available.</li>}
      </ul>

      {((type === 'wizard' && showSelectedFieldsIndex === stepIndex) ||
        type === 'traditional') &&
        groupFields?.length > 0 &&
        groupFields?.map((item, index) => (
          <div
            draggable
            onDragStart={e => handleDragStart(e, index)}
            onDragOver={handleDragOver}
            onDrop={e => handleDrop(e, index)}
            key={index}
            className="mt-2 flex justify-between items-center bg-white p-3 rounded-lg shadow">
            <div className="flex items-center gap-2">
              <FaBars style={{color: '#EDEBE5'}} className="cursor-move" />
              <div>
                <div className="text-sm font-medium">{item.name}</div>
                <div className="text-xs text-gray-500">
                  {capitalizeFirst(fieldTypeFormat(item.type))}
                </div>
              </div>
            </div>
            <div className="flex items-center gap-2">
              {/* <p className="as-link">
                <img
                  onClick={() => {
                    handleDelete(index, 'multi_select_field');
                  }}
                  src={Trash}
                  alt=""
                />
              </p> */}
              <span
                onClick={() => {
                  handleDelete(index, 'multi_select_field');
                }}
                className="poc_delete as-link">
                <i class="fa-solid fa-trash-can"></i>
              </span>
              {/* <FaAsterisk
                className={
                  item.required === false
                    ? 'text-black as-link'
                    : 'text-green-500 as-link'
                }
                onClick={() =>
                  handleRequiredToggle(groupFields, setGroupFields, index)
                }
              /> */}
            </div>
          </div>
        ))}
    </>
  );
};

export default FieldSuggestion;
