import React, {useState,useEffect} from 'react';
import {Button, Form} from 'react-bootstrap';

import CustomModal from '../CustomModal';
import Scrollbars from 'react-custom-scrollbars';

const CatalogFilter = props => {
    //   const [show, setShow] = useState(props.show);
  const [tempCheck, setTempCheck] = useState([...props.isCheck]);
  const [tempCheckAll, setTempCheckAll] = useState(props.isCheckAll);
  const [applyClicked, setApplyClicked] = useState(false);

  const closeModal = () => {
    // setShow(false);
    props.onClose();
  };

  useEffect(() => {
    setTempCheck([...props.isCheck]); 
    setTempCheckAll(props.isCheckAll);
  }, [props.isCheck, props.isCheckAll]);

  const handleSelectAll = () => {
    setTempCheckAll(!tempCheckAll);
    setTempCheck(tempCheckAll ? [] : props.filterlisting.map(item => item.id));
  };

  const handleClick = e => {
    const {id, checked} = e.target;
    setTempCheck(prev =>
      checked ? [...prev, id] : prev.filter(item => item !== id)
    );
  };

  useEffect(() => {
    if (props.filterlisting.length > 0) {
      setTempCheckAll(tempCheck.length === props.filterlisting.length);
    }
  }, [tempCheck]);

  // This useEffect ensures that hitFuctionAfterFilter() runs only after the state updates
  useEffect(() => {
    if (applyClicked) {
      props.hitFuctionAfterFilter();
      closeModal();
      setApplyClicked(false); // Reset flag
    }
  }, [props.isCheck, props.isCheckAll, applyClicked]); // Runs after state updates

  const applyFilter = () => {
    props.setIsCheck(tempCheck);
    props.setIsCheckAll(tempCheckAll);
    setApplyClicked(true); // Trigger effect to apply changes after state update
  };

  return (
    <>
    <CustomModal
      className={'sessioneventFilter sessioneventFilteration'}
      style={{height: '100%'}}
      onClose={() => closeModal()}>
      <div className="serach-filter-wrapper">
        <div className="d-flex align-items-center justify-content-center mb-3">
          <div className="headings m-0">
            <h2 className="m-0">Categories</h2>
          </div>
          
            <div className="sidebar-heading-wrapper mt-0 text-right">
              {/* <div className="ticket-icons-options">
              <ul>
                <li
                  className={props.active ? 'active' : 'target-icon'}
                  onClick={() => {
                    setSearchFilter(true);
                  }}>
                  <i className="icon-bullseye" />
                </li>
              </ul>
            </div> */}
            </div>
          </div>

        <Scrollbars>
        <div className="">
          <div className="filter-category-list">
            <ul>
              <li className={tempCheckAll ? 'active' : ''}>
                <Form.Label>
                All{' '}
                  <input
                    type="checkbox"
                    id="ALL"
                    name="ALL"
                    value="ALL"
                    onChange={handleSelectAll}
                    checked={tempCheckAll}
                    />{' '}
                  <span />
                </Form.Label>
              </li>
              {Object.entries(props.filterlisting).length > 0 &&
                  props.filterlisting.map((item, index) => (
                    <li
                  key={`sessionC-${index}`}
                  className={tempCheck.includes(item.id) ? 'active' : ''}>
                  <Form.Label>
                    {item.catalog_type}
                    <input
                      type="checkbox"
                      id={item.id}
                      value={item.id}
                      onChange={handleClick}
                      checked={tempCheck.includes(item.id)}
                    />
                    <span />
                  </Form.Label>
                </li>
              ))}
            </ul>
            </div>
          </div>
        </Scrollbars>
        <div className="filter-btn-wrapper pt-1 bg_transparent text-center">
        <Button
              className="btn-dark"
              type="submit"
              onClick={applyFilter}>
            Filter Categories
          </Button>
        </div>
      </div>
    </CustomModal>
    </>
  );
};

export default CatalogFilter;
