import React, {useState, useEffect, useRef} from 'react';
import {Button, Dropdown, Form} from 'react-bootstrap';
import {
  blockInvalidChar,
  capitalizeFirst,
  capitalizeFirstLetter,
  fixName,
  WithBottomClass,
} from '../../Utils/commonUtils';
import Scrollbars from 'react-custom-scrollbars';
import TransparentButton from '../TransparentButton/TransparentButton';
import CustomDropdown from '../CustomDropdown';
import commonApi from '../../Services/CommonService';
import EditPencilSvg from '../../Svg/EditPencilSvg';
import {successMessage} from '../../Utils/ToastMessages';
import { useDispatch } from 'react-redux';
import { getCompensationPay } from '../../Store/Actions/getCompensationPayAction';
import { useSelector } from 'react-redux';

const CompensationPay = props => {
  const [validated, setValidated] = useState(false);
  const [dropdownId, setDropdownId] = useState('salary');
  const [dropdownId2, setDropdownId2] = useState('one_time');
  const [typeProductOffering, setTypeProductOffering] = useState('offering');
  const [show, setShow] = useState(true);
  const [amount, setAmount] = useState('');
  const [searchQry, setSearchQry] = useState('');
  const [suggestion, setSuggestion] = useState([]);
  const [suggestionLists, setSuggestionLists] = useState({});
  const [showError, setShowError] = useState(false);
  const [milestoneDropdownId, setMilestoneDropdownId] = useState('');
  const [milestoneDropdownValues, setMilestoneDropdownValues] = useState([]);
  const [editPay, setEditPay] = useState({});
  const [payListing, setPayListing] = useState([]);
  const dispatch = useDispatch();
  const getConpensationPay = useSelector(state => state.compensationPayListing);
  

  const buttonRef = useRef(null);

  const handle = event => {
    const form = event.currentTarget;

    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      if (Object.keys(suggestionLists).length === 0) {
        setShowError(true);
      }else{
        setShowError(false);
        setValidated(true);
      }
    } else {
      event.preventDefault();
      if (Object.keys(suggestionLists).length === 0) {
        setShowError(true);
      }else{
        create_update_pay();
        setValidated(false);
        setShowError(false);
      }
    }
  };

  const dropdownValues = [
    {
      id: 'salary',
      name: 'Salary',
    },
    {
      id: 'hourly',
      name: 'Hourly',
    },
    {
      id: 'flat_fee',
      name: 'Flat Fee',
    },
  ];
  const dropdownValues2 = [
    {
      id: 'one_time',
      name: 'One time',
    },
    {
      id: 'monthly',
      name: 'Monthly',
    },
  ];
  const prjectOfferingList = [
    {
      id: 'offering',
      name: 'Offering',
    },
    {
      id: 'project',
      name: 'Project',
    },
  ];

  const buttons = [
    {id: 1, label: 'Before', views: 3},
    {id: 2, label: 'After', views: 2},
  ];

  const create_update_pay = () => {
    return false
    const payload = {
      pay_id: editPay?.id ? editPay?.id : '',
      pay_type: dropdownId,
      type: typeProductOffering,
      milestone_id: milestoneDropdownId ? milestoneDropdownId : '',
      type_id: suggestionLists.id
        ? suggestionLists.id
        : suggestionLists?.catalog_id
        ? suggestionLists?.catalog_id
        : '',
      frequency: dropdownId2,
      amount: amount,
    };

    commonApi
      .create_update_pay(payload)
      .then(res => {
        if (res.status === 200) {
          // setPayListing(res.data);
          // get_all_pay();
          dispatch(getCompensationPay())
          reset();
          successMessage(res.message);
        }
      })
      .catch(err => {
        return {type: 'error', message: err.message};
      });
  };

  const get_projects_on_search = e => {
    const payload = {
      search_query: e ? e : searchQry,
    };
    commonApi
      .get_projects_on_search(payload)
      .then(res => {
        setSuggestion(res.projects);
      })
      .catch(err => {
        return {type: 'error', message: err.message};
      });
  };

  const get_all_catalogs_with_search = e => {
    const payload = {
      search: e ? e : searchQry,
    };
    commonApi
      .get_all_catalogs_with_search(payload)
      .then(res => {
        setSuggestion(res.data);
      })
      .catch(err => {
        return {type: 'error', message: err.message};
      });
  };

  const get_all_pay = () => {
    commonApi
      .get_all_pay()
      .then(res => {
        setPayListing(res?.data);
        // reset();
      })
      .catch(err => {
        return {type: 'error', message: err.message};
      });
  };

  const delete_pay = ID => {
    const payload = {
      id: ID,
    };
    commonApi
      .delete_pay(payload)
      .then(res => {
        handleDelete(ID);
        successMessage(res.message);
      })
      .catch(err => {
        return {type: 'error', message: err.message};
      });
  };

  const handleDelete = ID => {
    setPayListing(previous => previous.filter(pay => pay.id !== ID));
  };

  const handleEdit = item => {
    setAmount(item.amount);
    setDropdownId(item?.pay_type);
    setTypeProductOffering(item.type);
    setDropdownId2(item.frequency);
    if (item.project_details && Object.keys(item.project_details).length > 0) {
      setTimeout(() => {
        setMilestoneDropdownId(item?.milestone_id ? item.milestone_id : '');
        setMilestoneDropdownValues(
          item?.milestone_id ? item.project_details?.milestones : [],
        );
        setSuggestionLists(item.project_details);
      }, 1000);
    
    } else if (
      item.offering_details &&
      Object.keys(item.offering_details).length > 0
    ) {
      setTimeout(() => {
        setSuggestionLists(item?.offering_details); 
      }, 1000);
    }
    setEditPay(item);
  };

  const handleSuggestion = item => {
    setSuggestionLists(item);
    setSuggestion({});
    setSearchQry('');
    if (typeProductOffering === 'project') {
      if (item?.milestones?.length > 0) {
        setMilestoneDropdownValues(item?.milestones);
        setMilestoneDropdownId(item?.milestones[0].id);
      } else {
        setMilestoneDropdownValues([]);
      }
    }
  };

  useEffect(() => {
    setSearchQry('');
    setSuggestion([]);
    setSuggestionLists({});
    setMilestoneDropdownId('');
    setMilestoneDropdownValues([]);
    setShowError(false)
  }, [typeProductOffering]);

  useEffect(() => {
    // get_all_pay();
    dispatch(getCompensationPay())
  }, []);

  const reset = () => {
    setSearchQry('');
    setSuggestion([]);
    setSuggestionLists({});
    setMilestoneDropdownId('');
    setMilestoneDropdownValues([]);
    setAmount('');
    setDropdownId('salary');
    setDropdownId2('one_time');
    setEditPay({});
    setShowError(false)
  };

  return (
    <>
      <div
        className="refferal-list-wrapper p-2"
        style={{
          backgroundColor: '#EDEBE5',
          borderRadius: '5px',
        }}>
        <div
          className="w-100 d-flex align-items-center justify-content-between fixed-width-inputs black-head-form-pad-left fix-width-forms mb_12 pt-3 "
          onClick={() => {
            setShow(!show);
          }}>
          <div className="d-flex">
            <p className="p-bold m-0 f_600_h">Pay</p>
          </div>
        </div>
        {show && (
          <>
            <Form noValidate validated={validated} onSubmit={handle}>
              <div className=" fixed-width-inputs black-head-form-pad-left fix-width-forms">
                <div className="mb-3">
                  <CustomDropdown
                    // type={'credential'}
                    setDropdownId={setDropdownId}
                    dropdownId={dropdownId}
                    DropDownValues={dropdownValues}
                    itemName={['name']}
                    className={
                      'recruitment_dropdown recruitement_dropdown_setInvites'
                    }
                    // handleDropdownValue={handleInvitesAssociation}
                    disabled={props.id ? true : false}
                  />
                </div>

                <div className="mb-3">
                  <CustomDropdown
                    // type={'credential'}
                    setDropdownId={setTypeProductOffering}
                    dropdownId={typeProductOffering}
                    DropDownValues={prjectOfferingList}
                    itemName={['name']}
                    className={
                      'recruitment_dropdown recruitement_dropdown_setInvites'
                    }
                    // handleDropdownValue={handleInvitesAssociation}
                    disabled={props.id ? true : false}
                  />
                </div>
                <div className="fieldset">
                  <Form.Control
                    name="Offering"
                    value={searchQry}
                    pattern="^\S.*$"
                    placeholder={`Search ${typeProductOffering}`}
                    onChange={e => {
                      if (typeProductOffering === 'offering') {
                        get_all_catalogs_with_search(e.target.value);
                      } else {
                        get_projects_on_search(e.target.value);
                      }
                      setSearchQry(e.target.value);
                    }}
                  />
                  {Object.keys(suggestionLists).length === 0 && showError && (
                    <span
                      style={{
                        color: '#dc3545',
                        marginTop:'.25rem',
                        fontSize: '.875em',
                        fontWeight: 'normal'
                      }}
                      className="errorMsg">{`${capitalizeFirstLetter(
                      typeProductOffering,
                    )} is required.`}</span>
                  )}

                  {searchQry && (
                    <ul className="custom-suggestions invoices_suggestion">
                      {suggestion?.length > 0 ? (
                        suggestion?.map(item => (
                          <li
                            className="as-link"
                            key={'item' + item.id}
                            onClick={() => {
                              handleSuggestion(item);
                            }}>
                            {capitalizeFirst(
                              item.name ? item.name : item.title,
                            )}
                          </li>
                        ))
                      ) : (
                        <>
                          <li className="as-link">{`No ${typeProductOffering} available.`}</li>
                        </>
                      )}
                    </ul>
                  )}
                  {milestoneDropdownValues?.length > 0 && (
                    <div className="mt-3 roi-form-dropdown ">
                      <div className="select-wrapper">
                        <Dropdown
                          onSelect={(eventKey, event) => {
                            setMilestoneDropdownId(eventKey);
                          }}
                          drop="down">
                          <Dropdown.Toggle variant="primary">
                            {milestoneDropdownId
                              ? milestoneDropdownValues.find(
                                  item => item.id === milestoneDropdownId,
                                )?.title
                              : 'Select Milestone'}
                          </Dropdown.Toggle>

                          <Dropdown.Menu>
                            {milestoneDropdownValues.map((item, index) => (
                              <Dropdown.Item
                                key={`st-${index}`}
                                eventKey={item.id.toString()}
                                active={item.id === milestoneDropdownId}>
                                {item.title}
                              </Dropdown.Item>
                            ))}
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    </div>
                  )}

                  <div className="mb-3">
                    {suggestionLists &&
                      Object.keys(suggestionLists).length > 0 && (
                        <div class="d-flex align-items-center phases_item mt-3">
                          <div className="customer_name flex-1">
                            <div className="alias_name">
                              {fixName(
                                suggestionLists.name
                                  ? suggestionLists.name
                                  : suggestionLists.title,
                              )}
                            </div>
                            <span
                              class="as-link  cross-icon-field"
                              onClick={() => {
                                setMilestoneDropdownValues([])
                                setMilestoneDropdownId('')
                                setSuggestionLists({})
                              }}>
                              <i
                                class="fas fa-times text-secondary"
                                style={{fontSize: '1.5rem'}}></i>
                            </span>
                            {/* )} */}
                          </div>
                        </div>
                      )}
                  </div>
                </div>
                <div className="mb-3">
                  <CustomDropdown
                    // type={'credential'}
                    setDropdownId={setDropdownId2}
                    dropdownId={dropdownId2}
                    DropDownValues={dropdownValues2}
                    itemName={['name']}
                    className={
                      'recruitment_dropdown recruitement_dropdown_setInvites'
                    }
                    // handleDropdownValue={handleInvitesAssociation}
                    disabled={props.id ? true : false}
                  />
                </div>
                <div className="fieldset">
                  <Form.Control
                    type="number"
                    name="amount"
                    value={amount}
                    required
                    onKeyDown={blockInvalidChar}
                    placeholder="Amount"
                    onChange={e => {
                      setAmount(e.target.value);
                    }}
                  />
                  <Form.Control.Feedback type="invalid">
                    Amount is required.
                  </Form.Control.Feedback>
                </div>

                <div className="flex content-center justify-center">
                  <Button className="btn-dark" type="submit">
                    {editPay && Object.keys(editPay).length > 0
                      ? 'Update Pay'
                      : 'Add Pay'}
                  </Button>
                </div>
                {editPay && Object.keys(editPay).length > 0 && (
                  <div className="m-2 flex justify-center items-center mt-3">
                    <h2
                      className="mb-2 as-link"
                      onClick={() => {
                        reset();
                      }}>
                      Cancel
                    </h2>
                  </div>
                )}
              </div>
            </Form>

            {getConpensationPay?.length > 0 &&
              getConpensationPay.map((item, index) => (
                <div
                  key={index}
                  className="mt-2 flex justify-between items-center bg-white p-3 rounded-lg ">
                  <div className="flex items-center gap-2">
                    <div>
                      <div className="text-sm font-medium truncate_2_lines">
                        {item.project_details
                          ? item?.project_details?.name
                          : item?.offering_details &&
                            item?.offering_details?.title}
                      </div>
                      <div className="text-xs text-gray-500">{item.amount}</div>
                    </div>
                  </div>

                  <div className="flex items-center gap-2">
                  <p
                      className="as-link"
                      onClick={() => {
                        handleEdit(item);
                      }}>
                      <EditPencilSvg fill={'#52bd41'} h={15} w={15} />
                    </p>
                    <p className="as-link">
                      <span className="poc_delete as-link">
                        <i
                          class="fa-solid fa-trash-can"
                          onClick={() => {
                            props.handleBlock('', 'deleteConfirmation', item)
                            // delete_pay(item.id);
                          }}
                        />
                      </span>
                    </p>
                   
                  </div>
                </div>
              ))}
          </>
        )}
      </div>
    </>
  );
};

export default CompensationPay;
