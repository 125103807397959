import React, {useState, useEffect} from 'react';
import {Button} from 'react-bootstrap';
import Slider from 'react-slick';

import {DEFAULT_IMAGE} from '../../Utils/env';
import {labels} from '../../Constants/Translations';
import {selected_lang} from '../../Utils/env';
import CustomModal from '../CustomModal';
import {useHistory} from 'react-router-dom';
import { getItem } from '../../Utils/LocalStorage';
import commonApi from '../../Services/CommonService';
import { useSelector,useDispatch } from 'react-redux';
import { checkMenuExists } from '../../Utils/commonUtils';
import { errorMessage } from '../../Utils/ToastMessages';
import { getActiveCompanyUserMenus } from '../../Store/Actions/MenuAction';
const RenderOferrings = () => {
  const companyOfferings = useSelector(state => state.companyOferringsList);
  const companyMenuList = useSelector(state => state.menuActiveList);

  const [showLogin, setShowLogin] = useState(null);

  const [dataItem, setDataItem] = useState({});

  const dispatch = useDispatch();

  const {pathname, search} = window.location;
  const urlParts = pathname.split('/').filter(Boolean);

  const history = useHistory();
  const eventSlider = {
    // className: "center",
    // centerMode: true,
    dots: true,
    arrows: false,
    infinite: false,
    // centerPadding : "60px",
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    // adaptiveHeight: true,
  };

//   const companyOfferings = [
//     {
//       image:
//         'https://s3.amazonaws.com/build-dev/catalog_images/1714029608107.jpg',
//       catalog_type: 'service',
//       company_id: '151',
//       title: 'Sasfewef',
//       description: 'ASas',
//       cost: '50',
//       audience: 'standard',
//       delivery_method: '',
//       'duration ': '',
//       subscription: 'monthly',
//       incentives: '',
//       role_id: '',
//       created_by: '2515',
//       left_item: '',
//       is_favourite: true,
//       favourite_id: '53',
//       is_cart: false,
//       cart_id: '',
//       catalog_id: '209',
//     },
//     {
//       image:
//         'https://s3.amazonaws.com/build-dev/catalog_images/1714029644519.jpg',
//       catalog_type: 'service',
//       company_id: '151',
//       title: 'AXAWEFWE',
//       description: 'WEFWE',
//       cost: '8',
//       audience: 'standard',
//       delivery_method: '',
//       'duration ': '',
//       subscription: 'monthly',
//       incentives: '',
//       role_id: '',
//       created_by: '2515',
//       left_item: '',
//       is_favourite: true,
//       favourite_id: '54',
//       is_cart: false,
//       cart_id: '',
//       catalog_id: '210',
//     },
//     {
//       image:
//         'https://s3.amazonaws.com/build-dev/catalog_images/1715342303366.jpg',
//       catalog_type: 'product',
//       company_id: '151',
//       title: 'Item 1',
//       description: 'x\\zx\\zx\\',
//       cost: '40',
//       audience: 'standard',
//       delivery_method: '',
//       'quantity_available ': '',
//       subscription: 'monthly',
//       incentives: '',
//       role_id: '',
//       created_by: '2515',
//       variations: '',
//       left_item: '22',
//       is_favourite: true,
//       favourite_id: '55',
//       is_cart: false,
//       cart_id: '',
//       catalog_id: '238',
//     },
//     {
//       image:
//         'https://s3.amazonaws.com/build-dev/catalog_images/1715342346551.jpg',
//       catalog_type: 'product',
//       company_id: '151',
//       title: 'Item 2',
//       description: '',
//       cost: '120',
//       audience: 'standard',
//       delivery_method: '',
//       'quantity_available ': '',
//       subscription: 'annually',
//       incentives: '',
//       role_id: '',
//       created_by: '2515',
//       variations: '',
//       left_item: '20',
//       is_favourite: false,
//       favourite_id: '',
//       is_cart: false,
//       cart_id: '',
//       catalog_id: '239',
//     },
//     {
//       image:
//         'https://s3.amazonaws.com/build-dev/catalog_images/1715342372207.jpg',
//       catalog_type: 'product',
//       company_id: '151',
//       title: 'Item 3',
//       description: 'ADAd',
//       cost: '80',
//       audience: 'standard',
//       delivery_method: '',
//       'quantity_available ': '',
//       subscription: '',
//       incentives: '',
//       role_id: '',
//       created_by: '2515',
//       variations: '',
//       left_item: '10',
//       is_favourite: false,
//       favourite_id: '',
//       is_cart: false,
//       cart_id: '',
//       catalog_id: '240',
//     },
//     {
//       image:
//         'https://s3.amazonaws.com/build-dev/catalog_images/1716363764724.jpg',
//       catalog_type: 'product',
//       company_id: '151',
//       title: 'NaN product',
//       description: 'asdasdasd',
//       cost: '01',
//       audience: 'standard',
//       delivery_method: '',
//       'quantity_available ': '',
//       subscription: 'Custom',
//       incentives: '',
//       role_id: '',
//       created_by: '2515',
//       variations: '',
//       left_item: '56',
//       is_favourite: false,
//       favourite_id: '',
//       is_cart: false,
//       cart_id: '',
//       catalog_id: '266',
//     },
//   ];

const AddUserInCompany = async (url, item) => {
    try {
        const payload = {
            company_name: urlParts[0] ? urlParts[0].replace('-', ' ') : '',
            user_id: localStorage.getItem('id'),
        };

        const res = await commonApi.add_user_in_company(payload);
        const activeMenusResponse = await dispatch(getActiveCompanyUserMenus());

        if (res && activeMenusResponse) {
            const result = await checkMenuExists('offerings', activeMenusResponse);
          

            if (result) {
                history.push(url, { typeIs: 'business_offerings' });
            } else {
                errorMessage('This Menu is not available to you, Please contact your Administrator');
                history.push(activeMenusResponse.menus.menu_details[0].link);
            }
        }
    } catch (err) {
       
        return { type: 'error', message: err.message };
    }
};


const AddOutsideUserInCompany = (url,item) => {

    history.push(url, { typeIs: 'business_offerings' });



    // const payload = {
    //   company_name: urlParts[0] ? urlParts[0].replace('-', ' ') : '',
    //   user_id: localStorage.getItem('id'),
    // };
    // commonApi
    //   .add_user_in_company(payload)
    //   .then(res => {
    
    //     history.push(url, { typeIs: 'business_offerings' });
    //   })
    //   .catch(err => {
    
    //     return {type: 'error', message: err.message};
    //   });
  };
  
  return (

    <div className="refferal-list-wrapper">
    
   
    <div className='portfolio-tab-screen '>
       
      <div className="profile-meetups-tab offering_card_h">
        {companyOfferings && companyOfferings.length > 0 ? (
          <>
            <div className="">
              <Slider {...eventSlider}>
                {companyOfferings &&
                  companyOfferings.length > 0 &&
                  companyOfferings.slice(0, 7).map((item, index) => (
                    <div key={index}>
                      <div
                        className="event-card as-link"
                          onClick={() => {
                            const searchParams = new URLSearchParams();
                            searchParams.append('id', item.id);

                            if (getItem('id')) {
                             
                                const url = `/${
                                  urlParts[0]
                                }/offerings?${searchParams.toString()}`;

                                AddUserInCompany(url,item);
                           
                            } else {
                              setDataItem(item)
                              const url = `/${
                                urlParts[0]
                              }/offerings?${searchParams.toString()}`;
                              setShowLogin(url);
                            }
                          }}
                      >
                        {/* <div className="event-date">
                              Product
                            </div> */}

                        <h1> {item.title}</h1>
                        <div className="event-slider-image ">
                          <img
                            src={item.image ? item.image : DEFAULT_IMAGE}
                            alt=""
                          />
                        </div>

                        {/* <div className="event-type">
                            {' '}
                            <img
                              className="d-inline mr-1"
                              src={profileIcon}
                              alt=""
                            />
                            165 Networking and Referrals
                          </div> */}

                        <div className="event-location">
                          {item.catalog_type && item.catalog_type !== '' && (
                            <span>{item.catalog_type}</span>
                          )}

                          {item.cost && <span> ${item.cost}</span>}
                        </div>

                        {/* {item.cost  && (
                            <div className="card-button">
                              <Button
                                className="new-btn btn-green"
                                onClick={() => {}}>
                                Purchase Ticket
                              </Button>
                            </div>
                          )} */}
                      </div>
                    </div>
                  ))}
              </Slider>
            </div>
            {/* {meetupData.length > 7 && 
              <div className="show-more-btn" onClick={()=>{
                       setShowModal('sessionList');
                       setDataofSession(meetupData)
                      }}>show more</div>} */}
          </>
        ) : (
          <div className="profile_page_meetup_recruitement d-flex align-items-center justify-content-center ">
            {labels.no_record_found[selected_lang]}
          </div>
        )}
      </div>

     
</div>
        
    

    {showLogin && (
          <CustomModal
            // position="bottom"
            size="small"
            onClose={() => setShowLogin(null)}>
            <div className="leave-session-content">
              <h3>Login</h3>
              <p>For more information please login.</p>

              <div className="text-center">
                <Button
                  className="btn-dark"
                  onClick={() => {
                    // history.push('/login/session_invite/' + showLogin);
                    // history.push(showLogin);
                    AddOutsideUserInCompany(showLogin,dataItem)
                    setShowLogin(null);
                  }}>
                  Login
                </Button>
                <Button
                  className="btn-dark"
                  onClick={() => {
                    setShowLogin(null);
                  }}>
                  No
                </Button>
              </div>
            </div>
          </CustomModal>
        )}
    </div>
    
  );
};

export default RenderOferrings;
