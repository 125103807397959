import React, {useState} from 'react';
import Scrollbars from 'react-custom-scrollbars';
import SlidingPanel from '../SlidingPanel';
import CustomTabs from '../CustomTabs';
import Slider from 'react-slick';

import {RoiLogo} from '../../Constants/Images';
import EditPencilSvg from '../../Svg/EditPencilSvg';

export default function TransformDetail(props) {
  const ticketTabs = ['Before', 'After'];
  const [tabType, setTabType] = useState('Before');

  const imgSliderSettings = {
    dots: true,
    arrows: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: false,
  };

  const renderHeader = () => {
    return (
      <>
          <div className="search-tabs-icons createCircle d-flex gap-2">
            <span
              className="icon mt-0 as-link"
              onClick={() => {
                props.handleComponentOpen()
              //  props.setShowComponent({...props.showComponent, 
              //   createTransform: true,
              //   transformation: false
              // });
              }}>
              <EditPencilSvg fill={'white'} h={20} w={20} />
            </span>
          </div>
      
             {/* <img src={RoiLogo} alt="" /> */}
      </>
    );
  };

  return (
    <SlidingPanel
      hidePanel={() => props.onClose()}
      renderData={renderHeader}
      showBlackHeader={true}
      headerPadding={'24px 20px'}
      lastDivStyle={{borderRadius: '35px', background: '#f7f6f2'}}
      firstDivStyle={{
        padding: 0,
        background: 'linear-gradient(to bottom, #424242, #424242, #f7f6f2 50%)',
      }}>
      <div className="serach-filter-wrapper w-100">
        <div className="d-flex align-items-center justify-content-center fixed-width-inputs  black-head-form-pad-left w-100">
          <div className="create-ticket-tabs w-100">
            <CustomTabs
              darkmode
              tabs={ticketTabs}
              active={tabType}
              onSelect={item => setTabType(item)}
            />
          </div>
        </div>
        <div className="fixed-width-inputs  black-head-form-pad-left">
          <div
            className={`sidebar-heading-wrapper mt-0 ${
              props.withHeadingSpace || props.windowWidth <= 1295
                ? 'with-panel'
                : ''
            }`}>
            <div className="heading">
              <h2>{props.item.title}</h2>
            </div>
          </div>
        </div>

        <Scrollbars>
          <div className="fixed-width-inputs  black-head-form-pad-left ">
            <div className="events-wrapper">
              <div className="events-img-slider">
                <Slider {...imgSliderSettings} className="snapshot-slider">
                  {tabType === 'Before' &&
                    Object.entries(props.item.before_images).length > 0 &&
                    props.item.before_images.map((item, index) => (
                      <img src={item} alt="" key={index} />
                    ))}
                  {tabType === 'After' &&
                    Object.entries(props.item.after_images).length > 0 &&
                    props.item.after_images.map((item, index) => (
                      <img src={item} alt="" key={index} />
                    ))}
                </Slider>
              </div>

              <div className="events-headings">
                {/* <h2>{props.item.creator_name}</h2> */}
                <div className="d-flex align-item-center justify-content-between">
                  <span className="category-name">
                    {props.item.category_title}
                  </span>
                </div>
              </div>

              <div className="events-desc-wrapper">
                <p>{props.item.description}</p>
              </div>
            </div>
          </div>
        </Scrollbars>
      </div>
    </SlidingPanel>
  );
}
