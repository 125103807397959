import React, { useRef, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import CustomModal from '../CustomModal';
import { errorMessage } from '../../Utils/ToastMessages';
import Scrollbars from 'react-custom-scrollbars';
import EditPencilSvg from '../../Svg/EditPencilSvg';
import ConfirmationModel from '../ConfirmationModel/ConfirmationModel';

const IconModal = ({tagData,setTagData,closeModel}) => {
  // const [tagData, setTagData] = useState([]); 
  const [newTag, setNewTag] = useState(''); 
  const [editIndex, setEditIndex] = useState(null); 
  const [validated, setValidated] = useState(false);
  const buttonRef = useRef();
  const [tagDetail, setTagDetail] = useState({
    modal: false,
    value: ''
  });
  const handleClick = () => {
    if (buttonRef.current) {
      buttonRef.current.click();
    }
  };

  const handle = e => {
    const form = e.currentTarget;

    if (form.checkValidity() === false) {
        e.preventDefault();
        e.stopPropagation();
  
        if (tagData.length == 0 && form.checkValidity()) {
          errorMessage('Please write icon');
        } 
  
        setValidated(true);
      } else {
        e.preventDefault();
        const trimmedTitle = newTag.trim();

    // Check if the title is empty or consists only of whitespace
    if (trimmedTitle === '') {
        errorMessage('Tags cannot be added empty');
        return;
    }

        if (newTag) {
            if(!tagExists(newTag, editIndex)){
            if (editIndex !== null) {
              const updatedTags = [...tagData];
              updatedTags[editIndex] = newTag;
              setTagData(updatedTags);
              setEditIndex(null); 
            } else {
              setTagData([...tagData, newTag]); 
            }
            setNewTag(''); 
          }else{
            errorMessage('Icon already exists!')
          }
        }

      }
    
  };

  

  const tagExists = (value, id) => {
    
    return tagData.some((item,index )=> item === value && index  !== id);
  };


  const handleDeleteTag = value => {
    
    setTagDetail({
      modal: true,
      value: value
    })
 
  };


  const removeTag = ()=>{
    const updatedTags = tagData.filter((item) => item !== tagDetail?.value); 
    setTagData(updatedTags);
  }


  const handleEdit = index => {
    
    setNewTag(tagData[index]); 
    setEditIndex(index); 
  };


  return (
    <>
    <CustomModal
      size="full"
      style={{height: '100%'}}
      onClose={() => {
        closeModel();
      }}>
      <div className="create-template serach-filter-wrapper">
        <div
          className="sidebar-heading-wrapper mt-0 text-center"
          style={{display: 'inline', paddingRight: '11px'}}>
          <div className="filter-headings ">
            <h2 className="text-center">Tags</h2>
          </div>
        </div>
        <Scrollbars autoHide>
          <div className="" style={{paddingBottom: '32px'}}>
            <div className="create-project-content iconModelProjContent">
              <Form noValidate validated={validated} onSubmit={handle}>
                <Form.Label>Add Tags</Form.Label>
                <Form.Group className="form-group project-content-txtarea" controlId="tags">
                  <textarea
                    rows={5}
                    name="tags"
                    placeholder="Enter tags here"
                    value={newTag}
                    onChange={e => setNewTag(e.target.value)}
                  />
                </Form.Group>

                <div className="mb-5">
                  {tagData.length > 0 &&
                    tagData.map((tag, index) => (
                      <div
                        className="d-flex align-items-center phases_item mt-2 icon-alias-holder"
                        key={index}>
                        <div className="customer_name flex-1">
                          <div className="alias_name">{tag}</div>
                          <div style={{display: 'flex'}}>
                            <span 
                            style={{padding: '2px'}}
                             onClick={() => handleEdit(index)}
                             className="cross-icon-field">
                                <EditPencilSvg fill={'#a39f8d'} />
                            </span>
                            <span className="cross-icon-field">
                              <i
                                className="as-link fas fa-times"
                                onClick={() => handleDeleteTag(tag)}
                                style={{fontSize: '1.5rem', color: '#a39f8d',}}></i>
                            </span>
                          </div>
                        </div>
                      </div>
                    ))}
                </div>

                <Button
                  style={{display: 'none'}}
                  ref={buttonRef}
                  className="btn-dark m-0"
                  type="submit"></Button>
              </Form>
            </div>
          </div>
        </Scrollbars>
      </div>
      <div className="filter-btn-wrapper mt-2">
        <div className="invite-btn text-center bg_transparent pb-2 pt-2">
          <Button className="btn-dark mb-0 me-0" type="submit" onClick={handleClick}>
            {editIndex !== null ? 'Update' : 'Add'}
          </Button>
        </div>
      </div>
    </CustomModal>

{tagDetail?.modal && (
  <ConfirmationModel
    closeModel={() => {
      setTagDetail({
        modal: false,
        value: ''
      });
    }}
    discription={`Are you sure you want to delete this 
      tag `}
    buttonOne={'Yes'}
    buttonTwo={'No Thanks'}
    ButtonOneAction={() => {
      removeTag()
      setTagDetail(
        {
          modal: false,
          value: ''
        }
      );
    }}
  />
)}
</>
  );
};

export default IconModal;
