import React from 'react';
// import { useState} from 'react';
import {useHistory} from 'react-router-dom';
import API from '../../Services/SessionServices';
import {errorMessage, successMessage} from '../../Utils/ToastMessages';
import {DEFAULT_IMAGE} from '../../Utils/env';
import commonApi from '../../Services/CommonService';
import {amplitudeEvent} from '../../Services/AmplitudeServices';
import {getItem} from '../../Utils/LocalStorage';
import {fixName, goToZoom} from '../../Utils/commonUtils';
import {useDispatch} from 'react-redux';
import {manageFavourite} from '../../Store/Actions/DeepLinkAction';
import {getSessionListing} from '../../Store/Actions/SessionAction';
import {formatAmount} from '../../Utils/commonUtils';
import {UserGroupMini} from '../../Constants/Images';

const ShareAblesSessions = props => {
  const {item} = props;
  let totalMem = item.total_members + 1;
  let totalMode = item.total_moderators + 1;
  const history = useHistory();

  const heartClass = props.item.favourite ? 'fas' : 'far';
  const dispatch = useDispatch();

  const joinTheSession = async () => {
    var CheckLastSession = localStorage.getItem('session_started');
    // var hideCase = localStorage.setItem('hide_case', 'yes');
    if (CheckLastSession && CheckLastSession === item.id) {
      let payload = {conference_id: item.id};
      // const response = await API.is_session_started(payload);
      const response = await API.is_session_joined(payload);
      // if (response.started) {
      if (response.session_id) {
        // localStorage.setItem('conferenceAlias', response.conferenceAlias);
        // localStorage.setItem('conferenceId', response.session_id);
        // localStorage.setItem('conf_id', item.id);
        // history.push('/session-details');
        goToZoom(response.session_id);
      } else {
      }
      if (item.creator_id === getItem('id')) {
        amplitudeEvent('START_SESSION');
      } else {
        amplitudeEvent('JOIN_SESSION');
      }
      // } else {
      //   errorMessage(response.message);
      // }
    }
    if (!CheckLastSession) {
      let payload = {conference_id: item.id};
      const response = await API.is_session_started(payload);

      if (response.started) {
        if (response.session_id) {
          // localStorage.setItem('conferenceAlias', response.conferenceAlias);
          // localStorage.setItem('conferenceId', response.session_id);
          // localStorage.setItem('conf_id', item.id);
          // history.push('/session-details');
          goToZoom(response.session_id);
        } else {
        }
        if (item.creator_id === getItem('id')) {
          amplitudeEvent('START_SESSION');
        } else {
          amplitudeEvent('JOIN_SESSION');
        }
      } else {
        errorMessage(response.message);
      }
    }
    if (CheckLastSession && CheckLastSession !== item.id) {
      errorMessage(
        'Your are already in a session ' +
          getItem('session_started_name') +
          '.',
      );
    }
  };
  

  // useEffect(() => {
  //   item.favourite = favourite;
  // }, [favourite]);

  const favouriteOpportunity = () => {
    dispatch(getSessionListing(item.id));
    dispatch(manageFavourite(item.id));
    let payload = {
      conference_id: item.id,
    };
    commonApi
      .manage_favourite(payload)
      .then(res => {
        if (res.status === 200) {
          if (res.success) {
            // props.item.favourite = true;
            // setFavourite(true);
          } else {
            // props.item.favourite = false;
            // setFavourite(false);
          }
          successMessage(res.message);
        }
      })
      .catch(err => {
      
        return {type: 'error', message: err.message};
      });
  };

  const _renderCreatorImg = person => {
    return (
      <div className="img-item">
        {
          <span className="owner-icon">
            <i className="fas fa-crown" />
          </span>
        }
        {/* {!person.owner && person.isOnline && <span className="online-icon"></span>} */}
        {/* {person.speaker && (
                    <span className="speaker-icon">
                        <i className="fas fa-microphone" />
                    </span>
                )} */}
        <img
          src={person.creator_img === '' ? DEFAULT_IMAGE : person.creator_img}
          alt=""
        />
      </div>
    );
  };
  const _renderAudiImg = (person, speaking) => {
    return (
      <div key={`p-id-${person.id}`} className="img-item">
        {/* {!person.owner && person.isOnline && <span className="online-icon"></span>} */}
        {/* {person.speaker && (
                    <span className="speaker-icon">
                        <i className="fas fa-microphone" />
                    </span>
                )} */}
        {/* {speaking && (
                    <span className="speaker-icon">
                        <i className="fas fa-microphone" />
                    </span>
                )} */}
        <img
          src={person.profile_img === '' ? DEFAULT_IMAGE : person.profile_img}
          alt=""
        />
      </div>
    );
  };
  // const _renderUserImg = (person) => {
  //     return (
  //         <div className="img-item">
  //             {person.owner && (
  //                 <span className="owner-icon">
  //                     <i className="fas fa-crown" />
  //                 </span>
  //             )}
  //             {!person.owner && person.isOnline && <span className="online-icon"></span>}
  //             {person.speaker && (
  //                 <span className="speaker-icon">
  //                     <i className="fas fa-microphone" />
  //                 </span>
  //             )}
  //             <img src={person.image} alt="" />
  //         </div>
  //     );
  // };

  return (
    <div
      className={
        !props.typeMesages && !props.typeForNpClick
          ? 'as-link card-container'
          : 'as-link container-fromChat-Shareables'
      }>
      <div
        className={
          !props.typeMesages && !props.typeForNpClick
            ? 'padd-with-borderContaier'
            : ''
        }>
        <div
          className="session-heading"
          onClick={() => {
            if (props.typeMesages) {
              if (!props.typeForNpClick) {
                if (
                  props.serverTime &&
                  new Date(props.serverTime) < new Date(item.start_time)
                ) {
                  props.componentDecesion(item, 'session');
                  // 
                } else {
                  joinTheSession();
                  // 
                }
              }
            }
          }}>
          <h2>{item.session_title}</h2>
          <div className="events-place-like align-items-start">
            <div className="w-100">
              <span className="users-text usercount-type">
                <img src={UserGroupMini} height={16} width={16} alt="" />
                <span className="userCount">{`${totalMem}/${totalMode}`}</span>
                <span className="category-text">{item.category_name}</span>
              </span>
            </div>
            {props.heart === 'upcoming' && (
              <div className="session-share-favs">
                <span
                  className="event-heart as-link"
                  onClick={e => {
                    if (!props.typeForNpClick) {
                      e.stopPropagation();
                      props.componentDecesion(item, 'Refer');
                    }
                  }}>
                  <i className="fas fa-share" />
                </span>
              </div>
            )}
          </div>
        </div>
        <div
          className="persons-img-list"
          onClick={() => {
            if (props.typeMesages) {
              if (!props.typeForNpClick) {
                if (props.heart === 'upcoming' || props.upcoming === true) {
                  props.componentDecesion(item, 'session');
                } else {
                  joinTheSession();
                }
              }
            }
          }}>
          {/* {Object.entries(item.persons).length > 0 && item.persons.map((person, index) => _renderUserImg(person))} */}
          {_renderCreatorImg(item)}
          {!(item.moderator_ids === '') &&
            item.moderators_list &&
            item.moderators_list.length > 0 &&
            Object.entries(item.moderators_list).length > 0 &&
            item.moderators_list.slice(0, 4).map(person => {
              // if (index < 7) {
              return _renderAudiImg(person, true);
              // }
            })}
          {!(item.audience_ids === '') &&
            item.audience_list &&
            item.audience_list.length > 0 &&
            Object.entries(item.audience_list).length > 0 &&
            item.audience_list.slice(0, 3).map(person => {
              // if (index < 7) {
              return _renderAudiImg(person);
              // }
            })}
        </div>

        <div
          className="persons-name-list"
          onClick={() => {
            if (props.typeMesages) {
              if (!props.typeForNpClick) {
                if (props.heart === 'upcoming' || props.upcoming === true) {
                  props.componentDecesion(item, 'session');
                } else {
                  joinTheSession();
                }
              }
            }
          }}>
          <ul>
            <li key={`person-name-creator`}>
              <span className="owner-icon">
                <i className="fas fa-crown" />
              </span>
              {fixName(item.creator_first_name, item.creator_last_name)}
            </li>
            {!(item.moderator_ids === '') &&
              item.moderators_list &&
              item.moderators_list.length > 0 &&
              Object.entries(item.moderators_list).length > 0 &&
              item.moderators_list.slice(0, 4).map(person => (
                <li key={`person-mod-${person.id}`}>
                  {fixName(person.first_name, person.last_name)}
                  <span className="speaker-icon">
                    <i className="fas fa-microphone" />
                  </span>
                </li>
              ))}
            {!(item.audience_ids === '') &&
              item.audience_list &&
              item.audience_list.length > 0 &&
              Object.entries(item.audience_list).length > 0 &&
              item.audience_list.slice(0, 3).map(person => (
                // index < 5 &&
                <li key={`person-aud-${person.id}`}>
                  {/* {person.owner && (
                                <span className="owner-icon">
                                    <i className="fas fa-crown" />
                                </span>
                            )}
                            {!person.owner && person.speaker && (
                                <span className="speaker-icon">
                                    <i className="fas fa-microphone" />
                                </span>
                            )} */}
                  {fixName(person.first_name, person.last_name)}
                </li>
              ))}
          </ul>
        </div>

        <div className="events-place-like session-address-wrapper mt-2">
          <div>
            {item.address && (
              <span
                className="address as-link"
                onClick={() => {
                  if (props.typeMesages) {
                    if (!props.typeForNpClick) {
                      if (
                        props.heart === 'upcoming' ||
                        props.upcoming === true
                      ) {
                        props.componentDecesion(item, 'session');
                      } else {
                        joinTheSession();
                      }
                    }
                  }
                }}>
                <i className="fas fa-location-dot"></i> {item.address}
              </span>
            )}
          </div>

          {item.price && (
            <span className="text-right">
              {formatAmount(item.price ? item.price : '')}
            </span>
          )}

          {props.heart === 'upcoming' && !props.typeMesages && (
            <div className="session-share-favs">
              {/* <span
              className="event-heart as-link"
              onClick={() => {
                props.componentDecesion(item, 'Refer');
              }}>
              <i className="fas fa-share" />
            </span> */}
              <span
                className="event-heart as-link"
                onClick={() => {
                  favouriteOpportunity();
                }}>
                <i className={`${heartClass} fa-heart`} />
              </span>
            </div>
          )}
        </div>
      </div>

      {!props.typeMesages &&
        !props.type &&
        !props.typeForNpClick &&
        props.ElementDesign('conference', props.item)}
    </div>
  );
};

export default ShareAblesSessions;
