import React from 'react';
import { Button, } from 'react-bootstrap';
import commonApi from '../../Services/CommonService';
import { useState} from 'react';
import LoaderModal from '../Modals/LoaderModal';
import {successMessage} from '../../Utils/ToastMessages';
import CustomModal from '../CustomModal';

const SaveCard = props => {
  const [showLoader, setshowLoader] = useState(false);
  const [showModel, setShowModel] = useState('');
  const [cardId, setCardId] = useState('');

  const removeCard = id => {
    commonApi
      .remove_card({card_id: id})
      .then(res => {
        if (res.status === 200) {
          successMessage(res.message);
          setshowLoader(false);
          props.getMyCard();
        }
      })
      .catch(err => {
        
        return {type: 'error', message: err.message};
      });
  };
  const updateCardStatus = id => {
    let payload = {
      card_id: id,
      type: 'default',
    };
    commonApi
      .update_card_status(payload)
      .then(res => {
        if (res.status === 200) {
          successMessage(res.message);
          setshowLoader(false);
          props.getMyCard();
        }
      })
      .catch(err => {
        
        return {type: 'error', message: err.message};
      });
  };

  return (
    <>
      {showLoader && <LoaderModal show={showLoader} />}

      <div className="saved-card-wrapper">
        <h3>Saved Cards</h3>
        {Object.entries(props.defaultCard).length > 0 ? (
          Object.entries(props.defaultCard).length > 0 &&
          props.defaultCard.map((item, index) => (
            <div className="saved-card-item card" key={'meyou' + index}>
              <span>XXXX XXXX XXXX {item.card_number.substr(-4)}</span>

              <div className="remove-icons">
                {item.card_type === 'other' && (
                  <span
                    className="icon"
                    onClick={() => {
                      updateCardStatus(item.id);
                    }}>
                    <i className="far fa-circle" />
                  </span>
                )}
                {item.card_type === 'default' && (
                  <span className="icon ative">
                    <i className="fas fa-circle-check" />

                    {/* {item.card_type.charAt(0).toUpperCase() +
                      item.card_type.slice(1)} */}
                  </span>
                )}

                <span
                  className="remove"
                  onClick={() => {
                    setCardId(item.id);
                    if (props.setHideScrollBar) {
                      props.setHideScrollBar('card_delete_model');
                    }
                    setShowModel('card_delete_model');
                  }}>
                  <i className="fas fa-times" />
                </span>
              </div>
            </div>
          ))
        ) : (
          <></>
        )}
      </div>
      {showModel === 'card_delete_model' && cardId && (
        <CustomModal
          className="shareables-workspace-modal"
          size="large"
          onClose={() => {
            setShowModel('');
            props.setHideScrollBar('');
          }}>
          <div className="remove-user-text">
            <b>Delete! </b>
            <div>
              <p className="mt-2">Do you want to delete this card?</p>
            </div>
            <Button
              className="btn-dark"
              onClick={() => {
                removeCard(cardId);
                props.setHideScrollBar('');
                setShowModel('');
              }}>
              Yes
            </Button>
            <Button
              className="btn-dark"
              onClick={() => {
                props.setHideScrollBar('');
                setShowModel('');
              }}>
              No
            </Button>
          </div>
        </CustomModal>
      )}
    </>
  );
};

export default SaveCard;
