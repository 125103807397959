import React, {useState, useEffect, useRef, Fragment} from 'react';
import {Button, Form, Dropdown} from 'react-bootstrap';
import commonApi from '../../../Services/CommonService';
import Scrollbars from 'react-custom-scrollbars';
import {errorMessage, successMessage} from '../../../Utils/ToastMessages';
import {CustomModal, SlidingPanel} from '../../../Components';
// import {DEFAULT_IMAGE} from '../../../Utils/env';
import SelectedReferUser from '../../../Components/Referral/SelectedReferUser';
import CustomTabs from '../../../Components/CustomTabs';
import {labels} from '../../../Constants/Translations';
import {selected_lang} from '../../../Utils/env';
import LoaderSpinner from '../../../Components/Modals/LoaderSpinner';
import {cameraicon, RoiLogo, upload_file} from '../../../Constants/Images';
import Slider from 'react-slick';
import EditPencilSvg from '../../../Svg/EditPencilSvg';
import ConfirmationModel from '../../../Components/ConfirmationModel/ConfirmationModel';
import IconModal from '../../../Components/SessionDetails/IconModal';
import ImagePreview from '../../../Components/Modals/ImagePreview';
import CropImage from '../../../Components/Cropper/CropImage';
import Upload from '../../../Components/Project/Upload';
import ViewFile from '../../../Components/UplioadFile/ViewFile';
import {
  changeFileVariable,
  formatPhoneNumber,
  getCityStateFromAddress,
  getMaxDateFromToday,
  validPhoneNumber,
} from '../../../Utils/commonUtils';
import GooglePlacesAutocomplete, {
  geocodeByPlaceId,
  getLatLng,
} from 'react-google-places-autocomplete';
import $ from 'jquery';
import ProfileTags from '../../../Components/ProfilePage/ProfileTags';
import Calender from '../../../Components/FeedBack/Calender';
import moment from 'moment/moment';
import DatePanel from 'react-multi-date-picker/plugins/date_panel';

const SendInvitation = props => {
  const [validated, setValidated] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [PhoneNo, setPhonenNo] = useState(
    props.phoneNumber ? props.phoneNumber : '',
  );
  const [email, setEmail] = useState(
    props.phoneNumber ? props.phoneNumber : '',
  );

  const settings = {
    dots: false,
    infinite: false,
    centerMode: false,
    variableWidth: true,
    speed: 500,
    arrows: false,
    slidesToShow: 3,
    slidesToScroll: 3,
  };
  const [participantArray, setParticipantArraytPhonenNo] = useState([]);
  const [showComponent, setShowComponent] = useState('');
  const [tabType, setTabType] = useState('Business');
  const [contactRelations, setContactRelations] = useState([]);
  const [pocRoles, setPocRoles] = useState([]);
  const [pocName, setPocName] = useState('');
  const [relationType, setRelationType] = useState('1');
  const [showLoader, setShowLoader] = useState(false);
  const [emailToggle, setEmailToggle] = useState(false);

  const [showContactDetails, setShowContactDetails] = useState(true);
  const [showPOCs, setShowPOCs] = useState(true);
  const [showAdditionalDetail, setShowAdditionalDetail] = useState(true);
  const [showAttachments, setShowAttachments] = useState(true);
  const [documentInfo, setDocumentInfo] = useState([]);
  const [index, setIndex] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState({});
  const [tagTitleList, setTagTitleList] = useState([]);
  const [showModal, setShowModal] = useState('');
  const [ticketImg, setTicketImg] = useState('');
  const [pocImage, setPocImage] = useState('');
  const [sliderImage, setSliderImage] = useState('');
  const [modelImg, setModelImg] = useState('');
  const [cropImg, setCropImg] = useState(false);
  const [file, setFile] = useState('');
  const [showFileComponent, setShowFileComponent] = useState(false);
  const [editProject, setEditProject] = useState(false);
  const [uploadFilesListing, setUploadFilesListing] = useState([]);
  const allowedImageTypes = ['image/jpeg', 'image/png', 'image/jpg'];
  const [locationCity, setLocationCity] = useState('');
  const [locationState, setLocationState] = useState('');
  const [inviteAddressLocation, setInviteAddressLocation] = useState('');
  const [inviteAddressLatitude, setInviteAddressLatitude] = useState('');
  const [showLocationError, setShowLocationError] = useState(false);
  const [latitude, setLatitude] = useState('');
  const [longitude, setLongitude] = useState('');
  const [location, setLocation] = useState('');
  const [error, setError] = useState('');
  const autocompleteRef = useRef();
  const fileInput = useRef(null); // Define the reference to the file input
  const [isValid, setIsValid] = useState(true);
  const [showPocError, setShowPocError] = useState(false);
  const [validPocPhone, setValidPocPhone] = useState(false);

  

  const [contactData, setcontactData] = useState({
    name: '',
    role: '',
    phoneNo: '',
    address: '',
    pocListing: [],
    website: '',
    timeZone: '',
    contactSource: '',
    birthDate: '',
    date_established: '',
    tags: [],
    image: '',
    attachments: [],
  });
  const [pocDetail, setPocDetail] = useState({
    name: '',
    email: '',
    phone: '',
    role: '',
    poc_image: '',
  });
 

  const handleChange = e => {
    const {name, value} = e.target;

    // Handle phone number validation
    if (name === 'phoneNo') {
      // Allow only valid characters (+ and digits)
      const formattedValue = value.replace(/[^\d+]/g, '');

      const checkValidPhone = validPhoneNumber(value);

      // Update contact data with the formatted value
      setcontactData({
        ...contactData,
        [name]: formattedValue,
      });

      // Update validity state
      setIsValid(checkValidPhone);

      // Update validity state
    } else {
      // Handle other fields
      setcontactData({
        ...contactData,
        [name]: value,
      });
    }

  
  };

  const handlePoc = e => {
    const {value, name} = e.target;
    

    if (name === 'phone') {
      const formattedValue = value.replace(/[^\d+]/g, '');
      setPocDetail({
        ...pocDetail,
        [name]: formattedValue,
      });
      setValidPocPhone(false);
    } else {
      setPocDetail({
        ...pocDetail,
        [name]: value,
      });
    }
  };

  const resetPOc = () => {
    setPocDetail(prev => ({
      ...prev, // Preserve other properties
      name: '',
      email: '',
      phone: '',
    }));
  };

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(
      position => {
        setLatitude(position.coords.latitude);
        setLongitude(position.coords.longitude);

        fetch(
          `https://maps.googleapis.com/maps/api/geocode/json?latlng=${position.coords.latitude},${position.coords.longitude}&key=AIzaSyAUwMSFtdT541UlTa2ucZ1EORtWqwz0R7s`,
        )
          .then(response => response.json())
          .then(data => {
            //
            if (data.status === 'OK') {
              
              const cityResult = data.results[0].address_components.find(
                component => component.types.includes('locality'),
              );
              const countryResult = data.results[0].address_components.find(
                component => component.types.includes('country'),
              );

              const city = cityResult ? cityResult.long_name : '';
              const country = countryResult ? countryResult.long_name : '';

              $('.css-1uccc91-singleValue').html(city + ', ' + country);
              // setLocation('');
              setLocationCity(city);
              setLocationState(country);
            } else {
              setError('Error getting location city and state');
            }
          })
          .catch(error => setError(error.message));
      },
      error => setError(error.message),
      {enableHighAccuracy: true, timeout: 20000, maximumAge: 1000},
    );
  }, []);

  console.log(
    validPocPhone,
    'validPocPhonevalidPocPhonevalidPocPhonevalidPocPhone',
  );
  const handlePocListing = e => {
    const checkValidPhone = validPhoneNumber(pocDetail.phone);
   

    if (
      pocDetail.name !== '' &&
      pocDetail.email !== '' &&
      pocDetail.phone !== ''
    ) {
      if (index !== null) {
        if (checkValidPhone === false) {
          setValidPocPhone(true);
        } else {
          const updatedPocListing = [...contactData.pocListing];
          updatedPocListing[index] = {...pocDetail};
          setcontactData(prev => ({...prev, pocListing: updatedPocListing}));
          setIndex(null);
          resetPOc();
          setShowPocError(false);
          setValidPocPhone(false);
        }
      } else {
        if (checkValidPhone === false) {
          setValidPocPhone(true);
        } else {
          setcontactData(prevData => ({
            ...prevData,
            pocListing: [...prevData.pocListing, pocDetail],
          }));
          resetPOc();
          setShowPocError(false);
          setValidPocPhone(false);
        }
      }
    } else {
      setShowPocError(true);
      if (checkValidPhone === false) {
        setValidPocPhone(true);
      }
    }
  };

  const handleEdit = (item, index) => {
    setPocDetail({
      name: item.name,
      email: item.email,
      phone: item.phone,
      role: item.role,
    });
    setIndex(index);
  };
  const handleDelete = item => {
    const updatedPocListing = contactData.pocListing.filter(
      (_, i) => i !== contactData.pocListing.indexOf(item),
    );
    setcontactData(prev => ({...prev, pocListing: updatedPocListing}));
    setShowDeleteModal(prev => ({
      open: false,
      item: '',
    }));
  };

  useEffect(() => {
    if (tagTitleList?.length > 0) {
      setcontactData(prev => ({...prev, tags: tagTitleList}));
    }
  }, [tagTitleList]);

  useEffect(() => {
    if (ticketImg) {
      setcontactData(prev => ({...prev, image: ticketImg}));
    }
  }, [ticketImg]);

  useEffect(() => {
    if (pocImage) {
      setPocDetail(prev => ({
        ...prev, // Preserve other properties
        poc_image: pocImage, // Update poc_image with the new string
      }));
    }
  }, [pocImage]);

  useEffect(() => {
    if (uploadFilesListing.length > 0) {
      setcontactData(prev => ({...prev, attachments: uploadFilesListing}));
    }
  }, [uploadFilesListing]);

  const changeTicketImg = (e, type) => {
    if (e.target.files.length === 0) return false;
    const file = e.target.files[0];

    if (!/^image\//.test(file.type)) {
      errorMessage(`File is not an image.`);
      return false;
    } else if (allowedImageTypes.includes(file.type)) {
      setFile(file);
      setCropImg(true);
      if (type === 'profile') {
        setShowModal('image_modal');
      } else {
        setShowModal('poc_image_modal');
      }

      e.target.value = null;
    } else {
      errorMessage('The file format is not supported');
      e.target.value = null; // reset the file input
    }
  };

  const handleFiles = (item, type) => {
    
    if (type === 'delete') {
      setShowModal('confirmation_Model');
      setDocumentInfo(item);
    } else if (type === 'upload') {
      setUploadFilesListing([
        ...uploadFilesListing,
        {
          file: item?.file_url,
        },
      ]);
    } else if (type === 'open') {
      setShowFileComponent(true);

      setDocumentInfo(changeFileVariable(item));
    }
  };

  console.log(
    uploadFilesListing,
    'uploadFilesListinguploadFilesListinguploadFilesListingdd',
  );

  const delete_document_files = () => {
    const deleteFile =
      uploadFilesListing?.length > 0 &&
      uploadFilesListing?.filter(item => item.file !== documentInfo.file);
    setUploadFilesListing(deleteFile);
    setShowModal('');
  };

  const buttonRef = useRef(null);
  // const inviteButton = useRef(null);
  const validateFields = useRef(false);

  console.log(
    validateFields,
    'validateFieldsvalidateFieldsvalidateFieldsvalidateFields',
  );

  const ticketTabs = [
    labels.qr_type_business[selected_lang],
    labels.type_individual[selected_lang],
  ];

  const clearAll = () => {
    setInviteAddressLocation('');
    setLatitude('');
    setLongitude('');
    $('.css-1uccc91-singleValue').html('Address');
  };

  const handle = event => {
    const form = event.currentTarget;

    if (form.checkValidity() === false || (validateFields.current && !contactData.email && !contactData.phoneNo)) {
      event.preventDefault();
      event.stopPropagation();
      setValidated(true);
      setShowPocError(false);
      if (
        (contactData.name === '' || contactData.email) &&
        !showContactDetails
      ) {
        if (tabType === labels.type_individual[selected_lang]) {
          errorMessage('Please enter contact details.');
        } else {
          errorMessage('Please enter company details.');
        }
      }

      // if(inviteAddressLocation === ''){
      //   setShowLocationError(true)
      // }
    } else {
      event.preventDefault();
      setValidated(false);
      setShowLocationError(false);
      setShowPocError(false);
      // if (contactData.pocListing.length === 0) {
      //   errorMessage('Please add POCs');
      // } else if (contactData.image === '') {
      //   errorMessage('Please upload image');
      // }

      let place_id =
        typeof inviteAddressLocation === 'object'
          ? inviteAddressLocation.value.place_id
          : typeof inviteAddressLocation === 'string'
          ? ''
          : '';

      if (place_id) {
        geocodeByPlaceId(place_id)
          .then(results => {
            const geocodingResult = results[0];
            return Promise.all([
              getLatLng(geocodingResult),
              getCityStateFromAddress(geocodingResult),
            ]);
          })
          .then(([latLngResult, cityStateResult]) => {
            const {lat, lng} = latLngResult;
            sendnvitationOutIside(lat, lng);
          })
          .catch(error => {
            console.error('An error occurred:', error);
          });
      } else {
        sendnvitationOutIside();
      }

      // referOutside();
      // alert('d')
      // checkInputType();

      //
    }
  };
  //

  const checkInputType = () => {
    // if (PhoneNo.match(/^\d+$/) || PhoneNo.match(/^\+\d+$/)) {
    //   // alert('Data contains only numbers.');
    //   checkExist(PhoneNo);
    // } else if (PhoneNo.match(/^[A-Za-z]+$/)) {
    //   // alert('Data contains only words (letters).');
    //   referOutside();
    // } else if (PhoneNo.match(/^[A-Za-z\d]+$/)) {
    //   // alert('Data contains both words and numbers.');
    //   referOutside();
    // } else {
    // alert('Data contains other characters or is empty.');

    if (props.addBusinessPOC) {
      addPocInBusinessProfile();
    } else {
      referOutside();
    }

    // }
  };

  const addPocInBusinessProfile = () => {
    let Payload = {
      poc_name: pocName,
      contact:
        /^\d+$/.test(PhoneNo) && !PhoneNo.includes('+')
          ? '+' + PhoneNo
          : PhoneNo,
      contact_email: emailToggle ? email : '',
      linked_user_id:
        props.addBusinessPOC && props.businessProfileId !== ''
          ? props.businessProfileId
          : '',
    };

    commonApi
      .add_single_user_in_linked_user_poc(Payload)
      .then(res => {
        if (res.status === 200 && res.success) {
          successMessage(res.message);
          props.onCloseHitApi && props.onCloseHitApi();
          props.onClose();
        }
        if (res.success === false) {
          errorMessage(res.message);
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };
  const referOutside = type => {
    let Payload = {
      poc_name: pocName,
      contact: type === 'number' ? formatPhoneNumber(PhoneNo) : PhoneNo,
      fname: firstName,
      relationship_id: relationType,
      user_type: tabType === 'Business' ? 'business' : 'individual',
      invite_only: emailToggle,
      contact_email: emailToggle ? email : '',
    };

    if (type === 'number') {
      if (PhoneNo.includes('+')) {
        // setParticipantArraytPhonenNo(previous => [...previous, Payload]);
        sendnvitationOutIside(Payload);
      } else {
        // alert('')
        let PayloadData = {
          poc_name: pocName,
          contact: PhoneNo ? formatPhoneNumber(PhoneNo) : '',
          fname: firstName,
          relationship_id: relationType,
          user_type: tabType === 'Business' ? 'business' : 'individual',
          invite_only: emailToggle,
          contact_email: emailToggle ? email : '',
        };
        // setParticipantArraytPhonenNo(previous => [...previous, PayloadData]);

        sendnvitationOutIside(PayloadData);
      }
    } else {
      sendnvitationOutIside(Payload);
    }
    // alert('2')
    if (props.contact) {
      props.setContact(previous => [...previous, Payload]);
    }

    setFirstName('');
    setPhonenNo('');
    setEmail('');
    setPocName('');
    setValidated(false);
    if (props.type === 'schedule') {
      const UpdatedArray = [...participantArray, Payload];
      props.setContact(previous => [...previous, Payload]);
      sendnvitationOutIside(UpdatedArray);
    }
  };

  const sendnvitationOutIside = (lat, lng) => {

    let address =
      typeof inviteAddressLocation === 'object'
        ? inviteAddressLocation.label
        : typeof inviteAddressLocation === 'string'
        ? inviteAddressLocation
        : '';

    const urlsFormat = uploadFilesListing.map(item => item.file);

    const pocDetailFormat = contactData.pocListing.map(item => ({
      poc_phone: item.phone,
      poc_name: item.name,
      poc_email: item.email,
      profile_image: item.poc_image ? item.poc_image.toString() : '', // Default value for the new field
      role: item.role,
    }));

    const getTagsID = contactData?.tags.map(item => item.id);

    const payload = {
      contacts: [
        {
          contact: contactData.phoneNo ? formatPhoneNumber(contactData.phoneNo) : '',
          relationship_id: relationType,
          user_type: tabType,
          fname: contactData.name,
          invite_only: false,
          contact_email: contactData.email,
          current_location: locationCity + ', ' + locationState,
          current_location_latitude: latitude, // new fields
          current_location_longitude: longitude, // new fields
          invite_address: address,
          invite_address_latitude:
            typeof inviteAddressLatitude === 'object'
              ? lat
              : typeof inviteAddressLatitude === 'string'
              ? latitude
              : '', // new fields
          invite_address_longitude:
            typeof inviteAddressLocation === 'object'
              ? lng
              : typeof inviteAddressLocation === 'string'
              ? longitude
              : '', // new fields
          web: contactData.website,
          time_zone: contactData.timeZone,
          birth_date: contactData.birthDate,
          date_established: contactData.date_established,
          contact_source: contactData.contactSource,
          profile_image: contactData.image.toString(), // new fields
          tags: getTagsID ? getTagsID : [],
          poc_details: pocDetailFormat ? pocDetailFormat : [],
          attachments: urlsFormat ? urlsFormat : '',
        },
      ],
    };

    // return;

    commonApi
      .send_invitation_outside(payload)
      .then(res => {
        if (res.status === 200) {
          successMessage(res.message);
          resetPOc();
          setcontactData({
            name: '',
            role: '',
            phoneNo: '',
            address: '',
            pocListing: [],
            website: '',
            timeZone: '',
            contactSource: '',
            birthDate: '',
            date_established: '',
            tags: [],
            image: '',
            attachments: [],
            email: ''
          })
          setUploadFilesListing([])
          // if (props.type === 'Catalog' || (props.hitApi && props.hitApi)) {
          //   props.onCloseHitApi && props.onCloseHitApi();
          // }
          // if (props.setContact) {
          //   props.setContact(previous => [...previous, Updated]);

          //   setParticipantArraytPhonenNo([]);
          // }
          if (props.setCheckUserHitApi) {
            props.setCheckUserHitApi(!props.checkUserHitApi);
          }

          props.onClose && props.onClose()

          props.onCloseApiHit && props.onCloseApiHit();
        }
        if (res.status === false) {
          errorMessage(res.message);
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };
  // const sendnvitationOutIside = Updated => {
  //   return;
  //   var Payload;

  //   if (props.type === 'schedule') {
  //     Payload = {
  //       contacts: [Updated],
  //     };
  //   } else {
  //     Payload = {
  //       contacts: [Updated],
  //     };
  //   }

  //   commonApi
  //     .send_invitation_outside(Payload)
  //     .then(res => {
  //       if (res.status === 200) {
  //         successMessage(res.message);
  //         if (props.type === 'Catalog' || (props.hitApi && props.hitApi)) {
  //           props.onCloseHitApi && props.onCloseHitApi();
  //         }
  //         if (props.setContact) {
  //           props.setContact(previous => [...previous, Updated]);

  //           setParticipantArraytPhonenNo([]);
  //         }
  //         if (props.setCheckUserHitApi) {
  //           props.setCheckUserHitApi(!props.checkUserHitApi);
  //         }

  //         props.onCloseApiHit();
  //       }
  //       if (res.status === false) {
  //         errorMessage(res.message);
  //       }
  //     })
  //     .catch(err => {
  //       console.log('Err', err);
  //       return {type: 'error', message: err.message};
  //     });
  // };

  useEffect(() => {
    setShowLoader(true);
    getContactRelationTypes();
    get_poc_roles();
  }, []);
  useEffect(() => {
    setFirstName('');
    setPhonenNo(props.phoneNumber ? props.phoneNumber : '');
    setPocName('');
    setEmail(props.phoneNumber ? props.phoneNumber : '');
    setIsValid(true);
    setShowPocError(false);
  }, [tabType]);

  const getContactRelationTypes = () => {
    commonApi
      .get_contact_relation_types()
      .then(res => {
        if (res.data.length > 0) {
          setContactRelations(res.data);
          setTimeout(() => {
            setShowLoader(false);
          }, 1200);
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  const get_poc_roles = () => {
    commonApi
      .get_poc_roles()
      .then(res => {
        if (res.user_poc_roles.length > 0) {
          setPocRoles(res.user_poc_roles);
          setPocDetail({
            ...pocDetail,
            role: res.user_poc_roles[0].id,
          });
          setTimeout(() => {
            setShowLoader(false);
          }, 1200);
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  let jugar =
    typeof inviteAddressLocation === 'object'
      ? inviteAddressLocation.label
      : typeof inviteAddressLocation === 'string' &&
        inviteAddressLocation !== ''
      ? inviteAddressLocation
      : 'Address';

  console.log(
    inviteAddressLocation,
    '==========>>>>>>>>>>>locationlocationlocation',
  );

  const renderProfileTagsButtons = () => {
    // Return the JSX element conditionally based on props.permission
    return (
      <>
        {/* <img src={RoiLogo} alt="" /> */}
        {/* <div className="flex-1 form-control">

        <GooglePlacesAutocomplete
                location
                selectProps={{
                  location,
                  onChange: setInviteAddressLocation,
                  name: 'city',
                  placeholder: jugar,
                  noOptionsMessage: () => null,
                }}
              />
              </div> */}
        <i className="icon-map-pin"></i>
      </>
    );
  };

  // how to check bootstrap validtaion on form when user press enter burron in react js

  // const tagList = [
  //   {
  //     id: '180',
  //     company_id: '257',
  //     title: 'New Test Tag',
  //     icon: '',
  //     description: 'NewTag',
  //     read_role_ids: ['710', '711'],
  //     update_role_ids: ['710', '711'],
  //     created_by: '1364',
  //     created_at: '2025-01-06 03:02:13',
  //     updated_by: '1364',
  //     updated_at: '2025-01-06 05:02:25',
  //     assign_details: ['2516'],
  //     role_titles: [
  //       {
  //         id: '710',
  //         role_alias: 'Customer',
  //         read: true,
  //         update: true,
  //       },
  //       {
  //         id: '711',
  //         role_alias: 'co-founder',
  //         read: true,
  //         update: true,
  //       },
  //     ],
  //   },
  //   {
  //     id: '67',
  //     company_id: '257',
  //     title: 'you boy',
  //     icon: 'https://s3.amazonaws.com/build-dev/company_tags/1723207471764.jpg',
  //     description: 'www',
  //     read_role_ids: ['711'],
  //     update_role_ids: [],
  //     created_by: '1364',
  //     created_at: '2024-08-09 08:44:41',
  //     updated_by: '1364',
  //     updated_at: '2024-08-09 08:46:04',
  //     assign_details: ['3043', '2515', '3916', '1865', '2516'],
  //     role_titles: [
  //       {
  //         id: '711',
  //         role_alias: 'co-founder',
  //         read: true,
  //         update: false,
  //       },
  //     ],
  //   },
  //   {
  //     id: '63',
  //     company_id: '257',
  //     title: 'title',
  //     icon: '',
  //     description: 'cxvxc',
  //     read_role_ids: ['711'],
  //     update_role_ids: [],
  //     created_by: '4185',
  //     created_at: '2024-08-09 08:25:43',
  //     updated_by: '0',
  //     updated_at: '2024-08-09 08:25:43',
  //     assign_details: ['3043', '1865', '2515', '3916', '2516'],
  //     role_titles: [
  //       {
  //         id: '711',
  //         role_alias: 'co-founder',
  //         read: true,
  //         update: false,
  //       },
  //     ],
  //   },
  //   {
  //     id: '61',
  //     company_id: '257',
  //     title: 'Abcdefg ads adssd asdasd asd a da',
  //     icon: 'https://s3.amazonaws.com/build-dev/company_tags/172320473537.jpg',
  //     description: 'Abcdefg',
  //     read_role_ids: ['710', '711'],
  //     update_role_ids: ['711'],
  //     created_by: '1364',
  //     created_at: '2024-08-09 07:59:09',
  //     updated_by: '1364',
  //     updated_at: '2025-01-06 02:44:36',
  //     assign_details: ['3043', '2516'],
  //     role_titles: [
  //       {
  //         id: '710',
  //         role_alias: 'Customer',
  //         read: true,
  //         update: false,
  //       },
  //       {
  //         id: '711',
  //         role_alias: 'co-founder',
  //         read: true,
  //         update: true,
  //       },
  //     ],
  //   },
  // ];
  return (
    <>
      <SlidingPanel
        renderData={renderProfileTagsButtons}
        showBlackHeader={true}
        lastDivStyle={{borderRadius: '35px', background: '#f7f6f2'}}
        firstDivStyle={{
          padding: 0,
          background:
            'linear-gradient(to bottom, #424242, #424242, #f7f6f2 50%)',
        }}
        hidePanel={() => {
          props.onClose();
        }}>
        {showLoader && <LoaderSpinner className={'curved-spinner-bg'} />}
        <div className="referal_page_content">
          <div className="refer_outside add-contacts referal_page_content">
            <div className="sidebar-heading-wrapper fixed-width-inputs fix-width-forms pt-3 black-head-form-pad-left black-head-form-pad-right">
              <p className="p-bold m-0 f_600_h">
                {props.snapshotIds ? 'Refer Outside ROI' : 'Add Contact'}
              </p>
            </div>

            {!props.addBusinessPOC && (
              <div className="d-flex align-items-center justify-content-center w-100">
                <div className="create-ticket-tabs w-100">
                  <CustomTabs
                    darkmode
                    tabs={ticketTabs}
                    active={tabType}
                    onSelect={item => {
                      setTabType(item);
                      validateFields.current = false;
                      setValidated(false);
                      setEmailToggle(false);
                    }}
                  />
                </div>
              </div>
            )}
            <Scrollbars className="custom-scroll">
              <div>
                <Form
                  className="send-refferal-form-input-holder"
                  noValidate
                  validated={validated}
                  onSubmit={handle}>
                  <div className=" p-4 rounded-md">
                    {/* Contact Details */}
                    <div className="bg-white rounded-md shadow-md mb-2">
                      <div
                        className="flex justify-between items-center p-3 cursor-pointer"
                        onClick={() =>
                          setShowContactDetails(!showContactDetails)
                        }>
                        <h2 className="text-base font-semibold">
                          {tabType === labels.type_individual[selected_lang]
                            ? 'Contact Details'
                            : 'Company Details'}
                        </h2>
                        {/* <button className="text-gray-500">
                          {showContactDetails ? '▲' : '▼'}
                        </button> */}
                      </div>
                      <div
                        className={`overflow-hidden overflow-y-auto custom-scrollbar transition-all duration-300 ${
                          showContactDetails ? 'max-h-screen' : 'max-h-0'
                        }`}>
                        <hr class="border-t border-gray-300 pb-3"></hr>
                        <div className="p-4 pt-0 space-y-2">
                          <div>
                            <Form.Control
                              type="text"
                              value={contactData.name}
                              name="name"
                              pattern="^\S.*$"
                              placeholder={
                                tabType ===
                                labels.type_individual[selected_lang]
                                  ? 'Name'
                                  : 'Company name'
                              }
                              onChange={handleChange}
                              required
                            />
                            <Form.Control.Feedback type="invalid">
                              {tabType === labels.type_individual[selected_lang]
                                ? 'Name is required.'
                                : 'Company name is required.'}
                            </Form.Control.Feedback>
                          </div>

                          <div className="roi-form-dropdown ">
                            <div className="select-wrapper">
                              <Dropdown
                                onSelect={(eventKey, event) => {
                                  setRelationType(eventKey);
                                }}
                                drop="down">
                                <Dropdown.Toggle variant="primary">
                                  {relationType
                                    ? contactRelations.find(
                                        item => item.id === relationType,
                                      )?.relations
                                    : 'Select Role'}
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                  {contactRelations.map((item, index) => (
                                    <Dropdown.Item
                                      key={`st-${index}`}
                                      eventKey={item.id.toString()}
                                      active={item.id === relationType}>
                                      {item.relations}
                                    </Dropdown.Item>
                                  ))}
                                </Dropdown.Menu>
                              </Dropdown>
                            </div>
                          </div>
                          <div>
                            <Form.Control
                              type="email"
                              value={contactData.email}
                              name="email"
                              required={validateFields.current && !contactData.phoneNo}
                              pattern="^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$"
                              onChange={handleChange}
                              placeholder={'Email'}
                            />
                            <Form.Control.Feedback type="invalid">
                              {contactData.email ? 'Invalid email' : 'Email is required.'}
                            </Form.Control.Feedback>
                          </div>
                          <div>
                            <Form.Control
                              type="text"
                              name="phoneNo"
                              value={contactData.phoneNo}
                              onChange={handleChange}
                              placeholder={'Phone number'}
                              // isInvalid={validateFields.current && !isValid}
                              required={validateFields.current && !contactData.email}
                              pattern="^(?:\+92|92)?0?3\d{9}$|^(?:\+91|91)?[6-9]\d{9}$|^(?:\+1|1)?\d{10}$"
                            />
                            <Form.Control.Feedback type="invalid">
                              {contactData.phoneNo ? 'Invalid phone number' : 'Phone number is required.'}
                            </Form.Control.Feedback>
                          </div>

                          <div
                            style={{padding: '0px'}}
                            className="location-field form-control schedule_location">
                            <GooglePlacesAutocomplete
                              selectProps={{
                                inviteAddressLocation,
                                onChange: setInviteAddressLocation,
                                name: 'city',
                                placeholder: jugar,
                                // noOptionsMessage: () => null,
                              }}
                            />

                            {inviteAddressLocation !== '' && (
                              <span
                                className="location_clear_icon"
                                onClick={() => {
                                  clearAll();
                                }}>
                                <i
                                  className="fas fa-close"
                                  // style={{paddingLeft: '210px'}}
                                />
                              </span>
                            )}
                          </div>

                          {/* {showLocationError === true && inviteAddressLocation === '' &&  (
                            <div className="text-danger textforerror">
                              Location is required.
                            </div>
                         )} */}
                        </div>
                      </div>
                    </div>

                    {/* POCs */}
                    <div
                      className="rounded-md shadow-md mb-2"
                      style={{
                        backgroundColor: '#EDEBE5',
                      }}>
                      <div className="flex justify-between items-center p-3 cursor-pointer">
                        <h2
                          onClick={() => setShowPOCs(!showPOCs)}
                          className="text-base font-semibold">
                          POCs
                        </h2>
                        <div>
                          <input
                            type="file"
                            accept="image/*"
                            onChange={e => changeTicketImg(e, 'pocs')}
                            style={{display: 'none'}} // Hide the input field
                            ref={fileInput} // Set the reference to the input
                          />

                          <div onClick={() => fileInput.current?.click()}>
                            {pocImage ? (
                              <img
                                style={{
                                  borderRadius: '6px',
                                  border: 'none',
                                  width: '37px',
                                  height: '24px',
                                  marginLeft: 'auto',
                                }}
                                src={pocImage}
                                alt=""
                              />
                            ) : (
                              <img src={cameraicon} alt="Camera icon" />
                            )}
                          </div>
                        </div>

                        {/* <button className="text-gray-500">
                          {showPOCs ? '▲' : '▼'}
                        </button> */}
                      </div>
                      <div
                        className={`overflow-hidden transition-all duration-300 ${
                          showPOCs ? 'max-h-screen' : 'max-h-0'
                        }`}>
                        <hr class="border-t border-gray-300 pb-3 ml-[28px] mr-[28px]"></hr>
                        <div className="p-4  pt-0 space-y-2 height-input">
                          <Form.Control
                            type="text"
                            value={pocDetail.name}
                            name="name"
                            // pattern="Se^\S.*$"
                            onChange={handlePoc}
                            placeholder={'Name'}
                          />

                          {showPocError === true && pocDetail.name === '' && (
                            <div className="text-danger textforerror">
                              Name is required.
                            </div>
                          )}

                          <Form.Control
                            type="email"
                            value={pocDetail.email}
                            name="email"
                            pattern="^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$"
                            onChange={handlePoc}
                            placeholder={'Email'}
                          />

                          {showPocError === true && pocDetail.email === '' && (
                            <div className="text-danger textforerror">
                              Email is required.
                            </div>
                          )}

                          <Form.Control
                            type="text"
                            value={pocDetail.phone}
                            name="phone"
                            // pattern="Se^\S.*$"
                            onChange={handlePoc}
                            placeholder={'Phone number'}
                          />

                          {validPocPhone === true && pocDetail.phone === '' ? (
                            <div className="text-danger textforerror">
                              Phone number is required.
                            </div>
                          ) : (
                            validPocPhone === true &&
                            pocDetail.phone !== '' && (
                              <div className="text-danger textforerror">
                                Invalid phone number.
                              </div>
                            )
                          )}

                          <div className="roi-form-dropdown ">
                            <div className="select-wrapper">
                              <Dropdown
                                onSelect={(eventKey, event) => {
                                  setPocDetail({
                                    ...pocDetail,
                                    role: eventKey,
                                  });
                                }}
                                drop="down">
                                <Dropdown.Toggle variant="primary">
                                  {pocDetail.role
                                    ? (() => {
                                        const role =
                                          pocRoles.find(
                                            item => item.id === pocDetail.role,
                                          )?.role_name || '';
                                        return (
                                          role.charAt(0).toUpperCase() +
                                          role.slice(1)
                                        );
                                      })()
                                    : 'Select Role'}
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                  {pocRoles.map((item, index) => (
                                    <Dropdown.Item
                                      key={`st-${index}`}
                                      eventKey={item.id.toString()}
                                      active={item.id === pocDetail.role}>
                                      {item.role_name.charAt(0).toUpperCase() +
                                        item.role_name.slice(1)}
                                    </Dropdown.Item>
                                  ))}
                                </Dropdown.Menu>
                              </Dropdown>
                            </div>
                          </div>

                          <div className="flex justify-center items-center mt-3">
                            <button
                              type="button"
                              onClick={handlePocListing}
                              className="bg-black text-white px-4 py-2 rounded-md">
                              Add POC
                            </button>
                          </div>
                          {contactData?.pocListing?.length > 0 && (
                            <>
                              {contactData?.pocListing.map((item, index) => (
                                <div
                                  className="d-flex align-items-center phases_item mt-2 icon-alias-holder"
                                  key={index}>
                                  <div className="customer_name flex-1">
                                    <div className="alias_name">
                                      {item.name}
                                    </div>
                                    <div style={{display: 'flex'}}>
                                      <span
                                        style={{padding: '2px'}}
                                        onClick={() => handleEdit(item, index)}
                                        className="as-link cross-icon-field">
                                        <EditPencilSvg fill={'#a39f8d'} />
                                      </span>
                                      <span className="cross-icon-field">
                                        <i
                                          className="as-link fas fa-times"
                                          onClick={() =>
                                            setShowDeleteModal({
                                              open: true,
                                              item,
                                            })
                                          }
                                          style={{
                                            fontSize: '1.5rem',
                                            color: '#a39f8d',
                                          }}></i>
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                    {/* {Additional Details} */}
                    <div className="bg-white rounded-md shadow-md mb-2">
                      <div
                        className="flex justify-between items-center p-3 cursor-pointer"
                        onClick={() =>
                          setShowAdditionalDetail(!showAdditionalDetail)
                        }>
                        <h2 className="text-base font-semibold">
                          Additional Details
                        </h2>
                        {/* <button className="text-gray-500">
                          {showAdditionalDetail ? '▲' : '▼'}
                        </button> */}
                      </div>
                      <div
                        className={`overflow-hidden transition-all duration-300 ${
                          showAdditionalDetail ? 'max-h-screen' : 'max-h-0'
                        }`}>
                        <hr class="border-t border-gray-300 pb-3 ml-[28px] mr-[28px]"></hr>
                        <div className="p-4  pt-0 space-y-2 height-input">
                          <Form.Control
                            type="url"
                            value={contactData.website}
                            name="website"
                            // pattern="Se^\S.*$"
                            onChange={handleChange}
                            placeholder={'Website '}
                            // required
                          />
                          <Form.Control.Feedback type="invalid">
                            Website is required.
                          </Form.Control.Feedback>

                          <Form.Control
                            type="text"
                            value={contactData.timeZone}
                            name="timeZone"
                            // pattern="Se^\S.*$"
                            onChange={handleChange}
                            placeholder={'Time zone'}
                            // required
                          />
                          <Form.Control.Feedback type="invalid">
                            Time zone is required.
                          </Form.Control.Feedback>

                          <Form.Control
                            type="text"
                            value={contactData.contactSource}
                            name="contactSource"
                            // pattern="Se^\S.*$"
                            onChange={handleChange}
                            placeholder={'Contact source'}
                            // required
                          />
                          <Form.Control.Feedback type="invalid">
                            Contact source is required.
                          </Form.Control.Feedback>

                          {tabType === labels.type_individual[selected_lang] ? (
                            <>
                              <Calender
                                minDate={new Date()}
                                maxDate={getMaxDateFromToday()}
                                setSelectedDate={selectedDate => {
                                  if (selectedDate) {
                                    setcontactData({
                                      ...contactData,
                                      birthDate: selectedDate,
                                    });
                                  } else {
                                    setcontactData({
                                      ...contactData,
                                      birthDate: '',
                                    });
                                  }
                                }}
                                selectedDate={
                                  contactData.birthDate &&
                                  moment(
                                    contactData.birthDate,
                                    'YYYY-MM-DD',
                                    true,
                                  ).isValid()
                                    ? new Date(contactData.birthDate)
                                    : ''
                                }
                                showDate={true}
                                showTime={false}
                                type={'contact'}
                                placeholder={'Birth Date'}
                              />
                            </>
                          ) : (
                            <>
                              <Calender
                                minDate={new Date()}
                                maxDate={getMaxDateFromToday()}
                                setSelectedDate={selectedDate => {
                                  if (selectedDate) {
                                    setcontactData({
                                      ...contactData,
                                      date_established: selectedDate,
                                    });
                                  } else {
                                    setcontactData({
                                      ...contactData,
                                      date_established: '',
                                    });
                                  }
                                }}
                                selectedDate={
                                  contactData.date_established &&
                                  moment(
                                    contactData.date_established,
                                    'YYYY-MM-DD',
                                    true,
                                  ).isValid()
                                    ? new Date(contactData.date_established)
                                    : ''
                                }
                                showDate={true}
                                showTime={false}
                                type={'contact'}
                                placeholder={'Date established'}
                              />
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                    {/* {Attachments} */}
                    <div className="bg-white rounded-md shadow-md mb-2">
                      <div
                        className="flex justify-between items-center p-3 cursor-pointer"
                        onClick={() => setShowAttachments(!showAttachments)}>
                        <h2 className="text-base font-semibold">Attachments</h2>
                        {/* <button className="text-gray-500">
                          {showAdditionalDetail ? '▲' : '▼'}
                        </button> */}
                      </div>
                      <div
                        className={`overflow-hidden transition-all duration-300 ${
                          showAttachments ? 'max-h-screen' : 'max-h-0'
                        }`}>
                        <hr class="border-t border-gray-300 pb-3 ml-[28px] mr-[28px]"></hr>
                        <div
                          onClick={() => {
                            setEditProject(true);
                          }}
                          className="p-4  pt-0 space-y-2 height-input">
                          {/* <div
                            className="file-upload-wrapper as-link"
                            style={{
                              marginBottom: '15px',
                            }}>
                            {!documentInfo && (
                              <input
                                type="file"
                                className="as-link"
                                accept=".doc,.docx,.xls,.xlsx,.pdf,.ppt,.pptx"
                                onChange={e => {
                                  // handleFileUpload(e);
                                }}
                              />
                            )}
                            {documentInfo && documentInfo?.length > 0 ? (
                              <>
                                <span
                                  // onClick={() => {
                                  //   setshowModel('confirmation_Model');
                                  // }}
                                  style={{
                                    width: '100%',
                                    textAlign: 'end',
                                    paddingRight: '11px',
                                    position: 'absolute',
                                    top: '7px',
                                  }}>
                                  {' '}
                                  <i className="fa-solid fa-xmark"></i>
                                </span>

                                <span style={{fontSize: '40px'}}>
                                  <i className="fa-solid fa-file"></i>
                                </span>
                              </>
                            ) : (
                              <img src={upload_file} alt="" />
                            )}
                            <span>Tap to upload</span>
                          </div> */}
                          <Upload
                            uploadFilesListing={uploadFilesListing}
                            setUploadFilesListing={setUploadFilesListing}
                            handleFiles={handleFiles}
                            setShowFileComponent={setShowFileComponent}
                            type={'contact_attachment'}
                            setShowLoader={setShowLoader}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="roi-tags-icons flex items-center roi-profile-block-tags-icons">
                      {/* {showPlusButtonDecesion(
              tagsListing?.tag_permission?.own_profile_permission,
              tagsListing?.tag_permission?.show_tag,
            ) === true && ( */}
                      <div
                        className="tag-icon as-link cursor-pointer"
                        // onClick={() => {
                        //   handleBlock('', 'view_tag');
                        // }}
                      >
                        <i
                          onClick={() => {
                            setShowModal('tags');
                          }}
                          className="icon-plus"></i>
                      </div>
                      {/* )} */}

                      <div className="flex-grow overflow-x-auto">
                        <Slider {...settings} className="whitespace-nowrap">
                          {contactData?.tags?.length > 0 &&
                            contactData?.tags.map((item, index) => (
                              <div className="inline-block px-2" key={index}>
                                <div className="tag-icon-time flex items-center space-x-2">
                                  <div
                                    className="newClass flex items-center space-x-2"
                                    style={{
                                      paddingLeft: '3px',
                                    }}>
                                    <span className="text-sm multi-line-ellipsis">
                                      {item.title}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            ))}
                        </Slider>
                      </div>
                    </div>
                    <div className="bg-[#E8E6DF] w-20 h-20 flex items-center justify-center rounded-lg">
                      <div
                        style={{
                          height: '77px',
                          background: 'none',
                          borderRadius: '6px',
                          color: 'black',
                        }}
                        className={`ticket-img-wrapper mb-0 m-0 `}>
                        {contactData?.image && (
                          <p
                            onClick={() => {
                              // setImgModal(true);
                              // if (sliderImage.length > 0) {
                              // setModelImg(sliderImage[0]);
                              setShowModal('image_modal');
                              // }
                            }}>
                            Onclick
                          </p>
                        )}

                        <input
                          type="file"
                          accept="image/*"
                          onChange={e => changeTicketImg(e, 'profile')}
                        />
                        {contactData.image && (
                          <img
                            style={{borderRadius: '6px', border: 'none'}}
                            src={contactData?.image}
                            alt=""
                          />
                        )}
                        <i
                          style={{borderRadius: '6px'}}
                          className="icon-camera as-link"
                        />
                        {/* <img src={cameraicon} alt="Camera Icon" /> */}
                      </div>
                    </div>
                  </div>

                  <Button
                    type="submit"
                    style={{display: 'none'}}
                    ref={buttonRef}>
                    {props.snapshotIds ? 'Send Refferral' : 'Add Contact'}
                  </Button>
                </Form>
              </div>
            </Scrollbars>

            <div className="filter-btn-wrapper bg_transparent bottom-0">
              {/* {!props.addBusinessPOC && ( */}
              <div className="text-center pt-1">
                <span
                  // id='hh'
                  className="as-link "
                  style={{
                    color: '#00bf4d',
                  }}
                  onClick={() => {
                    validateFields.current = true;
                    setTimeout(() => {
                      buttonRef.current.click();
                    }, 100);

                    setEmailToggle(!emailToggle);
                  }}>
                  {tabType === labels.type_individual[selected_lang]
                    ? 'Invite Contact'
                    : 'Invite Business'}
                </span>
              </div>
              {/* )} */}
              <div className="invite-btns p-0 pt-1 text-center">
                <Button
                  type="submit"
                  style={{marginRight: '0px'}}
                  onClick={() => {
                    validateFields.current = false;

                    setEmailToggle(!emailToggle);

                    setTimeout(() => {
                      buttonRef.current.click();
                    }, 100);
                  }}>
                  {props.snapshotIds
                    ? 'Send Refferral'
                    : tabType === labels.qr_type_business[selected_lang] &&
                      !props.addBusinessPOC
                    ? 'Add Contact'
                    : 'Add Contact'}
                </Button>
              </div>
            </div>
          </div>

          {participantArray.length > 0 && props.id && !props.type && (
            <div className="filter-btn-wrapper pt-3">
              <div className="invite-btns text-center p-0">
                <Button
                  disabled={participantArray.length > 0 ? false : true}
                  onClick={() => {
                    participantArray.forEach((item, index) => {
                      props.setContact(previous => [...previous, item]);
                    });
                    sendnvitationOutIside();

                    // successMessage(' Succesfully add to recipient');
                  }}>
                  Add to recipient
                </Button>
              </div>
            </div>
          )}
        </div>
      </SlidingPanel>

      {showComponent === 'SelectedUsers' && (
        <SelectedReferUser
          selectedAllUser={[]}
          users={[]}
          setUsers={[]}
          setSelectedAllUser={[]}
          onClose={() => setShowComponent('')}
          alreadyAddedUser={[]}
          referOutSide={participantArray}
          setReferOutSide={setParticipantArraytPhonenNo}
        />
      )}

      {showDeleteModal.open && (
        <ConfirmationModel
          closeModel={() => {
            setShowDeleteModal(prev => ({...prev, open: false}));
          }}
          discription={'Are you Sure you want to delete this POC?'}
          buttonOne={'Yes'}
          buttonTwo={'No Thanks'}
          ButtonOneAction={() => {
            handleDelete(showDeleteModal.item);
          }}
        />
      )}

      {showModal === 'tags' && (
        <ProfileTags
          onClose={() => setShowModal('')}
          tagTitleList={tagTitleList}
          setTagTitleList={setTagTitleList}
          userTagList={contactData.tags}
          type="adContact"
        />
      )}

      {showModal === 'confirmation_Model' && (
        <ConfirmationModel
          closeModel={() => {
            setShowModal('');
          }}
          discription={'Are you Sure you want to delete this File?'}
          buttonOne={'Yes'}
          buttonTwo={'No Thanks'}
          ButtonOneAction={() => {
            delete_document_files();
          }}
        />
      )}

      {showModal === 'image_modal' && file && (
        <CustomModal
          size="large"
          onClose={() => {
            setShowModal('');
            setFile(null);
          }}>
          <CropImage
            type="poc_image"
            ratio={429 / 187}
            file={file}
            setFile={setFile}
            setCropImg={setShowModal}
            imgUrl={ticketImg}
            cropImg={cropImg}
            setImgUrl={setTicketImg}
            sliderImage={sliderImage}
            setSliderImage={setSliderImage}
            setshowLoader={setShowLoader}
          />
        </CustomModal>
      )}
      {showModal === 'poc_image_modal' && file && (
        <CustomModal
          size="large"
          onClose={() => {
            setShowModal('');
            setFile(null);
          }}>
          <CropImage
            type="poc_image"
            ratio={429 / 187}
            file={file}
            setFile={setFile}
            setCropImg={setShowModal}
            imgUrl={pocImage}
            cropImg={cropImg}
            setImgUrl={setPocImage}
            sliderImage={sliderImage}
            setSliderImage={setSliderImage}
            setshowLoader={setShowLoader}
          />
        </CustomModal>
      )}

      {showFileComponent && (
        <ViewFile
          selectedFile={documentInfo}
          onClose={() => {
            setShowFileComponent(false);
            // setSelectedFile(null);
          }}
        />
      )}
    </>
  );
};

export default SendInvitation;
