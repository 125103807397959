import React, {useState, useEffect, useRef} from 'react';
import {Button, Col, Form, Row} from 'react-bootstrap';
import {selected_lang} from '../../Utils/env';
import {geocodeByPlaceId} from 'react-google-places-autocomplete';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import {getLatLng} from 'react-google-places-autocomplete';
import {labels} from '../../Constants/Translations';
import commonApi from '../../Services/CommonService';

import SessionFocus from '../../Pages/Front/StaticPages/SessionFocus';
import {RoiLogo, UserProfileImg} from '../../Constants/Images';
import {errorMessage, successMessage} from '../../Utils/ToastMessages';
import $ from 'jquery';
import {useSelector, useDispatch} from 'react-redux';

import {
  getOpportunityListing,
  callOpportunityApi,
} from '../../Store/Actions/OpportunityAction';
import {callReferralApi} from '../../Store/Actions/ReferralAction';
import CustomTabs from '../CustomTabs';
import CustomModal from '../CustomModal';
import SlidingPanel from '../SlidingPanel';
import Scrollbars from 'react-custom-scrollbars';
import SelectedUsers from '../../Pages/Front/StaticPages/SelectedUsers';

import CircleListBuilders from '../../Pages/Front/StaticPages/CircleListBuilders';
import CreateSnapShot from '../Messages/CreateSnapShot';

import {list} from '../../Utils/commonUtils';
import SendInvitation from '../../Pages/Front/StaticPages/SendInvitation';
import Invite from '../SingleComponent/Invite';
import PersonalCreateModel from '../Modals/PersonalCreateModel';
import {get_my_all_associate_workspaces} from '../../Store/Actions/SessionAction';
import CustomDropdown from '../CustomDropdown';
import LoaderSpinner from '../Modals/LoaderSpinner';
const EditOpportunities = props => {
  const [frequencyIdvalue, setFrequencyIdvalue] = useState([]);
  const [tabType, setTabType] = useState('general');
  const [subTabs, setSubTabs] = useState('moderator');
  const [oppoUrl, setOppoUrl] = useState('');
  const [businessName, setBusinessName] = useState('');
  const [skillSet, setSkillSet] = useState('');
  const [oppoTitle, setOppoTitle] = useState('');
  const [oppoDescription, setOppoDescription] = useState('');
  const [oppoAddress, setOppoAddress] = useState('');
  const [oppoBackground, setOppoBackground] = useState([]);
  const [opp, setOpp] = useState([]);
  const [frequency, setFrequency] = useState();
  const [showLoader, setShowLoader] = useState(false);

  const [isCheckValue, setIsCheckValue] = useState([]);
  const [checkIndustry, setCheckIndustry] = useState([]);
  const [checkIndustryId, setCheckIndustryId] = useState([]);
  const [isCheck, setIsCheck] = useState([]);
  const [isCheckAll, setIsCheckAll] = useState(false);
  const [isSelectedAll, setIsSelectedAll] = useState(false);
  const [ShowModal, setShowModal] = useState(false);
  // const [showModalButtons, setShowModalButtons] = useState(false);
  // const [showModelDecesion, setShowModelDecesion] = useState('');
  const [amount, setAmount] = useState('');
  const [showError, setShowError] = useState(false);
  const [catError, setCatError] = useState(false);
  const [loactionError, setLocationError] = useState(false);
  const [audience, setAudience] = useState([]);
  const [selectedModData, setSelectedModData] = useState([]);
  const [selectedAudData, setSelectedAudData] = useState([]);
  const [modrator, setModrator] = useState([]);
  const [showComponent, setShowComponent] = useState('');

  const [oppoValue, setOppoValue] = useState('');

  const [validated, setValidated] = useState(false);
  const [bullseyeButton, setBullseyeButton] = useState(false);
  const [identiferId, setIdentiferId] = useState([]);
  const [ethnicityId, setEthnicityId] = useState([]);
  const [gender, setGender] = useState([]);
  const [focusLocations, setFocusLocations] = useState([]);
  const [latitude, setLatitude] = useState([]);
  const [longitude, setLongitude] = useState([]);

  // const [snapShotButton, setSnapShotButton] = useState(false);
  const [hostId, setHostId] = useState('');
  const [imageCreate, setImageCreate] = useState('');
  const [images, setImages] = useState([]);
  const [newButton, setNewButton] = useState(false);
  // const [change, setChange] = useState(false);

  const [appointmentId, setAppointmentId] = useState([]);
  const [categoriesId, setCategoriesId] = useState([]);
  const [persnalTypesId, setPersnalTypesId] = useState([]);

  const [alreadyAddedAud, setAlreadyAddedAud] = useState([]);
  const [alreadyAddedMod, setAlreadyAddedMod] = useState([]);

  const [originalDataOfMod, setOriginalDataOfMod] = useState([]);
  const [originalDataOfAud, setOriginalDataOfAud] = useState([]);
  const [contact, setContact] = useState([]);

  const [internShip, setInternShip] = useState(false);
  const [workSpaceIds, setWorkSpaceIds] = useState([]);
  const RadioButtonName = ['Invite Only', 'Share on The Market'];
  const [invitesOptionvalue, setInvitesOptionvalue] = useState(
    'Share on The Market',
  );

  const [inviteLoader, setInviteLoader] = useState(true);
  const [inviteFollowers, setInviteFollowers] = useState([]);
  const [followersList, setFollowersList] = useState([]);
  const [inviteQry, setInviteQry] = useState('');

  const [disableBtn, setDisableBtn] = useState(false);

  const buttonRef = useRef(null);
  const listWorspacerator = useSelector(
    state => state.hitAssociateWorkSpace.data,
  );
  const personal = useSelector(state => state.hitAssociateWorkSpace.personal);

  const dispatch = useDispatch();
  const opportunityData = useSelector(state => state.opportunityListing);

  // const [singleArrData, setSingleArrData] = useState({
  //   address: false,
  //   skill_set: false,
  //   url: false,
  // });

  const [topIcons, setTopIcons] = useState({
    bullseye: false,
    location: true,
    vaccinate: false,
    visibility: false,
    personal: false,
  });

  useEffect(() => {
    setShowLoader(true);
    dispatch(
      get_my_all_associate_workspaces({token: localStorage.getItem('token')}),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (workSpaceIds.length > 0) {
    } else {
      // Filter the workspaces with status "active" from the API response
      const userExist = listWorspacerator.filter(
        workspace => workspace.status === 'active',
      );

      // Extract the 'id' values from 'userExist'
      const updatedIds = userExist.map(workspace => workspace.id);

      if (personal === 'yes') {
        updatedIds.push('personal'); // Add 'personal' to the ids array if needed
      }

      // setActiveWorkspacesId(updatedIds);
      setWorkSpaceIds(updatedIds);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listWorspacerator]);

  useEffect(() => {
    if (Object.entries(props.item).length > 0) {
      if (props.item.team_member_ids) {
        if (props.item.team_member_details.length > 0) {
          let item = props.item.team_member_details;
          item.forEach((miniItem, index) => {
            setModrator(previous => [...previous, miniItem.id]);
            setAlreadyAddedMod(previous => [...previous, miniItem.id]);
            setSelectedModData(previous => [...previous, miniItem]);
            setOriginalDataOfMod(previous => [...previous, miniItem]);
          });
        }
      }
      if (props.item?.workspace_ids) {
        setWorkSpaceIds(props.item.workspace_ids.split(','));
      }

      if (props.item?.personal === 'yes') {
        // alert('@@@@@@@@@@@')
        setTimeout(() => {
          setWorkSpaceIds(previous => [...previous, 'personal']);
          // workSpaceIds.push('personal');
        }, 1500);
      }

      if (props.item.snapshot_image && props.item.snapshot_id) {
        setHostId(props.item.snapshot_id);
        // if (images) {
        //   images.map(item => {

        //     if (hostId === item.id) {
        //       setImageCreate(item.images[0]);
        //     }
        //   });
        // }
        setImageCreate(props.item.snapshot_image);
        // setSliderImage([]);
        // setSnapShotButton(true);
        setNewButton(true);
      } else {
        // setSnapShotButton(false);
      }

      // if (
      //   props.item.personal_invite_ids !== '' &&
      //   props.item.personal_invite_ids !== null
      // ) {
      //   if (props.item.personal_invite_details.length > 0) {
      //     let item = props.item.personal_invite_details;
      //     item.map((miniItem, index) => {
      //       setAudience(previous => [...previous, miniItem.id]);
      //       setSelectedAudData(previous => [...previous, miniItem]);
      //     });
      //   }

      // }
      if (props.item.other_persons_opportunity_chat_room.length > 0) {
        let item = props.item.other_persons_opportunity_chat_room;
        item.forEach(miniItem => {
          setAudience(previous => [...previous, miniItem.id]);
          setAlreadyAddedAud(previous => [...previous, miniItem.id]);
          setSelectedAudData(previous => [...previous, miniItem]);
          // setOriginalDataOfAud(previous => [...previous, miniItem]);
        });
      } else {
        let item = props.item.personal_invite_details;
        item.forEach(miniItem => {
          setAudience(previous => [...previous, miniItem.id]);
          setAlreadyAddedAud(previous => [...previous, miniItem.id]);
          setSelectedAudData(previous => [...previous, miniItem]);
          setOriginalDataOfAud(previous => [...previous, miniItem]);
        });
      }
      setInternShip(props.item.internship === 'yes' ? true : false);
      setIsCheck(props.item.background_ids);
      props.item.background_detail.forEach(mini => {
        isCheckValue.push(mini.title);
      });
      setBusinessName(props.item.business_name);
      setOppoTitle(props.item.title);
      setOppoDescription(props.item.description);
      setAmount(props.item.amount);
      setFrequencyIdvalue(props.item.frequency_id);
      setOppoAddress(props.item.address);
      setLongitude(props.item.longitude);
      setLatitude(props.item.latitude);
      setIdentiferId([props.item.focus_identifier]);
      setGender([props.item.focus_gender]);
      setEthnicityId([props.item.focus_ethnicity]);
      setPersnalTypesId(
        props.item.on_boarding_areas
          ? props.item.on_boarding_areas.split()
          : [],
      );
      setAppointmentId(
        props.item.appointment_types
          ? props.item.appointment_types.split()
          : [],
      );
      setCategoriesId(
        props.item.on_boarding_categories
          ? props.item.on_boarding_categories.split()
          : [],
      );
      setOppoUrl(props.item.url);
      setSkillSet(props.item.skill_set);
      if (props.item.ally === 'yes') {
        setTopIcons(prev => ({...prev, bullseye: true}));
      }
      if (props.item.address) {
        setTopIcons(prev => ({...prev, location: true}));
      }
      if (props.item.vaccinate === 'yes') {
        setTopIcons(prev => ({...prev, vaccinate: true}));
      }
      if (props.item.visibility === 'private') {
        setTopIcons(prev => ({...prev, visibility: true}));
      }
      // if (props.item.snap_shot_image && props.item.snap_shot_id ) {
      //   setHostId(props.item.snap_shot_id);

      //   setImageCreate(props.item.snap_shot_image);

      //   setSnapShotButton(true);
      //   setNewButton(true);
      // }
    }
    getOpp();
    getOppoBackground();
    // getAllUsers('');
    getFrequency();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // const props.handleCreateOppo = () => {
  //     props.setDec(true);
  //     props.setInviteUser(!props.inviteUser);
  //
  //   };
  useEffect(() => {
    if (images) {
      images.forEach(item => {
        if (hostId === item.id) {
          setImageCreate(item.images[0]);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newButton]);

  const editOpportunity = (lat, lng) => {
    setDisableBtn(false);

    let initData = {
      address:
        typeof oppoAddress === 'object'
          ? oppoAddress.label
          : typeof oppoAddress === 'string'
          ? oppoAddress
          : '',
      ally: topIcons.bullseye ? 'yes' : 'no',
      amount: ['1', '2', '3', '5'].includes(oppoValue) ? amount : '',
      association_type: 'myself',
      association_with: 'user_id',
      opportunity_id: props.item.id,
      background_ids: isCheck,
      business_name: businessName,
      city_states: focusLocations,
      on_boarding_areas: persnalTypesId,
      appointment_types: appointmentId,
      on_boarding_categories: categoriesId,
      description: oppoDescription,
      education_level_id: 'Master',
      // ethnicities: ethnicityId.toString(),
      ethnicities: '',
      // gender: gender.toString(),
      gender: '',
      otherIdentifires: identiferId.toString(),
      frequency: ['2', '5'].includes(oppoValue)
        ? frequencyIdvalue.toString()
        : '',
      latitude:
        typeof oppoAddress === 'object'
          ? lat
          : typeof oppoAddress === 'string'
          ? latitude
          : '',
      longitude:
        typeof oppoAddress === 'object'
          ? lng
          : typeof oppoAddress === 'string'
          ? longitude
          : '',
      opportunity_type_id: oppoValue,
      paid: amount ? 'yes' : 'no',
      personal_invite_ids: audience,
      shop_id: '',
      skill_set: skillSet,
      status: 'pending',
      team_member_ids: modrator,
      title: oppoTitle,
      url: oppoUrl,
      vaccinate: topIcons.vaccinate ? 'yes' : 'no',
      visibility: topIcons.visibility ? 'private' : 'public',
      // snapshot_id: hostId,
      snapshot_id: '',
      // snapshot_image: imageCreate,
      snapshot_image: '',
      workspace_ids: workSpaceIds.toLocaleString(','),
      internship: internShip ? 'yes' : 'no',
    };

    commonApi
      .edit_opportunity(initData)
      .then(res => {
        if (res.success === true && res.status === 200) {
          setDisableBtn(false);
          dispatch(callOpportunityApi());
          dispatch(callReferralApi());
          dispatch(getOpportunityListing(opportunityData.payload));
          successMessage(res.message);
        } else {
          setDisableBtn(true);
          errorMessage(res.message);
        }
      })
      .catch(err => {
        
        setDisableBtn(true);
        return {type: 'error', message: err.message};
      });
  };
  const createOpportunity = (lat, lng) => {
    setDisableBtn(false);

    let initData = {
      address: oppoAddress ? oppoAddress.label : '',
      ally: topIcons.bullseye ? 'yes' : 'no',
      amount: ['1', '2', '3', '5'].includes(oppoValue) ? amount : '',
      association_type: 'myself',
      association_with: 'user_id',
      background_ids: isCheck,
      business_name: businessName,
      city_states: focusLocations,
      on_boarding_areas: persnalTypesId,
      appointment_types: appointmentId,
      on_boarding_categories: categoriesId,
      description: oppoDescription,
      education_level_id: 'Master',
      // ethnicities: ethnicityId.toString(),
      ethnicities: '',
      // gender: gender.toString(),
      gender: '',
      otherIdentifires: identiferId.toString(),
      frequency: ['2', '5'].includes(oppoValue)
        ? frequencyIdvalue.toString()
        : '',
      latitude: lat,
      longitude: lng,
      opportunity_type_id: oppoValue,
      paid: amount ? 'yes' : 'no',
      personal_invite_ids: audience,
      shop_id: '',
      skill_set: skillSet,
      status: 'pending',
      team_member_ids: modrator,
      title: oppoTitle,
      url: oppoUrl,
      vaccinate: topIcons.vaccinate ? 'yes' : 'no',
      visibility: topIcons.visibility ? 'private' : 'public',
      snapshot_id: '',
      snapshot_image: '',
      workspace_ids: workSpaceIds.toLocaleString(','),
      internship: internShip ? 'yes' : 'no',
    };
    commonApi
      .create_opportunity(initData)
      .then(res => {
        if (res.success === true && res.status === 200) {
          setDisableBtn(false);
          successMessage(res.message);
          dispatch(getOpportunityListing(opportunityData.payload));
        } else {
          errorMessage(res.message);
          setDisableBtn(true);
        }
      })
      .catch(err => {
        setDisableBtn(true);
      
        return {type: 'error', message: err.message};
      });
  };

  // const collectingUsers = item => {
  //   const creator = item.hasOwnProperty('creator_details')
  //     ? 'creator_details'
  //     : 'creator_detail';
  //   const moderator =
  //     item.chat_type === 'opportunity'
  //       ? 'team_member_details'
  //       : 'moderator_detail';
  //   const audience =
  //     item.chat_type === 'opportunity'
  //       ? 'other_persons_opportunity_chat_room'
  //       : 'audience_detail';

  //   const chatUsers = [
  //     {
  //       id: item[creator]['id'],
  //       first_name: item[creator]['first_name'],
  //       last_name: item[creator]['last_name'],
  //       profile_img: item[creator]['profile_img'],
  //       type: 'creator',
  //     },
  //   ];
  //   if (item[audience].length > 0) {
  //     item[audience].map(item => {
  //       item.type = 'audience';
  //       chatUsers.push(item);
  //     });
  //   }
  //   if (item[moderator].length > 0) {
  //     item[moderator].map(item => {
  //       item.type = 'moderator';
  //       chatUsers.push(item);
  //     });
  //   }
  //   return chatUsers;
  // };

  const submitForm = event => {
    const form = event.currentTarget;
    if (
      form.checkValidity() === false ||
      isCheck.length === 0 ||
      (oppoAddress === '' && !showError)
    ) {
      event.preventDefault();
      event.stopPropagation();

      setCatError(true);
      setLocationError(true);
      setValidated(true);
    } else {
      event.preventDefault();
      if (amount < 1 && ['1', '2', '3', '5'].includes(oppoValue)) {
        setTabType('Funds');
        errorMessage('Invalid amount ');
      } else if (
        frequencyIdvalue.length === 0 &&
        ['2', '5'].includes(oppoValue)
      ) {
        errorMessage('Select frequency');
      } else if (oppoAddress === '' && !showError) {
        setLocationError(true);
      }
      // else if (workSpaceIds.length == 0) {
      //   errorMessage('Please select workspace');
      // }
      else {
        setLocationError(false);
        setCatError(false);
        var place_id =
          typeof oppoAddress === 'object'
            ? oppoAddress.value.place_id
            : typeof oppoAddress === 'string'
            ? ''
            : '';

        if (place_id) {
          geocodeByPlaceId(place_id)
            .then(results => getLatLng(results[0]))
            .then(({lat, lng}) => {
              Object.entries(props.item).length > 0
                ? editOpportunity(lat, lng)
                : createOpportunity(lat, lng);
              // handleResetForm();
            });
        } else {
          Object.entries(props.item).length > 0
            ? editOpportunity('', '')
            : createOpportunity('', '');
        }

        // if (Object.entries(props.item).length > 0) {
        //   editOpportunity();
        // } else {

        //   createOpportunity();
        // }

        // props.setInviteUser(!props.inviteUser);

        handleResetForm();
        setValidated(false);
        // dispatch(callOpportunityApi());
        props.onClose();
      }
    }
  };
  // const getAllUsers = qry => {
  //   var initData = {
  //     search_query: qry,
  //   };
  //   API.all_users(initData)
  //     .then(res => {

  //       setFollowers(res.data);
  //       setNewFolowers(
  //         res.data.filter(
  //           item => !audience.includes(item.id) && !modrator.includes(item.id),
  //         ),
  //       );
  //     })
  //     .catch(err => {
  //       console.log('Err', err);
  //       return {type: 'error', message: err.message};
  //     });
  // };

  const handleResetForm = () => {
    setIsCheck([]);
    setOppoAddress('');

    setOppoValue('');
    setOppoDescription('');
    setTopIcons({
      focus: false,
      location: false,
      vaccinate: false,
      visibility: false,
    });

    setBusinessName('');
    setSkillSet('');
    setOppoUrl('');
    setOppoTitle('');
  };
  const getFrequency = () => {
    commonApi
      .get_frequency()
      .then(res => {
        if (res.status === 200) {
          setFrequency(res.frequencies);
        }
      })
      .catch(err => {
       
        return {type: 'error', message: err.message};
      });
  };
  const getOpp = () => {
    commonApi
      .get_opportunity_types()
      .then(res => {
        if (res.status === 200) {
          setOpp(res.opportunity_types);
          if (Object.entries(props.item).length > 0) {
            setOppoValue(props.item.opportunity_type_id);
          } else {
            setOppoValue(res.opportunity_types[1].id);
          }
        }
      })
      .catch(err => {
       
        return {type: 'error', message: err.message};
      });
  };
  const getOppoBackground = () => {
    commonApi
      .get_background()
      .then(res => {
        if (res.status === 200) {
          setOppoBackground(res.background);
          setShowLoader(false);
        }
      })
      .catch(err => {
        
        return {type: 'error', message: err.message};
      });
  };
  const handleClick = e => {
    const {id, value, checked} = e.target;
    setCheckIndustryId([...checkIndustryId, id]);
    setCheckIndustry([...checkIndustry, value]);
    if (!checked) {
      setCheckIndustryId(checkIndustryId.filter(item => item !== id));
    }
    if (!checked) {
      setCheckIndustry(checkIndustry.filter(item => item !== value));
    }
  };

  const handleSelectIndustry = e => {
    setIsCheckValue(checkIndustry);
    setIsCheck(checkIndustryId);
    setIsSelectedAll(isCheckAll);
    setShowModal(false);
  };

  const handleSelectAll = e => {
    setIsCheckAll(!isCheckAll);
    setCheckIndustryId(oppoBackground.map(item => item.id));
    setCheckIndustry(oppoBackground.map(item => item.title));
    if (isCheckAll) {
      setCheckIndustryId([]);
      setCheckIndustry([]);
    }
  };

  useEffect(() => {
    if (oppoBackground.length > 0) {
      if (checkIndustryId.length === oppoBackground.length) {
        setIsCheckAll(true);
      } else {
        setIsCheckAll(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkIndustryId]);

  let jugar =
    typeof oppoAddress === 'object'
      ? oppoAddress.label
      : typeof oppoAddress === 'string' && oppoAddress !== ''
      ? oppoAddress
      : 'Type city and state';

  const clearAll = () => {
    setOppoAddress('');

    $('.css-1uccc91-singleValue').html('Type city and state');
  };

  const handleChange = e => {
    // const {id, checked} = e.target;
    // setOpportunity([...opportunity, id]);
    setFrequencyIdvalue(e.target.value);
  };

  const ticketTabs = ['1', '2', '3', '5'].includes(oppoValue)
    ? [
        labels.sessions_type_general[selected_lang],
        labels.funds[selected_lang],
        labels.sessions_type_invites[selected_lang],
      ]
    : [
        labels.sessions_type_general[selected_lang],
        labels.sessions_type_invites[selected_lang],
      ];

  const CircleUserListData = item => {
    if (item.length > 0) {
      if (subTabs === 'moderator') {
        item.forEach((miniItem, index) => {
          if (
            !modrator.includes(miniItem.id) &&
            !audience.includes(miniItem.id)
          ) {
            setModrator(previous => [...previous, miniItem.id]);
            setSelectedModData(previous => [...previous, miniItem]);
            setOriginalDataOfMod(previous => [...previous, miniItem]);
          }
        });
      }
      if (subTabs === 'audience') {
        item.forEach((miniItem, index) => {
          if (
            !audience.includes(miniItem.id) &&
            !modrator.includes(miniItem.id)
          ) {
            setAudience(previous => [...previous, miniItem.id]);
            setSelectedAudData(previous => [...previous, miniItem]);
            setOriginalDataOfAud(previous => [...previous, miniItem]);
          }
        });
      }
    }
  };

  // const changeTicketImgSnapSHot = e => {
  //   if (e.target.files.length === 0) return false;
  //   const file = e.target.files[0];
  //   let fileExtension = file.name.split('.').pop().toLowerCase();

  //   // If no image selected, return
  //   // || fileExtension === "gif"
  //   if (!/^image\//.test(file.type)) {
  //     errorMessage(`File is not an image.`);
  //     return false;
  //   } else {
  //     const filePath = e.target.files[0];
  //     const objectUrl = URL.createObjectURL(filePath);
  //     let reader = new FileReader();
  //     reader.readAsDataURL(filePath);
  //     reader.onload = e => {
  //       uploadMediaSnapshot(e.target.result);
  //     };

  //     // setTicketImg(objectUrl);
  //   }
  //   // const imgTypes = ['image/gif', 'image/jpeg', 'image/png'];
  // };

  // const uploadMediaSnapshot = img => {
  //   var bodyFormData = new FormData();
  //   bodyFormData.append(
  //     'slider_images',
  //     img.replace(/^data:image\/[a-z]+;base64,/, ''),
  //   );

  //   commonApi
  //     .upload_event_media(bodyFormData)
  //     .then(res => {

  //       if (res.status === 200) {
  //         setImageCreate(res.slider_image_path);
  //       }
  //     })
  //     .catch(err => {
  //       console.log('Err', err);
  //       return {type: 'error', message: err.message};
  //     });
  // };

  const getAccomplishment = () => {
    var payLoad = {
      categories: [],
      user_id: localStorage.getItem('user'),
    };
    commonApi
      .get_accomplishment(payLoad)
      .then(res => {
        if (res.status === 200) {
          if (res.accomplishment.length > 0) {
            setImages(res.accomplishment);
            setHostId(res.accomplishment[0].id);
            setImageCreate(res.accomplishment[0].images[0]);
          } else {
          }
          // setImages(res.accomplishment);
        }
      })
      .catch(err => {
        
        return {type: 'error', message: err.message};
      });
  };

  const handleInviteOption = e => {
    // const {id, checked} = e.target;
    // setOpportunity([...opportunity, id]);
    setInvitesOptionvalue(e.target.value);
  };

  useEffect(() => {
    getAccomplishment();
  }, [newButton]);

  const handleCircle = () => {
    setShowComponent('CircleList');
  };
  useEffect(() => {
    if (contact.length > 0) {
      setInviteLoader(true);
      setInviteQry('');
    }
  }, [contact]);

  const renderProfileTagsButtons = () => {
    // Return the JSX element conditionally based on props.permission
    return (
      <>
        <img src={RoiLogo} alt="" />
      </>
    );
  };

  return (
    <>
      <SlidingPanel
        renderData={renderProfileTagsButtons}
        showBlackHeader={true}
        lastDivStyle={{borderRadius: '35px', background: '#f7f6f2'}}
        firstDivStyle={{
          padding: 0,
          background:
            'linear-gradient(to bottom, #424242, #424242, #f7f6f2 50%)',
        }}
        hidePanel={() => props.onClose()}>
        {/* <div style={{display: 'flex', flexDirection: 'column', flex: 1}}> */}
        {showLoader && <LoaderSpinner className={'curved-spinner-bg'} />}

        {/* <div className="pb-4 fix-width-forms w-100"> */}

        <div className="create-ticket-wrapper create_recruitment  primary_panel_content">
          {/* <div className="ticket-icons-options">
                <ul>
                  <li
                  
                    className="active"
                    onClick={() => {
                      setTopIcons({
                        ...topIcons,
                        ['personal']: !topIcons.personal,
                      });
                      setShowComponent('persnal-model');
                    }}>
                    
                    <PersnalSvg fill={'white'} />
                   
                  </li>
                  <li>
                      <i className="icon-dollar-sign" />
                  </li>
                  <li
                    className={topIcons.bullseye ? 'active' : ''}
                    onClick={() => {
                      setBullseyeButton(true);
                    }}>
                    <i className="icon-bullseye" />
                  </li>
                  <li
                    className={topIcons.location ? 'active' : ''}
                    onClick={() => {
                      setShowModelDecesion('location');
                      setShowModalButtons(true);
                    }}>
                    <i className="fas fa-map-marker-alt" />
                  </li>
                  <li
                    className={topIcons.visibility ? 'active' : ''}
                    onClick={() => {
                      if (Object.entries(props.item).length > 0) {
                      } else {
                        setShowModelDecesion('visibility');
                        setShowModalButtons(true);
                      }
                    }}>
                    <i className="fas fa-lock" />
                  </li>
                  <li
                    className={topIcons.vaccinate ? 'active' : ''}
                    onClick={() => {
                      setShowModelDecesion('vaccinate');
                      setShowModalButtons(true);
                    }}>
                    <i className="fa-solid fa-syringe"></i>
                  </li>
                  <li
                    className={snapShotButton ? 'active' : ''}
                    onClick={() => {
                      if (hostId) {
                      } else {
                        // setSnapShotButton(true);
                        // setNewButton(true);
                        // setShowComponent('CreateSnapShot');
                        setShowModelDecesion('snapshot');
                        setShowModalButtons(true);
                      }
                    }}>
                    <i className="icon-store"></i>
                  </li>
                  {props.item.visibility && (
                    <li onClick={() => props.onClose()}>
                      <div className="text-right">
                        <span className="close as-link">
                          <i className="fas fa-times" />
                        </span>
                      </div>
                    </li>
                  )}
                </ul>
              </div> */}
          {/* <div>
                <span>Create An Opportunity</span>
              </div> */}

          <div className="m-0 w-100 d-flex align-items-center justify-content-between fixed-width-inputs fix-width-forms pt-3 black-head-form-pad-left black-head-form-pad-right">
            <div className="d-flex">
              <p className="p-bold f_600_h">
                {' '}
                {Object.entries(props.item).length > 0
                  ? 'Update Recruitment'
                  : 'Create Recruitment'}
              </p>
            </div>
            <div className="d-flex"></div>
          </div>
          <div className="custom_tabs d-flex align-items-center justify-content-center w-100">
            <div className="create-ticket-tabs custom-create-ticket-tabs create-editoppertunity-tabs">
              <CustomTabs
                darkmode
                tabs={ticketTabs}
                active={tabType}
                onSelect={item => {
                  setTabType(item);
                  if (
                    item === labels.sessions_type_general[selected_lang] &&
                    inviteQry !== ''
                  ) {
                    setInviteFollowers(followersList);
                    setInviteQry('');
                  }
                }}
              />
            </div>
          </div>
          <Scrollbars>
            <div className="create-ticket-form  fixed-width-inputs black-head-form-pad-left black-head-form-pad-right">
              <Form noValidate validated={validated} onSubmit={submitForm}>
                {tabType === labels.sessions_type_general[selected_lang] && (
                  <div className="create-ticket-form-padding-applied">
                    <div className="fieldset">
                      <div className="d-flex justify-content-between align-items-center">
                        <Form.Label>Type</Form.Label>
                        {oppoValue === '2' && (
                          <div className="d-flex align-items-center">
                            <Form.Check
                              // disabled={props.id ? true : false}
                              // className="p-0"
                              type="switch"
                              id="custom-switch"
                              label={'Internship'}
                              value={internShip}
                              // value={showTraction ? true : false}
                              checked={internShip ? true : false}
                              onClick={() => {
                                setInternShip(!internShip);
                              }}
                            />
                          </div>
                        )}
                      </div>

                      {/* <div className="select-wrapper">
                            <select
                              className="form-control"
                              value={oppoValue}
                              disabled={
                                Object.entries(props.item).length > 0
                                  ? true
                                  : false
                              }
                              onChange={e => setOppoValue(e.target.value)}>
                              {Object.entries(opp).length > 0 &&
                                opp.map(item => (
                                  <option
                                    key={`opt-${item.id}`}
                                    value={item.id}>
                                    {item.type_name}
                                  </option>
                                ))}
                            </select>
                          </div> */}

                      <CustomDropdown
                        setDropdownId={setOppoValue}
                        dropdownId={oppoValue}
                        DropDownValues={opp}
                        itemName={['type_name']}
                        className={'mb-3 recruitment_dropdown'}
                      />
                    </div>

                    <div className="fieldset">
                      <Form.Label>Title</Form.Label>
                      <Form.Control
                        required
                        name="title"
                        value={oppoTitle}
                        pattern="^\S.*$"
                        onChange={e => setOppoTitle(e.target.value)}
                        placeholder="Choose something catchy"
                      />
                      <Form.Control.Feedback type="invalid">
                        Title is required.
                      </Form.Control.Feedback>
                    </div>
                    <div className="fieldset ">
                      <Form.Label>Industry</Form.Label>
                      <div className="d-flex align-items-center justify-content-between cutsom-control-menu">
                        <Form.Control
                          className="as-link"
                          readOnly={'readOnly'}
                          // value={isCheckValue.length > 0 ? isCheckValue.join(', ') : "" }
                          defaultValue={
                            isCheckValue.length > 0
                              ? isCheckValue.join(', ')
                              : ''
                          }
                          required
                          placeholder="Industry"
                          onClick={() => {
                            setShowModal(true);
                            setCheckIndustryId(isCheck);
                            setCheckIndustry(isCheckValue);
                            setIsCheckAll(isSelectedAll);
                          }}
                        />
                        <span className="drop-icon">
                          <i class="fa-solid fa-chevron-down"></i>
                        </span>
                      </div>
                      {isCheckValue.length === 0 && catError === true && (
                        <div className="text-danger textforerror">
                          Industry is required
                        </div>
                      )}
                    </div>
                    {/* <div className="fieldset">
                        <Form.Label>Business name</Form.Label>
                        <Form.Control
                          required
                          autoComplete="off"
                          name="business_name"
                          value={businessName}
                          onChange={e => setBusinessName(e.target.value)}
                          placeholder="Choose something catchy"
                        />
                        <Form.Control.Feedback type="invalid">
                          Business name is required.
                        </Form.Control.Feedback>
                      </div> */}

                    {/* {snapShotButton && ( */}
                    {/* <div className="invite-persons-wrapper">
                          <div className="fieldset">
                            {Object.entries(images).length > 0 &&
                              images.map((item, index) => (
                                <Col md={4} xl={3} key={`idimage-${index}`}>
                                  {item.id === hostId ? (
                                    <div className="profile-img upload-user-img">
                                      <img
                                        src={
                                          item.id === hostId
                                            ? imageCreate
                                            : imageCreate
                                        }
                                        alt=""
                                        onChange={() => {
                                          setImageCreate(
                                            item.id === hostId
                                              ? imageCreate
                                              : imageCreate,
                                          );
                                        }}
                                      />
                                      <span className="icon icon-camera"></span>
                                      <input
                                        type="file"
                                        name="my-image"
                                        id="image"
                                        accept="image/*"
                                        onChange={e => {
                                          changeTicketImgSnapSHot(e);
                                          e.target.value = null;
                                        }}
                                      />
                                    </div>
                                  ) : (
                                    <div className="stage-list-ite">
                                      <div
                                        className="stage-person-img"
                                        type="checkbox">
                                        <img
                                          src={
                                            item.id === hostId
                                              ? imageCreate
                                              : imageCreate
                                          }
                                          alt=""
                                          onChange={() => {
                                            setImageCreate(
                                              item.id === hostId
                                                ? imageCreate
                                                : imageCreate
                                            );
                                          }}
                                        />
                                        <span  onClick={(e) => changeTicketImg(e)}>Upload Image </span>
                                        <Col sm={2}>
                                          <div
                                            className="ticket-img-wrapper mb-3"
                                            style={{
                                              maxWidth: '35px',
                                              maxHeight: '35px',
                                            }}>
                                            <input
                                              type="file"
                                              accept="image/*"
                                              onChange={e => {
                                                changeTicketImgSnapSHot(e);
                                              }}
                                            />

                                            <i className="icon-camera" />
                                          </div>
                                        </Col>
                                      </div>
                                    </div>
                                    
                                  )}
                                </Col>
                              ))}
                          </div>
                          <div className="fieldset">
                            <div className="select-wrapper">
                              <select
                                value={hostId}
                                onChange={e => {
                                  setHostId(e.target.value);
                                  setChange(!change);
                                }}>
                                {Object.entries(images).length > 0 ? (
                                  Object.entries(images).length > 0 &&
                                  images.map((item, index) => (
                                    <option
                                      value={item.id}
                                      key={`cat-${index}`}>
                                      {item.title}
                                    </option>
                                  ))
                                ) : (
                                  <></>
                                )}
                              </select>
                            </div>
                          </div>
                        </div> */}
                    {/* )} */}
                    <div className="fieldset">
                      <Form.Label>Description </Form.Label>
                      <textarea
                        className="form-control"
                        id="textAreaExample1"
                        rows="6"
                        required
                        name="description"
                        value={oppoDescription}
                        autoComplete="off"
                        onChange={e => setOppoDescription(e.target.value)}
                        placeholder="Choose something catchy"
                      />
                      <Form.Control.Feedback type="invalid">
                        Description is required.
                      </Form.Control.Feedback>
                    </div>
                    {topIcons.location && (
                      <div className="fieldset">
                        <div className="d-flex justify-content-between align-items-center">
                          <Form.Label className="mb-0">Location</Form.Label>
                          <div className="d-flex align-items-center">
                            <Form.Check
                              // disabled={props.id ? true : false}
                              // className="p-0"
                              type="switch"
                              id="custom-switchssss"
                              label={'Remote'}
                              value={showError}
                              // value={showTraction ? true : false}
                              checked={showError ? true : false}
                              onClick={() => {
                                setShowError(!showError);
                                if (!showError) {
                                  setLocationError(false);
                                }
                              }}
                            />
                          </div>
                          {/* {oppoAddress !== '' && (
                                <span
                                  className="clear-btn"
                                  onClick={() => {
                                    clearAll();
                                  }}>
                                  Clear
                                </span>
                              )} */}
                        </div>
                        <div className="location-field form-control form-control-with-icon create_session">
                          <GooglePlacesAutocomplete
                            selectProps={{
                              onChange: setOppoAddress,
                              name: 'address',
                              placeholder: jugar,
                              noOptionsMessage: () => null,
                              isDisabled: showError ? true : false,
                            }}
                          />

                          {oppoAddress !== '' && (
                            <span
                              className="location_clear_icon"
                              onClick={() => {
                                clearAll();
                              }}>
                              <i
                                className="fas fa-close"
                                // style={{paddingLeft: '210px'}}
                              />
                            </span>
                          )}
                        </div>

                        {oppoAddress === '' && loactionError === true && (
                          <div className="text-danger textforerror">
                            Location is required.
                          </div>
                        )}
                      </div>
                    )}

                    {/* <div className="fieldset">
                        <Form.Label
                          className="as-link primary-color"
                          onClick={() =>
                            setSingleArrData({
                              ...singleArrData,
                              ['skill_set']: !singleArrData.skill_set,
                            })
                          }>
                          Add desired experience (optional)
                        </Form.Label>
                        <Form.Control
                          style={{
                            display: singleArrData.skill_set ? 'block' : 'none',
                          }}
                          name="skill_set"
                          value={skillSet}
                          onChange={e => setSkillSet(e.target.value)}
                          placeholder="Add skillset preference"
                        />
                      </div> */}
                    <div className="fieldset">
                      <Form.Label>Skillset preference (Optional)</Form.Label>
                      <Form.Control
                        name="skill_set"
                        value={skillSet}
                        onChange={e => setSkillSet(e.target.value)}
                        placeholder="Add skillset preference"
                      />
                    </div>
                    <div className="fieldset">
                      <Form.Label>URL (Optional)</Form.Label>
                      <Form.Control
                        type="url"
                        name="url"
                        value={oppoUrl}
                        onChange={e => setOppoUrl(e.target.value)}
                        placeholder="Add URL"
                      />
                      <Form.Control.Feedback type="invalid">
                        Invalid url.
                      </Form.Control.Feedback>
                    </div>

                    {/* <div className="fieldset">
                        <Form.Label
                          className="as-link primary-color"
                          onClick={() =>
                            setSingleArrData({
                              ...singleArrData,
                              ['url']: !singleArrData.url,
                            })
                          }>
                          Add URL(optional)
                        </Form.Label>
                        <Form.Control
                          type="url"
                          style={{
                            display: singleArrData.url ? 'block' : 'none',
                          }}
                          name="url"
                          value={oppoUrl}
                          onChange={e => setOppoUrl(e.target.value)}
                          placeholder="Add URL"
                        />
                        <Form.Control.Feedback type="invalid">
                          Invalid url.
                        </Form.Control.Feedback>
                      </div> */}

                    <div className="fieldset event-btn-wrapper text-center">
                      <Button
                        disabled={disableBtn ? true : false}
                        type="submit"
                        className={
                          disableBtn ? 'disable btn-dark m-0' : 'btn-dark m-0'
                        }
                        style={{display: 'none'}}
                        ref={buttonRef}>
                        {props.item.visibility
                          ? 'Update Recruitment'
                          : 'Create Recruitment'}
                      </Button>
                    </div>
                  </div>
                )}
                {tabType === labels.funds[selected_lang] && (
                  <>
                    {['2', '5'].includes(oppoValue) && (
                      <div className="frequency-box ">
                        <Form.Label>Frequency</Form.Label>
                        {/* <h2>Frequency</h2> */}
                        <Row>
                          {Object.entries(frequency).length > 0 &&
                            ['2', '5'].includes(oppoValue) &&
                            frequency.map((item, index) => (
                              <Col
                                md={4}
                                sm={4}
                                xs={12}
                                key={`frequency-${index}`}>
                                <Form.Group className="fieldset funds-frequency">
                                  <Form.Check
                                    required
                                    type="radio"
                                    name="frequency"
                                    label={item.title}
                                    id={item.id}
                                    value={item.id}
                                    checked={frequencyIdvalue === item.id}
                                    onChange={handleChange}
                                    // checked={frequencyIdvalue}
                                    // defaultChecked={frequencyIdvalue ? frequencyIdvalue : ""}
                                    // checked={props.freq.includes(item.id)}
                                    // disabled={
                                    //   opportunity.includes("2") ? false : true
                                    // }
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    Frequency is required.
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </Col>
                            ))}
                        </Row>
                      </div>
                    )}
                    <div className="search-tabs-wrapper mt-1">
                      {['2', '5'].includes(oppoValue) && (
                        // <p>Desired hourly pay</p>
                        <Form.Label>Desired hourly pay</Form.Label>
                      )}
                      {['1'].includes(oppoValue) && (
                        // <p>Investment Amount</p>
                        <Form.Label>Investment Amount</Form.Label>
                      )}
                      {['3'].includes(oppoValue) && (
                        //  <p>Budget Amount</p>
                        <Form.Label>Budget Amount</Form.Label>
                      )}
                      <div className="search-form d-flex mt-2">
                        <Form.Group className="search-input search-input-oppertunity-field">
                          <Form.Control
                            disabled={
                              Object.entries(props.item).length > 0
                                ? true
                                : false
                            }
                            type="number"
                            required
                            min={'1'}
                            placeholder="Enter the amount"
                            value={amount}
                            onChange={e => setAmount(e.target.value)}
                          />
                          <Form.Control.Feedback type="invalid">
                            {amount
                              ? 'Invalid hourly pay '
                              : 'Desired hourly pay is required.'}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </div>
                    </div>
                  </>
                )}
              </Form>
              {tabType === labels.sessions_type_invites[selected_lang] && (
                <>
                  <div className="refferal-list-wrapper">
                    {inviteLoader && (
                      <div className="invite_loader_hieght">
                        <LoaderSpinner />
                      </div>
                    )}

                    {!inviteLoader && (
                      <>
                        <div
                          className="d-flex invitation_outside as-link align-items-center"
                          onClick={() => {
                            setShowComponent('inviteUser');
                          }}>
                          <div className="add_contact_img">
                            <img alt="" src={UserProfileImg} />
                          </div>
                          <p className="p-reg text-gey">New Contact</p>
                        </div>
                        <div className="CreateSession-invite-radio-btns ">
                          {Object.entries(RadioButtonName).length > 0 &&
                            RadioButtonName.map((item, index) => (
                              <Form.Check
                                type="radio"
                                // name="radioGroup"
                                label={item}
                                id={item}
                                value={item}
                                checked={invitesOptionvalue === item}
                                onChange={handleInviteOption}
                              />
                            ))}
                        </div>
                      </>
                    )}

                    <Invite
                      type={'opportunity'}
                      moderator={modrator}
                      setModerator={setModrator}
                      selectedModData={selectedModData}
                      setSelectedModData={setSelectedModData}
                      audience={audience}
                      setAudience={setAudience}
                      selectedAudData={selectedAudData}
                      setSelectedAudData={setSelectedAudData}
                      contact={contact}
                      setContact={setContact}
                      originalDataOfMod={originalDataOfMod}
                      setOriginalDataOfMod={setOriginalDataOfMod}
                      originalDataOfAud={originalDataOfAud}
                      setOriginalDataOfAud={setOriginalDataOfAud}
                      subTabs={subTabs}
                      setSubTabs={setSubTabs}
                      alreadyAddedMod={alreadyAddedMod}
                      alreadyAddedAud={alreadyAddedAud}
                      handleCircle={handleCircle}
                      onClick={() => {
                        setShowComponent('inviteUser');
                        setTimeout(() => {
                          if (contact.length === 0 && inviteQry !== '') {
                            setInviteFollowers(followersList);
                            setInviteQry('');
                          }
                        }, 1000);
                      }}
                      forApiHit={showComponent}
                      showLoader={inviteLoader}
                      setshowLoader={setInviteLoader}
                      followers={inviteFollowers}
                      setFollowers={setInviteFollowers}
                      setFollowersList={setFollowersList}
                      followersList={followersList}
                      setSearchQry={setInviteQry}
                      searchQry={inviteQry}
                    />
                  </div>
                </>
              )}
            </div>
          </Scrollbars>
        </div>
        {/* </div> */}

        {!showLoader &&
          tabType === labels.sessions_type_general[selected_lang] && (
            <div className="fieldset event-btn-wrapper text-center bg_transparent bottom-0 pb-2">
              <Button
                type="submit"
                disabled={disableBtn ? true : false}
                onClick={() => {
                  buttonRef.current.click();
                }}
                className={
                  disableBtn ? 'disable btn-dark m-0' : 'btn-dark m-0'
                }>
                {props.item.visibility
                  ? 'Update Recruitment'
                  : 'Create Recruitment'}
              </Button>
            </div>
          )}
        {/* </div> */}
      </SlidingPanel>

      {audience.length > 0 && showComponent === 'AudSelectedUsers' && (
        <SelectedUsers
          onClose={() => {
            setShowComponent('');
          }}
          selectedAllUser={selectedAudData}
          setSelectedAllUser={setSelectedAudData}
          setUsers={setAudience}
          users={audience}
          alreadyAddedUser={alreadyAddedAud}
          //   enterSession={enterSession}
        />
      )}
      {showComponent === 'persnal-model' && (
        <PersonalCreateModel
          list={list}
          setWorkSpaceIds={setWorkSpaceIds}
          workSpaceIds={workSpaceIds}
          onClose={() => {
            setShowComponent('');
            setTopIcons({
              ...topIcons,
              personal: !topIcons.personal,
            });
          }}
        />
      )}
      {modrator.length > 0 && showComponent === 'ModSelectedUsers' && (
        <SelectedUsers
          onClose={() => setShowComponent('')}
          selectedAllUser={selectedModData}
          setSelectedAllUser={setSelectedModData}
          setUsers={setModrator}
          users={modrator}
          alreadyAddedUser={alreadyAddedMod}
        />
      )}

      {showComponent === 'CircleList' && (
        <CircleListBuilders
          onClose={() => {
            setShowComponent('');
          }}
          // setInviteCircle={setInviteCircle}
          hide={true}
          CircleUserListData={CircleUserListData}
        />
      )}
      {newButton === true && showComponent === 'CreateSnapShot' && (
        <CreateSnapShot
          images={images}
          setImages={setImages}
          setHostId={setHostId}
          hostId={hostId}
          onClose={() => {
            setNewButton(false);
            setShowComponent('');
          }}
        />
      )}

      {bullseyeButton === true && (
        <SessionFocus
          setTopIcons={setTopIcons}
          topIcons={topIcons}
          setIdentiferId={setIdentiferId}
          setEthnicityId={setEthnicityId}
          setGender={setGender}
          gender={gender}
          ethnicityId={ethnicityId}
          setFocusLocations={setFocusLocations}
          setPersnalTypesId={setPersnalTypesId}
          setCategoriesId={setCategoriesId}
          setAppointmentId={setAppointmentId}
          persnalTypesId={persnalTypesId}
          appointmentId={appointmentId}
          categoriesId={categoriesId}
          onClose={() => setBullseyeButton(false)}
        />
      )}

      {/* {showModalButtons && (
        <CustomModal size="small" onClose={() => setShowModalButtons(false)}>
          <div className="permission-modal-content text-center">
            {showModelDecesion === 'snapshot' && (
              <h3>
                <i className="icon-store  "></i>
                Host
              </h3>
            )}
            {showModelDecesion === 'vaccinate' && (
              <h3>
                <i className="fa-solid fa-syringe"></i>
                Vaccinated
              </h3>
            )}
            {showModelDecesion === 'visibility' && (
              <h3>
                <i className="fas fa-lock" />
                Private
              </h3>
            )}
            {showModelDecesion === 'location' && (
              <h3>
                <i className="fas fa-map-marker-alt" />
                Location
              </h3>
            )}

            {showModelDecesion === 'vaccinate' && (
              <p>This will show vaccination is required.</p>
            )}
            {showModelDecesion === 'snapshot' && (
              <p>Are you sure you want to enable this feature?</p>
            )}
            {showModelDecesion === 'visibility' && (
              <p>This feature is for invites only.</p>
            )}
            {showModelDecesion === 'location' && (
              <p>This feature is enable location.</p>
            )}

            <div className="event-btn-wrapper">
              <Button
                className="btn-dark"
                onClick={() => {
                  if (showModelDecesion === 'snapshot') {
                    setSnapShotButton(true);
                    setNewButton(true);
                    setShowComponent('CreateSnapShot');
                  }
                  if (showModelDecesion === 'vaccinate') {
                    setTopIcons({
                      ...topIcons,
                      ['vaccinate']: !topIcons.vaccinate,
                    });
                  }
                  if (showModelDecesion === 'visibility') {
                    setTopIcons({
                      ...topIcons,
                      ['visibility']: !topIcons.visibility,
                    });
                  }
                  if (showModelDecesion === 'location') {
                    setTopIcons({
                      ...topIcons,
                      ['location']: !topIcons.location,
                    });
                    clearAll();
                  }

                  setShowModalButtons(false);
                }}>
                {hostId === '' && showModelDecesion === 'snapshot' && ' Enable'}
                {topIcons.vaccinate && showModelDecesion === 'vaccinate'
                  ? 'Disable'
                  : !topIcons.vaccinate &&
                    showModelDecesion === 'vaccinate' &&
                    ' Enable'}
                {topIcons.visibility && showModelDecesion === 'visibility'
                  ? 'Disable'
                  : !topIcons.visibility &&
                    showModelDecesion === 'visibility' &&
                    ' Enable'}
                {topIcons.location && showModelDecesion === 'location'
                  ? 'Disable'
                  : !topIcons.location &&
                    showModelDecesion === 'location' &&
                    ' Enable'}
              </Button>
              <div className=" text-center mt-2 as-link">
                <span
                  onClick={() => {
                    setShowModalButtons(false);
                  }}>
                  Nevermind
                </span>
              </div>
            </div>
          </div>
        </CustomModal>
      )} */}
      {ShowModal && (
        <CustomModal
          size="small"
          onClose={() => {
            setShowModal(false);
            setCheckIndustryId([]);
            setCheckIndustry([]);
            setIsCheckAll(false);
          }}
          paddingBottomZero={true}>
          <div className="edit-opportunity-modal">
            <div className="filter-headings">
              <h2>Industry</h2>
            </div>
            <div className="filter-wrapper" style={{paddingBottom: '65px'}}>
              <div className="filter-category-list">
                <ul>
                  <li className={isCheckAll ? 'active' : ''}>
                    <Form.Label>
                      All{' '}
                      <input
                        type="checkbox"
                        id="ALL"
                        name="ALL"
                        value="ALL"
                        onChange={handleSelectAll}
                        checked={isCheckAll}
                      />{' '}
                      <span />
                    </Form.Label>
                  </li>
                  {Object.entries(oppoBackground).length > 0 &&
                    oppoBackground.map((item, index) => (
                      <li
                        key={`sessionC-${index}`}
                        className={
                          checkIndustryId.includes(item.id) ||
                          checkIndustry.includes(item.title)
                            ? 'active'
                            : ''
                        }>
                        <Form.Label>
                          {item.title}
                          <input
                            type="checkbox"
                            id={item.id}
                            value={item.title}
                            onChange={e => {
                              handleClick(e);
                            }}
                            checked={
                              checkIndustryId.includes(item.id) ||
                              checkIndustry.includes(item.title)
                            }
                          />
                          <span />
                        </Form.Label>
                      </li>
                    ))}
                </ul>
              </div>
            </div>
            <div
              className=" bg_transparent text-center pt-2 pb-3 bottom-0"
              style={{
                borderBottomLeftRadius: '25px',
                borderBottomLeftRadius: '25px',
              }}>
              <Button
                type="submit"
                className="btn-dark mt-2"
                onClick={() => {
                  handleSelectIndustry();
                }}>
                OK
              </Button>
            </div>
          </div>
        </CustomModal>
      )}
      {showComponent === 'inviteUser' && (
        <SendInvitation
          setContact={setContact}
          onClose={() => {
            // if(contact.length > 0){
            //   setInviteLoader(true)
            //   setInviteQry('')
            // }
            setShowComponent('');
          }}
        />
      )}

      {/* <Modal show={showModalButtons}>
        <Modal.Header closeButton>
          <Modal.Title>
            {showModelDecesion === 'vaccinate' && (
              <div className="text-center">
                <i className="fa-solid fa-syringe"></i> <br />
                Vaccinated
              </div>
            )}
            {showModelDecesion === 'visibility' && (
              <div className="text-center">
                <i className="fas fa-lock" />
                <br />
                Private
              </div>
            )}
            {showModelDecesion === 'location' && (
              <div className="text-center">
                <i className="fas fa-map-marker-alt" />
                <br />
                Location
              </div>
            )}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {showModelDecesion === 'vaccinate' &&
            'This will show vaccination is required.'}
          {showModelDecesion === 'visibility' &&
            'This feature is for invites only.'}
          {showModelDecesion === 'location' &&
            'This feature is enable location.'}
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              if (showModelDecesion === 'vaccinate') {
                setTopIcons({...topIcons, ['vaccinate']: !topIcons.vaccinate});
              }
              if (showModelDecesion === 'visibility') {
                setTopIcons({
                  ...topIcons,
                  ['visibility']: !topIcons.visibility,
                });
              }
              if (showModelDecesion === 'location') {
                setTopIcons({...topIcons, ['location']: !topIcons.location});
                clearAll();
              }

              setShowModalButtons(false);
            }}>
            {topIcons.vaccinate && showModelDecesion === 'vaccinate'
              ? 'DISABLE'
              : !topIcons.vaccinate &&
                showModelDecesion === 'vaccinate' &&
                ' ENABLE'}
            {topIcons.visibility && showModelDecesion === 'visibility'
              ? 'DISABLE'
              : !topIcons.visibility &&
                showModelDecesion === 'visibility' &&
                ' ENABLE'}
            {topIcons.location && showModelDecesion === 'location'
              ? 'DISABLE'
              : !topIcons.location &&
                showModelDecesion === 'location' &&
                ' ENABLE'}
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              setShowModalButtons(false);
            }}>
            Nevermind
          </Button>
        </Modal.Footer>
      </Modal> */}
    </>
  );
};
export default EditOpportunities;
