import {React, useState} from 'react';
import Scrollbars from 'react-custom-scrollbars';
import {fixName} from '../../Utils/commonUtils';
import {DEFAULT_IMAGE} from '../../Utils/env';
import {Button, Col, Form} from 'react-bootstrap';

export default function AddCC(props) {
  // const arrayOfselectedUsers= props.companyData.cc_user_ids.split(',')

  const [selectedUserIds, setSelectedUserIds] = useState(
    props.companydetailApiData?.cc_user_ids.length > 0
      ? props.companydetailApiData?.cc_user_ids
      : [],
  );
  // const [selectedUserItems, setSelectedUserItems] = useState([]);

  const handleSelect = mod => {
    props.setCompanydetailApiData(prevData => ({
      ...prevData,
      cc_user_ids: selectedUserIds,
    }));
    props.onClose();
    //
  };

  const handleUser = item => {
    if (!selectedUserIds.includes(item.id)) {
      if (props.type === 'referrel') {
        setSelectedUserIds([item.id]);
      } else {
        setSelectedUserIds(previous => [...previous, item.id]);
      }
      // setSelectedUserItems(previous => [...previous, item]);
    } else {
      setSelectedUserIds(previous => previous.filter(id => id !== item.id));

      // setSelectedUserItems(previous =>
      //   previous.filter(miniItem => miniItem.id !== item.id),
      // );
    }
  };

  return (
    <>
      <div
        style={{minHeight: props.type === 'referrel' ? '77vh' : '98vh'}}
        className="sidebar-modal with-custom-scroll d-flex flex-column">
        <Scrollbars className="custom-scroll">
          <div className="invite-page-search">
            {props.type === 'referrel' && (
              <div style={{margin: '10px'}} className="search-form">
                  <Form.Control
                    onChange={e => {
                      props.setSearchQry(e.target.value);
                    }}
                    value={props.searchQry}
                    type="text"
                    placeholder={'Search from your network'}
                  />
              </div>
            )}
          </div>
          <div className="invite-persons-wrapper">
            <div className="invite-persons-list">
              <div className="pl-0 container">
                <div className="row">
                  <div className="invite-persons-heading pl-0">
                    {/* <span className=" for-grey p-bold  ">
                      Select from your network
                    </span>
                    <span className="circle-link-icon">
                      Circles <i className="icon-circles" />
                    </span> */}
                  </div>

                  {props.companydetailApiData?.show_cc_user_array.length > 0 ? (
                    props.companydetailApiData?.show_cc_user_array.map(
                      (item, index) => (
                        <Col key={`f-item-${index}`} md={4} xl={3}>
                          <div
                            className="stage-list-item "
                            key={`folowers-${index}`}>
                            <div
                              className="stage-person-img as-link"
                              type="checkbox"
                              onClick={() => {
                                handleUser(item);
                              }}>
                              <img
                                className={
                                  selectedUserIds.includes(item.id)
                                    ? 'selercted-user'
                                    : ''
                                }
                                src={
                                  item.profile_img === ''
                                    ? DEFAULT_IMAGE
                                    : item.profile_img
                                }
                                alt=""
                                id={item.id}
                              />
                            </div>
                            <div className="stage-person-name as-link">
                              <h3>
                                {fixName(item.first_name, item.last_name)}
                              </h3>
                            </div>
                          </div>
                        </Col>
                      ),
                    )
                  ) : (
                    <div className="no_record_main"> No record found</div>
                  )}
                </div>
              </div>
            </div>
          </div>
          {/* <div className="btn-wrapper">
            <button type="button" className="btn btn-green btn btn-primary">
              Select
            </button>
          </div> */}
        </Scrollbars>

        {props.companydetailApiData?.show_cc_user_array.length > 0 && (
          <div className="btn-wrapper">
            <Button
              className="btn btn-green"
              onClick={() => {
                handleSelect();
              }}>
              Select
            </Button>
          </div>
        )}
      </div>
    </>
  );
}
