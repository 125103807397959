import React, {useState, useEffect} from 'react';
import Sidebar from '../../Pages/Sidebar';
import { selected_lang} from '../../Utils/env';
import Pannels from '../Pannels';
import LoaderSpinner from '../Modals/LoaderSpinner';
import commonApi from '../../Services/CommonService';
import {errorMessage, successMessage} from '../../Utils/ToastMessages';
import SnapshotDetail from './SnapshotDetail';
import {labels} from '../../Constants/Translations';
import {Button, Form} from 'react-bootstrap';
import CreateAction from './CreateAction';
import CreateWorkSpace from '../WorkSpace/CreateWorkSpace';
import CustomModal from '../CustomModal';
import Actions from '../Actions/Actions';
import ActionDetail from '../Actions/ActionDetail';
import {useSelector, useDispatch} from 'react-redux';
import {getAllActions} from '../../Store/Actions/SessionAction';

const SnapShotUsersList = props => {
  const dispatch = useDispatch();
  const [isFloated, toggleFloatedPanel] = useState(false);
  const [showHiddenPanel, toggleHiddenPanel] = useState(false);
  const [showLoader, setshowLoader] = useState(false);
  const [userListing, setUserListing] = useState([]);
  const [showComponent, setShowComponent] = useState('');
  const [snapShotId, setSnapShotId] = useState('');
  const [tabType, setTabType] = useState('notification');
  const [threeDots, setThreeDots] = useState('');
  // const [actionType, setActionType] = useState('');
  const [searchQry, setSearchQry] = useState('');
  const [searchFilter, setSearchFilterModal] = useState(false);
  // const [actionDetail, setActionDetail] = useState(false);
  const [checkFilterOption, setCheckFilterOption] = useState([]);
  const [filterOptionValue, setFilterOptionValue] = useState([]);
  const [isCheckAll, setIsCheckAll] = useState(true);
  // const [actionType, setActionType] = useState([1]);
  const [actionTypeValue, setActionTypeValue] = useState(['All']);
  // const [actionsData, setActionsData] = useState([]);
  const [dataOfAction, setDataOfAction] = useState([]);
  const [hitApi, setHitApi] = useState(false);
  // const ActionCategories = [
  //   {id: 1, title: 'All'},
  //   {id: 2, title: 'Me'},
  //   {id: 3, title: 'Other'},
  // ];
  const [workspaceData, setWorkspaceData] = useState([]);
  const actions = useSelector(state => state.AllActionsList);
  const filterOption = [
    {id: 1, title: 'Pending'},
    {id: 2, title: 'Completed'},
    {id: 3, title: 'Expire'},
  ];

  useEffect(() => {
    // if (Object.entries(workspaceData).length > 0) {
    //   setTimeout(() => {
        getData();
    //   }, 500);
    // }
  }, [workspaceData]);

  const getData = () => {
    
    setshowLoader(true);

    commonApi
      .get_accomplishment_invitation_by_login_id()
      .then(res => {
        if (res.status === 200) {
          if(res.accomplishment_invitations.length ===0){
            localStorage.setItem(
              'snapshot_invitation',
             ''
            );
          }
          setUserListing(res.accomplishment_invitations);
        }
        setshowLoader(false);
      })
      .catch(err => {
        
        return {type: 'error', message: err.message};
      });
  };
  const manageAccomplishmentInvitation = (status, id) => {
    let payload = {
      status: status,
      id: id,
    };
    commonApi
      .manage_accomplishment_invitation(payload)
      .then(res => {
        if (res.status === 200) {
          successMessage(res.message);
          getData();
        }
      })
      .catch(err => {
       
        return {type: 'error', message: err.message};
      });
  };

  useEffect(() => {
    if (showComponent !== '') {
      toggleHiddenPanel(true);
    } else {
      toggleHiddenPanel(false);
    }
  }, [showComponent]);

  const handleClickList = (id, value) => {
    // const {id, value} = e.target
    
    if (!checkFilterOption.includes(id)) {
      setCheckFilterOption([...checkFilterOption, id]);
      setFilterOptionValue([...filterOptionValue, value]);
    } else {
      if (checkFilterOption.length === 1) {
        errorMessage('One workspace should be selected.');
      } else {
        setCheckFilterOption(checkFilterOption.filter(itemId => itemId !== id));
        setFilterOptionValue(
          filterOptionValue.filter(itemValue => itemValue !== value),
        );
      }
    }
  };

  const handleSelectAll = () => {
    if (!isCheckAll) {
      setCheckFilterOption(filterOption.map(item => item.id));
      setFilterOptionValue(filterOption.map(item => item.title));
      setIsCheckAll(true);
    } else {
      setIsCheckAll(false);
      setCheckFilterOption([]);
      setFilterOptionValue([]);
    }
  };

  useEffect(() => {
    if (filterOptionValue.length > 0) {
      if (filterOptionValue.length === filterOption.length) {
        setIsCheckAll(true);
      } else {
        setIsCheckAll(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkFilterOption]);

  const getActions = () => {
    const actions = actionTypeValue.map(element => {
      return element.toLowerCase();
    });

    const options = filterOptionValue.map(element => {
      return element.toLowerCase();
    });
    
    

    var Payload = {
      user_id: localStorage.getItem('id'),
      type: actions,
      search_query: searchQry,
      filter: isCheckAll ? ['all'] : options.includes('watching') ? ['other'] : options,
    };

    dispatch(getAllActions(Payload));
  };

  useEffect(() => {
    if (isCheckAll) {
      setCheckFilterOption(filterOption.map(item => item.id));
      setFilterOptionValue(filterOption.map(item => item.title));
    }
    getActions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // const handleType = e => {
  //   const {id, value} = e.target;
    

  //   setActionType(id);
  //   setActionTypeValue([value]);
  //   // 
  //   // if (!actionType.includes(id)) {
  //   //   setActionType([id]);
  //   //   setActionTypeValue([value]);
  //   // } else {
  //   //   if (actionType.length == 1) {
  //   //     errorMessage('One should be selected ');
  //   //   } else {
  //   //     setActionType(
  //   //       actionType.filter(itemId => itemId !== id),
  //   //     );
  //   //     setActionTypeValue(
  //   //       actionTypeValue.filter(itemValue => itemValue !== value),
  //   //     );
  //   //   }
  //   // }
  // };
  
 
  
  return (
    <div className="page-wrapper">
      <Sidebar
        location={props.location}
        showComponent={showComponent}
        setShowComponent={setShowComponent}
        setWorkSpaceItem={setWorkspaceData}
        snapShotCount={userListing}
      />
      <div className="page-content-wrapper">
      
          <Pannels
            isFloated={isFloated}
            toggleFloatedPanel={toggleFloatedPanel}
            showHiddenPanel={showHiddenPanel}
            updateHiddenPanelState={nState => toggleHiddenPanel(nState)}
            primary={
              <>
               <div className="messages-header">
                    {/* <div className="event-tabs dark-tabs"> */}
                    <div className="event-tabs tabs-style-2 mb-0">
                      {' '}
                      {/* Merging */}
                      <ul className="LabelTabs">
                        <li>
                        <Button
                          className={tabType === 'notification' ? 'active' : ''}
                          onClick={() => {
                            setTabType('notification');
                          }}>
                          {labels.NOTIFICATIONS[selected_lang]}
                        </Button>
                      </li>
                        <li className="ml-20 d-flex align-items-center">
                          <Button
                            className={tabType === 'actions' ? 'active' : ''}
                            onClick={() => {
                              setTabType('actions');
                            }}>
                            {labels.ACTIONS[selected_lang]}
                          </Button>
                          <span className="actionCount">
                            {actions && actions.length}
                          </span>
                        </li>
                      </ul>
                    </div>

                    {/* <span className="add-icon">
                          <i
                            className="icon-plus"
                            onClick={() => setSecondComponent("group-component")}
                          />
                        </span>
                        <span className="add-icon">
                          <i
                            className="icon-filter-icon"
                            onClick={() => setSearchFilterModal(true)}
                          />
                        </span> */}

                    <div className="search-tabs-icons">
                      {tabType === 'actions' && (
                        <span
                          className={
                            showComponent === 'addAction'
                              ? 'icon active'
                              : 'icon'
                          }>
                          <i
                            className="icon-plus"
                            onClick={() => {
                              if (showComponent === 'addAction') {
                                setShowComponent('');
                              } else {
                                setShowComponent('addAction');
                              }
                            }}
                          />
                        </span>
                      )}

                      <span
                        onClick={() => {
                          setThreeDots(!threeDots);
                        }}
                        className={threeDots ? 'icon active' : 'icon'}>
                        <i className="fas fa-ellipsis-vertical" />
                      </span>
                    </div>
                  </div>
                <div
                  className="pt-0 custom-snapshot-user-scrollbar"
                  style={{overflowX: 'hidden', height:'100%', overflowY:'hidden'}}
                  >
                 
                  {threeDots && tabType !== 'actions' && (
                    <div className="person-search-wrapper mb-0 messages-users-wrapper">
                      <div className="search-form d-flex">
                        <Form.Group className="search-input">
                          <Form.Control
                            type="text"
                            placeholder={
                              tabType === 'actions'
                                ? 'Search for task'
                                : 'Search for notification'
                            }
                            value={searchQry}
                            onChange={e => {
                              setSearchQry(e.target.value);
                            }}
                          />
                        </Form.Group>
                        {tabType === 'actions' && (
                          <Button>
                            <i
                              className="icon-filter-icon"
                              onClick={() => setSearchFilterModal(true)}
                            />
                          </Button>
                        )}
                      </div>
                    </div>
                  )}
                  {showLoader && <LoaderSpinner />}

                  {tabType === 'notification' && (
                    <div className="mt-4">
                      {Object.entries(userListing).length > 0 ? (
                        userListing.map((userListing, index) => (
                          <div
                            className="refferals-list notification-listing"
                            key={index + 'userListing'}>
                            <div className="refferals-list-item card as-link">
                              <div
                                className="refferal-img as-link"
                                onClick={() => {
                                  setSnapShotId(userListing);
                                  setShowComponent('SnapShotDetail');
                                }}>
                                <img
                                  src={userListing.accomplishment_images}
                                  alt=""
                                />
                              </div>
                              <div className="as-link forwidth">
                                <div
                                  className="refferal-content as-link mb-2"
                                  onClick={() => {
                                    setSnapShotId(userListing);
                                    setShowComponent('SnapShotDetail');
                                  }}>
                                  <h2 className="single-line-text">
                                    {userListing.accomplishment_title}
                                  </h2>
                                  {userListing.accomplishment_sub_category_name && (
                                    <span className="refferal-auther single-line-text">
                                      {
                                        userListing.accomplishment_sub_category_name
                                      }
                                    </span>
                                  )}

                                  {userListing.accomplishment_role_name && (
                                    <span className="refferal-info-que single-line-text">
                                      {userListing.accomplishment_role_name}
                                    </span>
                                  )}
                                </div>
                                <div className="refferal-like-unlike">
                                  <span
                                    className="refferal-icons as-link"
                                    onClick={() => {
                                      manageAccomplishmentInvitation(
                                        'accepted',
                                        userListing.id,
                                      );
                                    }}>
                                    <i className="icon-like-icon active" />
                                  </span>
                                  <span
                                    className="refferal-icons as-link"
                                    onClick={() => {
                                      manageAccomplishmentInvitation(
                                        'rejected',
                                        userListing.id,
                                      );
                                    }}>
                                    <i className="icon-unlike" />
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))
                      ) : (
                        <div className="no-results-wrapper payment-no-results no-record-notification">
                          <div className="img-bg-wrapper">
                            <img
                              src={require('../../Assets/images/norecord/no-notifications.png')}
                              alt=""
                            />
                          </div>

                          <div className="no-results-content">
                            <h2>
                              {labels.Notifications_Pending[selected_lang]}
                            </h2>
                            <p>{labels.NoAction_Desc[selected_lang]}</p>

                            {/* <div className="btn-wrapper">
                      <Button
                        className="btn btn-dark"
                        onClick={() => {
                          setShowComponent('AddAppointment');
                          // setonBoardingModal(true);
                        }}>
                        Create Appointment Type
                      </Button>
                    </div> */}
                          </div>
                        </div>
                      )}
                    </div>
                  )}

                  {tabType === 'actions' && (
                    //                   <div className="mt-4">
                    //                     <div>
                    //                       <div className="category-btns-wrapper category-btns-wrapper-slider mt-0 mb-4">
                    //                         <CustomSlidingButtons
                    //                           items={ActionCategories}
                    //                           activeButtons={actionType}
                    //                           onItemClick={e => {
                    //                             handleType(e);
                    //                           }}
                    //                           showItems={3}
                    //                           value={actionTypeValue}
                    //                         />
                    //                       </div>
                    //                     </div>
                    //                     {Object.entries(actionsData).length > 0 ? actionsData.map((item, index) =>  (
                    //                       <div className="custom-scrollbar">
                    //                         <h2 className="single-line-text actions-title">
                    //                           {item.title}
                    //                         </h2>
                    //                         <div className="d-flex align-items-center session-cam mb-2">
                    //                           <img src={VideoGreyIcon} alt="video-cam" />
                    //                           <p className="p-reg m-0 single-line-text">
                    //                           {item.association_name}
                    //                           </p>
                    //                         </div>
                    //                         <p className="p-reg m-0 mb-2">
                    //                           Due tomorrow ({item.priority} Priority)
                    //                         </p>
                    //                         <div
                    //                           className="d-flex align-items-center justify-content-between"
                    //                           style={{alignItems: 'center'}}>
                    //                           <div className="d-flex align-items-center">
                    //                             <p className="p-reg m-0 actionsCategories single-line-text">
                    //                               For you from {fixName(item.creator_detail
                    // .first_name, item.creator_detail
                    // .last_name)}
                    //                             </p>
                    //                             <span className="redDot"></span>
                    //                           </div>
                    //                           <img
                    //                             height={20}
                    //                             width={20}
                    //                             src={CheckmarkFillGrey}
                    //                             alt="checkmark"
                    //                           />
                    //                         </div>
                    //                       </div>
                    //           )) : (
                    //                       <div className="no-results-wrapper payment-no-results no-record-notification">
                    //                         <div className="img-bg-wrapper">
                    //                           <img
                    //                             src={require('../../Assets/images/norecord/no-actions.png')}
                    //                             alt=""
                    //                           />
                    //                         </div>

                    //                         <div className="no-results-content">
                    //                           <h2>{labels.Actions_Pending[selected_lang]}</h2>
                    //                           <p>{labels.NoEvent_Create_Own[selected_lang]}</p>
                    //                         </div>
                    //                       </div>
                    //   )}
                    //                   </div>
                    <div className="notify-actions">
                      <Actions
                        threeDots={threeDots}
                        setShowComponent={setShowComponent}
                        setDataOfAction={setDataOfAction}
                        hitApi={hitApi}
                        showComponent={showComponent}
                        setActionTypeValue={setActionTypeValue}
                      />
                    </div>
                  )}
                </div>

                {showComponent === 'WorkSpace' && (
                  <CreateWorkSpace
                    onClose={() => {
                      setShowComponent('');
                    }}
                  />
                )}

                {searchFilter && tabType === 'actions' && (
                  <CustomModal
                    className="shareables-workspace-modal"
                    size="small"
                    onClose={() => setSearchFilterModal(false)}>
                    <div className="filter-wrapper">
                      <div className="filter-category-list">
                        <ul>
                          <li className={isCheckAll ? 'active' : ''}>
                            <Form.Label>
                              All
                              <input
                                type="checkbox"
                                id="All"
                                value="All"
                                onChange={() => {
                                  handleSelectAll();
                                }}
                                checked={isCheckAll}
                              />
                              <span />
                            </Form.Label>
                          </li>

                          {filterOption.map((item, index) => (
                            <li
                              key={`sessionC-${index}`}
                              className={
                                checkFilterOption.includes(item.id) ||
                                filterOptionValue.includes(item.title)
                                  ? 'active'
                                  : ''
                              }>
                              <Form.Label>
                                {item.title}
                                <input
                                  type="checkbox"
                                  id={item.id}
                                  value={item.title}
                                  onChange={e => {
                                    handleClickList(item.id, e.target.value);
                                  }}
                                  checked={
                                    checkFilterOption.includes(item.id) ||
                                    filterOptionValue.includes(item.title)
                                  }
                                />
                                <span />
                              </Form.Label>
                            </li>
                          ))}
                        </ul>
                      
                      </div>
                      <div className='text-center'>
                        <Button
                          type="submit"
                          className="btn-dark "
                          onClick={() => {
                            getActions();
                            setSearchFilterModal(false);
                          }}>
                          Select
                        </Button>
                        </div>
                    </div>
                  </CustomModal>
                )}
              </>
            }
            secondary={
              <>
                {showComponent === 'SnapShotDetail' && (
                  <SnapshotDetail
                    snapshotId={snapShotId.accomplishment_id}
                    userData={snapShotId.creator_detail}
                    onClose={() => {
                      setShowComponent('');
                    }}
                    showRefer={false}
                  />
                )}
                {showComponent === 'addAction' && (
                  <CreateAction
                    onClose={() => {
                      setShowComponent('');
                    }}
                    setHitApi={setHitApi}
                  />
                )}

                {showComponent === 'actionDetails' && (
                  <ActionDetail
                    dataOfAction={dataOfAction}
                    onClose={() => {
                      setShowComponent('');
                    }}
                    setHitApi={setHitApi}
                  />
                )}
              </>
            }
          />
          {showComponent === 'WorkSpace' && (
            <CreateWorkSpace
              onClose={() => {
                setShowComponent('');
              }}
            />
          )}

      </div>
    </div>
  );
};

export default SnapShotUsersList;
