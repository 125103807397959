import React, { useEffect, useRef, useState } from 'react';
import { CustomModal } from '../../Components';
import { Form } from 'react-bootstrap';
import { errorMessage } from '../../Utils/ToastMessages';
import { getBuilders } from '../../Utils/commonUtils';
import { useSelector } from 'react-redux';
import SuggestionsListing from './StaticPages/SuggestionsListing';

const Dialler = ({
    phoneNumber,
    setPhoneNumber,
    setSuggestions,
    suggestions,
    showModal,
    onClose
}) => {

  const [callModal, setCallModal] = useState(false);
  const inputRef = useRef(null);
  const allBuilderList = useSelector(state => state.AllBuilderList);
  const [inputWidth, setInputWidth] = useState(null);

  
    
      const handleCall = () => {
        // Add logic for making a call with the phone number
        if (localStorage.getItem('purchased_number_status') === 'active') {
          if (phoneNumber && phoneNumber !== '') {
            //   setShowModelPrimary('');
              setSuggestions([]);
              showModal()
            
          } else {
            errorMessage('Number is required');
          }
        } else {
          errorMessage("You didn't purchase any number");
        }
      };

      
  const handleSuggestionsClick = (item, cleanedPhoneNumber) => {
    setPhoneNumber(cleanedPhoneNumber);
    setSuggestions([]);
  };

  const handleChange = e => {
    const value = e.target.value;

      if (/^[\d+#*]{0,15}$/.test(value)) {
        setPhoneNumber(value);
        if (value) {
          const getBuiders = getBuilders(value, allBuilderList, 'callCenter');
          setSuggestions(getBuiders);
        } else {
          setSuggestions([]);
        }
      }
  };

    useEffect(() => {
      if (inputRef.current) {
        setInputWidth(inputRef.current.offsetWidth);
      }
    }, [inputRef.current]);

  return (
    <CustomModal 
    onClose={() => {
        // setShowFilter(false);
        onClose()
      }}
      size="small">
    <div
      className="call-center-page"
      style={{height: '300px'}}>
      <div className="messages-header mt-4">
        <div
          className="tabs-style-2"
          style={{margin: '0px', width: '100%'}}>
          <div
            className="phone-input"
            style={{
              justifyContent: 'space-between',
              width: '100%',
            }}>
            <div>
              <div
                className="search-tabs-wrapper"
                style={{margin: '0px'}}>
                <div className="search-form d-flex mt-0">
                  <Form.Group className="search-input-location">
                    <div className="form-control-with-icon phone_placeholder">
                      <Form.Control
                        ref={inputRef}
                        style={{
                          color:
                            phoneNumber !== ''
                              ? ''
                              : '#AEAEAE',
                          fontFamily: 'Inter',
                          fontStyle: 'normal',
                          fontWeight: '300',
                          lineHeight: 'normal',
                          fontSize: '15px',
                          background: 'white',
                          width: '275px',
                          borderRadius: '5px',
                          borderColor: '#eaeaea',
                          margin: '0px',
                        }}
                        value={phoneNumber}
                        onChange={handleChange}
                        maxLength={15}
                        type="text"
                        placeholder="Enter Phone Number"
                      />

                      {phoneNumber !== '' && (
                        <span
                          className="input-icon"
                          style={{left: '90%'}}
                          onClick={() => {
                            setPhoneNumber('');
                            setSuggestions([]);
                          }}>
                          <i className="fas fa-close" />
                        </span>
                      )}
                    </div>
                  </Form.Group>
                </div>
              </div>
            </div>

            <div>
              <div
                className="phone-call-icon cursor-pointer"
                onClick={() => {
                  handleCall();
                }}>
                <i
                  className={
                     `fa-solid fa-phone`
                  }
                  style={{
                    color: '#ffffff',
                    fontSize: '1.5em',
                  }}></i>
              </div>
            </div>
          </div>
        </div>
      </div>

      <SuggestionsListing
        listing={suggestions}
        type="callListing"
        handleSuggestions={handleSuggestionsClick}
        style={inputWidth}
      />
    </div>
  </CustomModal>
  )
}
export default Dialler;
