import React, {useState, useEffect} from 'react';
import {Button, Dropdown} from 'react-bootstrap';

import {errorMessage} from '../../Utils/ToastMessages';
import {labels} from '../../Constants/Translations';
import {selected_lang} from '../../Utils/env';
import {mobileScreen} from '../../Constants/Images';
import commonApi from '../../Services/CommonService';

const SliderComponent6 = props => {
  const TriggersType = [
    {id: 'email', name: 'Email'},
    {
      id: 'sms',
      name: 'Sms',
    },
  ];
  const [tabType, setTabType] = useState('team');
  const [type, setMenu] = useState('1');
  const [subMenu, setSubMenu] = useState('2');
  const [subMenuListing, setSubMenuListing] = useState([]);
  const [menuList, setMenuList] = useState([]);
  const [triggerId, setTriggerId] = useState('email');

  const menu_item = () => {
    commonApi
      .get_workspace_menu_item()
      .then(res => {
        setMenuList(res.menu_items);
      })
      .catch(err => {
        return {type: 'error', message: err.message};
      });
  };

  useEffect(() => {
    menu_item();
    // get_company_triggers();

    // get_sub_menus();
  }, [props.hitApi]);

  useEffect(() => {
    // menu_item();
    get_sub_menus();
    // eslint-disable-next-line
  }, [type]);

  // useEffect(() => {
  //   setAllTriggers();
  // }, [tabType, type, subMenu, triggerId]);

  const handleTriggers = () => {
    const idExists = props.triggers.some(
      trigger => trigger.sub_menu_id === subMenu,
    );

    const matchingSubMenu = subMenuListing.filter(
      trigger => trigger.id === subMenu,
    );

    if (matchingSubMenu.length > 0) {
      const matchingSubMenuName = matchingSubMenu[0].sub_menu_name; // Assuming "name" is the property you want to retrieve
      if (!idExists) {
        // If the id doesn't exist, add the new trigger to the array
        props.setTriggers(previous => [
          ...previous,
          {
            role_type: tabType,
            subMenu_name: matchingSubMenuName,
            menu_id: type,
            sub_menu_id: subMenu,
            template_id: '',
            trigger_type: triggerId,
          },
        ]);
      } else {
        // If the id already exists, you can handle it accordingly (e.g., show an error message)
        errorMessage('already added');
      }
    }
  };

  //   commonApi
  //     .get_company_triggers()
  //     .then(res => {
  //       if (res.status == 200) {
  //         setTriggerListing(res.data);
  //         props.setTriggers(res.data);
  //       }
  //     })
  //     .catch(err => {
  //       console.log('Err', err);
  //       return {type: 'error', message: err.message};
  //     });
  // };
  const get_sub_menus = () => {
    const payload = {menu_id: type};
    commonApi
      .get_sub_menus(payload)
      .then(res => {
        if (res.status === 200) {
          if (res.data.length > 0) {
            setSubMenuListing(res.data);
            setSubMenu(res.data[0].id);
          } else {
            setSubMenuListing([]);
            setSubMenu('');
          }
        }
      })
      .catch(err => {
        return {type: 'error', message: err.message};
      });
  };

  const handleDelete = item => {
    const updatedArray = props.triggers.filter(
      trigger => trigger.sub_menu_id !== item.sub_menu_id,
    );
    props.setTriggers(updatedArray);
    // successMessage('Trigger deleted succesfully ');

    
  };

  return (
    <>
      <div className="company-info-screen template_triggers_screen">
        {/* <div className="company-top-q">
          <h1 className="mb-2">Triggers</h1>
          <div className="mb-5">Triggers bring automation to your business</div>
        </div> */}

        <div className="slider3-container">
          <div className="mb-5">
            <h1 className="mb-2">What would you like to happen and when?</h1>

            <span>
              {' '}
              You have the ability to create triggers for every status change
              and state in ROI
            </span>
          </div>

          <div className="messages-header mb-2">
            <div className="event-tabs tabs-style-2 mb-2">
              {' '}
              <ul>
                <li>
                  <Button
                    className={tabType === 'team' ? 'active' : ''}
                    onClick={() => {
                      setTabType('team');
                    }}>
                    {labels.team[selected_lang]}
                  </Button>
                </li>
                <li>
                  <Button
                    className={tabType === 'customers' ? 'active' : ''}
                    onClick={() => {
                      setTabType('customers');
                    }}>
                    {labels.customers[selected_lang]}
                  </Button>
                </li>
                <li>
                  <Button
                    className={tabType === 'affliates' ? 'active' : ''}
                    onClick={() => {
                      setTabType('affliates');
                    }}>
                    {labels.affliates[selected_lang]}
                  </Button>
                </li>
              </ul>
            </div>
          </div>

          <div className="slider6-inner-container">
            <div className="slider2-inner-container customized-slider2-inner-container">
              <div className="menus-dropdowns">
                <Dropdown
                  className="select-wrapper"
                  onSelect={(eventKey, event) => {
                    setMenu(eventKey);
                   
                  }}
                  drop="down">
                  <Dropdown.Toggle variant="primary">
                    {type && menuList?.length > 0
                      ? menuList.find(item => item.id === type).menu_name
                      : 'Select Role'}
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    {menuList.map((item, index) => (
                      <Dropdown.Item
                        key={`sessionCategories-${index}`}
                        eventKey={item.id.toString()}
                        active={item.id === type}>
                        {item.menu_name}
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
              </div>

              <div className="menus-dropdowns">
                <Dropdown
                  className="select-wrapper"
                  onSelect={(eventKey, event) => {
                    setSubMenu(eventKey);
                  
                  }}
                  drop="down">
                  <Dropdown.Toggle variant="primary">
                    {subMenu && subMenuListing?.length > 0
                      ? subMenuListing.find(item => item.id === subMenu)
                          .sub_menu_name
                      : 'Select Role'}
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    {subMenuListing?.length > 0 &&
                      subMenuListing.map((item, index) => (
                        <Dropdown.Item
                          key={`sessionCategorie33-${index}`}
                          eventKey={item.id}
                          active={item.id === subMenu}>
                          {item.sub_menu_name}
                        </Dropdown.Item>
                      ))}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
              <div className="menus-dropdowns">
                <Dropdown
                  className="select-wrapper"
                  onSelect={(eventKey, event) => {
                    setTriggerId(eventKey);
                    
                  }}
                  drop="down">
                  <Dropdown.Toggle variant="primary">
                    {TriggersType
                      ? TriggersType.find(item => item.id === triggerId).name
                      : 'Select Role'}
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    {TriggersType.map((item, index) => (
                      <Dropdown.Item
                        key={`sessionCategories-${index}`}
                        eventKey={item.id}
                        active={item.id === triggerId}>
                        {item.name}
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
              <div className="triger_plus_main">
                <div
                  className="triger_plus"
                  onClick={() => {
                    handleTriggers();
                  }}>
                  <i className="fa-solid fa-plus"></i>
                </div>
              </div>
              <div className="">
                <span
                  className=" "
                  onClick={() => {
                    // handleMenus(menuType, type);
                  }}>
                  <div className="">
                    <img className="" src={mobileScreen} alt="" />
                  </div>
                </span>
              </div>
            </div>
          </div>
        </div>
        {props.triggers?.length > 0 &&
          props.triggers.map((item, index) => (
            <ul key={index}>
              <li>
                {/* {item.menu_name} */}
                {/* {menuList.find(item => item.id == type).menu_name} */}
                {item?.sub_menu_name}
                {item?.subMenu_name}
                <span
                  className="as-link"
                  onClick={() => {
                    // delete_company_trigger(item.id);
                    // handleTriggers(item.id)
                  }}>
                  <i
                    onClick={() => {
                      handleDelete(item);
                    }}
                    class="fa-solid fa-xmark"
                    style={{
                      marginTop: '10px',
                      color: '#d71616',
                      fontWeight: 'bold',
                      fontSize: '15px',
                      marginLeft: '20px',
                    }}></i>
                </span>{' '}
              </li>
            </ul>
          ))}
      </div>
    </>
  );
};

export default SliderComponent6;
