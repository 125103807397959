import React, {useState, useEffect, useRef} from 'react';
import {Button, Col, Form, Row} from 'react-bootstrap';
import {blockInvalidChar, WithBottomClass} from '../../Utils/commonUtils';
import Scrollbars from 'react-custom-scrollbars';
import TransparentButton from '../TransparentButton/TransparentButton';
import EditPencilSvg from '../../Svg/EditPencilSvg';
import commonApi from '../../Services/CommonService';
import {NoExpenseFound} from '../../Utils/NoRecordConstant';
import NorecordFound from '../NoRecord/NorecordFound';
import SlidingPanel from '../SlidingPanel';
import {RoiLogo} from '../../Constants/Images';

const InvoiceExpense = props => {
  const [searchQry, setSearchQry] = useState('');

  const [validated, setValidated] = useState(false);
  const [expense, setExpense] = useState({
    title: '',
    cost: '',
    description: '',
  });
  const [showComponent, setShowComponent] = useState('');
  const [data, setData] = useState({});
  const buttonRef = useRef(null);

  const get_expenses_for_invoices = value => {
    const payload = {search_query: value};
    commonApi
      .get_expenses(payload)
      .then(res => {
        if (res.status === 200) {
          props.setExpensesList(res.data);
        }
      })
      .catch(err => {
        
        return {type: 'error', message: err.message};
      });
  };

  // useEffect(() => {
  //   get_expenses_for_invoices('')
  // }, []);

  const renderHeader = () => {
    return (
      <>
        <img src={RoiLogo} alt="" />
      </>
    );
  };

  return (
    <>
      <SlidingPanel
        hidePanel={() => {
          props.onClose();
        }}
        marginTop={props.marginTop ? true : false}
        renderData={renderHeader}
        showBlackHeader={true}
        headerPadding={'24px 20px'}
        lastDivStyle={{borderRadius: '35px', background: '#f7f6f2'}}
        firstDivStyle={{
          padding: 0,
          background:
            'linear-gradient(to bottom, #424242, #424242, #f7f6f2 50%)',
        }}>
        <div className="serach-filter-wrapper create-sessionEvents pt-3">
          <div className="create-ticket-form  primary_panel_content">
            <Scrollbars>
              {/* <div className="roi-form-input fix-width-forms">
          <Form.Control
            type="text"
            pattern="^\S.*$"
            placeholder="Search expense"
            value={searchQry}
            onChange={e => {
              // get_expenses_for_invoices(e.target.value);
              setSearchQry(e.target.value);
            }}
          />
        </div> */}
              <div className="payment-search-wrapper fixed-width-inputs black-head-form-pad-left fix-width-forms  messages-users-wrapper mt-2 mb-4 ">
                <div className="search-form d-flex search-tabs-icons">
                  <Form.Group className="search-input roi-form-input">
                    <Form.Control
                      type="text"
                      placeholder="Search expense"
                      value={searchQry}
                      onChange={e => {
                        setSearchQry(e.target.value);
                        get_expenses_for_invoices(e.target.value);
                      }}
                    />
                  </Form.Group>
                  <span
                    className={`ml-0 mt-0 ${
                      props.showComponent === 'createExpense'
                        ? 'icon active'
                        : 'icon'
                    }`}>
                    <i
                      className="icon-plus"
                      onClick={() => {
                        props.setShowComponent('createExpense');
                      }}
                    />
                  </span>
                </div>
              </div>

              <div
                className="tag_listing fixed-width-inputs black-head-form-pad-left fix-width-forms"
                style={{paddingBottom: '50px'}}>
                {props.expensesList.length > 0 ? (
                  props.expensesList.map((item, index) => (
                    <div className="expense_card">
                      <div className=" f_700 mb-1">{item.title}</div>
                      <div
                        className={`d-flex align-items-center justify-content-between`}>
                        <div>${item.cost}</div>

                        <div className="d-flex align-items-center gap-2">
                          <span
                            className="as-link"
                            style={{color: '#52bd41'}}
                            onClick={() => {
                              props.setShowComponent('show_delete_model');
                              props.setExpenseItem(item);
                            }}>
                            <i className="fa-solid fa-trash-can" />
                          </span>
                          <span
                            className="as-link"
                            onClick={() => {
                              //   handleEditExpense(item)
                              props.setExpenseItem(item);
                              props.setShowComponent('createExpense');
                            }}>
                            <EditPencilSvg fill={'#52bd41'} />
                          </span>

                          {/* <span
                      className="poc_delete as-link"
                      onClick={() => {
                        // setDeletedId(item);
                        setData({
                          deleteItemIndex: index,
                          deleteModel: true,
                        });
                        // setShowComponent('show_delete_model');
                      }}>
                      <i className="fa-solid fa-trash-can" />
                    </span> */}
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <div style={{paddingTop: '20px'}}>
                    <NorecordFound
                      noRecordData={NoExpenseFound}
                      buttonPermesion={true}
                      ButtonAction={() => {
                        props.setShowComponent('createExpense');
                      }}
                    />
                  </div>
                )}
              </div>
            </Scrollbars>
          </div>
        </div>
      </SlidingPanel>
    </>
  );
};

export default InvoiceExpense;
