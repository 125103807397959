import React, {useState} from 'react';
import CustomModal from '../CustomModal';

import commonApi from '../../Services/CommonService';
import {errorMessage, successMessage} from '../../Utils/ToastMessages';
import {Button, Form, Row, Col, Dropdown} from 'react-bootstrap';

const TransformationCatgory = props => {
  const [AddNewCatgory, setAddNewCatgory] = useState('');

  const create_transformation_category = () => {
    const payload = {
      category_title: AddNewCatgory,
    };
    commonApi
      .create_transformation_category(payload)
      .then(res => {
        props.get_transformation_category();

        setAddNewCatgory('');
        successMessage(res.message);
        props.onClose();
      })
      .catch(err => {
        return {type: 'error', message: err.message};
      });
  };
  return (
    <CustomModal
      size="small"
      onClose={() => {
        props.onClose();
      }}
      className="availability-modal">
      <div
        className="new-session-modal-wrapper text-center  "
        style={{width: '-webkit-fill-available'}}>
        <div className="new-session-modal">
          <div className="fieldset">
            <div className="d-flex align-items-center justify-content-between">
              <Form.Label className="w-auto" controlid="validationCustom01">
                Add Category
              </Form.Label>
            </div>

            <Form.Control
              value={AddNewCatgory}
              type="text"
              placeholder="Add Category... "
              onChange={e => {
                console.log(e.target.value, 'Abcdefg');
                setAddNewCatgory(e.target.value);
              }}
            />
          </div>

          <div className="invite-btns  p-0 pt-3 text-center">
            <Button
              onClick={() => {
                if (AddNewCatgory === '') {
                  errorMessage('Please enter catgory.');
                } else {
                  create_transformation_category();
                }
              }}>
              Add Category
            </Button>
          </div>
        </div>
      </div>
    </CustomModal>
  );
};

export default TransformationCatgory;
