import React, {useState, useEffect} from 'react';
import LoaderSpinner from '../../Components/Modals/LoaderSpinner';
import {fixName} from '../../Utils/commonUtils';
import {DEFAULT_IMAGE} from '../../Utils/env';
import {checkPermissions} from '../../Utils/commonUtils.js';
import {useSelector} from 'react-redux';
import {Button, Dropdown} from 'react-bootstrap';
import {labels} from '../../Constants/Translations';
import {selected_lang} from '../../Utils/env';
import moment from 'moment';
import { FaAngleLeft, FaAngleRight } from "react-icons/fa6";


const TableListing = ({
  loader,
  listing,
  header,
  content,
  renderNoRecord
}) => {
  const [selectedValue, setSelectedValue] = useState(25);
  return (
    <>
      {loader && (
        <div className="invite_loader_hieght">
          <LoaderSpinner />
        </div>
      )}  
      {!loader && (
        <div className="p-4">
          <div
            className={
              listing?.length > 0
                ? 'rounded-lg border border-gray-300 scrollable-table overflow-hidden'
                : 'scrollable-table overflow-hidden'
            }>
            {listing.length > 0 ? (
              <div className="border rounded-md overflow-hidden ">
              <table className="bg-white w-full border-collapse">
              <thead style={{ borderBottom: "1px solid #515151" }}>
                  <tr className="bg-white shadow-md border border-gray-300 first:rounded-t-lg">
                    {header.map((col, index) => (
                      <th key={index} className="text-sm px-3 py-3 text-left">
                         <p
                        style={{ fontWeight: '600' }}
                        >
                        {col}
                        </p>
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {listing.map((item, index) => (
                    content(item, index)
                  ))}
                </tbody>
                <div className="flex justify-between items-center  p-2 border-t">
                  <p className="text-gray-800 text-base">Showing 1-25 of 28 items</p>
                  <div className="flex items-center gap-4">
                    <div className="roi-form-dropdown gap-2 ">
                      <div className="select-wrapper">
                        <Dropdown  className='w-[6rem]'
                          onSelect={(eventKey, event) => {
                            setSelectedValue(eventKey);
                          }}
                          drop="down">
                          <Dropdown.Toggle variant="primary">
                            {selectedValue || 'Select Value'}
                          </Dropdown.Toggle>

                          <Dropdown.Menu>
                            {[25, 50, 100].map((value, index) => (
                              <Dropdown.Item
                                key={`value-${index}`}
                                eventKey={value.toString()}
                                active={value === selectedValue}>
                                {value}
                              </Dropdown.Item>
                            ))}
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    </div>

                     <label className="text-base text-gray-800 whitespace-nowrap">per page</label> 
                    <span className="bg-[#dddddd] border rounded-sm p-1 text-gray-500"> <FaAngleLeft /></span>
                    <span><FaAngleRight/></span>
                  </div>
                </div>

              </table>
              </div>
            ) : (
             <>
             {renderNoRecord()}
             </>
            )}
          </div>
        </div>
      )}
      
    </>
  );
};

export default TableListing;
