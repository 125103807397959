
import commonApi from '../../Services/CommonService';

export const getDispatch = (type, data) => ({
    type: type,
    payload: data,
  });

export const getIncentives = data => {
    return async dispatch => {
      const response = await  commonApi
      .get_all_incentives(data);
      dispatch(getDispatch('get_incentves', response));
      return response;
    };
  };