import { setItem, removeItem } from "../../Utils/LocalStorage";
import { LOGIN, LOGOUT } from "../../Constants/Types";

// import { useHistory } from 'react-router-dom';

// import jwt_token from "jwt-decode";

import API from "../../Utils/API";

// LOGIN ACTION
export const loginDispatch = (user) => ({
    type: LOGIN,
    user,
});

export const requestOtp = (data) => (dispatch) =>
    API.requestOtp(data)
        .then((res) => res)
        .catch((err) => {
            return { type: "error", message: err.message };
        });

export const registerOtpRequest = (data) => (dispatch) => API.registerOtpRequest(data);

export const verifyOtp = (data) => (dispatch) =>
    API.verifyOtp(data)
        .then((res) => {
            // const history = useHistory();
            if (res.type === true && res.token) {
                const authToken = res.token;
                const userData = res.user;
                setItem("bf_auth_token", authToken);
                setItem("token", authToken);
               

                userData.authToken = authToken;

                dispatch(loginDispatch(userData));
            }
            // history.push('/sessions-events');
            return res;
        })
        .catch((err) => {
            return { type: "error", message: err.message };
        });

// LOGOUT
export const logoutDispatch = () => ({
    type: LOGOUT,
});

export const logout = () => (dispatch) => {
    removeItem("bf_auth_token");

    dispatch(logoutDispatch());
};
