import React, {useState, useEffect, useRef} from 'react';
import {Button, Dropdown, Form} from 'react-bootstrap';
import {
  blockInvalidChar,
  capitalizeFirst,
  fixName,
  WithBottomClass,
} from '../../Utils/commonUtils';
import Scrollbars from 'react-custom-scrollbars';
import TransparentButton from '../TransparentButton/TransparentButton';
import CustomDropdown from '../CustomDropdown';
import commonApi from '../../Services/CommonService';
import {errorMessage, successMessage} from '../../Utils/ToastMessages';
import EditPencilSvg from '../../Svg/EditPencilSvg';
import { getIncentives } from '../../Store/Actions/getIncentivesAction';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';

const CompensationIncentive = props => {
  const [validated, setValidated] = useState(false);
  const [incentives_typeId, setIncentives_typeId] = useState('points');
  const [milestoneDropdownId, setMilestoneDropdownId] = useState('');
  const [milestoneDropdownValues, setMilestoneDropdownValues] = useState([]);
  const [projects, setProjects] = useState([]);
  const [projectLists, setProjectLists] = useState({});
  const [searchQry, setSearchQry] = useState('');
  const [show, setShow] = useState(true);
  const [point_bonus, setPoint_bonus] = useState('');
  const [incentivesListing, setIncentivesListing] = useState([]);
  const [editIncentive, setEditIncentive] = useState({});
  const [showError, setShowError] = useState(false);
  const dispatch = useDispatch()
  const getIncentiveListing = useSelector(state => state.incentiveListing);

  const buttonRef = useRef(null);

  const handle = event => {
    
    const form = event.currentTarget;
 

    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      if(Object.keys(projectLists).length === 0){
        setShowError(true)
      }else{
      setValidated(true);
      setShowError(false)
      }
    } else {
      event.preventDefault();

      if(Object.keys(projectLists).length === 0){
        setShowError(true)
      }else{
        setValidated(false);
        setShowError(false)
        create_update_incentives();     
      } 
    }
  };

  const incentives_typeValues = [
    {
      id: 'points',
      name: 'Points',
    },
    {
      id: 'bonus',
      name: 'Bonus',
    },
  ];

  const get_projects_on_search = e => {
    const payload = {
      search_query: e ? e : searchQry,
    };
    commonApi
      .get_projects_on_search(payload)
      .then(res => {
        setProjects(res.projects);
      })
      .catch(err => {
        return {type: 'error', message: err.message};
      });
  };

  const create_update_incentives = e => {
    return false
    const payload = {
      incentives_id: editIncentive?.id ? editIncentive?.id : '',
      incentives_type: incentives_typeId,
      type: 'project',
      type_id: projectLists ? projectLists.id : '',
      point_bonus: point_bonus,
      milestone_id: milestoneDropdownId ? milestoneDropdownId : '',
    };
    commonApi
      .create_update_incentives(payload)
      .then(res => {
        // get_all_incentives();
        dispatch(getIncentives())
        reset();
        successMessage(res.message)
      })
      .catch(err => {
        return {type: 'error', message: err.message};
      });
  };

  const get_all_incentives = () => {
    commonApi
      .get_all_incentives()
      .then(res => {
        setIncentivesListing(res?.data);
        reset();
      })
      .catch(err => {
        return {type: 'error', message: err.message};
      });
  };

  const delete_incentives = ID => {
    const payload = {
      id: ID,
    };
    commonApi
      .delete_incentives(payload)
      .then(res => {
        handleIncentiveDelete(ID);
        successMessage(res.message)
      })
      .catch(err => {
        return {type: 'error', message: err.message};
      });
  };

  const handleProject = item => {
    setProjectLists(item);
    setSearchQry('');
    setProjects([]);
    if (item?.milestones?.length > 0) {
      setMilestoneDropdownValues(item?.milestones);
      setMilestoneDropdownId(item?.milestones[0].id);
    } else {
      setMilestoneDropdownValues([]);
    }
  };

  const handleIncentiveDelete = ID => {
    setIncentivesListing(previous =>
      previous.filter(incent => incent.id !== ID),
    );
  };

  const handleEditIncentive = item => {
    setPoint_bonus(item.point_bonus);
    setMilestoneDropdownId(item.milestone_id ? item.milestone_id : '');
    setIncentives_typeId(item?.incentives_type ? item?.incentives_type : '');
    setProjectLists(item.project_details);
    setMilestoneDropdownValues(item?.project_details?.milestones?.length > 0 ? item?.project_details?.milestones : [])
    setEditIncentive(item);
  };

  useEffect(() => {
    // get_all_incentives();
    dispatch(getIncentives())
  }, []);

  const reset = () => {
    setPoint_bonus('');
    setMilestoneDropdownId('');
    setMilestoneDropdownValues([]);
    setIncentives_typeId('points');
    setProjectLists({});
    setEditIncentive({});
    setShowError(false)
  };

  return (
    <>
      <div
        className="refferal-list-wrapper p-2"
        style={{
          backgroundColor: '#EDEBE5',
          borderRadius: '5px',
        }}>
        <div
          className="w-100 d-flex align-items-center justify-content-between fixed-width-inputs black-head-form-pad-left fix-width-forms mb_12 pt-3 "
          onClick={() => {
            setShow(!show);
          }}>
          <div className="d-flex">
            <p className="p-bold m-0 f_600_h">Incentives</p>
          </div>
        </div>
        {show && (
          <>
            <Form noValidate validated={validated} onSubmit={handle}>
              <div className=" fixed-width-inputs black-head-form-pad-left fix-width-forms">
                <div className="mb-3">
                  <CustomDropdown
                    // type={'credential'}
                    setDropdownId={setIncentives_typeId}
                    dropdownId={incentives_typeId}
                    DropDownValues={incentives_typeValues}
                    itemName={['name']}
                    className={
                      'recruitment_dropdown recruitement_dropdown_setInvites'
                    }
                    // handleDropdownValue={handleInvitesAssociation}
                    // disabled={props.id ? true : false}
                  />
                </div>

                <div className="fieldset">
                  <Form.Control
                    name="Offering"
                    value={searchQry}
                    pattern="^\S.*$"
                    placeholder="Search project"
                    onChange={e => {
                      get_projects_on_search(e.target.value);
                      setSearchQry(e.target.value);
                    }}
                  />
                  {Object.keys(projectLists).length === 0 && showError &&
                <span style={{
                  color: '#dc3545',
                  marginTop:'.25rem',
                  fontSize: '.875em',
                  fontWeight: 'normal'
                }}
                 className="errorMsg">Project is required.</span>
                  }
                </div>


                {searchQry && (
                  <ul className="custom-suggestions invoices_suggestion">
                    {projects?.length > 0 ? (
                      projects?.map(suggestion => (
                        <li
                          className="as-link"
                          key={'suggestion' + suggestion.id}
                          onClick={() => {
                            handleProject(suggestion);
                          }}>
                          {capitalizeFirst(suggestion.name)}
                        </li>
                      ))
                    ) : (
                      <>
                        <li className="as-link">No projects available.</li>
                      </>
                    )}
                  </ul>
                )}
                {milestoneDropdownValues?.length > 0 && (
                  <div className="roi-form-dropdown ">
                    <div className="select-wrapper">
                      <Dropdown
                        onSelect={(eventKey, event) => {
                          setMilestoneDropdownId(eventKey);
                        }}
                        drop="down">
                        <Dropdown.Toggle variant="primary">
                          {milestoneDropdownId
                            ? milestoneDropdownValues.find(
                                item => item.id === milestoneDropdownId,
                              )?.title
                            : 'Select Milestone'}
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          {milestoneDropdownValues.map((item, index) => (
                            <Dropdown.Item
                              key={`st-${index}`}
                              eventKey={item.id.toString()}
                              active={item.id === milestoneDropdownId}>
                              {item.title}
                            </Dropdown.Item>
                          ))}
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </div>
                )}

                <div className="mb-3">
                  {projectLists && Object.keys(projectLists).length > 0 && (
                    <div class="d-flex align-items-center phases_item mt-3">
                      <div className="customer_name flex-1">
                        <div className="alias_name">
                          {fixName(projectLists.name)}
                        </div>
                        <span
                          class="as-link  cross-icon-field"
                          onClick={() => {
                            setProjectLists({});
                            setMilestoneDropdownId('');
                            setMilestoneDropdownValues([]);
                          }}>
                          <i
                            class="fas fa-times text-secondary"
                            style={{fontSize: '1.5rem'}}></i>
                        </span>
                        {/* )} */}
                      </div>
                    </div>
                  )}
                </div>

                <div className="fieldset">
                  <Form.Control
                    type="number"
                    name="point_bonus"
                    value={point_bonus}
                    onKeyDown={blockInvalidChar}
                    required
                    placeholder="Points"
                    onChange={e => {
                      setPoint_bonus(e.target.value);
                    }}
                  />
                  <Form.Control.Feedback type="invalid">
                    Points are required.
                  </Form.Control.Feedback>
                </div>

                <div className="flex content-center justify-center">
                  <Button className="btn-dark" type="submit">
                    {editIncentive && Object.keys(editIncentive).length > 0
                      ? 'Update Incentive'
                      : 'Add Incentive'}
                  </Button>
                </div>
                {editIncentive && Object.keys(editIncentive).length > 0 && (
                  <div className="m-2 flex justify-center items-center mt-3">
                    <h2
                      className="mb-2 as-link"
                      onClick={() => {
                        reset();
                      }}>
                      Cancel
                    </h2>
                  </div>
                )}
              </div>
            </Form>
            {getIncentiveListing?.length > 0 &&
              getIncentiveListing.map((item, index) => (
                <div
                  key={index}
                  className="mt-2 flex justify-between items-center bg-white p-3 rounded-lg ">
                  <div className="flex items-center gap-2">
                    <div>
                      <div className="text-sm font-medium truncate_2_lines">
                        {item?.project_details?.name}
                      </div>
                      <div className="text-xs text-gray-500">
                        {item.point_bonus}
                      </div>
                    </div>
                  </div>

                  <div className="flex items-center gap-2">
                  <p
                      className="as-link"
                      onClick={() => {
                        handleEditIncentive(item);
                      }}>
                      <EditPencilSvg fill={'#52bd41'} h={15} w={15} />
                    </p>
                    <p className="as-link">
                      <span className="poc_delete as-link">
                        <i
                          class="fa-solid fa-trash-can"
                          onClick={() => {
                            props.handleBlock('', 'deleteConfirmation', item)
                            // delete_incentives(item.id);
                          }}
                        />
                      </span>
                    </p>
                    
                  </div>
                </div>
              ))}
          </>
        )}
      </div>
    </>
  );
};

export default CompensationIncentive;
