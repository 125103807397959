import React, {useState, useEffect, useRef} from 'react';
import commonApi from '../../Services/CommonService';
import {Button, Form} from 'react-bootstrap';
import EditPencilSvg from '../../Svg/EditPencilSvg';
import {useSelector, useDispatch} from 'react-redux';
import {getPipelineData} from '../../Store/Actions/ProfileAction';
import LoaderSpinner from '../Modals/LoaderSpinner';
import NorecordFound from '../NoRecord/NorecordFound';
import {PipelineNoRecordFound} from '../../Utils/NoRecordConstant';
import { getUpdateDataOfPipeline } from '../../Store/Actions/ProfileAction';

const ProfilePipeline = props => {
  // const [pipelineList, setPipelineList] = useState([]);
  const [pipelineItem, setPipelineItem] = useState({});
  const [showLoader, setshowLoader] = useState(true);
  const dispatch = useDispatch();
  // const [pipelinePermissions, setPipelinePermissions] = useState(props.userData.pipeline_permission);

  const pipelineList = useSelector(state => state.pipelineList);
  const updatePipelineData = useSelector(state => state.updatePipelineData);
  const getPipelines = value => {

    const res = dispatch(getPipelineData({user_id: props.userData?.data?.id}));
    
    if (res) {
      setTimeout(() => {
        setshowLoader(false);
      }, 500);
    }
    // commonApi
    //   .get_pipeline_details()
    //   .then(res => {
    //     if (res.status === 200) {
    //       setPipelineList(res.data);
    //     }
    //   })
    //   .catch(err => {
    //     console.log('Err', err);
    //     return {type: 'error', message: err.message};
    //   });
  };

  useEffect(() => {
    getPipelines();
  }, []);

  useEffect(() => {
    if (Object.keys(pipelineItem).length > 0 && updatePipelineData) {
      pipelineList.forEach(pipeline => {
        if (pipeline.id === pipelineItem.id) {
          setPipelineItem(pipeline);
          props.handleCommand(pipeline, 'pipeLineDetail');
        }
      });
    }
  }, [pipelineList]);

  return (
    <div className="refferal-list-wrapper mt-4">
      {showLoader && (
        <div className="loader_hieght">{showLoader && <LoaderSpinner />}</div>
      )}

      {Object.entries(pipelineList).length > 0 && !showLoader
        ? pipelineList.map((item, index) => (
            <div
              key={`pipeline_${index}`}
              className="refferals-list action-listing as-link"
              style={{border: '1px solid #f1eded'}}
              onClick={() => {
                props.handleCommand(item, 'pipeLineDetail');
                setPipelineItem(item);
              }}>
              <div className="task-item">
                <div className="d-flex justify-content-between">
                  <h2 className="truncate_2_lines actions-title mb-2">
                    {item.title}
                  </h2>

                  {/* <span className='as-link' onClick={()=>{

              }}>
              <EditPencilSvg
                                                    fill={'#a39f8d'}
                                                  />
              </span> */}
                </div>

                <p className="p-reg p-reg-truncate m-0 mb-2">{item.description}</p>
                <div
                  className="d-flex align-items-center justify-content-between"
                  style={{alignItems: 'center'}}>
                  <div className="d-flex align-items-center">
                    <p className="p-reg m-0 actionsCategories single-line-text">
                      Phases: {item.phases.map(phase => phase.phase).join(', ')}
                    </p>
                  </div>

                  <div className="arrow-up"></div>
                </div>
              </div>
            </div>
          ))
        : !showLoader && (
            <NorecordFound
              noRecordData={PipelineNoRecordFound}
              buttonPermesion={
                props.userData.pipeline_permission.create_pipeline
                  ? true
                  : false
              }
              ButtonAction={() => {
                props.handleCommand('', 'pipeline');
              }}
            />

            // <div className="no-results-wrapper payment-no-results no-record-notification">
            //   {/* <div className="img-bg-wrapper">
            //                     <img
            //                       src={require('../../Assets/images/norecord/no-actions.png')}
            //                       alt=""
            //                     />
            //                   </div> */}

            //   <div className="no-results-content">
            //     <p>No PipeLines found</p>
            //     <div className="btn-wrapper mt-3">
            //       <Button
            //         className="btn btn-dark"
            //         onClick={() => {
            //           props.handleCommand('', 'pipeline');
            //         }}>
            //         Create Pipeline
            //       </Button>
            //     </div>
            //   </div>
            // </div>
          )}
    </div>
  );
};

export default ProfilePipeline;
