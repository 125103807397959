import React, {useState, useEffect} from 'react';
import {Button} from 'react-bootstrap';
import {labels} from '../../Constants/Translations';
import {selected_lang} from '../../Utils/env';
import {useDispatch, useSelector} from 'react-redux';
import {
  getUserCompanyRecruitment,
} from '../../Store/Actions/OpportunityAction';
import { useHistory} from 'react-router-dom';
import {getItem} from '../../Utils/LocalStorage';
import CustomModal from '../CustomModal';
import {callDeepLink} from '../../Store/Actions/DeepLinkAction';
import {parseUrl} from '../../Utils/commonUtils';
import moment from 'moment';
import {DEFAULT_IMAGE} from '../../Utils/env';
import Slider from 'react-slick';
import LoaderSpinner from '../Modals/LoaderSpinner';
import commonApi from '../../Services/CommonService';

const RenderOpportunities = ({user,setShowModal,setDataofRecruitment}) => {
  // const [sessionType, setGalleryType] = useState('available_opportunities');
  // const [searchQry, setSearchQry] = useState('');
  // const [userOpportunity, setOpportunityData] = useState([]);
  // const [searchFilter, setSearchFilterModal] = useState(false);
  // const [isCheck, setIsCheck] = useState([]);
  const [showLogin, setShowLogin] = useState(null);
  // const [hasRendered, setHasRendered] = useState(false);
  const [showLoader, setshowLoader] = useState(false);
  const [dataItem, setDataItem] = useState({});

  // const location = useLocation();
  // const searchParams = new URLSearchParams(location.search);
  // const id = searchParams.get('type');

  // const {type} = useParams();
  const history = useHistory();
  const urlString = String(window.location);
  const {pathname} = window.location;
  const urlParts = pathname.split('/').filter(Boolean);
  // const searchParams = new URLSearchParams(search);
  // const id = searchParams.get('type');

  const urlData = parseUrl(urlString);

  const dispatch = useDispatch();
  // const userOpportunity = useSelector(state => state.userOpportunityListing);
  const recruitmentData = useSelector(state => state.userCompanyRecruitment);

  const eventSlider = {
    // className: "center",
    // centerMode: true,
    dots: true,
    arrows: false,
    infinite: false,
    // centerPadding : "60px",
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    // adaptiveHeight: true,
  };

  // const getOpportunityData = (
  //   type = 'available_opportunities',
  //   qry = '',
  //   filter = [],
  //   page = 1,
  // ) => {
  //   const data = {
  //     user_id: user.id,
  //     opportunity_types: filter,
  //     search_query: qry,
  //     status: type,
  //     page: page,
  //     login_id: getItem('id') ? getItem('id') : '',
  //   };

  //   dispatch(getUserOpportunityList(data));
  // };

  // const hitWhenFilter = () => {
  //   // getOpportunityData(sessionType, searchQry, isCheck, 1);
  //   if (hasRendered) {
  //     getOpportunityData(sessionType, searchQry, isCheck, 1);
  //   } else {
  //     setHasRendered(true);
  //   }
  // };

  const AddUserInCompany = (url,item )=> {
    const payload = {
      company_name: urlParts[0] ? urlParts[0].replace('-', ' ') : '',
      user_id: localStorage.getItem('id'),
    };
    commonApi
      .add_user_in_company(payload)
      .then(res => {
        // history.push(url);
        history.push(url, { typeIs: 'recruitment', opprtuntity_item:item });
      })
      .catch(err => {
       
        return {type: 'error', message: err.message};
      });
  };
  // const makeItem = item => {
  //   return {
  //     id: item.id,
  //     title: item.title,
  //     category: item.opportunity_type_name,
  //     description: item.description,
  //     address: item.address,
  //     investment: item.amount,
  //     likes: 0,
  //     isLiked: item.like,
  //   };
  // };

  // useEffect(() => {
  //   // getOpportunityData();
  //   if (searchFilter) {
  //   } else {
  //     // if (urlParts[1] === 'opportunity' && id) {
  //     // } else {
  //     hitWhenFilter();
  //     // }
  //   }
  // }, [searchFilter]);

  useEffect(() => {
    if (recruitmentData.length === 0) {
      setshowLoader(true);
    }

    setTimeout(() => {
      setshowLoader(false);
    }, 1500);
    // if (urlParts[1] === 'opportunity') {
    //   if (id) {
    //     setIsCheck([id]);
    //     getOpportunityData('available_opportunities', '', [id], 1);
    //     // history.replace({pathname: '/qrlinks'});
    //   }
    // } else {
    //   getOpportunityData('available_opportunities', '', [], 1);
    // }

    // getUserCompanyRecruitmentData(urlData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch(callDeepLink());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showLogin]);

  // useEffect(() => {
  // setshowLoader(false)

  // }, [recruitmentData])

  const getUserCompanyRecruitmentData = urldata => {
    // setGalleryType(sesType);

    // const data = {
    //   company_name: urldata.companyName
    //     ? urldata.companyName.replace('-', ' ')
    //     : '',
    //   user_id: urldata.userId ? urldata.userId : '',
    //   search_query: '',
    //   opportunity_type_id: urldata.type === 'recruitment' ? urldata.typeId : '',
    // };

    const data = {
      company_name: urlParts[0] ? urlParts[0].replace('-', ' ') : '',
      user_id:  urlParts[0] && !urlParts[1] ? '' : urlData.userId,
      search_query: '',
      opportunity_type_id: urldata.type === 'recruitment' ? urldata.typeId : '',
    };

    dispatch(getUserCompanyRecruitment(data));

    // dispatch(getCompanyMeetUpList(data));
  };

  return (
    <div className="sessions-widget-wrapper refferal-list-wrapper">
      <div className="profile-meetups-tab profile_page_meetup_recruitement">
        {showLoader && <LoaderSpinner />}
        {recruitmentData && recruitmentData.length > 0 ? (
          <>
            <div className=" mt-4">
              <Slider {...eventSlider}>
                {recruitmentData &&
                  recruitmentData.length > 0 &&
                  recruitmentData.slice(0,7).map((item, index) => (
                    <div>
                      <div
                        className="event-card as-link"
                        onClick={() => {
                          if (getItem('id')) {
                            // history.push(
                            //   '/opportunities/opportunity_invite/' + item.id,
                            // );
                            const searchParams = new URLSearchParams();
                            searchParams.append('id', item.id);

                            const url = 
                            urlParts[1] ? `/${
                              urlParts[0] + '/' + urlParts[1]
                            }/recruitment/invite?${searchParams.toString()}`
                            : `/${
                              urlParts[0] + '/' + urlParts[1]
                            }/recruitment/invite?${searchParams.toString()}`;
                         
                          
                          AddUserInCompany(url,item)
                            
                            // history.push(
                            //   '/' +
                            //     pathname[0] +
                            //     '/opportunity/invite?id=' +
                            //     item.id,
                            // );
                          } else {
                            setDataItem(item)
                            // successMessage('Please login first');
                            // history.push('/login/opportunity_invite/' + item.id);
                            setShowLogin(item.id);
                          }
                        }}>
                        {moment(
                          item.start_time,
                          'YYYY-MM-DD HH:mm:ss',
                          true,
                        ).isValid() && (
                          <div className="event-date">
                            {' '}
                            {moment(item.start_time).format(
                              'ddd, MMM D @ h:mm A',
                            )}
                          </div>
                        )}

                        <h1> {item.title}</h1>
                        <div className="event-slider-image ">
                          <img
                            src={
                              item.image?.slider_images
                                ? item.image.slider_images
                                : DEFAULT_IMAGE
                            }
                            alt=""
                          />
                        </div>

                        {/* <div className="event-type">
                          {' '}
                          <img
                            className="d-inline mr-1"
                            src={profileIcon}
                            alt=""
                          />
                          165 Networking and Referrals
                        </div> */}

                        <div className="event-location">
                          {item.address && (
                            <span>
                              <i
                                style={{color: '#9B9787', marginRight: '5px'}}
                                className="fas fa-location-dot"></i>
                              {item.address}
                            </span>
                          )}

                          {item.price && <span>${item.price}</span>}
                        </div>

                        {/* <div className="card-button">
                          <Button
                            className="new-btn btn-green"
                            onClick={() => {}}>
                            Purchase Ticket
                          </Button>
                        </div> */}
                      </div>
                    </div>
                  ))}
              </Slider>
            </div>
            {recruitmentData.length >=  7  &&
            <div className="show-more-btn"  onClick={()=>{
                       setShowModal('recruitmentList');
                       setDataofRecruitment(recruitmentData)
                      }}>show more</div>}
          </>
        ) : (
          <div className="profile_page_meetup_recruitement d-flex align-items-center justify-content-center ">
            {labels.no_record_found[selected_lang]}
          </div>
        )}
      </div>

      {/* <div className="card">
        <div className="search-tabs-wrapper">
          <div className="d-flex justify-content-between align-items-center mb-2">
            <div className="event-tabs tabs-style-2 mb-0">
              <ul>
                <li>
                  <Button
                    className={mainTab === 'Opportunities' ? 'active' : ''}
                    
                  >
                    {labels.gallery_type_opportunities[selected_lang]}
                  </Button>
                </li>
               
              </ul>
            </div>

            <div className="search-tabs-icons">
              <span
                onClick={() => {
                  if (sessionType === 'available_opportunities') {
                    setGalleryType('pending_opportunities');
                    setSearchQry('');
                    // getOpportunityData('pending_opportunities', '', !typeIs, 1);
                    getOpportunityData('pending_opportunities', '', isCheck);
                  } else {
                    setGalleryType('available_opportunities');
                    setSearchQry('');
                    // getOpportunityData( 'available_opportunities', '', !typeIs, 1);
                    getOpportunityData('available_opportunities', '', isCheck);
                  }
                }}
                className={
                  sessionType === 'pending_opportunities'
                    ? 'icon active'
                    : 'icon'
                }>
                <i className="icon-plane"></i>
              </span>

              <span
                onClick={() => {
                  setThreeDots(!threeDots);
                }}
                className={threeDots ? 'icon active' : 'icon'}>
                <i className="fas fa-ellipsis-vertical" />
              </span>
            </div>
          </div>

          {threeDots && (
            <div className="search-form d-flex">
              <Form.Group className="search-input">
                <Form.Control
                  onChange={e => {
                    getOpportunityData(sessionType, e.target.value, isCheck);
                    setSearchQry(e.target.value);
                  }}
                  value={searchQry}
                  type="text"
                  placeholder={'Search for an opportunity'}
                />
              </Form.Group>
              <Button>
                <i
                  className="icon-filter-icon"
                  onClick={() => setSearchFilterModal(true)}
                />
              </Button>
            </div>
          )}
        </div>

        <Scrollbars className="sessions-widget-scroll">
          <div className="opportunities-list-wrapper">
            <div style={{padding: '7px 10px'}}>
              {Object.entries(userOpportunity).length > 0
                ? userOpportunity.map((item, index) => (
                    <div
                      className="as-link"
                      key={`opportu-em-${index}`}
                      onClick={() => {
                        if (getItem('id')) {
                          // history.push(
                          //   '/opportunities/opportunity_invite/' + item.id,
                          // );
                          const searchParams = new URLSearchParams();
                          searchParams.append('id', item.id);

                          const url = `/${
                            urlParts[0]
                          }/opportunity/invite?${searchParams.toString()}`;

                        
                          history.push(url);
                          // history.push(
                          //   '/' +
                          //     pathname[0] +
                          //     '/opportunity/invite?id=' +
                          //     item.id,
                          // );
                        } else {
                          // successMessage('Please login first');
                          // history.push('/login/opportunity_invite/' + item.id);
                          setShowLogin(item.id);
                        }
                      }}>
                      <OpportunityItem
                        key={`opportunity-item-${index}`}
                        item={item}
                        hideRefer={true}
                      />
                    </div>
                  ))
                : labels.no_record_found[selected_lang]}
            </div>
          </div>
        </Scrollbars>

        {searchFilter && (
          <OpportunitySearchFilter
            show={searchFilter}
            onClose={() => setSearchFilterModal(false)}
            isCheckAll={isCheckAll}
            setIsCheckAll={setIsCheckAll}
            isCheck={isCheck}
            setIsCheck={setIsCheck}
          />
        )}
        {showLogin && (
          <CustomModal
            // position="bottom"
            size="small"
            onClose={() => setShowLogin(null)}>
            <div className="leave-session-content">
              <h3>Login</h3>
              <p>For more information please login.</p>

              <div className="text-center">
                <Button
                  className="btn-dark"
                  onClick={() => {
                    const searchParams = new URLSearchParams();
                    searchParams.append('id', showLogin);
                    const url = `/${
                      urlParts[0]
                    }/opportunity/invite?${searchParams.toString()}`;
                    history.push(url);
                    // history.push('/login/opportunity_invite/' + showLogin);
                    setShowLogin(null);
                  }}>
                  Login
                </Button>
                <Button
                  className="btn-dark"
                  onClick={() => {
                    setShowLogin(null);
                  }}>
                  No
                </Button>
              </div>
            </div>
          </CustomModal>
        )}
      </div> */}

      {showLogin && (
        <CustomModal
          // position="bottom"
          size="small"
          onClose={() => setShowLogin(null)}>
          <div className="leave-session-content">
            <h3>Login</h3>
            <p>For more information please login.</p>

            <div className="text-center">
              <Button
                className="btn-dark"
                onClick={() => {
                  const searchParams = new URLSearchParams();
                  searchParams.append('id', showLogin);
                  const url = `/${
                    urlParts[0] + '/' + urlParts[1]
                  }/recruitment/invite?${searchParams.toString()}`;
                  // history.push(url);
                  AddUserInCompany(url,dataItem)
                  // history.push('/login/opportunity_invite/' + showLogin);
                  setShowLogin(null);
                }}>
                Login
              </Button>
              <Button
                className="btn-dark"
                onClick={() => {
                  setShowLogin(null);
                }}>
                No
              </Button>
            </div>
          </div>
        </CustomModal>
      )}
    </div>
  );
};

export default RenderOpportunities;
