import React, {useState, useEffect} from 'react';
import {Button, Col, Container, Form, Row} from 'react-bootstrap';
import commonApi from '../../Services/CommonService';
import img from '../../Assets/images/norecord/no-circles.png';
// import { NoCircles } from '../../Constants/Images';
import {fixName, formatHoursMinutesTime} from '../../Utils/commonUtils';
// import {DEFAULT_IMAGE} from '../../Utils/env';
import LoaderSpinner from '../Modals/LoaderSpinner';

import {labels} from '../../Constants/Translations';
import {selected_lang, DEFAULT_IMAGE} from '../../Utils/env';
import {errorMessage, successMessage} from '../../Utils/ToastMessages';
import API from '../../Services/SessionServices';
import {getItem} from '../../Utils/LocalStorage';

const Invite = props => {
  const [followers, setFollowers] = useState([]);
  const [orginalFollowers, setOrginalFollowers] = useState([]);
  const [showSlectedUser, setShowSlectedUser] = useState('');
  const [searchQry, setSearchQry] = useState('');

  const [initialRender, setInitialRender] = useState(true);
  // const [moderator, setModerator] = useState([]);

  // const [selectedModData, setSelectedModData] = useState([]);

  const [showLoader, setshowLoader] = useState(false);

  useEffect(() => {
    if (props.component === 'invoice'|| props.component === 'project') {
      if (props.pipeline && props.moderator.length > 0) {
        getCompanyfolowersForSelectedUsers('');
      } else if(props.moderator.length > 0) {
        getfolowersForSelectedUsers('');
      }
    }

    // if(props.component !=='invoice'){
    // props.setshowLoader(true);
    // }

    // if(props.component ==='invoice'){
    //   setshowLoader(true);
    // }

    // setSearchQry('');
    if (props.invoice) {
      list_of_catalogs_ps();
    } else {
      setTimeout(() => {
        if (props.pipeline && props.showLoader) {
          getCompanyRolesfolowers('');
        } else {
          if(props.showLoader){
            getfolowers('');
          }
          
        }
      }, 1000);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.forApiHit]);

  // useEffect(() => {
  //   if(props.component ==='invoice' && initialRender ){

  //     setshowLoader(true)
  //   }

  //   setInitialRender(false)

  // }, [props.forApiHit,props.hitApiAddedUser])

  // const UpdateForState  =(miniItem)=>{
  //
  //   setSelectedModData([...selectedModData, miniItem]);
  // }

  const getfolowersForSelectedUsers = qry => {
    var payLoad = {
      search_query: qry,
      company_id: props.id ? props.id : ''
    };
    commonApi
      .excluded_followers_list(payLoad)
      .then(res => {
        if (res.status === 200) {
          setOrginalFollowers(res.data);

          if ((props.component === 'invoice' || props.component === 'project') && props.moderator.length > 0) {
            props.setSelectedModData(
              res.data.filter(user => props.moderator.includes(user.id)),
            );
            props.setOriginalDataOfMod(
              res.data.filter(user => props.moderator.includes(user.id)),
            );
          }else{
            props.setSelectedModData(
              []
            );
            props.setOriginalDataOfMod(
             []
            );
          }
        }
      })
      .catch(err => {
       
        return {type: 'error', message: err.message};
      });
  };

  const getCompanyfolowersForSelectedUsers = qry => {
    var payLoad = {
      pipeline_id: props.pipeline ? props.pieplineId : '',
      search: qry,
    };
    commonApi
      .get_company_roles_user(payLoad)
      .then(res => {
        if (res.status === 200) {
          setOrginalFollowers(res.data);

          if (props.component === 'invoice' && props.moderator.length > 0) {
            props.setSelectedModData(
              res.data.filter(user => props.moderator.includes(user.id)),
            );
            props.setOriginalDataOfMod(
              res.data.filter(user => props.moderator.includes(user.id)),
            );
          }
        }
      })
      .catch(err => {
     
        return {type: 'error', message: err.message};
      });
  };

  const getfolowers = qry => {
    // alert('@@@@@')
    if (props.type === 'sessionDetail') {
      let payLoad = {id: getItem('conf_id'), search_query: qry};
      API.users_not_in_conference(payLoad)
        .then(res => {
          props.setshowLoader(false);
          // if (res.status === 200) {
          props.setFollowers(res.data);

          // }
        })
        .catch(err => {
          
          return {type: 'error', message: err.message};
        });
    } else {
      var payLoad = {
        search_query: qry,
        remove_company_creator: props.removeCompnayCreator
          ? props.removeCompnayCreator
          : '',
        excluded_role_id: props.pipeline ? '1' : '',
              company_id: props.id ? props.id : ''
      };
      commonApi
        .excluded_followers_list(payLoad)
        .then(res => {
          

          if (res.status === 200) {
            if(props.showLoader){
              
              props.setFollowersList(res.data)
            }
            if (props.circleFollowers) {
              props.setCircleFollowers(res.data);
            }
            props.setFollowers(res.data);
            props.setOriginalFolowers?.(res.data);
            props.setshowLoader(false);
            if (
              props.component === 'businessProfile' &&
              props.moderator.length > 0
            ) {
              props.setSelectedModData(
                res.data.filter(user => props.moderator.includes(user.id)),
              );
              props.setOriginalDataOfMod(
                res.data.filter(user => props.moderator.includes(user.id)),
              );
            }

            if (props.contact?.length > 0) {
              const filterData = res.data.filter(
                item =>
                  item.exist_in_build === 'no' &&
                  props.contact.some(
                    dataItem =>
                      dataItem.fname === item.first_name &&
                      dataItem.contact === item.phone,
                  ),
              );

              if (filterData.length > 0) {
                filterData.forEach(miniItem => {
                  props.setModerator(prev => [...prev, miniItem.id]);

                  props.setSelectedModData(previous => [...previous, miniItem]);
                  props.setOriginalDataOfMod(previous => [
                    ...previous,
                    miniItem,
                  ]);

                  // setSelectedModData(updatedData)
                });
              }
            }
            if (props.setContact) {
              props.setContact([]);
            }
          }
        })
        .catch(err => {
          
          return {type: 'error', message: err.message};
        });
    }
  };

  const getCompanyRolesfolowers = qry => {
    // alert('@@@@@')

    var payLoad = {
      search: qry,
      pipeline_id: props.pipeline ? props.pieplineId : '',
    };
    commonApi
      .get_company_roles_user(payLoad)
      .then(res => {
        props.setshowLoader(false);

        if (res.status === 200) {
         
          if(props.showLoader){
            props.setFollowersList(res.data)
          }
          props.setFollowers(res.data);
          props.setOriginalFolowers?.(res.data);

          if (
            props.component === 'businessProfile' &&
            props.moderator.length > 0
          ) {
            props.setSelectedModData(
              res.data.filter(user => props.moderator.includes(user.id)),
            );
            props.setOriginalDataOfMod(
              res.data.filter(user => props.moderator.includes(user.id)),
            );
          }

          if (props.contact?.length > 0) {
            const filterData = res.data.filter(
              item =>
                item.exist_in_build === 'no' &&
                props.contact.some(
                  dataItem =>
                    dataItem.fname === item.first_name &&
                    dataItem.contact === item.phone,
                ),
            );

            if (filterData.length > 0) {
              filterData.forEach(miniItem => {
                props.setModerator(prev => [...prev, miniItem.id]);

                props.setSelectedModData(previous => [...previous, miniItem]);
                props.setOriginalDataOfMod(previous => [...previous, miniItem]);

                // setSelectedModData(updatedData)
              });
            }
          }
          if (props.setContact) {
            props.setContact([]);
          }
        }
      })
      .catch(err => {
       
        return {type: 'error', message: err.message};
      });
  };
  const list_of_catalogs_ps = qry => {
    // alert('@@@@@')

    var payLoad = {
      search_query: qry,
    };
    commonApi
      .list_of_catalogs_ps(payLoad)
      .then(res => {
        props.setshowLoader(false);
        if (res.status === 200) {
          setFollowers(res.catalog_data);
          props.setCircleFollowers(res.catalog_data);
        }
      })
      .catch(err => {
       
        return {type: 'error', message: err.message};
      });
  };

  // const getUserList = qry => {
  //   let payLoad = {id: getItem('conf_id'), search_query: qry};
  //   API.users_not_in_conference(payLoad)
  //     .then(res => {
  //       setshowLoader(false);
  //       // if (res.status === 200) {
  //       setFolowers(res.data);
  //       setNewFolowers(
  //         res.data.filter(
  //           item => !audience.includes(item.id) && !modrator.includes(item.id),
  //         ),
  //       );
  //       // }
  //     })
  //     .catch(err => {
  //       console.log('Err', err);
  //       return {type: 'error', message: err.message};
  //     });
  // };

  const AddUsersInSession = () => {
    let data = {
      conference_id: getItem('conf_id'),
      moderators: props.moderator,
      audience: props.audience,
    };
    commonApi
      .add_all_user_in_conference_dolby(data)
      .then(response => {
        if (response) {
          successMessage(response.message);

          //   GetUserNotInSession('', '');
        }
      })
      .catch(err => {
       
        return {type: 'error', message: err.message};
      });
  };

  const ModData = mod => {
    if (!props.moderator.includes(mod.id) && (props.availabletickets ? props.moderator.length < props.availabletickets.length : true)) {
      if (props.pipeline || props.estimate) {
        props.setModerator([mod.id]);
        props.setSelectedModData([mod]);
        props.setOriginalDataOfMod([mod]);
      } else {
        props.setModerator(previous => [...previous, mod.id]);
        // setModerator(previous => [...previous, mod.id]);
        props.setSelectedModData(previous => [...previous, mod]);
        props.setOriginalDataOfMod(previous => [...previous, mod]);
      }
    } else if (
     ( props.alreadyAddedMod &&
      props.alreadyAddedMod.includes(mod.id)) || (props.component ==='sendTicket' && props.moderator.length === props.availabletickets.length && !props.moderator.includes(mod.id))
    ) {
      if(props.component ==='sendTicket'){
        errorMessage('Not enough tickets');
      }else{
        errorMessage('This user is  already associated');
      }
      
    } else {
      props.setModerator(previous => previous.filter(id => id !== mod.id));

      // setModerator(previous => previous.filter(id => id !== mod.id));
      props.setSelectedModData(previous =>
        previous.filter(item => item.id !== mod.id),
      );
      props.setOriginalDataOfMod(previous =>
        previous.filter(item => item.id !== mod.id),
      );
    }

    //
  };
  const AudData = item => {
    if (!props.audience.includes(item.id)) {
      props.setAudience(previous => [...previous, item.id]);
      props.setSelectedAudData(previous => [...previous, item]);
      props.setOriginalDataOfAud(previous => [...previous, item]);
    } else if (
      props.alreadyAddedAud &&
      props.alreadyAddedAud.includes(item.id)
    ) {
      errorMessage('This user is  already associated');
    } else {
      props.setAudience(previous => previous.filter(id => id !== item.id));
      props.setSelectedAudData(previous =>
        previous.filter(miniitem => miniitem.id !== item.id),
      );
      props.setOriginalDataOfAud(previous =>
        previous.filter(miniitem => miniitem.id !== item.id),
      );
    }
  };

  useEffect(() => {
    if (props.audience?.length === 0) {
      setShowSlectedUser('');
    }
  }, [props.audience]);

  useEffect(() => {
    if (props.moderator.length === 0) {
      setShowSlectedUser('');
    }
  }, [props.moderator]);

  const handleSearchQry = q => {
    if (props.invoice) {
      list_of_catalogs_ps(q);
    } else {
      if (props.pipeline) {
        getCompanyRolesfolowers(q);
      } else {
        getfolowers(q);
      }
    }
  };

  const handleSelectedSearchQryForAud = (query, array) => {
    if (!query && props.originalDataOfAud.length > 0) {
      props.setSelectedAudData(props.originalDataOfAud); // Reset to original data when query is empty
      return;
    }
    props.setSelectedAudData(
      array.filter(el => {
        // const fullName = el.first_name.toLowerCase() + el.last_name.toLowerCase();
        const fullNameSpace =
          el.first_name.toLowerCase() + ' ' + el.last_name.toLowerCase();
        return (
          el.first_name.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
          el.last_name.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
          fullNameSpace.toLowerCase().indexOf(query.toLowerCase()) !== -1
        );
      }),
    );
  };
  const handleSelectedSearchQryForMod = (query, array) => {
    if (!query && props.originalDataOfMod.length > 0) {
      props.setSelectedModData(props.originalDataOfMod);

      return;
    }

   
    props.setSelectedModData(
      array.filter(el => {
        // const fullName = el.first_name.toLowerCase() + el.last_name.toLowerCase();
        const fullNameSpace =
          el.first_name.toLowerCase() + ' ' + el.last_name.toLowerCase();
        return (
          el.first_name.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
          el.last_name.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
          fullNameSpace.toLowerCase().indexOf(query.toLowerCase()) !== -1
        );
      }),
    );
  };
  // useEffect(() => {
  //   if(props.searchQry !=='' && props.followersList.length > 0 ){
  //     props.setFollowers(props.followersList)
  //   }
  // }, [props.subTabs])
  

  //

  // console.log(props.followersList,'props.followersListprops.followersList')

  return (
    <>
    
      
      { !props.showLoader && (<div className="ScheduleScreen_Style ScheduleScreenInvite_Style">
        {props.type !== 'refer' && props.type !== 'snapshot' && (
          <div className="event-tabs tabs-style-2 mb-0">
            <ul>
              <li>
                <Button
                  className={
                    props.subTabs === 'moderator' ||
                    showSlectedUser === 'selected-moderator'
                      ? 'active'
                      : ''
                  }
                  onClick={() => {
                    if(props.searchQry !==''){
                      getfolowers('')
                      // props.setFollowers(props.followersList)
                    }
                    setShowSlectedUser('');
                    props.setSearchQry('');
                    props.setSubTabs('moderator');
                    
                    
                  }}>
                  Moderators
                </Button>
              </li>
              <li>
                <Button
                  className={
                    props.subTabs === 'audience' ||
                    showSlectedUser === 'selected-audience'
                      ? 'active'
                      : ''
                  }
                  onClick={() => {
                    if(props.searchQry !==''){
                      // props.setFollowers(props.followersList)
                      getfolowers('')
                    }
                    setShowSlectedUser('');
                    props.setSearchQry('');
                    props.setSubTabs('audience');
                    
                  }}>
                  Audience
                </Button>
              </li>
            </ul>
          </div>
        )}

        {props.component === 'invoice' && !props.hide && (
          <div className="d-flex">
            <p className="p-bold f_600_h">
              {props.pipeline ? 'Select Customer' : 'Select Users'}
            </p>
          </div>
        )}

        <div className="invite-page-search " style={{padding: 0}}>
          {props.tab === 'workspace' && (
            <Form.Label>What would you like analytics on?</Form.Label>
          )}
          <div className="search-form">
            <Form.Group className="search-input">
              <Form.Control
                onChange={e => {
                  props.setSearchQry(e.target.value);
                  // getAllUsers(e.target.value);

                  if (showSlectedUser === 'selected-moderator') {
                    handleSelectedSearchQryForMod(
                      e.target.value,
                      props.selectedModData,
                    );
                  } else if (showSlectedUser === 'selected-audience') {
                    handleSelectedSearchQryForAud(
                      e.target.value,
                      props.selectedAudData,
                    );
                  } else {
                    handleSearchQry(e.target.value);
                  }
                }}
                value={props.searchQry}
                type="text"
                placeholder={
                  props.invoice ? 'Search for catalog' : 'Search from your network'
                }
              />
            </Form.Group>
          </div>
        </div>

        {(props.component === 'groupCatalog' && props.invoice &&  followers && followers.length > 0) &&  (
          <div className="d-flex mt-2">
            <p className="p-bold f_600_h">Select Catalogs</p>
          </div>
        )}

        {props.component === 'groupCatalog' && props.invoice && (
          <div className="ld_list_card mt-2 mb-4 ">
            <ul>
              {Object.entries(props.followers).length > 0 ? (
                Object.entries(props.followers).length > 0 &&
                props.followers.map(
                  (item, index) =>
                    // !moderator.includes(item.id) &&
                    //messagesCase
                    (!props.opprAudience ||
                      !props.opprAudience.includes(item.id)) &&
                    (!props.mustAudience ||
                      !props.mustAudience.includes(item.id)) &&
                    (!props.opportunityLeftAudience ||
                      !props.opportunityLeftAudience.includes(item.id)) &&
                    (!props.hideCreator ||
                      !props.hideCreator.includes(item.id)) &&
                    //Snapshot case
                    (!props.pendingUsersIds ||
                      !props.pendingUsersIds.includes(item.id)) &&
                    (!props.partners || !props.partners.includes(item.id)) &&
                    (!props.members || !props.members.includes(item.id)) &&
                    (!props.leadership ||
                      !props.leadership.includes(item.id)) &&
                    //otherSingleCases
                    !props.audience?.includes(item.id) && (
                      <div
                        className={`deal_item as-link ${
                          props.moderator.includes(item.id)
                            ? 'selercted-user'
                            : ''
                        }`}
                        key={index}
                        onClick={() => {
                          // AddCataloginDeal(item);
                          ModData(item);
                        }}>
                        <div className="f_600_h text_overflow_container">
                          {item.title}
                        </div>

                        <p className='deal_item_desc'>{item.description ? item.description : ''}</p>
                        {/* <FormattedText
              className={`profile-description ${showMoreClass}`}
              style={'margin'}
              content={item.description ? item.description : ''}
            />
            {(item.description?.length > 250 ||
              linesOfCatalog?.length > 3) && (
              <>
                {' '}
                <span
                  className="show_more-btn"
                  onClick={() => setShowMore(!showMore)}>
                  {!showMore
                    ? labels.show_more[selected_lang]
                    : labels.show_less[selected_lang]}
                </span>
              </>
            )} */}

                        <div className="d-flex justify-content-between">
                          <span className="deal_time">
                            {item.duration
                              ? formatHoursMinutesTime(item.duration)
                              : ''}
                          </span>
                          <span className="deal_cost f_600_h">
                          {item.cost
                                                ? `$${item.cost}`
                                                : 'Free'}
                          </span>
                        </div>
                      </div>
                    ),
                )
              ) : props.hideCircle || props.noRecord || props.invoice ? (
                <Col md={12} xl={12} className="text-center">
                  {props.hideCircle &&
                    !props.invoice &&
                    labels.no_record_found[selected_lang]}
                  {props.noRecord && labels.no_user_found[selected_lang]}
                  {props.invoice && 'No catalog found'}
                </Col>
              ) : (
                <div className="no-results-wrapper no-appointments my-5">
                  <div className="img-bg-wrapper">
                    <img src={img} alt="" />
                  </div>
                  <div className="no-results-content">
                    <p className="noRecord-title">
                      {props.searchQry
                        ? `  We didn't find the ${props.searchQry} match for the name`
                        : `  We didn't find  any match `}
                    </p>

                    <div className="btn-wrapper">
                      <Button
                        className="btn btn-dark"
                        onClick={() => {
                          props.onClick();
                        }}>
                        {props.searchQry ? 'Add Contact' : 'Add Contacts'}
                      </Button>
                    </div>
                  </div>
                </div>
              )}
            </ul>
          </div>
        )}

        {((props.subTabs &&
          props.subTabs === 'moderator' &&
          showSlectedUser === '') ||
          (props.type === 'refer' && showSlectedUser === '') ||
          (props.type === 'snapshot' && showSlectedUser === '')) && !props.invoice && (
            <div className="invite-persons-wrapper invite-persons-list-holder px-0">
              <div className="invite-persons-list px-0">
                <Container className="p-0">
                  <Row>
                    <div className="invite-persons-heading">
                      {!props.invoice && (
                        <span
                          className={
                            props.moderator.length === 0 &&
                            props.subTabs &&
                            props.subTabs === 'moderator' &&
                            showSlectedUser === ''
                              ? 'for-grey f_400 p-bold'
                              : props.moderator.length > 0 && showSlectedUser === '' ? 'for-grey  as-link p-bold' :  props.moderator.length > 0 &&
                                showSlectedUser === 'selected-moderator'
                              ? 'builder-text as-link p-bold'
                              : ` for-grey p-bold ${
                                  props.moderator.length === 0 ? '' : 'as-link'
                                } `
                          }
                          onClick={() => {
                            if (props.moderator.length > 0) {
                              setShowSlectedUser('selected-moderator');
                              props.setSearchQry('');
                              handleSelectedSearchQryForMod('', '');
                            }
                          }}>
                          {props.moderator.length === 0
                            ? 'Select from your network'
                            : props.moderator.length + ' Contacts Selected'}
                        </span>
                      )}
                      {!props.hideCircle && !props.hideCircleIcon && (
                        <span
                          className="circle-link-icon"
                          onClick={() => {
                            props.handleCircle();
                            // setTimeout(() => {
                            //   setInitialRender(true)
                            // }, 500);
                          }}>
                          {/* Circles */}
                          <i className="icon-circles" />
                        </span>
                      )}
                    </div>
                    {Object.entries(props.followers).length > 0 ? (
                      Object.entries(props.followers).length > 0 &&
                      props.followers.map(
                        (item, index) =>
                          // !moderator.includes(item.id) &&
                          //messagesCase
                          (!props.opprAudience ||
                            !props.opprAudience.includes(item.id)) &&
                          (!props.mustAudience ||
                            !props.mustAudience.includes(item.id)) &&
                          (!props.opportunityLeftAudience ||
                            !props.opportunityLeftAudience.includes(item.id)) &&
                          (!props.hideCreator ||
                            !props.hideCreator.includes(item.id)) &&
                          //Snapshot case
                          (!props.pendingUsersIds ||
                            !props.pendingUsersIds.includes(item.id)) &&
                          (!props.partners ||
                            !props.partners.includes(item.id)) &&
                          (!props.members ||
                            !props.members.includes(item.id)) &&
                          (!props.leadership ||
                            !props.leadership.includes(item.id)) &&
                          //otherSingleCases
                          !props.audience?.includes(item.id) && (
                            <Col key={`f-item-${index}`} md={4} xl={3}>
                              <div
                                className="stage-list-item "
                                key={`folowers-${index}`}>
                                <div
                                  // className="stage-person-img as-link"
                                  className={
                                    item?.linked_user_detail?.user_type ===
                                    'business'
                                      ? 'stage-person-img business as-link'
                                      : 'stage-person-img as-link'
                                  }
                                  type="checkbox"
                                  onClick={() => {
                                    if (props.type === 'snapshot') {
                                      if (
                                        props.category === '1' &&
                                        !props.moderator.includes(item.id)
                                      ) {
                                        props.setclearAllModal(true);
                                        props.setCurrentInviteUser(item);
                                      } else if (
                                        props.category === '1' &&
                                        props.tab
                                      ) {
                                        props.tab === 'Partners'
                                          ? props.handleSlectedUser(
                                              item,
                                              'Partner',
                                            )
                                          : props.handleSlectedUser(
                                              item,
                                              'Member',
                                            );
                                      } else {
                                        props.handleSlectedUser(
                                          item,
                                          'LeaderShip',
                                        );
                                      }
                                    } else {
                                      ModData(item);
                                    }
                                  }}>
                                  <img
                                    className={
                                      props.moderator.includes(item.id)
                                        ? 'selercted-user'
                                        : ''
                                    }
                                    src={
                                      props.invoice
                                        ? item.image
                                        : item.profile_img === ''
                                        ? DEFAULT_IMAGE
                                        : item.profile_img
                                    }
                                    alt=""
                                    id={item.id}
                                  />
                                </div>
                                <div
                                  className="stage-person-name as-link"
                                  onClick={() => {
                                    if (props.type === 'snapshot') {
                                      if (
                                        props.category === '1' &&
                                        !props.moderator.includes(item.id)
                                      ) {
                                        props.setclearAllModal(true);
                                        props.setCurrentInviteUser(item);
                                      } else {
                                        props.handleSlectedUser(
                                          item,
                                          'LeaderShip',
                                        );
                                      }
                                    } else {
                                      ModData(item);
                                    }
                                  }}>
                                  <h3>
                                    {props.invoice
                                      ? item.title
                                      : fixName(
                                          item.first_name,
                                          item.last_name,
                                        )}
                                  </h3>
                                </div>
                              </div>
                            </Col>
                          ),
                      )
                    ) : props.hideCircle || props.noRecord || props.invoice ? (
                      <Col md={12} xl={12} className="text-center">
                        {props.hideCircle &&
                          !props.invoice &&
                          labels.no_record_found[selected_lang]}
                        {props.noRecord && labels.no_user_found[selected_lang]}
                        {props.invoice && 'No catalog found'}
                      </Col>
                    ) : !props.showLoader && (
                      <div className="no-results-wrapper no-appointments my-5">
                        <div className="img-bg-wrapper">
                          <img src={img} alt="" />
                        </div>
                        <div className="no-results-content">
                          <p className="noRecord-title">
                            {props.searchQry
                              ? `  We didn't find the ${props.searchQry} match for the name`
                              : `  We didn't find  any match `}
                          </p>

                          <div className="btn-wrapper">
                            <Button
                              className="btn btn-dark"
                              onClick={() => {
                                props.onClick();
                              }}>
                              {props.searchQry ? 'Add Contact' : 'Add Contacts'}
                            </Button>
                          </div>
                        </div>
                      </div>
                    )}
                  </Row>
                </Container>
              </div>
            </div>
          )}

        {props.subTabs &&
          props.subTabs === 'audience' &&
          showSlectedUser === '' && (
            <div className="invite-persons-wrapper px-0">
              <div className="invite-persons-list px-0">
                <Container className="p-0">
                  {/* {props.showLoader && <LoaderSpinner />} */}
                  <Row>
                    <div className="invite-persons-heading">
                      <span
                        className={
                          props.audience.length === 0 &&
                          props.subTabs === 'audience' &&
                          showSlectedUser === ''
                            ? 'for-grey f_400 p-bold'
                            : props.audience.length > 0 && showSlectedUser === '' ? 'for-grey  as-link p-bold'
                            : props.audience.length > 0 &&
                              showSlectedUser === 'selected-audience'
                            ? 'builder-text as-link p-bold'
                            : ' for-grey  as-link p-bold'
                        }
                        onClick={() => {
                          if (props.audience.length > 0) {
                            setShowSlectedUser('selected-audience');
                            props.setSearchQry('');
                            handleSelectedSearchQryForAud('', '');
                          }
                        }}>
                        {props.audience.length === 0
                          ? 'Select from your network'
                          : props.audience.length + ' Contacts Selected'}
                      </span>
                      <span
                        className="circle-link-icon"
                        onClick={props.handleCircle}>
                        {/* Circles  */}
                        <i className="icon-circles" />
                      </span>
                    </div>
                    {Object.entries(props.followers).length > 0 ? (
                      Object.entries(props.followers).length > 0 &&
                      props.followers.map(
                        (item, index) =>
                          (!props.opportunityLeftAudience ||
                            !props.opportunityLeftAudience.includes(item.id)) &&
                          (!props.hideCreator ||
                            !props.hideCreator.includes(item.id)) &&
                          (!props.mustModerators ||
                            !props.mustModerators.includes(item.id)) &&
                          !props.moderator.includes(item.id) && (
                            <Col key={`f-again-${index}`} md={4} xl={3}>
                              <div
                                className="stage-list-item"
                                key={`folow-${index}`}>
                                <div
                                  className={
                                    item?.linked_user_detail?.user_type ===
                                    'business'
                                      ? 'stage-person-img business as-link'
                                      : 'stage-person-img as-link'
                                  }
                                  onClick={() => {
                                    AudData(item);
                                  }}>
                                  <img
                                    className={
                                      props.audience.includes(item.id)
                                        ? 'selercted-user'
                                        : ''
                                    }
                                    src={
                                      item.profile_img === ''
                                        ? DEFAULT_IMAGE
                                        : item.profile_img
                                    }
                                    alt=""
                                    id={item.id}
                                  />
                                </div>
                                <div
                                  className="stage-person-name as-link"
                                  onClick={() => {
                                    AudData(item);
                                  }}
                                  id={item.id}
                                  value={item.id}>
                                  <h3>
                                    {fixName(item.first_name, item.last_name)}
                                  </h3>
                                </div>
                              </div>
                            </Col>
                          ),
                      )
                    ) : (
                      <div className="no-results-wrapper no-appointments my-5">
                        <div className="img-bg-wrapper">
                          <img src={img} alt="" />
                        </div>
                        <div className="no-results-content">
                          <p className="noRecord-title">
                            {props.searchQry
                              ? `  We didn't find the ${props.searchQry} match for the name`
                              : `  We didn't find  any match `}
                          </p>
                          {/* <p className="noRecord-desc">
        Quickly access your colleagues to
        invite, refer and connect
      </p> */}

                          <div className="btn-wrapper">
                            <Button
                              className="btn btn-dark"
                              onClick={() => {
                                props.onClick();
                              }}>
                              {props.searchQry ? 'Add Contact' : 'Add Contacts'}
                            </Button>
                          </div>
                        </div>
                      </div>
                    )}
                  </Row>
                </Container>
              </div>
            </div>
          )}

        {showSlectedUser === 'selected-moderator' && (
          <div className="invite-persons-wrapper">
            <div className="invite-persons-list px-0">
              <Container className="p-0">
                {/* {props.showLoader && <LoaderSpinner />} */}
                <Row>
                  <div className="invite-persons-heading">
                    <span
                      className={
                        props.moderator.length > 0
                          ? 'builder-text as-link p-bold'
                          : 'builder-text p-bold'
                      }
                      onClick={() => {
                        setShowSlectedUser('');
                        props.setSearchQry('');
                        props.setFollowers(props.followersList)
                        // getfolowers('');
                      }}>
                      {props.moderator.length === 0
                        ? ''
                        : props.moderator.length + ' Contacts Selected'}
                    </span>
                    {!props.hideCircle && !props.hideCircleIcon && (
                      <span
                        className="circle-link-icon"
                        onClick={props.handleCircle}>
                        {/* Circles */}
                        <i className="icon-circles" />
                      </span>
                    )}
                  </div>
                  {Object.entries(props.selectedModData).length > 0 ? (
                    Object.entries(props.selectedModData).length > 0 &&
                    props.selectedModData.map(
                      (item, index) =>
                        // !moderator.includes(item.id) &&
                        (!props.opprAudience ||
                          !props.opprAudience.includes(item.id)) &&
                        (!props.mustAudience ||
                          !props.mustAudience.includes(item.id)) &&
                        (!props.opportunityLeftAudience ||
                          !props.opportunityLeftAudience.includes(item.id)) &&
                        (!props.hideCreator ||
                          !props.hideCreator.includes(item.id)) &&
                        !props.audience?.includes(item.id) && (
                          <Col key={`f-item-${index}`} md={4} xl={3}>
                            <div
                              className="stage-list-item "
                              key={`folowers-${index}`}>
                              <div
                                className={
                                  item?.linked_user_detail?.user_type ===
                                  'business'
                                    ? 'stage-person-img business as-link'
                                    : 'stage-person-img as-link'
                                }
                                type="checkbox"
                                onClick={() => {
                                  ModData(item);
                                }}>
                                <img
                                  className={
                                    props.moderator.includes(item.id)
                                      ? 'selercted-user'
                                      : ''
                                  }
                                  src={
                                    item.profile_img === ''
                                      ? DEFAULT_IMAGE
                                      : item.profile_img
                                  }
                                  alt=""
                                  id={item.id}
                                />
                              </div>
                              <div
                                className="stage-person-name as-link"
                                onClick={() => {
                                  ModData(item);
                                }}>
                                <h3>
                                  {fixName(item.first_name, item.last_name)}
                                </h3>
                              </div>
                            </div>
                          </Col>
                        ),
                    )
                  ) : props.hideCircle ? (
                    <Col md={12} xl={12} className="text-center">
                      {labels.no_record_found[selected_lang]}
                    </Col>
                  ) : (
                    <div className="no-results-wrapper no-appointments my-5">
                      <div className="img-bg-wrapper">
                        <img src={img} alt="" />
                      </div>
                      <div className="no-results-content">
                        <p className="noRecord-title">
                          {props.searchQry
                            ? `  We didn't find the ${props.searchQry} match for the name`
                            : `  We didn't find  any match `}
                        </p>
                        {/* <p className="noRecord-desc">
          Quickly access your colleagues to
          invite, refer and connect
        </p> */}

                        <div className="btn-wrapper">
                          <Button
                            className="btn btn-dark"
                            onClick={() => {
                              props.onClick();
                            }}>
                            {props.searchQry ? 'Add Contact' : 'Add Contacts'}
                          </Button>
                        </div>
                      </div>
                    </div>
                  )}
                </Row>
              </Container>
            </div>
          </div>
        )}

        {showSlectedUser === 'selected-audience' && (
          <div className="invite-persons-wrapper">
            <div className="invite-persons-list px-0">
              <Container className="p-0">
                {/* {props.showLoader && <LoaderSpinner />} */}
                <Row>
                  <div className="invite-persons-heading">
                    <span
                      className={
                        props.audience.length > 0
                          ? 'builder-text as-link p-bold'
                          : 'builder-text p-bold'
                      }
                      onClick={() => {
                        setShowSlectedUser('');
                        props.setSearchQry('');
                        props.setFollowers(props.followersList)
                        // getfolowers('');
                      }}>
                      {props.audience.length === 0
                        ? ''
                        : props.audience.length + ' Contacts Selected'}
                    </span>
                    <span
                      className="circle-link-icon"
                      onClick={props.handleCircle}>
                      {/* Circles */}
                      <i className="icon-circles" />
                    </span>
                  </div>
                  {Object.entries(props.selectedAudData).length > 0 ? (
                    Object.entries(props.selectedAudData).length > 0 &&
                    props.selectedAudData.map(
                      (item, index) =>
                        (!props.opportunityLeftAudience ||
                          !props.opportunityLeftAudience.includes(item.id)) &&
                        (!props.hideCreator ||
                          !props.hideCreator.includes(item.id)) &&
                        (!props.mustModerators ||
                          !props.mustModerators.includes(item.id)) &&
                        !props.moderator.includes(item.id) && (
                          // !audience.includes(item.id) &&
                          <Col key={`f-again-${index}`} md={4} xl={3}>
                            <div
                              className="stage-list-item"
                              key={`folow-${index}`}>
                              <div
                                className={
                                  item?.linked_user_detail?.user_type ===
                                  'business'
                                    ? 'stage-person-img business as-link'
                                    : 'stage-person-img as-link'
                                }
                                onClick={() => {
                                  AudData(item);
                                }}>
                                <img
                                  className={
                                    props.audience.includes(item.id)
                                      ? 'selercted-user'
                                      : ''
                                  }
                                  src={
                                    item.profile_img === ''
                                      ? DEFAULT_IMAGE
                                      : item.profile_img
                                  }
                                  alt=""
                                  id={item.id}
                                />
                              </div>
                              <div
                                className="stage-person-name as-link"
                                onClick={() => {
                                  AudData(item);
                                }}
                                id={item.id}
                                value={item.id}>
                                <h3>
                                  {fixName(item.first_name, item.last_name)}
                                </h3>
                              </div>
                            </div>
                          </Col>
                        ),
                    )
                  ) : (
                    <div className="no-results-wrapper no-appointments my-5">
                      <div className="img-bg-wrapper">
                        <img src={img} alt="" />
                      </div>
                      <div className="no-results-content">
                        <p className="noRecord-title">
                          {props.searchQry
                            ? `  We didn't find the ${props.searchQry} match for the name`
                            : `  We didn't find the  match `}
                        </p>
                        {/* <p className="noRecord-desc">
        Quickly access your colleagues to
        invite, refer and connect
      </p> */}

                        <div className="btn-wrapper">
                          <Button
                            className="btn btn-dark"
                            onClick={() => {
                              props.onClick();
                            }}>
                            {props.searchQry ? 'Add Contact' : 'Add Contacts'}
                          </Button>
                        </div>
                      </div>
                    </div>
                  )}
                </Row>
              </Container>
            </div>
          </div>
        )}

        {!props.showLoader &&
          props.type === 'sessionDetail' &&
          props.followers.length > 0 && (
            <div className="filter-btn-wrapper pt-2">
              <div className="invite-btn text-center">
                <Button
                  disabled={
                    props.showLoader ||
                    !(props.audience.length > 0 || props.moderator.length > 0)
                      ? true
                      : false
                  }
                  className="btn-dark"
                  type="button"
                  onClick={() => {
                    props.onClose();
                    AddUsersInSession();
                  }}>
                  Send Invite
                </Button>
              </div>
            </div>
          )}

        {props.component === 'businessProfile' && props.followers.length > 0 && (
          <div className="filter-btn-wrapper pt-2">
            <div className="invite-btn text-center">
              <Button
                className="btn-dark"
                type="button"
                onClick={() => {
                  props.updateCompanyDetails();
                  props.onClose();
                }}>
                Select
              </Button>
            </div>
          </div>
        )}
        {/* {props.component === 'invoice' && followers.length > 0 && (
          <div className="filter-btn-wrapper pt-2">
            <div className="invite-btn text-center">
              <Button
                className="btn-dark"
                type="button"
                onClick={() => {

                  props.onClose();
                }}>
                Select
              </Button>
            </div>
          </div>
        )} */}
      </div>)
}
    </>
  );
};

export default Invite;
