import React, {useState, useEffect, useRef} from 'react';
import {Form, Button} from 'react-bootstrap';

import Sidebar from '../../Pages/Sidebar';
import {CustomModal, CustomSlidingButtons, Pannels} from '../../Components';
import {
  waveform,
  gridRound,
  rewardStar,
  timeIcon,
} from '../../Constants/Images';

import Moment from 'react-moment';

import DiscoverBuilders from '../../Components/SessionList/DiscoverBuilders';
import Scrollbars from 'react-custom-scrollbars';
import CreateWorkSpace from '../../Components/WorkSpace/CreateWorkSpace';
import Slider from 'react-slick';
import SendInvitation from './StaticPages/SendInvitation';
import ProfileComponent from '../../Components/ProfilePage/ProfileComponent';
import AllBuilders from './StaticPages/AllBuilders';
import CallScreenModal from '../../Components/Modals/CallScreenModal';
import commonApi from '../../Services/CommonService';
import CallsInformation from './StaticPages/CallsInformation';
import {errorMessage, successMessage} from '../../Utils/ToastMessages';
import CallRecordings from '../../Components/CallCenter/CallRecordings';
import SuggestionsListing from './StaticPages/SuggestionsListing';
import {useSelector} from 'react-redux';
import CallContacts from '../../Components/Contacts/CallContacts';
import ConfirmationModel from '../../Components/ConfirmationModel/ConfirmationModel';
import {chatroomInfo, getBuilders} from '../../Utils/commonUtils';
import TwillioCallCenterSms from '../../Components/CallCenter/TwillioCallCenterSms';
import CallMessages from '../../Components/Messages/CallMessages';
import {getAllBuilderList} from '../../Store/Actions/SessionAction';
import {getItem} from '../../Utils/LocalStorage';
import {useDispatch} from 'react-redux';
import TwillioMessges from './TwillioMessges';
import CreateGroup from '../../Components/Messages/CreateGroup';
import SmartChatPage from '../../Components/Common/SmartChatPage';
import SvgIconComponent from '../../Constants/SvgIconComponent';
import RecentFilterModel from '../../Components/CallCenter/RecentFilterModel';
import InvitePoc from '../../Components/SingleComponent/InvitePoc';
import AddPoc from '../../Components/SingleComponent/AddPoc';
const CallCenter = props => {
  const [threeDots, setThreeDots] = useState(false);
  const [tabType, setTabType] = useState('recent');
  const [showModel, setShowModel] = useState(false);
  const [showComponentHistory, setShowComponentHistory] = useState('All');
  const [messages, setMessages] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [scrollLock, setScrollLock] = useState(false);
  const [phoneDetail, setPhoneDetail] = useState({});

  const [isFloated, toggleFloatedPanel] = useState(false);
  const [showHiddenPanel, toggleHiddenPanel] = useState(false);

  const [showBuildersComponent, setShowBuildersComponent] = useState('');
  const [userChat, setUserChat] = useState({});
  const [chatDetails, setChatDetails] = useState([]);
  const [chatRoomId, setChatRoomId] = useState('');
  const [chatDetailItem, setChatDetailItem] = useState([]);
  const [chatPropType, setChatPropType] = useState('');
  const [allChatUsers, setAllChatUsers] = useState([]);
  const [chatRoomName, setChatRoomName] = useState('');
  const [workspaceData, setWorkspaceData] = useState([]);
  const [convosData, setConvosData] = useState([]);
  const [groupChat, setGroupChat] = useState('');
  const [allDataOfItemSession, setAllDataOfItem] = useState([]);
  const [subTabType, setSubTabType] = useState('Convos');
  const [searchQry, setSearchQry] = useState('');
  const [modelFilter, setModelFilter] = useState('all');
  const [smsFilterOptions, setSmsFilterOptions] = useState('all');

  const [phoneNumber, setPhoneNumber] = useState('');
  const [analycticsSlider, setAnalycticsSlider] = useState(false);
  const [buildersUserId, setBuildersUserId] = useState('');
  const [refresh, setRefresh] = useState([]);
  const [callModal, setCallModal] = useState(false);
  const [callModalRecent, setCallModalRecent] = useState(false);
  const [callHistoryList, setCallHistoryList] = useState([]);
  const [suggestions, setSuggestions] = useState([]);
  const [authToken_Twillio, setAuthToken_Twillio] = useState('');
  const [checkUserHitApi, setCheckUserHitApi] = useState(false);
  const allBuilderList = useSelector(state => state.AllBuilderList);
  const [filterType, setFilterType] = useState('All');
  const dispatch = useDispatch();
  const [callRecordingList, setCallRecordingList] = useState([]);
  const [showSpinner, setShowSpinner] = useState(true);
  const [showComponent, setShowComponent] = useState('History');
  const [showModelPrimary, setShowModelPrimary] = useState('');
  const [contactListing, setContactListing] = useState(allBuilderList);
  const scrollbarRef = useRef();

  const handleSearchQry = value => {
    const getBuiders = getBuilders(value, allBuilderList, 'messages');
    setContactListing(getBuiders);
  };

  useEffect(() => {
    if (scrollbarRef.current) {
      scrollbarRef.current.scrollToTop();
    }
  }, [showComponent]);

  useEffect(() => {
    if (showComponent === 'History') {
      setContactListing(allBuilderList);
    }
  }, [tabType, showComponent]);

  const filterOptions = [
    {id: 'all', title: 'All'},
    {id: 'incoming', title: 'Incoming'},
    {id: 'outgoing', title: 'Outgoing'},
  ];

  const smsFiltersOption = [
    {id: 'all', title: 'ALL'},
    {id: 'customers', title: 'Customer'},
    {id: 'team', title: 'Team'},
  ];

  const handleButtonClick = digit => {
    setPhoneNumber(prevPhoneNumber => prevPhoneNumber + digit);
  };

  const handleDelete = () => {
    setPhoneNumber(prevPhoneNumber => prevPhoneNumber.slice(0, -1));
  };
  const TabList = [
    // {id: 'Dialler', title: 'Dialler'},
    // {id: 'Contacts', title: 'Contacts'},
    {id: 'History', title: 'History'},
    {id: 'Recordings', title: 'Recordings'},
    {id: 'Convos', title: 'Convos'},
  ];
  const historyFilter = [
    {id: 'All', title: 'All'},
    {id: 'Missed', title: 'Missed'},
    {id: 'Customer', title: 'Customer'},
    {id: 'Team', title: 'Team'},
  ];

  useEffect(() => {
    setTabType('recent');
  }, [showComponent]);

  const getDispatchBuilders = () => {
    var data = {
      user_id: getItem('id'),
      search_query: '',
    };

    dispatch(getAllBuilderList(data));
  };

  useEffect(() => {
    getDispatchBuilders();
  }, []);

  const handleCall = () => {
    // Add logic for making a call with the phone number
    if (localStorage.getItem('purchased_number_status') === 'active') {
      if (phoneNumber && phoneNumber !== '') {
        if (showComponent === 'Convos') {
          setShowModelPrimary('');
          setShowBuildersComponent('showMessage');

          setSuggestions([]);
        } else {
          setShowModelPrimary('');
          setCallModal(true);

          setSuggestions([]);
        }
      } else {
        errorMessage('Number is required');
      }
    } else {
      errorMessage("You didn't purchase any number");
    }
  };
  const handleContact = item => {
    if (item?.phone) {
      const cleanedPhoneNumber = item?.phone?.replace(/"/g, '');
      const onlyNumnbers = /^[\d+#*]{0,15}$/.test(cleanedPhoneNumber);

      if (cleanedPhoneNumber && onlyNumnbers) {
        setPhoneNumber(cleanedPhoneNumber);
        setShowComponent('Dialler');
      } else {
        setPhoneNumber('');
        errorMessage('User not contain number ');
      }
    } else {
      setPhoneNumber('');
      errorMessage('User not contain number ');
    }
  };

  const handleChange = e => {
    const value = e.target.value;
    if (showComponent === 'Convos') {
      setPhoneNumber(value);
      const getBuiders = getBuilders(value, allBuilderList, 'messages');
      setSuggestions(getBuiders);
    } else {
      if (/^[\d+#*]{0,15}$/.test(value)) {
        setPhoneNumber(value);
        if (value) {
          const getBuiders = getBuilders(value, allBuilderList, 'callCenter');
          setSuggestions(getBuiders);
        } else {
          setSuggestions([]);
        }
      }
    }
  };
  let phone = '';

  const rewardSlider = {
    dots: false,
    arrows: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
  };
  const rewardSliderData = [
    {
      name: 'Response Time',
      money: '$2.4k',
      time: 'versus last month',
      svgIcon: rewardStar,
    },

    {
      name: 'Duration',
      money: '5m 20s',
      time: 'versus last month',
      svgIcon: rewardStar,
    },

    {
      name: 'Frequency',
      money: '45%',
      time: 'versus last month',
      svgIcon: rewardStar,
    },
    {
      name: 'Rating',
      money: '4.8',
      time: 'versus last month',
      svgIcon: rewardStar,
    },
  ];

  const handleType = e => {
    const {id, value} = e.target;
    setShowComponent(value);
  };
  const handleSMSType = e => {
    const {id, value} = e.target;
    setFilterType(value);
  };
  const handleTypeHistory = e => {
    const {id, value} = e.target;
    setShowComponentHistory(value);
  };
  const dynamicStyle = {
    padding: '0px 0px 15px',
  };

  const buildersHandle = (t, id, item) => {
    if (t === 'profileComponent') {
      if (item?.phone) {
        const cleanedPhoneNumber = item?.phone?.replace(/"/g, '');
        const onlyNumnbers = /^[\d+#*]{0,15}$/.test(cleanedPhoneNumber);

        if (cleanedPhoneNumber && onlyNumnbers) {
          setPhoneNumber(cleanedPhoneNumber);
          // check_users_in_company(cleanedPhoneNumber)
          setShowComponent('Dialler');
        } else {
          setPhoneNumber('');
          errorMessage('User not contain number ');
        }
      } else {
        setPhoneNumber('');
        errorMessage('User not contain number ');
      }
    } else {
      setBuildersUserId(id);
      setShowBuildersComponent(t);
    }
  };
  const UserProfileHandle = item => {
    setBuildersUserId(item.userId);
    setShowBuildersComponent('profileComponent');
  };

  useEffect(() => {
    if (showComponent !== '') {
      toggleHiddenPanel(true);
    } else {
      toggleHiddenPanel(false);
    }
    // check_user_number_exist();s
  }, [showComponent]);

  useEffect(() => {
    if (showComponent !== 'Dialler') {
      setPhoneNumber('');
      setSuggestions([]);
    }
  }, [showComponent]);

  const handleMessgaes = () => {
    if (phoneNumber && phoneNumber !== '') {
      check_user_number_exist(phoneNumber);
    } else {
      errorMessage('Please enter number or name.');
    }
  };

  const check_users_in_company = searchQuery => {
    const payload = {search_query: searchQuery};

    commonApi
      .check_users_in_company(payload)

      .then(res => {
        setSuggestions(res?.data);
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };
  const check_user_number_exist = phone_number => {
    const payload = {phone_number: phone_number};

    commonApi
      .check_user_in_company(payload)

      .then(res => {
        if (res.status === 200 && res.user_found) {
          // setShowBuildersComponent('showMessage');
          setUserChat(phoneDetail);
          setPhoneNumber('');
        } else {
          setShowModel('inviteModl');
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  const handleLastMsg = (item, userName) => {
    get_user_chats(1);
    return;
    let messageExists = false;

    // Update existing messages
    let updatedMessages = messages.map(message => {
      if (message?.last_message?.receiver_id === item?.message?.receiver_id) {
        messageExists = true;
        setFilterType('All');
        return {
          ...message,
          last_message: {
            ...message.last_message,
            ...item.message,
            timestamp: item?.message?.timestamp,
          },
        };
      }
      return message;
    });

    // Add new message if it doesn't exist
    if (!messageExists) {
      setFilterType('All');
      updatedMessages.push({
        last_message: {
          receiver_id: item?.message?.receiver_id,
          sender_id: item?.message?.sender_id,
          message: item?.message?.message,
          timestamp: item?.message?.timestamp,
          type: item?.message?.type,
        },
        chat_with: {
          first_name: userName?.first_name
            ? userName?.first_name
            : userName?.receiver_number,
          id: userName?.id,
          last_name: userName?.last_name,
          phone: userName?.phone,
          profile_img: userName?.profile_img,
        },
      });
    }

    // Move the last updated or newly added message to the first index
    const lastMessageIndex = updatedMessages.findIndex(
      message =>
        message.last_message.receiver_id === item?.message?.receiver_id,
    );

    if (lastMessageIndex > -1) {
      const [lastMessageObject] = updatedMessages.splice(lastMessageIndex, 1); // Remove the message
      updatedMessages = [lastMessageObject, ...updatedMessages]; // Add it to the beginning
    }

    // Update the state
    setMessages(updatedMessages);
  };

  const handleSuggestionsClick = (item, cleanedPhoneNumber) => {
    setPhoneNumber(cleanedPhoneNumber);
    setSuggestions([]);
  };

  const inputRef = useRef(null);
  const [inputWidth, setInputWidth] = useState(null);

  useEffect(() => {
    if (inputRef.current) {
      setInputWidth(inputRef.current.offsetWidth);
    }
  }, [inputRef.current]);

  useEffect(() => {
    get_twilio_call_access_token();
  }, []);

  const get_twilio_call_access_token = phoneNumber => {
    // const payload = {phone_number: '+923045485310'};
    const payload = {phone_number: localStorage.getItem('purchased_number')};
    commonApi
      .get_twilio_call_access_token(payload)
      .then(res => {
        if (res.status === 200) {
          setAuthToken_Twillio(res.token);
        }
      })
      .catch(err => console.error('Error fetching Twilio token:', err));
  };

  let debounceTimer;

  const handleScroll = values => {
    if (scrollLock) return;

    const target = values.target;

    const tolerance = 5;
    const bottom =
      target.scrollHeight - target.scrollTop <= target.clientHeight + tolerance;

    if (bottom) {
      clearTimeout(debounceTimer);

      debounceTimer = setTimeout(() => {
        setPageNumber(prevPage => prevPage + 1);
        if (showComponent === 'Convos' && tabType === 'recent') {
          get_user_chats(pageNumber + 1);
        } else if (showComponent === 'Recordings' && tabType === 'recent') {
          get_twilio_voicemails(pageNumber + 1);
        } else if (showComponent === 'Recordings' && tabType === 'contacts') {
          get_twilio_recordings(pageNumber + 1);
        } else if (showComponent === 'History') {
          get_twilio_call(pageNumber + 1);
        }
      }, 300);
    }
  };

  const get_user_chats = page => {
    const payload = {
      search_query: searchQry ? searchQry : '',
      filter_type: filterType.toLowerCase(),
      page: page ? page : 1,
      // type: smsFilterOptions.toLowerCase()
    };

    commonApi
      .get_user_chats(payload)
      .then(res => {
        if (res?.status === 200) {
          // setShowSpinner(false)

          const fetchedData = res?.data || [];

          // Handling scenarios based on filtered data
          if (fetchedData.length > 0) {
            if (page > 1) {
              // Case: length > 0 and page > 1 (Append to array)
              if (fetchedData.length > 0) {
                setMessages(prevData => [...prevData, ...fetchedData]);
                setPageNumber(page); // Increment page for next fetch
              }
            } else if (page === 1) {
              // Case: length > 0 and page === 1 (Replace array)
              setMessages(fetchedData);
              setPageNumber(page); // Reset page number for next page fetch
            }
          } else if (fetchedData.length === 0 && page === 1) {
            // Case: length === 0 and page === 1 (Clear the array)
            setMessages([]);
          } else if (fetchedData.length === 0 && page > 1) {
            setScrollLock(true);
            // Case: length === 0 and page > 1 (No action needed for pagination)
          }
        }
      })
      .catch(err => {
        console.log('Err', err);
        // setShowSpinner(false)
        return {type: 'error', message: err.message};
      });
  };
  const get_twilio_voicemails = page => {
    if (pageNumber === 1) {
      setShowSpinner(true);
    }

    const payload = {
      search_query: searchQry ? searchQry : '',
      filter: smsFilterOptions.toLowerCase(),
      page: page ? page : 1,
    };

    commonApi
      .get_twilio_voicemails(payload)
      .then(res => {
        if (res?.status === 200) {
          setShowSpinner(false);

          const fetchedData = res?.call_details || [];

          // Handling scenarios based on filtered data
          if (fetchedData.length > 0) {
            if (page > 1) {
              // Case: length > 0 and page > 1 (Append to array)
              if (fetchedData.length > 0) {
                setCallRecordingList(prevData => [...prevData, ...fetchedData]);
                setPageNumber(page); // Increment page for next fetch
              }
            } else if (page === 1) {
              // Case: length > 0 and page === 1 (Replace array)
              setCallRecordingList(fetchedData);
              setPageNumber(page); // Reset page number for next page fetch
            }
          } else if (fetchedData.length === 0 && page === 1) {
            // Case: length === 0 and page === 1 (Clear the array)
            setCallRecordingList([]);
          } else if (fetchedData.length === 0 && page > 1) {
            setScrollLock(true);
            // Case: length === 0 and page > 1 (No action needed for pagination)
          }
        }
      })
      .catch(err => {
        console.log('Err', err);
        setShowSpinner(false);
        return {type: 'error', message: err.message};
      });
  };
  const get_twilio_recordings = page => {
    if (pageNumber === 1) {
      setShowSpinner(true);
    }

    const payload = {
      search_query: searchQry ? searchQry : '',
      filter: smsFilterOptions.toLowerCase(),
      page: page ? page : 1,
    };

    commonApi
      .get_twilio_recordings(payload)
      .then(res => {
        if (res?.status === 200) {
          setShowSpinner(false);

          const fetchedData = res?.call_details || [];

          // Handling scenarios based on filtered data
          if (fetchedData.length > 0) {
            if (page > 1) {
              // Case: length > 0 and page > 1 (Append to array)
              if (fetchedData.length > 0) {
                setCallRecordingList(prevData => [...prevData, ...fetchedData]);
                setPageNumber(page); // Increment page for next fetch
              }
            } else if (page === 1) {
              // Case: length > 0 and page === 1 (Replace array)
              setCallRecordingList(fetchedData);
              setPageNumber(page); // Reset page number for next page fetch
            }
          } else if (fetchedData.length === 0 && page === 1) {
            // Case: length === 0 and page === 1 (Clear the array)
            setCallRecordingList([]);
          } else if (fetchedData.length === 0 && page > 1) {
            setScrollLock(true);
            // Case: length === 0 and page > 1 (No action needed for pagination)
          }
        }
      })
      .catch(err => {
        console.log('Err', err);
        setShowSpinner(false);
        return {type: 'error', message: err.message};
      });
  };

  useEffect(() => {
    if (showComponent === 'Convos' && tabType === 'recent') {
      get_user_chats(1);
    } else if (showComponent === 'Recordings' && tabType === 'recent') {
      get_twilio_voicemails(1);
    } else if (showComponent === 'Recordings' && tabType === 'contacts') {
      get_twilio_recordings(1);
    }
    setPageNumber(1);
    setSubTabType('Convos');
    setShowBuildersComponent('');
  }, [searchQry, showComponent, tabType, filterType, smsFilterOptions]);

  useEffect(() => {
    setSearchQry('');
    setScrollLock(false);
  }, [tabType, showComponent]);

  useEffect(() => {
    if (showComponent === 'History' && tabType === 'recent') {
      get_twilio_call(1);
    }
  }, [modelFilter, showComponentHistory, searchQry, showComponent]);

  const smsFilter = [
    {id: 'All', title: 'All'},
    {id: 'Recent', title: 'Recent'},
    {id: 'Customers', title: 'Customers'},
    {id: 'Team', title: 'Team'},
  ];

  const handleCommonChat = (data, type) => {
    if (type === 'edit-group-component') {
      setGroupChat(data);
      if (data.chat_room_type === 'single') {
        setShowBuildersComponent('group-component');
      } else {
        setShowBuildersComponent(type);
      }
    } else if (type === 'create-session-component') {
      setAllDataOfItem(data);
      setShowBuildersComponent(type);
    }
  };

  const loginUser = JSON.parse(localStorage.getItem('allUserData'));

  const get_twilio_call = page => {
    // if (page === 1 || pageNumber === 1) {
    //   setShowSpinner(true);
    // }

    const paylaod = {
      type: showComponentHistory.toLowerCase(),
      filter: modelFilter,
      search_query: searchQry ? searchQry : '',
      page: page ? page : 1,
    };
    commonApi
      .get_twilio_call(paylaod)
      .then(res => {
        if (res.status === 200) {
          setShowSpinner(false);

          const fetchedData = res?.call_details || [];

          // Handling scenarios based on filtered data
          if (fetchedData.length > 0) {
            if (page > 1) {
              // Case: length > 0 and page > 1 (Append to array)
              if (fetchedData.length > 0) {
                setCallHistoryList(prevData => [...prevData, ...fetchedData]);
                setPageNumber(page); // Increment page for next fetch
              }
            } else if (page === 1) {
              // Case: length > 0 and page === 1 (Replace array)
              setCallHistoryList(fetchedData);
              setPageNumber(page); // Reset page number for next page fetch
            }
          } else if (fetchedData.length === 0 && page === 1) {
            // Case: length === 0 and page === 1 (Clear the array)
            setCallHistoryList([]);
          } else if (fetchedData.length === 0 && page > 1) {
            setScrollLock(true);
            // Case: length === 0 and page > 1 (No action needed for pagination)
          }
        }
      })
      .catch(err => {
        setShowSpinner(false);
        console.error('Error fetching Twilio token:', err);
      });
  };

  const callToPerson = item => {
    if (localStorage.getItem('purchased_number_status') === 'active') {
      setCallModalRecent(true);
    } else {
      errorMessage("You didn't purchase any number");
    }

    // alert(item.phoneNumber,'1')
    // alert(phone,'2')
  };

  return (
    <>
      <div className="page-wrapper">
        <Sidebar
          location={props.location}
          setShowComponent={setShowComponent}
          setWorkSpaceItem={setRefresh}
          showComponent={showComponent}
        />
        <div className="page-content-wrapper">
          <div className="sessions-list-wrapper slider-screen-main">
            <div className="Reward_main analytics_slider_bg">
              <span
                className="analytics_slider_heading "
                style={{color: analycticsSlider ? '' : '#999999'}}
                onClick={() => {
                  setAnalycticsSlider(!analycticsSlider);
                }}>
                Analytics Row
              </span>

              {analycticsSlider && (
                <div className="reward-top mt-3 mb-2">
                  <Slider {...rewardSlider}>
                    {rewardSliderData.length > 0 &&
                      rewardSliderData.map((item, index) => (
                        <div className="reward-main">
                          <div className="reward-card">
                            <span style={{fontWeight: 700, fontSize: '13px'}}>
                              {item.name}
                            </span>
                            <span
                              style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                              }}>
                              <span style={{fontSize: '17px'}}>
                                {item.money}
                              </span>
                              {item.name === 'Ratings' && (
                                <img src={item.svgIcon} alt="" />
                              )}
                              {item.name === 'Conversion' ? (
                                <i
                                  className="fa-solid fa-arrow-down"
                                  style={{color: '#BD4141'}}></i>
                              ) : (
                                <i
                                  class="fa-solid fa-arrow-up"
                                  style={{color: '#52bd41'}}></i>
                              )}
                            </span>
                            <div class="message-date-info">
                              <span
                                style={{color: '#333'}}
                                class="message-date">
                                {item.time}
                              </span>
                            </div>
                          </div>
                        </div>
                      ))}
                  </Slider>
                </div>
              )}
            </div>
            <div className="add_border"></div>
            <Pannels
              isFloated={isFloated}
              toggleFloatedPanel={toggleFloatedPanel}
              showHiddenPanel={showHiddenPanel}
              updateHiddenPanelState={nState => toggleHiddenPanel(nState)}
              customCss={dynamicStyle}
              primary={
                <>
                  {/* <div>
                    <DiscoverBuilders
                      buildersHandle={buildersHandle}
                      buildersUserId={buildersUserId}
                      apiHit={refresh}
                      checkUserHitApi={checkUserHitApi}
                    />
                  </div> */}

                  <div
                    style={{
                      bottom: '96px',
                      left: 'auto',
                      right: '34px',
                    }}
                    className="absolute z-[9999]">
                    <a
                      onClick={() => {
                        if (showModelPrimary === 'Dialler') {
                          setShowModelPrimary('');
                        } else {
                          setShowModelPrimary('Dialler');
                        }
                      }}
                      className="flex items-center justify-center w-10 h-10 bg-black text-white  hover:bg-black rounded-[9px] as-link">
                      <SvgIconComponent icon={'typePad'} />
                    </a>
                  </div>
                  {/* <div className="category-btns-wrapper category-btns-wrapper-slider mt-0 ">
                    <CustomSlidingButtons
                      items={TabList}
                      activeButtons={showComponent}
                      onItemClick={e => {
                        handleType(e);
                      }}
                      showItems={3}
                      value={showComponent}
                    />
                  </div> */}
                  {showComponent !== 'Dialler' && (
                    <div className="primary_panel_wraper primary_panel_content">
                      <div className="search-tabs-wrapper">
                        <div className="d-flex justify-content-between align-items-center mb-2">
                          <div className="event-tabs tabs-style-2 mb-0">
                            <ul>
                              <li>
                                <Button
                                  className={
                                    tabType === 'recent' ? 'active' : ''
                                  }
                                  onClick={() => {
                                    setTabType('recent');
                                  }}>
                                  {showComponent === 'Recordings'
                                    ? 'Voicemail'
                                    : showComponent === 'Convos'
                                    ? 'SMS'
                                    : 'Recent'}
                                </Button>
                              </li>
                              <li>
                                <Button
                                  className={
                                    tabType === 'contacts' ? 'active' : ''
                                  }
                                  onClick={() => {
                                    setTabType('contacts');
                                  }}>
                                  {showComponent === 'Recordings'
                                    ? 'Recordings'
                                    : showComponent === 'Convos'
                                    ? 'Chat'
                                    : 'Contacts'}
                                </Button>
                              </li>
                              {showComponent === 'Convos' && (
                                <li>
                                  <Button
                                    className={
                                      tabType === 'email' ? 'active' : ''
                                    }
                                    onClick={() => {
                                      setTabType('email');
                                    }}>
                                    {showComponent === 'Convos' ? 'Email' : ''}
                                  </Button>
                                </li>
                              )}
                            </ul>
                          </div>

                          <div className="search-tabs-icons">
                            {' '}
                            {((showComponent === 'History' &&
                              tabType === 'contacts') ||
                              (showComponent !== 'History' &&
                                showComponent !== 'Recordings')) && (
                              <span
                                className={
                                  showBuildersComponent === ''
                                    ? 'icon'
                                    : 'icon active'
                                }
                                onClick={() => {
                                  if (
                                    showComponent === 'Convos' &&
                                    tabType === 'recent'
                                  ) {
                                    setShowBuildersComponent('builderMessges');
                                  } else if (
                                    showComponent === 'History' &&
                                    tabType === 'contacts'
                                  ) {
                                    setShowBuildersComponent(
                                      'inviteOutSideTheBuildPlusIcon',
                                    );
                                  }
                                }}>
                                <i className="icon-plus"></i>
                              </span>
                            )}
                            <span
                              onClick={() => {
                                setThreeDots(!threeDots);
                              }}
                              className={threeDots ? 'icon active' : 'icon'}>
                              <i className="fas fa-ellipsis-vertical" />
                            </span>
                          </div>
                        </div>

                        {threeDots && (
                          <>
                            <div className="search-form d-flex">
                              <Form.Group className="search-input">
                                <Form.Control
                                  type="text"
                                  placeholder={`Search for a ${
                                    showComponent === 'Recordings'
                                      ? tabType === 'contacts'
                                        ? 'recordings'
                                        : 'voicemail'
                                      : showComponent === 'Convos'
                                      ? tabType === 'contacts'
                                        ? 'chat'
                                        : tabType === 'recent'
                                        ? 'sms'
                                        : 'email'
                                      : showComponent === 'History' && tabType
                                  }`}
                                  onChange={e => {
                                    setSearchQry(e.target.value);
                                    if (
                                      showComponent === 'History' &&
                                      tabType === 'contacts'
                                    ) {
                                      handleSearchQry(e.target.value);
                                    }
                                  }}
                                  value={searchQry}
                                />
                              </Form.Group>
                              {showComponent !== 'Convos' &&
                                // showComponent !== 'Recordings' &&
                                tabType == 'recent' && (
                                  <Button
                                    onClick={() => {
                                      if (showComponent === 'History') {
                                        setShowModel('filterRecent');
                                      } else if (
                                        showComponent === 'Recordings'
                                      ) {
                                        setShowModel('filterSMS');
                                      }
                                    }}>
                                    <i className="icon-filter-icon" />
                                  </Button>
                                )}
                            </div>
                            {showComponent === 'History' &&
                              tabType !== 'contacts' && (
                                <div className="category-btns-wrapper category-btns-wrapper-slider mt-0 mt-4">
                                  <CustomSlidingButtons
                                    items={historyFilter}
                                    activeButtons={showComponentHistory}
                                    onItemClick={e => {
                                      handleTypeHistory(e);
                                    }}
                                    showItems={3}
                                    value={showComponent}
                                  />
                                </div>
                              )}
                            {showComponent === 'Convos' &&
                              tabType !== 'contacts' && (
                                <div className="category-btns-wrapper category-btns-wrapper-slider mt-0 mt-4">
                                  <CustomSlidingButtons
                                    items={smsFilter}
                                    activeButtons={filterType}
                                    onItemClick={e => {
                                      handleSMSType(e);
                                    }}
                                    showItems={3}
                                    value={showComponent}
                                  />
                                </div>
                              )}
                          </>
                        )}
                      </div>
                    </div>
                  )}

                  {/* {showComponent === 'SMS' && (
                    <>
                      <div
                        className="primary_panel_wraper"
                        style={{paddingTop: '0px'}}>
                        <div className="call-center-page">
                          <div className="messages-header">
                            <div
                              className="tabs-style-2"
                              style={{margin: '0px', width: '100%'}}>
                              <div
                                className="phone-input"
                                style={{
                                  justifyContent: 'space-between',
                                  width: '100%',
                                }}>
                                <div className="phone-messages-call-input-holder">
                                  <div
                                    className="search-tabs-wrapper"
                                    style={{margin: '0px'}}>
                                    <div className="search-form mt-0">
                                      <Form.Group className="search-input-location">
                                        <div className="form-control-with-icon phone_placeholder phone-messages-call-input-contain">
                                          <Form.Control
                                            ref={inputRef}
                                            style={{
                                              color:
                                                phoneNumber !== ''
                                                  ? ''
                                                  : '#AEAEAE',
                                              fontFamily: 'Inter',
                                              fontStyle: 'normal',
                                              fontWeight: '300',
                                              lineHeight: 'normal',
                                              fontSize: '15px',
                                              background: 'white',
                                              width: '',
                                              borderRadius: '5px',
                                              borderColor: '#eaeaea',
                                              margin: '0px',
                                            }}
                                            value={phoneNumber}
                                            onChange={handleChange}
                                            maxLength={15}
                                            type="text"
                                            placeholder="Enter Phone Number"
                                          />
                                          {phoneNumber !== '' && (
                                            <span
                                              className="input-icon"
                                              style={{left: '90%'}}
                                              onClick={() => {
                                                setPhoneNumber('');
                                                setSuggestions([]);
                                              }}>
                                              <i className="fas fa-close" />
                                            </span>
                                          )}
                                        </div>
                                      </Form.Group>
                                    </div>
                                  </div>
                                </div>

                                <div>
                                  <div
                                    className="phone-call-icon as-link"
                                    style={{
                                      width: '50px',
                                      height: '50px',
                                      paddingTop: '3px',
                                      paddingRight: '3px',
                                    }}
                                    onClick={handleMessgaes}>
                                    <i
                                      className="fa-solid icon-plane"
                                      style={{
                                        color: 'white',
                                        fontSize: '26px',
                                      }}></i>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <SuggestionsListing
                          listing={suggestions}
                          type="messages"
                          handleSuggestions={handleSuggestionsClick}
                          style={inputWidth}
                        />
                      </div>

                      <div className="category-btns-wrapper category-btns-wrapper-slider mt-3 mb-2">
                        <CustomSlidingButtons
                          items={TabList}
                          activeButtons={filterType}
                          onItemClick={e => {
                            handleSMSType(e);
                          }}
                          showItems={3}
                          value={filterType}
                        />
                      </div>
                    </>
                  )} */}
                  {showComponent === 'Convos' && tabType === 'contacts' ? (
                    <TwillioMessges
                      chatPropType={chatPropType}
                      chatDetailItem={chatDetailItem}
                      oppoDetails={chatDetails}
                      chatDetails={chatDetails}
                      allChatUsers={allChatUsers}
                      chatRoomName={chatRoomName}
                      chatRoomId={chatRoomId}
                      workspaceData={workspaceData}
                      setChatRoomId={setChatRoomId}
                      paddingBottom={true}
                      setConvosData={setConvosData}
                      convosData={convosData}
                      setChatDetails={setChatDetails}
                      setAllChatUsers={setAllChatUsers}
                      setChatRoomName={setChatRoomName}
                      setChatPropType={setChatPropType}
                      setChatDetailItem={setChatDetailItem}
                      setShowBuildersComponent={setShowBuildersComponent}
                      showBuildersComponent={showBuildersComponent}
                      subTabType={subTabType}
                      setSubTabType={setSubTabType}
                    />
                  ) : (
                    <Scrollbars
                      ref={scrollbarRef}
                      autoHide
                      renderTrackHorizontal={props => (
                        <div {...props} style={{display: 'none'}} />
                      )}
                      onScroll={handleScroll}
                      // Hide the vertical scrollbar
                      renderTrackVertical={props => (
                        <div {...props} style={{display: 'none'}} />
                      )}>
                      <div
                        className="primary_panel_wraper"
                        style={{paddingTop: '0px'}}>
                        <div className="call-center-page">
                          {/* {showComponent === 'Dialler' && (
                            <>
                              <div className="messages-header mt-4">
                                <div
                                  className="tabs-style-2"
                                  style={{margin: '0px', width: '100%'}}>
                                  <div
                                    className="phone-input"
                                    style={{
                                      justifyContent: 'space-between',
                                      width: '100%',
                                    }}>
                                    <div>
                                      <div
                                        className="search-tabs-wrapper"
                                        style={{margin: '0px'}}>
                                        <div className="search-form d-flex mt-0">
                                          <Form.Group className="search-input-location">
                                            <div className="form-control-with-icon phone_placeholder">
                                              <Form.Control
                                                ref={inputRef}
                                                style={{
                                                  color:
                                                    phoneNumber !== ''
                                                      ? ''
                                                      : '#AEAEAE',
                                                  fontFamily: 'Inter',
                                                  fontStyle: 'normal',
                                                  fontWeight: '300',
                                                  lineHeight: 'normal',
                                                  fontSize: '15px',
                                                  background: 'white',
                                                  width: '310px',
                                                  borderRadius: '5px',
                                                  borderColor: '#eaeaea',
                                                  margin: '0px',
                                                }}
                                                value={phoneNumber}
                                                onChange={handleChange}
                                                maxLength={15}
                                                type="text"
                                                placeholder="Enter Phone Number"
                                              />
                                              {phoneNumber !== '' && (
                                                <span
                                                  className="input-icon"
                                                  style={{left: '90%'}}
                                                  onClick={() => {
                                                    setPhoneNumber('');
                                                    setSuggestions([]);
                                                  }}>
                                                  <i className="fas fa-close" />
                                                </span>
                                              )}
                                            </div>
                                          </Form.Group>
                                        </div>
                                      </div>
                                    </div>

                                    <div>
                                      <div
                                        className="phone-call-icon cursor-pointer"
                                        onClick={handleCall}>
                                        <i
                                          class="fa-solid fa-phone "
                                          style={{
                                            color: '#ffffff',
                                            fontSize: '1.5em',
                                          }}></i>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <SuggestionsListing
                                listing={suggestions}
                                type="callListing"
                                handleSuggestions={handleSuggestionsClick}
                                style={inputWidth}
                              />
                            </>
                          )} */}

                          {showComponent === 'History' && (
                            <CallsInformation
                              tabType={tabType}
                              allBuilderList={contactListing}
                              filter={showComponentHistory}
                              modelFilter={modelFilter}
                              setShowModelPrimary={setShowModelPrimary}
                              callHistoryList={callHistoryList}
                              showSpinner={showSpinner}
                              callToPerson={callToPerson}
                              setPhoneNumber={setPhoneNumber}
                            />
                          )}
                          {showComponent === 'Convos' &&
                            tabType === 'recent' && (
                              <TwillioCallCenterSms
                                subTabType={
                                  tabType === 'contacts'
                                    ? 'chat'
                                    : tabType === 'recent'
                                    ? 'sms'
                                    : 'email'
                                }
                                callHistoryList22={messages}
                                setUserChat={setUserChat}
                                setShowBuildersComponent={
                                  setShowBuildersComponent
                                }
                              />
                            )}

                          {showComponent === 'Recordings' && (
                            <CallRecordings
                              subTabType={
                                tabType === 'contacts'
                                  ? 'Recordings'
                                  : 'Voicemail'
                              }
                              callRecordingList={callRecordingList}
                              showSpinner={showSpinner}
                              UserProfileHandle={UserProfileHandle}
                            />
                          )}

                          {/* {showComponent === 'Contacts' && (
                          <>
                            <CallContacts
                              contactListing={allBuilderList}
                              handleContact={handleContact}
                            />
                          </>
                        )} */}

                          {/* </Scrollbars> */}
                        </div>
                      </div>
                    </Scrollbars>
                  )}

                  {/* <div className="primary_panel_wraper"> */}
                  <div className="call-center-page"></div>

                  <div className="category-btns-wrapper category-btns-wrapper-slider call-center-btn-slide-wrapper">
                    <div
                      style={{
                        display: 'flex',
                        flex: '0 0 auto',
                        width: '80%',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        margin: '0',
                      }}>
                      <div
                        className="as-link"
                        onClick={() => {
                          setShowComponent('History');
                        }}>
                        {showComponent === 'History' ? (
                          <i
                            className="icon-history"
                            style={{fontSize: '22px', color: 'green'}}
                          />
                        ) : (
                          <i
                            className="icon-history"
                            style={{fontSize: '22px'}}
                          />
                        )}
                      </div>
                      <div
                        className="as-link"
                        onClick={() => {
                          setShowComponent('Convos');
                        }}>
                        {showComponent === 'Convos' ? (
                          <i
                            style={{
                              marginTop: '3px',
                              fontSize: '22px',
                              color: 'green',
                            }}
                            className="fa-solid icon-plane"></i>
                        ) : (
                          <i
                            style={{
                              marginTop: '3px',
                              fontSize: '22px',
                              color: 'black',
                            }}
                            className="fa-solid icon-plane"></i>
                        )}
                      </div>

                      <div
                        className="as-link"
                        onClick={() => {
                          setShowComponent('Recordings');
                        }}>
                        {showComponent === 'Recordings' ? (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="23"
                            viewBox="0 0 24 28"
                            fill="green">
                            <path
                              d="M11.6779 27.3604C11.2935 27.2159 10.9203 27.059 10.6594 26.7153C10.4312 26.4149 10.3002 26.084 10.2878 25.7076C10.2857 25.6455 10.2862 25.5829 10.2862 25.5208C10.2862 17.6223 10.2862 9.72386 10.2862 1.82541C10.2862 0.91253 10.77 0.263071 11.6022 0.0505115C12.6095 -0.206488 13.6479 0.549519 13.7075 1.58501C13.7145 1.70923 13.7123 1.83398 13.7123 1.95873C13.7123 9.69709 13.7016 17.4349 13.7252 25.1733C13.7285 26.2553 13.4052 27.0242 12.3206 27.3604H11.6779Z"
                              fill="green"
                            />
                            <path
                              d="M24.0003 17.4216C23.8559 17.8157 23.6895 18.1926 23.3324 18.4528C22.789 18.849 22.2011 18.9315 21.5917 18.6595C20.9624 18.3784 20.5865 17.8703 20.5812 17.1785C20.5629 14.8468 20.5661 12.5145 20.5785 10.1828C20.5828 9.36951 21.2421 8.6724 22.0497 8.56425C22.8706 8.45449 23.6476 8.94011 23.908 9.72288C23.9327 9.79731 23.9687 9.86745 23.9993 9.93973V17.4211L24.0003 17.4216Z"
                              fill="green"
                            />
                            <path
                              d="M18.8556 13.6934C18.8556 16.2398 18.8583 18.7868 18.854 21.3332C18.8524 22.3794 18.0459 23.1381 17.015 23.0792C16.2021 23.0326 15.4966 22.3436 15.4354 21.5329C15.429 21.4441 15.4289 21.3552 15.4289 21.2658C15.4289 16.2082 15.4284 11.1502 15.4289 6.09264C15.4289 5.21991 15.8961 4.5651 16.673 4.33969C17.7318 4.03236 18.8207 4.81674 18.8508 5.91916C18.8658 6.47974 18.8551 7.04086 18.8551 7.60197C18.8551 9.63227 18.8551 11.6626 18.8551 13.6929L18.8556 13.6934Z"
                              fill="green"
                            />
                            <path
                              d="M3.4265 13.6798C3.4265 15.9505 3.43133 18.2212 3.42435 20.4919C3.42059 21.7186 2.29143 22.5163 1.13166 22.126C0.518488 21.9199 0.0497482 21.3133 0.00571995 20.667C0.000887578 20.5958 0.000350648 20.5246 0.000350648 20.4534C0.000350648 15.9388 -0.000723212 11.4241 0.000887578 6.91005C0.000887578 5.90507 0.722521 5.14693 1.67933 5.13301C2.63614 5.11909 3.41415 5.85796 3.42382 6.81475C3.43509 7.98088 3.42704 9.14755 3.42704 10.3142C3.42704 11.4359 3.42704 12.5581 3.42704 13.6798H3.4265Z"
                              fill="green"
                            />
                            <path
                              d="M8.56986 13.6768C8.56986 14.8167 8.57469 15.956 8.56825 17.0954C8.56342 17.9323 8.01897 18.5913 7.19962 18.7761C6.45382 18.9442 5.65487 18.5598 5.31821 17.8343C5.21298 17.6078 5.15284 17.3395 5.15123 17.0895C5.13888 14.8193 5.13995 12.5487 5.14586 10.2785C5.14801 9.30564 5.89649 8.55606 6.84685 8.55178C7.81118 8.54749 8.56396 9.29815 8.56932 10.2838C8.57577 11.4146 8.57094 12.5454 8.57094 13.6762L8.56986 13.6768Z"
                              fill="green"
                            />
                          </svg>
                        ) : (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="23"
                            viewBox="0 0 24 28"
                            fill="black">
                            <path
                              d="M11.6779 27.3604C11.2935 27.2159 10.9203 27.059 10.6594 26.7153C10.4312 26.4149 10.3002 26.084 10.2878 25.7076C10.2857 25.6455 10.2862 25.5829 10.2862 25.5208C10.2862 17.6223 10.2862 9.72386 10.2862 1.82541C10.2862 0.91253 10.77 0.263071 11.6022 0.0505115C12.6095 -0.206488 13.6479 0.549519 13.7075 1.58501C13.7145 1.70923 13.7123 1.83398 13.7123 1.95873C13.7123 9.69709 13.7016 17.4349 13.7252 25.1733C13.7285 26.2553 13.4052 27.0242 12.3206 27.3604H11.6779Z"
                              fill="black"
                            />
                            <path
                              d="M24.0003 17.4216C23.8559 17.8157 23.6895 18.1926 23.3324 18.4528C22.789 18.849 22.2011 18.9315 21.5917 18.6595C20.9624 18.3784 20.5865 17.8703 20.5812 17.1785C20.5629 14.8468 20.5661 12.5145 20.5785 10.1828C20.5828 9.36951 21.2421 8.6724 22.0497 8.56425C22.8706 8.45449 23.6476 8.94011 23.908 9.72288C23.9327 9.79731 23.9687 9.86745 23.9993 9.93973V17.4211L24.0003 17.4216Z"
                              fill="black"
                            />
                            <path
                              d="M18.8556 13.6934C18.8556 16.2398 18.8583 18.7868 18.854 21.3332C18.8524 22.3794 18.0459 23.1381 17.015 23.0792C16.2021 23.0326 15.4966 22.3436 15.4354 21.5329C15.429 21.4441 15.4289 21.3552 15.4289 21.2658C15.4289 16.2082 15.4284 11.1502 15.4289 6.09264C15.4289 5.21991 15.8961 4.5651 16.673 4.33969C17.7318 4.03236 18.8207 4.81674 18.8508 5.91916C18.8658 6.47974 18.8551 7.04086 18.8551 7.60197C18.8551 9.63227 18.8551 11.6626 18.8551 13.6929L18.8556 13.6934Z"
                              fill="black"
                            />
                            <path
                              d="M3.4265 13.6798C3.4265 15.9505 3.43133 18.2212 3.42435 20.4919C3.42059 21.7186 2.29143 22.5163 1.13166 22.126C0.518488 21.9199 0.0497482 21.3133 0.00571995 20.667C0.000887578 20.5958 0.000350648 20.5246 0.000350648 20.4534C0.000350648 15.9388 -0.000723212 11.4241 0.000887578 6.91005C0.000887578 5.90507 0.722521 5.14693 1.67933 5.13301C2.63614 5.11909 3.41415 5.85796 3.42382 6.81475C3.43509 7.98088 3.42704 9.14755 3.42704 10.3142C3.42704 11.4359 3.42704 12.5581 3.42704 13.6798H3.4265Z"
                              fill="black"
                            />
                            <path
                              d="M8.56986 13.6768C8.56986 14.8167 8.57469 15.956 8.56825 17.0954C8.56342 17.9323 8.01897 18.5913 7.19962 18.7761C6.45382 18.9442 5.65487 18.5598 5.31821 17.8343C5.21298 17.6078 5.15284 17.3395 5.15123 17.0895C5.13888 14.8193 5.13995 12.5487 5.14586 10.2785C5.14801 9.30564 5.89649 8.55606 6.84685 8.55178C7.81118 8.54749 8.56396 9.29815 8.56932 10.2838C8.57577 11.4146 8.57094 12.5454 8.57094 13.6762L8.56986 13.6768Z"
                              fill="black"
                            />
                          </svg>
                        )}
                      </div>
                    </div>
                  </div>
                  {callModal && (
                    <CallScreenModal
                      closeModel={() => {
                        setCallModal(false);
                        // setPhoneNumber('');
                        setSuggestions([]);
                      }}
                      show={callModal}
                      phoneNumber={phoneNumber}
                      check_user_number_exist={check_user_number_exist}
                      authToken_Twillio={authToken_Twillio}
                    />
                  )}
                  {callModalRecent && phoneNumber && (
                    <CallScreenModal
                      closeModel={() => {
                        setCallModal(false);
                        // setPhoneNumber('');
                        setSuggestions([]);
                      }}
                      show={callModal}
                      phoneNumber={phoneNumber}
                      check_user_number_exist={check_user_number_exist}
                      authToken_Twillio={authToken_Twillio}
                    />
                  )}

                  {showModel === 'inviteModl' && (
                    <ConfirmationModel
                      closeModel={() => {
                        setShowModel('');
                      }}
                      discription={'Will you invite this user in app ?'}
                      buttonOne={'Yes'}
                      buttonTwo={'No'}
                      ButtonOneAction={() => {
                        setShowBuildersComponent('inviteOutSideTheBuild');
                        setShowModel('');
                      }}
                    />
                  )}
                  {showModel === 'filterRecent' && (
                    <RecentFilterModel
                      onClose={() => {
                        setShowModel('');
                      }}
                      setModelFilter={setModelFilter}
                      modelFilter={modelFilter}
                      filterOptions={filterOptions}
                    />
                  )}
                  {showModel === 'filterSMS' && (
                    <RecentFilterModel
                      onClose={() => {
                        setShowModel('');
                      }}
                      setModelFilter={setSmsFilterOptions}
                      modelFilter={smsFilterOptions}
                      filterOptions={smsFiltersOption}
                    />
                  )}
                  {showModelPrimary === 'Dialler' && (
                    <>
                      <CustomModal size="small">
                        <div
                          className="call-center-page"
                          style={{height: '300px'}}>
                          <div className="messages-header mt-4">
                            <div
                              className="tabs-style-2"
                              style={{margin: '0px', width: '100%'}}>
                              <div
                                className="phone-input"
                                style={{
                                  justifyContent: 'space-between',
                                  width: '100%',
                                }}>
                                <div>
                                  <div
                                    className="search-tabs-wrapper"
                                    style={{margin: '0px'}}>
                                    <div className="search-form d-flex mt-0">
                                      <Form.Group className="search-input-location">
                                        <div className="form-control-with-icon phone_placeholder">
                                          <Form.Control
                                            ref={inputRef}
                                            style={{
                                              color:
                                                phoneNumber !== ''
                                                  ? ''
                                                  : '#AEAEAE',
                                              fontFamily: 'Inter',
                                              fontStyle: 'normal',
                                              fontWeight: '300',
                                              lineHeight: 'normal',
                                              fontSize: '15px',
                                              background: 'white',
                                              width: '275px',
                                              borderRadius: '5px',
                                              borderColor: '#eaeaea',
                                              margin: '0px',
                                            }}
                                            value={phoneNumber}
                                            onChange={handleChange}
                                            maxLength={15}
                                            type="text"
                                            placeholder="Enter Phone Number"
                                          />

                                          {phoneNumber !== '' && (
                                            <span
                                              className="input-icon"
                                              style={{left: '90%'}}
                                              onClick={() => {
                                                setPhoneNumber('');
                                                setSuggestions([]);
                                              }}>
                                              <i className="fas fa-close" />
                                            </span>
                                          )}
                                        </div>
                                      </Form.Group>
                                    </div>
                                  </div>
                                </div>

                                <div>
                                  <div
                                    className="phone-call-icon cursor-pointer"
                                    onClick={() => {
                                      handleCall();
                                    }}>
                                    <i
                                      className={
                                        showComponent === 'Convos'
                                          ? 'icon-plane'
                                          : `fa-solid fa-phone`
                                      }
                                      style={{
                                        color: '#ffffff',
                                        fontSize: '1.5em',
                                      }}></i>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <SuggestionsListing
                            listing={suggestions}
                            type="callListing"
                            handleSuggestions={handleSuggestionsClick}
                            style={inputWidth}
                          />
                        </div>
                      </CustomModal>
                    </>
                  )}
                </>
              }
              secondary={
                <>
                  {showBuildersComponent === 'inviteOutSideTheBuild' && (
                    <SendInvitation
                      onClose={() => {
                        setShowBuildersComponent('');
                      }}
                      phoneNumber={phoneNumber}
                      setCheckUserHitApi={setCheckUserHitApi}
                      checkUserHitApi={checkUserHitApi}
                    />
                  )}
                  {showBuildersComponent ===
                    'inviteOutSideTheBuildPlusIcon' && (
                    <SendInvitation
                      onClose={() => {
                        setShowBuildersComponent('');
                      }}
                    />
                  )}
                  {buildersUserId &&
                    showBuildersComponent === 'profileComponent' && (
                      <div className="pb-4 fix-width-forms">
                        <ProfileComponent
                          userId={buildersUserId}
                          show={false}
                          onClose={() => {
                            setShowBuildersComponent('');
                          }}
                          button={''}
                          userData={[]}
                          moderator={false}
                          VoxeetSDK={[]}
                          conferenceInfo={[]}
                          jdSpecialM={[]}
                          jdSpecialA={[]}
                        />
                      </div>
                    )}

                  {showBuildersComponent === 'AllBuilders' && (
                    <div className="pb-4">
                      <AllBuilders
                        onClose={() => {
                          setShowBuildersComponent('');
                        }}
                      />
                    </div>
                  )}

                  {showBuildersComponent === 'showMessage' && (
                    <CallMessages
                      onClose={() => {
                        setShowBuildersComponent('');
                        setUserChat({});
                      }}
                      phoneNumber={phoneNumber}
                      userChat={userChat}
                      handleLastMsg={handleLastMsg}
                    />
                  )}

                  {showBuildersComponent === 'chat-component' && (
                    <SmartChatPage
                      allmsg={true}
                      type={chatPropType}
                      item={chatDetailItem}
                      chatroomInfo={chatroomInfo(chatDetails)}
                      oppoDetails={chatDetails}
                      chatDetails={chatDetails}
                      allChatUsers={allChatUsers}
                      chatRoomName={chatRoomName}
                      chatRoomId={chatRoomId}
                      workSpaceItem={workspaceData}
                      // chatRoomStatus={chatDetails.status}
                      onClose={() => {
                        setShowBuildersComponent('');
                        setChatRoomId('');
                      }}
                      handleCommonChat={(data, type) => {
                        handleCommonChat(data, type);
                      }}
                      paddingBottom={true}
                      setConvosData={setConvosData}
                      convosData={convosData}
                    />
                  )}

                  {showBuildersComponent === 'group-component' && (
                    <CreateGroup
                      onClose={() => {
                        setShowBuildersComponent('');
                      }}
                      chatGroupType={'create'}
                    />
                  )}

                  {showBuildersComponent === 'builderMessges' && (
                    <AddPoc
                      onClose={() => setShowBuildersComponent('')}
                      openMessageComp={item => {
                        setShowBuildersComponent('showMessage');
                        const data = {
                          ...item,
                          chat_data: {
                            sender_detail: {
                              first_name: loginUser?.first_name,
                              last_name: loginUser?.last_name,
                              profile_img: loginUser?.profile_img,
                              id: loginUser?.id,
                            },
                          },
                        };
                        setUserChat(data);
                      }}
                      // user_id={userInfo?.data?.linked_user_detail?.id}
                      // existingPocIds={
                      //   userInfo?.data?.linked_user_detail?.linked_users_poc &&
                      //   userInfo?.data?.linked_user_detail?.linked_users_poc.length > 0
                      //     ? userInfo?.data?.linked_user_detail?.linked_users_poc.map(
                      //         item => item.id,
                      //       )
                      //     : []
                      // }
                      // getUserData={getUserData}
                      onlyOneSelectUser={'true'}
                    />
                  )}
                </>
              }
            />
            {showBuildersComponent === 'WorkSpace' && (
              <CreateWorkSpace
                onClose={() => {
                  setShowBuildersComponent('');
                }}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};
export default CallCenter;
