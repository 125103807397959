import { project_management, twillio_icon } from "../Constants/Images";

export const PipelineNoRecordFound = {
    img: null,
    title: null,
    description: 'No Pipeline found.',
    button: 'Create PipeLine',
  };
export const NoOfferingsFound = {
    img: null,
    title: null,
    description: 'No offerings available.',
    button: 'Add Offering',
  };
export const NoProjectsFound = {
    img: project_management,
    title: null,
    description: 'No projects available.',
    button: 'Add projects',
  };
export const NoTagsFound = {
    img: null,
    title: null,
    description: 'No tags found.',
    button: 'Add tags',
  };


  export const NoExpenseFound = {
    img: null,
    title: null,
    description: 'No expense found.',
    button: 'Create Expense',
  };
  export const formNoFound = {
    img: null,
    title: null,
    description: 'No forms found.',
    button: 'Create Form',
  };
  export const filedNoFound = {
    img: null,
    title: null,
    description: 'No fields found.',
    button: 'Create Field',
  };


  export const OverviewNoRecordFound = {
    img: null,
    title: null,
    description: 'No record found.',
    button: null,
  };
  export const messagesRecordFound = {
    img: twillio_icon,
    title: null,
    description: 'No record found.',
    button: 'Make call',
  };
  export const propertyNoRecordFound = {
    img: null,
    title: null,
    description: 'No record found.',
    button: null,
  };


  export const DraggableDealsNoRecord = {
    img: null,
    title: null,
    description: 'No deals available.',
    button: 'Add Deal',
  };

  // export const DraggableTaskNoRecord = {
  //   img: null,
  //   title: null,
  //   description: 'No deals available.',
  //   button: 'Add Deal',
  // };

  export const TasksNoRecordFound = {
    img: null,
    title: null,
    description: 'No Task found.',
    button: 'Create Task',
  };

  export const POCNoRecordFound = {
    img: null,
    title: null,
    description: 'No POC found.',
    button: 'Add POC',
  };
  export const PortfolioNoRecordFound = {
    img: null,
    title: null,
    description: 'No Portfolio found.',
    button: 'Add Portfolio',
  };



