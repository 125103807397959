import React, {useState, useEffect, useImperativeHandle} from 'react';
import Sidebar from '../../Pages/Sidebar';
import {selected_lang} from '../../Utils/env';
import Pannels from '../../Components/Pannels';

import {arrow_up, rewardStar, taskCheckMark} from '../../Constants/Images';
import {labels} from '../../Constants/Translations';
import {Button, Form} from 'react-bootstrap';

import Slider from 'react-slick';
import CreateWorkSpace from '../../Components/WorkSpace/CreateWorkSpace';
import Scrollbars from 'react-custom-scrollbars';
import {CustomModal, CustomSlidingButtons} from '../../Components';

import {getAllActions} from '../../Store/Actions/SessionAction';

import {useDispatch, useSelector} from 'react-redux';
import {capitalizeFirst, fixName} from '../../Utils/commonUtils';
import CreateAction from '../../Components/Snapshot/CreateAction';
import ActionDetail from '../../Components/Actions/ActionDetail';

import LoaderSpinner from '../Modals/LoaderSpinner';
import {emptySearch} from '../../Store/Actions/emptySearchAction';

const ProfileTask = props => {
  const [isFloated, toggleFloatedPanel] = useState(false);
  const [showHiddenPanel, toggleHiddenPanel] = useState(false);
  const [showComponent, setShowComponent] = useState('');
  const [tabType, setTabType] = useState('list');
  const [threeDots, setThreeDots] = useState('');
  const [workspaceData, setWorkspaceData] = useState([]);

  const [analycticsSlider, setAnalycticsSlider] = useState(false);
  const [showLoader, setshowLoader] = useState(true);

  const dispatch = useDispatch();
  const getEmptySearch = useSelector(state => state.emptySearchReducer);

  // const [actionType, setActionType] = useState('');
  const [searchQry, setSearchQry] = useState('');
  const [searchFilter, setSearchFilterModal] = useState(false);
  const [checkFilterOption, setCheckFilterOption] = useState([1]);
  const [filterOptionValue, setFilterOptionValue] = useState(['All']);
  const [actionType, setActionType] = useState([1]);
  const [actionTypeValue, setActionTypeValue] = useState(['All']);
  const [s3howModel, setshowModel] = useState('');
  const [dataOfAction, setDataOfAction] = useState('');

  const ActionCategories = [
    {id: 1, title: 'All'},
    {id: 2, title: 'Me'},
    {id: 3, title: 'Watching'},
  ];

  const filterOption = [
    {id: 1, title: 'All'},
    {id: 2, title: 'Pending'},
    {id: 3, title: 'Completed'},
    {id: 4, title: 'Expired'},
  ];

  const handleSearchQry = q => {
    getActions(q);
  };

  const getActions = qry => {
    const actions = actionTypeValue.map(element => {
      let lowerCaseElement = element.toLowerCase();
      if (lowerCaseElement === 'expired') {
        lowerCaseElement = lowerCaseElement.slice(0, -1); // Remove the last character 'd'
      }
      return lowerCaseElement;
    });

    let options = [];
    if (props.filterOptionValue.length > 0) {
      options = props.filterOptionValue.map(element => {
        return element.toLowerCase();
      });
    }

    var Payload = {
      user_id: props.userData
        ? props.userData.data && props.userData.data.id
        : localStorage.getItem('id'),
      type: props.tabType
        ? ['other']
        : options === 'watching'
        ? ['other']
        : options.length > 0
        ? options
        : ['all'],
      search_query: searchQry,
      filter: actions === 'expired' ? ['expire'] : actions,
    };
    localStorage.setItem('action_paylaod', JSON.stringify(Payload));
    dispatch(getAllActions(Payload));

    setTimeout(() => {
      setshowLoader(false);
    }, 500);
  };

  const handleClickList = (id, value) => {
    setCheckFilterOption([id]);
    setFilterOptionValue([value]);
  };

  useEffect(() => {
    getActions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.hitApi, actionTypeValue, props.tabType, props.filterOptionValue]);

  useEffect(() => {
    getActions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showComponent, searchQry, props.userData]);

  useImperativeHandle(props.hitApiRef, () => ({
    getTasks() {
      getActions();
    },
  }));

  const handleType = e => {
    const {id, value} = e.target;

    setActionType(id);
    setActionTypeValue([value]);
  };

  const assigneDetail = item => {
    const userId = localStorage.getItem('id');
    const check = item.watchers_detail.some(obj => obj.id === userId);

    return item.created_by === userId && item.assigne !== '0' ? (
      <p className="p-reg m-0 actionsCategories single-line-text">
        From you to{' '}
        {fixName(item.assigne_detail.first_name, item.assigne_detail.last_name)}
      </p>
    ) : item.assigne === userId || check ? (
      <p className="p-reg m-0 actionsCategories single-line-text">
        For you from{' '}
        {fixName(item.creator_detail.first_name, item.creator_detail.last_name)}
      </p>
    ) : (
      <></>
    );
  };

  const actions = useSelector(state => state.AllActionsList);

  const [groupedTasks, setGroupedTasks] = useState({});

  // Group tasks by phase_title when component mounts or data changes
  useEffect(() => {
    const groupByPhase = actions.reduce((acc, task) => {
      // Check if phase already exists in groupedTasks
      if (!acc[task.phase_title]) {
        acc[task.phase_title] = [];
      }
      acc[task.phase_title].push(task);
      return acc;
    }, {});
    setGroupedTasks(groupByPhase);
  }, [actions]);

  const TaskList = [
    {
      id: '342',
      title: 'New Task',
      priority: 'medium',
      due_date: '2024-01-19 21:09:00',
      due_time: '21:09:00',
      description: 'Anything',
      association_id: '2515',
      association_type: 'profile',
      assigne: '1737',
      watchers: '',
      workspace_ids: '172',
      personal: 'yes',
      status: 'pending',
      images: '',
      created_by: '2515',
      created_at: '2024-01-01 05:10:12',
      updated_by: '0',
      updated_at: '2024-01-01 05:10:12',
      creator_detail: {
        id: '2515',
        first_name: 'Hello',
        last_name: 'World',
      },
      assigne_detail: {
        id: '1737',
        first_name: 'Free',
        last_name: 'Tickets',
      },
      watchers_detail: [],
      association_name: 'helloworld879321',
    },
  ];

  const taskSlider = {
    // className: "center",
    // centerMode: true,
    dots: false,
    arrows: false,
    infinite: false,
    // centerPadding : "60px",
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,

    responsive: [
      {
        breakpoint: 768, // This is the breakpoint for mobile devices (e.g., tablets)
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480, // This is the breakpoint for smaller mobile devices
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
    // adaptiveHeight: true,
  };

  const taskSliderData = [
    {
      taskStatus: 'Backlog',
    },

    {
      taskStatus: 'Pending',
    },
    {
      taskStatus: 'Awaiting QA',
    },
    {
      taskStatus: 'Complete',
    },
  ];

  useEffect(() => {
    if (showComponent !== '') {
      toggleHiddenPanel(true);
    } else {
      toggleHiddenPanel(false);
    }
  }, [showComponent]);

  const rewardSlider = {
    dots: false,
    arrows: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
  };
  const rewardSliderData = [
    {
      name: 'Completion',
      money: '2.4%',
      time: 'versus last month',
      svgIcon: rewardStar,
    },

    {
      name: 'High Impact',
      money: '85%',
      time: 'versus last month',
      svgIcon: rewardStar,
    },

    {
      name: 'Customer',
      money: '45%',
      time: 'versus last month',
      svgIcon: rewardStar,
    },
  ];

  const dynamicStyle = {
    padding: 0,
  };

  useEffect(() => {
    if (getEmptySearch || actionTypeValue) {
      setSearchQry('');
      dispatch(emptySearch(false));
    }
  }, [actionTypeValue, getEmptySearch]);

  return (
    <div className="">
      <div>
        {(props.threeDots || props.type === 'profile') && (
          <>
            <div className="person-search-wrapper  mt-0 messages-users-wrapper">
              <div className="search-form d-flex">
                <Form.Group className="search-input">
                  <Form.Control
                    type="text"
                    placeholder="Search"
                    value={searchQry}
                    onChange={e => {
                      setSearchQry(e.target.value);
                    }}
                  />
                </Form.Group>
                <Button
                  onClick={() => {
                    props.handleCommand('', 'task_filter');
                  }}>
                  <i className="icon-filter-icon" />
                </Button>
              </div>
            </div>
            <div className="mt-4">
              <div>
                <div className="category-btns-wrapper category-btns-wrapper-slider mt-0 mb-4">
                  <CustomSlidingButtons
                    items={filterOption}
                    activeButtons={actionType}
                    onItemClick={e => {
                      handleType(e);
                    }}
                    showItems={3}
                    value={actionTypeValue}
                  />
                </div>
              </div>
            </div>
          </>
        )}
      </div>

      {showLoader && (
        <div className="loader_hieght">{showLoader && <LoaderSpinner />}</div>
      )}

      <div className="task-list-waraper">
        {/* <div className="task-list-title mb-4">Backlog</div> */}
        {!showLoader && groupedTasks && Object.keys(groupedTasks)?.length > 0
          ? Object.keys(groupedTasks).map((phase, index) => (
              <div key={index}>
                <h2 style={{marginBottom: '6px'}}>{phase}</h2>
                {groupedTasks[phase]?.length > 0
                  ? groupedTasks[phase].map((item, index) => (
                      <div
                        key={index}
                        className="refferals-list action-listing as-link"
                        style={{border: '1px solid #f1eded'}}
                        onClick={() => {
                          props.handleCommand(item, 'followUp');

                          // setDataOfAction(item);
                          // setShowComponent('actionDetails');
                        }}>
                        <div className="task-item">
                          {/* <div className="red-bar"></div> */}
                          <h2 className="truncate_2_lines actions-title mb-2">
                            {/* {item.title} */}
                            {item.title}
                          </h2>
                          <div className="d-flex align-items-center session-cam mb-2">
                            {/* <img src={VideoGreyIcon} alt="video-cam" /> */}
                            <p className="p-reg m-0 single-line-text p-txt-area">
                              {/* {item.association_name} */}
                              {capitalizeFirst(item.priority)}
                            </p>
                          </div>
                          <p className="p-reg m-0 mb-2">
                            {/* {item.due_date !== '0000-00-00 00:00:00' &&
                    'Due ' + getRelativeDate(item.due_date)}
                  
                  {item.priority !== '' && (
                    <span>({capitalizeFirst(item.priority)} Priority)</span>
                  )} */}
                            {capitalizeFirst(item.status)}
                          </p>
                          <div
                            className="d-flex align-items-center justify-content-between"
                            style={{alignItems: 'center'}}>
                            <div className="d-flex align-items-center">
                              <p className="p-reg m-0 actionsCategories single-line-text">
                                {/* {item.association_name} */}
                                {assigneDetail(item)}
                              </p>
                            </div>

                            <div className="arrow-up">
                              {/* <img
                                        height={20}
                                        width={20}
                                        src={arrow_up}
                                        alt="checkmark"
                                      />
                                      <span>21</span> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    ))
                  : !showLoader && (
                      <div className="no-results-wrapper payment-no-results no-record-notification">
                        <div className="img-bg-wrapper">
                          <img
                            src={require('../../Assets/images/norecord/no-actions.png')}
                            alt=""
                          />
                        </div>

                        <div className="no-results-content">
                          {/* <h2>{labels.Actions_Pending[selected_lang]}</h2> */}
                          <h2>{`No Tasks ${
                            actionTypeValue.includes('All')
                              ? 'Available'
                              : actionTypeValue
                          }`}</h2>
                          <p>{labels.NoAction_Desc[selected_lang]}</p>
                          <div className="btn-wrapper mt-3">
                            <Button
                              className="btn btn-dark"
                              onClick={() => {
                                props.handleCommand &&
                                  props.handleCommand('', 'create_task');
                              }}>
                              Create Task
                            </Button>
                          </div>
                        </div>
                      </div>
                    )}
              </div>
            ))
          : !showLoader && (
              <div className="no-results-wrapper payment-no-results no-record-notification">
                <div className="img-bg-wrapper">
                  <img
                    src={require('../../Assets/images/norecord/no-actions.png')}
                    alt=""
                  />
                </div>

                <div className="no-results-content">
                  {/* <h2>{labels.Actions_Pending[selected_lang]}</h2> */}
                  <h2>{`No Tasks ${
                    actionTypeValue.includes('All')
                      ? 'Available'
                      : actionTypeValue
                  }`}</h2>
                  <p>{labels.NoAction_Desc[selected_lang]}</p>
                  <div className="btn-wrapper mt-3">
                    <Button
                      className="btn btn-dark"
                      onClick={() => {
                        props.handleCommand &&
                          props.handleCommand('', 'create_task');
                      }}>
                      Create Task
                    </Button>
                  </div>
                </div>
              </div>
            )}

        {/* <div className="task-list-title mb-4">Complete</div>

                          <div className="task-list-title mb-4">
                            Awaiting QA
                          </div>

                          <div className="task-list-title mb-4">Pending</div> */}
      </div>
    </div>
  );
};

export default ProfileTask;
