import React, {useEffect, useRef, useState} from 'react';
import {Form} from 'react-bootstrap';
import CustomSlidingButtons from '../CustomSlidingButtons';
import TextareaAutosize from 'react-textarea-autosize';
import SlidingPanel from '../SlidingPanel';
import {RoiLogo} from '../../Constants/Images';
import Scrollbars from 'react-custom-scrollbars';
import commonApi from '../../Services/CommonService';
import {fixName} from '../../Utils/commonUtils';
import LoaderSpinner from '../Modals/LoaderSpinner';
import {DEFAULT_IMAGE} from '../../Utils/env';
import {getTimeFormatForChat} from '../../Services/AppServices';
import ProfileComponent from '../ProfilePage/ProfileComponent';
import {errorMessage} from '../../Utils/ToastMessages';
import ConfirmationModel from '../ConfirmationModel/ConfirmationModel';
import SendInvitation from '../../Pages/Front/StaticPages/SendInvitation';

const CallMessages = props => {
  const {onClose, userChat, handleLastMsg, phoneNumber} = props;

  const [showComponent, setShowComponent] = useState('');
  const [filterType, setFilterType] = useState('All');
  const [writtenMsg, setWrittenMsg] = useState('');
  const [messageBody, setMessageBody] = useState([]);
  const [showSpinner, setShowSpinner] = useState(true);
  const [showChatLoader, setShowChatLoader] = useState(false);
  const [userData, setUserData] = useState({});
  const [checkUserHitApi, setCheckUserHitApi] = useState(false);
  const [noNeedShowInvite, setNoNeedShowInvite] = useState(false);
  const scrollRef = useRef(null);

  const TabList = [
    {id: 'All', title: 'All'},
    {id: 'Images', title: 'Images'},
    {id: 'Document', title: 'Document'},
    {id: 'Videos', title: 'Videos'},
    {id: 'Text', title: 'Text'},
  ];

  const handleType = e => {
    const {id, value} = e.target;
    setFilterType(value);
  };

  const renderProjeectButtons = profile => (
    <>
      <img src={RoiLogo} alt="" />
    </>
  );

  const send_twilio_message = message => {
    const payload = {
      receiver_number: userChat?.phone ? userChat?.phone : phoneNumber,
      message: message,
      receiver_id: userChat?.id,
      type: 'text',
    };
    commonApi
      .send_twilio_message(payload)
      .then(res => {
        if (res?.status === 200) {
          if (res?.success === false) {
            errorMessage(res?.message);
          } else {
            setMessageBody([...messageBody, res?.message]);
            handleLastMsg(res, userChat);

            setWrittenMsg('');
          }
        }
      })
      .catch(err => {
      
        return {type: 'error', message: err.message};
      });
  };
  const get_twilio_chat_messages = () => {
    

    const payload = {
      other_user_number: phoneNumber ? phoneNumber : userChat?.phone,
      search_query: '',
      filter_type: filterType.toLowerCase(), // Options: "all", "image", "document", "video", "text"
    };

    commonApi
      .get_twilio_chat_messages(payload)
      .then(res => {
        if (res.status === 200) {
          setShowSpinner(false);
          setShowChatLoader(false);
          setMessageBody(res?.data.messages);
          setUserData(res?.data?.users);
        }
      })
      .catch(err => {
        setShowSpinner(false);
        setShowChatLoader(false);
        
        return {type: 'error', message: err.message};
      });
  };

  useEffect(() => {
    get_twilio_chat_messages();
    setShowChatLoader(true);
  }, [userChat, filterType]);
  useEffect(() => {
    get_twilio_chat_messages();
  }, [checkUserHitApi]);

  const saveMessage = value => {
    if (userData?.other_user === null && !noNeedShowInvite) {
      setShowComponent('inviteModl');
    }
    send_twilio_message(value);
  };

  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollToBottom();
    }
  }, [messageBody]);

  const get_info = (msg, usersdetail) => {
    if (msg?.sender_id && msg?.sender_id === localStorage.getItem('id')) {
      const userInfo = usersdetail.current_user;
      return {
        userDetail: userInfo,
        type: 'sender',
      };
    } else {
      const userInfo = usersdetail?.other_user;
      return {
        userDetail: userInfo,
        type: 'receiver',
      };
    }
  };

  return (
    <>
      <>
        <SlidingPanel
          hidePanel={() => {
            onClose();
          }}
          renderData={renderProjeectButtons}
          showBlackHeader={true}
          lastDivStyle={{
            borderRadius: '35px',
            background: '#F7F6F2',
          }}
          firstDivStyle={{
            padding: 0,
            background:
              'linear-gradient(to bottom, #424242, #424242, #f7f6f2 50%)' /*linear-gradient(to bottom, black, white)*/,
          }}>
          {showSpinner && <LoaderSpinner />}
          <div className="messages-wrapper fix-width-forms message-chat-box-holder">
            <div className="mt-2">
              <div className="profile-links-btns success-decline-btn-block" />
              <div className="message-profile-heading-nospace-block message-profile-smart-chat-head-block">
                <div className="messages-profile-heading message-profile-heading-nospace no-space">
                  <div
                    className="profile-img as-link"
                    onClick={() => {
                      setShowComponent('profile-component');
                    }}>
                    {' '}
                    <img
                      src={
                        userData?.other_user?.profile_img
                          ? userData?.other_user?.profile_img
                          : DEFAULT_IMAGE
                      }
                      alt=""
                      className="shadow-none"
                    />
                  </div>
                  <div className="profile-content">
                    <div className="user-name my-username">
                      <h2 className="">
                        {userData?.other_user &&
                          userData?.other_user?.first_name &&
                          fixName(
                            userData?.other_user?.first_name,
                            userData?.other_user?.last_name,
                          )}
                        {userData?.other_user === null && userChat?.phone ? userChat?.phone :userData?.other_user === null  &&  phoneNumber}
                      </h2>
                    </div>
                  </div>
                </div>
                {/* <div className="profile-head-icons-block">
                <span className="icon threeDots">
                  <i className="fas fa-ellipsis-vertical" />
                </span>
              </div> */}
              </div>
            </div>
            <div className="category-btns-wrapper category-btns-wrapper-slider mt-2 mb-4">
              <CustomSlidingButtons
                items={TabList}
                activeButtons={filterType}
                onItemClick={e => {
                  handleType(e);
                }}
                showItems={4}
                value={filterType}
              />
            </div>

            <Scrollbars ref={scrollRef}>
              <div className="messages" style={{paddingRight: '17px'}}>
                {showChatLoader && <LoaderSpinner />}
                {!showChatLoader && (
                  <div className="messages-list">
                    {/* <div className="message-day-saparator">23/08/2024</div> */}
                    {messageBody &&
                      messageBody.length > 0 &&
                      messageBody.map((message, index) => {
                        const User_info = get_info(message, userData);

                        return (
                          <div
                            key={index}
                            className={`message-item new-msg-item   ${
                              User_info?.type === 'sender' && 'reply-message'
                            }`}>
                            <div className="msg-container">
                              <div className="msg-content">
                                {User_info.type !== 'sender' && (
                                  <div
                                    className="msg-content-left"
                                    onClick={() => {
                                      setShowComponent('profile-component');
                                    }}>
                                    <div className="msg-usr-img ">
                                      <img
                                        src={User_info?.userDetail?.profile_img}
                                        alt=""
                                        className="shadow-none"
                                      />
                                    </div>
                                  </div>
                                )}
                                <div
                                  className={`msg-content-right ${
                                    User_info?.type === 'sender' &&
                                    'msg-smartchat-contnt-right'
                                  }`}>
                                  <h2>
                                    {User_info.type === 'receiver' && User_info?.userDetail === null ? 
                                   phoneNumber ? phoneNumber : userChat?.phone:
                                    fixName(
                                      User_info?.userDetail?.first_name,
                                      User_info?.userDetail?.last_name,
                                    )}{' '}
                                    <span className="msg-date">
                                      {getTimeFormatForChat(message?.timestamp)}
                                    </span>
                                  </h2>
                                  <div className="msg-content-text">
                                    <p>{message?.message}</p>
                                    <div className="emoji-icons-wrapper">
                                      <div className="emoji-icons d-flex align-items-center" />
                                    </div>
                                    <div className="snapshot-imgs-wrapper" />
                                  </div>
                                </div>
                              </div>

                              <div className="msg-user-replies-wrapper" />
                            </div>
                          </div>
                        );
                      })}
                  </div>
                )}
                <div />
              </div>
            </Scrollbars>

            <div className="message-form message-smartchat-form">
              <input
                type="file"
                accept="video/*"
                name="myImage"
                style={{display: 'none'}}
              />
              <span className="form-icon text-yellow">
                <i className="fas fa-ellipsis-vertical" />
              </span>
              <div className="form-input">
                <Form.Group className="form-input">
                  <TextareaAutosize
                    maxRows={4}
                    minRows={1}
                    className="form-control"
                    name="message"
                    value={writtenMsg}
                    onKeyDown={e => {
                      if (e.key === 'Enter' && !e.shiftKey) {
                        e.preventDefault();
                        if (writtenMsg.trim() === '') {
                          errorMessage("Can't send empty message.");
                        } else {
                          saveMessage(e.target.value);
                        }
                      }
                    }}
                    onChange={e => {
                      setWrittenMsg(e.target.value);
                    }}
                    placeholder="Type your message here"
                  />
                </Form.Group>
              </div>
              <span
                onClick={() => {
                  if (writtenMsg.trim() === '') {
                    errorMessage("Can't send empty message.");
                    setWrittenMsg('');
                  } else {
                    saveMessage(writtenMsg);
                  }
                }}
                className="form-icon send-btn">
                <i className="fa-solid icon-plane" />
              </span>
            </div>
          </div>
        </SlidingPanel>
        {showComponent === 'profile-component' && (
          <ProfileComponent
            userId={userChat?.id}
            show={false}
            closeModal={() => {
              setShowComponent('');
            }}
            onClose={() => {
              setShowComponent('');
            }}
            userData={[]}
            moderator={false}
            // setParticipantType={setParticipantType}
            VoxeetSDK={[]}
            conferenceInfo={[]}
            jdSpecialM={[]}
            jdSpecialA={[]}
          />
        )}
        {showComponent === 'inviteModl' && (
          <ConfirmationModel
            closeModel={() => {
              setShowComponent('');
              setNoNeedShowInvite(true);
            }}
            discription={'Will you invite this user in app ?'}
            buttonOne={'Yes'}
            buttonTwo={'No'}
            ButtonOneAction={() => {
              setShowComponent('inviteOutSideTheBuild');
              // setShowComponent('');
            }}
          />
        )}

        {showComponent === 'inviteOutSideTheBuild' && (
          <SendInvitation
            onClose={() => {
              setShowComponent('');
              setNoNeedShowInvite(true);
            }}
            phoneNumber={phoneNumber ? phoneNumber : userChat?.phone}
            onCloseApiHit={() => {
              get_twilio_chat_messages();
            }}
          />
        )}
      </>
    </>
  );
};

export default CallMessages;
