import React, {useState, useEffect} from 'react';
import {capitalizeFirst} from '../../Utils/commonUtils';
import {
  Button,
  Form,
  Dropdown,
  Accordion,
  Col,
  Container,
  Row,
} from 'react-bootstrap';
import {errorMessage} from '../../Utils/ToastMessages';
import {selected_lang} from '../../Utils/env';
import {labels} from '../../Constants/Translations';
import LoaderSpinner from '../Modals/LoaderSpinner';

const PipelinePermission = props => {
  const [tabType, setTabType] = useState('pipeline');
  const [type, setType] = useState(props.aliasRoles[0].id);
  const [aliasItem, setAliasItem] = useState(props.aliasRoles[0]);
  const [deleteItem, setDeleteItem] = useState({});
  //   const [aliasRolesIds, setAliasRolesIds] = useState(props.aliasRoles.map(({id}) => id));
  const [showLoader, setshowLoader] = useState(false);
  const [aliasRolesIds, setAliasRolesIds] = useState(
    props.systemAliasRoles.length > 0
      ? props.systemAliasRoles.map(({id}) => id)
      : [],
  );
  const [tagAliasRolesIds, setTagAliasRolesIds] = useState(
    props.tagPermissionRoles.length > 0
      ? props.tagPermissionRoles.map(({id}) => id)
      : [],
  );
  const [filedsAliasRolesIds, setFieldsAliasRolesIds] = useState(
    props.fieldPermessions.length > 0
      ? props.fieldPermessions.map(({id}) => id)
      : [],
  );
  const [formsAliasRolesIds, setFormsAliasRolesIds] = useState(
    props.formPermessions.length > 0
      ? props.formPermessions.map(({id}) => id)
      : [],
  );
  const [systemAliasRoles, setSystemAliasRoles] = useState([]);
  const [tagsPermissionRoles, setTagsPermissionRoles] = useState([]);
  const [ownProfilePermesion, setOwnProfilePermesion] = useState(false);

  const addAliasRole = alias => {
    if (!aliasRolesIds.includes(alias)) {
      setAliasRolesIds(previous => [...previous, alias]);
      props.setSystemAliasRoles(previous => [
        ...previous,
        {
          ...aliasItem,
          create_pipeline: true,
          associate_pipeline: true,
          show_pipeline: true,
        },
      ]);
    } else {
      errorMessage('You have already added this Role');
    }
  };

  const addTagsAliasRole = alias => {
    if (!tagAliasRolesIds.includes(alias)) {
      setTagAliasRolesIds(previous => [...previous, alias]);
      props.setTagPermissionRoles(previous => [
        ...previous,
        {
          ...aliasItem,
          create_tag: true,
          show_tag: true,
          associate_tag: true,
        },
      ]);
    } else {
      errorMessage('You have already added this Role');
    }
  };

  const removeAliasRole = item => {
    props.setSystemAliasRoles(previous =>
      previous.filter(MiniItem => MiniItem.id !== item.id),
    );
    setAliasRolesIds(previous =>
      previous.filter(MiniItem => MiniItem !== item.id),
    );

    props.setShowModel('');
  };
  const removeFormRole = item => {
    props.setFormPermessions(previous =>
      previous.filter(MiniItem => MiniItem.id !== item.id),
    );
    setFormsAliasRolesIds(previous =>
      previous.filter(MiniItem => MiniItem !== item.id),
    );

    props.setShowModel('');
  };
  const removeFieldRole = item => {
    props.setFieldPermessions(previous =>
      previous.filter(MiniItem => MiniItem.id !== item.id),
    );
    setFieldsAliasRolesIds(previous =>
      previous.filter(MiniItem => MiniItem !== item.id),
    );

    props.setShowModel('');
  };
  const removeTagsPermission = item => {
    props.setTagPermissionRoles(previous =>
      previous.filter(MiniItem => MiniItem.id !== item.id),
    );
    setTagAliasRolesIds(previous =>
      previous.filter(MiniItem => MiniItem !== item.id),
    );

    props.setShowModel('');
  };

  useEffect(() => {
    setshowLoader(true);
    setTimeout(() => {
      setshowLoader(false);
    }, 1500);
  }, [tabType]);

  const handleAliasPermissions = (type, item) => {
    const updatedAliasItems = props.systemAliasRoles.map(aliasItem => {
      if (aliasItem.id === item.id) {
        const updatedItem = {...aliasItem};

        const toggleAll = value => {
          updatedItem.create_pipeline = value;
          updatedItem.associate_pipeline = value;
          updatedItem.show_pipeline = value;
        };

        switch (type) {
          case 'create':
            // if (updatedItem.show_pipeline) {
            updatedItem.create_pipeline = !updatedItem.create_pipeline;
            updatedItem.associate_pipeline =
              updatedItem.create_pipeline || !updatedItem.associate_pipeline;
            updatedItem.show_pipeline =
              updatedItem.create_pipeline || updatedItem.associate_pipeline;
            // }
            break;

          case 'update':
            if (updatedItem.create_pipeline) {
              toggleAll(false);
            } else {
              updatedItem.associate_pipeline = !updatedItem.associate_pipeline;
              updatedItem.show_pipeline = updatedItem.associate_pipeline;
              updatedItem.create_pipeline = !updatedItem.create_pipeline;
            }
            break;

          case 'read':
            updatedItem.show_pipeline = !updatedItem.show_pipeline;
            if (!updatedItem.show_pipeline) toggleAll(false);
            break;

          default:
            break;
        }

        return updatedItem;
      }
      return aliasItem;
    });

    props.setSystemAliasRoles(updatedAliasItems);
  };
  const handleFormPermissions = (type, item) => {
    const updatedAliasItems = props.formPermessions.map(aliasItem => {
      if (aliasItem.id === item.id) {
        const updatedItem = {...aliasItem};

        const toggleAll = value => {
          updatedItem.create_form = value;
          updatedItem.associate_form = value;
          updatedItem.show_form = value;
        };

        switch (type) {
          case 'create':
            // if (updatedItem.show_pipeline) {
            updatedItem.create_form = !updatedItem.create_form;
            updatedItem.associate_form =
              updatedItem.create_form || !updatedItem.associate_form;
            updatedItem.show_form =
              updatedItem.create_form || updatedItem.associate_form;
            // }
            break;

          case 'update':
            if (updatedItem.create_form) {
              toggleAll(false);
            } else {
              updatedItem.associate_form = !updatedItem.associate_form;
              updatedItem.show_form = updatedItem.associate_form;
              updatedItem.create_form = !updatedItem.create_form;
            }
            break;

          case 'read':
            updatedItem.show_form = !updatedItem.show_form;
            if (!updatedItem.show_form) toggleAll(false);
            break;

          default:
            break;
        }

        return updatedItem;
      }
      return aliasItem;
    });

    props.setFormPermessions(updatedAliasItems);
  };
  const handleFieldPermissions = (type, item) => {
    const updatedAliasItems = props.fieldPermessions.map(aliasItem => {
      if (aliasItem.id === item.id) {
        const updatedItem = {...aliasItem};

        const toggleAll = value => {
          updatedItem.create_field = value;
          updatedItem.associate_field = value;
          updatedItem.show_field = value;
        };

        switch (type) {
          case 'create':
            // if (updatedItem.show_pipeline) {
            updatedItem.create_field = !updatedItem.create_field;
            updatedItem.associate_field =
              updatedItem.create_field || !updatedItem.associate_field;
            updatedItem.show_field =
              updatedItem.create_field || updatedItem.associate_field;
            // }
            break;

          case 'update':
            if (updatedItem.create_field) {
              toggleAll(false);
            } else {
              updatedItem.associate_field = !updatedItem.associate_field;
              updatedItem.show_field = updatedItem.associate_field;
              updatedItem.create_field = !updatedItem.create_field;
            }
            break;

          case 'read':
            updatedItem.show_field = !updatedItem.show_field;
            if (!updatedItem.show_field) toggleAll(false);
            break;

          default:
            break;
        }

        return updatedItem;
      }
      return aliasItem;
    });

    props.setFieldPermessions(updatedAliasItems);
  };

  const handleTagsPermissions = (type, item) => {
    const updatedAliasItems = props.tagPermissionRoles.map(aliasItem => {
      if (aliasItem.id === item.id) {
        const updatedItem = {...aliasItem};

        switch (type) {
          case 'create':
            updatedItem.create_tag = !updatedItem.create_tag;
            updatedItem.associate_tag =
              !updatedItem.associate_tag || updatedItem.create_tag;

            updatedItem.show_tag =
              updatedItem.create_tag || updatedItem.associate_tag;
            break;

          case 'update':
            if (updatedItem.create_tag) {
              updatedItem.create_tag = false;
              updatedItem.associate_tag = false;
              updatedItem.show_tag = false;
            } else {
              updatedItem.associate_tag = !updatedItem.associate_tag;
              updatedItem.show_tag = updatedItem.associate_tag;
              updatedItem.create_tag = !updatedItem.create_tag;
            }
            break;

          case 'read':
            updatedItem.show_tag = !updatedItem.show_tag;
            if (!updatedItem.show_tag) {
              updatedItem.associate_tag = false;
              updatedItem.create_tag = false;
            }
            break;
          case 'profile':
            updatedItem.own_profile_permission =
              !updatedItem.own_profile_permission;
            break;

          default:
            break;
        }

        return updatedItem;
      }
      return aliasItem;
    });

    props.setTagPermissionRoles(updatedAliasItems);
  };

  useEffect(() => {
    if (
      props.deleteCatalog &&
      Object.keys(deleteItem).length > 0 &&
      tabType === 'tags'
    ) {
      removeTagsPermission(deleteItem);
    } else if (
      props.deleteCatalog &&
      Object.keys(deleteItem).length > 0 &&
      tabType === 'pipeline'
    ) {
      removeAliasRole(deleteItem);
    } else if (
      props.deleteCatalog &&
      Object.keys(deleteItem).length > 0 &&
      tabType === 'forms'
    ) {
      // alert('1')
      removeFormRole(deleteItem);
    } else if (
      props.deleteCatalog &&
      Object.keys(deleteItem).length > 0 &&
      tabType === 'fields'
    ) {
      removeFieldRole(deleteItem);
    }

    props.setDeleteCatalog(false);
    setDeleteItem({});
  }, [props.deleteCatalog]);

  // const handleTagsPermissions = (type, item) => {
  //   const updatedAliasItems = tagsPermissionRoles.map(aliasItem => {
  //     if (aliasItem.id === item.id) {
  //       const updatedItem = {...aliasItem};

  //       if (type === 'create') {
  //         updatedItem.create_pipeline = !updatedItem.create_pipeline;
  //       } else if (type === 'update') {
  //         updatedItem.associate_pipeline = !updatedItem.associate_pipeline;
  //       } else if (type === 'read') {
  //         updatedItem.show_pipeline = !updatedItem.show_pipeline;
  //       }

  //       return updatedItem;
  //     }
  //     return aliasItem;
  //   });

  //   setTagsPermissionRoles(updatedAliasItems);
  // };

  const addFieldsRoles = alias => {
    if (!filedsAliasRolesIds.includes(alias)) {
      setFieldsAliasRolesIds(previous => [...previous, alias]);
      props.setFieldPermessions(previous => [
        ...previous,
        {
          ...aliasItem,
          create_field: true,
          show_field: true,
          associate_field: true,
        },
      ]);
    } else {
      errorMessage('You have already added this Role');
    }
  };
  const addFormsRoles = alias => {
    if (!formsAliasRolesIds.includes(alias)) {
      setFormsAliasRolesIds(previous => [...previous, alias]);
      props.setFormPermessions(previous => [
        ...previous,
        {
          ...aliasItem,
          create_form: true,
          show_form: true,
          associate_form: true,
        },
      ]);
    } else {
      errorMessage('You have already added this Role');
    }
  };

  
  return (
    <div className="company-info-screen system_role_screen pipleine_permission_screen">
      <div>
        <div className="slider3-container">
          <div className="messages-header mb-2">
            <div className="event-tabs tabs-style-2 mb-2">
              {' '}
              <ul>
                <li>
                  <Button
                    className={tabType === 'pipeline' ? 'active' : ''}
                    onClick={() => {
                      // props.getPipeLineTagsPermissions('pipeline');
                      setTabType('pipeline');
                    }}>
                    {labels.Funnel[selected_lang]}
                  </Button>
                </li>
                <li>
                  <Button
                    className={tabType === 'tags' ? 'active' : ''}
                    onClick={() => {
                      // props.getPipeLineTagsPermissions('tags');
                      setTabType('tags');
                    }}>
                    {labels.Tags[selected_lang]}
                  </Button>
                </li>
                <li>
                  <Button
                    className={tabType === 'fields' ? 'active' : ''}
                    onClick={() => {
                      // props.getPipeLineTagsPermissions('fields');
                      setTabType('fields');
                    }}>
                    {labels.FIELDS[selected_lang]}
                  </Button>
                </li>
                <li>
                  <Button
                    className={tabType === 'forms' ? 'active' : ''}
                    onClick={() => {
                      // props.getPipeLineTagsPermissions('forms');
                      setTabType('forms');
                    }}>
                    {labels.Forms[selected_lang]}
                  </Button>
                </li>
              </ul>
            </div>
          </div>

          <div>
            {tabType === 'pipeline' && (
              <div className="slider2-inner-container">
                <div
                  className="scheduler-dropdown"
                  style={{width: '230px', flex: 'none'}}>
                  {Object.entries(props.aliasRoles).length > 0 && (
                    <Dropdown
                      className="select-wrapper"
                      onSelect={(eventKey, event) => {
                        var item = JSON.parse(eventKey);
                        setType(item.id);
                        setAliasItem(item);
                      }}
                      drop="down">
                      <Dropdown.Toggle variant="primary">
                        {type
                          ? capitalizeFirst(
                              props.aliasRoles.find(item => item.id === type)
                                ?.role_alias,
                            )
                          : 'Select Role'}
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        {props.aliasRoles.map((item, index) => (
                          <Dropdown.Item
                            key={`sessionCategories-${index}`}
                            eventKey={JSON.stringify(item)}
                            active={item.id === type}>
                            {capitalizeFirst(item.role_alias)}
                          </Dropdown.Item>
                        ))}
                      </Dropdown.Menu>
                    </Dropdown>
                  )}
                </div>

                <div className="btn-add-type">
                  <Button
                    style={{padding: '11px 27px'}}
                    className="new-btn btn-green "
                    onClick={() => {
                      if (tabType === 'pipeline') {
                        addAliasRole(type);
                      }
                    }}>
                    Add Role
                  </Button>
                </div>
              </div>
            )}

            {(tabType === 'tags' ||
              tabType === 'forms' ||
              tabType === 'fields') && (
              <div className="slider2-inner-container">
                <div
                  className="scheduler-dropdown"
                  style={{width: '230px', flex: 'none'}}>
                  {Object.entries(props.tagAliasRoles).length > 0 && (
                    <Dropdown
                      className="select-wrapper"
                      onSelect={(eventKey, event) => {
                        var item = JSON.parse(eventKey);
                        setType(item.id);
                        setAliasItem(item);
                      }}
                      drop="down">
                      <Dropdown.Toggle variant="primary">
                        {type
                          ? capitalizeFirst(
                              props.tagAliasRoles.find(item => item.id === type)
                                ?.role_alias,
                            )
                          : 'Select Role'}
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        {props.tagAliasRoles.map((item, index) => (
                          <Dropdown.Item
                            key={`sessionCategories-${index}`}
                            eventKey={JSON.stringify(item)}
                            active={item.id === type}>
                            {capitalizeFirst(item.role_alias)}
                          </Dropdown.Item>
                        ))}
                      </Dropdown.Menu>
                    </Dropdown>
                  )}
                </div>

                <div className="btn-add-type">
                  <Button
                    style={{padding: '11px 27px'}}
                    className="new-btn btn-green "
                    onClick={() => {
                      if (tabType === 'tags') {
                        addTagsAliasRole(type);
                      } else if (tabType === 'forms') {
                        addFormsRoles(type);
                      } else if (tabType === 'fields') {
                        addFieldsRoles(type);
                      }
                    }}>
                    Add Role
                  </Button>
                </div>
              </div>
            )}
          </div>
          <div className="slider-3-roles-conatiner">
          {/* {showLoader && <LoaderSpinner />} */}

          {tabType === 'pipeline' && props.systemAliasRoles.length > 0 && (
          

              <div className="mt-3">
                <div className="system-role-list">
                  <Container className="mb-3">
                    <Row>
                      <Col md={4} className="f_700">
                        {' '}
                        Roles
                      </Col>

                      <Col
                        md={4}
                        className="f_700"
                        style={{paddingLeft: '1.5em'}}>
                        Permissions{' '}
                      </Col>
                    </Row>
                  </Container>

                  <Container>
                    {Object.entries(props.systemAliasRoles).length > 0 &&
                      props.systemAliasRoles.map((item, index) => (
                        <Row key={`r-item-${index}`}>
                          <Col md={3} className="role-row align_item_center">
                            {' '}
                            {capitalizeFirst(item.role_alias)}
                          </Col>

                          <Col
                            md={6}
                            className="role-row align_item_center pb-0">
                            <div className="alias_permission_flex">
                              <Form.Group className="fieldset payment-checkbox m-0">
                                <Form.Check
                                  type={'checkbox'}
                                  label={'Read'}
                                  id={`Read_${index}_${item.id}`}
                                  // value={'read'}
                                  checked={item.show_pipeline}
                                  onChange={() => {
                                    handleAliasPermissions('read', item);
                                  }}
                                />
                              </Form.Group>

                              <Form.Group className="fieldset payment-checkbox m-0">
                                <Form.Check
                                  type={'checkbox'}
                                  label={'Update'}
                                  id={`Update_${index}_${item.id}`}
                                  // value={'update'}
                                  checked={item.associate_pipeline}
                                  onChange={() => {
                                    handleAliasPermissions('update', item);
                                  }}
                                />
                              </Form.Group>

                              <Form.Group className=" form-horizontal fieldset payment-checkbox m-0">
                                <Form.Check
                                  type={'checkbox'}
                                  label={'Create'}
                                  id={`Create_${index}_${item.id}`}
                                  // value={'create'}
                                  checked={item.create_pipeline}
                                  onChange={() => {
                                    handleAliasPermissions('create', item);
                                  }}
                                />
                              </Form.Group>
                            </div>
                          </Col>

                          <Col
                            md={1}
                            className="role-row d-flex justify-content-between align-items-center">
                            {' '}
                            <span
                              className="as-link"
                              onClick={() => {
                                props.setShowModel('deleteItem');
                                setDeleteItem(item);
                                // removeAliasRole(item);
                              }}>
                              <i
                                class="fa-solid fa-xmark"
                                style={{
                                  color: '#d71616',
                                  fontWeight: 'bold',
                                  fontSize: '22px',
                                }}></i>
                            </span>{' '}
                          </Col>
                        </Row>
                      ))}
                  </Container>
                </div>
              </div>
          
          )}
          {tabType === 'forms' && props.formPermessions.length > 0 && (
          

              <div className="mt-3">
                <div className="system-role-list">
                  <Container className="mb-3">
                    <Row>
                      <Col md={4} className="f_700">
                        {' '}
                        Roles
                      </Col>

                      <Col
                        md={4}
                        className="f_700"
                        style={{paddingLeft: '1.5em'}}>
                        Permissions{' '}
                      </Col>
                    </Row>
                  </Container>

                  <Container>
                    {Object.entries(props.formPermessions).length > 0 &&
                      props.formPermessions.map((item, index) => (
                        <Row key={`r-item-${index}`}>
                          <Col md={3} className="role-row align_item_center">
                            {' '}
                            {capitalizeFirst(item.role_alias)}
                          </Col>

                          <Col
                            md={6}
                            className="role-row align_item_center pb-0">
                            <div className="alias_permission_flex">
                              <Form.Group className="fieldset payment-checkbox m-0">
                                <Form.Check
                                  type={'checkbox'}
                                  label={'Read'}
                                  id={`Read_${index}_${item.id}`}
                                  // value={'read'}
                                  checked={item.show_form}
                                  onChange={() => {
                                    handleFormPermissions('read', item);
                                  }}
                                />
                              </Form.Group>

                              <Form.Group className="fieldset payment-checkbox m-0">
                                <Form.Check
                                  type={'checkbox'}
                                  label={'Update'}
                                  id={`Update_${index}_${item.id}`}
                                  // value={'update'}
                                  checked={item.associate_form}
                                  onChange={() => {
                                    handleFormPermissions('update', item);
                                  }}
                                />
                              </Form.Group>

                              <Form.Group className=" form-horizontal fieldset payment-checkbox m-0">
                                <Form.Check
                                  type={'checkbox'}
                                  label={'Create'}
                                  id={`Create_${index}_${item.id}`}
                                  // value={'create'}
                                  checked={item.create_form}
                                  onChange={() => {
                                    handleFormPermissions('create', item);
                                  }}
                                />
                              </Form.Group>
                            </div>
                          </Col>

                          <Col
                            md={1}
                            className="role-row d-flex justify-content-between align-items-center">
                            {' '}
                            <span
                              className="as-link"
                              onClick={() => {
                                props.setShowModel('deleteItem');
                                setDeleteItem(item);
                                // removeAliasRole(item);
                              }}>
                              <i
                                class="fa-solid fa-xmark"
                                style={{
                                  color: '#d71616',
                                  fontWeight: 'bold',
                                  fontSize: '22px',
                                }}></i>
                            </span>{' '}
                          </Col>
                        </Row>
                      ))}
                  </Container>
                </div>
              </div>
           
          )}
          {tabType === 'fields' && props.fieldPermessions.length > 0 && (
         

              <div className="mt-3">
                <div className="system-role-list">
                  <Container className="mb-3">
                    <Row>
                      <Col md={4} className="f_700">
                        {' '}
                        Roles
                      </Col>

                      <Col
                        md={4}
                        className="f_700"
                        style={{paddingLeft: '1.5em'}}>
                        Permissions{' '}
                      </Col>
                    </Row>
                  </Container>

                  <Container>
                    {Object.entries(props.fieldPermessions).length > 0 &&
                      props.fieldPermessions.map((item, index) => (
                        <Row key={`r-item-${index}`}>
                          <Col md={3} className="role-row align_item_center">
                            {' '}
                            {capitalizeFirst(item.role_alias)}
                          </Col>

                          <Col
                            md={6}
                            className="role-row align_item_center pb-0">
                            <div className="alias_permission_flex">
                              <Form.Group className="fieldset payment-checkbox m-0">
                                <Form.Check
                                  type={'checkbox'}
                                  label={'Read'}
                                  id={`Read_${index}_${item.id}`}
                                  // value={'read'}
                                  checked={item.show_field}
                                  onChange={() => {
                                    handleFieldPermissions('read', item);
                                  }}
                                />
                              </Form.Group>

                              <Form.Group className="fieldset payment-checkbox m-0">
                                <Form.Check
                                  type={'checkbox'}
                                  label={'Update'}
                                  id={`Update_${index}_${item.id}`}
                                  // value={'update'}
                                  checked={item.associate_field}
                                  onChange={() => {
                                    handleFieldPermissions('update', item);
                                  }}
                                />
                              </Form.Group>

                              <Form.Group className=" form-horizontal fieldset payment-checkbox m-0">
                                <Form.Check
                                  type={'checkbox'}
                                  label={'Create'}
                                  id={`Create_${index}_${item.id}`}
                                  // value={'create'}
                                  checked={item.create_field}
                                  onChange={() => {
                                    handleFieldPermissions('create', item);
                                  }}
                                />
                              </Form.Group>
                            </div>
                          </Col>

                          <Col
                            md={1}
                            className="role-row d-flex justify-content-between align-items-center">
                            {' '}
                            <span
                              className="as-link"
                              onClick={() => {
                                props.setShowModel('deleteItem');
                                setDeleteItem(item);
                                // removeAliasRole(item);
                              }}>
                              <i
                                class="fa-solid fa-xmark"
                                style={{
                                  color: '#d71616',
                                  fontWeight: 'bold',
                                  fontSize: '22px',
                                }}></i>
                            </span>{' '}
                          </Col>
                        </Row>
                      ))}
                  </Container>
                </div>
              </div>
          
          )}

          {tabType === 'tags' && props.tagPermissionRoles.length > 0 && (
           
              

              <div className="mt-3">
                <div className="system-role-list">
                  <Container className="mb-3">
                    <Row>
                      <Col md={4} className="f_700">
                        {' '}
                        Roles
                      </Col>

                      <Col
                        md={4}
                        className="f_700"
                        style={{paddingLeft: '1.5em'}}>
                        Permissions{' '}
                      </Col>
                    </Row>
                  </Container>

                  <Container>
                    {Object.entries(props.tagPermissionRoles).length > 0 &&
                      props.tagPermissionRoles.map((item, index) => (
                        <Row key={`r-item-${index}`}>
                          <Col md={3} className="role-row align_item_center">
                            {' '}
                            {capitalizeFirst(item.role_alias)}
                          </Col>

                          <Col
                            md={6}
                            className="role-row align_item_center pb-0">
                            <div className="alias_permission_flex">
                              <Form.Group className="fieldset payment-checkbox m-0">
                                <Form.Check
                                  type={'checkbox'}
                                  label={'Read'}
                                  id={`Read_${index}_${item.id}`}
                                  // value={'read'}
                                  checked={item.show_tag}
                                  onChange={() => {
                                    handleTagsPermissions('read', item);
                                  }}
                                />
                              </Form.Group>

                              <Form.Group className="fieldset payment-checkbox m-0">
                                <Form.Check
                                  type={'checkbox'}
                                  label={'Update'}
                                  id={`Update_${index}_${item.id}`}
                                  // value={'update'}
                                  checked={item.associate_tag}
                                  onChange={() => {
                                    handleTagsPermissions('update', item);
                                  }}
                                />
                              </Form.Group>

                              <Form.Group className=" form-horizontal fieldset payment-checkbox m-0">
                                <Form.Check
                                  type={'checkbox'}
                                  label={'Create'}
                                  id={`Create_${index}_${item.id}`}
                                  // value={'create'}
                                  checked={item.create_tag}
                                  onChange={() => {
                                    handleTagsPermissions('create', item);
                                  }}
                                />
                              </Form.Group>
                              <Form.Group className=" form-horizontal fieldset payment-checkbox m-0">
                                <Form.Check
                                  type={'checkbox'}
                                  label={'Own Profile'}
                                  id={`Create_${index}_${item.id}`}
                                  // value={'create'}
                                  checked={item.own_profile_permission}
                                  onChange={() => {
                                    handleTagsPermissions('profile', item);
                                  }}
                                />
                              </Form.Group>
                            </div>
                          </Col>

                          <Col
                            md={1}
                            className="role-row d-flex justify-content-between align-items-center">
                            {' '}
                            <span
                              className="as-link"
                              onClick={() => {
                                props.setShowModel('deleteItem');
                                setDeleteItem(item);
                                // removeTagsPermission(item);
                              }}>
                              <i
                                class="fa-solid fa-xmark"
                                style={{
                                  color: '#d71616',
                                  fontWeight: 'bold',
                                  fontSize: '22px',
                                }}></i>
                            </span>{' '}
                          </Col>
                        </Row>
                      ))}
                  </Container>
                </div>
              </div>
           
          )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PipelinePermission;
