import React, {useEffect,useImperativeHandle} from 'react';
import {Button} from 'react-bootstrap';
import {useDispatch, useSelector} from 'react-redux';
import {DEFAULT_IMAGE} from '../../Utils/env';

import {fixName} from '../../Utils/commonUtils';

import {Swiper, SwiperSlide} from 'swiper/react';
import { Navigation} from 'swiper';
import {AddContact} from '../../Constants/Images';
import {getAllBuilderList} from '../../Store/Actions/SessionAction';

import {getItem} from '../../Utils/LocalStorage';
import $ from 'jquery';

const AssigneeBuilders = props => {
  const dispatch = useDispatch();
  // var builders

  // if(props.type=='appointment'){

  //    builders = useSelector(state => state.AllBuilderList);
  // }
  // else{
  //    builders = useSelector(state => state.builderList)
  // }

  // 

  // const [builders,setBuilders] = useState([]);
  // const [showLoader, setshowLoader] = useState(false);




  // const builders = useSelector(state =>
  //   props.type === 'appointment' ? state.AllBuilderList : state.builderList,
  // );

  // let builders = [];

  const getBuilders = (qry) => {
    var data = {
      user_id: getItem('id'),
      // search_query: props.searchqueryAssighne ? props.searchqueryAssighne : '',
      search_query: qry ? qry : '',
    };

    dispatch(getAllBuilderList(data));

    // if(response){
    //   props.setshowLoader(false);
    // }

    // commonApi
    //   .get_builders(data)
    //   .then(response => {
    //     if (response) {
    //       setbuilders(response.discover_builder);
    //     }
    //   })
    //   .catch(err => {
    //     console.log('Err', err);
    //     return {type: 'error', message: err.message};
    //   });
  };
  const builders = useSelector(state => state.AllBuilderList);

  useEffect(() => {
    // props.setshowLoader(true);
    // if (!props.searchqueryAssighne) {
   
      getBuilders('');
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.contact]);
  useEffect(() => {
    // props.setshowLoader(true);
    // if (props.type == 'Create_Action') {
    //   if (builders.length == 0) {
    //     props.setshowLoader(true);
    //   }
    //   setTimeout(() => {
    //     props.setshowLoader(false);
    //   }, 2000);
    // }
  }, [props.contact, props.apiHit]);


  useImperativeHandle(props.createTaskRef, () => ({


    getAssignee(qry) {
      getBuilders(qry);
    },

   
  }));


  // useEffect(() => {
  //   // props.setshowLoader(true);
  //   if (props.searchqueryAssighne || props.searchqueryAssighne === '') {
     
  //     getBuilders();
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [props.searchqueryAssighne]);

  useEffect(() => {
    
    if (props.addContact === 'assigne') {
      if (props.contact?.length > 0) {
        
       
     
        const filterData = builders.filter(
          item =>
            item.exist_in_build === 'no' &&
            props.contact.some(
              dataItem =>
                dataItem.fname === item.first_name &&
                // dataItem.lname === item.last_name &&
                dataItem.contact === item.phone,
            ),
        );
        
       

        
        if (filterData.length > 0) {
          filterData.forEach(miniItem => {
            props.buildersHandle('profileComponent', miniItem);

            // props.setModerator(prev => [...prev, miniItem.id]);

            // props.setSelectedModData(previous => [...previous, miniItem]);
            // props.setOriginalDataOfMod(previous => [
            //   ...previous,
            //   miniItem,
            // ]);

            // setSelectedModData(updatedData)

            
          });

          props.setAddContact('');
          props.setSearchqueryAssighne('')
        }
      }
    }

    if (props.contact?.length > 0 && props.setContact) {
      props.setContact([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [builders]);


  
  
//   useEffect(() => {
//    if(builders.length > 6){
//     handleSliderMargin()
//    }
//   }, [builders])
  
// const handleSliderMargin = () =>{
//   if (builders.length > 6) {
//     $('.assigne_builder_slider')
//       .find('.swiper')
//       .addClass('margin_0');
//   } else if (builders.length === 6) {
//     $('.assigne_builder_slider')
//       .find('.swiper')
//       .removeClass('m-0');
//   }
// }
  

  const renderDiscoverBuilder = index => {
    // Function to render DiscoverBuilder component
    return (
      <SwiperSlide key={`discover-builder-${index}`}>
        <div
          className="discover-item search as-link"
          onClick={() => {
            props.buildersHandle('AllBuilders');
          }}>
          <div className="img-wrapper">
            <i className="fas fa-magnifying-glass" />
          </div>

          <h3>Search</h3>
          <p>Network</p>
        </div>
      </SwiperSlide>
    );
  };
  

  return (
    <>
      {!props.showLoader && (<div className="discover-builders-wrapper home-users-listing customer_user_row custom_user_row_assignee">
        <div className="discover-builders">
          <div className="discover-builders-slider assigne_builder_slider">
            <Swiper
              slidesPerView={'auto'}
              spaceBetween={20}
              navigation={true}
              modules={[Navigation]}
              // className="mySwiper"

              className={`mySwiper ${
                builders.length > 6
                  ? 'addwidthDiscoverBuilders'
                  : 'onlyBuildersSlider'
              }`}>
              {!props.type && (
                <SwiperSlide>
                  <div
                    className="discover-item search as-link"
                    onClick={() => {
                      props.buildersHandle('inviteOutSideTheBuild');
                    }}>
                    <div className="img-wrapper">
                      <img src={AddContact} alt="" />
                    </div>

                    <h3>Add</h3>
                    <p>Contact</p>
                  </div>
                </SwiperSlide>
              )}

              {props.type &&
                props.type !== 'Create_Action' &&
                (builders.length === 0 || props.type !== 'poll') && (
                  <React.Fragment>
                    <SwiperSlide>
                      <div
                        className="discover-item search as-link"
                        onClick={() => {
                          props.buildersHandle('AllBuilders');
                        }}>
                        <div className="img-wrapper">
                          <i className="fas fa-magnifying-glass" />
                        </div>

                        <h3>Search</h3>
                        <p>Network</p>
                      </div>
                    </SwiperSlide>
                  </React.Fragment>
                )}
              {builders.length === 0 && !props.type && (
                <React.Fragment>
                  <SwiperSlide>
                    <div
                      className="discover-item search as-link"
                      onClick={() => {
                        props.buildersHandle('AllBuilders');
                      }}>
                      <div className="img-wrapper">
                        <i className="fas fa-magnifying-glass" />
                      </div>

                      <h3>Search</h3>
                      <p>Network</p>
                    </div>
                  </SwiperSlide>
                </React.Fragment>
              )}
              {props.showMessagesUsers && !props.searchqueryAssighneCheck ? (
                Object.entries(props.allChatUsers).length > 0 &&
                props.allChatUsers.map((item, index) => {
                  return (
                    <React.Fragment key={`discover-${index}`}>
                      <SwiperSlide key={`discover-item-${index}`}>
                        <div
                          className="discover-item as-link"
                          onClick={() => {
                            if (
                              props.type === 'appointment' ||
                              props.type === 'poll' ||
                              props.type === 'Create_Action'
                            ) {
                              props.buildersHandle('profileComponent', item);
                            } else {
                              props.buildersHandle('profileComponent', item.id);
                            }
                          }}>
                          <div className={
                              item?.linked_user_detail?.user_type === 'business'
                                ? 'img-wrapper business'
                                : 'img-wrapper'
                            }>
                            <img
                              className={
                                (props.type === 'appointment' &&
                                  props.builders.includes(item.id)) ||
                                (props.type === 'poll' &&
                                  props.builders.includes(item.id)) ||
                                (props.type === 'Create_Action' &&
                                  props.builders.includes(item.id))
                                  ? 'selercted-user'
                                  : ''
                              }
                              src={
                                item.profile_img
                                  ? item.profile_img
                                  : DEFAULT_IMAGE
                              }
                              alt=""
                            />
                          </div>
                          <h3>{fixName(item.first_name, item.last_name)}</h3>
                          <p>
                            {item.title ? item.title : ''}
                            {parseInt(item.total) ? ' +' + item.total : ''}
                          </p>
                        </div>
                      </SwiperSlide>
                      {!props.type &&
                        (index === builders.length - 1 && builders.length <= 10
                          ? renderDiscoverBuilder()
                          : index > 0 &&
                            index % 10 === 0 &&
                            renderDiscoverBuilder(index))}
                    </React.Fragment>
                  );
                })
              ) : Object.entries(builders).length > 0 ? (
                builders.map((item, index) => {
                  return (
                    <React.Fragment key={`discover-${index}`}>
                      <SwiperSlide key={`discover-item-${index}`}>
                        <div
                          className="discover-item as-link"
                          onClick={() => {
                            if (
                              props.type === 'appointment' ||
                              props.type === 'poll' ||
                              props.type === 'Create_Action'
                            ) {
                              props.buildersHandle('profileComponent', item);
                            } else {
                              props.buildersHandle('profileComponent', item.id);
                            }
                          }}>
                          <div className={
                              item?.linked_user_detail?.user_type === 'business'
                                ? 'img-wrapper business'
                                : 'img-wrapper'
                            }>
                            <img
                              className={
                                (props.type === 'appointment' &&
                                  props.builders.includes(item.id)) ||
                                (props.type === 'poll' &&
                                  props.builders.includes(item.id)) ||
                                (props.type === 'Create_Action' &&
                                  props.builders.includes(item.id))
                                  ? 'selercted-user'
                                  : ''
                              }
                              src={
                                item.profile_img
                                  ? item.profile_img
                                  : DEFAULT_IMAGE
                              }
                              alt=""
                            />
                          </div>
                          <h3>{fixName(item.first_name, item.last_name)}</h3>
                          <p>
                            {item.title ? item.title : ''}
                            {parseInt(item.total) ? ' +' + item.total : ''}
                          </p>
                        </div>
                      </SwiperSlide>
                      {!props.type &&
                        (index === builders.length - 1 && builders.length <= 10
                          ? renderDiscoverBuilder()
                          : index > 0 &&
                            index % 10 === 0 &&
                            renderDiscoverBuilder(index))}
                    </React.Fragment>
                  );
                })
              ) : (
                <></>
              )}
            </Swiper>
           
          </div>
          {builders && builders.length === 0 && (
              <div className="w-100">
                <div className="no-results-wrapper no-appointments my-5">
                  <div className="no-results-content">
                    <p className="noRecord-title">
                      {props.searchqueryAssighne
                        ? `  We didn't find the ${props.searchqueryAssighne} match for the name`
                        : `  We didn't find  any match `}
                    </p>

                    <div className="btn-wrapper">
                      <Button
                        className="btn btn-dark"
                        onClick={() => {
                          // props.onClick();
                          props.setShowComponent('inviteUser');

                          props.setAddContact('assigne');
                        }}>
                        {props.searchqueryAssighne
                          ? 'Add Contact'
                          : 'Add Contacts'}
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            )}
        </div>
      </div>
      )}
    </>
  );
};

export default AssigneeBuilders;
