import React, {useState} from 'react';
import {Button, Form, Dropdown} from 'react-bootstrap';

const CustomDropdown = ({
  show,
  type,
  setDropdownId,
  dropdownId,
  DropDownValues,
  itemName,
  className,
  handleDropdownValue,
  disabled,
  noData,
}) => {
  // const handleDropdownChange = id => {
  //   setDropdownId(id);
  // };

  const getItemName = (item, keys) => {
    for (let key of keys) {
      if (item[key]) {
        return item[key];
      }
    }
    return 'Unknown';
  };

  return (
    <div className={`roi-form-dropdown ${className ? className : ''} }`}>
      <div className={`${disabled ? 'disabled' : ''} select-wrapper  `}>
         {( (show || Object.entries(DropDownValues).length > 0) && (
        <Dropdown
          onSelect={(eventKey, event) => {
            setDropdownId(eventKey);
            if (
              type === 'credential' ||
              type === 'milestone' ||
              type === 'deliverable'
            ) {
              handleDropdownValue && handleDropdownValue(eventKey);
            }
          }}
          disabled={DropDownValues.length > 0 && true}
          drop="down">
          <Dropdown.Toggle
            variant="primary"
            className={`${
              DropDownValues.length === 0 || disabled ? 'disabled pointer-events-none cursor-default' : ''
            }`}>
            {dropdownId && type !== 'deliverable' && type !== 'Area'
              ? getItemName(
                  DropDownValues.find(item => item.id === dropdownId),
                  itemName,
                )
              : dropdownId && type === 'deliverable'
              ? getItemName(
                  DropDownValues.find(item => item.title === dropdownId),
                  itemName,
                )
              : dropdownId && type === 'Area'
              ? getItemName(
                  DropDownValues.find(item => item.name === dropdownId),
                  itemName,
                )
              : noData
              ? noData
              : 'Select Role'}
          </Dropdown.Toggle>

          <Dropdown.Menu>
            {DropDownValues.map((item, index) => (
              <Dropdown.Item
                key={`st-${index}`}
                eventKey={
                  type === 'deliverable'
                    ? item.title
                    : type === 'Area'
                    ? item.name
                    : item.id.toString()
                }
                active={
                  type === 'deliverable'
                    ? item.title === dropdownId
                    : type === 'Area'
                    ? item.name === dropdownId
                    : item.id === dropdownId
                }>
                {getItemName(item, itemName)}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
         ))}
      </div>
    </div>
  );
};

export default CustomDropdown;
