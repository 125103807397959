
export const fieldsGroupList = (
    state = [],
    action,
  ) => {
    switch (action.type) {
      case 'get_fileds':
        return action.payload;
      default:
        return state;
    }
  };