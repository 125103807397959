
export const getDispatch = (type, data = false) => ({
    type: type,
    payload: data,
  });

export const emptySearch = (data) => {
    return async dispatch => {
      dispatch(getDispatch('empty_search', data));
    };
  };