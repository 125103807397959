import React, {useState, useEffect} from 'react';
import SnapshotDetail from './SnapshotDetail';
import commonApi from '../../Services/CommonService';
import {Row, Col, Button} from 'react-bootstrap';
import Scrollbars from 'react-custom-scrollbars';
import AddNewSnapShot from '../Messages/AddNewSnapShot';
import LoaderSpinner from '../Modals/LoaderSpinner';
import {DEFAULT_IMAGE} from '../../Utils/env';
import SlidingPanel from '../SlidingPanel';
import ProfileComponent from '../ProfilePage/ProfileComponent';
import {getItem} from '../../Utils/LocalStorage';
import {fixName} from '../../Utils/commonUtils';

const SnapshotList = props => {
  const [showSpinner, setShowSpinner] = useState(true);
  const [showComponent, setShowComponent] = useState('');
  const [snapshotData, setSnapshotData] = useState([]);
  // const [singleSnapshotData, setSingleSnapshotData] = useState(props.snapshotData);
  const [singleSnapshotData, setSingleSnapshotData] = useState();

  const handleClick = item => {
    setSingleSnapshotData(item);
    // setShowDetail(true);
    setShowComponent('snapshot-detail');
  };

  const getSnapshotData = () => {
    var data = {
      user_id: props.show.sendButton
        ? getItem('id')
        : props.userData && props.userData.id
        ? props.userData.id
        : getItem('id'),
      categories: [],
    };
    commonApi
      .get_accomplishment(data)
      .then(res => {
        if (res) {
          setSnapshotData(res.accomplishment);
        }
        setShowSpinner(false);
      })
      .catch(err => {
        setShowSpinner(false);
     
        return {type: 'error', message: err.message};
      });
  };

  useEffect(() => {
    getSnapshotData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
     
        <SlidingPanel hidePanel={() => props.onClose()}>
          <div className="serach-filter-wrapper pt-0">
            <div
              className={`sidebar-heading-wrapper fix-width-forms mt-0 ${
                props.withHeadingSpace ? 'with-panel' : ''
              }`}>
              
              <div className="with-user-img with-user-snapshot-img">
                <div
                  className="user-profile-img as-link"
                  onClick={() => setShowComponent('show-profile')}>
                  <img
                    src={
                      props.userData && props.userData.profile_img
                        ? props.userData.profile_img
                        : getItem('profilePic')
                        ? getItem('profilePic')
                        : DEFAULT_IMAGE
                    }
                    alt=""
                  />
                </div>
                {/* <span
                    className="close as-link"
                    onClick={() => props.onClose()}>
                    <i className="fas fa-times" />
                  </span> */}
              </div>
              <div className="heading mt-0">
                <h2>
                  {props.userData && Object.entries(props.userData).length > 0
                    ? fixName(
                        props.userData.first_name,
                        props.userData.last_name,
                      )
                    : fixName(getItem('firstName'), getItem('lastName'))}
                </h2>
              </div>
            </div>

            <Scrollbars>
              {/* <div className="invite-page-search">
                            <div className="search-form">
                                <Form.Group className="search-input">
                                    <Form.Control type="text" onChange={(e) => { }} placeholder="Please Add Modal..." />
                                </Form.Group>
                            </div>
                        </div> */}

              <div className="fix-width-forms snapshots-listing">
                <div className="gallery-imgs">
                  {showSpinner && <LoaderSpinner />}
                  {!showSpinner && (
                    <Row>
                      {Object.entries(snapshotData).length > 0 &&
                        snapshotData.map((item, index) => {
                          return (
                            <Col md={4} sm={4} key={`gallery-img-${index}`}>
                              <div
                                style={
                                  props.snapshotIds.includes(item.id)
                                    ? {
                                        border:
                                          '1px solid #52bd41',
                                      }
                                    : {}
                                }
                                className="gallery-item"
                                onClick={() => {
                                  if (props.snapshotIds.includes(item.id)) {
                                    props.setSnapshotIds(current => {
                                      return current.filter(
                                        id => id !== item.id,
                                      );
                                    });
                                    props.setSnapshotImages(current => {
                                      return current.filter(
                                        image => image !== item.images[0],
                                      );
                                    });
                                  } else {
                                    props.setSnapshotIds(current => [
                                      ...current,
                                      item.id,
                                    ]);
                                    props.setSnapshotImages(current => [
                                      ...current,
                                      item.images[0],
                                    ]);
                                  }
                                 
                                  // this.showLightbox(images, index);
                                }}>
                                <span
                                  onClick={() => {
                                    handleClick(item);
                                  }}
                                  className="zoom-icon">
                                  <i className="fas fa-magnifying-glass" />
                                </span>
                                <img src={item.images[0]} alt="" />
                              </div>
                            </Col>
                          );
                        })}
                      {Object.entries(snapshotData).length < 1 && (
                        <div className="no-results-wrapper mt-5">
                          {/* <div className="img-bg-wrapper">
                    <img src={DEFAULT_IMG} alt="" />
                  </div> */}

                          <div className="no-results-content ">
                            <h2>What represents your life in a snapshot?</h2>
                            <p className='mt-2'>
                              Think about your values, experiences and
                              accomplishments. This is a quicker way for people
                              to get to know you.
                            </p>
                            <div className="btn-wrapper pt-3 mt-2">
                              <Button
                                className="btn btn-dark"
                                onClick={() => {
                                  setShowComponent('add-snapshot');
                                }}>
                                Add Snippet
                              </Button>
                            </div>
                          </div>
                        </div>
                      )}
                    </Row>
                  )}
                </div>
              </div>
            </Scrollbars>
            {props.show.hasOwnProperty('sendButton') &&
              props.show.sendButton &&
              props.snapshotIds &&
              props.snapshotIds.length > 0 &&
              Object.entries(snapshotData).length > 0 && (
                <div className="filter-btn-wrapper pt-3 pb-3">
                  <div className="invite-btns p-0 text-left">
                    <Button
                      className="btn-dark"
                      onClick={() => {
                        props.onClose();
                        props.onSend();
                      }}>
                      Send
                    </Button>
                  </div>
                </div>
              )}
          </div>
        </SlidingPanel>
 
      {showComponent === 'snapshot-detail' && (
        <SnapshotDetail
          snapshotId={singleSnapshotData}
          userData={props.userData}
          onClose={e => {
            // setShowDetail(false);
            if (e) {
              getSnapshotData();
            }
            setShowComponent('');
          }}
          showRefer={false}
        />
      )}
      {showComponent === 'add-snapshot' && (
        <AddNewSnapShot
          onClose={() => {
            getSnapshotData();
            setShowComponent('');
          }}
        />
      )}
      {showComponent === 'show-profile' && (
        <ProfileComponent
          userId={props.userData.id}
          closeModal={() => {
            setShowComponent('');
          }}
          onClose={() => {
            setShowComponent('');
          }}
          userData={[]}
          VoxeetSDK={[]}
          conferenceInfo={[]}
          jdSpecialM={[]}
          jdSpecialA={[]}
        />
      )}
    </>
  );
};

export default SnapshotList;
