import React, { useState, useRef, useEffect } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import {
  PaymentElement,
  Elements,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';
import axios from 'axios';

import { Button } from 'react-bootstrap';
import commonApi from '../../Services/CommonService';
import { successMessage, errorMessage } from '../../Utils/ToastMessages';

const CheckoutForm = props => {
  const stripe = useStripe();
  const elements = useElements();
  const [errorMessage, setErrorMessage] = useState(null);

  const sendPaymentSuccessDetails = (transactionId) => {
    var payload = {
      amount: props.price && props.price !== '' ? props.price : '',
      id_transaction_type: props.paymentId && props.paymentId !== '' ? props.paymentId : '',
      transaction_type: props.transactionType ? props.transactionType : '',
      stripe_transaction_id: transactionId,
      // from_web:true,
      device_type: 'web',
    };

    commonApi
      .payment_success_details(payload)
      .then(res => {
        if (res.success === true) {
          if(props.transactionType ==='invoice'){
            successMessage(res.message)
          }
          
          props.onClose()
        } else {
          errorMessage(res.message);
        }
      })
      .catch(err => {
       
        return { type: 'error', message: err.message };
      });
  };

  const handleSubmit = async event => {
    event.preventDefault();

    if (elements == null) {
      return;
    }

    // Trigger form validation and wallet collection
    const { error: submitError } = await elements.submit();
    if (submitError) {
      // Show error to your customer
      setErrorMessage(submitError.message);
      return;
    }

    // Create the PaymentIntent and obtain clientSecret from your server endpoint
    const requestBody = { amount: 100 * Number(props.price).toFixed(2) };
    const response = await axios.post(
      'https://devapi.roilevelup.com/create_payment',
      requestBody,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }
    );

    const clientSecret = response.data.stripe.client_secret;
    const transactionId = response.data.stripe.id;
    const host = window.location.origin;

    const returnUrl = `${host}/payment`;

    const result = await stripe
      .confirmPayment({
        elements,
        clientSecret,
        confirmParams: {
          return_url: returnUrl,
        },
        redirect: "if_required",
      })
      .then(function (result) {
       
        sendPaymentSuccessDetails(transactionId)
        // return;
      });



    // return;
    // const clientSecret = elements._id.replace('elements-', '');
    // console.log(clientSecret)

    //console.log(elements);
    // console.log(elements._id);

    // console.log(clientSecret);
    // await stripe
    //   .confirmCardPayment(
    //     //`Elements` instance that was used to create the Payment Element
    //     //   elements,
    //     clientSecret,
    //     {payment_method: 'card'},
    //   )
    //   .then(function (result) {
    //     console.log('000000000', result);
    //     return;
    //   });
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <PaymentElement />
        {/* <button type="submit" disabled={!stripe || !elements}>
          Submit
        </button> */}

        <div className="filter-btn-wrapper ">
          <div className="invite-btns  p-0 pt-3 text-center">
            <Button type="submit" disabled={!stripe || !elements}>
              Pay ${props.price}
            </Button>
          </div>
        </div>
        {/* Show error message to your customers */}
        {errorMessage && <div>{errorMessage}</div>}
      </form>
    </div>
  );
};
export default CheckoutForm;
