import React, {useEffect} from 'react';
import {Form, Dropdown} from 'react-bootstrap';

import {useState} from 'react';
import SlidingPanel from '../SlidingPanel';
import Scrollbars from 'react-custom-scrollbars';
import {RoiLogo, Templates} from '../../Constants/Images';
import {labels} from '../../Constants/Translations';
import {selected_lang} from '../../Utils/env';
import CustomTabs from '../CustomTabs';
import {
  changeFormat,
  createFormAssociation,
  editSection,
  FomsPermmesion,
  traditionalToWizard,
  wizardToTraditional,
} from '../../Utils/commonUtils';
import CustomSlidingButtons from '../CustomSlidingButtons';
import MultiSelectionDropdown from '../MultiSelectionDropdown';
import FormSection from './FormSection';
import FormStep from './FormStep';
import CreateField from './CreateField';
import ConfirmationModel from '../ConfirmationModel/ConfirmationModel';
import {useDispatch} from 'react-redux';
import {useSelector} from 'react-redux';
import {getFieldGroupList} from '../../Store/Actions/FieldsAction';
import commonApi from '../../Services/CommonService';
import {errorMessage, successMessage} from '../../Utils/ToastMessages';
import FieldSuggestion from './FieldSuggestion';
import EditPencilSvg from '../../Svg/EditPencilSvg';
import LoaderSpinner from '../Modals/LoaderSpinner';

const CreateForm = props => {
  const [type, setType] = useState('traditional');
  const [title, setTitle] = useState('');
  const [formName, setFormName] = useState('');
  const [search, setSearch] = useState('');
  const [sectionName, setSectionName] = useState('');
  const [stepName, setStepName] = useState('');
  const [introStatement, setIntroStatement] = useState('');
  const [tabType, setTabType] = useState('Forms');
  const [groupFields, setGroupFields] = useState([]);
  const [formsFields, setFormsFields] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [fieldIndex, setFieldIndex] = useState({
    editing: false,
    index: null,
  });
  const [fieldTypeArray, setFieldTypeArray] = useState([]);
  const [traditionalSection, setTraditionalSection] = useState([]);
  const [wizardStepData, setWizardStepData] = useState([]);
  const [traditionalFields, setTraditionalFields] = useState([]);
  const [selectField, setSelectField] = useState('field');
  const [error, setError] = useState(false);
  const fildsGroupListing = useSelector(state => state.fieldsGroupList);
  const dispatch = useDispatch();
  const [validated, setValidated] = useState(false);
  // const [userInEdit, setUserInEdit] = useState(
  //   (props.editFormItem &&
  //     typeof props.editFormItem === 'object' &&
  //     Object.keys(props.editFormItem).length > 0) ||
  //     (props.editFieldData &&
  //       typeof props.editFieldData === 'object' &&
  //       Object.keys(props.editFieldData).length > 0)
  //     ? false
  //     : 
  //     true,
  // );
  const [userInEdit, setUserInEdit] = useState(props.permesions ? true :  false)
  const [publicToogle, setPublicToogle] = useState(true);
  const [section_tabsToogle, setSection_tabsToogle] = useState(true);
  const [showLoader, setShowLoader] = useState(true);

  const [typeIndex, setTypeIndex] = useState({
    editing: false,
    index: null,
  });
  const [formsIndex, setFormsIndex] = useState({
    index: null,
    type: '',
  });
  const [formSectionIndex, setFormSectionIndex] = useState({
    index: null,
    type: '',
  });

  const [groupIndex, setGroupIndex] = useState({
    editing: false,
    index: null,
  });

  const [formAssociationButtonsFilter, setFormAssociationButtonsFilter] =
    useState('');
  const [selectedFieldsIds, setSelectedFieldsIds] = useState(['1']);
  const [fieldsList, setFieldsList] = useState([
    {group_name: 'Field Group (multi select)', id: '1'},
    {group_name: 'Field Group (multi select)', id: '2'},
    {group_name: 'Field Group (multi select)', id: '3'},
    {group_name: 'Field Group (multi select)', id: '4'},
  ]);
  const [statement1, setStatement1] = useState({
    value1: '',
    value2: '',
  });

  const [isFormVisible, setIsFormVisible] = useState(true);
  const [fieldData, setFieldData] = useState({
    fields: [],
    group_fields: [],
  });

  const dropdownMenu = [
    {id: 'traditional', name: 'Traditional'},
    {
      id: 'wizard',
      name: 'Wizard',
    },
  ];

  const statement1Dropdown = [
    {id: '1', name: 'Dropdown'},
    {
      id: '2',
      name: 'Training',
    },
    {
      id: '3',
      name: 'Training',
    },
  ];
  const renderHeader = () => {
    return (
      <>
        {props.permesions &&
        ((props.editFormItem &&
          typeof props.editFormItem === 'object' &&
          Object.keys(props.editFormItem).length > 0) ||
          (props.editFieldData &&
            typeof props.editFieldData === 'object' &&
            Object.keys(props.editFieldData).length > 0)) &&
        !userInEdit ? (
          <span
            className="as-link"
            onClick={() => {
              setUserInEdit(true);
            }}>
            <EditPencilSvg fill={'white'} h={20} w={20} />
          </span>
        ) : (
          <img src={RoiLogo} alt="" />
        )}
      </>
    );
  };

  const handleSectionStep = event => {
    event.preventDefault();
    event.stopPropagation();
    const form = event.currentTarget;

    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      setValidated(true);
    } else {
      event.preventDefault();

      setValidated(false);

      if (type === 'wizard') {
        if (formsIndex.type === 'edit_Wizard_Step') {
          editSection(
            wizardStepData,
            setWizardStepData,
            stepName,
            '',
            formsIndex.index,
            'editWizardStep',
          );
          resetForm();
        } else {
          const getNewStep = {
            name: stepName,
          };
          setWizardStepData(prev => [...prev, getNewStep]);
          resetForm();
        }
      } else {
        if (formsFields?.length > 0) {
          // create_update_form();

          if (formSectionIndex.index !== null) {
            editSection(
              traditionalSection,
              setTraditionalSection,
              sectionName,
              formsFields,
              formSectionIndex.index,
            );
            resetForm();
          } else {
            const getSections = {
              name: sectionName,
              field_ids: formsFields,
            };
            setTraditionalSection(prev => [...prev, getSections]);
            resetForm();
          }
        } else {
          errorMessage('Please select atleast one field.');
        }
      }
    }
  };

  useEffect(() => {
    if (props.editFormItem?.type) {
      setFormName(props.editFormItem.name);
      setType(props.editFormItem.type);
      setPublicToogle(props.editFormItem.public);
      if (props.editFormItem.type === 'traditional') {
        setTraditionalSection(props.editFormItem.section_step_details);
        setSection_tabsToogle(props.editFormItem.section_tabs);
      } else {
        setWizardStepData(props.editFormItem.section_step_details);
      }
    }
  }, [props.editFormItem]);

  

  const cancelEdit = () => {
    if (type === 'wizard') {
      setStepName('');
      setFormsIndex({
        index: null,
        type: '',
      });
    } else {
      setSectionName('');
      setFormsFields([]);
      setFormSectionIndex({
        index: null,
        type: '',
      });
    }
    setValidated(false)
  };

  const decideCancelButton = () => {
    if (type === 'wizard') {
      return formsIndex.index !== null;
    } else {
      return formSectionIndex.index !== null;
    }
  };

  const chooseButton = () => {
    if (type === 'wizard') {
      return formsIndex.index === null ? 'Add Step' : 'Update Step';
    } else {
      return formSectionIndex.index === null ? 'Add Section' : 'Update Section';
    }
  };

  const resetForm = () => {
    if (type === 'wizard') {
      setStepName('');
      setFormsIndex({
        index: null,
        type: '',
      });
    } else {
      setSectionName('');
      setFormsFields([]);
      setFormSectionIndex({
        index: null,
        type: '',
      });
    }
  };

  useEffect(() => {
    dispatch(getFieldGroupList({serach: ''}));
  }, []);
  useEffect(() => {
    if (fildsGroupListing.status === 200) {
      setFieldData({
        fields: fildsGroupListing.fields,
        group_fields: fildsGroupListing.group_fields,
      });
    }
  }, [fildsGroupListing]);

  const SubmitFormApi = () => {
    if (type === 'wizard') {
      if (wizardStepData.length > 0 && formName !== '') {
        create_update_form();
      } else {
        if (formName === '') {
          setError(true);
        } else if (wizardStepData.length === 0) {
          errorMessage('Please add one step.');
        }
      }
    }

    if (type === 'traditional') {
      if (traditionalSection.length > 0 && formName !== '') {
        create_update_form();
      } else {
        if (formName === '') {
          setError(true);
        } else if (traditionalSection.length === 0) {
          errorMessage('Please add one section.');
        }
      }
    }
  };

  const errorStyle = {
    color: '#dc3545',
    fontSize: '.875em',
    marginTop: '.25rem',
  };

  const create_update_form = () => {
    const formatedValues = changeFormat(wizardStepData, traditionalSection);
    const typeDecesion = props.editFormItem?.id ? 'edit' : 'add';
    const payload = {
      form_id: props.editFormItem?.id ? props.editFormItem?.id : '',
      name: formName,
      type: type,
      step: type === 'wizard' ? formatedValues.newwizardData : [],
      section: type === 'traditional' ? formatedValues.newtransformedData : [],
      public: publicToogle,
      section_tabs:section_tabsToogle,
    };

    commonApi
      .create_update_form(payload)
      .then(res => {
        if (res.status === 200) {
          props.handleAddUpdateForm(res.data, typeDecesion);
          successMessage(res.message);
          props.onClose();
        }
      })
      .catch(err => {
        
        return {type: 'error', message: err.message};
      });
  };

  const delete_field = () => {
    const payload = {
      delete_id: fieldIndex.delete_id,
      type: fieldIndex.type === 'delete_group_field' ? 'group' : 'field',
    };
    // return;
    commonApi
      .delete_field(payload)
      .then(res => {
        if (res.status === 200) {
          deleteField();
          successMessage(res.message);
        } else {
          errorMessage(res.message);
        }
      })
      .catch(err => {
      
        return {type: 'error', message: err.message};
      });
  };

  const deleteField = () => {
    if (fieldIndex.index !== null && !fieldIndex.editing) {
      setFieldData(prev => {
        const updatedFields = {...prev};

        if (selectField === 'field_group') {
          updatedFields.group_fields = updatedFields.group_fields.filter(
            (_, index) => index !== fieldIndex.index,
          );
        } else {
          updatedFields.fields = updatedFields.fields.filter(
            (_, index) => index !== fieldIndex.index,
          );
        }

        return updatedFields;
      });
      
    setFieldIndex({
      editing: false,
      index: null,
      delete_id: null,
    });
    } else if (typeIndex.type === 'delete_option') {
      setFieldTypeArray(prev =>
        prev.filter((_, index) => index !== typeIndex.index),
      );
      setTypeIndex({
        editing: false,
        index: null,
        type: null,
      });
    } else if (groupIndex.type === 'goup_fields_del') {
      setGroupFields(prev =>
        prev.filter((_, index) => index !== groupIndex.index),
      );
      setGroupIndex({
        editing: false,
        index: null,
        type: null,
      });
  
    }
  };

  const handleForm = (itemINdex, type, item, otherType) => {
    if (otherType === 'wizard_section') {
      if (type === 'delete_Wizard_Step') {
        setFormsIndex({
          index: itemINdex,
          type: type,
        });
        setShowDeleteModal(true);
      } else if (type === 'edit_Wizard_Step') {
        setStepName(item.name);
        setFormsIndex({
          index: itemINdex,
          type: type,
        });
      }
    } else {
      if (type === 'edit_section') {
        setSectionName(item.name);
        setFormsFields(item?.field_ids?.length > 0 ? item?.field_ids : []);
        setFormSectionIndex({
          index: itemINdex,
          type: type,
        });
      } else if (type === 'delete_section') {
        setShowDeleteModal(true);
        setFormsIndex({
          index: itemINdex,
          type: type,
        });
      } else if (type === 'multi_select_field'){
        setFormsFields(prev =>
          prev.filter((_, index) => index !== itemINdex),
        );
      }
    }
  };

  useEffect(() => {
    setValidated(false);
  }, [type]);
  useEffect(() => {
    setTimeout(() => {
      if (props.type === 'field') {
        setTabType(labels.FIELDS[selected_lang]);
      } else {
        if (props.Formtype === 'forms') {
          setTabType(labels.Forms[selected_lang]);
        } else {
          setTabType(labels.FIELDS[selected_lang]);

          if (props.fieldPermesion && props.formPermesion) {
            if (props.type === 'field') {
              setTabType(labels.FIELDS[selected_lang]);
            } else {
              setTabType(labels.Forms[selected_lang]);
            }
          } else if (props.formPermesion) {
            setTabType(labels.Forms[selected_lang]);
          } else if (props.fieldPermesion) {
            setTabType(labels.FIELDS[selected_lang]);
          }
        }
      }

      setShowLoader(false);
    }, 2000);
  }, []);

  const showHeading = (type) => {
    // alert('');
    if (
      userInEdit &&
      ((props.editFieldData &&
        typeof props.editFieldData === 'object' &&
        Object.keys(props.editFieldData).length > 0) ||
        (props.editFormItem &&
          typeof props.editFormItem === 'object' &&
          Object.keys(props.editFormItem).length > 0))
    ) {
      if (tabType === 'Fields') {
        return 'Update Field';
      } else {
        return 'Update Form';
      }
    } else if (
      !userInEdit &&
      ((props.editFieldData &&
        typeof props.editFieldData === 'object' &&
        Object.keys(props.editFieldData).length > 0) ||
        (props.editFormItem &&
          typeof props.editFormItem === 'object' &&
          Object.keys(props.editFormItem).length > 0))
    ) {
      if (tabType === 'Fields') {
        return 'Detail Field';
      } else {
        return 'Detail Form';
      }
    } else if (
      userInEdit &&
      (!props.editFieldData ||
        typeof props.editFieldData !== 'object' ||
        Object.keys(props.editFieldData).length === 0) &&
      (!props.editFormItem ||
        typeof props.editFormItem !== 'object' ||
        Object.keys(props.editFormItem).length === 0)
     && !type) {
      if (tabType === 'Fields') {
        return 'Add Field';
      } else {
        return 'Add Form';
      }
    }
  };


  const mouseStyles = {
    cursor: !userInEdit ? 'default' : 'text',
  };

  return (
    <>
      <SlidingPanel
        hidePanel={() => {
          props.onClose();
        }}
        renderData={renderHeader}
        showBlackHeader={true}
        headerPadding={'24px 20px'}
        lastDivStyle={{
          borderRadius: '35px',
          background: '#f7f6f2',
        }}
        firstDivStyle={{
          padding: 0,
          background:
            'linear-gradient(to bottom, #424242, #424242, #f7f6f2 50%)',
        }}>
        <div className="serach-filter-wrapper create-sessionEvents">
          {showLoader && <LoaderSpinner className={'curved-spinner-bg'} />}
          <Scrollbars
            renderTrackHorizontal={props => (
              <div {...props} style={{display: 'none'}} />
            )}
            // Hide the vertical scrollbar
            renderTrackVertical={props => (
              <div {...props} style={{display: 'none'}} />
            )}>
            <div className="create-ticket-form pb-3 fixed-width-inputs black-head-form-pad-left createForm_wrapper">
              <div className="create-form-page">
                {/* <div className="create-form-page-top">
                  <h1>Form</h1>

                  <img src={Templates} alt="" />
                </div> */}
                {!props.hideButtons && (
                  <div className="mb-3">
                    <CustomTabs
                      darkmode
                      active={tabType}
                      onSelect={item => {
                        setTabType(item);
                      }}
                      tabs={
                        props.fieldPermesion && props.formPermesion
                          ? [
                              labels.Forms[selected_lang],
                              labels.FIELDS[selected_lang],
                            ]
                          : []
                      }
                    />
                  </div>
                )}
                {((!props.formPermesion && props.fieldPermesion) ||
                  (props.formPermesion && !props.fieldPermesion)) && (
                  <h2 className="mb-3 as-link">{showHeading()}</h2>
                )}
                {((props.formPermesion && props.fieldPermesion) ||
                  (props.formPermesion && props.fieldPermesion)) && (
                  <h2 className="mb-3 as-link">{showHeading('bothPermesions')}</h2>
                )}

                <div className="category-btns-wrapper category-btns-wrapper-slider mt-0 mb-4">
                  <CustomSlidingButtons
                    items={createFormAssociation}
                    activeButtons={formAssociationButtonsFilter}
                    // onItemClick={e => {
                    //   handleType(e);

                    // }}
                    showItems={3}
                    value={formAssociationButtonsFilter}
                  />
                </div>

                {userInEdit && (
                  <div className="roi-form-input">
                    <Form.Control
                      type="text"
                      value={search}
                      pattern="^\S.*$"
                      placeholder="Search"
                      onChange={e => {
                        setSearch(e.target.value);
                      }}
                    />
                  </div>
                )}

                {tabType === 'Fields' && (
                  <CreateField
                    fieldData={fieldData}
                    setFieldData={setFieldData}
                    setShowDeleteModal={setShowDeleteModal}
                    fieldIndex={fieldIndex}
                    setFieldIndex={setFieldIndex}
                    typeIndex={typeIndex}
                    setTypeIndex={setTypeIndex}
                    fieldTypeArray={fieldTypeArray}
                    setFieldTypeArray={setFieldTypeArray}
                    selectField={selectField}
                    setSelectField={setSelectField}
                    groupIndex={groupIndex}
                    setGroupIndex={setGroupIndex}
                    setGroupFields={setGroupFields}
                    groupFields={groupFields}
                    typeOfField={props.type}
                    hideButtons={props.hideButtons}
                    editFieldData={props.editFieldData}
                    updateFieldListing={props.handleAddUpdateFields}
                    onClose={() => {
                      props.onClose();
                    }}
                    userInEdit={userInEdit}
                  />
                )}
                {tabType === 'Forms' && props.type !== 'field' && (
                  <div className="create-form-page-content">
                    <div
                      style={{borderRadius: '5.92px'}}
                      className="bg-[#EDEBE5] p-3">
                      <div className="flex justify-between">
                        <h2
                          className="mb-3 as-link"
                          onClick={() => setIsFormVisible(!isFormVisible)}>
                          New Form
                        </h2>
                        <div className="toggle_btn flex">
                        <label
                            htmlFor="custom-switchy"
                            className="mr-1 text-sm font-medium">
                            Public
                          </label>
                          <Form.Check
                            type="switch"
                            id="custom-switchys"
                            className=""
                            value={publicToogle ? true : false}
                            checked={publicToogle ? true : false}
                            disabled={userInEdit ? false : true}
                            onClick={() => {
                              setPublicToogle(!publicToogle);
                            }}
                          />
                         
                        </div>
                      </div>
                      {isFormVisible && (
                        <>
                          <div
                            className={`overflow-hidden ease-in-out transition-all duration-300 ${
                              isFormVisible ? 'max-h-100' : 'max-h-0'
                            }`}>
                            <hr class="border-t border-gray-300 pb-3"></hr>
                            <div className="roi-form-input">
                              <Form.Control
                                type="text"
                                value={formName}
                                pattern="^\S.*$"
                                required
                                // disabled={userInEdit ? false : true}
                                readOnly={!userInEdit}
                                style={mouseStyles}
                                placeholder="Form Name"
                                onChange={e => {
                                  setFormName(e.target.value);
                                }}
                              />
                              <Form.Control.Feedback type="invalid">
                                Form name is required.
                              </Form.Control.Feedback>
                              <span style={errorStyle}>
                                {error &&
                                  formName === '' &&
                                  'Form name is required'}
                              </span>
                            </div>
                            {/* <p>{(error === true && formName === '') && 'Form name is required'}</p> */}
                            <Form
                              noValidate
                              validated={validated}
                              onSubmit={handleSectionStep}>
                              <div className="roi-form-dropdown mb-3">
                                <div className="select-wrapper">
                                  <Dropdown
                                    onSelect={(eventKey, event) => {
                                      setType(eventKey);
                                      if (props.hideButtons) {
                                        if (eventKey === 'wizard') {
                                          traditionalToWizard(
                                            traditionalSection,
                                            setWizardStepData,
                                            setTraditionalSection,
                                          );
                                        } else {
                                          wizardToTraditional(
                                            wizardStepData,
                                            setTraditionalSection,
                                            setWizardStepData,
                                          );
                                        }
                                      }
                                    }}
                                    drop="down"
                                    disabled={userInEdit ? false : true}>
                                    <Dropdown.Toggle
                                      variant="primary"
                                      disabled={userInEdit ? false : true}
                                      style={{opacity: 1}}>
                                      {type
                                        ? dropdownMenu.find(
                                            item => item.id === type,
                                          ).name
                                        : 'Select type'}
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                      {dropdownMenu.map((item, index) => (
                                        <Dropdown.Item
                                          key={`st-${index}`}
                                          eventKey={item.id.toString()}
                                          active={item.id === type}>
                                          {item.name}
                                        </Dropdown.Item>
                                      ))}
                                    </Dropdown.Menu>
                                  </Dropdown>
                                </div>
                              </div>
                              {userInEdit && (
                                <div className="roi-form-input">
                                  <Form.Control
                                    type="text"
                                    value={
                                      type === 'wizard' ? stepName : sectionName
                                    }
                                    pattern="^\S.*$"
                                    required
                                    placeholder={
                                      type === 'wizard'
                                        ? 'Step Name'
                                        : 'Section Name'
                                    }
                                    onChange={e => {
                                      if (type === 'wizard') {
                                        setStepName(e.target.value);
                                      } else {
                                        setSectionName(e.target.value);
                                      }
                                    }}
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    {type === 'wizard'
                                      ? 'Step name is required.'
                                      : 'Section name is required.'}
                                  </Form.Control.Feedback>
                                </div>
                              )}

                              {userInEdit && type !== 'wizard' && (
                                // <MultiSelectionDropdown
                                //   setDropdownIds={setSelectedFieldsIds}
                                //   dropdownIds={selectedFieldsIds}
                                //   DropDownValues={fieldsList}
                                //   itemName={['group_name']}
                                //   // ErrorMessage={labels.groupRequired[selected_lang]}
                                //   DropdownType={'all'}
                                // />
                                <FieldSuggestion
                                  fieldData={fieldData}
                                  groupFields={formsFields}
                                  setGroupFields={setFormsFields}
                                  handleDelete={handleForm}
                                  type={'traditional'}
                                />
                              )}
                              {userInEdit && (
                                <div className="text-center">
                                  {' '}
                                  <button
                                    style={{
                                      borderRadius: '6.25px',
                                      border: '1px solid #888888',
                                    }}
                                    type="submit"
                                    className="as-link  mb-3 mt-3  add-button">
                                    {chooseButton()}
                                  </button>
                                  {decideCancelButton() && (
                                    <h2
                                      className="mb-2 as-link"
                                      onClick={() => {
                                        cancelEdit();
                                      }}>
                                      Cancel
                                    </h2>
                                  )}
                                </div>
                              )}
                              {userInEdit && type !== 'wizard' && (
                                <div
                                  style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                  }}
                                  className="toogle_btn">
                                  <Form.Check
                                    label={'Use Section Tabs'}
                                    className=""
                                    type="switch"
                                    id="custom-switchys"
                                    disabled={userInEdit ? false : true}
                                    value={section_tabsToogle ? true : false}
                                    checked={section_tabsToogle ? true : false}
                                    onClick={() => {
                                      setSection_tabsToogle(!section_tabsToogle);
                                    }}
                                  />
                                </div>
                              )}

                              {type !== 'wizard' && (
                                <FormSection
                                  sections={traditionalSection}
                                  setSections={setTraditionalSection}
                                  handelSection={handleForm}
                                  SubmitFormApi={SubmitFormApi}
                                  userInEdit={userInEdit}
                                  updateForm={
                                    props.editFormItem &&
                                    typeof props.editFormItem === 'object' &&
                                    Object.keys(props.editFormItem).length > 0
                                  }
                                />
                              )}
                            </Form>
                          </div>
                          {/* </Form> */}

                          {type === 'wizard' && (
                            <FormStep
                              steps={wizardStepData}
                              setSteps={setWizardStepData}
                              handleWizardStep={handleForm}
                              SubmitFormApi={SubmitFormApi}
                              userInEdit={userInEdit}
                              updateForm={
                                props.editFormItem &&
                                typeof props.editFormItem === 'object' &&
                                Object.keys(props.editFormItem).length > 0
                              }
                            />
                          )}
                        </>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </Scrollbars>
        </div>
      </SlidingPanel>

      {showDeleteModal && (
        <ConfirmationModel
          closeModel={() => {
            setShowDeleteModal(false);
            setFormsIndex({
              index: null,
              type: '',
            })
            setFormSectionIndex({
              index: null,
              type: '',
            });
            setSectionName('');
            setFormsFields([]);
            setStepName('')
          }}
          discription={`Are you sure you want to delete?`}
          buttonOne={'Yes'}
          buttonTwo={'No Thanks'}
          ButtonOneAction={() => {
            if (typeIndex.type === 'delete_option') {
              deleteField();
            } else {
              if (groupIndex.type === 'goup_fields_del') {
                deleteField();
              } else if (formsIndex.type === 'multi_select_field') {
                setFormsFields(prev =>
                  prev.filter((_, index) => index !== formsIndex.index),
                );
              } else if (formsIndex.type === 'delete_section') {
                setTraditionalSection(prev =>
                  prev.filter((_, index) => index !== formsIndex.index),
                );
                setSectionName('');
                setFormsFields([]);
                setFormSectionIndex({
                  index: null,
                  type: '',
                });
              } else if (formsIndex.type === 'delete_Wizard_Step') {
                setWizardStepData(prevSteps =>
                  prevSteps.filter((_, index) => index !== formsIndex.index),
                );
                setStepName('');
                setFormsIndex({
                  index: null,
                  type: '',
                });
              } else {
                delete_field();
              }
            }

            // deleteField()

            setShowDeleteModal(false);
          }}
        />
      )}
    </>
  );
};

export default CreateForm;
